
.table-responsive::-moz-scrollbar {
    background-color: #4D06A5 !important;
}

.table-responsive::-webkit-scrollbar-thumb {
    background-color: #4D06A5 !important;
}

.table-responsive::-moz-scrollbar-thumb {
    background-color: #4D06A5 !important; 
}