.letter {
  border-right: 2px solid white;
  border-left: 2px solid white;
  background-color: white;
  // background-color: red;
  // padding: 50px;
  .centering {
    margin: auto 50px;

    &.alt {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .menu {
    margin: -5px 0;
    .menu-item {
      margin: 5px 0;
      font-size: 14px;
      text-transform: capitalize;
      &:first-child {
        font-weight: 600;
      }
    }
  }

  .letter-header {
    min-height: 60px;
    padding: 15px 0;
    color: white;
    // background-color: #f7f7f7;

    .logoWrapper {
      // padding: 0 50px;

      img {
        width: 100%;
        height: 175px;
        object-fit: contain;
      }
    }
  }

  .sub-header {
    border-bottom: 2px solid #ccc;
    text-transform: capitalize;
    padding-bottom: 10px;

  }
  .letter-content {
    background-color: #fff;
    padding: 40px 0;

    .letter-excerpt {
      margin: 20px 0;

      .ref-to {
        margin-bottom: 10px;
      }
      .letter-ref {
        font-weight: 550;
        font-size: 17px;
        text-transform: capitalize;
      }
    }

    .addressWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .address h5 {
        line-height: 13px;
        font-size: larger;
      }
    }

    .date {
      margin: 20px 0;
    }

    .letterTitle {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;

      .letter-subtitle {
        // color: #000;
        font-weight: 550;
        font-size: 18px;
        text-transform: capitalize;
      }
    }
  }

  .content-footer {
    .signature-canvas {
      max-height: auto !important;
      max-width: auto !important;
      border: 1px solid #6e7998;
      // margin-bottom: 10px;
    }
  }

  .letter-footer {
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #618597;
  }
}

.message-container {
  max-height: 40rem;
  overflow-y: scroll;
  padding-right: 1rem;
  &::-webkit-scrollbar {
    width: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #ffff;
    -webkit-border-radius: 1rem;
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    -webkit-border-radius: 1rem;
    border-radius: 1rem;
    background: #4444;
  }
}
.message-body {
  background-color: #f2f3f4;
  margin-bottom: 1rem;
  padding: 0.7rem;
  border-radius: 1rem;

  &__title {
    text-transform: capitalize;
    font-weight: 550;
    color: #333;
  }

  &__message {
    color: #444;
  }

  &__date {
    font-size: 13px;
  }
}

.no-message {
  font-style: italic;
}

.dropdown-toggle::after {
  display: none !important; 
}


