.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.not-sr-only {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.focus-within\:sr-only:focus-within {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.focus-within\:not-sr-only:focus-within {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.focus\:sr-only:focus {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.focus\:not-sr-only:focus {
  position: static;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: visible;
  clip: auto;
  white-space: normal;
}
.pointer-events-none {
  pointer-events: none;
}
.pointer-events-auto {
  pointer-events: auto;
}
.visible {
  visibility: visible;
}
.invisible {
  visibility: hidden;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: sticky;
}
.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.inset-1 {
  top: 0.25rem;
  right: 0.25rem;
  bottom: 0.25rem;
  left: 0.25rem;
}
.inset-2 {
  top: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  left: 0.5rem;
}
.inset-3 {
  top: 0.75rem;
  right: 0.75rem;
  bottom: 0.75rem;
  left: 0.75rem;
}
.inset-4 {
  top: 1rem;
  right: 1rem;
  bottom: 1rem;
  left: 1rem;
}
.inset-5 {
  top: 1.25rem;
  right: 1.25rem;
  bottom: 1.25rem;
  left: 1.25rem;
}
.inset-6 {
  top: 1.5rem;
  right: 1.5rem;
  bottom: 1.5rem;
  left: 1.5rem;
}
.inset-7 {
  top: 1.75rem;
  right: 1.75rem;
  bottom: 1.75rem;
  left: 1.75rem;
}
.inset-8 {
  top: 2rem;
  right: 2rem;
  bottom: 2rem;
  left: 2rem;
}
.inset-9 {
  top: 2.25rem;
  right: 2.25rem;
  bottom: 2.25rem;
  left: 2.25rem;
}
.inset-10 {
  top: 2.5rem;
  right: 2.5rem;
  bottom: 2.5rem;
  left: 2.5rem;
}
.inset-11 {
  top: 2.75rem;
  right: 2.75rem;
  bottom: 2.75rem;
  left: 2.75rem;
}
.inset-12 {
  top: 3rem;
  right: 3rem;
  bottom: 3rem;
  left: 3rem;
}
.inset-14 {
  top: 3.5rem;
  right: 3.5rem;
  bottom: 3.5rem;
  left: 3.5rem;
}
.inset-16 {
  top: 4rem;
  right: 4rem;
  bottom: 4rem;
  left: 4rem;
}
.inset-20 {
  top: 5rem;
  right: 5rem;
  bottom: 5rem;
  left: 5rem;
}
.inset-24 {
  top: 6rem;
  right: 6rem;
  bottom: 6rem;
  left: 6rem;
}
.inset-28 {
  top: 7rem;
  right: 7rem;
  bottom: 7rem;
  left: 7rem;
}
.inset-32 {
  top: 8rem;
  right: 8rem;
  bottom: 8rem;
  left: 8rem;
}
.inset-36 {
  top: 9rem;
  right: 9rem;
  bottom: 9rem;
  left: 9rem;
}
.inset-40 {
  top: 10rem;
  right: 10rem;
  bottom: 10rem;
  left: 10rem;
}
.inset-44 {
  top: 11rem;
  right: 11rem;
  bottom: 11rem;
  left: 11rem;
}
.inset-48 {
  top: 12rem;
  right: 12rem;
  bottom: 12rem;
  left: 12rem;
}
.inset-52 {
  top: 13rem;
  right: 13rem;
  bottom: 13rem;
  left: 13rem;
}
.inset-56 {
  top: 14rem;
  right: 14rem;
  bottom: 14rem;
  left: 14rem;
}
.inset-60 {
  top: 15rem;
  right: 15rem;
  bottom: 15rem;
  left: 15rem;
}
.inset-64 {
  top: 16rem;
  right: 16rem;
  bottom: 16rem;
  left: 16rem;
}
.inset-72 {
  top: 18rem;
  right: 18rem;
  bottom: 18rem;
  left: 18rem;
}
.inset-80 {
  top: 20rem;
  right: 20rem;
  bottom: 20rem;
  left: 20rem;
}
.inset-96 {
  top: 24rem;
  right: 24rem;
  bottom: 24rem;
  left: 24rem;
}
.inset-auto {
  top: auto;
  right: auto;
  bottom: auto;
  left: auto;
}
.inset-px {
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px;
}
.inset-0\.5 {
  top: 0.125rem;
  right: 0.125rem;
  bottom: 0.125rem;
  left: 0.125rem;
}
.inset-1\.5 {
  top: 0.375rem;
  right: 0.375rem;
  bottom: 0.375rem;
  left: 0.375rem;
}
.inset-2\.5 {
  top: 0.625rem;
  right: 0.625rem;
  bottom: 0.625rem;
  left: 0.625rem;
}
.inset-3\.5 {
  top: 0.875rem;
  right: 0.875rem;
  bottom: 0.875rem;
  left: 0.875rem;
}
.-inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.-inset-1 {
  top: -0.25rem;
  right: -0.25rem;
  bottom: -0.25rem;
  left: -0.25rem;
}
.-inset-2 {
  top: -0.5rem;
  right: -0.5rem;
  bottom: -0.5rem;
  left: -0.5rem;
}
.-inset-3 {
  top: -0.75rem;
  right: -0.75rem;
  bottom: -0.75rem;
  left: -0.75rem;
}
.-inset-4 {
  top: -1rem;
  right: -1rem;
  bottom: -1rem;
  left: -1rem;
}
.-inset-5 {
  top: -1.25rem;
  right: -1.25rem;
  bottom: -1.25rem;
  left: -1.25rem;
}
.-inset-6 {
  top: -1.5rem;
  right: -1.5rem;
  bottom: -1.5rem;
  left: -1.5rem;
}
.-inset-7 {
  top: -1.75rem;
  right: -1.75rem;
  bottom: -1.75rem;
  left: -1.75rem;
}
.-inset-8 {
  top: -2rem;
  right: -2rem;
  bottom: -2rem;
  left: -2rem;
}
.-inset-9 {
  top: -2.25rem;
  right: -2.25rem;
  bottom: -2.25rem;
  left: -2.25rem;
}
.-inset-10 {
  top: -2.5rem;
  right: -2.5rem;
  bottom: -2.5rem;
  left: -2.5rem;
}
.-inset-11 {
  top: -2.75rem;
  right: -2.75rem;
  bottom: -2.75rem;
  left: -2.75rem;
}
.-inset-12 {
  top: -3rem;
  right: -3rem;
  bottom: -3rem;
  left: -3rem;
}
.-inset-14 {
  top: -3.5rem;
  right: -3.5rem;
  bottom: -3.5rem;
  left: -3.5rem;
}
.-inset-16 {
  top: -4rem;
  right: -4rem;
  bottom: -4rem;
  left: -4rem;
}
.-inset-20 {
  top: -5rem;
  right: -5rem;
  bottom: -5rem;
  left: -5rem;
}
.-inset-24 {
  top: -6rem;
  right: -6rem;
  bottom: -6rem;
  left: -6rem;
}
.-inset-28 {
  top: -7rem;
  right: -7rem;
  bottom: -7rem;
  left: -7rem;
}
.-inset-32 {
  top: -8rem;
  right: -8rem;
  bottom: -8rem;
  left: -8rem;
}
.-inset-36 {
  top: -9rem;
  right: -9rem;
  bottom: -9rem;
  left: -9rem;
}
.-inset-40 {
  top: -10rem;
  right: -10rem;
  bottom: -10rem;
  left: -10rem;
}
.-inset-44 {
  top: -11rem;
  right: -11rem;
  bottom: -11rem;
  left: -11rem;
}
.-inset-48 {
  top: -12rem;
  right: -12rem;
  bottom: -12rem;
  left: -12rem;
}
.-inset-52 {
  top: -13rem;
  right: -13rem;
  bottom: -13rem;
  left: -13rem;
}
.-inset-56 {
  top: -14rem;
  right: -14rem;
  bottom: -14rem;
  left: -14rem;
}
.-inset-60 {
  top: -15rem;
  right: -15rem;
  bottom: -15rem;
  left: -15rem;
}
.-inset-64 {
  top: -16rem;
  right: -16rem;
  bottom: -16rem;
  left: -16rem;
}
.-inset-72 {
  top: -18rem;
  right: -18rem;
  bottom: -18rem;
  left: -18rem;
}
.-inset-80 {
  top: -20rem;
  right: -20rem;
  bottom: -20rem;
  left: -20rem;
}
.-inset-96 {
  top: -24rem;
  right: -24rem;
  bottom: -24rem;
  left: -24rem;
}
.-inset-px {
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
}
.-inset-0\.5 {
  top: -0.125rem;
  right: -0.125rem;
  bottom: -0.125rem;
  left: -0.125rem;
}
.-inset-1\.5 {
  top: -0.375rem;
  right: -0.375rem;
  bottom: -0.375rem;
  left: -0.375rem;
}
.-inset-2\.5 {
  top: -0.625rem;
  right: -0.625rem;
  bottom: -0.625rem;
  left: -0.625rem;
}
.-inset-3\.5 {
  top: -0.875rem;
  right: -0.875rem;
  bottom: -0.875rem;
  left: -0.875rem;
}
.inset-1\/2 {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
}
.inset-1\/3 {
  top: 33.333333%;
  right: 33.333333%;
  bottom: 33.333333%;
  left: 33.333333%;
}
.inset-2\/3 {
  top: 66.666667%;
  right: 66.666667%;
  bottom: 66.666667%;
  left: 66.666667%;
}
.inset-1\/4 {
  top: 25%;
  right: 25%;
  bottom: 25%;
  left: 25%;
}
.inset-2\/4 {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
}
.inset-3\/4 {
  top: 75%;
  right: 75%;
  bottom: 75%;
  left: 75%;
}
.inset-full {
  top: 100%;
  right: 100%;
  bottom: 100%;
  left: 100%;
}
.-inset-1\/2 {
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%;
}
.-inset-1\/3 {
  top: -33.333333%;
  right: -33.333333%;
  bottom: -33.333333%;
  left: -33.333333%;
}
.-inset-2\/3 {
  top: -66.666667%;
  right: -66.666667%;
  bottom: -66.666667%;
  left: -66.666667%;
}
.-inset-1\/4 {
  top: -25%;
  right: -25%;
  bottom: -25%;
  left: -25%;
}
.-inset-2\/4 {
  top: -50%;
  right: -50%;
  bottom: -50%;
  left: -50%;
}
.-inset-3\/4 {
  top: -75%;
  right: -75%;
  bottom: -75%;
  left: -75%;
}
.-inset-full {
  top: -100%;
  right: -100%;
  bottom: -100%;
  left: -100%;
}
.inset-x-0 {
  left: 0;
  right: 0;
}
.inset-x-1 {
  left: 0.25rem;
  right: 0.25rem;
}
.inset-x-2 {
  left: 0.5rem;
  right: 0.5rem;
}
.inset-x-3 {
  left: 0.75rem;
  right: 0.75rem;
}
.inset-x-4 {
  left: 1rem;
  right: 1rem;
}
.inset-x-5 {
  left: 1.25rem;
  right: 1.25rem;
}
.inset-x-6 {
  left: 1.5rem;
  right: 1.5rem;
}
.inset-x-7 {
  left: 1.75rem;
  right: 1.75rem;
}
.inset-x-8 {
  left: 2rem;
  right: 2rem;
}
.inset-x-9 {
  left: 2.25rem;
  right: 2.25rem;
}
.inset-x-10 {
  left: 2.5rem;
  right: 2.5rem;
}
.inset-x-11 {
  left: 2.75rem;
  right: 2.75rem;
}
.inset-x-12 {
  left: 3rem;
  right: 3rem;
}
.inset-x-14 {
  left: 3.5rem;
  right: 3.5rem;
}
.inset-x-16 {
  left: 4rem;
  right: 4rem;
}
.inset-x-20 {
  left: 5rem;
  right: 5rem;
}
.inset-x-24 {
  left: 6rem;
  right: 6rem;
}
.inset-x-28 {
  left: 7rem;
  right: 7rem;
}
.inset-x-32 {
  left: 8rem;
  right: 8rem;
}
.inset-x-36 {
  left: 9rem;
  right: 9rem;
}
.inset-x-40 {
  left: 10rem;
  right: 10rem;
}
.inset-x-44 {
  left: 11rem;
  right: 11rem;
}
.inset-x-48 {
  left: 12rem;
  right: 12rem;
}
.inset-x-52 {
  left: 13rem;
  right: 13rem;
}
.inset-x-56 {
  left: 14rem;
  right: 14rem;
}
.inset-x-60 {
  left: 15rem;
  right: 15rem;
}
.inset-x-64 {
  left: 16rem;
  right: 16rem;
}
.inset-x-72 {
  left: 18rem;
  right: 18rem;
}
.inset-x-80 {
  left: 20rem;
  right: 20rem;
}
.inset-x-96 {
  left: 24rem;
  right: 24rem;
}
.inset-x-auto {
  left: auto;
  right: auto;
}
.inset-x-px {
  left: 1px;
  right: 1px;
}
.inset-x-0\.5 {
  left: 0.125rem;
  right: 0.125rem;
}
.inset-x-1\.5 {
  left: 0.375rem;
  right: 0.375rem;
}
.inset-x-2\.5 {
  left: 0.625rem;
  right: 0.625rem;
}
.inset-x-3\.5 {
  left: 0.875rem;
  right: 0.875rem;
}
.-inset-x-0 {
  left: 0;
  right: 0;
}
.-inset-x-1 {
  left: -0.25rem;
  right: -0.25rem;
}
.-inset-x-2 {
  left: -0.5rem;
  right: -0.5rem;
}
.-inset-x-3 {
  left: -0.75rem;
  right: -0.75rem;
}
.-inset-x-4 {
  left: -1rem;
  right: -1rem;
}
.-inset-x-5 {
  left: -1.25rem;
  right: -1.25rem;
}
.-inset-x-6 {
  left: -1.5rem;
  right: -1.5rem;
}
.-inset-x-7 {
  left: -1.75rem;
  right: -1.75rem;
}
.-inset-x-8 {
  left: -2rem;
  right: -2rem;
}
.-inset-x-9 {
  left: -2.25rem;
  right: -2.25rem;
}
.-inset-x-10 {
  left: -2.5rem;
  right: -2.5rem;
}
.-inset-x-11 {
  left: -2.75rem;
  right: -2.75rem;
}
.-inset-x-12 {
  left: -3rem;
  right: -3rem;
}
.-inset-x-14 {
  left: -3.5rem;
  right: -3.5rem;
}
.-inset-x-16 {
  left: -4rem;
  right: -4rem;
}
.-inset-x-20 {
  left: -5rem;
  right: -5rem;
}
.-inset-x-24 {
  left: -6rem;
  right: -6rem;
}
.-inset-x-28 {
  left: -7rem;
  right: -7rem;
}
.-inset-x-32 {
  left: -8rem;
  right: -8rem;
}
.-inset-x-36 {
  left: -9rem;
  right: -9rem;
}
.-inset-x-40 {
  left: -10rem;
  right: -10rem;
}
.-inset-x-44 {
  left: -11rem;
  right: -11rem;
}
.-inset-x-48 {
  left: -12rem;
  right: -12rem;
}
.-inset-x-52 {
  left: -13rem;
  right: -13rem;
}
.-inset-x-56 {
  left: -14rem;
  right: -14rem;
}
.-inset-x-60 {
  left: -15rem;
  right: -15rem;
}
.-inset-x-64 {
  left: -16rem;
  right: -16rem;
}
.-inset-x-72 {
  left: -18rem;
  right: -18rem;
}
.-inset-x-80 {
  left: -20rem;
  right: -20rem;
}
.-inset-x-96 {
  left: -24rem;
  right: -24rem;
}
.-inset-x-px {
  left: -1px;
  right: -1px;
}
.-inset-x-0\.5 {
  left: -0.125rem;
  right: -0.125rem;
}
.-inset-x-1\.5 {
  left: -0.375rem;
  right: -0.375rem;
}
.-inset-x-2\.5 {
  left: -0.625rem;
  right: -0.625rem;
}
.-inset-x-3\.5 {
  left: -0.875rem;
  right: -0.875rem;
}
.inset-x-1\/2 {
  left: 50%;
  right: 50%;
}
.inset-x-1\/3 {
  left: 33.333333%;
  right: 33.333333%;
}
.inset-x-2\/3 {
  left: 66.666667%;
  right: 66.666667%;
}
.inset-x-1\/4 {
  left: 25%;
  right: 25%;
}
.inset-x-2\/4 {
  left: 50%;
  right: 50%;
}
.inset-x-3\/4 {
  left: 75%;
  right: 75%;
}
.inset-x-full {
  left: 100%;
  right: 100%;
}
.-inset-x-1\/2 {
  left: -50%;
  right: -50%;
}
.-inset-x-1\/3 {
  left: -33.333333%;
  right: -33.333333%;
}
.-inset-x-2\/3 {
  left: -66.666667%;
  right: -66.666667%;
}
.-inset-x-1\/4 {
  left: -25%;
  right: -25%;
}
.-inset-x-2\/4 {
  left: -50%;
  right: -50%;
}
.-inset-x-3\/4 {
  left: -75%;
  right: -75%;
}
.-inset-x-full {
  left: -100%;
  right: -100%;
}
.inset-y-0 {
  top: 0;
  bottom: 0;
}
.inset-y-1 {
  top: 0.25rem;
  bottom: 0.25rem;
}
.inset-y-2 {
  top: 0.5rem;
  bottom: 0.5rem;
}
.inset-y-3 {
  top: 0.75rem;
  bottom: 0.75rem;
}
.inset-y-4 {
  top: 1rem;
  bottom: 1rem;
}
.inset-y-5 {
  top: 1.25rem;
  bottom: 1.25rem;
}
.inset-y-6 {
  top: 1.5rem;
  bottom: 1.5rem;
}
.inset-y-7 {
  top: 1.75rem;
  bottom: 1.75rem;
}
.inset-y-8 {
  top: 2rem;
  bottom: 2rem;
}
.inset-y-9 {
  top: 2.25rem;
  bottom: 2.25rem;
}
.inset-y-10 {
  top: 2.5rem;
  bottom: 2.5rem;
}
.inset-y-11 {
  top: 2.75rem;
  bottom: 2.75rem;
}
.inset-y-12 {
  top: 3rem;
  bottom: 3rem;
}
.inset-y-14 {
  top: 3.5rem;
  bottom: 3.5rem;
}
.inset-y-16 {
  top: 4rem;
  bottom: 4rem;
}
.inset-y-20 {
  top: 5rem;
  bottom: 5rem;
}
.inset-y-24 {
  top: 6rem;
  bottom: 6rem;
}
.inset-y-28 {
  top: 7rem;
  bottom: 7rem;
}
.inset-y-32 {
  top: 8rem;
  bottom: 8rem;
}
.inset-y-36 {
  top: 9rem;
  bottom: 9rem;
}
.inset-y-40 {
  top: 10rem;
  bottom: 10rem;
}
.inset-y-44 {
  top: 11rem;
  bottom: 11rem;
}
.inset-y-48 {
  top: 12rem;
  bottom: 12rem;
}
.inset-y-52 {
  top: 13rem;
  bottom: 13rem;
}
.inset-y-56 {
  top: 14rem;
  bottom: 14rem;
}
.inset-y-60 {
  top: 15rem;
  bottom: 15rem;
}
.inset-y-64 {
  top: 16rem;
  bottom: 16rem;
}
.inset-y-72 {
  top: 18rem;
  bottom: 18rem;
}
.inset-y-80 {
  top: 20rem;
  bottom: 20rem;
}
.inset-y-96 {
  top: 24rem;
  bottom: 24rem;
}
.inset-y-auto {
  top: auto;
  bottom: auto;
}
.inset-y-px {
  top: 1px;
  bottom: 1px;
}
.inset-y-0\.5 {
  top: 0.125rem;
  bottom: 0.125rem;
}
.inset-y-1\.5 {
  top: 0.375rem;
  bottom: 0.375rem;
}
.inset-y-2\.5 {
  top: 0.625rem;
  bottom: 0.625rem;
}
.inset-y-3\.5 {
  top: 0.875rem;
  bottom: 0.875rem;
}
.-inset-y-0 {
  top: 0;
  bottom: 0;
}
.-inset-y-1 {
  top: -0.25rem;
  bottom: -0.25rem;
}
.-inset-y-2 {
  top: -0.5rem;
  bottom: -0.5rem;
}
.-inset-y-3 {
  top: -0.75rem;
  bottom: -0.75rem;
}
.-inset-y-4 {
  top: -1rem;
  bottom: -1rem;
}
.-inset-y-5 {
  top: -1.25rem;
  bottom: -1.25rem;
}
.-inset-y-6 {
  top: -1.5rem;
  bottom: -1.5rem;
}
.-inset-y-7 {
  top: -1.75rem;
  bottom: -1.75rem;
}
.-inset-y-8 {
  top: -2rem;
  bottom: -2rem;
}
.-inset-y-9 {
  top: -2.25rem;
  bottom: -2.25rem;
}
.-inset-y-10 {
  top: -2.5rem;
  bottom: -2.5rem;
}
.-inset-y-11 {
  top: -2.75rem;
  bottom: -2.75rem;
}
.-inset-y-12 {
  top: -3rem;
  bottom: -3rem;
}
.-inset-y-14 {
  top: -3.5rem;
  bottom: -3.5rem;
}
.-inset-y-16 {
  top: -4rem;
  bottom: -4rem;
}
.-inset-y-20 {
  top: -5rem;
  bottom: -5rem;
}
.-inset-y-24 {
  top: -6rem;
  bottom: -6rem;
}
.-inset-y-28 {
  top: -7rem;
  bottom: -7rem;
}
.-inset-y-32 {
  top: -8rem;
  bottom: -8rem;
}
.-inset-y-36 {
  top: -9rem;
  bottom: -9rem;
}
.-inset-y-40 {
  top: -10rem;
  bottom: -10rem;
}
.-inset-y-44 {
  top: -11rem;
  bottom: -11rem;
}
.-inset-y-48 {
  top: -12rem;
  bottom: -12rem;
}
.-inset-y-52 {
  top: -13rem;
  bottom: -13rem;
}
.-inset-y-56 {
  top: -14rem;
  bottom: -14rem;
}
.-inset-y-60 {
  top: -15rem;
  bottom: -15rem;
}
.-inset-y-64 {
  top: -16rem;
  bottom: -16rem;
}
.-inset-y-72 {
  top: -18rem;
  bottom: -18rem;
}
.-inset-y-80 {
  top: -20rem;
  bottom: -20rem;
}
.-inset-y-96 {
  top: -24rem;
  bottom: -24rem;
}
.-inset-y-px {
  top: -1px;
  bottom: -1px;
}
.-inset-y-0\.5 {
  top: -0.125rem;
  bottom: -0.125rem;
}
.-inset-y-1\.5 {
  top: -0.375rem;
  bottom: -0.375rem;
}
.-inset-y-2\.5 {
  top: -0.625rem;
  bottom: -0.625rem;
}
.-inset-y-3\.5 {
  top: -0.875rem;
  bottom: -0.875rem;
}
.inset-y-1\/2 {
  top: 50%;
  bottom: 50%;
}
.inset-y-1\/3 {
  top: 33.333333%;
  bottom: 33.333333%;
}
.inset-y-2\/3 {
  top: 66.666667%;
  bottom: 66.666667%;
}
.inset-y-1\/4 {
  top: 25%;
  bottom: 25%;
}
.inset-y-2\/4 {
  top: 50%;
  bottom: 50%;
}
.inset-y-3\/4 {
  top: 75%;
  bottom: 75%;
}
.inset-y-full {
  top: 100%;
  bottom: 100%;
}
.-inset-y-1\/2 {
  top: -50%;
  bottom: -50%;
}
.-inset-y-1\/3 {
  top: -33.333333%;
  bottom: -33.333333%;
}
.-inset-y-2\/3 {
  top: -66.666667%;
  bottom: -66.666667%;
}
.-inset-y-1\/4 {
  top: -25%;
  bottom: -25%;
}
.-inset-y-2\/4 {
  top: -50%;
  bottom: -50%;
}
.-inset-y-3\/4 {
  top: -75%;
  bottom: -75%;
}
.-inset-y-full {
  top: -100%;
  bottom: -100%;
}
.top-0 {
  top: 0;
}
.top-1 {
  top: 0.25rem;
}
.top-2 {
  top: 0.5rem;
}
.top-3 {
  top: 0.75rem;
}
.top-4 {
  top: 1rem;
}
.top-5 {
  top: 1.25rem;
}
.top-6 {
  top: 1.5rem;
}
.top-7 {
  top: 1.75rem;
}
.top-8 {
  top: 2rem;
}
.top-9 {
  top: 2.25rem;
}
.top-10 {
  top: 2.5rem;
}
.top-11 {
  top: 2.75rem;
}
.top-12 {
  top: 3rem;
}
.top-14 {
  top: 3.5rem;
}
.top-16 {
  top: 4rem;
}
.top-20 {
  top: 5rem;
}
.top-24 {
  top: 6rem;
}
.top-28 {
  top: 7rem;
}
.top-32 {
  top: 8rem;
}
.top-36 {
  top: 9rem;
}
.top-40 {
  top: 10rem;
}
.top-44 {
  top: 11rem;
}
.top-48 {
  top: 12rem;
}
.top-52 {
  top: 13rem;
}
.top-56 {
  top: 14rem;
}
.top-60 {
  top: 15rem;
}
.top-64 {
  top: 16rem;
}
.top-72 {
  top: 18rem;
}
.top-80 {
  top: 20rem;
}
.top-96 {
  top: 24rem;
}
.top-auto {
  top: auto;
}
.top-px {
  top: 1px;
}
.top-0\.5 {
  top: 0.125rem;
}
.top-1\.5 {
  top: 0.375rem;
}
.top-2\.5 {
  top: 0.625rem;
}
.top-3\.5 {
  top: 0.875rem;
}
.-top-0 {
  top: 0;
}
.-top-1 {
  top: -0.25rem;
}
.-top-2 {
  top: -0.5rem;
}
.-top-3 {
  top: -0.75rem;
}
.-top-4 {
  top: -1rem;
}
.-top-5 {
  top: -1.25rem;
}
.-top-6 {
  top: -1.5rem;
}
.-top-7 {
  top: -1.75rem;
}
.-top-8 {
  top: -2rem;
}
.-top-9 {
  top: -2.25rem;
}
.-top-10 {
  top: -2.5rem;
}
.-top-11 {
  top: -2.75rem;
}
.-top-12 {
  top: -3rem;
}
.-top-14 {
  top: -3.5rem;
}
.-top-16 {
  top: -4rem;
}
.-top-20 {
  top: -5rem;
}
.-top-24 {
  top: -6rem;
}
.-top-28 {
  top: -7rem;
}
.-top-32 {
  top: -8rem;
}
.-top-36 {
  top: -9rem;
}
.-top-40 {
  top: -10rem;
}
.-top-44 {
  top: -11rem;
}
.-top-48 {
  top: -12rem;
}
.-top-52 {
  top: -13rem;
}
.-top-56 {
  top: -14rem;
}
.-top-60 {
  top: -15rem;
}
.-top-64 {
  top: -16rem;
}
.-top-72 {
  top: -18rem;
}
.-top-80 {
  top: -20rem;
}
.-top-96 {
  top: -24rem;
}
.-top-px {
  top: -1px;
}
.-top-0\.5 {
  top: -0.125rem;
}
.-top-1\.5 {
  top: -0.375rem;
}
.-top-2\.5 {
  top: -0.625rem;
}
.-top-3\.5 {
  top: -0.875rem;
}
.top-1\/2 {
  top: 50%;
}
.top-1\/3 {
  top: 33.333333%;
}
.top-2\/3 {
  top: 66.666667%;
}
.top-1\/4 {
  top: 25%;
}
.top-2\/4 {
  top: 50%;
}
.top-3\/4 {
  top: 75%;
}
.top-full {
  top: 100%;
}
.-top-1\/2 {
  top: -50%;
}
.-top-1\/3 {
  top: -33.333333%;
}
.-top-2\/3 {
  top: -66.666667%;
}
.-top-1\/4 {
  top: -25%;
}
.-top-2\/4 {
  top: -50%;
}
.-top-3\/4 {
  top: -75%;
}
.-top-full {
  top: -100%;
}
.right-0 {
  right: 0;
}
.right-1 {
  right: 0.25rem;
}
.right-2 {
  right: 0.5rem;
}
.right-3 {
  right: 0.75rem;
}
.right-4 {
  right: 1rem;
}
.right-5 {
  right: 1.25rem;
}
.right-6 {
  right: 1.5rem;
}
.right-7 {
  right: 1.75rem;
}
.right-8 {
  right: 2rem;
}
.right-9 {
  right: 2.25rem;
}
.right-10 {
  right: 2.5rem;
}
.right-11 {
  right: 2.75rem;
}
.right-12 {
  right: 3rem;
}
.right-14 {
  right: 3.5rem;
}
.right-16 {
  right: 4rem;
}
.right-20 {
  right: 5rem;
}
.right-24 {
  right: 6rem;
}
.right-28 {
  right: 7rem;
}
.right-32 {
  right: 8rem;
}
.right-36 {
  right: 9rem;
}
.right-40 {
  right: 10rem;
}
.right-44 {
  right: 11rem;
}
.right-48 {
  right: 12rem;
}
.right-52 {
  right: 13rem;
}
.right-56 {
  right: 14rem;
}
.right-60 {
  right: 15rem;
}
.right-64 {
  right: 16rem;
}
.right-72 {
  right: 18rem;
}
.right-80 {
  right: 20rem;
}
.right-96 {
  right: 24rem;
}
.right-auto {
  right: auto;
}
.right-px {
  right: 1px;
}
.right-0\.5 {
  right: 0.125rem;
}
.right-1\.5 {
  right: 0.375rem;
}
.right-2\.5 {
  right: 0.625rem;
}
.right-3\.5 {
  right: 0.875rem;
}
.-right-0 {
  right: 0;
}
.-right-1 {
  right: -0.25rem;
}
.-right-2 {
  right: -0.5rem;
}
.-right-3 {
  right: -0.75rem;
}
.-right-4 {
  right: -1rem;
}
.-right-5 {
  right: -1.25rem;
}
.-right-6 {
  right: -1.5rem;
}
.-right-7 {
  right: -1.75rem;
}
.-right-8 {
  right: -2rem;
}
.-right-9 {
  right: -2.25rem;
}
.-right-10 {
  right: -2.5rem;
}
.-right-11 {
  right: -2.75rem;
}
.-right-12 {
  right: -3rem;
}
.-right-14 {
  right: -3.5rem;
}
.-right-16 {
  right: -4rem;
}
.-right-20 {
  right: -5rem;
}
.-right-24 {
  right: -6rem;
}
.-right-28 {
  right: -7rem;
}
.-right-32 {
  right: -8rem;
}
.-right-36 {
  right: -9rem;
}
.-right-40 {
  right: -10rem;
}
.-right-44 {
  right: -11rem;
}
.-right-48 {
  right: -12rem;
}
.-right-52 {
  right: -13rem;
}
.-right-56 {
  right: -14rem;
}
.-right-60 {
  right: -15rem;
}
.-right-64 {
  right: -16rem;
}
.-right-72 {
  right: -18rem;
}
.-right-80 {
  right: -20rem;
}
.-right-96 {
  right: -24rem;
}
.-right-px {
  right: -1px;
}
.-right-0\.5 {
  right: -0.125rem;
}
.-right-1\.5 {
  right: -0.375rem;
}
.-right-2\.5 {
  right: -0.625rem;
}
.-right-3\.5 {
  right: -0.875rem;
}
.right-1\/2 {
  right: 50%;
}
.right-1\/3 {
  right: 33.333333%;
}
.right-2\/3 {
  right: 66.666667%;
}
.right-1\/4 {
  right: 25%;
}
.right-2\/4 {
  right: 50%;
}
.right-3\/4 {
  right: 75%;
}
.right-full {
  right: 100%;
}
.-right-1\/2 {
  right: -50%;
}
.-right-1\/3 {
  right: -33.333333%;
}
.-right-2\/3 {
  right: -66.666667%;
}
.-right-1\/4 {
  right: -25%;
}
.-right-2\/4 {
  right: -50%;
}
.-right-3\/4 {
  right: -75%;
}
.-right-full {
  right: -100%;
}
.bottom-0 {
  bottom: 0;
}
.bottom-1 {
  bottom: 0.25rem;
}
.bottom-2 {
  bottom: 0.5rem;
}
.bottom-3 {
  bottom: 0.75rem;
}
.bottom-4 {
  bottom: 1rem;
}
.bottom-5 {
  bottom: 1.25rem;
}
.bottom-6 {
  bottom: 1.5rem;
}
.bottom-7 {
  bottom: 1.75rem;
}
.bottom-8 {
  bottom: 2rem;
}
.bottom-9 {
  bottom: 2.25rem;
}
.bottom-10 {
  bottom: 2.5rem;
}
.bottom-11 {
  bottom: 2.75rem;
}
.bottom-12 {
  bottom: 3rem;
}
.bottom-14 {
  bottom: 3.5rem;
}
.bottom-16 {
  bottom: 4rem;
}
.bottom-20 {
  bottom: 5rem;
}
.bottom-24 {
  bottom: 6rem;
}
.bottom-28 {
  bottom: 7rem;
}
.bottom-32 {
  bottom: 8rem;
}
.bottom-36 {
  bottom: 9rem;
}
.bottom-40 {
  bottom: 10rem;
}
.bottom-44 {
  bottom: 11rem;
}
.bottom-48 {
  bottom: 12rem;
}
.bottom-52 {
  bottom: 13rem;
}
.bottom-56 {
  bottom: 14rem;
}
.bottom-60 {
  bottom: 15rem;
}
.bottom-64 {
  bottom: 16rem;
}
.bottom-72 {
  bottom: 18rem;
}
.bottom-80 {
  bottom: 20rem;
}
.bottom-96 {
  bottom: 24rem;
}
.bottom-auto {
  bottom: auto;
}
.bottom-px {
  bottom: 1px;
}
.bottom-0\.5 {
  bottom: 0.125rem;
}
.bottom-1\.5 {
  bottom: 0.375rem;
}
.bottom-2\.5 {
  bottom: 0.625rem;
}
.bottom-3\.5 {
  bottom: 0.875rem;
}
.-bottom-0 {
  bottom: 0;
}
.-bottom-1 {
  bottom: -0.25rem;
}
.-bottom-2 {
  bottom: -0.5rem;
}
.-bottom-3 {
  bottom: -0.75rem;
}
.-bottom-4 {
  bottom: -1rem;
}
.-bottom-5 {
  bottom: -1.25rem;
}
.-bottom-6 {
  bottom: -1.5rem;
}
.-bottom-7 {
  bottom: -1.75rem;
}
.-bottom-8 {
  bottom: -2rem;
}
.-bottom-9 {
  bottom: -2.25rem;
}
.-bottom-10 {
  bottom: -2.5rem;
}
.-bottom-11 {
  bottom: -2.75rem;
}
.-bottom-12 {
  bottom: -3rem;
}
.-bottom-14 {
  bottom: -3.5rem;
}
.-bottom-16 {
  bottom: -4rem;
}
.-bottom-20 {
  bottom: -5rem;
}
.-bottom-24 {
  bottom: -6rem;
}
.-bottom-28 {
  bottom: -7rem;
}
.-bottom-32 {
  bottom: -8rem;
}
.-bottom-36 {
  bottom: -9rem;
}
.-bottom-40 {
  bottom: -10rem;
}
.-bottom-44 {
  bottom: -11rem;
}
.-bottom-48 {
  bottom: -12rem;
}
.-bottom-52 {
  bottom: -13rem;
}
.-bottom-56 {
  bottom: -14rem;
}
.-bottom-60 {
  bottom: -15rem;
}
.-bottom-64 {
  bottom: -16rem;
}
.-bottom-72 {
  bottom: -18rem;
}
.-bottom-80 {
  bottom: -20rem;
}
.-bottom-96 {
  bottom: -24rem;
}
.-bottom-px {
  bottom: -1px;
}
.-bottom-0\.5 {
  bottom: -0.125rem;
}
.-bottom-1\.5 {
  bottom: -0.375rem;
}
.-bottom-2\.5 {
  bottom: -0.625rem;
}
.-bottom-3\.5 {
  bottom: -0.875rem;
}
.bottom-1\/2 {
  bottom: 50%;
}
.bottom-1\/3 {
  bottom: 33.333333%;
}
.bottom-2\/3 {
  bottom: 66.666667%;
}
.bottom-1\/4 {
  bottom: 25%;
}
.bottom-2\/4 {
  bottom: 50%;
}
.bottom-3\/4 {
  bottom: 75%;
}
.bottom-full {
  bottom: 100%;
}
.-bottom-1\/2 {
  bottom: -50%;
}
.-bottom-1\/3 {
  bottom: -33.333333%;
}
.-bottom-2\/3 {
  bottom: -66.666667%;
}
.-bottom-1\/4 {
  bottom: -25%;
}
.-bottom-2\/4 {
  bottom: -50%;
}
.-bottom-3\/4 {
  bottom: -75%;
}
.-bottom-full {
  bottom: -100%;
}
.left-0 {
  left: 0;
}
.left-1 {
  left: 0.25rem;
}
.left-2 {
  left: 0.5rem;
}
.left-3 {
  left: 0.75rem;
}
.left-4 {
  left: 1rem;
}
.left-5 {
  left: 1.25rem;
}
.left-6 {
  left: 1.5rem;
}
.left-7 {
  left: 1.75rem;
}
.left-8 {
  left: 2rem;
}
.left-9 {
  left: 2.25rem;
}
.left-10 {
  left: 2.5rem;
}
.left-11 {
  left: 2.75rem;
}
.left-12 {
  left: 3rem;
}
.left-14 {
  left: 3.5rem;
}
.left-16 {
  left: 4rem;
}
.left-20 {
  left: 5rem;
}
.left-24 {
  left: 6rem;
}
.left-28 {
  left: 7rem;
}
.left-32 {
  left: 8rem;
}
.left-36 {
  left: 9rem;
}
.left-40 {
  left: 10rem;
}
.left-44 {
  left: 11rem;
}
.left-48 {
  left: 12rem;
}
.left-52 {
  left: 13rem;
}
.left-56 {
  left: 14rem;
}
.left-60 {
  left: 15rem;
}
.left-64 {
  left: 16rem;
}
.left-72 {
  left: 18rem;
}
.left-80 {
  left: 20rem;
}
.left-96 {
  left: 24rem;
}
.left-auto {
  left: auto;
}
.left-px {
  left: 1px;
}
.left-0\.5 {
  left: 0.125rem;
}
.left-1\.5 {
  left: 0.375rem;
}
.left-2\.5 {
  left: 0.625rem;
}
.left-3\.5 {
  left: 0.875rem;
}
.-left-0 {
  left: 0;
}
.-left-1 {
  left: -0.25rem;
}
.-left-2 {
  left: -0.5rem;
}
.-left-3 {
  left: -0.75rem;
}
.-left-4 {
  left: -1rem;
}
.-left-5 {
  left: -1.25rem;
}
.-left-6 {
  left: -1.5rem;
}
.-left-7 {
  left: -1.75rem;
}
.-left-8 {
  left: -2rem;
}
.-left-9 {
  left: -2.25rem;
}
.-left-10 {
  left: -2.5rem;
}
.-left-11 {
  left: -2.75rem;
}
.-left-12 {
  left: -3rem;
}
.-left-14 {
  left: -3.5rem;
}
.-left-16 {
  left: -4rem;
}
.-left-20 {
  left: -5rem;
}
.-left-24 {
  left: -6rem;
}
.-left-28 {
  left: -7rem;
}
.-left-32 {
  left: -8rem;
}
.-left-36 {
  left: -9rem;
}
.-left-40 {
  left: -10rem;
}
.-left-44 {
  left: -11rem;
}
.-left-48 {
  left: -12rem;
}
.-left-52 {
  left: -13rem;
}
.-left-56 {
  left: -14rem;
}
.-left-60 {
  left: -15rem;
}
.-left-64 {
  left: -16rem;
}
.-left-72 {
  left: -18rem;
}
.-left-80 {
  left: -20rem;
}
.-left-96 {
  left: -24rem;
}
.-left-px {
  left: -1px;
}
.-left-0\.5 {
  left: -0.125rem;
}
.-left-1\.5 {
  left: -0.375rem;
}
.-left-2\.5 {
  left: -0.625rem;
}
.-left-3\.5 {
  left: -0.875rem;
}
.left-1\/2 {
  left: 50%;
}
.left-1\/3 {
  left: 33.333333%;
}
.left-2\/3 {
  left: 66.666667%;
}
.left-1\/4 {
  left: 25%;
}
.left-2\/4 {
  left: 50%;
}
.left-3\/4 {
  left: 75%;
}
.left-full {
  left: 100%;
}
.-left-1\/2 {
  left: -50%;
}
.-left-1\/3 {
  left: -33.333333%;
}
.-left-2\/3 {
  left: -66.666667%;
}
.-left-1\/4 {
  left: -25%;
}
.-left-2\/4 {
  left: -50%;
}
.-left-3\/4 {
  left: -75%;
}
.-left-full {
  left: -100%;
}
.isolate {
  isolation: isolate;
}
.isolation-auto {
  isolation: auto;
}
.z-0 {
  z-index: 0;
}
.z-10 {
  z-index: 10;
}
.z-20 {
  z-index: 20;
}
.z-30 {
  z-index: 30;
}
.z-40 {
  z-index: 40;
}
.z-50 {
  z-index: 50;
}
.z-auto {
  z-index: auto;
}
.focus-within\:z-0:focus-within {
  z-index: 0;
}
.focus-within\:z-10:focus-within {
  z-index: 10;
}
.focus-within\:z-20:focus-within {
  z-index: 20;
}
.focus-within\:z-30:focus-within {
  z-index: 30;
}
.focus-within\:z-40:focus-within {
  z-index: 40;
}
.focus-within\:z-50:focus-within {
  z-index: 50;
}
.focus-within\:z-auto:focus-within {
  z-index: auto;
}
.focus\:z-0:focus {
  z-index: 0;
}
.focus\:z-10:focus {
  z-index: 10;
}
.focus\:z-20:focus {
  z-index: 20;
}
.focus\:z-30:focus {
  z-index: 30;
}
.focus\:z-40:focus {
  z-index: 40;
}
.focus\:z-50:focus {
  z-index: 50;
}
.focus\:z-auto:focus {
  z-index: auto;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}
.order-4 {
  order: 4;
}
.order-5 {
  order: 5;
}
.order-6 {
  order: 6;
}
.order-7 {
  order: 7;
}
.order-8 {
  order: 8;
}
.order-9 {
  order: 9;
}
.order-10 {
  order: 10;
}
.order-11 {
  order: 11;
}
.order-12 {
  order: 12;
}
.order-first {
  order: -9999;
}
.order-last {
  order: 9999;
}
.order-none {
  order: 0;
}
.col-auto {
  grid-column: auto;
}
.col-span-1 {
  grid-column: span 1 / span 1;
}
.col-span-2 {
  grid-column: span 2 / span 2;
}
.col-span-3 {
  grid-column: span 3 / span 3;
}
.col-span-4 {
  grid-column: span 4 / span 4;
}
.col-span-5 {
  grid-column: span 5 / span 5;
}
.col-span-6 {
  grid-column: span 6 / span 6;
}
.col-span-7 {
  grid-column: span 7 / span 7;
}
.col-span-8 {
  grid-column: span 8 / span 8;
}
.col-span-9 {
  grid-column: span 9 / span 9;
}
.col-span-10 {
  grid-column: span 10 / span 10;
}
.col-span-11 {
  grid-column: span 11 / span 11;
}
.col-span-12 {
  grid-column: span 12 / span 12;
}
.col-span-full {
  grid-column: 1/-1;
}
.col-start-1 {
  grid-column-start: 1;
}
.col-start-2 {
  grid-column-start: 2;
}
.col-start-3 {
  grid-column-start: 3;
}
.col-start-4 {
  grid-column-start: 4;
}
.col-start-5 {
  grid-column-start: 5;
}
.col-start-6 {
  grid-column-start: 6;
}
.col-start-7 {
  grid-column-start: 7;
}
.col-start-8 {
  grid-column-start: 8;
}
.col-start-9 {
  grid-column-start: 9;
}
.col-start-10 {
  grid-column-start: 10;
}
.col-start-11 {
  grid-column-start: 11;
}
.col-start-12 {
  grid-column-start: 12;
}
.col-start-13 {
  grid-column-start: 13;
}
.col-start-auto {
  grid-column-start: auto;
}
.col-end-1 {
  grid-column-end: 1;
}
.col-end-2 {
  grid-column-end: 2;
}
.col-end-3 {
  grid-column-end: 3;
}
.col-end-4 {
  grid-column-end: 4;
}
.col-end-5 {
  grid-column-end: 5;
}
.col-end-6 {
  grid-column-end: 6;
}
.col-end-7 {
  grid-column-end: 7;
}
.col-end-8 {
  grid-column-end: 8;
}
.col-end-9 {
  grid-column-end: 9;
}
.col-end-10 {
  grid-column-end: 10;
}
.col-end-11 {
  grid-column-end: 11;
}
.col-end-12 {
  grid-column-end: 12;
}
.col-end-13 {
  grid-column-end: 13;
}
.col-end-auto {
  grid-column-end: auto;
}
.row-auto {
  grid-row: auto;
}
.row-span-1 {
  grid-row: span 1 / span 1;
}
.row-span-2 {
  grid-row: span 2 / span 2;
}
.row-span-3 {
  grid-row: span 3 / span 3;
}
.row-span-4 {
  grid-row: span 4 / span 4;
}
.row-span-5 {
  grid-row: span 5 / span 5;
}
.row-span-6 {
  grid-row: span 6 / span 6;
}
.row-span-full {
  grid-row: 1/-1;
}
.row-start-1 {
  grid-row-start: 1;
}
.row-start-2 {
  grid-row-start: 2;
}
.row-start-3 {
  grid-row-start: 3;
}
.row-start-4 {
  grid-row-start: 4;
}
.row-start-5 {
  grid-row-start: 5;
}
.row-start-6 {
  grid-row-start: 6;
}
.row-start-7 {
  grid-row-start: 7;
}
.row-start-auto {
  grid-row-start: auto;
}
.row-end-1 {
  grid-row-end: 1;
}
.row-end-2 {
  grid-row-end: 2;
}
.row-end-3 {
  grid-row-end: 3;
}
.row-end-4 {
  grid-row-end: 4;
}
.row-end-5 {
  grid-row-end: 5;
}
.row-end-6 {
  grid-row-end: 6;
}
.row-end-7 {
  grid-row-end: 7;
}
.row-end-auto {
  grid-row-end: auto;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}
.float-none {
  float: none;
}
.clear-left {
  clear: left;
}
.clear-right {
  clear: right;
}
.clear-both {
  clear: both;
}
.clear-none {
  clear: none;
}
.m-0 {
  margin: 0;
}
.m-1 {
  margin: 0.25rem;
}
.m-2 {
  margin: 0.5rem;
}
.m-3 {
  margin: 0.75rem;
}
.m-4 {
  margin: 1rem;
}
.m-5 {
  margin: 1.25rem;
}
.m-6 {
  margin: 1.5rem;
}
.m-7 {
  margin: 1.75rem;
}
.m-8 {
  margin: 2rem;
}
.m-9 {
  margin: 2.25rem;
}
.m-10 {
  margin: 2.5rem;
}
.m-11 {
  margin: 2.75rem;
}
.m-12 {
  margin: 3rem;
}
.m-14 {
  margin: 3.5rem;
}
.m-16 {
  margin: 4rem;
}
.m-20 {
  margin: 5rem;
}
.m-24 {
  margin: 6rem;
}
.m-28 {
  margin: 7rem;
}
.m-32 {
  margin: 8rem;
}
.m-36 {
  margin: 9rem;
}
.m-40 {
  margin: 10rem;
}
.m-44 {
  margin: 11rem;
}
.m-48 {
  margin: 12rem;
}
.m-52 {
  margin: 13rem;
}
.m-56 {
  margin: 14rem;
}
.m-60 {
  margin: 15rem;
}
.m-64 {
  margin: 16rem;
}
.m-72 {
  margin: 18rem;
}
.m-80 {
  margin: 20rem;
}
.m-96 {
  margin: 24rem;
}
.m-auto {
  margin: auto;
}
.m-px {
  margin: 1px;
}
.m-0\.5 {
  margin: 0.125rem;
}
.m-1\.5 {
  margin: 0.375rem;
}
.m-2\.5 {
  margin: 0.625rem;
}
.m-3\.5 {
  margin: 0.875rem;
}
.-m-0 {
  margin: 0;
}
.-m-1 {
  margin: -0.25rem;
}
.-m-2 {
  margin: -0.5rem;
}
.-m-3 {
  margin: -0.75rem;
}
.-m-4 {
  margin: -1rem;
}
.-m-5 {
  margin: -1.25rem;
}
.-m-6 {
  margin: -1.5rem;
}
.-m-7 {
  margin: -1.75rem;
}
.-m-8 {
  margin: -2rem;
}
.-m-9 {
  margin: -2.25rem;
}
.-m-10 {
  margin: -2.5rem;
}
.-m-11 {
  margin: -2.75rem;
}
.-m-12 {
  margin: -3rem;
}
.-m-14 {
  margin: -3.5rem;
}
.-m-16 {
  margin: -4rem;
}
.-m-20 {
  margin: -5rem;
}
.-m-24 {
  margin: -6rem;
}
.-m-28 {
  margin: -7rem;
}
.-m-32 {
  margin: -8rem;
}
.-m-36 {
  margin: -9rem;
}
.-m-40 {
  margin: -10rem;
}
.-m-44 {
  margin: -11rem;
}
.-m-48 {
  margin: -12rem;
}
.-m-52 {
  margin: -13rem;
}
.-m-56 {
  margin: -14rem;
}
.-m-60 {
  margin: -15rem;
}
.-m-64 {
  margin: -16rem;
}
.-m-72 {
  margin: -18rem;
}
.-m-80 {
  margin: -20rem;
}
.-m-96 {
  margin: -24rem;
}
.-m-px {
  margin: -1px;
}
.-m-0\.5 {
  margin: -0.125rem;
}
.-m-1\.5 {
  margin: -0.375rem;
}
.-m-2\.5 {
  margin: -0.625rem;
}
.-m-3\.5 {
  margin: -0.875rem;
}
.mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}
.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}
.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}
.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}
.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}
.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}
.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem;
}
.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}
.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem;
}
.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem;
}
.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}
.mx-24 {
  margin-left: 6rem;
  margin-right: 6rem;
}
.mx-28 {
  margin-left: 7rem;
  margin-right: 7rem;
}
.mx-32 {
  margin-left: 8rem;
  margin-right: 8rem;
}
.mx-36 {
  margin-left: 9rem;
  margin-right: 9rem;
}
.mx-40 {
  margin-left: 10rem;
  margin-right: 10rem;
}
.mx-44 {
  margin-left: 11rem;
  margin-right: 11rem;
}
.mx-48 {
  margin-left: 12rem;
  margin-right: 12rem;
}
.mx-52 {
  margin-left: 13rem;
  margin-right: 13rem;
}
.mx-56 {
  margin-left: 14rem;
  margin-right: 14rem;
}
.mx-60 {
  margin-left: 15rem;
  margin-right: 15rem;
}
.mx-64 {
  margin-left: 16rem;
  margin-right: 16rem;
}
.mx-72 {
  margin-left: 18rem;
  margin-right: 18rem;
}
.mx-80 {
  margin-left: 20rem;
  margin-right: 20rem;
}
.mx-96 {
  margin-left: 24rem;
  margin-right: 24rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.mx-px {
  margin-left: 1px;
  margin-right: 1px;
}
.mx-0\.5 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}
.mx-1\.5 {
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}
.mx-2\.5 {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}
.mx-3\.5 {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
}
.-mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.-mx-1 {
  margin-left: -0.25rem;
  margin-right: -0.25rem;
}
.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.-mx-3 {
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}
.-mx-5 {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}
.-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}
.-mx-7 {
  margin-left: -1.75rem;
  margin-right: -1.75rem;
}
.-mx-8 {
  margin-left: -2rem;
  margin-right: -2rem;
}
.-mx-9 {
  margin-left: -2.25rem;
  margin-right: -2.25rem;
}
.-mx-10 {
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}
.-mx-11 {
  margin-left: -2.75rem;
  margin-right: -2.75rem;
}
.-mx-12 {
  margin-left: -3rem;
  margin-right: -3rem;
}
.-mx-14 {
  margin-left: -3.5rem;
  margin-right: -3.5rem;
}
.-mx-16 {
  margin-left: -4rem;
  margin-right: -4rem;
}
.-mx-20 {
  margin-left: -5rem;
  margin-right: -5rem;
}
.-mx-24 {
  margin-left: -6rem;
  margin-right: -6rem;
}
.-mx-28 {
  margin-left: -7rem;
  margin-right: -7rem;
}
.-mx-32 {
  margin-left: -8rem;
  margin-right: -8rem;
}
.-mx-36 {
  margin-left: -9rem;
  margin-right: -9rem;
}
.-mx-40 {
  margin-left: -10rem;
  margin-right: -10rem;
}
.-mx-44 {
  margin-left: -11rem;
  margin-right: -11rem;
}
.-mx-48 {
  margin-left: -12rem;
  margin-right: -12rem;
}
.-mx-52 {
  margin-left: -13rem;
  margin-right: -13rem;
}
.-mx-56 {
  margin-left: -14rem;
  margin-right: -14rem;
}
.-mx-60 {
  margin-left: -15rem;
  margin-right: -15rem;
}
.-mx-64 {
  margin-left: -16rem;
  margin-right: -16rem;
}
.-mx-72 {
  margin-left: -18rem;
  margin-right: -18rem;
}
.-mx-80 {
  margin-left: -20rem;
  margin-right: -20rem;
}
.-mx-96 {
  margin-left: -24rem;
  margin-right: -24rem;
}
.-mx-px {
  margin-left: -1px;
  margin-right: -1px;
}
.-mx-0\.5 {
  margin-left: -0.125rem;
  margin-right: -0.125rem;
}
.-mx-1\.5 {
  margin-left: -0.375rem;
  margin-right: -0.375rem;
}
.-mx-2\.5 {
  margin-left: -0.625rem;
  margin-right: -0.625rem;
}
.-mx-3\.5 {
  margin-left: -0.875rem;
  margin-right: -0.875rem;
}
.my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}
.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}
.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}
.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem;
}
.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}
.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}
.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.my-24 {
  margin-top: 6rem;
  margin-bottom: 6rem;
}
.my-28 {
  margin-top: 7rem;
  margin-bottom: 7rem;
}
.my-32 {
  margin-top: 8rem;
  margin-bottom: 8rem;
}
.my-36 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}
.my-40 {
  margin-top: 10rem;
  margin-bottom: 10rem;
}
.my-44 {
  margin-top: 11rem;
  margin-bottom: 11rem;
}
.my-48 {
  margin-top: 12rem;
  margin-bottom: 12rem;
}
.my-52 {
  margin-top: 13rem;
  margin-bottom: 13rem;
}
.my-56 {
  margin-top: 14rem;
  margin-bottom: 14rem;
}
.my-60 {
  margin-top: 15rem;
  margin-bottom: 15rem;
}
.my-64 {
  margin-top: 16rem;
  margin-bottom: 16rem;
}
.my-72 {
  margin-top: 18rem;
  margin-bottom: 18rem;
}
.my-80 {
  margin-top: 20rem;
  margin-bottom: 20rem;
}
.my-96 {
  margin-top: 24rem;
  margin-bottom: 24rem;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.my-px {
  margin-top: 1px;
  margin-bottom: 1px;
}
.my-0\.5 {
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
.my-1\.5 {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}
.my-2\.5 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}
.my-3\.5 {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}
.-my-0 {
  margin-top: 0;
  margin-bottom: 0;
}
.-my-1 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}
.-my-2 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
.-my-3 {
  margin-top: -0.75rem;
  margin-bottom: -0.75rem;
}
.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}
.-my-5 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
}
.-my-6 {
  margin-top: -1.5rem;
  margin-bottom: -1.5rem;
}
.-my-7 {
  margin-top: -1.75rem;
  margin-bottom: -1.75rem;
}
.-my-8 {
  margin-top: -2rem;
  margin-bottom: -2rem;
}
.-my-9 {
  margin-top: -2.25rem;
  margin-bottom: -2.25rem;
}
.-my-10 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem;
}
.-my-11 {
  margin-top: -2.75rem;
  margin-bottom: -2.75rem;
}
.-my-12 {
  margin-top: -3rem;
  margin-bottom: -3rem;
}
.-my-14 {
  margin-top: -3.5rem;
  margin-bottom: -3.5rem;
}
.-my-16 {
  margin-top: -4rem;
  margin-bottom: -4rem;
}
.-my-20 {
  margin-top: -5rem;
  margin-bottom: -5rem;
}
.-my-24 {
  margin-top: -6rem;
  margin-bottom: -6rem;
}
.-my-28 {
  margin-top: -7rem;
  margin-bottom: -7rem;
}
.-my-32 {
  margin-top: -8rem;
  margin-bottom: -8rem;
}
.-my-36 {
  margin-top: -9rem;
  margin-bottom: -9rem;
}
.-my-40 {
  margin-top: -10rem;
  margin-bottom: -10rem;
}
.-my-44 {
  margin-top: -11rem;
  margin-bottom: -11rem;
}
.-my-48 {
  margin-top: -12rem;
  margin-bottom: -12rem;
}
.-my-52 {
  margin-top: -13rem;
  margin-bottom: -13rem;
}
.-my-56 {
  margin-top: -14rem;
  margin-bottom: -14rem;
}
.-my-60 {
  margin-top: -15rem;
  margin-bottom: -15rem;
}
.-my-64 {
  margin-top: -16rem;
  margin-bottom: -16rem;
}
.-my-72 {
  margin-top: -18rem;
  margin-bottom: -18rem;
}
.-my-80 {
  margin-top: -20rem;
  margin-bottom: -20rem;
}
.-my-96 {
  margin-top: -24rem;
  margin-bottom: -24rem;
}
.-my-px {
  margin-top: -1px;
  margin-bottom: -1px;
}
.-my-0\.5 {
  margin-top: -0.125rem;
  margin-bottom: -0.125rem;
}
.-my-1\.5 {
  margin-top: -0.375rem;
  margin-bottom: -0.375rem;
}
.-my-2\.5 {
  margin-top: -0.625rem;
  margin-bottom: -0.625rem;
}
.-my-3\.5 {
  margin-top: -0.875rem;
  margin-bottom: -0.875rem;
}
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mt-2 {
  margin-top: 0.5rem;
}
.mt-3 {
  margin-top: 0.75rem;
}
.mt-4 {
  margin-top: 1rem;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.mt-7 {
  margin-top: 1.75rem;
}
.mt-8 {
  margin-top: 2rem;
}
.mt-9 {
  margin-top: 2.25rem;
}
.mt-10 {
  margin-top: 2.5rem;
}
.mt-11 {
  margin-top: 2.75rem;
}
.mt-12 {
  margin-top: 3rem;
}
.mt-14 {
  margin-top: 3.5rem;
}
.mt-16 {
  margin-top: 4rem;
}
.mt-20 {
  margin-top: 5rem;
}
.mt-24 {
  margin-top: 6rem;
}
.mt-28 {
  margin-top: 7rem;
}
.mt-32 {
  margin-top: 8rem;
}
.mt-36 {
  margin-top: 9rem;
}
.mt-40 {
  margin-top: 10rem;
}
.mt-44 {
  margin-top: 11rem;
}
.mt-48 {
  margin-top: 12rem;
}
.mt-52 {
  margin-top: 13rem;
}
.mt-56 {
  margin-top: 14rem;
}
.mt-60 {
  margin-top: 15rem;
}
.mt-64 {
  margin-top: 16rem;
}
.mt-72 {
  margin-top: 18rem;
}
.mt-80 {
  margin-top: 20rem;
}
.mt-96 {
  margin-top: 24rem;
}
.mt-auto {
  margin-top: auto;
}
.mt-px {
  margin-top: 1px;
}
.mt-0\.5 {
  margin-top: 0.125rem;
}
.mt-1\.5 {
  margin-top: 0.375rem;
}
.mt-2\.5 {
  margin-top: 0.625rem;
}
.mt-3\.5 {
  margin-top: 0.875rem;
}
.-mt-0 {
  margin-top: 0;
}
.-mt-1 {
  margin-top: -0.25rem;
}
.-mt-2 {
  margin-top: -0.5rem;
}
.-mt-3 {
  margin-top: -0.75rem;
}
.-mt-4 {
  margin-top: -1rem;
}
.-mt-5 {
  margin-top: -1.25rem;
}
.-mt-6 {
  margin-top: -1.5rem;
}
.-mt-7 {
  margin-top: -1.75rem;
}
.-mt-8 {
  margin-top: -2rem;
}
.-mt-9 {
  margin-top: -2.25rem;
}
.-mt-10 {
  margin-top: -2.5rem;
}
.-mt-11 {
  margin-top: -2.75rem;
}
.-mt-12 {
  margin-top: -3rem;
}
.-mt-14 {
  margin-top: -3.5rem;
}
.-mt-16 {
  margin-top: -4rem;
}
.-mt-20 {
  margin-top: -5rem;
}
.-mt-24 {
  margin-top: -6rem;
}
.-mt-28 {
  margin-top: -7rem;
}
.-mt-32 {
  margin-top: -8rem;
}
.-mt-36 {
  margin-top: -9rem;
}
.-mt-40 {
  margin-top: -10rem;
}
.-mt-44 {
  margin-top: -11rem;
}
.-mt-48 {
  margin-top: -12rem;
}
.-mt-52 {
  margin-top: -13rem;
}
.-mt-56 {
  margin-top: -14rem;
}
.-mt-60 {
  margin-top: -15rem;
}
.-mt-64 {
  margin-top: -16rem;
}
.-mt-72 {
  margin-top: -18rem;
}
.-mt-80 {
  margin-top: -20rem;
}
.-mt-96 {
  margin-top: -24rem;
}
.-mt-px {
  margin-top: -1px;
}
.-mt-0\.5 {
  margin-top: -0.125rem;
}
.-mt-1\.5 {
  margin-top: -0.375rem;
}
.-mt-2\.5 {
  margin-top: -0.625rem;
}
.-mt-3\.5 {
  margin-top: -0.875rem;
}
.mr-0 {
  margin-right: 0;
}
.mr-1 {
  margin-right: 0.25rem;
}
.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}
.mr-4 {
  margin-right: 1rem;
}
.mr-5 {
  margin-right: 1.25rem;
}
.mr-6 {
  margin-right: 1.5rem;
}
.mr-7 {
  margin-right: 1.75rem;
}
.mr-8 {
  margin-right: 2rem;
}
.mr-9 {
  margin-right: 2.25rem;
}
.mr-10 {
  margin-right: 2.5rem;
}
.mr-11 {
  margin-right: 2.75rem;
}
.mr-12 {
  margin-right: 3rem;
}
.mr-14 {
  margin-right: 3.5rem;
}
.mr-16 {
  margin-right: 4rem;
}
.mr-20 {
  margin-right: 5rem;
}
.mr-24 {
  margin-right: 6rem;
}
.mr-28 {
  margin-right: 7rem;
}
.mr-32 {
  margin-right: 8rem;
}
.mr-36 {
  margin-right: 9rem;
}
.mr-40 {
  margin-right: 10rem;
}
.mr-44 {
  margin-right: 11rem;
}
.mr-48 {
  margin-right: 12rem;
}
.mr-52 {
  margin-right: 13rem;
}
.mr-56 {
  margin-right: 14rem;
}
.mr-60 {
  margin-right: 15rem;
}
.mr-64 {
  margin-right: 16rem;
}
.mr-72 {
  margin-right: 18rem;
}
.mr-80 {
  margin-right: 20rem;
}
.mr-96 {
  margin-right: 24rem;
}
.mr-auto {
  margin-right: auto;
}
.mr-px {
  margin-right: 1px;
}
.mr-0\.5 {
  margin-right: 0.125rem;
}
.mr-1\.5 {
  margin-right: 0.375rem;
}
.mr-2\.5 {
  margin-right: 0.625rem;
}
.mr-3\.5 {
  margin-right: 0.875rem;
}
.-mr-0 {
  margin-right: 0;
}
.-mr-1 {
  margin-right: -0.25rem;
}
.-mr-2 {
  margin-right: -0.5rem;
}
.-mr-3 {
  margin-right: -0.75rem;
}
.-mr-4 {
  margin-right: -1rem;
}
.-mr-5 {
  margin-right: -1.25rem;
}
.-mr-6 {
  margin-right: -1.5rem;
}
.-mr-7 {
  margin-right: -1.75rem;
}
.-mr-8 {
  margin-right: -2rem;
}
.-mr-9 {
  margin-right: -2.25rem;
}
.-mr-10 {
  margin-right: -2.5rem;
}
.-mr-11 {
  margin-right: -2.75rem;
}
.-mr-12 {
  margin-right: -3rem;
}
.-mr-14 {
  margin-right: -3.5rem;
}
.-mr-16 {
  margin-right: -4rem;
}
.-mr-20 {
  margin-right: -5rem;
}
.-mr-24 {
  margin-right: -6rem;
}
.-mr-28 {
  margin-right: -7rem;
}
.-mr-32 {
  margin-right: -8rem;
}
.-mr-36 {
  margin-right: -9rem;
}
.-mr-40 {
  margin-right: -10rem;
}
.-mr-44 {
  margin-right: -11rem;
}
.-mr-48 {
  margin-right: -12rem;
}
.-mr-52 {
  margin-right: -13rem;
}
.-mr-56 {
  margin-right: -14rem;
}
.-mr-60 {
  margin-right: -15rem;
}
.-mr-64 {
  margin-right: -16rem;
}
.-mr-72 {
  margin-right: -18rem;
}
.-mr-80 {
  margin-right: -20rem;
}
.-mr-96 {
  margin-right: -24rem;
}
.-mr-px {
  margin-right: -1px;
}
.-mr-0\.5 {
  margin-right: -0.125rem;
}
.-mr-1\.5 {
  margin-right: -0.375rem;
}
.-mr-2\.5 {
  margin-right: -0.625rem;
}
.-mr-3\.5 {
  margin-right: -0.875rem;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-2 {
  margin-bottom: 0.5rem;
}
.mb-3 {
  margin-bottom: 0.75rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mb-5 {
  margin-bottom: 1.25rem;
}
.mb-6 {
  margin-bottom: 1.5rem;
}
.mb-7 {
  margin-bottom: 1.75rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mb-9 {
  margin-bottom: 2.25rem;
}
.mb-10 {
  margin-bottom: 2.5rem;
}
.mb-11 {
  margin-bottom: 2.75rem;
}
.mb-12 {
  margin-bottom: 3rem;
}
.mb-14 {
  margin-bottom: 3.5rem;
}
.mb-16 {
  margin-bottom: 4rem;
}
.mb-20 {
  margin-bottom: 5rem;
}
.mb-24 {
  margin-bottom: 6rem;
}
.mb-28 {
  margin-bottom: 7rem;
}
.mb-32 {
  margin-bottom: 8rem;
}
.mb-36 {
  margin-bottom: 9rem;
}
.mb-40 {
  margin-bottom: 10rem;
}
.mb-44 {
  margin-bottom: 11rem;
}
.mb-48 {
  margin-bottom: 12rem;
}
.mb-52 {
  margin-bottom: 13rem;
}
.mb-56 {
  margin-bottom: 14rem;
}
.mb-60 {
  margin-bottom: 15rem;
}
.mb-64 {
  margin-bottom: 16rem;
}
.mb-72 {
  margin-bottom: 18rem;
}
.mb-80 {
  margin-bottom: 20rem;
}
.mb-96 {
  margin-bottom: 24rem;
}
.mb-auto {
  margin-bottom: auto;
}
.mb-px {
  margin-bottom: 1px;
}
.mb-0\.5 {
  margin-bottom: 0.125rem;
}
.mb-1\.5 {
  margin-bottom: 0.375rem;
}
.mb-2\.5 {
  margin-bottom: 0.625rem;
}
.mb-3\.5 {
  margin-bottom: 0.875rem;
}
.-mb-0 {
  margin-bottom: 0;
}
.-mb-1 {
  margin-bottom: -0.25rem;
}
.-mb-2 {
  margin-bottom: -0.5rem;
}
.-mb-3 {
  margin-bottom: -0.75rem;
}
.-mb-4 {
  margin-bottom: -1rem;
}
.-mb-5 {
  margin-bottom: -1.25rem;
}
.-mb-6 {
  margin-bottom: -1.5rem;
}
.-mb-7 {
  margin-bottom: -1.75rem;
}
.-mb-8 {
  margin-bottom: -2rem;
}
.-mb-9 {
  margin-bottom: -2.25rem;
}
.-mb-10 {
  margin-bottom: -2.5rem;
}
.-mb-11 {
  margin-bottom: -2.75rem;
}
.-mb-12 {
  margin-bottom: -3rem;
}
.-mb-14 {
  margin-bottom: -3.5rem;
}
.-mb-16 {
  margin-bottom: -4rem;
}
.-mb-20 {
  margin-bottom: -5rem;
}
.-mb-24 {
  margin-bottom: -6rem;
}
.-mb-28 {
  margin-bottom: -7rem;
}
.-mb-32 {
  margin-bottom: -8rem;
}
.-mb-36 {
  margin-bottom: -9rem;
}
.-mb-40 {
  margin-bottom: -10rem;
}
.-mb-44 {
  margin-bottom: -11rem;
}
.-mb-48 {
  margin-bottom: -12rem;
}
.-mb-52 {
  margin-bottom: -13rem;
}
.-mb-56 {
  margin-bottom: -14rem;
}
.-mb-60 {
  margin-bottom: -15rem;
}
.-mb-64 {
  margin-bottom: -16rem;
}
.-mb-72 {
  margin-bottom: -18rem;
}
.-mb-80 {
  margin-bottom: -20rem;
}
.-mb-96 {
  margin-bottom: -24rem;
}
.-mb-px {
  margin-bottom: -1px;
}
.-mb-0\.5 {
  margin-bottom: -0.125rem;
}
.-mb-1\.5 {
  margin-bottom: -0.375rem;
}
.-mb-2\.5 {
  margin-bottom: -0.625rem;
}
.-mb-3\.5 {
  margin-bottom: -0.875rem;
}
.ml-0 {
  margin-left: 0;
}
.ml-1 {
  margin-left: 0.25rem;
}
.ml-2 {
  margin-left: 0.5rem;
}
.ml-3 {
  margin-left: 0.75rem;
}
.ml-4 {
  margin-left: 1rem;
}
.ml-5 {
  margin-left: 1.25rem;
}
.ml-6 {
  margin-left: 1.5rem;
}
.ml-7 {
  margin-left: 1.75rem;
}
.ml-8 {
  margin-left: 2rem;
}
.ml-9 {
  margin-left: 2.25rem;
}
.ml-10 {
  margin-left: 2.5rem;
}
.ml-11 {
  margin-left: 2.75rem;
}
.ml-12 {
  margin-left: 3rem;
}
.ml-14 {
  margin-left: 3.5rem;
}
.ml-16 {
  margin-left: 4rem;
}
.ml-20 {
  margin-left: 5rem;
}
.ml-24 {
  margin-left: 6rem;
}
.ml-28 {
  margin-left: 7rem;
}
.ml-32 {
  margin-left: 8rem;
}
.ml-36 {
  margin-left: 9rem;
}
.ml-40 {
  margin-left: 10rem;
}
.ml-44 {
  margin-left: 11rem;
}
.ml-48 {
  margin-left: 12rem;
}
.ml-52 {
  margin-left: 13rem;
}
.ml-56 {
  margin-left: 14rem;
}
.ml-60 {
  margin-left: 15rem;
}
.ml-64 {
  margin-left: 16rem;
}
.ml-72 {
  margin-left: 18rem;
}
.ml-80 {
  margin-left: 20rem;
}
.ml-96 {
  margin-left: 24rem;
}
.ml-auto {
  margin-left: auto;
}
.ml-px {
  margin-left: 1px;
}
.ml-0\.5 {
  margin-left: 0.125rem;
}
.ml-1\.5 {
  margin-left: 0.375rem;
}
.ml-2\.5 {
  margin-left: 0.625rem;
}
.ml-3\.5 {
  margin-left: 0.875rem;
}
.-ml-0 {
  margin-left: 0;
}
.-ml-1 {
  margin-left: -0.25rem;
}
.-ml-2 {
  margin-left: -0.5rem;
}
.-ml-3 {
  margin-left: -0.75rem;
}
.-ml-4 {
  margin-left: -1rem;
}
.-ml-5 {
  margin-left: -1.25rem;
}
.-ml-6 {
  margin-left: -1.5rem;
}
.-ml-7 {
  margin-left: -1.75rem;
}
.-ml-8 {
  margin-left: -2rem;
}
.-ml-9 {
  margin-left: -2.25rem;
}
.-ml-10 {
  margin-left: -2.5rem;
}
.-ml-11 {
  margin-left: -2.75rem;
}
.-ml-12 {
  margin-left: -3rem;
}
.-ml-14 {
  margin-left: -3.5rem;
}
.-ml-16 {
  margin-left: -4rem;
}
.-ml-20 {
  margin-left: -5rem;
}
.-ml-24 {
  margin-left: -6rem;
}
.-ml-28 {
  margin-left: -7rem;
}
.-ml-32 {
  margin-left: -8rem;
}
.-ml-36 {
  margin-left: -9rem;
}
.-ml-40 {
  margin-left: -10rem;
}
.-ml-44 {
  margin-left: -11rem;
}
.-ml-48 {
  margin-left: -12rem;
}
.-ml-52 {
  margin-left: -13rem;
}
.-ml-56 {
  margin-left: -14rem;
}
.-ml-60 {
  margin-left: -15rem;
}
.-ml-64 {
  margin-left: -16rem;
}
.-ml-72 {
  margin-left: -18rem;
}
.-ml-80 {
  margin-left: -20rem;
}
.-ml-96 {
  margin-left: -24rem;
}
.-ml-px {
  margin-left: -1px;
}
.-ml-0\.5 {
  margin-left: -0.125rem;
}
.-ml-1\.5 {
  margin-left: -0.375rem;
}
.-ml-2\.5 {
  margin-left: -0.625rem;
}
.-ml-3\.5 {
  margin-left: -0.875rem;
}
.box-border {
  box-sizing: border-box;
}
.box-content {
  box-sizing: content-box;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.inline {
  display: inline;
}
.flex {
  display: flex;
}
.inline-flex {
  display: inline-flex;
}
.table {
  display: table;
}
.inline-table {
  display: inline-table;
}
.table-caption {
  display: table-caption;
}
.table-cell {
  display: table-cell;
}
.table-column {
  display: table-column;
}
.table-column-group {
  display: table-column-group;
}
.table-footer-group {
  display: table-footer-group;
}
.table-header-group {
  display: table-header-group;
}
.table-row-group {
  display: table-row-group;
}
.table-row {
  display: table-row;
}
.flow-root {
  display: flow-root;
}
.grid {
  display: grid;
}
.inline-grid {
  display: inline-grid;
}
.contents {
  display: contents;
}
.list-item {
  display: list-item;
}
.hidden {
  display: none;
}
.h-0 {
  height: 0;
}
.h-1 {
  height: 0.25rem;
}
.h-2 {
  height: 0.5rem;
}
.h-3 {
  height: 0.75rem;
}
.h-4 {
  height: 1rem;
}
.h-5 {
  height: 1.25rem;
}
.h-6 {
  height: 1.5rem;
}
.h-7 {
  height: 1.75rem;
}
.h-8 {
  height: 2rem;
}
.h-9 {
  height: 2.25rem;
}
.h-10 {
  height: 2.5rem;
}
.h-11 {
  height: 2.75rem;
}
.h-12 {
  height: 3rem;
}
.h-14 {
  height: 3.5rem;
}
.h-16 {
  height: 4rem;
}
.h-20 {
  height: 5rem;
}
.h-24 {
  height: 6rem;
}
.h-28 {
  height: 7rem;
}
.h-32 {
  height: 8rem;
}
.h-36 {
  height: 9rem;
}
.h-40 {
  height: 10rem;
}
.h-44 {
  height: 11rem;
}
.h-48 {
  height: 12rem;
}
.h-52 {
  height: 13rem;
}
.h-56 {
  height: 14rem;
}
.h-60 {
  height: 15rem;
}
.h-64 {
  height: 16rem;
}
.h-72 {
  height: 18rem;
}
.h-80 {
  height: 20rem;
}
.h-96 {
  height: 24rem;
}
.h-auto {
  height: auto;
}
.h-px {
  height: 1px;
}
.h-0\.5 {
  height: 0.125rem;
}
.h-1\.5 {
  height: 0.375rem;
}
.h-2\.5 {
  height: 0.625rem;
}
.h-3\.5 {
  height: 0.875rem;
}
.h-1\/2 {
  height: 50%;
}
.h-1\/3 {
  height: 33.333333%;
}
.h-2\/3 {
  height: 66.666667%;
}
.h-1\/4 {
  height: 25%;
}
.h-2\/4 {
  height: 50%;
}
.h-3\/4 {
  height: 75%;
}
.h-1\/5 {
  height: 20%;
}
.h-2\/5 {
  height: 40%;
}
.h-3\/5 {
  height: 60%;
}
.h-4\/5 {
  height: 80%;
}
.h-1\/6 {
  height: 16.666667%;
}
.h-2\/6 {
  height: 33.333333%;
}
.h-3\/6 {
  height: 50%;
}
.h-4\/6 {
  height: 66.666667%;
}
.h-5\/6 {
  height: 83.333333%;
}
.h-full {
  height: 100%;
}
.h-screen {
  height: 100vh;
}
.max-h-0 {
  max-height: 0;
}
.max-h-1 {
  max-height: 0.25rem;
}
.max-h-2 {
  max-height: 0.5rem;
}
.max-h-3 {
  max-height: 0.75rem;
}
.max-h-4 {
  max-height: 1rem;
}
.max-h-5 {
  max-height: 1.25rem;
}
.max-h-6 {
  max-height: 1.5rem;
}
.max-h-7 {
  max-height: 1.75rem;
}
.max-h-8 {
  max-height: 2rem;
}
.max-h-9 {
  max-height: 2.25rem;
}
.max-h-10 {
  max-height: 2.5rem;
}
.max-h-11 {
  max-height: 2.75rem;
}
.max-h-12 {
  max-height: 3rem;
}
.max-h-14 {
  max-height: 3.5rem;
}
.max-h-16 {
  max-height: 4rem;
}
.max-h-20 {
  max-height: 5rem;
}
.max-h-24 {
  max-height: 6rem;
}
.max-h-28 {
  max-height: 7rem;
}
.max-h-32 {
  max-height: 8rem;
}
.max-h-36 {
  max-height: 9rem;
}
.max-h-40 {
  max-height: 10rem;
}
.max-h-44 {
  max-height: 11rem;
}
.max-h-48 {
  max-height: 12rem;
}
.max-h-52 {
  max-height: 13rem;
}
.max-h-56 {
  max-height: 14rem;
}
.max-h-60 {
  max-height: 15rem;
}
.max-h-64 {
  max-height: 16rem;
}
.max-h-72 {
  max-height: 18rem;
}
.max-h-80 {
  max-height: 20rem;
}
.max-h-96 {
  max-height: 24rem;
}
.max-h-px {
  max-height: 1px;
}
.max-h-0\.5 {
  max-height: 0.125rem;
}
.max-h-1\.5 {
  max-height: 0.375rem;
}
.max-h-2\.5 {
  max-height: 0.625rem;
}
.max-h-3\.5 {
  max-height: 0.875rem;
}
.max-h-full {
  max-height: 100%;
}
.max-h-screen {
  max-height: 100vh;
}
.min-h-0 {
  min-height: 0;
}
.min-h-full {
  min-height: 100%;
}
.min-h-screen {
  min-height: 100vh;
}
.w-0 {
  width: 0;
}
.w-1 {
  width: 0.25rem;
}
.w-2 {
  width: 0.5rem;
}
.w-3 {
  width: 0.75rem;
}
.w-4 {
  width: 1rem;
}
.w-5 {
  width: 1.25rem;
}
.w-6 {
  width: 1.5rem;
}
.w-7 {
  width: 1.75rem;
}
.w-8 {
  width: 2rem;
}
.w-9 {
  width: 2.25rem;
}
.w-10 {
  width: 2.5rem;
}
.w-11 {
  width: 2.75rem;
}
.w-12 {
  width: 3rem;
}
.w-14 {
  width: 3.5rem;
}
.w-16 {
  width: 4rem;
}
.w-20 {
  width: 5rem;
}
.w-24 {
  width: 6rem;
}
.w-28 {
  width: 7rem;
}
.w-32 {
  width: 8rem;
}
.w-36 {
  width: 9rem;
}
.w-40 {
  width: 10rem;
}
.w-44 {
  width: 11rem;
}
.w-48 {
  width: 12rem;
}
.w-52 {
  width: 13rem;
}
.w-56 {
  width: 14rem;
}
.w-60 {
  width: 15rem;
}
.w-64 {
  width: 16rem;
}
.w-72 {
  width: 18rem;
}
.w-80 {
  width: 20rem;
}
.w-96 {
  width: 24rem;
}
.w-auto {
  width: auto;
}
.w-px {
  width: 1px;
}
.w-0\.5 {
  width: 0.125rem;
}
.w-1\.5 {
  width: 0.375rem;
}
.w-2\.5 {
  width: 0.625rem;
}
.w-3\.5 {
  width: 0.875rem;
}
.w-1\/2 {
  width: 50%;
}
.w-1\/3 {
  width: 33.333333%;
}
.w-2\/3 {
  width: 66.666667%;
}
.w-1\/4 {
  width: 25%;
}
.w-2\/4 {
  width: 50%;
}
.w-3\/4 {
  width: 75%;
}
.w-1\/5 {
  width: 20%;
}
.w-2\/5 {
  width: 40%;
}
.w-3\/5 {
  width: 60%;
}
.w-4\/5 {
  width: 80%;
}
.w-1\/6 {
  width: 16.666667%;
}
.w-2\/6 {
  width: 33.333333%;
}
.w-3\/6 {
  width: 50%;
}
.w-4\/6 {
  width: 66.666667%;
}
.w-5\/6 {
  width: 83.333333%;
}
.w-1\/12 {
  width: 8.333333%;
}
.w-2\/12 {
  width: 16.666667%;
}
.w-3\/12 {
  width: 25%;
}
.w-4\/12 {
  width: 33.333333%;
}
.w-5\/12 {
  width: 41.666667%;
}
.w-6\/12 {
  width: 50%;
}
.w-7\/12 {
  width: 58.333333%;
}
.w-8\/12 {
  width: 66.666667%;
}
.w-9\/12 {
  width: 75%;
}
.w-10\/12 {
  width: 83.333333%;
}
.w-11\/12 {
  width: 91.666667%;
}
.w-full {
  width: 100%;
}
.w-screen {
  width: 100vw;
}
.w-min {
  width: min-content;
}
.w-max {
  width: max-content;
}
.min-w-0 {
  min-width: 0;
}
.min-w-full {
  min-width: 100%;
}
.min-w-min {
  min-width: min-content;
}
.min-w-max {
  min-width: max-content;
}
.max-w-0 {
  max-width: 0;
}
.max-w-none {
  max-width: none;
}
.max-w-xs {
  max-width: 20rem;
}
.max-w-sm {
  max-width: 24rem;
}
.max-w-md {
  max-width: 28rem;
}
.max-w-lg {
  max-width: 32rem;
}
.max-w-xl {
  max-width: 36rem;
}
.max-w-2xl {
  max-width: 42rem;
}
.max-w-3xl {
  max-width: 48rem;
}
.max-w-4xl {
  max-width: 56rem;
}
.max-w-5xl {
  max-width: 64rem;
}
.max-w-6xl {
  max-width: 72rem;
}
.max-w-7xl {
  max-width: 80rem;
}
.max-w-full {
  max-width: 100%;
}
.max-w-min {
  max-width: min-content;
}
.max-w-max {
  max-width: max-content;
}
.max-w-prose {
  max-width: 65ch;
}
.max-w-screen-sm {
  max-width: 640px;
}
.max-w-screen-md {
  max-width: 768px;
}
.max-w-screen-lg {
  max-width: 1024px;
}
.max-w-screen-xl {
  max-width: 1280px;
}
.max-w-screen-2xl {
  max-width: 1536px;
}
.flex-1 {
  flex: 1 1 0%;
}
.flex-auto {
  flex: 1 1 auto;
}
.flex-initial {
  flex: 0 1 auto;
}
.flex-none {
  flex: none;
}
.flex-shrink-0 {
  flex-shrink: 0;
}
.flex-shrink {
  flex-shrink: 1;
}
.flex-grow-0 {
  flex-grow: 0;
}
.flex-grow {
  flex-grow: 1;
}
.table-auto {
  table-layout: auto;
}
.table-fixed {
  table-layout: fixed;
}
.border-collapse {
  border-collapse: collapse;
}
.border-separate {
  border-collapse: separate;
}
.origin-center {
  transform-origin: center;
}
.origin-top {
  transform-origin: top;
}
.origin-top-right {
  transform-origin: top right;
}
.origin-right {
  transform-origin: right;
}
.origin-bottom-right {
  transform-origin: bottom right;
}
.origin-bottom {
  transform-origin: bottom;
}
.origin-bottom-left {
  transform-origin: bottom left;
}
.origin-left {
  transform-origin: left;
}
.origin-top-left {
  transform-origin: top left;
}
.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform-gpu {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform-none {
  transform: none;
}
.translate-x-0 {
  --tw-translate-x: 0px;
}
.translate-x-1 {
  --tw-translate-x: 0.25rem;
}
.translate-x-2 {
  --tw-translate-x: 0.5rem;
}
.translate-x-3 {
  --tw-translate-x: 0.75rem;
}
.translate-x-4 {
  --tw-translate-x: 1rem;
}
.translate-x-5 {
  --tw-translate-x: 1.25rem;
}
.translate-x-6 {
  --tw-translate-x: 1.5rem;
}
.translate-x-7 {
  --tw-translate-x: 1.75rem;
}
.translate-x-8 {
  --tw-translate-x: 2rem;
}
.translate-x-9 {
  --tw-translate-x: 2.25rem;
}
.translate-x-10 {
  --tw-translate-x: 2.5rem;
}
.translate-x-11 {
  --tw-translate-x: 2.75rem;
}
.translate-x-12 {
  --tw-translate-x: 3rem;
}
.translate-x-14 {
  --tw-translate-x: 3.5rem;
}
.translate-x-16 {
  --tw-translate-x: 4rem;
}
.translate-x-20 {
  --tw-translate-x: 5rem;
}
.translate-x-24 {
  --tw-translate-x: 6rem;
}
.translate-x-28 {
  --tw-translate-x: 7rem;
}
.translate-x-32 {
  --tw-translate-x: 8rem;
}
.translate-x-36 {
  --tw-translate-x: 9rem;
}
.translate-x-40 {
  --tw-translate-x: 10rem;
}
.translate-x-44 {
  --tw-translate-x: 11rem;
}
.translate-x-48 {
  --tw-translate-x: 12rem;
}
.translate-x-52 {
  --tw-translate-x: 13rem;
}
.translate-x-56 {
  --tw-translate-x: 14rem;
}
.translate-x-60 {
  --tw-translate-x: 15rem;
}
.translate-x-64 {
  --tw-translate-x: 16rem;
}
.translate-x-72 {
  --tw-translate-x: 18rem;
}
.translate-x-80 {
  --tw-translate-x: 20rem;
}
.translate-x-96 {
  --tw-translate-x: 24rem;
}
.translate-x-px {
  --tw-translate-x: 1px;
}
.translate-x-0\.5 {
  --tw-translate-x: 0.125rem;
}
.translate-x-1\.5 {
  --tw-translate-x: 0.375rem;
}
.translate-x-2\.5 {
  --tw-translate-x: 0.625rem;
}
.translate-x-3\.5 {
  --tw-translate-x: 0.875rem;
}
.-translate-x-0 {
  --tw-translate-x: 0px;
}
.-translate-x-1 {
  --tw-translate-x: -0.25rem;
}
.-translate-x-2 {
  --tw-translate-x: -0.5rem;
}
.-translate-x-3 {
  --tw-translate-x: -0.75rem;
}
.-translate-x-4 {
  --tw-translate-x: -1rem;
}
.-translate-x-5 {
  --tw-translate-x: -1.25rem;
}
.-translate-x-6 {
  --tw-translate-x: -1.5rem;
}
.-translate-x-7 {
  --tw-translate-x: -1.75rem;
}
.-translate-x-8 {
  --tw-translate-x: -2rem;
}
.-translate-x-9 {
  --tw-translate-x: -2.25rem;
}
.-translate-x-10 {
  --tw-translate-x: -2.5rem;
}
.-translate-x-11 {
  --tw-translate-x: -2.75rem;
}
.-translate-x-12 {
  --tw-translate-x: -3rem;
}
.-translate-x-14 {
  --tw-translate-x: -3.5rem;
}
.-translate-x-16 {
  --tw-translate-x: -4rem;
}
.-translate-x-20 {
  --tw-translate-x: -5rem;
}
.-translate-x-24 {
  --tw-translate-x: -6rem;
}
.-translate-x-28 {
  --tw-translate-x: -7rem;
}
.-translate-x-32 {
  --tw-translate-x: -8rem;
}
.-translate-x-36 {
  --tw-translate-x: -9rem;
}
.-translate-x-40 {
  --tw-translate-x: -10rem;
}
.-translate-x-44 {
  --tw-translate-x: -11rem;
}
.-translate-x-48 {
  --tw-translate-x: -12rem;
}
.-translate-x-52 {
  --tw-translate-x: -13rem;
}
.-translate-x-56 {
  --tw-translate-x: -14rem;
}
.-translate-x-60 {
  --tw-translate-x: -15rem;
}
.-translate-x-64 {
  --tw-translate-x: -16rem;
}
.-translate-x-72 {
  --tw-translate-x: -18rem;
}
.-translate-x-80 {
  --tw-translate-x: -20rem;
}
.-translate-x-96 {
  --tw-translate-x: -24rem;
}
.-translate-x-px {
  --tw-translate-x: -1px;
}
.-translate-x-0\.5 {
  --tw-translate-x: -0.125rem;
}
.-translate-x-1\.5 {
  --tw-translate-x: -0.375rem;
}
.-translate-x-2\.5 {
  --tw-translate-x: -0.625rem;
}
.-translate-x-3\.5 {
  --tw-translate-x: -0.875rem;
}
.translate-x-1\/2 {
  --tw-translate-x: 50%;
}
.translate-x-1\/3 {
  --tw-translate-x: 33.333333%;
}
.translate-x-2\/3 {
  --tw-translate-x: 66.666667%;
}
.translate-x-1\/4 {
  --tw-translate-x: 25%;
}
.translate-x-2\/4 {
  --tw-translate-x: 50%;
}
.translate-x-3\/4 {
  --tw-translate-x: 75%;
}
.translate-x-full {
  --tw-translate-x: 100%;
}
.-translate-x-1\/2 {
  --tw-translate-x: -50%;
}
.-translate-x-1\/3 {
  --tw-translate-x: -33.333333%;
}
.-translate-x-2\/3 {
  --tw-translate-x: -66.666667%;
}
.-translate-x-1\/4 {
  --tw-translate-x: -25%;
}
.-translate-x-2\/4 {
  --tw-translate-x: -50%;
}
.-translate-x-3\/4 {
  --tw-translate-x: -75%;
}
.-translate-x-full {
  --tw-translate-x: -100%;
}
.translate-y-0 {
  --tw-translate-y: 0px;
}
.translate-y-1 {
  --tw-translate-y: 0.25rem;
}
.translate-y-2 {
  --tw-translate-y: 0.5rem;
}
.translate-y-3 {
  --tw-translate-y: 0.75rem;
}
.translate-y-4 {
  --tw-translate-y: 1rem;
}
.translate-y-5 {
  --tw-translate-y: 1.25rem;
}
.translate-y-6 {
  --tw-translate-y: 1.5rem;
}
.translate-y-7 {
  --tw-translate-y: 1.75rem;
}
.translate-y-8 {
  --tw-translate-y: 2rem;
}
.translate-y-9 {
  --tw-translate-y: 2.25rem;
}
.translate-y-10 {
  --tw-translate-y: 2.5rem;
}
.translate-y-11 {
  --tw-translate-y: 2.75rem;
}
.translate-y-12 {
  --tw-translate-y: 3rem;
}
.translate-y-14 {
  --tw-translate-y: 3.5rem;
}
.translate-y-16 {
  --tw-translate-y: 4rem;
}
.translate-y-20 {
  --tw-translate-y: 5rem;
}
.translate-y-24 {
  --tw-translate-y: 6rem;
}
.translate-y-28 {
  --tw-translate-y: 7rem;
}
.translate-y-32 {
  --tw-translate-y: 8rem;
}
.translate-y-36 {
  --tw-translate-y: 9rem;
}
.translate-y-40 {
  --tw-translate-y: 10rem;
}
.translate-y-44 {
  --tw-translate-y: 11rem;
}
.translate-y-48 {
  --tw-translate-y: 12rem;
}
.translate-y-52 {
  --tw-translate-y: 13rem;
}
.translate-y-56 {
  --tw-translate-y: 14rem;
}
.translate-y-60 {
  --tw-translate-y: 15rem;
}
.translate-y-64 {
  --tw-translate-y: 16rem;
}
.translate-y-72 {
  --tw-translate-y: 18rem;
}
.translate-y-80 {
  --tw-translate-y: 20rem;
}
.translate-y-96 {
  --tw-translate-y: 24rem;
}
.translate-y-px {
  --tw-translate-y: 1px;
}
.translate-y-0\.5 {
  --tw-translate-y: 0.125rem;
}
.translate-y-1\.5 {
  --tw-translate-y: 0.375rem;
}
.translate-y-2\.5 {
  --tw-translate-y: 0.625rem;
}
.translate-y-3\.5 {
  --tw-translate-y: 0.875rem;
}
.-translate-y-0 {
  --tw-translate-y: 0px;
}
.-translate-y-1 {
  --tw-translate-y: -0.25rem;
}
.-translate-y-2 {
  --tw-translate-y: -0.5rem;
}
.-translate-y-3 {
  --tw-translate-y: -0.75rem;
}
.-translate-y-4 {
  --tw-translate-y: -1rem;
}
.-translate-y-5 {
  --tw-translate-y: -1.25rem;
}
.-translate-y-6 {
  --tw-translate-y: -1.5rem;
}
.-translate-y-7 {
  --tw-translate-y: -1.75rem;
}
.-translate-y-8 {
  --tw-translate-y: -2rem;
}
.-translate-y-9 {
  --tw-translate-y: -2.25rem;
}
.-translate-y-10 {
  --tw-translate-y: -2.5rem;
}
.-translate-y-11 {
  --tw-translate-y: -2.75rem;
}
.-translate-y-12 {
  --tw-translate-y: -3rem;
}
.-translate-y-14 {
  --tw-translate-y: -3.5rem;
}
.-translate-y-16 {
  --tw-translate-y: -4rem;
}
.-translate-y-20 {
  --tw-translate-y: -5rem;
}
.-translate-y-24 {
  --tw-translate-y: -6rem;
}
.-translate-y-28 {
  --tw-translate-y: -7rem;
}
.-translate-y-32 {
  --tw-translate-y: -8rem;
}
.-translate-y-36 {
  --tw-translate-y: -9rem;
}
.-translate-y-40 {
  --tw-translate-y: -10rem;
}
.-translate-y-44 {
  --tw-translate-y: -11rem;
}
.-translate-y-48 {
  --tw-translate-y: -12rem;
}
.-translate-y-52 {
  --tw-translate-y: -13rem;
}
.-translate-y-56 {
  --tw-translate-y: -14rem;
}
.-translate-y-60 {
  --tw-translate-y: -15rem;
}
.-translate-y-64 {
  --tw-translate-y: -16rem;
}
.-translate-y-72 {
  --tw-translate-y: -18rem;
}
.-translate-y-80 {
  --tw-translate-y: -20rem;
}
.-translate-y-96 {
  --tw-translate-y: -24rem;
}
.-translate-y-px {
  --tw-translate-y: -1px;
}
.-translate-y-0\.5 {
  --tw-translate-y: -0.125rem;
}
.-translate-y-1\.5 {
  --tw-translate-y: -0.375rem;
}
.-translate-y-2\.5 {
  --tw-translate-y: -0.625rem;
}
.-translate-y-3\.5 {
  --tw-translate-y: -0.875rem;
}
.translate-y-1\/2 {
  --tw-translate-y: 50%;
}
.translate-y-1\/3 {
  --tw-translate-y: 33.333333%;
}
.translate-y-2\/3 {
  --tw-translate-y: 66.666667%;
}
.translate-y-1\/4 {
  --tw-translate-y: 25%;
}
.translate-y-2\/4 {
  --tw-translate-y: 50%;
}
.translate-y-3\/4 {
  --tw-translate-y: 75%;
}
.translate-y-full {
  --tw-translate-y: 100%;
}
.-translate-y-1\/2 {
  --tw-translate-y: -50%;
}
.-translate-y-1\/3 {
  --tw-translate-y: -33.333333%;
}
.-translate-y-2\/3 {
  --tw-translate-y: -66.666667%;
}
.-translate-y-1\/4 {
  --tw-translate-y: -25%;
}
.-translate-y-2\/4 {
  --tw-translate-y: -50%;
}
.-translate-y-3\/4 {
  --tw-translate-y: -75%;
}
.-translate-y-full {
  --tw-translate-y: -100%;
}
.hover\:translate-x-0:hover {
  --tw-translate-x: 0px;
}
.hover\:translate-x-1:hover {
  --tw-translate-x: 0.25rem;
}
.hover\:translate-x-2:hover {
  --tw-translate-x: 0.5rem;
}
.hover\:translate-x-3:hover {
  --tw-translate-x: 0.75rem;
}
.hover\:translate-x-4:hover {
  --tw-translate-x: 1rem;
}
.hover\:translate-x-5:hover {
  --tw-translate-x: 1.25rem;
}
.hover\:translate-x-6:hover {
  --tw-translate-x: 1.5rem;
}
.hover\:translate-x-7:hover {
  --tw-translate-x: 1.75rem;
}
.hover\:translate-x-8:hover {
  --tw-translate-x: 2rem;
}
.hover\:translate-x-9:hover {
  --tw-translate-x: 2.25rem;
}
.hover\:translate-x-10:hover {
  --tw-translate-x: 2.5rem;
}
.hover\:translate-x-11:hover {
  --tw-translate-x: 2.75rem;
}
.hover\:translate-x-12:hover {
  --tw-translate-x: 3rem;
}
.hover\:translate-x-14:hover {
  --tw-translate-x: 3.5rem;
}
.hover\:translate-x-16:hover {
  --tw-translate-x: 4rem;
}
.hover\:translate-x-20:hover {
  --tw-translate-x: 5rem;
}
.hover\:translate-x-24:hover {
  --tw-translate-x: 6rem;
}
.hover\:translate-x-28:hover {
  --tw-translate-x: 7rem;
}
.hover\:translate-x-32:hover {
  --tw-translate-x: 8rem;
}
.hover\:translate-x-36:hover {
  --tw-translate-x: 9rem;
}
.hover\:translate-x-40:hover {
  --tw-translate-x: 10rem;
}
.hover\:translate-x-44:hover {
  --tw-translate-x: 11rem;
}
.hover\:translate-x-48:hover {
  --tw-translate-x: 12rem;
}
.hover\:translate-x-52:hover {
  --tw-translate-x: 13rem;
}
.hover\:translate-x-56:hover {
  --tw-translate-x: 14rem;
}
.hover\:translate-x-60:hover {
  --tw-translate-x: 15rem;
}
.hover\:translate-x-64:hover {
  --tw-translate-x: 16rem;
}
.hover\:translate-x-72:hover {
  --tw-translate-x: 18rem;
}
.hover\:translate-x-80:hover {
  --tw-translate-x: 20rem;
}
.hover\:translate-x-96:hover {
  --tw-translate-x: 24rem;
}
.hover\:translate-x-px:hover {
  --tw-translate-x: 1px;
}
.hover\:translate-x-0\.5:hover {
  --tw-translate-x: 0.125rem;
}
.hover\:translate-x-1\.5:hover {
  --tw-translate-x: 0.375rem;
}
.hover\:translate-x-2\.5:hover {
  --tw-translate-x: 0.625rem;
}
.hover\:translate-x-3\.5:hover {
  --tw-translate-x: 0.875rem;
}
.hover\:-translate-x-0:hover {
  --tw-translate-x: 0px;
}
.hover\:-translate-x-1:hover {
  --tw-translate-x: -0.25rem;
}
.hover\:-translate-x-2:hover {
  --tw-translate-x: -0.5rem;
}
.hover\:-translate-x-3:hover {
  --tw-translate-x: -0.75rem;
}
.hover\:-translate-x-4:hover {
  --tw-translate-x: -1rem;
}
.hover\:-translate-x-5:hover {
  --tw-translate-x: -1.25rem;
}
.hover\:-translate-x-6:hover {
  --tw-translate-x: -1.5rem;
}
.hover\:-translate-x-7:hover {
  --tw-translate-x: -1.75rem;
}
.hover\:-translate-x-8:hover {
  --tw-translate-x: -2rem;
}
.hover\:-translate-x-9:hover {
  --tw-translate-x: -2.25rem;
}
.hover\:-translate-x-10:hover {
  --tw-translate-x: -2.5rem;
}
.hover\:-translate-x-11:hover {
  --tw-translate-x: -2.75rem;
}
.hover\:-translate-x-12:hover {
  --tw-translate-x: -3rem;
}
.hover\:-translate-x-14:hover {
  --tw-translate-x: -3.5rem;
}
.hover\:-translate-x-16:hover {
  --tw-translate-x: -4rem;
}
.hover\:-translate-x-20:hover {
  --tw-translate-x: -5rem;
}
.hover\:-translate-x-24:hover {
  --tw-translate-x: -6rem;
}
.hover\:-translate-x-28:hover {
  --tw-translate-x: -7rem;
}
.hover\:-translate-x-32:hover {
  --tw-translate-x: -8rem;
}
.hover\:-translate-x-36:hover {
  --tw-translate-x: -9rem;
}
.hover\:-translate-x-40:hover {
  --tw-translate-x: -10rem;
}
.hover\:-translate-x-44:hover {
  --tw-translate-x: -11rem;
}
.hover\:-translate-x-48:hover {
  --tw-translate-x: -12rem;
}
.hover\:-translate-x-52:hover {
  --tw-translate-x: -13rem;
}
.hover\:-translate-x-56:hover {
  --tw-translate-x: -14rem;
}
.hover\:-translate-x-60:hover {
  --tw-translate-x: -15rem;
}
.hover\:-translate-x-64:hover {
  --tw-translate-x: -16rem;
}
.hover\:-translate-x-72:hover {
  --tw-translate-x: -18rem;
}
.hover\:-translate-x-80:hover {
  --tw-translate-x: -20rem;
}
.hover\:-translate-x-96:hover {
  --tw-translate-x: -24rem;
}
.hover\:-translate-x-px:hover {
  --tw-translate-x: -1px;
}
.hover\:-translate-x-0\.5:hover {
  --tw-translate-x: -0.125rem;
}
.hover\:-translate-x-1\.5:hover {
  --tw-translate-x: -0.375rem;
}
.hover\:-translate-x-2\.5:hover {
  --tw-translate-x: -0.625rem;
}
.hover\:-translate-x-3\.5:hover {
  --tw-translate-x: -0.875rem;
}
.hover\:translate-x-1\/2:hover {
  --tw-translate-x: 50%;
}
.hover\:translate-x-1\/3:hover {
  --tw-translate-x: 33.333333%;
}
.hover\:translate-x-2\/3:hover {
  --tw-translate-x: 66.666667%;
}
.hover\:translate-x-1\/4:hover {
  --tw-translate-x: 25%;
}
.hover\:translate-x-2\/4:hover {
  --tw-translate-x: 50%;
}
.hover\:translate-x-3\/4:hover {
  --tw-translate-x: 75%;
}
.hover\:translate-x-full:hover {
  --tw-translate-x: 100%;
}
.hover\:-translate-x-1\/2:hover {
  --tw-translate-x: -50%;
}
.hover\:-translate-x-1\/3:hover {
  --tw-translate-x: -33.333333%;
}
.hover\:-translate-x-2\/3:hover {
  --tw-translate-x: -66.666667%;
}
.hover\:-translate-x-1\/4:hover {
  --tw-translate-x: -25%;
}
.hover\:-translate-x-2\/4:hover {
  --tw-translate-x: -50%;
}
.hover\:-translate-x-3\/4:hover {
  --tw-translate-x: -75%;
}
.hover\:-translate-x-full:hover {
  --tw-translate-x: -100%;
}
.hover\:translate-y-0:hover {
  --tw-translate-y: 0px;
}
.hover\:translate-y-1:hover {
  --tw-translate-y: 0.25rem;
}
.hover\:translate-y-2:hover {
  --tw-translate-y: 0.5rem;
}
.hover\:translate-y-3:hover {
  --tw-translate-y: 0.75rem;
}
.hover\:translate-y-4:hover {
  --tw-translate-y: 1rem;
}
.hover\:translate-y-5:hover {
  --tw-translate-y: 1.25rem;
}
.hover\:translate-y-6:hover {
  --tw-translate-y: 1.5rem;
}
.hover\:translate-y-7:hover {
  --tw-translate-y: 1.75rem;
}
.hover\:translate-y-8:hover {
  --tw-translate-y: 2rem;
}
.hover\:translate-y-9:hover {
  --tw-translate-y: 2.25rem;
}
.hover\:translate-y-10:hover {
  --tw-translate-y: 2.5rem;
}
.hover\:translate-y-11:hover {
  --tw-translate-y: 2.75rem;
}
.hover\:translate-y-12:hover {
  --tw-translate-y: 3rem;
}
.hover\:translate-y-14:hover {
  --tw-translate-y: 3.5rem;
}
.hover\:translate-y-16:hover {
  --tw-translate-y: 4rem;
}
.hover\:translate-y-20:hover {
  --tw-translate-y: 5rem;
}
.hover\:translate-y-24:hover {
  --tw-translate-y: 6rem;
}
.hover\:translate-y-28:hover {
  --tw-translate-y: 7rem;
}
.hover\:translate-y-32:hover {
  --tw-translate-y: 8rem;
}
.hover\:translate-y-36:hover {
  --tw-translate-y: 9rem;
}
.hover\:translate-y-40:hover {
  --tw-translate-y: 10rem;
}
.hover\:translate-y-44:hover {
  --tw-translate-y: 11rem;
}
.hover\:translate-y-48:hover {
  --tw-translate-y: 12rem;
}
.hover\:translate-y-52:hover {
  --tw-translate-y: 13rem;
}
.hover\:translate-y-56:hover {
  --tw-translate-y: 14rem;
}
.hover\:translate-y-60:hover {
  --tw-translate-y: 15rem;
}
.hover\:translate-y-64:hover {
  --tw-translate-y: 16rem;
}
.hover\:translate-y-72:hover {
  --tw-translate-y: 18rem;
}
.hover\:translate-y-80:hover {
  --tw-translate-y: 20rem;
}
.hover\:translate-y-96:hover {
  --tw-translate-y: 24rem;
}
.hover\:translate-y-px:hover {
  --tw-translate-y: 1px;
}
.hover\:translate-y-0\.5:hover {
  --tw-translate-y: 0.125rem;
}
.hover\:translate-y-1\.5:hover {
  --tw-translate-y: 0.375rem;
}
.hover\:translate-y-2\.5:hover {
  --tw-translate-y: 0.625rem;
}
.hover\:translate-y-3\.5:hover {
  --tw-translate-y: 0.875rem;
}
.hover\:-translate-y-0:hover {
  --tw-translate-y: 0px;
}
.hover\:-translate-y-1:hover {
  --tw-translate-y: -0.25rem;
}
.hover\:-translate-y-2:hover {
  --tw-translate-y: -0.5rem;
}
.hover\:-translate-y-3:hover {
  --tw-translate-y: -0.75rem;
}
.hover\:-translate-y-4:hover {
  --tw-translate-y: -1rem;
}
.hover\:-translate-y-5:hover {
  --tw-translate-y: -1.25rem;
}
.hover\:-translate-y-6:hover {
  --tw-translate-y: -1.5rem;
}
.hover\:-translate-y-7:hover {
  --tw-translate-y: -1.75rem;
}
.hover\:-translate-y-8:hover {
  --tw-translate-y: -2rem;
}
.hover\:-translate-y-9:hover {
  --tw-translate-y: -2.25rem;
}
.hover\:-translate-y-10:hover {
  --tw-translate-y: -2.5rem;
}
.hover\:-translate-y-11:hover {
  --tw-translate-y: -2.75rem;
}
.hover\:-translate-y-12:hover {
  --tw-translate-y: -3rem;
}
.hover\:-translate-y-14:hover {
  --tw-translate-y: -3.5rem;
}
.hover\:-translate-y-16:hover {
  --tw-translate-y: -4rem;
}
.hover\:-translate-y-20:hover {
  --tw-translate-y: -5rem;
}
.hover\:-translate-y-24:hover {
  --tw-translate-y: -6rem;
}
.hover\:-translate-y-28:hover {
  --tw-translate-y: -7rem;
}
.hover\:-translate-y-32:hover {
  --tw-translate-y: -8rem;
}
.hover\:-translate-y-36:hover {
  --tw-translate-y: -9rem;
}
.hover\:-translate-y-40:hover {
  --tw-translate-y: -10rem;
}
.hover\:-translate-y-44:hover {
  --tw-translate-y: -11rem;
}
.hover\:-translate-y-48:hover {
  --tw-translate-y: -12rem;
}
.hover\:-translate-y-52:hover {
  --tw-translate-y: -13rem;
}
.hover\:-translate-y-56:hover {
  --tw-translate-y: -14rem;
}
.hover\:-translate-y-60:hover {
  --tw-translate-y: -15rem;
}
.hover\:-translate-y-64:hover {
  --tw-translate-y: -16rem;
}
.hover\:-translate-y-72:hover {
  --tw-translate-y: -18rem;
}
.hover\:-translate-y-80:hover {
  --tw-translate-y: -20rem;
}
.hover\:-translate-y-96:hover {
  --tw-translate-y: -24rem;
}
.hover\:-translate-y-px:hover {
  --tw-translate-y: -1px;
}
.hover\:-translate-y-0\.5:hover {
  --tw-translate-y: -0.125rem;
}
.hover\:-translate-y-1\.5:hover {
  --tw-translate-y: -0.375rem;
}
.hover\:-translate-y-2\.5:hover {
  --tw-translate-y: -0.625rem;
}
.hover\:-translate-y-3\.5:hover {
  --tw-translate-y: -0.875rem;
}
.hover\:translate-y-1\/2:hover {
  --tw-translate-y: 50%;
}
.hover\:translate-y-1\/3:hover {
  --tw-translate-y: 33.333333%;
}
.hover\:translate-y-2\/3:hover {
  --tw-translate-y: 66.666667%;
}
.hover\:translate-y-1\/4:hover {
  --tw-translate-y: 25%;
}
.hover\:translate-y-2\/4:hover {
  --tw-translate-y: 50%;
}
.hover\:translate-y-3\/4:hover {
  --tw-translate-y: 75%;
}
.hover\:translate-y-full:hover {
  --tw-translate-y: 100%;
}
.hover\:-translate-y-1\/2:hover {
  --tw-translate-y: -50%;
}
.hover\:-translate-y-1\/3:hover {
  --tw-translate-y: -33.333333%;
}
.hover\:-translate-y-2\/3:hover {
  --tw-translate-y: -66.666667%;
}
.hover\:-translate-y-1\/4:hover {
  --tw-translate-y: -25%;
}
.hover\:-translate-y-2\/4:hover {
  --tw-translate-y: -50%;
}
.hover\:-translate-y-3\/4:hover {
  --tw-translate-y: -75%;
}
.hover\:-translate-y-full:hover {
  --tw-translate-y: -100%;
}
.focus\:translate-x-0:focus {
  --tw-translate-x: 0px;
}
.focus\:translate-x-1:focus {
  --tw-translate-x: 0.25rem;
}
.focus\:translate-x-2:focus {
  --tw-translate-x: 0.5rem;
}
.focus\:translate-x-3:focus {
  --tw-translate-x: 0.75rem;
}
.focus\:translate-x-4:focus {
  --tw-translate-x: 1rem;
}
.focus\:translate-x-5:focus {
  --tw-translate-x: 1.25rem;
}
.focus\:translate-x-6:focus {
  --tw-translate-x: 1.5rem;
}
.focus\:translate-x-7:focus {
  --tw-translate-x: 1.75rem;
}
.focus\:translate-x-8:focus {
  --tw-translate-x: 2rem;
}
.focus\:translate-x-9:focus {
  --tw-translate-x: 2.25rem;
}
.focus\:translate-x-10:focus {
  --tw-translate-x: 2.5rem;
}
.focus\:translate-x-11:focus {
  --tw-translate-x: 2.75rem;
}
.focus\:translate-x-12:focus {
  --tw-translate-x: 3rem;
}
.focus\:translate-x-14:focus {
  --tw-translate-x: 3.5rem;
}
.focus\:translate-x-16:focus {
  --tw-translate-x: 4rem;
}
.focus\:translate-x-20:focus {
  --tw-translate-x: 5rem;
}
.focus\:translate-x-24:focus {
  --tw-translate-x: 6rem;
}
.focus\:translate-x-28:focus {
  --tw-translate-x: 7rem;
}
.focus\:translate-x-32:focus {
  --tw-translate-x: 8rem;
}
.focus\:translate-x-36:focus {
  --tw-translate-x: 9rem;
}
.focus\:translate-x-40:focus {
  --tw-translate-x: 10rem;
}
.focus\:translate-x-44:focus {
  --tw-translate-x: 11rem;
}
.focus\:translate-x-48:focus {
  --tw-translate-x: 12rem;
}
.focus\:translate-x-52:focus {
  --tw-translate-x: 13rem;
}
.focus\:translate-x-56:focus {
  --tw-translate-x: 14rem;
}
.focus\:translate-x-60:focus {
  --tw-translate-x: 15rem;
}
.focus\:translate-x-64:focus {
  --tw-translate-x: 16rem;
}
.focus\:translate-x-72:focus {
  --tw-translate-x: 18rem;
}
.focus\:translate-x-80:focus {
  --tw-translate-x: 20rem;
}
.focus\:translate-x-96:focus {
  --tw-translate-x: 24rem;
}
.focus\:translate-x-px:focus {
  --tw-translate-x: 1px;
}
.focus\:translate-x-0\.5:focus {
  --tw-translate-x: 0.125rem;
}
.focus\:translate-x-1\.5:focus {
  --tw-translate-x: 0.375rem;
}
.focus\:translate-x-2\.5:focus {
  --tw-translate-x: 0.625rem;
}
.focus\:translate-x-3\.5:focus {
  --tw-translate-x: 0.875rem;
}
.focus\:-translate-x-0:focus {
  --tw-translate-x: 0px;
}
.focus\:-translate-x-1:focus {
  --tw-translate-x: -0.25rem;
}
.focus\:-translate-x-2:focus {
  --tw-translate-x: -0.5rem;
}
.focus\:-translate-x-3:focus {
  --tw-translate-x: -0.75rem;
}
.focus\:-translate-x-4:focus {
  --tw-translate-x: -1rem;
}
.focus\:-translate-x-5:focus {
  --tw-translate-x: -1.25rem;
}
.focus\:-translate-x-6:focus {
  --tw-translate-x: -1.5rem;
}
.focus\:-translate-x-7:focus {
  --tw-translate-x: -1.75rem;
}
.focus\:-translate-x-8:focus {
  --tw-translate-x: -2rem;
}
.focus\:-translate-x-9:focus {
  --tw-translate-x: -2.25rem;
}
.focus\:-translate-x-10:focus {
  --tw-translate-x: -2.5rem;
}
.focus\:-translate-x-11:focus {
  --tw-translate-x: -2.75rem;
}
.focus\:-translate-x-12:focus {
  --tw-translate-x: -3rem;
}
.focus\:-translate-x-14:focus {
  --tw-translate-x: -3.5rem;
}
.focus\:-translate-x-16:focus {
  --tw-translate-x: -4rem;
}
.focus\:-translate-x-20:focus {
  --tw-translate-x: -5rem;
}
.focus\:-translate-x-24:focus {
  --tw-translate-x: -6rem;
}
.focus\:-translate-x-28:focus {
  --tw-translate-x: -7rem;
}
.focus\:-translate-x-32:focus {
  --tw-translate-x: -8rem;
}
.focus\:-translate-x-36:focus {
  --tw-translate-x: -9rem;
}
.focus\:-translate-x-40:focus {
  --tw-translate-x: -10rem;
}
.focus\:-translate-x-44:focus {
  --tw-translate-x: -11rem;
}
.focus\:-translate-x-48:focus {
  --tw-translate-x: -12rem;
}
.focus\:-translate-x-52:focus {
  --tw-translate-x: -13rem;
}
.focus\:-translate-x-56:focus {
  --tw-translate-x: -14rem;
}
.focus\:-translate-x-60:focus {
  --tw-translate-x: -15rem;
}
.focus\:-translate-x-64:focus {
  --tw-translate-x: -16rem;
}
.focus\:-translate-x-72:focus {
  --tw-translate-x: -18rem;
}
.focus\:-translate-x-80:focus {
  --tw-translate-x: -20rem;
}
.focus\:-translate-x-96:focus {
  --tw-translate-x: -24rem;
}
.focus\:-translate-x-px:focus {
  --tw-translate-x: -1px;
}
.focus\:-translate-x-0\.5:focus {
  --tw-translate-x: -0.125rem;
}
.focus\:-translate-x-1\.5:focus {
  --tw-translate-x: -0.375rem;
}
.focus\:-translate-x-2\.5:focus {
  --tw-translate-x: -0.625rem;
}
.focus\:-translate-x-3\.5:focus {
  --tw-translate-x: -0.875rem;
}
.focus\:translate-x-1\/2:focus {
  --tw-translate-x: 50%;
}
.focus\:translate-x-1\/3:focus {
  --tw-translate-x: 33.333333%;
}
.focus\:translate-x-2\/3:focus {
  --tw-translate-x: 66.666667%;
}
.focus\:translate-x-1\/4:focus {
  --tw-translate-x: 25%;
}
.focus\:translate-x-2\/4:focus {
  --tw-translate-x: 50%;
}
.focus\:translate-x-3\/4:focus {
  --tw-translate-x: 75%;
}
.focus\:translate-x-full:focus {
  --tw-translate-x: 100%;
}
.focus\:-translate-x-1\/2:focus {
  --tw-translate-x: -50%;
}
.focus\:-translate-x-1\/3:focus {
  --tw-translate-x: -33.333333%;
}
.focus\:-translate-x-2\/3:focus {
  --tw-translate-x: -66.666667%;
}
.focus\:-translate-x-1\/4:focus {
  --tw-translate-x: -25%;
}
.focus\:-translate-x-2\/4:focus {
  --tw-translate-x: -50%;
}
.focus\:-translate-x-3\/4:focus {
  --tw-translate-x: -75%;
}
.focus\:-translate-x-full:focus {
  --tw-translate-x: -100%;
}
.focus\:translate-y-0:focus {
  --tw-translate-y: 0px;
}
.focus\:translate-y-1:focus {
  --tw-translate-y: 0.25rem;
}
.focus\:translate-y-2:focus {
  --tw-translate-y: 0.5rem;
}
.focus\:translate-y-3:focus {
  --tw-translate-y: 0.75rem;
}
.focus\:translate-y-4:focus {
  --tw-translate-y: 1rem;
}
.focus\:translate-y-5:focus {
  --tw-translate-y: 1.25rem;
}
.focus\:translate-y-6:focus {
  --tw-translate-y: 1.5rem;
}
.focus\:translate-y-7:focus {
  --tw-translate-y: 1.75rem;
}
.focus\:translate-y-8:focus {
  --tw-translate-y: 2rem;
}
.focus\:translate-y-9:focus {
  --tw-translate-y: 2.25rem;
}
.focus\:translate-y-10:focus {
  --tw-translate-y: 2.5rem;
}
.focus\:translate-y-11:focus {
  --tw-translate-y: 2.75rem;
}
.focus\:translate-y-12:focus {
  --tw-translate-y: 3rem;
}
.focus\:translate-y-14:focus {
  --tw-translate-y: 3.5rem;
}
.focus\:translate-y-16:focus {
  --tw-translate-y: 4rem;
}
.focus\:translate-y-20:focus {
  --tw-translate-y: 5rem;
}
.focus\:translate-y-24:focus {
  --tw-translate-y: 6rem;
}
.focus\:translate-y-28:focus {
  --tw-translate-y: 7rem;
}
.focus\:translate-y-32:focus {
  --tw-translate-y: 8rem;
}
.focus\:translate-y-36:focus {
  --tw-translate-y: 9rem;
}
.focus\:translate-y-40:focus {
  --tw-translate-y: 10rem;
}
.focus\:translate-y-44:focus {
  --tw-translate-y: 11rem;
}
.focus\:translate-y-48:focus {
  --tw-translate-y: 12rem;
}
.focus\:translate-y-52:focus {
  --tw-translate-y: 13rem;
}
.focus\:translate-y-56:focus {
  --tw-translate-y: 14rem;
}
.focus\:translate-y-60:focus {
  --tw-translate-y: 15rem;
}
.focus\:translate-y-64:focus {
  --tw-translate-y: 16rem;
}
.focus\:translate-y-72:focus {
  --tw-translate-y: 18rem;
}
.focus\:translate-y-80:focus {
  --tw-translate-y: 20rem;
}
.focus\:translate-y-96:focus {
  --tw-translate-y: 24rem;
}
.focus\:translate-y-px:focus {
  --tw-translate-y: 1px;
}
.focus\:translate-y-0\.5:focus {
  --tw-translate-y: 0.125rem;
}
.focus\:translate-y-1\.5:focus {
  --tw-translate-y: 0.375rem;
}
.focus\:translate-y-2\.5:focus {
  --tw-translate-y: 0.625rem;
}
.focus\:translate-y-3\.5:focus {
  --tw-translate-y: 0.875rem;
}
.focus\:-translate-y-0:focus {
  --tw-translate-y: 0px;
}
.focus\:-translate-y-1:focus {
  --tw-translate-y: -0.25rem;
}
.focus\:-translate-y-2:focus {
  --tw-translate-y: -0.5rem;
}
.focus\:-translate-y-3:focus {
  --tw-translate-y: -0.75rem;
}
.focus\:-translate-y-4:focus {
  --tw-translate-y: -1rem;
}
.focus\:-translate-y-5:focus {
  --tw-translate-y: -1.25rem;
}
.focus\:-translate-y-6:focus {
  --tw-translate-y: -1.5rem;
}
.focus\:-translate-y-7:focus {
  --tw-translate-y: -1.75rem;
}
.focus\:-translate-y-8:focus {
  --tw-translate-y: -2rem;
}
.focus\:-translate-y-9:focus {
  --tw-translate-y: -2.25rem;
}
.focus\:-translate-y-10:focus {
  --tw-translate-y: -2.5rem;
}
.focus\:-translate-y-11:focus {
  --tw-translate-y: -2.75rem;
}
.focus\:-translate-y-12:focus {
  --tw-translate-y: -3rem;
}
.focus\:-translate-y-14:focus {
  --tw-translate-y: -3.5rem;
}
.focus\:-translate-y-16:focus {
  --tw-translate-y: -4rem;
}
.focus\:-translate-y-20:focus {
  --tw-translate-y: -5rem;
}
.focus\:-translate-y-24:focus {
  --tw-translate-y: -6rem;
}
.focus\:-translate-y-28:focus {
  --tw-translate-y: -7rem;
}
.focus\:-translate-y-32:focus {
  --tw-translate-y: -8rem;
}
.focus\:-translate-y-36:focus {
  --tw-translate-y: -9rem;
}
.focus\:-translate-y-40:focus {
  --tw-translate-y: -10rem;
}
.focus\:-translate-y-44:focus {
  --tw-translate-y: -11rem;
}
.focus\:-translate-y-48:focus {
  --tw-translate-y: -12rem;
}
.focus\:-translate-y-52:focus {
  --tw-translate-y: -13rem;
}
.focus\:-translate-y-56:focus {
  --tw-translate-y: -14rem;
}
.focus\:-translate-y-60:focus {
  --tw-translate-y: -15rem;
}
.focus\:-translate-y-64:focus {
  --tw-translate-y: -16rem;
}
.focus\:-translate-y-72:focus {
  --tw-translate-y: -18rem;
}
.focus\:-translate-y-80:focus {
  --tw-translate-y: -20rem;
}
.focus\:-translate-y-96:focus {
  --tw-translate-y: -24rem;
}
.focus\:-translate-y-px:focus {
  --tw-translate-y: -1px;
}
.focus\:-translate-y-0\.5:focus {
  --tw-translate-y: -0.125rem;
}
.focus\:-translate-y-1\.5:focus {
  --tw-translate-y: -0.375rem;
}
.focus\:-translate-y-2\.5:focus {
  --tw-translate-y: -0.625rem;
}
.focus\:-translate-y-3\.5:focus {
  --tw-translate-y: -0.875rem;
}
.focus\:translate-y-1\/2:focus {
  --tw-translate-y: 50%;
}
.focus\:translate-y-1\/3:focus {
  --tw-translate-y: 33.333333%;
}
.focus\:translate-y-2\/3:focus {
  --tw-translate-y: 66.666667%;
}
.focus\:translate-y-1\/4:focus {
  --tw-translate-y: 25%;
}
.focus\:translate-y-2\/4:focus {
  --tw-translate-y: 50%;
}
.focus\:translate-y-3\/4:focus {
  --tw-translate-y: 75%;
}
.focus\:translate-y-full:focus {
  --tw-translate-y: 100%;
}
.focus\:-translate-y-1\/2:focus {
  --tw-translate-y: -50%;
}
.focus\:-translate-y-1\/3:focus {
  --tw-translate-y: -33.333333%;
}
.focus\:-translate-y-2\/3:focus {
  --tw-translate-y: -66.666667%;
}
.focus\:-translate-y-1\/4:focus {
  --tw-translate-y: -25%;
}
.focus\:-translate-y-2\/4:focus {
  --tw-translate-y: -50%;
}
.focus\:-translate-y-3\/4:focus {
  --tw-translate-y: -75%;
}
.focus\:-translate-y-full:focus {
  --tw-translate-y: -100%;
}
.rotate-0 {
  --tw-rotate: 0deg;
}
.rotate-1 {
  --tw-rotate: 1deg;
}
.rotate-2 {
  --tw-rotate: 2deg;
}
.rotate-3 {
  --tw-rotate: 3deg;
}
.rotate-6 {
  --tw-rotate: 6deg;
}
.rotate-12 {
  --tw-rotate: 12deg;
}
.rotate-45 {
  --tw-rotate: 45deg;
}
.rotate-90 {
  --tw-rotate: 90deg;
}
.rotate-180 {
  --tw-rotate: 180deg;
}
.-rotate-180 {
  --tw-rotate: -180deg;
}
.-rotate-90 {
  --tw-rotate: -90deg;
}
.-rotate-45 {
  --tw-rotate: -45deg;
}
.-rotate-12 {
  --tw-rotate: -12deg;
}
.-rotate-6 {
  --tw-rotate: -6deg;
}
.-rotate-3 {
  --tw-rotate: -3deg;
}
.-rotate-2 {
  --tw-rotate: -2deg;
}
.-rotate-1 {
  --tw-rotate: -1deg;
}
.hover\:rotate-0:hover {
  --tw-rotate: 0deg;
}
.hover\:rotate-1:hover {
  --tw-rotate: 1deg;
}
.hover\:rotate-2:hover {
  --tw-rotate: 2deg;
}
.hover\:rotate-3:hover {
  --tw-rotate: 3deg;
}
.hover\:rotate-6:hover {
  --tw-rotate: 6deg;
}
.hover\:rotate-12:hover {
  --tw-rotate: 12deg;
}
.hover\:rotate-45:hover {
  --tw-rotate: 45deg;
}
.hover\:rotate-90:hover {
  --tw-rotate: 90deg;
}
.hover\:rotate-180:hover {
  --tw-rotate: 180deg;
}
.hover\:-rotate-180:hover {
  --tw-rotate: -180deg;
}
.hover\:-rotate-90:hover {
  --tw-rotate: -90deg;
}
.hover\:-rotate-45:hover {
  --tw-rotate: -45deg;
}
.hover\:-rotate-12:hover {
  --tw-rotate: -12deg;
}
.hover\:-rotate-6:hover {
  --tw-rotate: -6deg;
}
.hover\:-rotate-3:hover {
  --tw-rotate: -3deg;
}
.hover\:-rotate-2:hover {
  --tw-rotate: -2deg;
}
.hover\:-rotate-1:hover {
  --tw-rotate: -1deg;
}
.focus\:rotate-0:focus {
  --tw-rotate: 0deg;
}
.focus\:rotate-1:focus {
  --tw-rotate: 1deg;
}
.focus\:rotate-2:focus {
  --tw-rotate: 2deg;
}
.focus\:rotate-3:focus {
  --tw-rotate: 3deg;
}
.focus\:rotate-6:focus {
  --tw-rotate: 6deg;
}
.focus\:rotate-12:focus {
  --tw-rotate: 12deg;
}
.focus\:rotate-45:focus {
  --tw-rotate: 45deg;
}
.focus\:rotate-90:focus {
  --tw-rotate: 90deg;
}
.focus\:rotate-180:focus {
  --tw-rotate: 180deg;
}
.focus\:-rotate-180:focus {
  --tw-rotate: -180deg;
}
.focus\:-rotate-90:focus {
  --tw-rotate: -90deg;
}
.focus\:-rotate-45:focus {
  --tw-rotate: -45deg;
}
.focus\:-rotate-12:focus {
  --tw-rotate: -12deg;
}
.focus\:-rotate-6:focus {
  --tw-rotate: -6deg;
}
.focus\:-rotate-3:focus {
  --tw-rotate: -3deg;
}
.focus\:-rotate-2:focus {
  --tw-rotate: -2deg;
}
.focus\:-rotate-1:focus {
  --tw-rotate: -1deg;
}
.skew-x-0 {
  --tw-skew-x: 0deg;
}
.skew-x-1 {
  --tw-skew-x: 1deg;
}
.skew-x-2 {
  --tw-skew-x: 2deg;
}
.skew-x-3 {
  --tw-skew-x: 3deg;
}
.skew-x-6 {
  --tw-skew-x: 6deg;
}
.skew-x-12 {
  --tw-skew-x: 12deg;
}
.-skew-x-12 {
  --tw-skew-x: -12deg;
}
.-skew-x-6 {
  --tw-skew-x: -6deg;
}
.-skew-x-3 {
  --tw-skew-x: -3deg;
}
.-skew-x-2 {
  --tw-skew-x: -2deg;
}
.-skew-x-1 {
  --tw-skew-x: -1deg;
}
.skew-y-0 {
  --tw-skew-y: 0deg;
}
.skew-y-1 {
  --tw-skew-y: 1deg;
}
.skew-y-2 {
  --tw-skew-y: 2deg;
}
.skew-y-3 {
  --tw-skew-y: 3deg;
}
.skew-y-6 {
  --tw-skew-y: 6deg;
}
.skew-y-12 {
  --tw-skew-y: 12deg;
}
.-skew-y-12 {
  --tw-skew-y: -12deg;
}
.-skew-y-6 {
  --tw-skew-y: -6deg;
}
.-skew-y-3 {
  --tw-skew-y: -3deg;
}
.-skew-y-2 {
  --tw-skew-y: -2deg;
}
.-skew-y-1 {
  --tw-skew-y: -1deg;
}
.hover\:skew-x-0:hover {
  --tw-skew-x: 0deg;
}
.hover\:skew-x-1:hover {
  --tw-skew-x: 1deg;
}
.hover\:skew-x-2:hover {
  --tw-skew-x: 2deg;
}
.hover\:skew-x-3:hover {
  --tw-skew-x: 3deg;
}
.hover\:skew-x-6:hover {
  --tw-skew-x: 6deg;
}
.hover\:skew-x-12:hover {
  --tw-skew-x: 12deg;
}
.hover\:-skew-x-12:hover {
  --tw-skew-x: -12deg;
}
.hover\:-skew-x-6:hover {
  --tw-skew-x: -6deg;
}
.hover\:-skew-x-3:hover {
  --tw-skew-x: -3deg;
}
.hover\:-skew-x-2:hover {
  --tw-skew-x: -2deg;
}
.hover\:-skew-x-1:hover {
  --tw-skew-x: -1deg;
}
.hover\:skew-y-0:hover {
  --tw-skew-y: 0deg;
}
.hover\:skew-y-1:hover {
  --tw-skew-y: 1deg;
}
.hover\:skew-y-2:hover {
  --tw-skew-y: 2deg;
}
.hover\:skew-y-3:hover {
  --tw-skew-y: 3deg;
}
.hover\:skew-y-6:hover {
  --tw-skew-y: 6deg;
}
.hover\:skew-y-12:hover {
  --tw-skew-y: 12deg;
}
.hover\:-skew-y-12:hover {
  --tw-skew-y: -12deg;
}
.hover\:-skew-y-6:hover {
  --tw-skew-y: -6deg;
}
.hover\:-skew-y-3:hover {
  --tw-skew-y: -3deg;
}
.hover\:-skew-y-2:hover {
  --tw-skew-y: -2deg;
}
.hover\:-skew-y-1:hover {
  --tw-skew-y: -1deg;
}
.focus\:skew-x-0:focus {
  --tw-skew-x: 0deg;
}
.focus\:skew-x-1:focus {
  --tw-skew-x: 1deg;
}
.focus\:skew-x-2:focus {
  --tw-skew-x: 2deg;
}
.focus\:skew-x-3:focus {
  --tw-skew-x: 3deg;
}
.focus\:skew-x-6:focus {
  --tw-skew-x: 6deg;
}
.focus\:skew-x-12:focus {
  --tw-skew-x: 12deg;
}
.focus\:-skew-x-12:focus {
  --tw-skew-x: -12deg;
}
.focus\:-skew-x-6:focus {
  --tw-skew-x: -6deg;
}
.focus\:-skew-x-3:focus {
  --tw-skew-x: -3deg;
}
.focus\:-skew-x-2:focus {
  --tw-skew-x: -2deg;
}
.focus\:-skew-x-1:focus {
  --tw-skew-x: -1deg;
}
.focus\:skew-y-0:focus {
  --tw-skew-y: 0deg;
}
.focus\:skew-y-1:focus {
  --tw-skew-y: 1deg;
}
.focus\:skew-y-2:focus {
  --tw-skew-y: 2deg;
}
.focus\:skew-y-3:focus {
  --tw-skew-y: 3deg;
}
.focus\:skew-y-6:focus {
  --tw-skew-y: 6deg;
}
.focus\:skew-y-12:focus {
  --tw-skew-y: 12deg;
}
.focus\:-skew-y-12:focus {
  --tw-skew-y: -12deg;
}
.focus\:-skew-y-6:focus {
  --tw-skew-y: -6deg;
}
.focus\:-skew-y-3:focus {
  --tw-skew-y: -3deg;
}
.focus\:-skew-y-2:focus {
  --tw-skew-y: -2deg;
}
.focus\:-skew-y-1:focus {
  --tw-skew-y: -1deg;
}
.scale-0 {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}
.scale-50 {
  --tw-scale-x: 0.5;
  --tw-scale-y: 0.5;
}
.scale-75 {
  --tw-scale-x: 0.75;
  --tw-scale-y: 0.75;
}
.scale-90 {
  --tw-scale-x: 0.9;
  --tw-scale-y: 0.9;
}
.scale-95 {
  --tw-scale-x: 0.95;
  --tw-scale-y: 0.95;
}
.scale-100 {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}
.scale-105 {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}
.scale-110 {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}
.scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}
.scale-150 {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}
.hover\:scale-0:hover {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}
.hover\:scale-50:hover {
  --tw-scale-x: 0.5;
  --tw-scale-y: 0.5;
}
.hover\:scale-75:hover {
  --tw-scale-x: 0.75;
  --tw-scale-y: 0.75;
}
.hover\:scale-90:hover {
  --tw-scale-x: 0.9;
  --tw-scale-y: 0.9;
}
.hover\:scale-95:hover {
  --tw-scale-x: 0.95;
  --tw-scale-y: 0.95;
}
.hover\:scale-100:hover {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}
.hover\:scale-105:hover {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}
.hover\:scale-110:hover {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}
.hover\:scale-125:hover {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}
.hover\:scale-150:hover {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}
.focus\:scale-0:focus {
  --tw-scale-x: 0;
  --tw-scale-y: 0;
}
.focus\:scale-50:focus {
  --tw-scale-x: 0.5;
  --tw-scale-y: 0.5;
}
.focus\:scale-75:focus {
  --tw-scale-x: 0.75;
  --tw-scale-y: 0.75;
}
.focus\:scale-90:focus {
  --tw-scale-x: 0.9;
  --tw-scale-y: 0.9;
}
.focus\:scale-95:focus {
  --tw-scale-x: 0.95;
  --tw-scale-y: 0.95;
}
.focus\:scale-100:focus {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}
.focus\:scale-105:focus {
  --tw-scale-x: 1.05;
  --tw-scale-y: 1.05;
}
.focus\:scale-110:focus {
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
}
.focus\:scale-125:focus {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
}
.focus\:scale-150:focus {
  --tw-scale-x: 1.5;
  --tw-scale-y: 1.5;
}
.scale-x-0 {
  --tw-scale-x: 0;
}
.scale-x-50 {
  --tw-scale-x: 0.5;
}
.scale-x-75 {
  --tw-scale-x: 0.75;
}
.scale-x-90 {
  --tw-scale-x: 0.9;
}
.scale-x-95 {
  --tw-scale-x: 0.95;
}
.scale-x-100 {
  --tw-scale-x: 1;
}
.scale-x-105 {
  --tw-scale-x: 1.05;
}
.scale-x-110 {
  --tw-scale-x: 1.1;
}
.scale-x-125 {
  --tw-scale-x: 1.25;
}
.scale-x-150 {
  --tw-scale-x: 1.5;
}
.scale-y-0 {
  --tw-scale-y: 0;
}
.scale-y-50 {
  --tw-scale-y: 0.5;
}
.scale-y-75 {
  --tw-scale-y: 0.75;
}
.scale-y-90 {
  --tw-scale-y: 0.9;
}
.scale-y-95 {
  --tw-scale-y: 0.95;
}
.scale-y-100 {
  --tw-scale-y: 1;
}
.scale-y-105 {
  --tw-scale-y: 1.05;
}
.scale-y-110 {
  --tw-scale-y: 1.1;
}
.scale-y-125 {
  --tw-scale-y: 1.25;
}
.scale-y-150 {
  --tw-scale-y: 1.5;
}
.hover\:scale-x-0:hover {
  --tw-scale-x: 0;
}
.hover\:scale-x-50:hover {
  --tw-scale-x: 0.5;
}
.hover\:scale-x-75:hover {
  --tw-scale-x: 0.75;
}
.hover\:scale-x-90:hover {
  --tw-scale-x: 0.9;
}
.hover\:scale-x-95:hover {
  --tw-scale-x: 0.95;
}
.hover\:scale-x-100:hover {
  --tw-scale-x: 1;
}
.hover\:scale-x-105:hover {
  --tw-scale-x: 1.05;
}
.hover\:scale-x-110:hover {
  --tw-scale-x: 1.1;
}
.hover\:scale-x-125:hover {
  --tw-scale-x: 1.25;
}
.hover\:scale-x-150:hover {
  --tw-scale-x: 1.5;
}
.hover\:scale-y-0:hover {
  --tw-scale-y: 0;
}
.hover\:scale-y-50:hover {
  --tw-scale-y: 0.5;
}
.hover\:scale-y-75:hover {
  --tw-scale-y: 0.75;
}
.hover\:scale-y-90:hover {
  --tw-scale-y: 0.9;
}
.hover\:scale-y-95:hover {
  --tw-scale-y: 0.95;
}
.hover\:scale-y-100:hover {
  --tw-scale-y: 1;
}
.hover\:scale-y-105:hover {
  --tw-scale-y: 1.05;
}
.hover\:scale-y-110:hover {
  --tw-scale-y: 1.1;
}
.hover\:scale-y-125:hover {
  --tw-scale-y: 1.25;
}
.hover\:scale-y-150:hover {
  --tw-scale-y: 1.5;
}
.focus\:scale-x-0:focus {
  --tw-scale-x: 0;
}
.focus\:scale-x-50:focus {
  --tw-scale-x: 0.5;
}
.focus\:scale-x-75:focus {
  --tw-scale-x: 0.75;
}
.focus\:scale-x-90:focus {
  --tw-scale-x: 0.9;
}
.focus\:scale-x-95:focus {
  --tw-scale-x: 0.95;
}
.focus\:scale-x-100:focus {
  --tw-scale-x: 1;
}
.focus\:scale-x-105:focus {
  --tw-scale-x: 1.05;
}
.focus\:scale-x-110:focus {
  --tw-scale-x: 1.1;
}
.focus\:scale-x-125:focus {
  --tw-scale-x: 1.25;
}
.focus\:scale-x-150:focus {
  --tw-scale-x: 1.5;
}
.focus\:scale-y-0:focus {
  --tw-scale-y: 0;
}
.focus\:scale-y-50:focus {
  --tw-scale-y: 0.5;
}
.focus\:scale-y-75:focus {
  --tw-scale-y: 0.75;
}
.focus\:scale-y-90:focus {
  --tw-scale-y: 0.9;
}
.focus\:scale-y-95:focus {
  --tw-scale-y: 0.95;
}
.focus\:scale-y-100:focus {
  --tw-scale-y: 1;
}
.focus\:scale-y-105:focus {
  --tw-scale-y: 1.05;
}
.focus\:scale-y-110:focus {
  --tw-scale-y: 1.1;
}
.focus\:scale-y-125:focus {
  --tw-scale-y: 1.25;
}
.focus\:scale-y-150:focus {
  --tw-scale-y: 1.5;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@keyframes ping {
  100%,
  75% {
    transform: scale(2);
    opacity: 0;
  }
}
@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
@keyframes bounce {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
.animate-none {
  animation: none;
}
.animate-spin {
  animation: spin 1s linear infinite;
}
.animate-ping {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
.animate-bounce {
  animation: bounce 1s infinite;
}
.cursor-auto {
  cursor: auto;
}
.cursor-default {
  cursor: default;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-wait {
  cursor: wait;
}
.cursor-text {
  cursor: text;
}
.cursor-move {
  cursor: move;
}
.cursor-help {
  cursor: help;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.select-none {
  -webkit-user-select: none;
  user-select: none;
}
.select-text {
  -webkit-user-select: text;
  user-select: text;
}
.select-all {
  -webkit-user-select: all;
  user-select: all;
}
.select-auto {
  -webkit-user-select: auto;
  user-select: auto;
}
.resize-none {
  resize: none;
}
.resize-y {
  resize: vertical;
}
.resize-x {
  resize: horizontal;
}
.resize {
  resize: both;
}
.list-inside {
  list-style-position: inside;
}
.list-outside {
  list-style-position: outside;
}
.list-none {
  list-style-type: none;
}
.list-disc {
  list-style-type: disc;
}
.list-decimal {
  list-style-type: decimal;
}
.appearance-none {
  -webkit-appearance: none;
  appearance: none;
}
.auto-cols-auto {
  grid-auto-columns: auto;
}
.auto-cols-min {
  grid-auto-columns: min-content;
}
.auto-cols-max {
  grid-auto-columns: max-content;
}
.auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr);
}
.grid-flow-row {
  grid-auto-flow: row;
}
.grid-flow-col {
  grid-auto-flow: column;
}
.grid-flow-row-dense {
  grid-auto-flow: row dense;
}
.grid-flow-col-dense {
  grid-auto-flow: column dense;
}
.auto-rows-auto {
  grid-auto-rows: auto;
}
.auto-rows-min {
  grid-auto-rows: min-content;
}
.auto-rows-max {
  grid-auto-rows: max-content;
}
.auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr);
}
.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}
.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}
.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}
.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}
.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}
.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}
.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}
.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}
.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}
.grid-cols-none {
  grid-template-columns: none;
}
.grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr));
}
.grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}
.grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}
.grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr));
}
.grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr));
}
.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}
.grid-rows-none {
  grid-template-rows: none;
}
.flex-row {
  flex-direction: row;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.place-content-center {
  place-content: center;
}
.place-content-start {
  place-content: start;
}
.place-content-end {
  place-content: end;
}
.place-content-between {
  place-content: space-between;
}
.place-content-around {
  place-content: space-around;
}
.place-content-evenly {
  place-content: space-evenly;
}
.place-content-stretch {
  place-content: stretch;
}
.place-items-start {
  place-items: start;
}
.place-items-end {
  place-items: end;
}
.place-items-center {
  place-items: center;
}
.place-items-stretch {
  place-items: stretch;
}
.content-center {
  align-content: center;
}
.content-start {
  align-content: flex-start;
}
.content-end {
  align-content: flex-end;
}
.content-between {
  align-content: space-between;
}
.content-around {
  align-content: space-around;
}
.content-evenly {
  align-content: space-evenly;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.items-center {
  align-items: center;
}
.items-baseline {
  align-items: baseline;
}
.items-stretch {
  align-items: stretch;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-items-start {
  justify-items: start;
}
.justify-items-end {
  justify-items: end;
}
.justify-items-center {
  justify-items: center;
}
.justify-items-stretch {
  justify-items: stretch;
}
.gap-0 {
  gap: 0;
}
.gap-1 {
  gap: 0.25rem;
}
.gap-2 {
  gap: 0.5rem;
}
.gap-3 {
  gap: 0.75rem;
}
.gap-4 {
  gap: 1rem;
}
.gap-5 {
  gap: 1.25rem;
}
.gap-6 {
  gap: 1.5rem;
}
.gap-7 {
  gap: 1.75rem;
}
.gap-8 {
  gap: 2rem;
}
.gap-9 {
  gap: 2.25rem;
}
.gap-10 {
  gap: 2.5rem;
}
.gap-11 {
  gap: 2.75rem;
}
.gap-12 {
  gap: 3rem;
}
.gap-14 {
  gap: 3.5rem;
}
.gap-16 {
  gap: 4rem;
}
.gap-20 {
  gap: 5rem;
}
.gap-24 {
  gap: 6rem;
}
.gap-28 {
  gap: 7rem;
}
.gap-32 {
  gap: 8rem;
}
.gap-36 {
  gap: 9rem;
}
.gap-40 {
  gap: 10rem;
}
.gap-44 {
  gap: 11rem;
}
.gap-48 {
  gap: 12rem;
}
.gap-52 {
  gap: 13rem;
}
.gap-56 {
  gap: 14rem;
}
.gap-60 {
  gap: 15rem;
}
.gap-64 {
  gap: 16rem;
}
.gap-72 {
  gap: 18rem;
}
.gap-80 {
  gap: 20rem;
}
.gap-96 {
  gap: 24rem;
}
.gap-px {
  gap: 1px;
}
.gap-0\.5 {
  gap: 0.125rem;
}
.gap-1\.5 {
  gap: 0.375rem;
}
.gap-2\.5 {
  gap: 0.625rem;
}
.gap-3\.5 {
  gap: 0.875rem;
}
.gap-x-0 {
  column-gap: 0;
}
.gap-x-1 {
  column-gap: 0.25rem;
}
.gap-x-2 {
  column-gap: 0.5rem;
}
.gap-x-3 {
  column-gap: 0.75rem;
}
.gap-x-4 {
  column-gap: 1rem;
}
.gap-x-5 {
  column-gap: 1.25rem;
}
.gap-x-6 {
  column-gap: 1.5rem;
}
.gap-x-7 {
  column-gap: 1.75rem;
}
.gap-x-8 {
  column-gap: 2rem;
}
.gap-x-9 {
  column-gap: 2.25rem;
}
.gap-x-10 {
  column-gap: 2.5rem;
}
.gap-x-11 {
  column-gap: 2.75rem;
}
.gap-x-12 {
  column-gap: 3rem;
}
.gap-x-14 {
  column-gap: 3.5rem;
}
.gap-x-16 {
  column-gap: 4rem;
}
.gap-x-20 {
  column-gap: 5rem;
}
.gap-x-24 {
  column-gap: 6rem;
}
.gap-x-28 {
  column-gap: 7rem;
}
.gap-x-32 {
  column-gap: 8rem;
}
.gap-x-36 {
  column-gap: 9rem;
}
.gap-x-40 {
  column-gap: 10rem;
}
.gap-x-44 {
  column-gap: 11rem;
}
.gap-x-48 {
  column-gap: 12rem;
}
.gap-x-52 {
  column-gap: 13rem;
}
.gap-x-56 {
  column-gap: 14rem;
}
.gap-x-60 {
  column-gap: 15rem;
}
.gap-x-64 {
  column-gap: 16rem;
}
.gap-x-72 {
  column-gap: 18rem;
}
.gap-x-80 {
  column-gap: 20rem;
}
.gap-x-96 {
  column-gap: 24rem;
}
.gap-x-px {
  column-gap: 1px;
}
.gap-x-0\.5 {
  column-gap: 0.125rem;
}
.gap-x-1\.5 {
  column-gap: 0.375rem;
}
.gap-x-2\.5 {
  column-gap: 0.625rem;
}
.gap-x-3\.5 {
  column-gap: 0.875rem;
}
.gap-y-0 {
  row-gap: 0;
}
.gap-y-1 {
  row-gap: 0.25rem;
}
.gap-y-2 {
  row-gap: 0.5rem;
}
.gap-y-3 {
  row-gap: 0.75rem;
}
.gap-y-4 {
  row-gap: 1rem;
}
.gap-y-5 {
  row-gap: 1.25rem;
}
.gap-y-6 {
  row-gap: 1.5rem;
}
.gap-y-7 {
  row-gap: 1.75rem;
}
.gap-y-8 {
  row-gap: 2rem;
}
.gap-y-9 {
  row-gap: 2.25rem;
}
.gap-y-10 {
  row-gap: 2.5rem;
}
.gap-y-11 {
  row-gap: 2.75rem;
}
.gap-y-12 {
  row-gap: 3rem;
}
.gap-y-14 {
  row-gap: 3.5rem;
}
.gap-y-16 {
  row-gap: 4rem;
}
.gap-y-20 {
  row-gap: 5rem;
}
.gap-y-24 {
  row-gap: 6rem;
}
.gap-y-28 {
  row-gap: 7rem;
}
.gap-y-32 {
  row-gap: 8rem;
}
.gap-y-36 {
  row-gap: 9rem;
}
.gap-y-40 {
  row-gap: 10rem;
}
.gap-y-44 {
  row-gap: 11rem;
}
.gap-y-48 {
  row-gap: 12rem;
}
.gap-y-52 {
  row-gap: 13rem;
}
.gap-y-56 {
  row-gap: 14rem;
}
.gap-y-60 {
  row-gap: 15rem;
}
.gap-y-64 {
  row-gap: 16rem;
}
.gap-y-72 {
  row-gap: 18rem;
}
.gap-y-80 {
  row-gap: 20rem;
}
.gap-y-96 {
  row-gap: 24rem;
}
.gap-y-px {
  row-gap: 1px;
}
.gap-y-0\.5 {
  row-gap: 0.125rem;
}
.gap-y-1\.5 {
  row-gap: 0.375rem;
}
.gap-y-2\.5 {
  row-gap: 0.625rem;
}
.gap-y-3\.5 {
  row-gap: 0.875rem;
}
.space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3rem * var(--tw-space-x-reverse));
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4rem * var(--tw-space-x-reverse));
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(5rem * var(--tw-space-x-reverse));
  margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6rem * var(--tw-space-x-reverse));
  margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(7rem * var(--tw-space-x-reverse));
  margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8rem * var(--tw-space-x-reverse));
  margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(9rem * var(--tw-space-x-reverse));
  margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(10rem * var(--tw-space-x-reverse));
  margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(11rem * var(--tw-space-x-reverse));
  margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(12rem * var(--tw-space-x-reverse));
  margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(13rem * var(--tw-space-x-reverse));
  margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(14rem * var(--tw-space-x-reverse));
  margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(15rem * var(--tw-space-x-reverse));
  margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(16rem * var(--tw-space-x-reverse));
  margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(18rem * var(--tw-space-x-reverse));
  margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(20rem * var(--tw-space-x-reverse));
  margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(24rem * var(--tw-space-x-reverse));
  margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1px * var(--tw-space-x-reverse));
  margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.125rem * var(--tw-space-x-reverse));
  margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.625rem * var(--tw-space-x-reverse));
  margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.875rem * var(--tw-space-x-reverse));
  margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0px * var(--tw-space-x-reverse));
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1rem * var(--tw-space-x-reverse));
  margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2rem * var(--tw-space-x-reverse));
  margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
  margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3rem * var(--tw-space-x-reverse));
  margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
  margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-4rem * var(--tw-space-x-reverse));
  margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-5rem * var(--tw-space-x-reverse));
  margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-6rem * var(--tw-space-x-reverse));
  margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-7rem * var(--tw-space-x-reverse));
  margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-8rem * var(--tw-space-x-reverse));
  margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-9rem * var(--tw-space-x-reverse));
  margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-10rem * var(--tw-space-x-reverse));
  margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-11rem * var(--tw-space-x-reverse));
  margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-12rem * var(--tw-space-x-reverse));
  margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-13rem * var(--tw-space-x-reverse));
  margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-14rem * var(--tw-space-x-reverse));
  margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-15rem * var(--tw-space-x-reverse));
  margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-16rem * var(--tw-space-x-reverse));
  margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-18rem * var(--tw-space-x-reverse));
  margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-20rem * var(--tw-space-x-reverse));
  margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-24rem * var(--tw-space-x-reverse));
  margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-1px * var(--tw-space-x-reverse));
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)));
}
.-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
  margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)));
}
.space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}
.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}
.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}
.space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}
.space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}
.space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}
.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}
.space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
}
.space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}
.space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
}
.space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}
.space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
}
.space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4rem * var(--tw-space-y-reverse));
}
.space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(5rem * var(--tw-space-y-reverse));
}
.space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(6rem * var(--tw-space-y-reverse));
}
.space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(7rem * var(--tw-space-y-reverse));
}
.space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(8rem * var(--tw-space-y-reverse));
}
.space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(9rem * var(--tw-space-y-reverse));
}
.space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(10rem * var(--tw-space-y-reverse));
}
.space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(11rem * var(--tw-space-y-reverse));
}
.space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(12rem * var(--tw-space-y-reverse));
}
.space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(13rem * var(--tw-space-y-reverse));
}
.space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(14rem * var(--tw-space-y-reverse));
}
.space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(15rem * var(--tw-space-y-reverse));
}
.space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(16rem * var(--tw-space-y-reverse));
}
.space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(18rem * var(--tw-space-y-reverse));
}
.space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(20rem * var(--tw-space-y-reverse));
}
.space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(24rem * var(--tw-space-y-reverse));
}
.space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1px * var(--tw-space-y-reverse));
}
.space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
}
.space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
}
.space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
}
.space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
}
.-space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0px * var(--tw-space-y-reverse));
}
.-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
}
.-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
}
.-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
}
.-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
}
.-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
}
.-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
}
.-space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
}
.-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
}
.-space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
}
.-space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
}
.-space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
}
.-space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
}
.-space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
}
.-space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
}
.-space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
}
.-space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
}
.-space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
}
.-space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
}
.-space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
}
.-space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
}
.-space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
}
.-space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
}
.-space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
}
.-space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
}
.-space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
}
.-space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
}
.-space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
}
.-space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
}
.-space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
}
.-space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-1px * var(--tw-space-y-reverse));
}
.-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
}
.-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
}
.-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
}
.-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
}
.space-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 1;
}
.space-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1;
}
.divide-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(0px * var(--tw-divide-x-reverse));
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
}
.divide-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(2px * var(--tw-divide-x-reverse));
  border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
}
.divide-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(4px * var(--tw-divide-x-reverse));
  border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
}
.divide-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(8px * var(--tw-divide-x-reverse));
  border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
}
.divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0;
  border-right-width: calc(1px * var(--tw-divide-x-reverse));
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
}
.divide-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
}
.divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
}
.divide-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
}
.divide-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
}
.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}
.divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 1;
}
.divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 1;
}
.divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid;
}
.divide-dashed > :not([hidden]) ~ :not([hidden]) {
  border-style: dashed;
}
.divide-dotted > :not([hidden]) ~ :not([hidden]) {
  border-style: dotted;
}
.divide-double > :not([hidden]) ~ :not([hidden]) {
  border-style: double;
}
.divide-none > :not([hidden]) ~ :not([hidden]) {
  border-style: none;
}
.divide-transparent > :not([hidden]) ~ :not([hidden]) {
  border-color: transparent;
}
.divide-current > :not([hidden]) ~ :not([hidden]) {
  border-color: currentColor;
}
.divide-black > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
}
.divide-white > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
}
.divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
}
.divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
}
.divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
}
.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
}
.divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
}
.divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
}
.divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
}
.divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
}
.divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
}
.divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
}
.divide-red-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
}
.divide-red-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
}
.divide-red-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
}
.divide-red-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
}
.divide-red-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
}
.divide-red-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
}
.divide-red-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
}
.divide-red-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
}
.divide-red-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
}
.divide-red-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
}
.divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
}
.divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
}
.divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
}
.divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
}
.divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
}
.divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
}
.divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
}
.divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
}
.divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
}
.divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
}
.divide-green-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
}
.divide-green-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
}
.divide-green-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
}
.divide-green-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
}
.divide-green-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
}
.divide-green-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
}
.divide-green-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
}
.divide-green-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
}
.divide-green-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
}
.divide-green-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
}
.divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
}
.divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
}
.divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
}
.divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
}
.divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
}
.divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
}
.divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
}
.divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
}
.divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
}
.divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
}
.divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
}
.divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
}
.divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
}
.divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
}
.divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
}
.divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
}
.divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
}
.divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
}
.divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
}
.divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
}
.divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
}
.divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
}
.divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
}
.divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
}
.divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
}
.divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
}
.divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
}
.divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
}
.divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
}
.divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
}
.divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
}
.divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
}
.divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
}
.divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
}
.divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
}
.divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
}
.divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
}
.divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
}
.divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
}
.divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
}
.divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0;
}
.divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.05;
}
.divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.1;
}
.divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.2;
}
.divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.25;
}
.divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.3;
}
.divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.4;
}
.divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.5;
}
.divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.6;
}
.divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.7;
}
.divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.75;
}
.divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.8;
}
.divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.9;
}
.divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.95;
}
.divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
}
.place-self-auto {
  place-self: auto;
}
.place-self-start {
  place-self: start;
}
.place-self-end {
  place-self: end;
}
.place-self-center {
  place-self: center;
}
.place-self-stretch {
  place-self: stretch;
}
.self-auto {
  align-self: auto;
}
.self-start {
  align-self: flex-start;
}
.self-end {
  align-self: flex-end;
}
.self-center {
  align-self: center;
}
.self-stretch {
  align-self: stretch;
}
.self-baseline {
  align-self: baseline;
}
.justify-self-auto {
  justify-self: auto;
}
.justify-self-start {
  justify-self: start;
}
.justify-self-end {
  justify-self: end;
}
.justify-self-center {
  justify-self: center;
}
.justify-self-stretch {
  justify-self: stretch;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.overflow-visible {
  overflow: visible;
}
.overflow-scroll {
  overflow: scroll;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-hidden {
  overflow-x: hidden;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.overflow-x-visible {
  overflow-x: visible;
}
.overflow-y-visible {
  overflow-y: visible;
}
.overflow-x-scroll {
  overflow-x: scroll;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.overscroll-auto {
  overscroll-behavior: auto;
}
.overscroll-contain {
  overscroll-behavior: contain;
}
.overscroll-none {
  overscroll-behavior: none;
}
.overscroll-y-auto {
  overscroll-behavior-y: auto;
}
.overscroll-y-contain {
  overscroll-behavior-y: contain;
}
.overscroll-y-none {
  overscroll-behavior-y: none;
}
.overscroll-x-auto {
  overscroll-behavior-x: auto;
}
.overscroll-x-contain {
  overscroll-behavior-x: contain;
}
.overscroll-x-none {
  overscroll-behavior-x: none;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.overflow-ellipsis {
  text-overflow: ellipsis;
}
.overflow-clip {
  text-overflow: clip;
}
.whitespace-normal {
  white-space: normal;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.whitespace-pre {
  white-space: pre;
}
.whitespace-pre-line {
  white-space: pre-line;
}
.whitespace-pre-wrap {
  white-space: pre-wrap;
}
.break-normal {
  overflow-wrap: normal;
  word-break: normal;
}
.break-words {
  overflow-wrap: break-word;
}
.break-all {
  word-break: break-all;
}
.rounded-none {
  border-radius: 0;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.rounded {
  border-radius: 0.25rem;
}
.rounded-md {
  border-radius: 0.375rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-xl {
  border-radius: 0.75rem;
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded-3xl {
  border-radius: 1.5rem;
}
.rounded-full {
  border-radius: 9999px;
}
.rounded-t-none {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rounded-t-sm {
  border-top-left-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
}
.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.rounded-t-md {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}
.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.rounded-t-xl {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
}
.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.rounded-t-3xl {
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
}
.rounded-t-full {
  border-top-left-radius: 9999px;
  border-top-right-radius: 9999px;
}
.rounded-r-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.rounded-r-sm {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}
.rounded-r {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.rounded-r-md {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
.rounded-r-xl {
  border-top-right-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.rounded-r-3xl {
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}
.rounded-r-full {
  border-top-right-radius: 9999px;
  border-bottom-right-radius: 9999px;
}
.rounded-b-none {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.rounded-b-sm {
  border-bottom-right-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}
.rounded-b {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.rounded-b-md {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.rounded-b-xl {
  border-bottom-right-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.rounded-b-3xl {
  border-bottom-right-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}
.rounded-b-full {
  border-bottom-right-radius: 9999px;
  border-bottom-left-radius: 9999px;
}
.rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rounded-l-sm {
  border-top-left-radius: 0.125rem;
  border-bottom-left-radius: 0.125rem;
}
.rounded-l {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.rounded-l-md {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}
.rounded-l-lg {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.rounded-l-xl {
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
}
.rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.rounded-l-3xl {
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}
.rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}
.rounded-tl-none {
  border-top-left-radius: 0;
}
.rounded-tl-sm {
  border-top-left-radius: 0.125rem;
}
.rounded-tl {
  border-top-left-radius: 0.25rem;
}
.rounded-tl-md {
  border-top-left-radius: 0.375rem;
}
.rounded-tl-lg {
  border-top-left-radius: 0.5rem;
}
.rounded-tl-xl {
  border-top-left-radius: 0.75rem;
}
.rounded-tl-2xl {
  border-top-left-radius: 1rem;
}
.rounded-tl-3xl {
  border-top-left-radius: 1.5rem;
}
.rounded-tl-full {
  border-top-left-radius: 9999px;
}
.rounded-tr-none {
  border-top-right-radius: 0;
}
.rounded-tr-sm {
  border-top-right-radius: 0.125rem;
}
.rounded-tr {
  border-top-right-radius: 0.25rem;
}
.rounded-tr-md {
  border-top-right-radius: 0.375rem;
}
.rounded-tr-lg {
  border-top-right-radius: 0.5rem;
}
.rounded-tr-xl {
  border-top-right-radius: 0.75rem;
}
.rounded-tr-2xl {
  border-top-right-radius: 1rem;
}
.rounded-tr-3xl {
  border-top-right-radius: 1.5rem;
}
.rounded-tr-full {
  border-top-right-radius: 9999px;
}
.rounded-br-none {
  border-bottom-right-radius: 0;
}
.rounded-br-sm {
  border-bottom-right-radius: 0.125rem;
}
.rounded-br {
  border-bottom-right-radius: 0.25rem;
}
.rounded-br-md {
  border-bottom-right-radius: 0.375rem;
}
.rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}
.rounded-br-xl {
  border-bottom-right-radius: 0.75rem;
}
.rounded-br-2xl {
  border-bottom-right-radius: 1rem;
}
.rounded-br-3xl {
  border-bottom-right-radius: 1.5rem;
}
.rounded-br-full {
  border-bottom-right-radius: 9999px;
}
.rounded-bl-none {
  border-bottom-left-radius: 0;
}
.rounded-bl-sm {
  border-bottom-left-radius: 0.125rem;
}
.rounded-bl {
  border-bottom-left-radius: 0.25rem;
}
.rounded-bl-md {
  border-bottom-left-radius: 0.375rem;
}
.rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}
.rounded-bl-xl {
  border-bottom-left-radius: 0.75rem;
}
.rounded-bl-2xl {
  border-bottom-left-radius: 1rem;
}
.rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem;
}
.rounded-bl-full {
  border-bottom-left-radius: 9999px;
}
.border-0 {
  border-width: 0;
}
.border-2 {
  border-width: 2px;
}
.border-4 {
  border-width: 4px;
}
.border-8 {
  border-width: 8px;
}
.border {
  border-width: 1px;
}
.border-t-0 {
  border-top-width: 0;
}
.border-t-2 {
  border-top-width: 2px;
}
.border-t-4 {
  border-top-width: 4px;
}
.border-t-8 {
  border-top-width: 8px;
}
.border-t {
  border-top-width: 1px;
}
.border-r-0 {
  border-right-width: 0;
}
.border-r-2 {
  border-right-width: 2px;
}
.border-r-4 {
  border-right-width: 4px;
}
.border-r-8 {
  border-right-width: 8px;
}
.border-r {
  border-right-width: 1px;
}
.border-b-0 {
  border-bottom-width: 0;
}
.border-b-2 {
  border-bottom-width: 2px;
}
.border-b-4 {
  border-bottom-width: 4px;
}
.border-b-8 {
  border-bottom-width: 8px;
}
.border-b {
  border-bottom-width: 1px;
}
.border-l-0 {
  border-left-width: 0;
}
.border-l-2 {
  border-left-width: 2px;
}
.border-l-4 {
  border-left-width: 4px;
}
.border-l-8 {
  border-left-width: 8px;
}
.border-l {
  border-left-width: 1px;
}
.border-solid {
  border-style: solid;
}
.border-dashed {
  border-style: dashed;
}
.border-dotted {
  border-style: dotted;
}
.border-double {
  border-style: double;
}
.border-none {
  border-style: none;
}
.border-transparent {
  border-color: transparent;
}
.border-current {
  border-color: currentColor;
}
.border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}
.border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}
.border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}
.border-red-50 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}
.border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}
.border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}
.border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}
.border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}
.border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}
.border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}
.border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}
.border-yellow-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}
.border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}
.border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}
.border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}
.border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}
.border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}
.border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
.border-yellow-700 {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}
.border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}
.border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}
.border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}
.border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}
.border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}
.border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}
.border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}
.border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}
.border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}
.border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}
.border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}
.border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}
.border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}
.border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}
.border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}
.border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}
.border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}
.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}
.border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}
.border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}
.border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}
.border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}
.border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}
.border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}
.border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}
.border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}
.border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}
.border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}
.border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}
.border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}
.border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}
.border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}
.border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}
.border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}
.border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}
.border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}
.border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}
.border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}
.border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}
.border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}
.border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}
.border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}
.border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}
.border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}
.border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}
.border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}
.border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}
.border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-transparent {
  border-color: transparent;
}
.group:hover .group-hover\:border-current {
  border-color: currentColor;
}
.group:hover .group-hover\:border-black {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-700 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-gray-900 {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-50 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-200 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-400 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-600 {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-700 {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-800 {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-red-900 {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-50 {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-100 {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-200 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-300 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-400 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-500 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-600 {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-700 {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-800 {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-yellow-900 {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-50 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-100 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-200 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-300 {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-600 {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-700 {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-800 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-green-900 {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-50 {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-100 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-200 {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-300 {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-400 {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-600 {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-800 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-blue-900 {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-50 {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-100 {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-200 {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-300 {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-400 {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-500 {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-600 {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-700 {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-800 {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-indigo-900 {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-50 {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-300 {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-400 {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-500 {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-600 {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-800 {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-purple-900 {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-50 {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-100 {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-200 {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-300 {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-400 {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-600 {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-700 {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-800 {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}
.group:hover .group-hover\:border-pink-900 {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}
.focus-within\:border-transparent:focus-within {
  border-color: transparent;
}
.focus-within\:border-current:focus-within {
  border-color: currentColor;
}
.focus-within\:border-black:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.focus-within\:border-white:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.focus-within\:border-gray-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}
.focus-within\:border-gray-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.focus-within\:border-gray-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.focus-within\:border-gray-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.focus-within\:border-gray-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.focus-within\:border-gray-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.focus-within\:border-gray-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.focus-within\:border-gray-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.focus-within\:border-gray-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}
.focus-within\:border-gray-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}
.focus-within\:border-red-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}
.focus-within\:border-red-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}
.focus-within\:border-red-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}
.focus-within\:border-red-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}
.focus-within\:border-red-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}
.focus-within\:border-red-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.focus-within\:border-red-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.focus-within\:border-red-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}
.focus-within\:border-red-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}
.focus-within\:border-red-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}
.focus-within\:border-yellow-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}
.focus-within\:border-yellow-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}
.focus-within\:border-yellow-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}
.focus-within\:border-yellow-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}
.focus-within\:border-yellow-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}
.focus-within\:border-yellow-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}
.focus-within\:border-yellow-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
.focus-within\:border-yellow-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}
.focus-within\:border-yellow-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}
.focus-within\:border-yellow-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}
.focus-within\:border-green-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}
.focus-within\:border-green-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}
.focus-within\:border-green-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}
.focus-within\:border-green-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}
.focus-within\:border-green-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}
.focus-within\:border-green-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}
.focus-within\:border-green-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}
.focus-within\:border-green-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}
.focus-within\:border-green-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}
.focus-within\:border-green-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}
.focus-within\:border-blue-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}
.focus-within\:border-blue-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}
.focus-within\:border-blue-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}
.focus-within\:border-blue-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}
.focus-within\:border-blue-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}
.focus-within\:border-blue-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}
.focus-within\:border-blue-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.focus-within\:border-blue-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}
.focus-within\:border-blue-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}
.focus-within\:border-blue-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}
.focus-within\:border-indigo-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}
.focus-within\:border-indigo-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}
.focus-within\:border-indigo-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}
.focus-within\:border-indigo-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}
.focus-within\:border-indigo-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.focus-within\:border-indigo-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}
.focus-within\:border-indigo-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.focus-within\:border-indigo-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}
.focus-within\:border-indigo-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}
.focus-within\:border-indigo-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}
.focus-within\:border-purple-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}
.focus-within\:border-purple-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}
.focus-within\:border-purple-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}
.focus-within\:border-purple-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}
.focus-within\:border-purple-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}
.focus-within\:border-purple-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}
.focus-within\:border-purple-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.focus-within\:border-purple-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}
.focus-within\:border-purple-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}
.focus-within\:border-purple-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}
.focus-within\:border-pink-50:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}
.focus-within\:border-pink-100:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}
.focus-within\:border-pink-200:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}
.focus-within\:border-pink-300:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}
.focus-within\:border-pink-400:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}
.focus-within\:border-pink-500:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}
.focus-within\:border-pink-600:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}
.focus-within\:border-pink-700:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}
.focus-within\:border-pink-800:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}
.focus-within\:border-pink-900:focus-within {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}
.hover\:border-transparent:hover {
  border-color: transparent;
}
.hover\:border-current:hover {
  border-color: currentColor;
}
.hover\:border-black:hover {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.hover\:border-white:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.hover\:border-gray-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}
.hover\:border-gray-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.hover\:border-gray-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.hover\:border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.hover\:border-gray-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.hover\:border-gray-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.hover\:border-gray-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.hover\:border-gray-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.hover\:border-gray-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}
.hover\:border-gray-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}
.hover\:border-red-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}
.hover\:border-red-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}
.hover\:border-red-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}
.hover\:border-red-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}
.hover\:border-red-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}
.hover\:border-red-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.hover\:border-red-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.hover\:border-red-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}
.hover\:border-red-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}
.hover\:border-red-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}
.hover\:border-yellow-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}
.hover\:border-yellow-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}
.hover\:border-yellow-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}
.hover\:border-yellow-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}
.hover\:border-yellow-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}
.hover\:border-yellow-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}
.hover\:border-yellow-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
.hover\:border-yellow-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}
.hover\:border-yellow-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}
.hover\:border-yellow-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}
.hover\:border-green-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}
.hover\:border-green-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}
.hover\:border-green-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}
.hover\:border-green-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}
.hover\:border-green-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}
.hover\:border-green-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}
.hover\:border-green-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}
.hover\:border-green-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}
.hover\:border-green-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}
.hover\:border-green-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}
.hover\:border-blue-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}
.hover\:border-blue-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}
.hover\:border-blue-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}
.hover\:border-blue-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}
.hover\:border-blue-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}
.hover\:border-blue-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}
.hover\:border-blue-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.hover\:border-blue-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}
.hover\:border-blue-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}
.hover\:border-blue-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}
.hover\:border-indigo-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}
.hover\:border-indigo-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}
.hover\:border-indigo-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}
.hover\:border-indigo-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}
.hover\:border-indigo-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.hover\:border-indigo-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}
.hover\:border-indigo-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.hover\:border-indigo-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}
.hover\:border-indigo-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}
.hover\:border-indigo-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}
.hover\:border-purple-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}
.hover\:border-purple-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}
.hover\:border-purple-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}
.hover\:border-purple-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}
.hover\:border-purple-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}
.hover\:border-purple-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}
.hover\:border-purple-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.hover\:border-purple-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}
.hover\:border-purple-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}
.hover\:border-purple-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}
.hover\:border-pink-50:hover {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}
.hover\:border-pink-100:hover {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}
.hover\:border-pink-200:hover {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}
.hover\:border-pink-300:hover {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}
.hover\:border-pink-400:hover {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}
.hover\:border-pink-500:hover {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}
.hover\:border-pink-600:hover {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}
.hover\:border-pink-700:hover {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}
.hover\:border-pink-800:hover {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}
.hover\:border-pink-900:hover {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}
.focus\:border-transparent:focus {
  border-color: transparent;
}
.focus\:border-current:focus {
  border-color: currentColor;
}
.focus\:border-black:focus {
  --tw-border-opacity: 1;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity));
}
.focus\:border-white:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}
.focus\:border-gray-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(249, 250, 251, var(--tw-border-opacity));
}
.focus\:border-gray-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(243, 244, 246, var(--tw-border-opacity));
}
.focus\:border-gray-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--tw-border-opacity));
}
.focus\:border-gray-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
}
.focus\:border-gray-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(156, 163, 175, var(--tw-border-opacity));
}
.focus\:border-gray-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(107, 114, 128, var(--tw-border-opacity));
}
.focus\:border-gray-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(75, 85, 99, var(--tw-border-opacity));
}
.focus\:border-gray-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(55, 65, 81, var(--tw-border-opacity));
}
.focus\:border-gray-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(31, 41, 55, var(--tw-border-opacity));
}
.focus\:border-gray-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(17, 24, 39, var(--tw-border-opacity));
}
.focus\:border-red-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 242, 242, var(--tw-border-opacity));
}
.focus\:border-red-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 226, 226, var(--tw-border-opacity));
}
.focus\:border-red-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 202, 202, var(--tw-border-opacity));
}
.focus\:border-red-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(252, 165, 165, var(--tw-border-opacity));
}
.focus\:border-red-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(248, 113, 113, var(--tw-border-opacity));
}
.focus\:border-red-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 68, 68, var(--tw-border-opacity));
}
.focus\:border-red-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(220, 38, 38, var(--tw-border-opacity));
}
.focus\:border-red-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(185, 28, 28, var(--tw-border-opacity));
}
.focus\:border-red-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(153, 27, 27, var(--tw-border-opacity));
}
.focus\:border-red-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(127, 29, 29, var(--tw-border-opacity));
}
.focus\:border-yellow-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(255, 251, 235, var(--tw-border-opacity));
}
.focus\:border-yellow-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(254, 243, 199, var(--tw-border-opacity));
}
.focus\:border-yellow-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(253, 230, 138, var(--tw-border-opacity));
}
.focus\:border-yellow-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity));
}
.focus\:border-yellow-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(251, 191, 36, var(--tw-border-opacity));
}
.focus\:border-yellow-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 158, 11, var(--tw-border-opacity));
}
.focus\:border-yellow-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(217, 119, 6, var(--tw-border-opacity));
}
.focus\:border-yellow-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(180, 83, 9, var(--tw-border-opacity));
}
.focus\:border-yellow-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(146, 64, 14, var(--tw-border-opacity));
}
.focus\:border-yellow-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(120, 53, 15, var(--tw-border-opacity));
}
.focus\:border-green-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(236, 253, 245, var(--tw-border-opacity));
}
.focus\:border-green-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(209, 250, 229, var(--tw-border-opacity));
}
.focus\:border-green-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(167, 243, 208, var(--tw-border-opacity));
}
.focus\:border-green-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(110, 231, 183, var(--tw-border-opacity));
}
.focus\:border-green-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(52, 211, 153, var(--tw-border-opacity));
}
.focus\:border-green-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(16, 185, 129, var(--tw-border-opacity));
}
.focus\:border-green-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
}
.focus\:border-green-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(4, 120, 87, var(--tw-border-opacity));
}
.focus\:border-green-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(6, 95, 70, var(--tw-border-opacity));
}
.focus\:border-green-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(6, 78, 59, var(--tw-border-opacity));
}
.focus\:border-blue-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(239, 246, 255, var(--tw-border-opacity));
}
.focus\:border-blue-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(219, 234, 254, var(--tw-border-opacity));
}
.focus\:border-blue-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(191, 219, 254, var(--tw-border-opacity));
}
.focus\:border-blue-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(147, 197, 253, var(--tw-border-opacity));
}
.focus\:border-blue-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(96, 165, 250, var(--tw-border-opacity));
}
.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}
.focus\:border-blue-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(37, 99, 235, var(--tw-border-opacity));
}
.focus\:border-blue-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(29, 78, 216, var(--tw-border-opacity));
}
.focus\:border-blue-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(30, 64, 175, var(--tw-border-opacity));
}
.focus\:border-blue-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(30, 58, 138, var(--tw-border-opacity));
}
.focus\:border-indigo-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(238, 242, 255, var(--tw-border-opacity));
}
.focus\:border-indigo-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(224, 231, 255, var(--tw-border-opacity));
}
.focus\:border-indigo-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(199, 210, 254, var(--tw-border-opacity));
}
.focus\:border-indigo-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(165, 180, 252, var(--tw-border-opacity));
}
.focus\:border-indigo-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(129, 140, 248, var(--tw-border-opacity));
}
.focus\:border-indigo-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(99, 102, 241, var(--tw-border-opacity));
}
.focus\:border-indigo-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(79, 70, 229, var(--tw-border-opacity));
}
.focus\:border-indigo-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(67, 56, 202, var(--tw-border-opacity));
}
.focus\:border-indigo-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(55, 48, 163, var(--tw-border-opacity));
}
.focus\:border-indigo-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(49, 46, 129, var(--tw-border-opacity));
}
.focus\:border-purple-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(245, 243, 255, var(--tw-border-opacity));
}
.focus\:border-purple-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(237, 233, 254, var(--tw-border-opacity));
}
.focus\:border-purple-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(221, 214, 254, var(--tw-border-opacity));
}
.focus\:border-purple-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(196, 181, 253, var(--tw-border-opacity));
}
.focus\:border-purple-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(167, 139, 250, var(--tw-border-opacity));
}
.focus\:border-purple-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(139, 92, 246, var(--tw-border-opacity));
}
.focus\:border-purple-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(124, 58, 237, var(--tw-border-opacity));
}
.focus\:border-purple-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(109, 40, 217, var(--tw-border-opacity));
}
.focus\:border-purple-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(91, 33, 182, var(--tw-border-opacity));
}
.focus\:border-purple-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(76, 29, 149, var(--tw-border-opacity));
}
.focus\:border-pink-50:focus {
  --tw-border-opacity: 1;
  border-color: rgba(253, 242, 248, var(--tw-border-opacity));
}
.focus\:border-pink-100:focus {
  --tw-border-opacity: 1;
  border-color: rgba(252, 231, 243, var(--tw-border-opacity));
}
.focus\:border-pink-200:focus {
  --tw-border-opacity: 1;
  border-color: rgba(251, 207, 232, var(--tw-border-opacity));
}
.focus\:border-pink-300:focus {
  --tw-border-opacity: 1;
  border-color: rgba(249, 168, 212, var(--tw-border-opacity));
}
.focus\:border-pink-400:focus {
  --tw-border-opacity: 1;
  border-color: rgba(244, 114, 182, var(--tw-border-opacity));
}
.focus\:border-pink-500:focus {
  --tw-border-opacity: 1;
  border-color: rgba(236, 72, 153, var(--tw-border-opacity));
}
.focus\:border-pink-600:focus {
  --tw-border-opacity: 1;
  border-color: rgba(219, 39, 119, var(--tw-border-opacity));
}
.focus\:border-pink-700:focus {
  --tw-border-opacity: 1;
  border-color: rgba(190, 24, 93, var(--tw-border-opacity));
}
.focus\:border-pink-800:focus {
  --tw-border-opacity: 1;
  border-color: rgba(157, 23, 77, var(--tw-border-opacity));
}
.focus\:border-pink-900:focus {
  --tw-border-opacity: 1;
  border-color: rgba(131, 24, 67, var(--tw-border-opacity));
}
.border-opacity-0 {
  --tw-border-opacity: 0;
}
.border-opacity-5 {
  --tw-border-opacity: 0.05;
}
.border-opacity-10 {
  --tw-border-opacity: 0.1;
}
.border-opacity-20 {
  --tw-border-opacity: 0.2;
}
.border-opacity-25 {
  --tw-border-opacity: 0.25;
}
.border-opacity-30 {
  --tw-border-opacity: 0.3;
}
.border-opacity-40 {
  --tw-border-opacity: 0.4;
}
.border-opacity-50 {
  --tw-border-opacity: 0.5;
}
.border-opacity-60 {
  --tw-border-opacity: 0.6;
}
.border-opacity-70 {
  --tw-border-opacity: 0.7;
}
.border-opacity-75 {
  --tw-border-opacity: 0.75;
}
.border-opacity-80 {
  --tw-border-opacity: 0.8;
}
.border-opacity-90 {
  --tw-border-opacity: 0.9;
}
.border-opacity-95 {
  --tw-border-opacity: 0.95;
}
.border-opacity-100 {
  --tw-border-opacity: 1;
}
.group:hover .group-hover\:border-opacity-0 {
  --tw-border-opacity: 0;
}
.group:hover .group-hover\:border-opacity-5 {
  --tw-border-opacity: 0.05;
}
.group:hover .group-hover\:border-opacity-10 {
  --tw-border-opacity: 0.1;
}
.group:hover .group-hover\:border-opacity-20 {
  --tw-border-opacity: 0.2;
}
.group:hover .group-hover\:border-opacity-25 {
  --tw-border-opacity: 0.25;
}
.group:hover .group-hover\:border-opacity-30 {
  --tw-border-opacity: 0.3;
}
.group:hover .group-hover\:border-opacity-40 {
  --tw-border-opacity: 0.4;
}
.group:hover .group-hover\:border-opacity-50 {
  --tw-border-opacity: 0.5;
}
.group:hover .group-hover\:border-opacity-60 {
  --tw-border-opacity: 0.6;
}
.group:hover .group-hover\:border-opacity-70 {
  --tw-border-opacity: 0.7;
}
.group:hover .group-hover\:border-opacity-75 {
  --tw-border-opacity: 0.75;
}
.group:hover .group-hover\:border-opacity-80 {
  --tw-border-opacity: 0.8;
}
.group:hover .group-hover\:border-opacity-90 {
  --tw-border-opacity: 0.9;
}
.group:hover .group-hover\:border-opacity-95 {
  --tw-border-opacity: 0.95;
}
.group:hover .group-hover\:border-opacity-100 {
  --tw-border-opacity: 1;
}
.focus-within\:border-opacity-0:focus-within {
  --tw-border-opacity: 0;
}
.focus-within\:border-opacity-5:focus-within {
  --tw-border-opacity: 0.05;
}
.focus-within\:border-opacity-10:focus-within {
  --tw-border-opacity: 0.1;
}
.focus-within\:border-opacity-20:focus-within {
  --tw-border-opacity: 0.2;
}
.focus-within\:border-opacity-25:focus-within {
  --tw-border-opacity: 0.25;
}
.focus-within\:border-opacity-30:focus-within {
  --tw-border-opacity: 0.3;
}
.focus-within\:border-opacity-40:focus-within {
  --tw-border-opacity: 0.4;
}
.focus-within\:border-opacity-50:focus-within {
  --tw-border-opacity: 0.5;
}
.focus-within\:border-opacity-60:focus-within {
  --tw-border-opacity: 0.6;
}
.focus-within\:border-opacity-70:focus-within {
  --tw-border-opacity: 0.7;
}
.focus-within\:border-opacity-75:focus-within {
  --tw-border-opacity: 0.75;
}
.focus-within\:border-opacity-80:focus-within {
  --tw-border-opacity: 0.8;
}
.focus-within\:border-opacity-90:focus-within {
  --tw-border-opacity: 0.9;
}
.focus-within\:border-opacity-95:focus-within {
  --tw-border-opacity: 0.95;
}
.focus-within\:border-opacity-100:focus-within {
  --tw-border-opacity: 1;
}
.hover\:border-opacity-0:hover {
  --tw-border-opacity: 0;
}
.hover\:border-opacity-5:hover {
  --tw-border-opacity: 0.05;
}
.hover\:border-opacity-10:hover {
  --tw-border-opacity: 0.1;
}
.hover\:border-opacity-20:hover {
  --tw-border-opacity: 0.2;
}
.hover\:border-opacity-25:hover {
  --tw-border-opacity: 0.25;
}
.hover\:border-opacity-30:hover {
  --tw-border-opacity: 0.3;
}
.hover\:border-opacity-40:hover {
  --tw-border-opacity: 0.4;
}
.hover\:border-opacity-50:hover {
  --tw-border-opacity: 0.5;
}
.hover\:border-opacity-60:hover {
  --tw-border-opacity: 0.6;
}
.hover\:border-opacity-70:hover {
  --tw-border-opacity: 0.7;
}
.hover\:border-opacity-75:hover {
  --tw-border-opacity: 0.75;
}
.hover\:border-opacity-80:hover {
  --tw-border-opacity: 0.8;
}
.hover\:border-opacity-90:hover {
  --tw-border-opacity: 0.9;
}
.hover\:border-opacity-95:hover {
  --tw-border-opacity: 0.95;
}
.hover\:border-opacity-100:hover {
  --tw-border-opacity: 1;
}
.focus\:border-opacity-0:focus {
  --tw-border-opacity: 0;
}
.focus\:border-opacity-5:focus {
  --tw-border-opacity: 0.05;
}
.focus\:border-opacity-10:focus {
  --tw-border-opacity: 0.1;
}
.focus\:border-opacity-20:focus {
  --tw-border-opacity: 0.2;
}
.focus\:border-opacity-25:focus {
  --tw-border-opacity: 0.25;
}
.focus\:border-opacity-30:focus {
  --tw-border-opacity: 0.3;
}
.focus\:border-opacity-40:focus {
  --tw-border-opacity: 0.4;
}
.focus\:border-opacity-50:focus {
  --tw-border-opacity: 0.5;
}
.focus\:border-opacity-60:focus {
  --tw-border-opacity: 0.6;
}
.focus\:border-opacity-70:focus {
  --tw-border-opacity: 0.7;
}
.focus\:border-opacity-75:focus {
  --tw-border-opacity: 0.75;
}
.focus\:border-opacity-80:focus {
  --tw-border-opacity: 0.8;
}
.focus\:border-opacity-90:focus {
  --tw-border-opacity: 0.9;
}
.focus\:border-opacity-95:focus {
  --tw-border-opacity: 0.95;
}
.focus\:border-opacity-100:focus {
  --tw-border-opacity: 1;
}
.bg-transparent {
  background-color: transparent;
}
.bg-current {
  background-color: currentColor;
}
.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-transparent {
  background-color: transparent;
}
.group:hover .group-hover\:bg-current {
  background-color: currentColor;
}
.group:hover .group-hover\:bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-red-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-yellow-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-green-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-blue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-indigo-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-purple-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.group:hover .group-hover\:bg-pink-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.focus-within\:bg-transparent:focus-within {
  background-color: transparent;
}
.focus-within\:bg-current:focus-within {
  background-color: currentColor;
}
.focus-within\:bg-black:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.focus-within\:bg-white:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.focus-within\:bg-gray-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.focus-within\:bg-red-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.focus-within\:bg-red-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.focus-within\:bg-red-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.focus-within\:bg-red-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.focus-within\:bg-red-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.focus-within\:bg-red-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.focus-within\:bg-red-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.focus-within\:bg-red-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.focus-within\:bg-red-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.focus-within\:bg-red-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.focus-within\:bg-yellow-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.focus-within\:bg-green-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.focus-within\:bg-green-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.focus-within\:bg-green-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.focus-within\:bg-green-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.focus-within\:bg-green-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.focus-within\:bg-green-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.focus-within\:bg-green-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.focus-within\:bg-green-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.focus-within\:bg-green-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.focus-within\:bg-green-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.focus-within\:bg-blue-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.focus-within\:bg-indigo-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.focus-within\:bg-purple-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-50:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-100:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-200:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-300:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-400:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-500:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-600:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-700:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-800:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.focus-within\:bg-pink-900:focus-within {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.hover\:bg-transparent:hover {
  background-color: transparent;
}
.hover\:bg-current:hover {
  background-color: currentColor;
}
.hover\:bg-black:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.hover\:bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.hover\:bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.hover\:bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.hover\:bg-gray-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.hover\:bg-gray-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.hover\:bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.hover\:bg-gray-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.hover\:bg-gray-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.hover\:bg-gray-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.hover\:bg-red-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.hover\:bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.hover\:bg-red-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.hover\:bg-red-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.hover\:bg-red-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.hover\:bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.hover\:bg-red-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.hover\:bg-red-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.hover\:bg-red-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.hover\:bg-red-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.hover\:bg-yellow-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.hover\:bg-yellow-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.hover\:bg-yellow-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.hover\:bg-yellow-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.hover\:bg-yellow-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.hover\:bg-yellow-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.hover\:bg-yellow-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.hover\:bg-yellow-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.hover\:bg-yellow-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.hover\:bg-yellow-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.hover\:bg-green-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.hover\:bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.hover\:bg-green-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.hover\:bg-green-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.hover\:bg-green-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.hover\:bg-green-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.hover\:bg-green-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.hover\:bg-green-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.hover\:bg-green-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.hover\:bg-green-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.hover\:bg-blue-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.hover\:bg-blue-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.hover\:bg-blue-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.hover\:bg-blue-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.hover\:bg-blue-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.hover\:bg-blue-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.hover\:bg-blue-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.hover\:bg-blue-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.hover\:bg-blue-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.hover\:bg-indigo-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.hover\:bg-indigo-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.hover\:bg-indigo-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.hover\:bg-indigo-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.hover\:bg-indigo-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.hover\:bg-indigo-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.hover\:bg-indigo-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.hover\:bg-indigo-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.hover\:bg-indigo-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.hover\:bg-indigo-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.hover\:bg-purple-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.hover\:bg-purple-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.hover\:bg-purple-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.hover\:bg-purple-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.hover\:bg-purple-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.hover\:bg-purple-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.hover\:bg-purple-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.hover\:bg-purple-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.hover\:bg-purple-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.hover\:bg-purple-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.hover\:bg-pink-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.hover\:bg-pink-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.hover\:bg-pink-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.hover\:bg-pink-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.hover\:bg-pink-400:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.hover\:bg-pink-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.hover\:bg-pink-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.hover\:bg-pink-700:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.hover\:bg-pink-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.hover\:bg-pink-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.focus\:bg-transparent:focus {
  background-color: transparent;
}
.focus\:bg-current:focus {
  background-color: currentColor;
}
.focus\:bg-black:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}
.focus\:bg-white:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}
.focus\:bg-gray-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
}
.focus\:bg-gray-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
}
.focus\:bg-gray-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
}
.focus\:bg-gray-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
}
.focus\:bg-gray-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}
.focus\:bg-gray-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
}
.focus\:bg-gray-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
}
.focus\:bg-gray-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
}
.focus\:bg-gray-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
}
.focus\:bg-gray-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
}
.focus\:bg-red-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
}
.focus\:bg-red-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
}
.focus\:bg-red-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}
.focus\:bg-red-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
}
.focus\:bg-red-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}
.focus\:bg-red-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}
.focus\:bg-red-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}
.focus\:bg-red-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}
.focus\:bg-red-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
}
.focus\:bg-red-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
}
.focus\:bg-yellow-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
}
.focus\:bg-yellow-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
}
.focus\:bg-yellow-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
}
.focus\:bg-yellow-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
}
.focus\:bg-yellow-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
}
.focus\:bg-yellow-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
}
.focus\:bg-yellow-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
}
.focus\:bg-yellow-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
}
.focus\:bg-yellow-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
}
.focus\:bg-yellow-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
}
.focus\:bg-green-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
}
.focus\:bg-green-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
}
.focus\:bg-green-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}
.focus\:bg-green-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
}
.focus\:bg-green-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}
.focus\:bg-green-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}
.focus\:bg-green-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
}
.focus\:bg-green-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
}
.focus\:bg-green-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
}
.focus\:bg-green-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
}
.focus\:bg-blue-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
}
.focus\:bg-blue-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.focus\:bg-blue-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
}
.focus\:bg-blue-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
}
.focus\:bg-blue-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
}
.focus\:bg-blue-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
}
.focus\:bg-blue-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
}
.focus\:bg-blue-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
}
.focus\:bg-blue-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
}
.focus\:bg-blue-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
}
.focus\:bg-indigo-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
}
.focus\:bg-indigo-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
}
.focus\:bg-indigo-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
}
.focus\:bg-indigo-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
}
.focus\:bg-indigo-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
}
.focus\:bg-indigo-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}
.focus\:bg-indigo-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}
.focus\:bg-indigo-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
}
.focus\:bg-indigo-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
}
.focus\:bg-indigo-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
}
.focus\:bg-purple-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
}
.focus\:bg-purple-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
}
.focus\:bg-purple-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
}
.focus\:bg-purple-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
}
.focus\:bg-purple-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
}
.focus\:bg-purple-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
}
.focus\:bg-purple-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
}
.focus\:bg-purple-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
}
.focus\:bg-purple-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
}
.focus\:bg-purple-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
}
.focus\:bg-pink-50:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
}
.focus\:bg-pink-100:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
}
.focus\:bg-pink-200:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
}
.focus\:bg-pink-300:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
}
.focus\:bg-pink-400:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
}
.focus\:bg-pink-500:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}
.focus\:bg-pink-600:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
}
.focus\:bg-pink-700:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
}
.focus\:bg-pink-800:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
}
.focus\:bg-pink-900:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
}
.bg-opacity-0 {
  --tw-bg-opacity: 0;
}
.bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}
.bg-opacity-10 {
  --tw-bg-opacity: 0.1;
}
.bg-opacity-20 {
  --tw-bg-opacity: 0.2;
}
.bg-opacity-25 {
  --tw-bg-opacity: 0.25;
}
.bg-opacity-30 {
  --tw-bg-opacity: 0.3;
}
.bg-opacity-40 {
  --tw-bg-opacity: 0.4;
}
.bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}
.bg-opacity-60 {
  --tw-bg-opacity: 0.6;
}
.bg-opacity-70 {
  --tw-bg-opacity: 0.7;
}
.bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}
.bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}
.bg-opacity-90 {
  --tw-bg-opacity: 0.9;
}
.bg-opacity-95 {
  --tw-bg-opacity: 0.95;
}
.bg-opacity-100 {
  --tw-bg-opacity: 1;
}
.group:hover .group-hover\:bg-opacity-0 {
  --tw-bg-opacity: 0;
}
.group:hover .group-hover\:bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}
.group:hover .group-hover\:bg-opacity-10 {
  --tw-bg-opacity: 0.1;
}
.group:hover .group-hover\:bg-opacity-20 {
  --tw-bg-opacity: 0.2;
}
.group:hover .group-hover\:bg-opacity-25 {
  --tw-bg-opacity: 0.25;
}
.group:hover .group-hover\:bg-opacity-30 {
  --tw-bg-opacity: 0.3;
}
.group:hover .group-hover\:bg-opacity-40 {
  --tw-bg-opacity: 0.4;
}
.group:hover .group-hover\:bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}
.group:hover .group-hover\:bg-opacity-60 {
  --tw-bg-opacity: 0.6;
}
.group:hover .group-hover\:bg-opacity-70 {
  --tw-bg-opacity: 0.7;
}
.group:hover .group-hover\:bg-opacity-75 {
  --tw-bg-opacity: 0.75;
}
.group:hover .group-hover\:bg-opacity-80 {
  --tw-bg-opacity: 0.8;
}
.group:hover .group-hover\:bg-opacity-90 {
  --tw-bg-opacity: 0.9;
}
.group:hover .group-hover\:bg-opacity-95 {
  --tw-bg-opacity: 0.95;
}
.group:hover .group-hover\:bg-opacity-100 {
  --tw-bg-opacity: 1;
}
.focus-within\:bg-opacity-0:focus-within {
  --tw-bg-opacity: 0;
}
.focus-within\:bg-opacity-5:focus-within {
  --tw-bg-opacity: 0.05;
}
.focus-within\:bg-opacity-10:focus-within {
  --tw-bg-opacity: 0.1;
}
.focus-within\:bg-opacity-20:focus-within {
  --tw-bg-opacity: 0.2;
}
.focus-within\:bg-opacity-25:focus-within {
  --tw-bg-opacity: 0.25;
}
.focus-within\:bg-opacity-30:focus-within {
  --tw-bg-opacity: 0.3;
}
.focus-within\:bg-opacity-40:focus-within {
  --tw-bg-opacity: 0.4;
}
.focus-within\:bg-opacity-50:focus-within {
  --tw-bg-opacity: 0.5;
}
.focus-within\:bg-opacity-60:focus-within {
  --tw-bg-opacity: 0.6;
}
.focus-within\:bg-opacity-70:focus-within {
  --tw-bg-opacity: 0.7;
}
.focus-within\:bg-opacity-75:focus-within {
  --tw-bg-opacity: 0.75;
}
.focus-within\:bg-opacity-80:focus-within {
  --tw-bg-opacity: 0.8;
}
.focus-within\:bg-opacity-90:focus-within {
  --tw-bg-opacity: 0.9;
}
.focus-within\:bg-opacity-95:focus-within {
  --tw-bg-opacity: 0.95;
}
.focus-within\:bg-opacity-100:focus-within {
  --tw-bg-opacity: 1;
}
.hover\:bg-opacity-0:hover {
  --tw-bg-opacity: 0;
}
.hover\:bg-opacity-5:hover {
  --tw-bg-opacity: 0.05;
}
.hover\:bg-opacity-10:hover {
  --tw-bg-opacity: 0.1;
}
.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: 0.2;
}
.hover\:bg-opacity-25:hover {
  --tw-bg-opacity: 0.25;
}
.hover\:bg-opacity-30:hover {
  --tw-bg-opacity: 0.3;
}
.hover\:bg-opacity-40:hover {
  --tw-bg-opacity: 0.4;
}
.hover\:bg-opacity-50:hover {
  --tw-bg-opacity: 0.5;
}
.hover\:bg-opacity-60:hover {
  --tw-bg-opacity: 0.6;
}
.hover\:bg-opacity-70:hover {
  --tw-bg-opacity: 0.7;
}
.hover\:bg-opacity-75:hover {
  --tw-bg-opacity: 0.75;
}
.hover\:bg-opacity-80:hover {
  --tw-bg-opacity: 0.8;
}
.hover\:bg-opacity-90:hover {
  --tw-bg-opacity: 0.9;
}
.hover\:bg-opacity-95:hover {
  --tw-bg-opacity: 0.95;
}
.hover\:bg-opacity-100:hover {
  --tw-bg-opacity: 1;
}
.focus\:bg-opacity-0:focus {
  --tw-bg-opacity: 0;
}
.focus\:bg-opacity-5:focus {
  --tw-bg-opacity: 0.05;
}
.focus\:bg-opacity-10:focus {
  --tw-bg-opacity: 0.1;
}
.focus\:bg-opacity-20:focus {
  --tw-bg-opacity: 0.2;
}
.focus\:bg-opacity-25:focus {
  --tw-bg-opacity: 0.25;
}
.focus\:bg-opacity-30:focus {
  --tw-bg-opacity: 0.3;
}
.focus\:bg-opacity-40:focus {
  --tw-bg-opacity: 0.4;
}
.focus\:bg-opacity-50:focus {
  --tw-bg-opacity: 0.5;
}
.focus\:bg-opacity-60:focus {
  --tw-bg-opacity: 0.6;
}
.focus\:bg-opacity-70:focus {
  --tw-bg-opacity: 0.7;
}
.focus\:bg-opacity-75:focus {
  --tw-bg-opacity: 0.75;
}
.focus\:bg-opacity-80:focus {
  --tw-bg-opacity: 0.8;
}
.focus\:bg-opacity-90:focus {
  --tw-bg-opacity: 0.9;
}
.focus\:bg-opacity-95:focus {
  --tw-bg-opacity: 0.95;
}
.focus\:bg-opacity-100:focus {
  --tw-bg-opacity: 1;
}
.bg-none {
  background-image: none;
}
.bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops));
}
.bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops));
}
.bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
}
.bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops));
}
.bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}
.bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
}
.bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--tw-gradient-stops));
}
.bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--tw-gradient-stops));
}
.from-transparent {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(0, 0, 0, 0));
}
.from-current {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.from-black {
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(0, 0, 0, 0));
}
.from-white {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.from-gray-50 {
  --tw-gradient-from: #f9fafb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(249, 250, 251, 0));
}
.from-gray-100 {
  --tw-gradient-from: #f3f4f6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(243, 244, 246, 0));
}
.from-gray-200 {
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.from-gray-300 {
  --tw-gradient-from: #d1d5db;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(209, 213, 219, 0));
}
.from-gray-400 {
  --tw-gradient-from: #9ca3af;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(156, 163, 175, 0));
}
.from-gray-500 {
  --tw-gradient-from: #6b7280;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(107, 114, 128, 0));
}
.from-gray-600 {
  --tw-gradient-from: #4b5563;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(75, 85, 99, 0));
}
.from-gray-700 {
  --tw-gradient-from: #374151;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(55, 65, 81, 0));
}
.from-gray-800 {
  --tw-gradient-from: #1f2937;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(31, 41, 55, 0));
}
.from-gray-900 {
  --tw-gradient-from: #111827;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(17, 24, 39, 0));
}
.from-red-50 {
  --tw-gradient-from: #fef2f2;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 242, 242, 0));
}
.from-red-100 {
  --tw-gradient-from: #fee2e2;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 226, 226, 0));
}
.from-red-200 {
  --tw-gradient-from: #fecaca;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 202, 202, 0));
}
.from-red-300 {
  --tw-gradient-from: #fca5a5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 165, 165, 0));
}
.from-red-400 {
  --tw-gradient-from: #f87171;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(248, 113, 113, 0));
}
.from-red-500 {
  --tw-gradient-from: #ef4444;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(239, 68, 68, 0));
}
.from-red-600 {
  --tw-gradient-from: #dc2626;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(220, 38, 38, 0));
}
.from-red-700 {
  --tw-gradient-from: #b91c1c;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(185, 28, 28, 0));
}
.from-red-800 {
  --tw-gradient-from: #991b1b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(153, 27, 27, 0));
}
.from-red-900 {
  --tw-gradient-from: #7f1d1d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(127, 29, 29, 0));
}
.from-yellow-50 {
  --tw-gradient-from: #fffbeb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 251, 235, 0));
}
.from-yellow-100 {
  --tw-gradient-from: #fef3c7;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 243, 199, 0));
}
.from-yellow-200 {
  --tw-gradient-from: #fde68a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(253, 230, 138, 0));
}
.from-yellow-300 {
  --tw-gradient-from: #fcd34d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 211, 77, 0));
}
.from-yellow-400 {
  --tw-gradient-from: #fbbf24;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(251, 191, 36, 0));
}
.from-yellow-500 {
  --tw-gradient-from: #f59e0b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(245, 158, 11, 0));
}
.from-yellow-600 {
  --tw-gradient-from: #d97706;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(217, 119, 6, 0));
}
.from-yellow-700 {
  --tw-gradient-from: #b45309;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(180, 83, 9, 0));
}
.from-yellow-800 {
  --tw-gradient-from: #92400e;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(146, 64, 14, 0));
}
.from-yellow-900 {
  --tw-gradient-from: #78350f;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(120, 53, 15, 0));
}
.from-green-50 {
  --tw-gradient-from: #ecfdf5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(236, 253, 245, 0));
}
.from-green-100 {
  --tw-gradient-from: #d1fae5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(209, 250, 229, 0));
}
.from-green-200 {
  --tw-gradient-from: #a7f3d0;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(167, 243, 208, 0));
}
.from-green-300 {
  --tw-gradient-from: #6ee7b7;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(110, 231, 183, 0));
}
.from-green-400 {
  --tw-gradient-from: #34d399;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(52, 211, 153, 0));
}
.from-green-500 {
  --tw-gradient-from: #10b981;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(16, 185, 129, 0));
}
.from-green-600 {
  --tw-gradient-from: #059669;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(5, 150, 105, 0));
}
.from-green-700 {
  --tw-gradient-from: #047857;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(4, 120, 87, 0));
}
.from-green-800 {
  --tw-gradient-from: #065f46;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(6, 95, 70, 0));
}
.from-green-900 {
  --tw-gradient-from: #064e3b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(6, 78, 59, 0));
}
.from-blue-50 {
  --tw-gradient-from: #eff6ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(239, 246, 255, 0));
}
.from-blue-100 {
  --tw-gradient-from: #dbeafe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(219, 234, 254, 0));
}
.from-blue-200 {
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(191, 219, 254, 0));
}
.from-blue-300 {
  --tw-gradient-from: #93c5fd;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(147, 197, 253, 0));
}
.from-blue-400 {
  --tw-gradient-from: #60a5fa;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(96, 165, 250, 0));
}
.from-blue-500 {
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(59, 130, 246, 0));
}
.from-blue-600 {
  --tw-gradient-from: #2563eb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(37, 99, 235, 0));
}
.from-blue-700 {
  --tw-gradient-from: #1d4ed8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(29, 78, 216, 0));
}
.from-blue-800 {
  --tw-gradient-from: #1e40af;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(30, 64, 175, 0));
}
.from-blue-900 {
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(30, 58, 138, 0));
}
.from-indigo-50 {
  --tw-gradient-from: #eef2ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(238, 242, 255, 0));
}
.from-indigo-100 {
  --tw-gradient-from: #e0e7ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(224, 231, 255, 0));
}
.from-indigo-200 {
  --tw-gradient-from: #c7d2fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(199, 210, 254, 0));
}
.from-indigo-300 {
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(165, 180, 252, 0));
}
.from-indigo-400 {
  --tw-gradient-from: #818cf8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(129, 140, 248, 0));
}
.from-indigo-500 {
  --tw-gradient-from: #6366f1;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(99, 102, 241, 0));
}
.from-indigo-600 {
  --tw-gradient-from: #4f46e5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(79, 70, 229, 0));
}
.from-indigo-700 {
  --tw-gradient-from: #4338ca;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(67, 56, 202, 0));
}
.from-indigo-800 {
  --tw-gradient-from: #3730a3;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(55, 48, 163, 0));
}
.from-indigo-900 {
  --tw-gradient-from: #312e81;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(49, 46, 129, 0));
}
.from-purple-50 {
  --tw-gradient-from: #f5f3ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(245, 243, 255, 0));
}
.from-purple-100 {
  --tw-gradient-from: #ede9fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(237, 233, 254, 0));
}
.from-purple-200 {
  --tw-gradient-from: #ddd6fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(221, 214, 254, 0));
}
.from-purple-300 {
  --tw-gradient-from: #c4b5fd;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(196, 181, 253, 0));
}
.from-purple-400 {
  --tw-gradient-from: #a78bfa;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(167, 139, 250, 0));
}
.from-purple-500 {
  --tw-gradient-from: #8b5cf6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(139, 92, 246, 0));
}
.from-purple-600 {
  --tw-gradient-from: #7c3aed;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(124, 58, 237, 0));
}
.from-purple-700 {
  --tw-gradient-from: #6d28d9;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(109, 40, 217, 0));
}
.from-purple-800 {
  --tw-gradient-from: #5b21b6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(91, 33, 182, 0));
}
.from-purple-900 {
  --tw-gradient-from: #4c1d95;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(76, 29, 149, 0));
}
.from-pink-50 {
  --tw-gradient-from: #fdf2f8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(253, 242, 248, 0));
}
.from-pink-100 {
  --tw-gradient-from: #fce7f3;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 231, 243, 0));
}
.from-pink-200 {
  --tw-gradient-from: #fbcfe8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(251, 207, 232, 0));
}
.from-pink-300 {
  --tw-gradient-from: #f9a8d4;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(249, 168, 212, 0));
}
.from-pink-400 {
  --tw-gradient-from: #f472b6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(244, 114, 182, 0));
}
.from-pink-500 {
  --tw-gradient-from: #ec4899;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(236, 72, 153, 0));
}
.from-pink-600 {
  --tw-gradient-from: #db2777;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(219, 39, 119, 0));
}
.from-pink-700 {
  --tw-gradient-from: #be185d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(190, 24, 93, 0));
}
.from-pink-800 {
  --tw-gradient-from: #9d174d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(157, 23, 77, 0));
}
.from-pink-900 {
  --tw-gradient-from: #831843;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(131, 24, 67, 0));
}
.hover\:from-transparent:hover {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(0, 0, 0, 0));
}
.hover\:from-current:hover {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.hover\:from-black:hover {
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(0, 0, 0, 0));
}
.hover\:from-white:hover {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.hover\:from-gray-50:hover {
  --tw-gradient-from: #f9fafb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(249, 250, 251, 0));
}
.hover\:from-gray-100:hover {
  --tw-gradient-from: #f3f4f6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(243, 244, 246, 0));
}
.hover\:from-gray-200:hover {
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.hover\:from-gray-300:hover {
  --tw-gradient-from: #d1d5db;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(209, 213, 219, 0));
}
.hover\:from-gray-400:hover {
  --tw-gradient-from: #9ca3af;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(156, 163, 175, 0));
}
.hover\:from-gray-500:hover {
  --tw-gradient-from: #6b7280;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(107, 114, 128, 0));
}
.hover\:from-gray-600:hover {
  --tw-gradient-from: #4b5563;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(75, 85, 99, 0));
}
.hover\:from-gray-700:hover {
  --tw-gradient-from: #374151;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(55, 65, 81, 0));
}
.hover\:from-gray-800:hover {
  --tw-gradient-from: #1f2937;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(31, 41, 55, 0));
}
.hover\:from-gray-900:hover {
  --tw-gradient-from: #111827;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(17, 24, 39, 0));
}
.hover\:from-red-50:hover {
  --tw-gradient-from: #fef2f2;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 242, 242, 0));
}
.hover\:from-red-100:hover {
  --tw-gradient-from: #fee2e2;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 226, 226, 0));
}
.hover\:from-red-200:hover {
  --tw-gradient-from: #fecaca;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 202, 202, 0));
}
.hover\:from-red-300:hover {
  --tw-gradient-from: #fca5a5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 165, 165, 0));
}
.hover\:from-red-400:hover {
  --tw-gradient-from: #f87171;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(248, 113, 113, 0));
}
.hover\:from-red-500:hover {
  --tw-gradient-from: #ef4444;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(239, 68, 68, 0));
}
.hover\:from-red-600:hover {
  --tw-gradient-from: #dc2626;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(220, 38, 38, 0));
}
.hover\:from-red-700:hover {
  --tw-gradient-from: #b91c1c;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(185, 28, 28, 0));
}
.hover\:from-red-800:hover {
  --tw-gradient-from: #991b1b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(153, 27, 27, 0));
}
.hover\:from-red-900:hover {
  --tw-gradient-from: #7f1d1d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(127, 29, 29, 0));
}
.hover\:from-yellow-50:hover {
  --tw-gradient-from: #fffbeb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 251, 235, 0));
}
.hover\:from-yellow-100:hover {
  --tw-gradient-from: #fef3c7;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 243, 199, 0));
}
.hover\:from-yellow-200:hover {
  --tw-gradient-from: #fde68a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(253, 230, 138, 0));
}
.hover\:from-yellow-300:hover {
  --tw-gradient-from: #fcd34d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 211, 77, 0));
}
.hover\:from-yellow-400:hover {
  --tw-gradient-from: #fbbf24;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(251, 191, 36, 0));
}
.hover\:from-yellow-500:hover {
  --tw-gradient-from: #f59e0b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(245, 158, 11, 0));
}
.hover\:from-yellow-600:hover {
  --tw-gradient-from: #d97706;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(217, 119, 6, 0));
}
.hover\:from-yellow-700:hover {
  --tw-gradient-from: #b45309;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(180, 83, 9, 0));
}
.hover\:from-yellow-800:hover {
  --tw-gradient-from: #92400e;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(146, 64, 14, 0));
}
.hover\:from-yellow-900:hover {
  --tw-gradient-from: #78350f;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(120, 53, 15, 0));
}
.hover\:from-green-50:hover {
  --tw-gradient-from: #ecfdf5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(236, 253, 245, 0));
}
.hover\:from-green-100:hover {
  --tw-gradient-from: #d1fae5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(209, 250, 229, 0));
}
.hover\:from-green-200:hover {
  --tw-gradient-from: #a7f3d0;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(167, 243, 208, 0));
}
.hover\:from-green-300:hover {
  --tw-gradient-from: #6ee7b7;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(110, 231, 183, 0));
}
.hover\:from-green-400:hover {
  --tw-gradient-from: #34d399;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(52, 211, 153, 0));
}
.hover\:from-green-500:hover {
  --tw-gradient-from: #10b981;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(16, 185, 129, 0));
}
.hover\:from-green-600:hover {
  --tw-gradient-from: #059669;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(5, 150, 105, 0));
}
.hover\:from-green-700:hover {
  --tw-gradient-from: #047857;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(4, 120, 87, 0));
}
.hover\:from-green-800:hover {
  --tw-gradient-from: #065f46;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(6, 95, 70, 0));
}
.hover\:from-green-900:hover {
  --tw-gradient-from: #064e3b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(6, 78, 59, 0));
}
.hover\:from-blue-50:hover {
  --tw-gradient-from: #eff6ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(239, 246, 255, 0));
}
.hover\:from-blue-100:hover {
  --tw-gradient-from: #dbeafe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(219, 234, 254, 0));
}
.hover\:from-blue-200:hover {
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(191, 219, 254, 0));
}
.hover\:from-blue-300:hover {
  --tw-gradient-from: #93c5fd;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(147, 197, 253, 0));
}
.hover\:from-blue-400:hover {
  --tw-gradient-from: #60a5fa;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(96, 165, 250, 0));
}
.hover\:from-blue-500:hover {
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(59, 130, 246, 0));
}
.hover\:from-blue-600:hover {
  --tw-gradient-from: #2563eb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(37, 99, 235, 0));
}
.hover\:from-blue-700:hover {
  --tw-gradient-from: #1d4ed8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(29, 78, 216, 0));
}
.hover\:from-blue-800:hover {
  --tw-gradient-from: #1e40af;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(30, 64, 175, 0));
}
.hover\:from-blue-900:hover {
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(30, 58, 138, 0));
}
.hover\:from-indigo-50:hover {
  --tw-gradient-from: #eef2ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(238, 242, 255, 0));
}
.hover\:from-indigo-100:hover {
  --tw-gradient-from: #e0e7ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(224, 231, 255, 0));
}
.hover\:from-indigo-200:hover {
  --tw-gradient-from: #c7d2fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(199, 210, 254, 0));
}
.hover\:from-indigo-300:hover {
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(165, 180, 252, 0));
}
.hover\:from-indigo-400:hover {
  --tw-gradient-from: #818cf8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(129, 140, 248, 0));
}
.hover\:from-indigo-500:hover {
  --tw-gradient-from: #6366f1;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(99, 102, 241, 0));
}
.hover\:from-indigo-600:hover {
  --tw-gradient-from: #4f46e5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(79, 70, 229, 0));
}
.hover\:from-indigo-700:hover {
  --tw-gradient-from: #4338ca;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(67, 56, 202, 0));
}
.hover\:from-indigo-800:hover {
  --tw-gradient-from: #3730a3;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(55, 48, 163, 0));
}
.hover\:from-indigo-900:hover {
  --tw-gradient-from: #312e81;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(49, 46, 129, 0));
}
.hover\:from-purple-50:hover {
  --tw-gradient-from: #f5f3ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(245, 243, 255, 0));
}
.hover\:from-purple-100:hover {
  --tw-gradient-from: #ede9fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(237, 233, 254, 0));
}
.hover\:from-purple-200:hover {
  --tw-gradient-from: #ddd6fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(221, 214, 254, 0));
}
.hover\:from-purple-300:hover {
  --tw-gradient-from: #c4b5fd;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(196, 181, 253, 0));
}
.hover\:from-purple-400:hover {
  --tw-gradient-from: #a78bfa;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(167, 139, 250, 0));
}
.hover\:from-purple-500:hover {
  --tw-gradient-from: #8b5cf6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(139, 92, 246, 0));
}
.hover\:from-purple-600:hover {
  --tw-gradient-from: #7c3aed;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(124, 58, 237, 0));
}
.hover\:from-purple-700:hover {
  --tw-gradient-from: #6d28d9;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(109, 40, 217, 0));
}
.hover\:from-purple-800:hover {
  --tw-gradient-from: #5b21b6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(91, 33, 182, 0));
}
.hover\:from-purple-900:hover {
  --tw-gradient-from: #4c1d95;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(76, 29, 149, 0));
}
.hover\:from-pink-50:hover {
  --tw-gradient-from: #fdf2f8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(253, 242, 248, 0));
}
.hover\:from-pink-100:hover {
  --tw-gradient-from: #fce7f3;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 231, 243, 0));
}
.hover\:from-pink-200:hover {
  --tw-gradient-from: #fbcfe8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(251, 207, 232, 0));
}
.hover\:from-pink-300:hover {
  --tw-gradient-from: #f9a8d4;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(249, 168, 212, 0));
}
.hover\:from-pink-400:hover {
  --tw-gradient-from: #f472b6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(244, 114, 182, 0));
}
.hover\:from-pink-500:hover {
  --tw-gradient-from: #ec4899;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(236, 72, 153, 0));
}
.hover\:from-pink-600:hover {
  --tw-gradient-from: #db2777;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(219, 39, 119, 0));
}
.hover\:from-pink-700:hover {
  --tw-gradient-from: #be185d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(190, 24, 93, 0));
}
.hover\:from-pink-800:hover {
  --tw-gradient-from: #9d174d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(157, 23, 77, 0));
}
.hover\:from-pink-900:hover {
  --tw-gradient-from: #831843;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(131, 24, 67, 0));
}
.focus\:from-transparent:focus {
  --tw-gradient-from: transparent;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(0, 0, 0, 0));
}
.focus\:from-current:focus {
  --tw-gradient-from: currentColor;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.focus\:from-black:focus {
  --tw-gradient-from: #000;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(0, 0, 0, 0));
}
.focus\:from-white:focus {
  --tw-gradient-from: #fff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.focus\:from-gray-50:focus {
  --tw-gradient-from: #f9fafb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(249, 250, 251, 0));
}
.focus\:from-gray-100:focus {
  --tw-gradient-from: #f3f4f6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(243, 244, 246, 0));
}
.focus\:from-gray-200:focus {
  --tw-gradient-from: #e5e7eb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.focus\:from-gray-300:focus {
  --tw-gradient-from: #d1d5db;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(209, 213, 219, 0));
}
.focus\:from-gray-400:focus {
  --tw-gradient-from: #9ca3af;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(156, 163, 175, 0));
}
.focus\:from-gray-500:focus {
  --tw-gradient-from: #6b7280;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(107, 114, 128, 0));
}
.focus\:from-gray-600:focus {
  --tw-gradient-from: #4b5563;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(75, 85, 99, 0));
}
.focus\:from-gray-700:focus {
  --tw-gradient-from: #374151;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(55, 65, 81, 0));
}
.focus\:from-gray-800:focus {
  --tw-gradient-from: #1f2937;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(31, 41, 55, 0));
}
.focus\:from-gray-900:focus {
  --tw-gradient-from: #111827;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(17, 24, 39, 0));
}
.focus\:from-red-50:focus {
  --tw-gradient-from: #fef2f2;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 242, 242, 0));
}
.focus\:from-red-100:focus {
  --tw-gradient-from: #fee2e2;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 226, 226, 0));
}
.focus\:from-red-200:focus {
  --tw-gradient-from: #fecaca;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 202, 202, 0));
}
.focus\:from-red-300:focus {
  --tw-gradient-from: #fca5a5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 165, 165, 0));
}
.focus\:from-red-400:focus {
  --tw-gradient-from: #f87171;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(248, 113, 113, 0));
}
.focus\:from-red-500:focus {
  --tw-gradient-from: #ef4444;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(239, 68, 68, 0));
}
.focus\:from-red-600:focus {
  --tw-gradient-from: #dc2626;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(220, 38, 38, 0));
}
.focus\:from-red-700:focus {
  --tw-gradient-from: #b91c1c;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(185, 28, 28, 0));
}
.focus\:from-red-800:focus {
  --tw-gradient-from: #991b1b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(153, 27, 27, 0));
}
.focus\:from-red-900:focus {
  --tw-gradient-from: #7f1d1d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(127, 29, 29, 0));
}
.focus\:from-yellow-50:focus {
  --tw-gradient-from: #fffbeb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(255, 251, 235, 0));
}
.focus\:from-yellow-100:focus {
  --tw-gradient-from: #fef3c7;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(254, 243, 199, 0));
}
.focus\:from-yellow-200:focus {
  --tw-gradient-from: #fde68a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(253, 230, 138, 0));
}
.focus\:from-yellow-300:focus {
  --tw-gradient-from: #fcd34d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 211, 77, 0));
}
.focus\:from-yellow-400:focus {
  --tw-gradient-from: #fbbf24;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(251, 191, 36, 0));
}
.focus\:from-yellow-500:focus {
  --tw-gradient-from: #f59e0b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(245, 158, 11, 0));
}
.focus\:from-yellow-600:focus {
  --tw-gradient-from: #d97706;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(217, 119, 6, 0));
}
.focus\:from-yellow-700:focus {
  --tw-gradient-from: #b45309;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(180, 83, 9, 0));
}
.focus\:from-yellow-800:focus {
  --tw-gradient-from: #92400e;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(146, 64, 14, 0));
}
.focus\:from-yellow-900:focus {
  --tw-gradient-from: #78350f;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(120, 53, 15, 0));
}
.focus\:from-green-50:focus {
  --tw-gradient-from: #ecfdf5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(236, 253, 245, 0));
}
.focus\:from-green-100:focus {
  --tw-gradient-from: #d1fae5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(209, 250, 229, 0));
}
.focus\:from-green-200:focus {
  --tw-gradient-from: #a7f3d0;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(167, 243, 208, 0));
}
.focus\:from-green-300:focus {
  --tw-gradient-from: #6ee7b7;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(110, 231, 183, 0));
}
.focus\:from-green-400:focus {
  --tw-gradient-from: #34d399;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(52, 211, 153, 0));
}
.focus\:from-green-500:focus {
  --tw-gradient-from: #10b981;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(16, 185, 129, 0));
}
.focus\:from-green-600:focus {
  --tw-gradient-from: #059669;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(5, 150, 105, 0));
}
.focus\:from-green-700:focus {
  --tw-gradient-from: #047857;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(4, 120, 87, 0));
}
.focus\:from-green-800:focus {
  --tw-gradient-from: #065f46;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(6, 95, 70, 0));
}
.focus\:from-green-900:focus {
  --tw-gradient-from: #064e3b;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(6, 78, 59, 0));
}
.focus\:from-blue-50:focus {
  --tw-gradient-from: #eff6ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(239, 246, 255, 0));
}
.focus\:from-blue-100:focus {
  --tw-gradient-from: #dbeafe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(219, 234, 254, 0));
}
.focus\:from-blue-200:focus {
  --tw-gradient-from: #bfdbfe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(191, 219, 254, 0));
}
.focus\:from-blue-300:focus {
  --tw-gradient-from: #93c5fd;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(147, 197, 253, 0));
}
.focus\:from-blue-400:focus {
  --tw-gradient-from: #60a5fa;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(96, 165, 250, 0));
}
.focus\:from-blue-500:focus {
  --tw-gradient-from: #3b82f6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(59, 130, 246, 0));
}
.focus\:from-blue-600:focus {
  --tw-gradient-from: #2563eb;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(37, 99, 235, 0));
}
.focus\:from-blue-700:focus {
  --tw-gradient-from: #1d4ed8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(29, 78, 216, 0));
}
.focus\:from-blue-800:focus {
  --tw-gradient-from: #1e40af;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(30, 64, 175, 0));
}
.focus\:from-blue-900:focus {
  --tw-gradient-from: #1e3a8a;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(30, 58, 138, 0));
}
.focus\:from-indigo-50:focus {
  --tw-gradient-from: #eef2ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(238, 242, 255, 0));
}
.focus\:from-indigo-100:focus {
  --tw-gradient-from: #e0e7ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(224, 231, 255, 0));
}
.focus\:from-indigo-200:focus {
  --tw-gradient-from: #c7d2fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(199, 210, 254, 0));
}
.focus\:from-indigo-300:focus {
  --tw-gradient-from: #a5b4fc;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(165, 180, 252, 0));
}
.focus\:from-indigo-400:focus {
  --tw-gradient-from: #818cf8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(129, 140, 248, 0));
}
.focus\:from-indigo-500:focus {
  --tw-gradient-from: #6366f1;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(99, 102, 241, 0));
}
.focus\:from-indigo-600:focus {
  --tw-gradient-from: #4f46e5;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(79, 70, 229, 0));
}
.focus\:from-indigo-700:focus {
  --tw-gradient-from: #4338ca;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(67, 56, 202, 0));
}
.focus\:from-indigo-800:focus {
  --tw-gradient-from: #3730a3;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(55, 48, 163, 0));
}
.focus\:from-indigo-900:focus {
  --tw-gradient-from: #312e81;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(49, 46, 129, 0));
}
.focus\:from-purple-50:focus {
  --tw-gradient-from: #f5f3ff;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(245, 243, 255, 0));
}
.focus\:from-purple-100:focus {
  --tw-gradient-from: #ede9fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(237, 233, 254, 0));
}
.focus\:from-purple-200:focus {
  --tw-gradient-from: #ddd6fe;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(221, 214, 254, 0));
}
.focus\:from-purple-300:focus {
  --tw-gradient-from: #c4b5fd;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(196, 181, 253, 0));
}
.focus\:from-purple-400:focus {
  --tw-gradient-from: #a78bfa;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(167, 139, 250, 0));
}
.focus\:from-purple-500:focus {
  --tw-gradient-from: #8b5cf6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(139, 92, 246, 0));
}
.focus\:from-purple-600:focus {
  --tw-gradient-from: #7c3aed;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(124, 58, 237, 0));
}
.focus\:from-purple-700:focus {
  --tw-gradient-from: #6d28d9;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(109, 40, 217, 0));
}
.focus\:from-purple-800:focus {
  --tw-gradient-from: #5b21b6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(91, 33, 182, 0));
}
.focus\:from-purple-900:focus {
  --tw-gradient-from: #4c1d95;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(76, 29, 149, 0));
}
.focus\:from-pink-50:focus {
  --tw-gradient-from: #fdf2f8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(253, 242, 248, 0));
}
.focus\:from-pink-100:focus {
  --tw-gradient-from: #fce7f3;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(252, 231, 243, 0));
}
.focus\:from-pink-200:focus {
  --tw-gradient-from: #fbcfe8;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(251, 207, 232, 0));
}
.focus\:from-pink-300:focus {
  --tw-gradient-from: #f9a8d4;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(249, 168, 212, 0));
}
.focus\:from-pink-400:focus {
  --tw-gradient-from: #f472b6;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(244, 114, 182, 0));
}
.focus\:from-pink-500:focus {
  --tw-gradient-from: #ec4899;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(236, 72, 153, 0));
}
.focus\:from-pink-600:focus {
  --tw-gradient-from: #db2777;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(219, 39, 119, 0));
}
.focus\:from-pink-700:focus {
  --tw-gradient-from: #be185d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(190, 24, 93, 0));
}
.focus\:from-pink-800:focus {
  --tw-gradient-from: #9d174d;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(157, 23, 77, 0));
}
.focus\:from-pink-900:focus {
  --tw-gradient-from: #831843;
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(131, 24, 67, 0));
}
.via-transparent {
  --tw-gradient-stops: var(--tw-gradient-from), transparent,
    var(--tw-gradient-to, rgba(0, 0, 0, 0));
}
.via-current {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor,
    var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.via-black {
  --tw-gradient-stops: var(--tw-gradient-from), #000,
    var(--tw-gradient-to, rgba(0, 0, 0, 0));
}
.via-white {
  --tw-gradient-stops: var(--tw-gradient-from), #fff,
    var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.via-gray-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
    var(--tw-gradient-to, rgba(249, 250, 251, 0));
}
.via-gray-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
    var(--tw-gradient-to, rgba(243, 244, 246, 0));
}
.via-gray-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.via-gray-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
    var(--tw-gradient-to, rgba(209, 213, 219, 0));
}
.via-gray-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
    var(--tw-gradient-to, rgba(156, 163, 175, 0));
}
.via-gray-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
    var(--tw-gradient-to, rgba(107, 114, 128, 0));
}
.via-gray-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
    var(--tw-gradient-to, rgba(75, 85, 99, 0));
}
.via-gray-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #374151,
    var(--tw-gradient-to, rgba(55, 65, 81, 0));
}
.via-gray-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
    var(--tw-gradient-to, rgba(31, 41, 55, 0));
}
.via-gray-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #111827,
    var(--tw-gradient-to, rgba(17, 24, 39, 0));
}
.via-red-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
    var(--tw-gradient-to, rgba(254, 242, 242, 0));
}
.via-red-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
    var(--tw-gradient-to, rgba(254, 226, 226, 0));
}
.via-red-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
    var(--tw-gradient-to, rgba(254, 202, 202, 0));
}
.via-red-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
    var(--tw-gradient-to, rgba(252, 165, 165, 0));
}
.via-red-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #f87171,
    var(--tw-gradient-to, rgba(248, 113, 113, 0));
}
.via-red-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
    var(--tw-gradient-to, rgba(239, 68, 68, 0));
}
.via-red-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
    var(--tw-gradient-to, rgba(220, 38, 38, 0));
}
.via-red-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
    var(--tw-gradient-to, rgba(185, 28, 28, 0));
}
.via-red-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
    var(--tw-gradient-to, rgba(153, 27, 27, 0));
}
.via-red-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
    var(--tw-gradient-to, rgba(127, 29, 29, 0));
}
.via-yellow-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
    var(--tw-gradient-to, rgba(255, 251, 235, 0));
}
.via-yellow-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
    var(--tw-gradient-to, rgba(254, 243, 199, 0));
}
.via-yellow-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
    var(--tw-gradient-to, rgba(253, 230, 138, 0));
}
.via-yellow-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
    var(--tw-gradient-to, rgba(252, 211, 77, 0));
}
.via-yellow-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
    var(--tw-gradient-to, rgba(251, 191, 36, 0));
}
.via-yellow-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
    var(--tw-gradient-to, rgba(245, 158, 11, 0));
}
.via-yellow-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #d97706,
    var(--tw-gradient-to, rgba(217, 119, 6, 0));
}
.via-yellow-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #b45309,
    var(--tw-gradient-to, rgba(180, 83, 9, 0));
}
.via-yellow-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #92400e,
    var(--tw-gradient-to, rgba(146, 64, 14, 0));
}
.via-yellow-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #78350f,
    var(--tw-gradient-to, rgba(120, 53, 15, 0));
}
.via-green-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
    var(--tw-gradient-to, rgba(236, 253, 245, 0));
}
.via-green-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
    var(--tw-gradient-to, rgba(209, 250, 229, 0));
}
.via-green-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
    var(--tw-gradient-to, rgba(167, 243, 208, 0));
}
.via-green-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
    var(--tw-gradient-to, rgba(110, 231, 183, 0));
}
.via-green-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #34d399,
    var(--tw-gradient-to, rgba(52, 211, 153, 0));
}
.via-green-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #10b981,
    var(--tw-gradient-to, rgba(16, 185, 129, 0));
}
.via-green-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #059669,
    var(--tw-gradient-to, rgba(5, 150, 105, 0));
}
.via-green-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #047857,
    var(--tw-gradient-to, rgba(4, 120, 87, 0));
}
.via-green-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #065f46,
    var(--tw-gradient-to, rgba(6, 95, 70, 0));
}
.via-green-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
    var(--tw-gradient-to, rgba(6, 78, 59, 0));
}
.via-blue-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
    var(--tw-gradient-to, rgba(239, 246, 255, 0));
}
.via-blue-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
    var(--tw-gradient-to, rgba(219, 234, 254, 0));
}
.via-blue-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
    var(--tw-gradient-to, rgba(191, 219, 254, 0));
}
.via-blue-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
    var(--tw-gradient-to, rgba(147, 197, 253, 0));
}
.via-blue-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
    var(--tw-gradient-to, rgba(96, 165, 250, 0));
}
.via-blue-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
    var(--tw-gradient-to, rgba(59, 130, 246, 0));
}
.via-blue-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
    var(--tw-gradient-to, rgba(37, 99, 235, 0));
}
.via-blue-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
    var(--tw-gradient-to, rgba(29, 78, 216, 0));
}
.via-blue-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
    var(--tw-gradient-to, rgba(30, 64, 175, 0));
}
.via-blue-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
    var(--tw-gradient-to, rgba(30, 58, 138, 0));
}
.via-indigo-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
    var(--tw-gradient-to, rgba(238, 242, 255, 0));
}
.via-indigo-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
    var(--tw-gradient-to, rgba(224, 231, 255, 0));
}
.via-indigo-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
    var(--tw-gradient-to, rgba(199, 210, 254, 0));
}
.via-indigo-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
    var(--tw-gradient-to, rgba(165, 180, 252, 0));
}
.via-indigo-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
    var(--tw-gradient-to, rgba(129, 140, 248, 0));
}
.via-indigo-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
    var(--tw-gradient-to, rgba(99, 102, 241, 0));
}
.via-indigo-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
    var(--tw-gradient-to, rgba(79, 70, 229, 0));
}
.via-indigo-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
    var(--tw-gradient-to, rgba(67, 56, 202, 0));
}
.via-indigo-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
    var(--tw-gradient-to, rgba(55, 48, 163, 0));
}
.via-indigo-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #312e81,
    var(--tw-gradient-to, rgba(49, 46, 129, 0));
}
.via-purple-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
    var(--tw-gradient-to, rgba(245, 243, 255, 0));
}
.via-purple-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
    var(--tw-gradient-to, rgba(237, 233, 254, 0));
}
.via-purple-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
    var(--tw-gradient-to, rgba(221, 214, 254, 0));
}
.via-purple-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
    var(--tw-gradient-to, rgba(196, 181, 253, 0));
}
.via-purple-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
    var(--tw-gradient-to, rgba(167, 139, 250, 0));
}
.via-purple-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
    var(--tw-gradient-to, rgba(139, 92, 246, 0));
}
.via-purple-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
    var(--tw-gradient-to, rgba(124, 58, 237, 0));
}
.via-purple-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
    var(--tw-gradient-to, rgba(109, 40, 217, 0));
}
.via-purple-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
    var(--tw-gradient-to, rgba(91, 33, 182, 0));
}
.via-purple-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
    var(--tw-gradient-to, rgba(76, 29, 149, 0));
}
.via-pink-50 {
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
    var(--tw-gradient-to, rgba(253, 242, 248, 0));
}
.via-pink-100 {
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
    var(--tw-gradient-to, rgba(252, 231, 243, 0));
}
.via-pink-200 {
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
    var(--tw-gradient-to, rgba(251, 207, 232, 0));
}
.via-pink-300 {
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
    var(--tw-gradient-to, rgba(249, 168, 212, 0));
}
.via-pink-400 {
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
    var(--tw-gradient-to, rgba(244, 114, 182, 0));
}
.via-pink-500 {
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
    var(--tw-gradient-to, rgba(236, 72, 153, 0));
}
.via-pink-600 {
  --tw-gradient-stops: var(--tw-gradient-from), #db2777,
    var(--tw-gradient-to, rgba(219, 39, 119, 0));
}
.via-pink-700 {
  --tw-gradient-stops: var(--tw-gradient-from), #be185d,
    var(--tw-gradient-to, rgba(190, 24, 93, 0));
}
.via-pink-800 {
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
    var(--tw-gradient-to, rgba(157, 23, 77, 0));
}
.via-pink-900 {
  --tw-gradient-stops: var(--tw-gradient-from), #831843,
    var(--tw-gradient-to, rgba(131, 24, 67, 0));
}
.hover\:via-transparent:hover {
  --tw-gradient-stops: var(--tw-gradient-from), transparent,
    var(--tw-gradient-to, rgba(0, 0, 0, 0));
}
.hover\:via-current:hover {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor,
    var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.hover\:via-black:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #000,
    var(--tw-gradient-to, rgba(0, 0, 0, 0));
}
.hover\:via-white:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fff,
    var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.hover\:via-gray-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
    var(--tw-gradient-to, rgba(249, 250, 251, 0));
}
.hover\:via-gray-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
    var(--tw-gradient-to, rgba(243, 244, 246, 0));
}
.hover\:via-gray-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.hover\:via-gray-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
    var(--tw-gradient-to, rgba(209, 213, 219, 0));
}
.hover\:via-gray-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
    var(--tw-gradient-to, rgba(156, 163, 175, 0));
}
.hover\:via-gray-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
    var(--tw-gradient-to, rgba(107, 114, 128, 0));
}
.hover\:via-gray-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
    var(--tw-gradient-to, rgba(75, 85, 99, 0));
}
.hover\:via-gray-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #374151,
    var(--tw-gradient-to, rgba(55, 65, 81, 0));
}
.hover\:via-gray-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
    var(--tw-gradient-to, rgba(31, 41, 55, 0));
}
.hover\:via-gray-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #111827,
    var(--tw-gradient-to, rgba(17, 24, 39, 0));
}
.hover\:via-red-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
    var(--tw-gradient-to, rgba(254, 242, 242, 0));
}
.hover\:via-red-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
    var(--tw-gradient-to, rgba(254, 226, 226, 0));
}
.hover\:via-red-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
    var(--tw-gradient-to, rgba(254, 202, 202, 0));
}
.hover\:via-red-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
    var(--tw-gradient-to, rgba(252, 165, 165, 0));
}
.hover\:via-red-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f87171,
    var(--tw-gradient-to, rgba(248, 113, 113, 0));
}
.hover\:via-red-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
    var(--tw-gradient-to, rgba(239, 68, 68, 0));
}
.hover\:via-red-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
    var(--tw-gradient-to, rgba(220, 38, 38, 0));
}
.hover\:via-red-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
    var(--tw-gradient-to, rgba(185, 28, 28, 0));
}
.hover\:via-red-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
    var(--tw-gradient-to, rgba(153, 27, 27, 0));
}
.hover\:via-red-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
    var(--tw-gradient-to, rgba(127, 29, 29, 0));
}
.hover\:via-yellow-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
    var(--tw-gradient-to, rgba(255, 251, 235, 0));
}
.hover\:via-yellow-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
    var(--tw-gradient-to, rgba(254, 243, 199, 0));
}
.hover\:via-yellow-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
    var(--tw-gradient-to, rgba(253, 230, 138, 0));
}
.hover\:via-yellow-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
    var(--tw-gradient-to, rgba(252, 211, 77, 0));
}
.hover\:via-yellow-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
    var(--tw-gradient-to, rgba(251, 191, 36, 0));
}
.hover\:via-yellow-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
    var(--tw-gradient-to, rgba(245, 158, 11, 0));
}
.hover\:via-yellow-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #d97706,
    var(--tw-gradient-to, rgba(217, 119, 6, 0));
}
.hover\:via-yellow-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #b45309,
    var(--tw-gradient-to, rgba(180, 83, 9, 0));
}
.hover\:via-yellow-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #92400e,
    var(--tw-gradient-to, rgba(146, 64, 14, 0));
}
.hover\:via-yellow-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #78350f,
    var(--tw-gradient-to, rgba(120, 53, 15, 0));
}
.hover\:via-green-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
    var(--tw-gradient-to, rgba(236, 253, 245, 0));
}
.hover\:via-green-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
    var(--tw-gradient-to, rgba(209, 250, 229, 0));
}
.hover\:via-green-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
    var(--tw-gradient-to, rgba(167, 243, 208, 0));
}
.hover\:via-green-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
    var(--tw-gradient-to, rgba(110, 231, 183, 0));
}
.hover\:via-green-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #34d399,
    var(--tw-gradient-to, rgba(52, 211, 153, 0));
}
.hover\:via-green-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #10b981,
    var(--tw-gradient-to, rgba(16, 185, 129, 0));
}
.hover\:via-green-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #059669,
    var(--tw-gradient-to, rgba(5, 150, 105, 0));
}
.hover\:via-green-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #047857,
    var(--tw-gradient-to, rgba(4, 120, 87, 0));
}
.hover\:via-green-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #065f46,
    var(--tw-gradient-to, rgba(6, 95, 70, 0));
}
.hover\:via-green-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
    var(--tw-gradient-to, rgba(6, 78, 59, 0));
}
.hover\:via-blue-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
    var(--tw-gradient-to, rgba(239, 246, 255, 0));
}
.hover\:via-blue-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
    var(--tw-gradient-to, rgba(219, 234, 254, 0));
}
.hover\:via-blue-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
    var(--tw-gradient-to, rgba(191, 219, 254, 0));
}
.hover\:via-blue-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
    var(--tw-gradient-to, rgba(147, 197, 253, 0));
}
.hover\:via-blue-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
    var(--tw-gradient-to, rgba(96, 165, 250, 0));
}
.hover\:via-blue-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
    var(--tw-gradient-to, rgba(59, 130, 246, 0));
}
.hover\:via-blue-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
    var(--tw-gradient-to, rgba(37, 99, 235, 0));
}
.hover\:via-blue-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
    var(--tw-gradient-to, rgba(29, 78, 216, 0));
}
.hover\:via-blue-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
    var(--tw-gradient-to, rgba(30, 64, 175, 0));
}
.hover\:via-blue-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
    var(--tw-gradient-to, rgba(30, 58, 138, 0));
}
.hover\:via-indigo-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
    var(--tw-gradient-to, rgba(238, 242, 255, 0));
}
.hover\:via-indigo-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
    var(--tw-gradient-to, rgba(224, 231, 255, 0));
}
.hover\:via-indigo-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
    var(--tw-gradient-to, rgba(199, 210, 254, 0));
}
.hover\:via-indigo-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
    var(--tw-gradient-to, rgba(165, 180, 252, 0));
}
.hover\:via-indigo-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
    var(--tw-gradient-to, rgba(129, 140, 248, 0));
}
.hover\:via-indigo-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
    var(--tw-gradient-to, rgba(99, 102, 241, 0));
}
.hover\:via-indigo-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
    var(--tw-gradient-to, rgba(79, 70, 229, 0));
}
.hover\:via-indigo-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
    var(--tw-gradient-to, rgba(67, 56, 202, 0));
}
.hover\:via-indigo-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
    var(--tw-gradient-to, rgba(55, 48, 163, 0));
}
.hover\:via-indigo-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #312e81,
    var(--tw-gradient-to, rgba(49, 46, 129, 0));
}
.hover\:via-purple-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
    var(--tw-gradient-to, rgba(245, 243, 255, 0));
}
.hover\:via-purple-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
    var(--tw-gradient-to, rgba(237, 233, 254, 0));
}
.hover\:via-purple-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
    var(--tw-gradient-to, rgba(221, 214, 254, 0));
}
.hover\:via-purple-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
    var(--tw-gradient-to, rgba(196, 181, 253, 0));
}
.hover\:via-purple-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
    var(--tw-gradient-to, rgba(167, 139, 250, 0));
}
.hover\:via-purple-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
    var(--tw-gradient-to, rgba(139, 92, 246, 0));
}
.hover\:via-purple-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
    var(--tw-gradient-to, rgba(124, 58, 237, 0));
}
.hover\:via-purple-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
    var(--tw-gradient-to, rgba(109, 40, 217, 0));
}
.hover\:via-purple-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
    var(--tw-gradient-to, rgba(91, 33, 182, 0));
}
.hover\:via-purple-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
    var(--tw-gradient-to, rgba(76, 29, 149, 0));
}
.hover\:via-pink-50:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
    var(--tw-gradient-to, rgba(253, 242, 248, 0));
}
.hover\:via-pink-100:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
    var(--tw-gradient-to, rgba(252, 231, 243, 0));
}
.hover\:via-pink-200:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
    var(--tw-gradient-to, rgba(251, 207, 232, 0));
}
.hover\:via-pink-300:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
    var(--tw-gradient-to, rgba(249, 168, 212, 0));
}
.hover\:via-pink-400:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
    var(--tw-gradient-to, rgba(244, 114, 182, 0));
}
.hover\:via-pink-500:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
    var(--tw-gradient-to, rgba(236, 72, 153, 0));
}
.hover\:via-pink-600:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #db2777,
    var(--tw-gradient-to, rgba(219, 39, 119, 0));
}
.hover\:via-pink-700:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #be185d,
    var(--tw-gradient-to, rgba(190, 24, 93, 0));
}
.hover\:via-pink-800:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
    var(--tw-gradient-to, rgba(157, 23, 77, 0));
}
.hover\:via-pink-900:hover {
  --tw-gradient-stops: var(--tw-gradient-from), #831843,
    var(--tw-gradient-to, rgba(131, 24, 67, 0));
}
.focus\:via-transparent:focus {
  --tw-gradient-stops: var(--tw-gradient-from), transparent,
    var(--tw-gradient-to, rgba(0, 0, 0, 0));
}
.focus\:via-current:focus {
  --tw-gradient-stops: var(--tw-gradient-from), currentColor,
    var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.focus\:via-black:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #000,
    var(--tw-gradient-to, rgba(0, 0, 0, 0));
}
.focus\:via-white:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fff,
    var(--tw-gradient-to, rgba(255, 255, 255, 0));
}
.focus\:via-gray-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
    var(--tw-gradient-to, rgba(249, 250, 251, 0));
}
.focus\:via-gray-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
    var(--tw-gradient-to, rgba(243, 244, 246, 0));
}
.focus\:via-gray-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
    var(--tw-gradient-to, rgba(229, 231, 235, 0));
}
.focus\:via-gray-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
    var(--tw-gradient-to, rgba(209, 213, 219, 0));
}
.focus\:via-gray-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
    var(--tw-gradient-to, rgba(156, 163, 175, 0));
}
.focus\:via-gray-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
    var(--tw-gradient-to, rgba(107, 114, 128, 0));
}
.focus\:via-gray-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
    var(--tw-gradient-to, rgba(75, 85, 99, 0));
}
.focus\:via-gray-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #374151,
    var(--tw-gradient-to, rgba(55, 65, 81, 0));
}
.focus\:via-gray-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
    var(--tw-gradient-to, rgba(31, 41, 55, 0));
}
.focus\:via-gray-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #111827,
    var(--tw-gradient-to, rgba(17, 24, 39, 0));
}
.focus\:via-red-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
    var(--tw-gradient-to, rgba(254, 242, 242, 0));
}
.focus\:via-red-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
    var(--tw-gradient-to, rgba(254, 226, 226, 0));
}
.focus\:via-red-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
    var(--tw-gradient-to, rgba(254, 202, 202, 0));
}
.focus\:via-red-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
    var(--tw-gradient-to, rgba(252, 165, 165, 0));
}
.focus\:via-red-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f87171,
    var(--tw-gradient-to, rgba(248, 113, 113, 0));
}
.focus\:via-red-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
    var(--tw-gradient-to, rgba(239, 68, 68, 0));
}
.focus\:via-red-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
    var(--tw-gradient-to, rgba(220, 38, 38, 0));
}
.focus\:via-red-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
    var(--tw-gradient-to, rgba(185, 28, 28, 0));
}
.focus\:via-red-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
    var(--tw-gradient-to, rgba(153, 27, 27, 0));
}
.focus\:via-red-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
    var(--tw-gradient-to, rgba(127, 29, 29, 0));
}
.focus\:via-yellow-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
    var(--tw-gradient-to, rgba(255, 251, 235, 0));
}
.focus\:via-yellow-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
    var(--tw-gradient-to, rgba(254, 243, 199, 0));
}
.focus\:via-yellow-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
    var(--tw-gradient-to, rgba(253, 230, 138, 0));
}
.focus\:via-yellow-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
    var(--tw-gradient-to, rgba(252, 211, 77, 0));
}
.focus\:via-yellow-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
    var(--tw-gradient-to, rgba(251, 191, 36, 0));
}
.focus\:via-yellow-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
    var(--tw-gradient-to, rgba(245, 158, 11, 0));
}
.focus\:via-yellow-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #d97706,
    var(--tw-gradient-to, rgba(217, 119, 6, 0));
}
.focus\:via-yellow-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #b45309,
    var(--tw-gradient-to, rgba(180, 83, 9, 0));
}
.focus\:via-yellow-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #92400e,
    var(--tw-gradient-to, rgba(146, 64, 14, 0));
}
.focus\:via-yellow-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #78350f,
    var(--tw-gradient-to, rgba(120, 53, 15, 0));
}
.focus\:via-green-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
    var(--tw-gradient-to, rgba(236, 253, 245, 0));
}
.focus\:via-green-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
    var(--tw-gradient-to, rgba(209, 250, 229, 0));
}
.focus\:via-green-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
    var(--tw-gradient-to, rgba(167, 243, 208, 0));
}
.focus\:via-green-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
    var(--tw-gradient-to, rgba(110, 231, 183, 0));
}
.focus\:via-green-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #34d399,
    var(--tw-gradient-to, rgba(52, 211, 153, 0));
}
.focus\:via-green-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #10b981,
    var(--tw-gradient-to, rgba(16, 185, 129, 0));
}
.focus\:via-green-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #059669,
    var(--tw-gradient-to, rgba(5, 150, 105, 0));
}
.focus\:via-green-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #047857,
    var(--tw-gradient-to, rgba(4, 120, 87, 0));
}
.focus\:via-green-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #065f46,
    var(--tw-gradient-to, rgba(6, 95, 70, 0));
}
.focus\:via-green-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
    var(--tw-gradient-to, rgba(6, 78, 59, 0));
}
.focus\:via-blue-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
    var(--tw-gradient-to, rgba(239, 246, 255, 0));
}
.focus\:via-blue-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
    var(--tw-gradient-to, rgba(219, 234, 254, 0));
}
.focus\:via-blue-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
    var(--tw-gradient-to, rgba(191, 219, 254, 0));
}
.focus\:via-blue-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
    var(--tw-gradient-to, rgba(147, 197, 253, 0));
}
.focus\:via-blue-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
    var(--tw-gradient-to, rgba(96, 165, 250, 0));
}
.focus\:via-blue-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
    var(--tw-gradient-to, rgba(59, 130, 246, 0));
}
.focus\:via-blue-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
    var(--tw-gradient-to, rgba(37, 99, 235, 0));
}
.focus\:via-blue-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
    var(--tw-gradient-to, rgba(29, 78, 216, 0));
}
.focus\:via-blue-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
    var(--tw-gradient-to, rgba(30, 64, 175, 0));
}
.focus\:via-blue-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
    var(--tw-gradient-to, rgba(30, 58, 138, 0));
}
.focus\:via-indigo-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
    var(--tw-gradient-to, rgba(238, 242, 255, 0));
}
.focus\:via-indigo-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
    var(--tw-gradient-to, rgba(224, 231, 255, 0));
}
.focus\:via-indigo-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
    var(--tw-gradient-to, rgba(199, 210, 254, 0));
}
.focus\:via-indigo-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
    var(--tw-gradient-to, rgba(165, 180, 252, 0));
}
.focus\:via-indigo-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
    var(--tw-gradient-to, rgba(129, 140, 248, 0));
}
.focus\:via-indigo-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
    var(--tw-gradient-to, rgba(99, 102, 241, 0));
}
.focus\:via-indigo-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
    var(--tw-gradient-to, rgba(79, 70, 229, 0));
}
.focus\:via-indigo-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
    var(--tw-gradient-to, rgba(67, 56, 202, 0));
}
.focus\:via-indigo-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
    var(--tw-gradient-to, rgba(55, 48, 163, 0));
}
.focus\:via-indigo-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #312e81,
    var(--tw-gradient-to, rgba(49, 46, 129, 0));
}
.focus\:via-purple-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
    var(--tw-gradient-to, rgba(245, 243, 255, 0));
}
.focus\:via-purple-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
    var(--tw-gradient-to, rgba(237, 233, 254, 0));
}
.focus\:via-purple-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
    var(--tw-gradient-to, rgba(221, 214, 254, 0));
}
.focus\:via-purple-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
    var(--tw-gradient-to, rgba(196, 181, 253, 0));
}
.focus\:via-purple-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
    var(--tw-gradient-to, rgba(167, 139, 250, 0));
}
.focus\:via-purple-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
    var(--tw-gradient-to, rgba(139, 92, 246, 0));
}
.focus\:via-purple-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
    var(--tw-gradient-to, rgba(124, 58, 237, 0));
}
.focus\:via-purple-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
    var(--tw-gradient-to, rgba(109, 40, 217, 0));
}
.focus\:via-purple-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
    var(--tw-gradient-to, rgba(91, 33, 182, 0));
}
.focus\:via-purple-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
    var(--tw-gradient-to, rgba(76, 29, 149, 0));
}
.focus\:via-pink-50:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
    var(--tw-gradient-to, rgba(253, 242, 248, 0));
}
.focus\:via-pink-100:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
    var(--tw-gradient-to, rgba(252, 231, 243, 0));
}
.focus\:via-pink-200:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
    var(--tw-gradient-to, rgba(251, 207, 232, 0));
}
.focus\:via-pink-300:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
    var(--tw-gradient-to, rgba(249, 168, 212, 0));
}
.focus\:via-pink-400:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
    var(--tw-gradient-to, rgba(244, 114, 182, 0));
}
.focus\:via-pink-500:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
    var(--tw-gradient-to, rgba(236, 72, 153, 0));
}
.focus\:via-pink-600:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #db2777,
    var(--tw-gradient-to, rgba(219, 39, 119, 0));
}
.focus\:via-pink-700:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #be185d,
    var(--tw-gradient-to, rgba(190, 24, 93, 0));
}
.focus\:via-pink-800:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
    var(--tw-gradient-to, rgba(157, 23, 77, 0));
}
.focus\:via-pink-900:focus {
  --tw-gradient-stops: var(--tw-gradient-from), #831843,
    var(--tw-gradient-to, rgba(131, 24, 67, 0));
}
.to-transparent {
  --tw-gradient-to: transparent;
}
.to-current {
  --tw-gradient-to: currentColor;
}
.to-black {
  --tw-gradient-to: #000;
}
.to-white {
  --tw-gradient-to: #fff;
}
.to-gray-50 {
  --tw-gradient-to: #f9fafb;
}
.to-gray-100 {
  --tw-gradient-to: #f3f4f6;
}
.to-gray-200 {
  --tw-gradient-to: #e5e7eb;
}
.to-gray-300 {
  --tw-gradient-to: #d1d5db;
}
.to-gray-400 {
  --tw-gradient-to: #9ca3af;
}
.to-gray-500 {
  --tw-gradient-to: #6b7280;
}
.to-gray-600 {
  --tw-gradient-to: #4b5563;
}
.to-gray-700 {
  --tw-gradient-to: #374151;
}
.to-gray-800 {
  --tw-gradient-to: #1f2937;
}
.to-gray-900 {
  --tw-gradient-to: #111827;
}
.to-red-50 {
  --tw-gradient-to: #fef2f2;
}
.to-red-100 {
  --tw-gradient-to: #fee2e2;
}
.to-red-200 {
  --tw-gradient-to: #fecaca;
}
.to-red-300 {
  --tw-gradient-to: #fca5a5;
}
.to-red-400 {
  --tw-gradient-to: #f87171;
}
.to-red-500 {
  --tw-gradient-to: #ef4444;
}
.to-red-600 {
  --tw-gradient-to: #dc2626;
}
.to-red-700 {
  --tw-gradient-to: #b91c1c;
}
.to-red-800 {
  --tw-gradient-to: #991b1b;
}
.to-red-900 {
  --tw-gradient-to: #7f1d1d;
}
.to-yellow-50 {
  --tw-gradient-to: #fffbeb;
}
.to-yellow-100 {
  --tw-gradient-to: #fef3c7;
}
.to-yellow-200 {
  --tw-gradient-to: #fde68a;
}
.to-yellow-300 {
  --tw-gradient-to: #fcd34d;
}
.to-yellow-400 {
  --tw-gradient-to: #fbbf24;
}
.to-yellow-500 {
  --tw-gradient-to: #f59e0b;
}
.to-yellow-600 {
  --tw-gradient-to: #d97706;
}
.to-yellow-700 {
  --tw-gradient-to: #b45309;
}
.to-yellow-800 {
  --tw-gradient-to: #92400e;
}
.to-yellow-900 {
  --tw-gradient-to: #78350f;
}
.to-green-50 {
  --tw-gradient-to: #ecfdf5;
}
.to-green-100 {
  --tw-gradient-to: #d1fae5;
}
.to-green-200 {
  --tw-gradient-to: #a7f3d0;
}
.to-green-300 {
  --tw-gradient-to: #6ee7b7;
}
.to-green-400 {
  --tw-gradient-to: #34d399;
}
.to-green-500 {
  --tw-gradient-to: #10b981;
}
.to-green-600 {
  --tw-gradient-to: #059669;
}
.to-green-700 {
  --tw-gradient-to: #047857;
}
.to-green-800 {
  --tw-gradient-to: #065f46;
}
.to-green-900 {
  --tw-gradient-to: #064e3b;
}
.to-blue-50 {
  --tw-gradient-to: #eff6ff;
}
.to-blue-100 {
  --tw-gradient-to: #dbeafe;
}
.to-blue-200 {
  --tw-gradient-to: #bfdbfe;
}
.to-blue-300 {
  --tw-gradient-to: #93c5fd;
}
.to-blue-400 {
  --tw-gradient-to: #60a5fa;
}
.to-blue-500 {
  --tw-gradient-to: #3b82f6;
}
.to-blue-600 {
  --tw-gradient-to: #2563eb;
}
.to-blue-700 {
  --tw-gradient-to: #1d4ed8;
}
.to-blue-800 {
  --tw-gradient-to: #1e40af;
}
.to-blue-900 {
  --tw-gradient-to: #1e3a8a;
}
.to-indigo-50 {
  --tw-gradient-to: #eef2ff;
}
.to-indigo-100 {
  --tw-gradient-to: #e0e7ff;
}
.to-indigo-200 {
  --tw-gradient-to: #c7d2fe;
}
.to-indigo-300 {
  --tw-gradient-to: #a5b4fc;
}
.to-indigo-400 {
  --tw-gradient-to: #818cf8;
}
.to-indigo-500 {
  --tw-gradient-to: #6366f1;
}
.to-indigo-600 {
  --tw-gradient-to: #4f46e5;
}
.to-indigo-700 {
  --tw-gradient-to: #4338ca;
}
.to-indigo-800 {
  --tw-gradient-to: #3730a3;
}
.to-indigo-900 {
  --tw-gradient-to: #312e81;
}
.to-purple-50 {
  --tw-gradient-to: #f5f3ff;
}
.to-purple-100 {
  --tw-gradient-to: #ede9fe;
}
.to-purple-200 {
  --tw-gradient-to: #ddd6fe;
}
.to-purple-300 {
  --tw-gradient-to: #c4b5fd;
}
.to-purple-400 {
  --tw-gradient-to: #a78bfa;
}
.to-purple-500 {
  --tw-gradient-to: #8b5cf6;
}
.to-purple-600 {
  --tw-gradient-to: #7c3aed;
}
.to-purple-700 {
  --tw-gradient-to: #6d28d9;
}
.to-purple-800 {
  --tw-gradient-to: #5b21b6;
}
.to-purple-900 {
  --tw-gradient-to: #4c1d95;
}
.to-pink-50 {
  --tw-gradient-to: #fdf2f8;
}
.to-pink-100 {
  --tw-gradient-to: #fce7f3;
}
.to-pink-200 {
  --tw-gradient-to: #fbcfe8;
}
.to-pink-300 {
  --tw-gradient-to: #f9a8d4;
}
.to-pink-400 {
  --tw-gradient-to: #f472b6;
}
.to-pink-500 {
  --tw-gradient-to: #ec4899;
}
.to-pink-600 {
  --tw-gradient-to: #db2777;
}
.to-pink-700 {
  --tw-gradient-to: #be185d;
}
.to-pink-800 {
  --tw-gradient-to: #9d174d;
}
.to-pink-900 {
  --tw-gradient-to: #831843;
}
.hover\:to-transparent:hover {
  --tw-gradient-to: transparent;
}
.hover\:to-current:hover {
  --tw-gradient-to: currentColor;
}
.hover\:to-black:hover {
  --tw-gradient-to: #000;
}
.hover\:to-white:hover {
  --tw-gradient-to: #fff;
}
.hover\:to-gray-50:hover {
  --tw-gradient-to: #f9fafb;
}
.hover\:to-gray-100:hover {
  --tw-gradient-to: #f3f4f6;
}
.hover\:to-gray-200:hover {
  --tw-gradient-to: #e5e7eb;
}
.hover\:to-gray-300:hover {
  --tw-gradient-to: #d1d5db;
}
.hover\:to-gray-400:hover {
  --tw-gradient-to: #9ca3af;
}
.hover\:to-gray-500:hover {
  --tw-gradient-to: #6b7280;
}
.hover\:to-gray-600:hover {
  --tw-gradient-to: #4b5563;
}
.hover\:to-gray-700:hover {
  --tw-gradient-to: #374151;
}
.hover\:to-gray-800:hover {
  --tw-gradient-to: #1f2937;
}
.hover\:to-gray-900:hover {
  --tw-gradient-to: #111827;
}
.hover\:to-red-50:hover {
  --tw-gradient-to: #fef2f2;
}
.hover\:to-red-100:hover {
  --tw-gradient-to: #fee2e2;
}
.hover\:to-red-200:hover {
  --tw-gradient-to: #fecaca;
}
.hover\:to-red-300:hover {
  --tw-gradient-to: #fca5a5;
}
.hover\:to-red-400:hover {
  --tw-gradient-to: #f87171;
}
.hover\:to-red-500:hover {
  --tw-gradient-to: #ef4444;
}
.hover\:to-red-600:hover {
  --tw-gradient-to: #dc2626;
}
.hover\:to-red-700:hover {
  --tw-gradient-to: #b91c1c;
}
.hover\:to-red-800:hover {
  --tw-gradient-to: #991b1b;
}
.hover\:to-red-900:hover {
  --tw-gradient-to: #7f1d1d;
}
.hover\:to-yellow-50:hover {
  --tw-gradient-to: #fffbeb;
}
.hover\:to-yellow-100:hover {
  --tw-gradient-to: #fef3c7;
}
.hover\:to-yellow-200:hover {
  --tw-gradient-to: #fde68a;
}
.hover\:to-yellow-300:hover {
  --tw-gradient-to: #fcd34d;
}
.hover\:to-yellow-400:hover {
  --tw-gradient-to: #fbbf24;
}
.hover\:to-yellow-500:hover {
  --tw-gradient-to: #f59e0b;
}
.hover\:to-yellow-600:hover {
  --tw-gradient-to: #d97706;
}
.hover\:to-yellow-700:hover {
  --tw-gradient-to: #b45309;
}
.hover\:to-yellow-800:hover {
  --tw-gradient-to: #92400e;
}
.hover\:to-yellow-900:hover {
  --tw-gradient-to: #78350f;
}
.hover\:to-green-50:hover {
  --tw-gradient-to: #ecfdf5;
}
.hover\:to-green-100:hover {
  --tw-gradient-to: #d1fae5;
}
.hover\:to-green-200:hover {
  --tw-gradient-to: #a7f3d0;
}
.hover\:to-green-300:hover {
  --tw-gradient-to: #6ee7b7;
}
.hover\:to-green-400:hover {
  --tw-gradient-to: #34d399;
}
.hover\:to-green-500:hover {
  --tw-gradient-to: #10b981;
}
.hover\:to-green-600:hover {
  --tw-gradient-to: #059669;
}
.hover\:to-green-700:hover {
  --tw-gradient-to: #047857;
}
.hover\:to-green-800:hover {
  --tw-gradient-to: #065f46;
}
.hover\:to-green-900:hover {
  --tw-gradient-to: #064e3b;
}
.hover\:to-blue-50:hover {
  --tw-gradient-to: #eff6ff;
}
.hover\:to-blue-100:hover {
  --tw-gradient-to: #dbeafe;
}
.hover\:to-blue-200:hover {
  --tw-gradient-to: #bfdbfe;
}
.hover\:to-blue-300:hover {
  --tw-gradient-to: #93c5fd;
}
.hover\:to-blue-400:hover {
  --tw-gradient-to: #60a5fa;
}
.hover\:to-blue-500:hover {
  --tw-gradient-to: #3b82f6;
}
.hover\:to-blue-600:hover {
  --tw-gradient-to: #2563eb;
}
.hover\:to-blue-700:hover {
  --tw-gradient-to: #1d4ed8;
}
.hover\:to-blue-800:hover {
  --tw-gradient-to: #1e40af;
}
.hover\:to-blue-900:hover {
  --tw-gradient-to: #1e3a8a;
}
.hover\:to-indigo-50:hover {
  --tw-gradient-to: #eef2ff;
}
.hover\:to-indigo-100:hover {
  --tw-gradient-to: #e0e7ff;
}
.hover\:to-indigo-200:hover {
  --tw-gradient-to: #c7d2fe;
}
.hover\:to-indigo-300:hover {
  --tw-gradient-to: #a5b4fc;
}
.hover\:to-indigo-400:hover {
  --tw-gradient-to: #818cf8;
}
.hover\:to-indigo-500:hover {
  --tw-gradient-to: #6366f1;
}
.hover\:to-indigo-600:hover {
  --tw-gradient-to: #4f46e5;
}
.hover\:to-indigo-700:hover {
  --tw-gradient-to: #4338ca;
}
.hover\:to-indigo-800:hover {
  --tw-gradient-to: #3730a3;
}
.hover\:to-indigo-900:hover {
  --tw-gradient-to: #312e81;
}
.hover\:to-purple-50:hover {
  --tw-gradient-to: #f5f3ff;
}
.hover\:to-purple-100:hover {
  --tw-gradient-to: #ede9fe;
}
.hover\:to-purple-200:hover {
  --tw-gradient-to: #ddd6fe;
}
.hover\:to-purple-300:hover {
  --tw-gradient-to: #c4b5fd;
}
.hover\:to-purple-400:hover {
  --tw-gradient-to: #a78bfa;
}
.hover\:to-purple-500:hover {
  --tw-gradient-to: #8b5cf6;
}
.hover\:to-purple-600:hover {
  --tw-gradient-to: #7c3aed;
}
.hover\:to-purple-700:hover {
  --tw-gradient-to: #6d28d9;
}
.hover\:to-purple-800:hover {
  --tw-gradient-to: #5b21b6;
}
.hover\:to-purple-900:hover {
  --tw-gradient-to: #4c1d95;
}
.hover\:to-pink-50:hover {
  --tw-gradient-to: #fdf2f8;
}
.hover\:to-pink-100:hover {
  --tw-gradient-to: #fce7f3;
}
.hover\:to-pink-200:hover {
  --tw-gradient-to: #fbcfe8;
}
.hover\:to-pink-300:hover {
  --tw-gradient-to: #f9a8d4;
}
.hover\:to-pink-400:hover {
  --tw-gradient-to: #f472b6;
}
.hover\:to-pink-500:hover {
  --tw-gradient-to: #ec4899;
}
.hover\:to-pink-600:hover {
  --tw-gradient-to: #db2777;
}
.hover\:to-pink-700:hover {
  --tw-gradient-to: #be185d;
}
.hover\:to-pink-800:hover {
  --tw-gradient-to: #9d174d;
}
.hover\:to-pink-900:hover {
  --tw-gradient-to: #831843;
}
.focus\:to-transparent:focus {
  --tw-gradient-to: transparent;
}
.focus\:to-current:focus {
  --tw-gradient-to: currentColor;
}
.focus\:to-black:focus {
  --tw-gradient-to: #000;
}
.focus\:to-white:focus {
  --tw-gradient-to: #fff;
}
.focus\:to-gray-50:focus {
  --tw-gradient-to: #f9fafb;
}
.focus\:to-gray-100:focus {
  --tw-gradient-to: #f3f4f6;
}
.focus\:to-gray-200:focus {
  --tw-gradient-to: #e5e7eb;
}
.focus\:to-gray-300:focus {
  --tw-gradient-to: #d1d5db;
}
.focus\:to-gray-400:focus {
  --tw-gradient-to: #9ca3af;
}
.focus\:to-gray-500:focus {
  --tw-gradient-to: #6b7280;
}
.focus\:to-gray-600:focus {
  --tw-gradient-to: #4b5563;
}
.focus\:to-gray-700:focus {
  --tw-gradient-to: #374151;
}
.focus\:to-gray-800:focus {
  --tw-gradient-to: #1f2937;
}
.focus\:to-gray-900:focus {
  --tw-gradient-to: #111827;
}
.focus\:to-red-50:focus {
  --tw-gradient-to: #fef2f2;
}
.focus\:to-red-100:focus {
  --tw-gradient-to: #fee2e2;
}
.focus\:to-red-200:focus {
  --tw-gradient-to: #fecaca;
}
.focus\:to-red-300:focus {
  --tw-gradient-to: #fca5a5;
}
.focus\:to-red-400:focus {
  --tw-gradient-to: #f87171;
}
.focus\:to-red-500:focus {
  --tw-gradient-to: #ef4444;
}
.focus\:to-red-600:focus {
  --tw-gradient-to: #dc2626;
}
.focus\:to-red-700:focus {
  --tw-gradient-to: #b91c1c;
}
.focus\:to-red-800:focus {
  --tw-gradient-to: #991b1b;
}
.focus\:to-red-900:focus {
  --tw-gradient-to: #7f1d1d;
}
.focus\:to-yellow-50:focus {
  --tw-gradient-to: #fffbeb;
}
.focus\:to-yellow-100:focus {
  --tw-gradient-to: #fef3c7;
}
.focus\:to-yellow-200:focus {
  --tw-gradient-to: #fde68a;
}
.focus\:to-yellow-300:focus {
  --tw-gradient-to: #fcd34d;
}
.focus\:to-yellow-400:focus {
  --tw-gradient-to: #fbbf24;
}
.focus\:to-yellow-500:focus {
  --tw-gradient-to: #f59e0b;
}
.focus\:to-yellow-600:focus {
  --tw-gradient-to: #d97706;
}
.focus\:to-yellow-700:focus {
  --tw-gradient-to: #b45309;
}
.focus\:to-yellow-800:focus {
  --tw-gradient-to: #92400e;
}
.focus\:to-yellow-900:focus {
  --tw-gradient-to: #78350f;
}
.focus\:to-green-50:focus {
  --tw-gradient-to: #ecfdf5;
}
.focus\:to-green-100:focus {
  --tw-gradient-to: #d1fae5;
}
.focus\:to-green-200:focus {
  --tw-gradient-to: #a7f3d0;
}
.focus\:to-green-300:focus {
  --tw-gradient-to: #6ee7b7;
}
.focus\:to-green-400:focus {
  --tw-gradient-to: #34d399;
}
.focus\:to-green-500:focus {
  --tw-gradient-to: #10b981;
}
.focus\:to-green-600:focus {
  --tw-gradient-to: #059669;
}
.focus\:to-green-700:focus {
  --tw-gradient-to: #047857;
}
.focus\:to-green-800:focus {
  --tw-gradient-to: #065f46;
}
.focus\:to-green-900:focus {
  --tw-gradient-to: #064e3b;
}
.focus\:to-blue-50:focus {
  --tw-gradient-to: #eff6ff;
}
.focus\:to-blue-100:focus {
  --tw-gradient-to: #dbeafe;
}
.focus\:to-blue-200:focus {
  --tw-gradient-to: #bfdbfe;
}
.focus\:to-blue-300:focus {
  --tw-gradient-to: #93c5fd;
}
.focus\:to-blue-400:focus {
  --tw-gradient-to: #60a5fa;
}
.focus\:to-blue-500:focus {
  --tw-gradient-to: #3b82f6;
}
.focus\:to-blue-600:focus {
  --tw-gradient-to: #2563eb;
}
.focus\:to-blue-700:focus {
  --tw-gradient-to: #1d4ed8;
}
.focus\:to-blue-800:focus {
  --tw-gradient-to: #1e40af;
}
.focus\:to-blue-900:focus {
  --tw-gradient-to: #1e3a8a;
}
.focus\:to-indigo-50:focus {
  --tw-gradient-to: #eef2ff;
}
.focus\:to-indigo-100:focus {
  --tw-gradient-to: #e0e7ff;
}
.focus\:to-indigo-200:focus {
  --tw-gradient-to: #c7d2fe;
}
.focus\:to-indigo-300:focus {
  --tw-gradient-to: #a5b4fc;
}
.focus\:to-indigo-400:focus {
  --tw-gradient-to: #818cf8;
}
.focus\:to-indigo-500:focus {
  --tw-gradient-to: #6366f1;
}
.focus\:to-indigo-600:focus {
  --tw-gradient-to: #4f46e5;
}
.focus\:to-indigo-700:focus {
  --tw-gradient-to: #4338ca;
}
.focus\:to-indigo-800:focus {
  --tw-gradient-to: #3730a3;
}
.focus\:to-indigo-900:focus {
  --tw-gradient-to: #312e81;
}
.focus\:to-purple-50:focus {
  --tw-gradient-to: #f5f3ff;
}
.focus\:to-purple-100:focus {
  --tw-gradient-to: #ede9fe;
}
.focus\:to-purple-200:focus {
  --tw-gradient-to: #ddd6fe;
}
.focus\:to-purple-300:focus {
  --tw-gradient-to: #c4b5fd;
}
.focus\:to-purple-400:focus {
  --tw-gradient-to: #a78bfa;
}
.focus\:to-purple-500:focus {
  --tw-gradient-to: #8b5cf6;
}
.focus\:to-purple-600:focus {
  --tw-gradient-to: #7c3aed;
}
.focus\:to-purple-700:focus {
  --tw-gradient-to: #6d28d9;
}
.focus\:to-purple-800:focus {
  --tw-gradient-to: #5b21b6;
}
.focus\:to-purple-900:focus {
  --tw-gradient-to: #4c1d95;
}
.focus\:to-pink-50:focus {
  --tw-gradient-to: #fdf2f8;
}
.focus\:to-pink-100:focus {
  --tw-gradient-to: #fce7f3;
}
.focus\:to-pink-200:focus {
  --tw-gradient-to: #fbcfe8;
}
.focus\:to-pink-300:focus {
  --tw-gradient-to: #f9a8d4;
}
.focus\:to-pink-400:focus {
  --tw-gradient-to: #f472b6;
}
.focus\:to-pink-500:focus {
  --tw-gradient-to: #ec4899;
}
.focus\:to-pink-600:focus {
  --tw-gradient-to: #db2777;
}
.focus\:to-pink-700:focus {
  --tw-gradient-to: #be185d;
}
.focus\:to-pink-800:focus {
  --tw-gradient-to: #9d174d;
}
.focus\:to-pink-900:focus {
  --tw-gradient-to: #831843;
}
.decoration-slice {
  -webkit-box-decoration-break: slice;
  box-decoration-break: slice;
}
.decoration-clone {
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
}
.bg-auto {
  background-size: auto;
}
.bg-cover {
  background-size: cover;
}
.bg-contain {
  background-size: contain;
}
.bg-fixed {
  background-attachment: fixed;
}
.bg-local {
  background-attachment: local;
}
.bg-scroll {
  background-attachment: scroll;
}
.bg-clip-border {
  background-clip: border-box;
}
.bg-clip-padding {
  background-clip: padding-box;
}
.bg-clip-content {
  background-clip: content-box;
}
.bg-clip-text {
  -webkit-background-clip: text;
  background-clip: text;
}
.bg-bottom {
  background-position: bottom;
}
.bg-center {
  background-position: center;
}
.bg-left {
  background-position: left;
}
.bg-left-bottom {
  background-position: left bottom;
}
.bg-left-top {
  background-position: left top;
}
.bg-right {
  background-position: right;
}
.bg-right-bottom {
  background-position: right bottom;
}
.bg-right-top {
  background-position: right top;
}
.bg-top {
  background-position: top;
}
.bg-repeat {
  background-repeat: repeat;
}
.bg-no-repeat {
  background-repeat: no-repeat;
}
.bg-repeat-x {
  background-repeat: repeat-x;
}
.bg-repeat-y {
  background-repeat: repeat-y;
}
.bg-repeat-round {
  background-repeat: round;
}
.bg-repeat-space {
  background-repeat: space;
}
.bg-origin-border {
  background-origin: border-box;
}
.bg-origin-padding {
  background-origin: padding-box;
}
.bg-origin-content {
  background-origin: content-box;
}
.fill-current {
  fill: currentColor;
}
.stroke-current {
  stroke: currentColor;
}
.stroke-0 {
  stroke-width: 0;
}
.stroke-1 {
  stroke-width: 1;
}
.stroke-2 {
  stroke-width: 2;
}
.object-contain {
  object-fit: contain;
}
.object-cover {
  object-fit: cover;
}
.object-fill {
  object-fit: fill;
}
.object-none {
  object-fit: none;
}
.object-scale-down {
  object-fit: scale-down;
}
.object-bottom {
  object-position: bottom;
}
.object-center {
  object-position: center;
}
.object-left {
  object-position: left;
}
.object-left-bottom {
  object-position: left bottom;
}
.object-left-top {
  object-position: left top;
}
.object-right {
  object-position: right;
}
.object-right-bottom {
  object-position: right bottom;
}
.object-right-top {
  object-position: right top;
}
.object-top {
  object-position: top;
}
.p-0 {
  padding: 0;
}
.p-1 {
  padding: 0.25rem;
}
.p-2 {
  padding: 0.5rem;
}
.p-3 {
  padding: 0.75rem;
}
.p-4 {
  padding: 1rem;
}
.p-5 {
  padding: 1.25rem;
}
.p-6 {
  padding: 1.5rem;
}
.p-7 {
  padding: 1.75rem;
}
.p-8 {
  padding: 2rem;
}
.p-9 {
  padding: 2.25rem;
}
.p-10 {
  padding: 2.5rem;
}
.p-11 {
  padding: 2.75rem;
}
.p-12 {
  padding: 3rem;
}
.p-14 {
  padding: 3.5rem;
}
.p-16 {
  padding: 4rem;
}
.p-20 {
  padding: 5rem;
}
.p-24 {
  padding: 6rem;
}
.p-28 {
  padding: 7rem;
}
.p-32 {
  padding: 8rem;
}
.p-36 {
  padding: 9rem;
}
.p-40 {
  padding: 10rem;
}
.p-44 {
  padding: 11rem;
}
.p-48 {
  padding: 12rem;
}
.p-52 {
  padding: 13rem;
}
.p-56 {
  padding: 14rem;
}
.p-60 {
  padding: 15rem;
}
.p-64 {
  padding: 16rem;
}
.p-72 {
  padding: 18rem;
}
.p-80 {
  padding: 20rem;
}
.p-96 {
  padding: 24rem;
}
.p-px {
  padding: 1px;
}
.p-0\.5 {
  padding: 0.125rem;
}
.p-1\.5 {
  padding: 0.375rem;
}
.p-2\.5 {
  padding: 0.625rem;
}
.p-3\.5 {
  padding: 0.875rem;
}
.px-0 {
  padding-left: 0;
  padding-right: 0;
}
.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}
.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}
.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}
.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}
.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}
.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}
.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}
.px-24 {
  padding-left: 6rem;
  padding-right: 6rem;
}
.px-28 {
  padding-left: 7rem;
  padding-right: 7rem;
}
.px-32 {
  padding-left: 8rem;
  padding-right: 8rem;
}
.px-36 {
  padding-left: 9rem;
  padding-right: 9rem;
}
.px-40 {
  padding-left: 10rem;
  padding-right: 10rem;
}
.px-44 {
  padding-left: 11rem;
  padding-right: 11rem;
}
.px-48 {
  padding-left: 12rem;
  padding-right: 12rem;
}
.px-52 {
  padding-left: 13rem;
  padding-right: 13rem;
}
.px-56 {
  padding-left: 14rem;
  padding-right: 14rem;
}
.px-60 {
  padding-left: 15rem;
  padding-right: 15rem;
}
.px-64 {
  padding-left: 16rem;
  padding-right: 16rem;
}
.px-72 {
  padding-left: 18rem;
  padding-right: 18rem;
}
.px-80 {
  padding-left: 20rem;
  padding-right: 20rem;
}
.px-96 {
  padding-left: 24rem;
  padding-right: 24rem;
}
.px-px {
  padding-left: 1px;
  padding-right: 1px;
}
.px-0\.5 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}
.px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}
.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.px-3\.5 {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}
.py-0 {
  padding-top: 0;
  padding-bottom: 0;
}
.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}
.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}
.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}
.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}
.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}
.py-28 {
  padding-top: 7rem;
  padding-bottom: 7rem;
}
.py-32 {
  padding-top: 8rem;
  padding-bottom: 8rem;
}
.py-36 {
  padding-top: 9rem;
  padding-bottom: 9rem;
}
.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}
.py-44 {
  padding-top: 11rem;
  padding-bottom: 11rem;
}
.py-48 {
  padding-top: 12rem;
  padding-bottom: 12rem;
}
.py-52 {
  padding-top: 13rem;
  padding-bottom: 13rem;
}
.py-56 {
  padding-top: 14rem;
  padding-bottom: 14rem;
}
.py-60 {
  padding-top: 15rem;
  padding-bottom: 15rem;
}
.py-64 {
  padding-top: 16rem;
  padding-bottom: 16rem;
}
.py-72 {
  padding-top: 18rem;
  padding-bottom: 18rem;
}
.py-80 {
  padding-top: 20rem;
  padding-bottom: 20rem;
}
.py-96 {
  padding-top: 24rem;
  padding-bottom: 24rem;
}
.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}
.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}
.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}
.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}
.py-3\.5 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}
.pt-0 {
  padding-top: 0;
}
.pt-1 {
  padding-top: 0.25rem;
}
.pt-2 {
  padding-top: 0.5rem;
}
.pt-3 {
  padding-top: 0.75rem;
}
.pt-4 {
  padding-top: 1rem;
}
.pt-5 {
  padding-top: 1.25rem;
}
.pt-6 {
  padding-top: 1.5rem;
}
.pt-7 {
  padding-top: 1.75rem;
}
.pt-8 {
  padding-top: 2rem;
}
.pt-9 {
  padding-top: 2.25rem;
}
.pt-10 {
  padding-top: 2.5rem;
}
.pt-11 {
  padding-top: 2.75rem;
}
.pt-12 {
  padding-top: 3rem;
}
.pt-14 {
  padding-top: 3.5rem;
}
.pt-16 {
  padding-top: 4rem;
}
.pt-20 {
  padding-top: 5rem;
}
.pt-24 {
  padding-top: 6rem;
}
.pt-28 {
  padding-top: 7rem;
}
.pt-32 {
  padding-top: 8rem;
}
.pt-36 {
  padding-top: 9rem;
}
.pt-40 {
  padding-top: 10rem;
}
.pt-44 {
  padding-top: 11rem;
}
.pt-48 {
  padding-top: 12rem;
}
.pt-52 {
  padding-top: 13rem;
}
.pt-56 {
  padding-top: 14rem;
}
.pt-60 {
  padding-top: 15rem;
}
.pt-64 {
  padding-top: 16rem;
}
.pt-72 {
  padding-top: 18rem;
}
.pt-80 {
  padding-top: 20rem;
}
.pt-96 {
  padding-top: 24rem;
}
.pt-px {
  padding-top: 1px;
}
.pt-0\.5 {
  padding-top: 0.125rem;
}
.pt-1\.5 {
  padding-top: 0.375rem;
}
.pt-2\.5 {
  padding-top: 0.625rem;
}
.pt-3\.5 {
  padding-top: 0.875rem;
}
.pr-0 {
  padding-right: 0;
}
.pr-1 {
  padding-right: 0.25rem;
}
.pr-2 {
  padding-right: 0.5rem;
}
.pr-3 {
  padding-right: 0.75rem;
}
.pr-4 {
  padding-right: 1rem;
}
.pr-5 {
  padding-right: 1.25rem;
}
.pr-6 {
  padding-right: 1.5rem;
}
.pr-7 {
  padding-right: 1.75rem;
}
.pr-8 {
  padding-right: 2rem;
}
.pr-9 {
  padding-right: 2.25rem;
}
.pr-10 {
  padding-right: 2.5rem;
}
.pr-11 {
  padding-right: 2.75rem;
}
.pr-12 {
  padding-right: 3rem;
}
.pr-14 {
  padding-right: 3.5rem;
}
.pr-16 {
  padding-right: 4rem;
}
.pr-20 {
  padding-right: 5rem;
}
.pr-24 {
  padding-right: 6rem;
}
.pr-28 {
  padding-right: 7rem;
}
.pr-32 {
  padding-right: 8rem;
}
.pr-36 {
  padding-right: 9rem;
}
.pr-40 {
  padding-right: 10rem;
}
.pr-44 {
  padding-right: 11rem;
}
.pr-48 {
  padding-right: 12rem;
}
.pr-52 {
  padding-right: 13rem;
}
.pr-56 {
  padding-right: 14rem;
}
.pr-60 {
  padding-right: 15rem;
}
.pr-64 {
  padding-right: 16rem;
}
.pr-72 {
  padding-right: 18rem;
}
.pr-80 {
  padding-right: 20rem;
}
.pr-96 {
  padding-right: 24rem;
}
.pr-px {
  padding-right: 1px;
}
.pr-0\.5 {
  padding-right: 0.125rem;
}
.pr-1\.5 {
  padding-right: 0.375rem;
}
.pr-2\.5 {
  padding-right: 0.625rem;
}
.pr-3\.5 {
  padding-right: 0.875rem;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-1 {
  padding-bottom: 0.25rem;
}
.pb-2 {
  padding-bottom: 0.5rem;
}
.pb-3 {
  padding-bottom: 0.75rem;
}
.pb-4 {
  padding-bottom: 1rem;
}
.pb-5 {
  padding-bottom: 1.25rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.pb-7 {
  padding-bottom: 1.75rem;
}
.pb-8 {
  padding-bottom: 2rem;
}
.pb-9 {
  padding-bottom: 2.25rem;
}
.pb-10 {
  padding-bottom: 2.5rem;
}
.pb-11 {
  padding-bottom: 2.75rem;
}
.pb-12 {
  padding-bottom: 3rem;
}
.pb-14 {
  padding-bottom: 3.5rem;
}
.pb-16 {
  padding-bottom: 4rem;
}
.pb-20 {
  padding-bottom: 5rem;
}
.pb-24 {
  padding-bottom: 6rem;
}
.pb-28 {
  padding-bottom: 7rem;
}
.pb-32 {
  padding-bottom: 8rem;
}
.pb-36 {
  padding-bottom: 9rem;
}
.pb-40 {
  padding-bottom: 10rem;
}
.pb-44 {
  padding-bottom: 11rem;
}
.pb-48 {
  padding-bottom: 12rem;
}
.pb-52 {
  padding-bottom: 13rem;
}
.pb-56 {
  padding-bottom: 14rem;
}
.pb-60 {
  padding-bottom: 15rem;
}
.pb-64 {
  padding-bottom: 16rem;
}
.pb-72 {
  padding-bottom: 18rem;
}
.pb-80 {
  padding-bottom: 20rem;
}
.pb-96 {
  padding-bottom: 24rem;
}
.pb-px {
  padding-bottom: 1px;
}
.pb-0\.5 {
  padding-bottom: 0.125rem;
}
.pb-1\.5 {
  padding-bottom: 0.375rem;
}
.pb-2\.5 {
  padding-bottom: 0.625rem;
}
.pb-3\.5 {
  padding-bottom: 0.875rem;
}
.pl-0 {
  padding-left: 0;
}
.pl-1 {
  padding-left: 0.25rem;
}
.pl-2 {
  padding-left: 0.5rem;
}
.pl-3 {
  padding-left: 0.75rem;
}
.pl-4 {
  padding-left: 1rem;
}
.pl-5 {
  padding-left: 1.25rem;
}
.pl-6 {
  padding-left: 1.5rem;
}
.pl-7 {
  padding-left: 1.75rem;
}
.pl-8 {
  padding-left: 2rem;
}
.pl-9 {
  padding-left: 2.25rem;
}
.pl-10 {
  padding-left: 2.5rem;
}
.pl-11 {
  padding-left: 2.75rem;
}
.pl-12 {
  padding-left: 3rem;
}
.pl-14 {
  padding-left: 3.5rem;
}
.pl-16 {
  padding-left: 4rem;
}
.pl-20 {
  padding-left: 5rem;
}
.pl-24 {
  padding-left: 6rem;
}
.pl-28 {
  padding-left: 7rem;
}
.pl-32 {
  padding-left: 8rem;
}
.pl-36 {
  padding-left: 9rem;
}
.pl-40 {
  padding-left: 10rem;
}
.pl-44 {
  padding-left: 11rem;
}
.pl-48 {
  padding-left: 12rem;
}
.pl-52 {
  padding-left: 13rem;
}
.pl-56 {
  padding-left: 14rem;
}
.pl-60 {
  padding-left: 15rem;
}
.pl-64 {
  padding-left: 16rem;
}
.pl-72 {
  padding-left: 18rem;
}
.pl-80 {
  padding-left: 20rem;
}
.pl-96 {
  padding-left: 24rem;
}
.pl-px {
  padding-left: 1px;
}
.pl-0\.5 {
  padding-left: 0.125rem;
}
.pl-1\.5 {
  padding-left: 0.375rem;
}
.pl-2\.5 {
  padding-left: 0.625rem;
}
.pl-3\.5 {
  padding-left: 0.875rem;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.align-baseline {
  vertical-align: baseline;
}
.align-top {
  vertical-align: top;
}
.align-middle {
  vertical-align: middle;
}
.align-bottom {
  vertical-align: bottom;
}
.align-text-top {
  vertical-align: text-top;
}
.align-text-bottom {
  vertical-align: text-bottom;
}
.font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.font-serif {
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
}
.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}
.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}
.text-8xl {
  font-size: 6rem;
  line-height: 1;
}
.text-9xl {
  font-size: 8rem;
  line-height: 1;
}
.font-thin {
  font-weight: 100;
}
.font-extralight {
  font-weight: 200;
}
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-extrabold {
  font-weight: 800;
}
.font-black {
  font-weight: 900;
}
.uppercase {
  text-transform: uppercase;
}
.lowercase {
  text-transform: lowercase;
}
.capitalize {
  text-transform: capitalize;
}
.normal-case {
  text-transform: none;
}
.italic {
  font-style: italic;
}
.not-italic {
  font-style: normal;
}
.diagonal-fractions,
.lining-nums,
.oldstyle-nums,
.ordinal,
.proportional-nums,
.slashed-zero,
.stacked-fractions,
.tabular-nums {
  --tw-ordinal: var(--tw-empty); /*!*/ /*!*/
  --tw-slashed-zero: var(--tw-empty); /*!*/ /*!*/
  --tw-numeric-figure: var(--tw-empty); /*!*/ /*!*/
  --tw-numeric-spacing: var(--tw-empty); /*!*/ /*!*/
  --tw-numeric-fraction: var(--tw-empty); /*!*/ /*!*/
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
    var(--tw-numeric-figure) var(--tw-numeric-spacing)
    var(--tw-numeric-fraction);
}
.normal-nums {
  font-variant-numeric: normal;
}
.ordinal {
  --tw-ordinal: ordinal;
}
.slashed-zero {
  --tw-slashed-zero: slashed-zero;
}
.lining-nums {
  --tw-numeric-figure: lining-nums;
}
.oldstyle-nums {
  --tw-numeric-figure: oldstyle-nums;
}
.proportional-nums {
  --tw-numeric-spacing: proportional-nums;
}
.tabular-nums {
  --tw-numeric-spacing: tabular-nums;
}
.diagonal-fractions {
  --tw-numeric-fraction: diagonal-fractions;
}
.stacked-fractions {
  --tw-numeric-fraction: stacked-fractions;
}
.leading-3 {
  line-height: 0.75rem;
}
.leading-4 {
  line-height: 1rem;
}
.leading-5 {
  line-height: 1.25rem;
}
.leading-6 {
  line-height: 1.5rem;
}
.leading-7 {
  line-height: 1.75rem;
}
.leading-8 {
  line-height: 2rem;
}
.leading-9 {
  line-height: 2.25rem;
}
.leading-10 {
  line-height: 2.5rem;
}
.leading-none {
  line-height: 1;
}
.leading-tight {
  line-height: 1.25;
}
.leading-snug {
  line-height: 1.375;
}
.leading-normal {
  line-height: 1.5;
}
.leading-relaxed {
  line-height: 1.625;
}
.leading-loose {
  line-height: 2;
}
.tracking-tighter {
  letter-spacing: -0.05em;
}
.tracking-tight {
  letter-spacing: -0.025em;
}
.tracking-normal {
  letter-spacing: 0;
}
.tracking-wide {
  letter-spacing: 0.025em;
}
.tracking-wider {
  letter-spacing: 0.05em;
}
.tracking-widest {
  letter-spacing: 0.1em;
}
.text-transparent {
  color: transparent;
}
.text-current {
  color: currentColor;
}
.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.text-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.text-red-50 {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}
.text-red-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}
.text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}
.text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}
.text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}
.text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}
.text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}
.text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}
.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}
.text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}
.text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}
.text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}
.text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}
.text-green-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}
.text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}
.text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}
.text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}
.text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}
.text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}
.text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}
.text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}
.text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}
.text-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}
.text-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}
.text-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}
.text-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}
.text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}
.text-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}
.text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}
.text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}
.text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}
.text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}
.text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}
.text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
.text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}
.text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}
.text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}
.text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}
.text-purple-50 {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}
.text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}
.text-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}
.text-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}
.text-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}
.text-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}
.text-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}
.text-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}
.text-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}
.text-purple-900 {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}
.text-pink-50 {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}
.text-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}
.text-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}
.text-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}
.text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}
.text-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}
.text-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}
.text-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}
.text-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}
.text-pink-900 {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-transparent {
  color: transparent;
}
.group:hover .group-hover\:text-current {
  color: currentColor;
}
.group:hover .group-hover\:text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-50 {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-100 {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-200 {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-300 {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-400 {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-500 {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-600 {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-700 {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-800 {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-gray-900 {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-50 {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-200 {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-400 {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-600 {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-700 {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-800 {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-red-900 {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-50 {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-100 {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-200 {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-300 {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-600 {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-700 {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-50 {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-100 {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-200 {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-300 {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-400 {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-600 {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-700 {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-800 {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-green-900 {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-50 {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-100 {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-200 {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-300 {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-400 {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-500 {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-600 {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-700 {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-blue-900 {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-50 {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-100 {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-200 {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-300 {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-400 {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-500 {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-600 {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-700 {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-800 {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-indigo-900 {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-50 {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-100 {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-200 {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-300 {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-400 {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-500 {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-600 {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-700 {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-800 {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-purple-900 {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-50 {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-100 {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-200 {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-300 {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-500 {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-600 {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-700 {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-800 {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}
.group:hover .group-hover\:text-pink-900 {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}
.focus-within\:text-transparent:focus-within {
  color: transparent;
}
.focus-within\:text-current:focus-within {
  color: currentColor;
}
.focus-within\:text-black:focus-within {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.focus-within\:text-white:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.focus-within\:text-gray-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.focus-within\:text-gray-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.focus-within\:text-gray-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.focus-within\:text-gray-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.focus-within\:text-gray-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.focus-within\:text-gray-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.focus-within\:text-gray-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.focus-within\:text-gray-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.focus-within\:text-gray-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.focus-within\:text-gray-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.focus-within\:text-red-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}
.focus-within\:text-red-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}
.focus-within\:text-red-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.focus-within\:text-red-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.focus-within\:text-red-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.focus-within\:text-red-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.focus-within\:text-red-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.focus-within\:text-red-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.focus-within\:text-red-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}
.focus-within\:text-red-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}
.focus-within\:text-yellow-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}
.focus-within\:text-yellow-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}
.focus-within\:text-yellow-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}
.focus-within\:text-yellow-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}
.focus-within\:text-yellow-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}
.focus-within\:text-yellow-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.focus-within\:text-yellow-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}
.focus-within\:text-yellow-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}
.focus-within\:text-yellow-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}
.focus-within\:text-yellow-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}
.focus-within\:text-green-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}
.focus-within\:text-green-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}
.focus-within\:text-green-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}
.focus-within\:text-green-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}
.focus-within\:text-green-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}
.focus-within\:text-green-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.focus-within\:text-green-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}
.focus-within\:text-green-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}
.focus-within\:text-green-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}
.focus-within\:text-green-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}
.focus-within\:text-blue-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}
.focus-within\:text-blue-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}
.focus-within\:text-blue-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}
.focus-within\:text-blue-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}
.focus-within\:text-blue-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.focus-within\:text-blue-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.focus-within\:text-blue-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.focus-within\:text-blue-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.focus-within\:text-blue-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}
.focus-within\:text-blue-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}
.focus-within\:text-indigo-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}
.focus-within\:text-indigo-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}
.focus-within\:text-indigo-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}
.focus-within\:text-indigo-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}
.focus-within\:text-indigo-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}
.focus-within\:text-indigo-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
.focus-within\:text-indigo-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}
.focus-within\:text-indigo-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}
.focus-within\:text-indigo-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}
.focus-within\:text-indigo-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}
.focus-within\:text-purple-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}
.focus-within\:text-purple-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}
.focus-within\:text-purple-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}
.focus-within\:text-purple-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}
.focus-within\:text-purple-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}
.focus-within\:text-purple-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}
.focus-within\:text-purple-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}
.focus-within\:text-purple-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}
.focus-within\:text-purple-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}
.focus-within\:text-purple-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}
.focus-within\:text-pink-50:focus-within {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}
.focus-within\:text-pink-100:focus-within {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}
.focus-within\:text-pink-200:focus-within {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}
.focus-within\:text-pink-300:focus-within {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}
.focus-within\:text-pink-400:focus-within {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}
.focus-within\:text-pink-500:focus-within {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}
.focus-within\:text-pink-600:focus-within {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}
.focus-within\:text-pink-700:focus-within {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}
.focus-within\:text-pink-800:focus-within {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}
.focus-within\:text-pink-900:focus-within {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}
.hover\:text-transparent:hover {
  color: transparent;
}
.hover\:text-current:hover {
  color: currentColor;
}
.hover\:text-black:hover {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.hover\:text-gray-50:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.hover\:text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.hover\:text-gray-200:hover {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.hover\:text-gray-300:hover {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.hover\:text-gray-400:hover {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.hover\:text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.hover\:text-gray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.hover\:text-gray-800:hover {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.hover\:text-gray-900:hover {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.hover\:text-red-50:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}
.hover\:text-red-100:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}
.hover\:text-red-200:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.hover\:text-red-300:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.hover\:text-red-400:hover {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.hover\:text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.hover\:text-red-600:hover {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.hover\:text-red-700:hover {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.hover\:text-red-800:hover {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}
.hover\:text-red-900:hover {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}
.hover\:text-yellow-50:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}
.hover\:text-yellow-100:hover {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}
.hover\:text-yellow-200:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}
.hover\:text-yellow-300:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}
.hover\:text-yellow-400:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}
.hover\:text-yellow-500:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.hover\:text-yellow-600:hover {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}
.hover\:text-yellow-700:hover {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}
.hover\:text-yellow-800:hover {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}
.hover\:text-yellow-900:hover {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}
.hover\:text-green-50:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}
.hover\:text-green-100:hover {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}
.hover\:text-green-200:hover {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}
.hover\:text-green-300:hover {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}
.hover\:text-green-400:hover {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}
.hover\:text-green-500:hover {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.hover\:text-green-600:hover {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}
.hover\:text-green-700:hover {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}
.hover\:text-green-800:hover {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}
.hover\:text-green-900:hover {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}
.hover\:text-blue-50:hover {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}
.hover\:text-blue-100:hover {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}
.hover\:text-blue-200:hover {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}
.hover\:text-blue-300:hover {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}
.hover\:text-blue-400:hover {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.hover\:text-blue-700:hover {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.hover\:text-blue-800:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}
.hover\:text-blue-900:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}
.hover\:text-indigo-50:hover {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}
.hover\:text-indigo-100:hover {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}
.hover\:text-indigo-200:hover {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}
.hover\:text-indigo-300:hover {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}
.hover\:text-indigo-400:hover {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}
.hover\:text-indigo-500:hover {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
.hover\:text-indigo-600:hover {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}
.hover\:text-indigo-700:hover {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}
.hover\:text-indigo-800:hover {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}
.hover\:text-indigo-900:hover {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}
.hover\:text-purple-50:hover {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}
.hover\:text-purple-100:hover {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}
.hover\:text-purple-200:hover {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}
.hover\:text-purple-300:hover {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}
.hover\:text-purple-400:hover {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}
.hover\:text-purple-500:hover {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}
.hover\:text-purple-600:hover {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}
.hover\:text-purple-700:hover {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}
.hover\:text-purple-800:hover {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}
.hover\:text-purple-900:hover {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}
.hover\:text-pink-50:hover {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}
.hover\:text-pink-100:hover {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}
.hover\:text-pink-200:hover {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}
.hover\:text-pink-300:hover {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}
.hover\:text-pink-400:hover {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}
.hover\:text-pink-500:hover {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}
.hover\:text-pink-600:hover {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}
.hover\:text-pink-700:hover {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}
.hover\:text-pink-800:hover {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}
.hover\:text-pink-900:hover {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}
.focus\:text-transparent:focus {
  color: transparent;
}
.focus\:text-current:focus {
  color: currentColor;
}
.focus\:text-black:focus {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}
.focus\:text-white:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.focus\:text-gray-50:focus {
  --tw-text-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-text-opacity));
}
.focus\:text-gray-100:focus {
  --tw-text-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-text-opacity));
}
.focus\:text-gray-200:focus {
  --tw-text-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-text-opacity));
}
.focus\:text-gray-300:focus {
  --tw-text-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-text-opacity));
}
.focus\:text-gray-400:focus {
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.focus\:text-gray-500:focus {
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
}
.focus\:text-gray-600:focus {
  --tw-text-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-text-opacity));
}
.focus\:text-gray-700:focus {
  --tw-text-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
}
.focus\:text-gray-800:focus {
  --tw-text-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-text-opacity));
}
.focus\:text-gray-900:focus {
  --tw-text-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-text-opacity));
}
.focus\:text-red-50:focus {
  --tw-text-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-text-opacity));
}
.focus\:text-red-100:focus {
  --tw-text-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-text-opacity));
}
.focus\:text-red-200:focus {
  --tw-text-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-text-opacity));
}
.focus\:text-red-300:focus {
  --tw-text-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-text-opacity));
}
.focus\:text-red-400:focus {
  --tw-text-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-text-opacity));
}
.focus\:text-red-500:focus {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}
.focus\:text-red-600:focus {
  --tw-text-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-text-opacity));
}
.focus\:text-red-700:focus {
  --tw-text-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-text-opacity));
}
.focus\:text-red-800:focus {
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}
.focus\:text-red-900:focus {
  --tw-text-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-text-opacity));
}
.focus\:text-yellow-50:focus {
  --tw-text-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-text-opacity));
}
.focus\:text-yellow-100:focus {
  --tw-text-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-text-opacity));
}
.focus\:text-yellow-200:focus {
  --tw-text-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-text-opacity));
}
.focus\:text-yellow-300:focus {
  --tw-text-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-text-opacity));
}
.focus\:text-yellow-400:focus {
  --tw-text-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-text-opacity));
}
.focus\:text-yellow-500:focus {
  --tw-text-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-text-opacity));
}
.focus\:text-yellow-600:focus {
  --tw-text-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-text-opacity));
}
.focus\:text-yellow-700:focus {
  --tw-text-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-text-opacity));
}
.focus\:text-yellow-800:focus {
  --tw-text-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-text-opacity));
}
.focus\:text-yellow-900:focus {
  --tw-text-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-text-opacity));
}
.focus\:text-green-50:focus {
  --tw-text-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-text-opacity));
}
.focus\:text-green-100:focus {
  --tw-text-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-text-opacity));
}
.focus\:text-green-200:focus {
  --tw-text-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-text-opacity));
}
.focus\:text-green-300:focus {
  --tw-text-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-text-opacity));
}
.focus\:text-green-400:focus {
  --tw-text-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-text-opacity));
}
.focus\:text-green-500:focus {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}
.focus\:text-green-600:focus {
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
}
.focus\:text-green-700:focus {
  --tw-text-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-text-opacity));
}
.focus\:text-green-800:focus {
  --tw-text-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-text-opacity));
}
.focus\:text-green-900:focus {
  --tw-text-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-text-opacity));
}
.focus\:text-blue-50:focus {
  --tw-text-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-text-opacity));
}
.focus\:text-blue-100:focus {
  --tw-text-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-text-opacity));
}
.focus\:text-blue-200:focus {
  --tw-text-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-text-opacity));
}
.focus\:text-blue-300:focus {
  --tw-text-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-text-opacity));
}
.focus\:text-blue-400:focus {
  --tw-text-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-text-opacity));
}
.focus\:text-blue-500:focus {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
}
.focus\:text-blue-600:focus {
  --tw-text-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-text-opacity));
}
.focus\:text-blue-700:focus {
  --tw-text-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-text-opacity));
}
.focus\:text-blue-800:focus {
  --tw-text-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-text-opacity));
}
.focus\:text-blue-900:focus {
  --tw-text-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-text-opacity));
}
.focus\:text-indigo-50:focus {
  --tw-text-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-text-opacity));
}
.focus\:text-indigo-100:focus {
  --tw-text-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-text-opacity));
}
.focus\:text-indigo-200:focus {
  --tw-text-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-text-opacity));
}
.focus\:text-indigo-300:focus {
  --tw-text-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-text-opacity));
}
.focus\:text-indigo-400:focus {
  --tw-text-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-text-opacity));
}
.focus\:text-indigo-500:focus {
  --tw-text-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-text-opacity));
}
.focus\:text-indigo-600:focus {
  --tw-text-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-text-opacity));
}
.focus\:text-indigo-700:focus {
  --tw-text-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-text-opacity));
}
.focus\:text-indigo-800:focus {
  --tw-text-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-text-opacity));
}
.focus\:text-indigo-900:focus {
  --tw-text-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-text-opacity));
}
.focus\:text-purple-50:focus {
  --tw-text-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-text-opacity));
}
.focus\:text-purple-100:focus {
  --tw-text-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-text-opacity));
}
.focus\:text-purple-200:focus {
  --tw-text-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-text-opacity));
}
.focus\:text-purple-300:focus {
  --tw-text-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-text-opacity));
}
.focus\:text-purple-400:focus {
  --tw-text-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-text-opacity));
}
.focus\:text-purple-500:focus {
  --tw-text-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-text-opacity));
}
.focus\:text-purple-600:focus {
  --tw-text-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-text-opacity));
}
.focus\:text-purple-700:focus {
  --tw-text-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-text-opacity));
}
.focus\:text-purple-800:focus {
  --tw-text-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-text-opacity));
}
.focus\:text-purple-900:focus {
  --tw-text-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-text-opacity));
}
.focus\:text-pink-50:focus {
  --tw-text-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-text-opacity));
}
.focus\:text-pink-100:focus {
  --tw-text-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-text-opacity));
}
.focus\:text-pink-200:focus {
  --tw-text-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-text-opacity));
}
.focus\:text-pink-300:focus {
  --tw-text-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-text-opacity));
}
.focus\:text-pink-400:focus {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}
.focus\:text-pink-500:focus {
  --tw-text-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-text-opacity));
}
.focus\:text-pink-600:focus {
  --tw-text-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-text-opacity));
}
.focus\:text-pink-700:focus {
  --tw-text-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-text-opacity));
}
.focus\:text-pink-800:focus {
  --tw-text-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-text-opacity));
}
.focus\:text-pink-900:focus {
  --tw-text-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-text-opacity));
}
.text-opacity-0 {
  --tw-text-opacity: 0;
}
.text-opacity-5 {
  --tw-text-opacity: 0.05;
}
.text-opacity-10 {
  --tw-text-opacity: 0.1;
}
.text-opacity-20 {
  --tw-text-opacity: 0.2;
}
.text-opacity-25 {
  --tw-text-opacity: 0.25;
}
.text-opacity-30 {
  --tw-text-opacity: 0.3;
}
.text-opacity-40 {
  --tw-text-opacity: 0.4;
}
.text-opacity-50 {
  --tw-text-opacity: 0.5;
}
.text-opacity-60 {
  --tw-text-opacity: 0.6;
}
.text-opacity-70 {
  --tw-text-opacity: 0.7;
}
.text-opacity-75 {
  --tw-text-opacity: 0.75;
}
.text-opacity-80 {
  --tw-text-opacity: 0.8;
}
.text-opacity-90 {
  --tw-text-opacity: 0.9;
}
.text-opacity-95 {
  --tw-text-opacity: 0.95;
}
.text-opacity-100 {
  --tw-text-opacity: 1;
}
.group:hover .group-hover\:text-opacity-0 {
  --tw-text-opacity: 0;
}
.group:hover .group-hover\:text-opacity-5 {
  --tw-text-opacity: 0.05;
}
.group:hover .group-hover\:text-opacity-10 {
  --tw-text-opacity: 0.1;
}
.group:hover .group-hover\:text-opacity-20 {
  --tw-text-opacity: 0.2;
}
.group:hover .group-hover\:text-opacity-25 {
  --tw-text-opacity: 0.25;
}
.group:hover .group-hover\:text-opacity-30 {
  --tw-text-opacity: 0.3;
}
.group:hover .group-hover\:text-opacity-40 {
  --tw-text-opacity: 0.4;
}
.group:hover .group-hover\:text-opacity-50 {
  --tw-text-opacity: 0.5;
}
.group:hover .group-hover\:text-opacity-60 {
  --tw-text-opacity: 0.6;
}
.group:hover .group-hover\:text-opacity-70 {
  --tw-text-opacity: 0.7;
}
.group:hover .group-hover\:text-opacity-75 {
  --tw-text-opacity: 0.75;
}
.group:hover .group-hover\:text-opacity-80 {
  --tw-text-opacity: 0.8;
}
.group:hover .group-hover\:text-opacity-90 {
  --tw-text-opacity: 0.9;
}
.group:hover .group-hover\:text-opacity-95 {
  --tw-text-opacity: 0.95;
}
.group:hover .group-hover\:text-opacity-100 {
  --tw-text-opacity: 1;
}
.focus-within\:text-opacity-0:focus-within {
  --tw-text-opacity: 0;
}
.focus-within\:text-opacity-5:focus-within {
  --tw-text-opacity: 0.05;
}
.focus-within\:text-opacity-10:focus-within {
  --tw-text-opacity: 0.1;
}
.focus-within\:text-opacity-20:focus-within {
  --tw-text-opacity: 0.2;
}
.focus-within\:text-opacity-25:focus-within {
  --tw-text-opacity: 0.25;
}
.focus-within\:text-opacity-30:focus-within {
  --tw-text-opacity: 0.3;
}
.focus-within\:text-opacity-40:focus-within {
  --tw-text-opacity: 0.4;
}
.focus-within\:text-opacity-50:focus-within {
  --tw-text-opacity: 0.5;
}
.focus-within\:text-opacity-60:focus-within {
  --tw-text-opacity: 0.6;
}
.focus-within\:text-opacity-70:focus-within {
  --tw-text-opacity: 0.7;
}
.focus-within\:text-opacity-75:focus-within {
  --tw-text-opacity: 0.75;
}
.focus-within\:text-opacity-80:focus-within {
  --tw-text-opacity: 0.8;
}
.focus-within\:text-opacity-90:focus-within {
  --tw-text-opacity: 0.9;
}
.focus-within\:text-opacity-95:focus-within {
  --tw-text-opacity: 0.95;
}
.focus-within\:text-opacity-100:focus-within {
  --tw-text-opacity: 1;
}
.hover\:text-opacity-0:hover {
  --tw-text-opacity: 0;
}
.hover\:text-opacity-5:hover {
  --tw-text-opacity: 0.05;
}
.hover\:text-opacity-10:hover {
  --tw-text-opacity: 0.1;
}
.hover\:text-opacity-20:hover {
  --tw-text-opacity: 0.2;
}
.hover\:text-opacity-25:hover {
  --tw-text-opacity: 0.25;
}
.hover\:text-opacity-30:hover {
  --tw-text-opacity: 0.3;
}
.hover\:text-opacity-40:hover {
  --tw-text-opacity: 0.4;
}
.hover\:text-opacity-50:hover {
  --tw-text-opacity: 0.5;
}
.hover\:text-opacity-60:hover {
  --tw-text-opacity: 0.6;
}
.hover\:text-opacity-70:hover {
  --tw-text-opacity: 0.7;
}
.hover\:text-opacity-75:hover {
  --tw-text-opacity: 0.75;
}
.hover\:text-opacity-80:hover {
  --tw-text-opacity: 0.8;
}
.hover\:text-opacity-90:hover {
  --tw-text-opacity: 0.9;
}
.hover\:text-opacity-95:hover {
  --tw-text-opacity: 0.95;
}
.hover\:text-opacity-100:hover {
  --tw-text-opacity: 1;
}
.focus\:text-opacity-0:focus {
  --tw-text-opacity: 0;
}
.focus\:text-opacity-5:focus {
  --tw-text-opacity: 0.05;
}
.focus\:text-opacity-10:focus {
  --tw-text-opacity: 0.1;
}
.focus\:text-opacity-20:focus {
  --tw-text-opacity: 0.2;
}
.focus\:text-opacity-25:focus {
  --tw-text-opacity: 0.25;
}
.focus\:text-opacity-30:focus {
  --tw-text-opacity: 0.3;
}
.focus\:text-opacity-40:focus {
  --tw-text-opacity: 0.4;
}
.focus\:text-opacity-50:focus {
  --tw-text-opacity: 0.5;
}
.focus\:text-opacity-60:focus {
  --tw-text-opacity: 0.6;
}
.focus\:text-opacity-70:focus {
  --tw-text-opacity: 0.7;
}
.focus\:text-opacity-75:focus {
  --tw-text-opacity: 0.75;
}
.focus\:text-opacity-80:focus {
  --tw-text-opacity: 0.8;
}
.focus\:text-opacity-90:focus {
  --tw-text-opacity: 0.9;
}
.focus\:text-opacity-95:focus {
  --tw-text-opacity: 0.95;
}
.focus\:text-opacity-100:focus {
  --tw-text-opacity: 1;
}
.underline {
  text-decoration: underline;
}
.line-through {
  text-decoration: line-through;
}
.no-underline {
  text-decoration: none;
}
.group:hover .group-hover\:underline {
  text-decoration: underline;
}
.group:hover .group-hover\:line-through {
  text-decoration: line-through;
}
.group:hover .group-hover\:no-underline {
  text-decoration: none;
}
.focus-within\:underline:focus-within {
  text-decoration: underline;
}
.focus-within\:line-through:focus-within {
  text-decoration: line-through;
}
.focus-within\:no-underline:focus-within {
  text-decoration: none;
}
.hover\:underline:hover {
  text-decoration: underline;
}
.hover\:line-through:hover {
  text-decoration: line-through;
}
.hover\:no-underline:hover {
  text-decoration: none;
}
.focus\:underline:focus {
  text-decoration: underline;
}
.focus\:line-through:focus {
  text-decoration: line-through;
}
.focus\:no-underline:focus {
  text-decoration: none;
}
.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.subpixel-antialiased {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
}
.placeholder-transparent::placeholder {
  color: transparent;
}
.placeholder-current::placeholder {
  color: currentColor;
}
.placeholder-black::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
}
.placeholder-white::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}
.placeholder-gray-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
}
.placeholder-gray-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
}
.placeholder-gray-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}
.placeholder-gray-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
}
.placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}
.placeholder-gray-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}
.placeholder-gray-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
}
.placeholder-gray-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
}
.placeholder-gray-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
}
.placeholder-red-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
}
.placeholder-red-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
}
.placeholder-red-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
}
.placeholder-red-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}
.placeholder-red-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
}
.placeholder-red-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
}
.placeholder-red-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
}
.placeholder-red-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
}
.placeholder-red-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
}
.placeholder-red-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
}
.placeholder-yellow-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
}
.placeholder-yellow-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
}
.placeholder-yellow-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
}
.placeholder-yellow-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
}
.placeholder-yellow-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
}
.placeholder-yellow-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
}
.placeholder-yellow-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
}
.placeholder-yellow-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
}
.placeholder-yellow-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
}
.placeholder-yellow-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
}
.placeholder-green-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
}
.placeholder-green-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
}
.placeholder-green-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
}
.placeholder-green-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
}
.placeholder-green-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
}
.placeholder-green-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
}
.placeholder-green-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
}
.placeholder-green-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
}
.placeholder-green-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
}
.placeholder-green-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
}
.placeholder-blue-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
}
.placeholder-blue-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
}
.placeholder-blue-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
}
.placeholder-blue-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
}
.placeholder-blue-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
}
.placeholder-blue-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
}
.placeholder-blue-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
}
.placeholder-blue-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
}
.placeholder-blue-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
}
.placeholder-blue-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
}
.placeholder-indigo-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
}
.placeholder-indigo-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
}
.placeholder-indigo-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
}
.placeholder-indigo-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
}
.placeholder-indigo-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
}
.placeholder-indigo-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
}
.placeholder-indigo-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
}
.placeholder-indigo-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
}
.placeholder-indigo-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
}
.placeholder-indigo-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
}
.placeholder-purple-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
}
.placeholder-purple-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
}
.placeholder-purple-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
}
.placeholder-purple-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
}
.placeholder-purple-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
}
.placeholder-purple-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
}
.placeholder-purple-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
}
.placeholder-purple-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
}
.placeholder-purple-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
}
.placeholder-purple-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
}
.placeholder-pink-50::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
}
.placeholder-pink-100::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
}
.placeholder-pink-200::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
}
.placeholder-pink-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
}
.placeholder-pink-400::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
}
.placeholder-pink-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
}
.placeholder-pink-600::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
}
.placeholder-pink-700::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
}
.placeholder-pink-800::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
}
.placeholder-pink-900::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
}
.focus\:placeholder-transparent:focus::placeholder {
  color: transparent;
}
.focus\:placeholder-current:focus::placeholder {
  color: currentColor;
}
.focus\:placeholder-black:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
}
.focus\:placeholder-white:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
}
.focus\:placeholder-gray-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
}
.focus\:placeholder-red-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
}
.focus\:placeholder-yellow-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
}
.focus\:placeholder-green-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
}
.focus\:placeholder-blue-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
}
.focus\:placeholder-indigo-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
}
.focus\:placeholder-purple-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-50:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-200:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-300:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-400:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-500:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-600:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-700:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-800:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
}
.focus\:placeholder-pink-900:focus::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
}
.placeholder-opacity-0::placeholder {
  --tw-placeholder-opacity: 0;
}
.placeholder-opacity-5::placeholder {
  --tw-placeholder-opacity: 0.05;
}
.placeholder-opacity-10::placeholder {
  --tw-placeholder-opacity: 0.1;
}
.placeholder-opacity-20::placeholder {
  --tw-placeholder-opacity: 0.2;
}
.placeholder-opacity-25::placeholder {
  --tw-placeholder-opacity: 0.25;
}
.placeholder-opacity-30::placeholder {
  --tw-placeholder-opacity: 0.3;
}
.placeholder-opacity-40::placeholder {
  --tw-placeholder-opacity: 0.4;
}
.placeholder-opacity-50::placeholder {
  --tw-placeholder-opacity: 0.5;
}
.placeholder-opacity-60::placeholder {
  --tw-placeholder-opacity: 0.6;
}
.placeholder-opacity-70::placeholder {
  --tw-placeholder-opacity: 0.7;
}
.placeholder-opacity-75::placeholder {
  --tw-placeholder-opacity: 0.75;
}
.placeholder-opacity-80::placeholder {
  --tw-placeholder-opacity: 0.8;
}
.placeholder-opacity-90::placeholder {
  --tw-placeholder-opacity: 0.9;
}
.placeholder-opacity-95::placeholder {
  --tw-placeholder-opacity: 0.95;
}
.placeholder-opacity-100::placeholder {
  --tw-placeholder-opacity: 1;
}
.focus\:placeholder-opacity-0:focus::placeholder {
  --tw-placeholder-opacity: 0;
}
.focus\:placeholder-opacity-5:focus::placeholder {
  --tw-placeholder-opacity: 0.05;
}
.focus\:placeholder-opacity-10:focus::placeholder {
  --tw-placeholder-opacity: 0.1;
}
.focus\:placeholder-opacity-20:focus::placeholder {
  --tw-placeholder-opacity: 0.2;
}
.focus\:placeholder-opacity-25:focus::placeholder {
  --tw-placeholder-opacity: 0.25;
}
.focus\:placeholder-opacity-30:focus::placeholder {
  --tw-placeholder-opacity: 0.3;
}
.focus\:placeholder-opacity-40:focus::placeholder {
  --tw-placeholder-opacity: 0.4;
}
.focus\:placeholder-opacity-50:focus::placeholder {
  --tw-placeholder-opacity: 0.5;
}
.focus\:placeholder-opacity-60:focus::placeholder {
  --tw-placeholder-opacity: 0.6;
}
.focus\:placeholder-opacity-70:focus::placeholder {
  --tw-placeholder-opacity: 0.7;
}
.focus\:placeholder-opacity-75:focus::placeholder {
  --tw-placeholder-opacity: 0.75;
}
.focus\:placeholder-opacity-80:focus::placeholder {
  --tw-placeholder-opacity: 0.8;
}
.focus\:placeholder-opacity-90:focus::placeholder {
  --tw-placeholder-opacity: 0.9;
}
.focus\:placeholder-opacity-95:focus::placeholder {
  --tw-placeholder-opacity: 0.95;
}
.focus\:placeholder-opacity-100:focus::placeholder {
  --tw-placeholder-opacity: 1;
}
.opacity-0 {
  opacity: 0;
}
.opacity-5 {
  opacity: 0.05;
}
.opacity-10 {
  opacity: 0.1;
}
.opacity-20 {
  opacity: 0.2;
}
.opacity-25 {
  opacity: 0.25;
}
.opacity-30 {
  opacity: 0.3;
}
.opacity-40 {
  opacity: 0.4;
}
.opacity-50 {
  opacity: 0.5;
}
.opacity-60 {
  opacity: 0.6;
}
.opacity-70 {
  opacity: 0.7;
}
.opacity-75 {
  opacity: 0.75;
}
.opacity-80 {
  opacity: 0.8;
}
.opacity-90 {
  opacity: 0.9;
}
.opacity-95 {
  opacity: 0.95;
}
.opacity-100 {
  opacity: 1;
}
.group:hover .group-hover\:opacity-0 {
  opacity: 0;
}
.group:hover .group-hover\:opacity-5 {
  opacity: 0.05;
}
.group:hover .group-hover\:opacity-10 {
  opacity: 0.1;
}
.group:hover .group-hover\:opacity-20 {
  opacity: 0.2;
}
.group:hover .group-hover\:opacity-25 {
  opacity: 0.25;
}
.group:hover .group-hover\:opacity-30 {
  opacity: 0.3;
}
.group:hover .group-hover\:opacity-40 {
  opacity: 0.4;
}
.group:hover .group-hover\:opacity-50 {
  opacity: 0.5;
}
.group:hover .group-hover\:opacity-60 {
  opacity: 0.6;
}
.group:hover .group-hover\:opacity-70 {
  opacity: 0.7;
}
.group:hover .group-hover\:opacity-75 {
  opacity: 0.75;
}
.group:hover .group-hover\:opacity-80 {
  opacity: 0.8;
}
.group:hover .group-hover\:opacity-90 {
  opacity: 0.9;
}
.group:hover .group-hover\:opacity-95 {
  opacity: 0.95;
}
.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}
.focus-within\:opacity-0:focus-within {
  opacity: 0;
}
.focus-within\:opacity-5:focus-within {
  opacity: 0.05;
}
.focus-within\:opacity-10:focus-within {
  opacity: 0.1;
}
.focus-within\:opacity-20:focus-within {
  opacity: 0.2;
}
.focus-within\:opacity-25:focus-within {
  opacity: 0.25;
}
.focus-within\:opacity-30:focus-within {
  opacity: 0.3;
}
.focus-within\:opacity-40:focus-within {
  opacity: 0.4;
}
.focus-within\:opacity-50:focus-within {
  opacity: 0.5;
}
.focus-within\:opacity-60:focus-within {
  opacity: 0.6;
}
.focus-within\:opacity-70:focus-within {
  opacity: 0.7;
}
.focus-within\:opacity-75:focus-within {
  opacity: 0.75;
}
.focus-within\:opacity-80:focus-within {
  opacity: 0.8;
}
.focus-within\:opacity-90:focus-within {
  opacity: 0.9;
}
.focus-within\:opacity-95:focus-within {
  opacity: 0.95;
}
.focus-within\:opacity-100:focus-within {
  opacity: 1;
}
.hover\:opacity-0:hover {
  opacity: 0;
}
.hover\:opacity-5:hover {
  opacity: 0.05;
}
.hover\:opacity-10:hover {
  opacity: 0.1;
}
.hover\:opacity-20:hover {
  opacity: 0.2;
}
.hover\:opacity-25:hover {
  opacity: 0.25;
}
.hover\:opacity-30:hover {
  opacity: 0.3;
}
.hover\:opacity-40:hover {
  opacity: 0.4;
}
.hover\:opacity-50:hover {
  opacity: 0.5;
}
.hover\:opacity-60:hover {
  opacity: 0.6;
}
.hover\:opacity-70:hover {
  opacity: 0.7;
}
.hover\:opacity-75:hover {
  opacity: 0.75;
}
.hover\:opacity-80:hover {
  opacity: 0.8;
}
.hover\:opacity-90:hover {
  opacity: 0.9;
}
.hover\:opacity-95:hover {
  opacity: 0.95;
}
.hover\:opacity-100:hover {
  opacity: 1;
}
.focus\:opacity-0:focus {
  opacity: 0;
}
.focus\:opacity-5:focus {
  opacity: 0.05;
}
.focus\:opacity-10:focus {
  opacity: 0.1;
}
.focus\:opacity-20:focus {
  opacity: 0.2;
}
.focus\:opacity-25:focus {
  opacity: 0.25;
}
.focus\:opacity-30:focus {
  opacity: 0.3;
}
.focus\:opacity-40:focus {
  opacity: 0.4;
}
.focus\:opacity-50:focus {
  opacity: 0.5;
}
.focus\:opacity-60:focus {
  opacity: 0.6;
}
.focus\:opacity-70:focus {
  opacity: 0.7;
}
.focus\:opacity-75:focus {
  opacity: 0.75;
}
.focus\:opacity-80:focus {
  opacity: 0.8;
}
.focus\:opacity-90:focus {
  opacity: 0.9;
}
.focus\:opacity-95:focus {
  opacity: 0.95;
}
.focus\:opacity-100:focus {
  opacity: 1;
}
.bg-blend-normal {
  background-blend-mode: normal;
}
.bg-blend-multiply {
  background-blend-mode: multiply;
}
.bg-blend-screen {
  background-blend-mode: screen;
}
.bg-blend-overlay {
  background-blend-mode: overlay;
}
.bg-blend-darken {
  background-blend-mode: darken;
}
.bg-blend-lighten {
  background-blend-mode: lighten;
}
.bg-blend-color-dodge {
  background-blend-mode: color-dodge;
}
.bg-blend-color-burn {
  background-blend-mode: color-burn;
}
.bg-blend-hard-light {
  background-blend-mode: hard-light;
}
.bg-blend-soft-light {
  background-blend-mode: soft-light;
}
.bg-blend-difference {
  background-blend-mode: difference;
}
.bg-blend-exclusion {
  background-blend-mode: exclusion;
}
.bg-blend-hue {
  background-blend-mode: hue;
}
.bg-blend-saturation {
  background-blend-mode: saturation;
}
.bg-blend-color {
  background-blend-mode: color;
}
.bg-blend-luminosity {
  background-blend-mode: luminosity;
}
.mix-blend-normal {
  mix-blend-mode: normal;
}
.mix-blend-multiply {
  mix-blend-mode: multiply;
}
.mix-blend-screen {
  mix-blend-mode: screen;
}
.mix-blend-overlay {
  mix-blend-mode: overlay;
}
.mix-blend-darken {
  mix-blend-mode: darken;
}
.mix-blend-lighten {
  mix-blend-mode: lighten;
}
.mix-blend-color-dodge {
  mix-blend-mode: color-dodge;
}
.mix-blend-color-burn {
  mix-blend-mode: color-burn;
}
.mix-blend-hard-light {
  mix-blend-mode: hard-light;
}
.mix-blend-soft-light {
  mix-blend-mode: soft-light;
}
.mix-blend-difference {
  mix-blend-mode: difference;
}
.mix-blend-exclusion {
  mix-blend-mode: exclusion;
}
.mix-blend-hue {
  mix-blend-mode: hue;
}
.mix-blend-saturation {
  mix-blend-mode: saturation;
}
.mix-blend-color {
  mix-blend-mode: color;
}
.mix-blend-luminosity {
  mix-blend-mode: luminosity;
}
*,
::after,
::before {
  --tw-shadow: 0 0 #0000;
}
.shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.group:hover .group-hover\:shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.group:hover .group-hover\:shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.group:hover .group-hover\:shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.group:hover .group-hover\:shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.group:hover .group-hover\:shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.group:hover .group-hover\:shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.group:hover .group-hover\:shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.group:hover .group-hover\:shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus-within\:shadow-sm:focus-within {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus-within\:shadow:focus-within {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus-within\:shadow-md:focus-within {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus-within\:shadow-lg:focus-within {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus-within\:shadow-xl:focus-within {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus-within\:shadow-2xl:focus-within {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus-within\:shadow-inner:focus-within {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus-within\:shadow-none:focus-within {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-sm:hover {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow:hover {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-xl:hover {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-2xl:hover {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-inner:hover {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.hover\:shadow-none:hover {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus\:shadow-sm:focus {
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus\:shadow:focus {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus\:shadow-md:focus {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus\:shadow-lg:focus {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus\:shadow-xl:focus {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus\:shadow-2xl:focus {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus\:shadow-inner:focus {
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.focus\:shadow-none:focus {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.outline-white {
  outline: 2px dotted white;
  outline-offset: 2px;
}
.outline-black {
  outline: 2px dotted black;
  outline-offset: 2px;
}
.focus-within\:outline-none:focus-within {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus-within\:outline-white:focus-within {
  outline: 2px dotted white;
  outline-offset: 2px;
}
.focus-within\:outline-black:focus-within {
  outline: 2px dotted black;
  outline-offset: 2px;
}
.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.focus\:outline-white:focus {
  outline: 2px dotted white;
  outline-offset: 2px;
}
.focus\:outline-black:focus {
  outline: 2px dotted black;
  outline-offset: 2px;
}
*,
::after,
::before {
  --tw-ring-inset: var(--tw-empty); /*!*/ /*!*/
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}
.ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.ring-8 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus-within\:ring-0:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus-within\:ring-1:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus-within\:ring-2:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus-within\:ring-4:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus-within\:ring-8:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus-within\:ring:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-1:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-4:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus\:ring-8:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.ring-inset {
  --tw-ring-inset: inset;
}
.focus-within\:ring-inset:focus-within {
  --tw-ring-inset: inset;
}
.focus\:ring-inset:focus {
  --tw-ring-inset: inset;
}
.ring-transparent {
  --tw-ring-color: transparent;
}
.ring-current {
  --tw-ring-color: currentColor;
}
.ring-black {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}
.ring-white {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}
.ring-gray-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}
.ring-gray-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}
.ring-gray-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}
.ring-gray-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}
.ring-gray-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}
.ring-gray-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}
.ring-gray-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}
.ring-gray-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}
.ring-gray-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}
.ring-gray-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
}
.ring-red-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
}
.ring-red-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
}
.ring-red-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
}
.ring-red-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
}
.ring-red-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
}
.ring-red-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}
.ring-red-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
}
.ring-red-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
}
.ring-red-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
}
.ring-red-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
}
.ring-yellow-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}
.ring-yellow-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}
.ring-yellow-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}
.ring-yellow-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}
.ring-yellow-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}
.ring-yellow-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}
.ring-yellow-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}
.ring-yellow-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}
.ring-yellow-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}
.ring-yellow-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}
.ring-green-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}
.ring-green-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}
.ring-green-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}
.ring-green-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}
.ring-green-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}
.ring-green-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}
.ring-green-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}
.ring-green-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}
.ring-green-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}
.ring-green-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}
.ring-blue-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}
.ring-blue-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}
.ring-blue-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}
.ring-blue-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}
.ring-blue-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}
.ring-blue-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}
.ring-blue-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}
.ring-blue-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}
.ring-blue-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}
.ring-blue-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}
.ring-indigo-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}
.ring-indigo-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}
.ring-indigo-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}
.ring-indigo-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}
.ring-indigo-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}
.ring-indigo-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}
.ring-indigo-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}
.ring-indigo-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}
.ring-indigo-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}
.ring-indigo-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}
.ring-purple-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}
.ring-purple-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}
.ring-purple-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}
.ring-purple-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}
.ring-purple-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}
.ring-purple-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}
.ring-purple-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}
.ring-purple-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}
.ring-purple-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}
.ring-purple-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}
.ring-pink-50 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}
.ring-pink-100 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}
.ring-pink-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}
.ring-pink-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}
.ring-pink-400 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}
.ring-pink-500 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}
.ring-pink-600 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}
.ring-pink-700 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}
.ring-pink-800 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}
.ring-pink-900 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}
.focus-within\:ring-transparent:focus-within {
  --tw-ring-color: transparent;
}
.focus-within\:ring-current:focus-within {
  --tw-ring-color: currentColor;
}
.focus-within\:ring-black:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}
.focus-within\:ring-white:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}
.focus-within\:ring-gray-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
}
.focus-within\:ring-red-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
}
.focus-within\:ring-red-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
}
.focus-within\:ring-red-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
}
.focus-within\:ring-red-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
}
.focus-within\:ring-red-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
}
.focus-within\:ring-red-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}
.focus-within\:ring-red-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
}
.focus-within\:ring-red-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
}
.focus-within\:ring-red-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
}
.focus-within\:ring-red-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}
.focus-within\:ring-yellow-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}
.focus-within\:ring-green-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}
.focus-within\:ring-green-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}
.focus-within\:ring-green-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}
.focus-within\:ring-green-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}
.focus-within\:ring-green-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}
.focus-within\:ring-green-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}
.focus-within\:ring-green-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}
.focus-within\:ring-green-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}
.focus-within\:ring-green-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}
.focus-within\:ring-green-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}
.focus-within\:ring-blue-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}
.focus-within\:ring-indigo-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}
.focus-within\:ring-purple-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-50:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-100:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-200:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-300:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-400:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-500:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-600:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-700:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-800:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}
.focus-within\:ring-pink-900:focus-within {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}
.focus\:ring-transparent:focus {
  --tw-ring-color: transparent;
}
.focus\:ring-current:focus {
  --tw-ring-color: currentColor;
}
.focus\:ring-black:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
}
.focus\:ring-white:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
}
.focus\:ring-gray-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
}
.focus\:ring-gray-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
}
.focus\:ring-gray-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
}
.focus\:ring-gray-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
}
.focus\:ring-gray-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
}
.focus\:ring-gray-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
}
.focus\:ring-gray-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
}
.focus\:ring-gray-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
}
.focus\:ring-gray-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
}
.focus\:ring-gray-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
}
.focus\:ring-red-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
}
.focus\:ring-red-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
}
.focus\:ring-red-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
}
.focus\:ring-red-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
}
.focus\:ring-red-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
}
.focus\:ring-red-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
}
.focus\:ring-red-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
}
.focus\:ring-red-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
}
.focus\:ring-red-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
}
.focus\:ring-red-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
}
.focus\:ring-yellow-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
}
.focus\:ring-yellow-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
}
.focus\:ring-yellow-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
}
.focus\:ring-yellow-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
}
.focus\:ring-yellow-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
}
.focus\:ring-yellow-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
}
.focus\:ring-yellow-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
}
.focus\:ring-yellow-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
}
.focus\:ring-yellow-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
}
.focus\:ring-yellow-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
}
.focus\:ring-green-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
}
.focus\:ring-green-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
}
.focus\:ring-green-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
}
.focus\:ring-green-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
}
.focus\:ring-green-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
}
.focus\:ring-green-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
}
.focus\:ring-green-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
}
.focus\:ring-green-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
}
.focus\:ring-green-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
}
.focus\:ring-green-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
}
.focus\:ring-blue-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
}
.focus\:ring-blue-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
}
.focus\:ring-blue-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
}
.focus\:ring-blue-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
}
.focus\:ring-blue-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
}
.focus\:ring-blue-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
}
.focus\:ring-blue-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
}
.focus\:ring-blue-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
}
.focus\:ring-blue-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
}
.focus\:ring-blue-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
}
.focus\:ring-indigo-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
}
.focus\:ring-indigo-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
}
.focus\:ring-indigo-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
}
.focus\:ring-indigo-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
}
.focus\:ring-indigo-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
}
.focus\:ring-indigo-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
}
.focus\:ring-indigo-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
}
.focus\:ring-indigo-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
}
.focus\:ring-indigo-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
}
.focus\:ring-indigo-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
}
.focus\:ring-purple-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
}
.focus\:ring-purple-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
}
.focus\:ring-purple-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
}
.focus\:ring-purple-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
}
.focus\:ring-purple-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
}
.focus\:ring-purple-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
}
.focus\:ring-purple-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
}
.focus\:ring-purple-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
}
.focus\:ring-purple-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
}
.focus\:ring-purple-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
}
.focus\:ring-pink-50:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
}
.focus\:ring-pink-100:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
}
.focus\:ring-pink-200:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
}
.focus\:ring-pink-300:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
}
.focus\:ring-pink-400:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
}
.focus\:ring-pink-500:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
}
.focus\:ring-pink-600:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
}
.focus\:ring-pink-700:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
}
.focus\:ring-pink-800:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
}
.focus\:ring-pink-900:focus {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
}
.ring-opacity-0 {
  --tw-ring-opacity: 0;
}
.ring-opacity-5 {
  --tw-ring-opacity: 0.05;
}
.ring-opacity-10 {
  --tw-ring-opacity: 0.1;
}
.ring-opacity-20 {
  --tw-ring-opacity: 0.2;
}
.ring-opacity-25 {
  --tw-ring-opacity: 0.25;
}
.ring-opacity-30 {
  --tw-ring-opacity: 0.3;
}
.ring-opacity-40 {
  --tw-ring-opacity: 0.4;
}
.ring-opacity-50 {
  --tw-ring-opacity: 0.5;
}
.ring-opacity-60 {
  --tw-ring-opacity: 0.6;
}
.ring-opacity-70 {
  --tw-ring-opacity: 0.7;
}
.ring-opacity-75 {
  --tw-ring-opacity: 0.75;
}
.ring-opacity-80 {
  --tw-ring-opacity: 0.8;
}
.ring-opacity-90 {
  --tw-ring-opacity: 0.9;
}
.ring-opacity-95 {
  --tw-ring-opacity: 0.95;
}
.ring-opacity-100 {
  --tw-ring-opacity: 1;
}
.focus-within\:ring-opacity-0:focus-within {
  --tw-ring-opacity: 0;
}
.focus-within\:ring-opacity-5:focus-within {
  --tw-ring-opacity: 0.05;
}
.focus-within\:ring-opacity-10:focus-within {
  --tw-ring-opacity: 0.1;
}
.focus-within\:ring-opacity-20:focus-within {
  --tw-ring-opacity: 0.2;
}
.focus-within\:ring-opacity-25:focus-within {
  --tw-ring-opacity: 0.25;
}
.focus-within\:ring-opacity-30:focus-within {
  --tw-ring-opacity: 0.3;
}
.focus-within\:ring-opacity-40:focus-within {
  --tw-ring-opacity: 0.4;
}
.focus-within\:ring-opacity-50:focus-within {
  --tw-ring-opacity: 0.5;
}
.focus-within\:ring-opacity-60:focus-within {
  --tw-ring-opacity: 0.6;
}
.focus-within\:ring-opacity-70:focus-within {
  --tw-ring-opacity: 0.7;
}
.focus-within\:ring-opacity-75:focus-within {
  --tw-ring-opacity: 0.75;
}
.focus-within\:ring-opacity-80:focus-within {
  --tw-ring-opacity: 0.8;
}
.focus-within\:ring-opacity-90:focus-within {
  --tw-ring-opacity: 0.9;
}
.focus-within\:ring-opacity-95:focus-within {
  --tw-ring-opacity: 0.95;
}
.focus-within\:ring-opacity-100:focus-within {
  --tw-ring-opacity: 1;
}
.focus\:ring-opacity-0:focus {
  --tw-ring-opacity: 0;
}
.focus\:ring-opacity-5:focus {
  --tw-ring-opacity: 0.05;
}
.focus\:ring-opacity-10:focus {
  --tw-ring-opacity: 0.1;
}
.focus\:ring-opacity-20:focus {
  --tw-ring-opacity: 0.2;
}
.focus\:ring-opacity-25:focus {
  --tw-ring-opacity: 0.25;
}
.focus\:ring-opacity-30:focus {
  --tw-ring-opacity: 0.3;
}
.focus\:ring-opacity-40:focus {
  --tw-ring-opacity: 0.4;
}
.focus\:ring-opacity-50:focus {
  --tw-ring-opacity: 0.5;
}
.focus\:ring-opacity-60:focus {
  --tw-ring-opacity: 0.6;
}
.focus\:ring-opacity-70:focus {
  --tw-ring-opacity: 0.7;
}
.focus\:ring-opacity-75:focus {
  --tw-ring-opacity: 0.75;
}
.focus\:ring-opacity-80:focus {
  --tw-ring-opacity: 0.8;
}
.focus\:ring-opacity-90:focus {
  --tw-ring-opacity: 0.9;
}
.focus\:ring-opacity-95:focus {
  --tw-ring-opacity: 0.95;
}
.focus\:ring-opacity-100:focus {
  --tw-ring-opacity: 1;
}
.ring-offset-0 {
  --tw-ring-offset-width: 0px;
}
.ring-offset-1 {
  --tw-ring-offset-width: 1px;
}
.ring-offset-2 {
  --tw-ring-offset-width: 2px;
}
.ring-offset-4 {
  --tw-ring-offset-width: 4px;
}
.ring-offset-8 {
  --tw-ring-offset-width: 8px;
}
.focus-within\:ring-offset-0:focus-within {
  --tw-ring-offset-width: 0px;
}
.focus-within\:ring-offset-1:focus-within {
  --tw-ring-offset-width: 1px;
}
.focus-within\:ring-offset-2:focus-within {
  --tw-ring-offset-width: 2px;
}
.focus-within\:ring-offset-4:focus-within {
  --tw-ring-offset-width: 4px;
}
.focus-within\:ring-offset-8:focus-within {
  --tw-ring-offset-width: 8px;
}
.focus\:ring-offset-0:focus {
  --tw-ring-offset-width: 0px;
}
.focus\:ring-offset-1:focus {
  --tw-ring-offset-width: 1px;
}
.focus\:ring-offset-2:focus {
  --tw-ring-offset-width: 2px;
}
.focus\:ring-offset-4:focus {
  --tw-ring-offset-width: 4px;
}
.focus\:ring-offset-8:focus {
  --tw-ring-offset-width: 8px;
}
.ring-offset-transparent {
  --tw-ring-offset-color: transparent;
}
.ring-offset-current {
  --tw-ring-offset-color: currentColor;
}
.ring-offset-black {
  --tw-ring-offset-color: #000;
}
.ring-offset-white {
  --tw-ring-offset-color: #fff;
}
.ring-offset-gray-50 {
  --tw-ring-offset-color: #f9fafb;
}
.ring-offset-gray-100 {
  --tw-ring-offset-color: #f3f4f6;
}
.ring-offset-gray-200 {
  --tw-ring-offset-color: #e5e7eb;
}
.ring-offset-gray-300 {
  --tw-ring-offset-color: #d1d5db;
}
.ring-offset-gray-400 {
  --tw-ring-offset-color: #9ca3af;
}
.ring-offset-gray-500 {
  --tw-ring-offset-color: #6b7280;
}
.ring-offset-gray-600 {
  --tw-ring-offset-color: #4b5563;
}
.ring-offset-gray-700 {
  --tw-ring-offset-color: #374151;
}
.ring-offset-gray-800 {
  --tw-ring-offset-color: #1f2937;
}
.ring-offset-gray-900 {
  --tw-ring-offset-color: #111827;
}
.ring-offset-red-50 {
  --tw-ring-offset-color: #fef2f2;
}
.ring-offset-red-100 {
  --tw-ring-offset-color: #fee2e2;
}
.ring-offset-red-200 {
  --tw-ring-offset-color: #fecaca;
}
.ring-offset-red-300 {
  --tw-ring-offset-color: #fca5a5;
}
.ring-offset-red-400 {
  --tw-ring-offset-color: #f87171;
}
.ring-offset-red-500 {
  --tw-ring-offset-color: #ef4444;
}
.ring-offset-red-600 {
  --tw-ring-offset-color: #dc2626;
}
.ring-offset-red-700 {
  --tw-ring-offset-color: #b91c1c;
}
.ring-offset-red-800 {
  --tw-ring-offset-color: #991b1b;
}
.ring-offset-red-900 {
  --tw-ring-offset-color: #7f1d1d;
}
.ring-offset-yellow-50 {
  --tw-ring-offset-color: #fffbeb;
}
.ring-offset-yellow-100 {
  --tw-ring-offset-color: #fef3c7;
}
.ring-offset-yellow-200 {
  --tw-ring-offset-color: #fde68a;
}
.ring-offset-yellow-300 {
  --tw-ring-offset-color: #fcd34d;
}
.ring-offset-yellow-400 {
  --tw-ring-offset-color: #fbbf24;
}
.ring-offset-yellow-500 {
  --tw-ring-offset-color: #f59e0b;
}
.ring-offset-yellow-600 {
  --tw-ring-offset-color: #d97706;
}
.ring-offset-yellow-700 {
  --tw-ring-offset-color: #b45309;
}
.ring-offset-yellow-800 {
  --tw-ring-offset-color: #92400e;
}
.ring-offset-yellow-900 {
  --tw-ring-offset-color: #78350f;
}
.ring-offset-green-50 {
  --tw-ring-offset-color: #ecfdf5;
}
.ring-offset-green-100 {
  --tw-ring-offset-color: #d1fae5;
}
.ring-offset-green-200 {
  --tw-ring-offset-color: #a7f3d0;
}
.ring-offset-green-300 {
  --tw-ring-offset-color: #6ee7b7;
}
.ring-offset-green-400 {
  --tw-ring-offset-color: #34d399;
}
.ring-offset-green-500 {
  --tw-ring-offset-color: #10b981;
}
.ring-offset-green-600 {
  --tw-ring-offset-color: #059669;
}
.ring-offset-green-700 {
  --tw-ring-offset-color: #047857;
}
.ring-offset-green-800 {
  --tw-ring-offset-color: #065f46;
}
.ring-offset-green-900 {
  --tw-ring-offset-color: #064e3b;
}
.ring-offset-blue-50 {
  --tw-ring-offset-color: #eff6ff;
}
.ring-offset-blue-100 {
  --tw-ring-offset-color: #dbeafe;
}
.ring-offset-blue-200 {
  --tw-ring-offset-color: #bfdbfe;
}
.ring-offset-blue-300 {
  --tw-ring-offset-color: #93c5fd;
}
.ring-offset-blue-400 {
  --tw-ring-offset-color: #60a5fa;
}
.ring-offset-blue-500 {
  --tw-ring-offset-color: #3b82f6;
}
.ring-offset-blue-600 {
  --tw-ring-offset-color: #2563eb;
}
.ring-offset-blue-700 {
  --tw-ring-offset-color: #1d4ed8;
}
.ring-offset-blue-800 {
  --tw-ring-offset-color: #1e40af;
}
.ring-offset-blue-900 {
  --tw-ring-offset-color: #1e3a8a;
}
.ring-offset-indigo-50 {
  --tw-ring-offset-color: #eef2ff;
}
.ring-offset-indigo-100 {
  --tw-ring-offset-color: #e0e7ff;
}
.ring-offset-indigo-200 {
  --tw-ring-offset-color: #c7d2fe;
}
.ring-offset-indigo-300 {
  --tw-ring-offset-color: #a5b4fc;
}
.ring-offset-indigo-400 {
  --tw-ring-offset-color: #818cf8;
}
.ring-offset-indigo-500 {
  --tw-ring-offset-color: #6366f1;
}
.ring-offset-indigo-600 {
  --tw-ring-offset-color: #4f46e5;
}
.ring-offset-indigo-700 {
  --tw-ring-offset-color: #4338ca;
}
.ring-offset-indigo-800 {
  --tw-ring-offset-color: #3730a3;
}
.ring-offset-indigo-900 {
  --tw-ring-offset-color: #312e81;
}
.ring-offset-purple-50 {
  --tw-ring-offset-color: #f5f3ff;
}
.ring-offset-purple-100 {
  --tw-ring-offset-color: #ede9fe;
}
.ring-offset-purple-200 {
  --tw-ring-offset-color: #ddd6fe;
}
.ring-offset-purple-300 {
  --tw-ring-offset-color: #c4b5fd;
}
.ring-offset-purple-400 {
  --tw-ring-offset-color: #a78bfa;
}
.ring-offset-purple-500 {
  --tw-ring-offset-color: #8b5cf6;
}
.ring-offset-purple-600 {
  --tw-ring-offset-color: #7c3aed;
}
.ring-offset-purple-700 {
  --tw-ring-offset-color: #6d28d9;
}
.ring-offset-purple-800 {
  --tw-ring-offset-color: #5b21b6;
}
.ring-offset-purple-900 {
  --tw-ring-offset-color: #4c1d95;
}
.ring-offset-pink-50 {
  --tw-ring-offset-color: #fdf2f8;
}
.ring-offset-pink-100 {
  --tw-ring-offset-color: #fce7f3;
}
.ring-offset-pink-200 {
  --tw-ring-offset-color: #fbcfe8;
}
.ring-offset-pink-300 {
  --tw-ring-offset-color: #f9a8d4;
}
.ring-offset-pink-400 {
  --tw-ring-offset-color: #f472b6;
}
.ring-offset-pink-500 {
  --tw-ring-offset-color: #ec4899;
}
.ring-offset-pink-600 {
  --tw-ring-offset-color: #db2777;
}
.ring-offset-pink-700 {
  --tw-ring-offset-color: #be185d;
}
.ring-offset-pink-800 {
  --tw-ring-offset-color: #9d174d;
}
.ring-offset-pink-900 {
  --tw-ring-offset-color: #831843;
}
.focus-within\:ring-offset-transparent:focus-within {
  --tw-ring-offset-color: transparent;
}
.focus-within\:ring-offset-current:focus-within {
  --tw-ring-offset-color: currentColor;
}
.focus-within\:ring-offset-black:focus-within {
  --tw-ring-offset-color: #000;
}
.focus-within\:ring-offset-white:focus-within {
  --tw-ring-offset-color: #fff;
}
.focus-within\:ring-offset-gray-50:focus-within {
  --tw-ring-offset-color: #f9fafb;
}
.focus-within\:ring-offset-gray-100:focus-within {
  --tw-ring-offset-color: #f3f4f6;
}
.focus-within\:ring-offset-gray-200:focus-within {
  --tw-ring-offset-color: #e5e7eb;
}
.focus-within\:ring-offset-gray-300:focus-within {
  --tw-ring-offset-color: #d1d5db;
}
.focus-within\:ring-offset-gray-400:focus-within {
  --tw-ring-offset-color: #9ca3af;
}
.focus-within\:ring-offset-gray-500:focus-within {
  --tw-ring-offset-color: #6b7280;
}
.focus-within\:ring-offset-gray-600:focus-within {
  --tw-ring-offset-color: #4b5563;
}
.focus-within\:ring-offset-gray-700:focus-within {
  --tw-ring-offset-color: #374151;
}
.focus-within\:ring-offset-gray-800:focus-within {
  --tw-ring-offset-color: #1f2937;
}
.focus-within\:ring-offset-gray-900:focus-within {
  --tw-ring-offset-color: #111827;
}
.focus-within\:ring-offset-red-50:focus-within {
  --tw-ring-offset-color: #fef2f2;
}
.focus-within\:ring-offset-red-100:focus-within {
  --tw-ring-offset-color: #fee2e2;
}
.focus-within\:ring-offset-red-200:focus-within {
  --tw-ring-offset-color: #fecaca;
}
.focus-within\:ring-offset-red-300:focus-within {
  --tw-ring-offset-color: #fca5a5;
}
.focus-within\:ring-offset-red-400:focus-within {
  --tw-ring-offset-color: #f87171;
}
.focus-within\:ring-offset-red-500:focus-within {
  --tw-ring-offset-color: #ef4444;
}
.focus-within\:ring-offset-red-600:focus-within {
  --tw-ring-offset-color: #dc2626;
}
.focus-within\:ring-offset-red-700:focus-within {
  --tw-ring-offset-color: #b91c1c;
}
.focus-within\:ring-offset-red-800:focus-within {
  --tw-ring-offset-color: #991b1b;
}
.focus-within\:ring-offset-red-900:focus-within {
  --tw-ring-offset-color: #7f1d1d;
}
.focus-within\:ring-offset-yellow-50:focus-within {
  --tw-ring-offset-color: #fffbeb;
}
.focus-within\:ring-offset-yellow-100:focus-within {
  --tw-ring-offset-color: #fef3c7;
}
.focus-within\:ring-offset-yellow-200:focus-within {
  --tw-ring-offset-color: #fde68a;
}
.focus-within\:ring-offset-yellow-300:focus-within {
  --tw-ring-offset-color: #fcd34d;
}
.focus-within\:ring-offset-yellow-400:focus-within {
  --tw-ring-offset-color: #fbbf24;
}
.focus-within\:ring-offset-yellow-500:focus-within {
  --tw-ring-offset-color: #f59e0b;
}
.focus-within\:ring-offset-yellow-600:focus-within {
  --tw-ring-offset-color: #d97706;
}
.focus-within\:ring-offset-yellow-700:focus-within {
  --tw-ring-offset-color: #b45309;
}
.focus-within\:ring-offset-yellow-800:focus-within {
  --tw-ring-offset-color: #92400e;
}
.focus-within\:ring-offset-yellow-900:focus-within {
  --tw-ring-offset-color: #78350f;
}
.focus-within\:ring-offset-green-50:focus-within {
  --tw-ring-offset-color: #ecfdf5;
}
.focus-within\:ring-offset-green-100:focus-within {
  --tw-ring-offset-color: #d1fae5;
}
.focus-within\:ring-offset-green-200:focus-within {
  --tw-ring-offset-color: #a7f3d0;
}
.focus-within\:ring-offset-green-300:focus-within {
  --tw-ring-offset-color: #6ee7b7;
}
.focus-within\:ring-offset-green-400:focus-within {
  --tw-ring-offset-color: #34d399;
}
.focus-within\:ring-offset-green-500:focus-within {
  --tw-ring-offset-color: #10b981;
}
.focus-within\:ring-offset-green-600:focus-within {
  --tw-ring-offset-color: #059669;
}
.focus-within\:ring-offset-green-700:focus-within {
  --tw-ring-offset-color: #047857;
}
.focus-within\:ring-offset-green-800:focus-within {
  --tw-ring-offset-color: #065f46;
}
.focus-within\:ring-offset-green-900:focus-within {
  --tw-ring-offset-color: #064e3b;
}
.focus-within\:ring-offset-blue-50:focus-within {
  --tw-ring-offset-color: #eff6ff;
}
.focus-within\:ring-offset-blue-100:focus-within {
  --tw-ring-offset-color: #dbeafe;
}
.focus-within\:ring-offset-blue-200:focus-within {
  --tw-ring-offset-color: #bfdbfe;
}
.focus-within\:ring-offset-blue-300:focus-within {
  --tw-ring-offset-color: #93c5fd;
}
.focus-within\:ring-offset-blue-400:focus-within {
  --tw-ring-offset-color: #60a5fa;
}
.focus-within\:ring-offset-blue-500:focus-within {
  --tw-ring-offset-color: #3b82f6;
}
.focus-within\:ring-offset-blue-600:focus-within {
  --tw-ring-offset-color: #2563eb;
}
.focus-within\:ring-offset-blue-700:focus-within {
  --tw-ring-offset-color: #1d4ed8;
}
.focus-within\:ring-offset-blue-800:focus-within {
  --tw-ring-offset-color: #1e40af;
}
.focus-within\:ring-offset-blue-900:focus-within {
  --tw-ring-offset-color: #1e3a8a;
}
.focus-within\:ring-offset-indigo-50:focus-within {
  --tw-ring-offset-color: #eef2ff;
}
.focus-within\:ring-offset-indigo-100:focus-within {
  --tw-ring-offset-color: #e0e7ff;
}
.focus-within\:ring-offset-indigo-200:focus-within {
  --tw-ring-offset-color: #c7d2fe;
}
.focus-within\:ring-offset-indigo-300:focus-within {
  --tw-ring-offset-color: #a5b4fc;
}
.focus-within\:ring-offset-indigo-400:focus-within {
  --tw-ring-offset-color: #818cf8;
}
.focus-within\:ring-offset-indigo-500:focus-within {
  --tw-ring-offset-color: #6366f1;
}
.focus-within\:ring-offset-indigo-600:focus-within {
  --tw-ring-offset-color: #4f46e5;
}
.focus-within\:ring-offset-indigo-700:focus-within {
  --tw-ring-offset-color: #4338ca;
}
.focus-within\:ring-offset-indigo-800:focus-within {
  --tw-ring-offset-color: #3730a3;
}
.focus-within\:ring-offset-indigo-900:focus-within {
  --tw-ring-offset-color: #312e81;
}
.focus-within\:ring-offset-purple-50:focus-within {
  --tw-ring-offset-color: #f5f3ff;
}
.focus-within\:ring-offset-purple-100:focus-within {
  --tw-ring-offset-color: #ede9fe;
}
.focus-within\:ring-offset-purple-200:focus-within {
  --tw-ring-offset-color: #ddd6fe;
}
.focus-within\:ring-offset-purple-300:focus-within {
  --tw-ring-offset-color: #c4b5fd;
}
.focus-within\:ring-offset-purple-400:focus-within {
  --tw-ring-offset-color: #a78bfa;
}
.focus-within\:ring-offset-purple-500:focus-within {
  --tw-ring-offset-color: #8b5cf6;
}
.focus-within\:ring-offset-purple-600:focus-within {
  --tw-ring-offset-color: #7c3aed;
}
.focus-within\:ring-offset-purple-700:focus-within {
  --tw-ring-offset-color: #6d28d9;
}
.focus-within\:ring-offset-purple-800:focus-within {
  --tw-ring-offset-color: #5b21b6;
}
.focus-within\:ring-offset-purple-900:focus-within {
  --tw-ring-offset-color: #4c1d95;
}
.focus-within\:ring-offset-pink-50:focus-within {
  --tw-ring-offset-color: #fdf2f8;
}
.focus-within\:ring-offset-pink-100:focus-within {
  --tw-ring-offset-color: #fce7f3;
}
.focus-within\:ring-offset-pink-200:focus-within {
  --tw-ring-offset-color: #fbcfe8;
}
.focus-within\:ring-offset-pink-300:focus-within {
  --tw-ring-offset-color: #f9a8d4;
}
.focus-within\:ring-offset-pink-400:focus-within {
  --tw-ring-offset-color: #f472b6;
}
.focus-within\:ring-offset-pink-500:focus-within {
  --tw-ring-offset-color: #ec4899;
}
.focus-within\:ring-offset-pink-600:focus-within {
  --tw-ring-offset-color: #db2777;
}
.focus-within\:ring-offset-pink-700:focus-within {
  --tw-ring-offset-color: #be185d;
}
.focus-within\:ring-offset-pink-800:focus-within {
  --tw-ring-offset-color: #9d174d;
}
.focus-within\:ring-offset-pink-900:focus-within {
  --tw-ring-offset-color: #831843;
}
.focus\:ring-offset-transparent:focus {
  --tw-ring-offset-color: transparent;
}
.focus\:ring-offset-current:focus {
  --tw-ring-offset-color: currentColor;
}
.focus\:ring-offset-black:focus {
  --tw-ring-offset-color: #000;
}
.focus\:ring-offset-white:focus {
  --tw-ring-offset-color: #fff;
}
.focus\:ring-offset-gray-50:focus {
  --tw-ring-offset-color: #f9fafb;
}
.focus\:ring-offset-gray-100:focus {
  --tw-ring-offset-color: #f3f4f6;
}
.focus\:ring-offset-gray-200:focus {
  --tw-ring-offset-color: #e5e7eb;
}
.focus\:ring-offset-gray-300:focus {
  --tw-ring-offset-color: #d1d5db;
}
.focus\:ring-offset-gray-400:focus {
  --tw-ring-offset-color: #9ca3af;
}
.focus\:ring-offset-gray-500:focus {
  --tw-ring-offset-color: #6b7280;
}
.focus\:ring-offset-gray-600:focus {
  --tw-ring-offset-color: #4b5563;
}
.focus\:ring-offset-gray-700:focus {
  --tw-ring-offset-color: #374151;
}
.focus\:ring-offset-gray-800:focus {
  --tw-ring-offset-color: #1f2937;
}
.focus\:ring-offset-gray-900:focus {
  --tw-ring-offset-color: #111827;
}
.focus\:ring-offset-red-50:focus {
  --tw-ring-offset-color: #fef2f2;
}
.focus\:ring-offset-red-100:focus {
  --tw-ring-offset-color: #fee2e2;
}
.focus\:ring-offset-red-200:focus {
  --tw-ring-offset-color: #fecaca;
}
.focus\:ring-offset-red-300:focus {
  --tw-ring-offset-color: #fca5a5;
}
.focus\:ring-offset-red-400:focus {
  --tw-ring-offset-color: #f87171;
}
.focus\:ring-offset-red-500:focus {
  --tw-ring-offset-color: #ef4444;
}
.focus\:ring-offset-red-600:focus {
  --tw-ring-offset-color: #dc2626;
}
.focus\:ring-offset-red-700:focus {
  --tw-ring-offset-color: #b91c1c;
}
.focus\:ring-offset-red-800:focus {
  --tw-ring-offset-color: #991b1b;
}
.focus\:ring-offset-red-900:focus {
  --tw-ring-offset-color: #7f1d1d;
}
.focus\:ring-offset-yellow-50:focus {
  --tw-ring-offset-color: #fffbeb;
}
.focus\:ring-offset-yellow-100:focus {
  --tw-ring-offset-color: #fef3c7;
}
.focus\:ring-offset-yellow-200:focus {
  --tw-ring-offset-color: #fde68a;
}
.focus\:ring-offset-yellow-300:focus {
  --tw-ring-offset-color: #fcd34d;
}
.focus\:ring-offset-yellow-400:focus {
  --tw-ring-offset-color: #fbbf24;
}
.focus\:ring-offset-yellow-500:focus {
  --tw-ring-offset-color: #f59e0b;
}
.focus\:ring-offset-yellow-600:focus {
  --tw-ring-offset-color: #d97706;
}
.focus\:ring-offset-yellow-700:focus {
  --tw-ring-offset-color: #b45309;
}
.focus\:ring-offset-yellow-800:focus {
  --tw-ring-offset-color: #92400e;
}
.focus\:ring-offset-yellow-900:focus {
  --tw-ring-offset-color: #78350f;
}
.focus\:ring-offset-green-50:focus {
  --tw-ring-offset-color: #ecfdf5;
}
.focus\:ring-offset-green-100:focus {
  --tw-ring-offset-color: #d1fae5;
}
.focus\:ring-offset-green-200:focus {
  --tw-ring-offset-color: #a7f3d0;
}
.focus\:ring-offset-green-300:focus {
  --tw-ring-offset-color: #6ee7b7;
}
.focus\:ring-offset-green-400:focus {
  --tw-ring-offset-color: #34d399;
}
.focus\:ring-offset-green-500:focus {
  --tw-ring-offset-color: #10b981;
}
.focus\:ring-offset-green-600:focus {
  --tw-ring-offset-color: #059669;
}
.focus\:ring-offset-green-700:focus {
  --tw-ring-offset-color: #047857;
}
.focus\:ring-offset-green-800:focus {
  --tw-ring-offset-color: #065f46;
}
.focus\:ring-offset-green-900:focus {
  --tw-ring-offset-color: #064e3b;
}
.focus\:ring-offset-blue-50:focus {
  --tw-ring-offset-color: #eff6ff;
}
.focus\:ring-offset-blue-100:focus {
  --tw-ring-offset-color: #dbeafe;
}
.focus\:ring-offset-blue-200:focus {
  --tw-ring-offset-color: #bfdbfe;
}
.focus\:ring-offset-blue-300:focus {
  --tw-ring-offset-color: #93c5fd;
}
.focus\:ring-offset-blue-400:focus {
  --tw-ring-offset-color: #60a5fa;
}
.focus\:ring-offset-blue-500:focus {
  --tw-ring-offset-color: #3b82f6;
}
.focus\:ring-offset-blue-600:focus {
  --tw-ring-offset-color: #2563eb;
}
.focus\:ring-offset-blue-700:focus {
  --tw-ring-offset-color: #1d4ed8;
}
.focus\:ring-offset-blue-800:focus {
  --tw-ring-offset-color: #1e40af;
}
.focus\:ring-offset-blue-900:focus {
  --tw-ring-offset-color: #1e3a8a;
}
.focus\:ring-offset-indigo-50:focus {
  --tw-ring-offset-color: #eef2ff;
}
.focus\:ring-offset-indigo-100:focus {
  --tw-ring-offset-color: #e0e7ff;
}
.focus\:ring-offset-indigo-200:focus {
  --tw-ring-offset-color: #c7d2fe;
}
.focus\:ring-offset-indigo-300:focus {
  --tw-ring-offset-color: #a5b4fc;
}
.focus\:ring-offset-indigo-400:focus {
  --tw-ring-offset-color: #818cf8;
}
.focus\:ring-offset-indigo-500:focus {
  --tw-ring-offset-color: #6366f1;
}
.focus\:ring-offset-indigo-600:focus {
  --tw-ring-offset-color: #4f46e5;
}
.focus\:ring-offset-indigo-700:focus {
  --tw-ring-offset-color: #4338ca;
}
.focus\:ring-offset-indigo-800:focus {
  --tw-ring-offset-color: #3730a3;
}
.focus\:ring-offset-indigo-900:focus {
  --tw-ring-offset-color: #312e81;
}
.focus\:ring-offset-purple-50:focus {
  --tw-ring-offset-color: #f5f3ff;
}
.focus\:ring-offset-purple-100:focus {
  --tw-ring-offset-color: #ede9fe;
}
.focus\:ring-offset-purple-200:focus {
  --tw-ring-offset-color: #ddd6fe;
}
.focus\:ring-offset-purple-300:focus {
  --tw-ring-offset-color: #c4b5fd;
}
.focus\:ring-offset-purple-400:focus {
  --tw-ring-offset-color: #a78bfa;
}
.focus\:ring-offset-purple-500:focus {
  --tw-ring-offset-color: #8b5cf6;
}
.focus\:ring-offset-purple-600:focus {
  --tw-ring-offset-color: #7c3aed;
}
.focus\:ring-offset-purple-700:focus {
  --tw-ring-offset-color: #6d28d9;
}
.focus\:ring-offset-purple-800:focus {
  --tw-ring-offset-color: #5b21b6;
}
.focus\:ring-offset-purple-900:focus {
  --tw-ring-offset-color: #4c1d95;
}
.focus\:ring-offset-pink-50:focus {
  --tw-ring-offset-color: #fdf2f8;
}
.focus\:ring-offset-pink-100:focus {
  --tw-ring-offset-color: #fce7f3;
}
.focus\:ring-offset-pink-200:focus {
  --tw-ring-offset-color: #fbcfe8;
}
.focus\:ring-offset-pink-300:focus {
  --tw-ring-offset-color: #f9a8d4;
}
.focus\:ring-offset-pink-400:focus {
  --tw-ring-offset-color: #f472b6;
}
.focus\:ring-offset-pink-500:focus {
  --tw-ring-offset-color: #ec4899;
}
.focus\:ring-offset-pink-600:focus {
  --tw-ring-offset-color: #db2777;
}
.focus\:ring-offset-pink-700:focus {
  --tw-ring-offset-color: #be185d;
}
.focus\:ring-offset-pink-800:focus {
  --tw-ring-offset-color: #9d174d;
}
.focus\:ring-offset-pink-900:focus {
  --tw-ring-offset-color: #831843;
}
.filter {
  --tw-blur: var(--tw-empty); /*!*/ /*!*/
  --tw-brightness: var(--tw-empty); /*!*/ /*!*/
  --tw-contrast: var(--tw-empty); /*!*/ /*!*/
  --tw-grayscale: var(--tw-empty); /*!*/ /*!*/
  --tw-hue-rotate: var(--tw-empty); /*!*/ /*!*/
  --tw-invert: var(--tw-empty); /*!*/ /*!*/
  --tw-saturate: var(--tw-empty); /*!*/ /*!*/
  --tw-sepia: var(--tw-empty); /*!*/ /*!*/
  --tw-drop-shadow: var(--tw-empty); /*!*/ /*!*/
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
    var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate)
    var(--tw-sepia) var(--tw-drop-shadow);
}
.filter-none {
  filter: none;
}
.blur-0 {
  --tw-blur: blur(0);
}
.blur-none {
  --tw-blur: blur(0);
}
.blur-sm {
  --tw-blur: blur(4px);
}
.blur {
  --tw-blur: blur(8px);
}
.blur-md {
  --tw-blur: blur(12px);
}
.blur-lg {
  --tw-blur: blur(16px);
}
.blur-xl {
  --tw-blur: blur(24px);
}
.blur-2xl {
  --tw-blur: blur(40px);
}
.blur-3xl {
  --tw-blur: blur(64px);
}
.brightness-0 {
  --tw-brightness: brightness(0);
}
.brightness-50 {
  --tw-brightness: brightness(0.5);
}
.brightness-75 {
  --tw-brightness: brightness(0.75);
}
.brightness-90 {
  --tw-brightness: brightness(0.9);
}
.brightness-95 {
  --tw-brightness: brightness(0.95);
}
.brightness-100 {
  --tw-brightness: brightness(1);
}
.brightness-105 {
  --tw-brightness: brightness(1.05);
}
.brightness-110 {
  --tw-brightness: brightness(1.1);
}
.brightness-125 {
  --tw-brightness: brightness(1.25);
}
.brightness-150 {
  --tw-brightness: brightness(1.5);
}
.brightness-200 {
  --tw-brightness: brightness(2);
}
.contrast-0 {
  --tw-contrast: contrast(0);
}
.contrast-50 {
  --tw-contrast: contrast(0.5);
}
.contrast-75 {
  --tw-contrast: contrast(0.75);
}
.contrast-100 {
  --tw-contrast: contrast(1);
}
.contrast-125 {
  --tw-contrast: contrast(1.25);
}
.contrast-150 {
  --tw-contrast: contrast(1.5);
}
.contrast-200 {
  --tw-contrast: contrast(2);
}
.drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.05));
}
.drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1))
    drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
}
.drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07))
    drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
}
.drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04))
    drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
}
.drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03))
    drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
}
.drop-shadow-2xl {
  --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
}
.drop-shadow-none {
  --tw-drop-shadow: drop-shadow(0 0 #0000);
}
.grayscale-0 {
  --tw-grayscale: grayscale(0);
}
.grayscale {
  --tw-grayscale: grayscale(100%);
}
.hue-rotate-0 {
  --tw-hue-rotate: hue-rotate(0deg);
}
.hue-rotate-15 {
  --tw-hue-rotate: hue-rotate(15deg);
}
.hue-rotate-30 {
  --tw-hue-rotate: hue-rotate(30deg);
}
.hue-rotate-60 {
  --tw-hue-rotate: hue-rotate(60deg);
}
.hue-rotate-90 {
  --tw-hue-rotate: hue-rotate(90deg);
}
.hue-rotate-180 {
  --tw-hue-rotate: hue-rotate(180deg);
}
.-hue-rotate-180 {
  --tw-hue-rotate: hue-rotate(-180deg);
}
.-hue-rotate-90 {
  --tw-hue-rotate: hue-rotate(-90deg);
}
.-hue-rotate-60 {
  --tw-hue-rotate: hue-rotate(-60deg);
}
.-hue-rotate-30 {
  --tw-hue-rotate: hue-rotate(-30deg);
}
.-hue-rotate-15 {
  --tw-hue-rotate: hue-rotate(-15deg);
}
.invert-0 {
  --tw-invert: invert(0);
}
.invert {
  --tw-invert: invert(100%);
}
.saturate-0 {
  --tw-saturate: saturate(0);
}
.saturate-50 {
  --tw-saturate: saturate(0.5);
}
.saturate-100 {
  --tw-saturate: saturate(1);
}
.saturate-150 {
  --tw-saturate: saturate(1.5);
}
.saturate-200 {
  --tw-saturate: saturate(2);
}
.sepia-0 {
  --tw-sepia: sepia(0);
}
.sepia {
  --tw-sepia: sepia(100%);
}
.backdrop-filter {
  --tw-backdrop-blur: var(--tw-empty); /*!*/ /*!*/
  --tw-backdrop-brightness: var(--tw-empty); /*!*/ /*!*/
  --tw-backdrop-contrast: var(--tw-empty); /*!*/ /*!*/
  --tw-backdrop-grayscale: var(--tw-empty); /*!*/ /*!*/
  --tw-backdrop-hue-rotate: var(--tw-empty); /*!*/ /*!*/
  --tw-backdrop-invert: var(--tw-empty); /*!*/ /*!*/
  --tw-backdrop-opacity: var(--tw-empty); /*!*/ /*!*/
  --tw-backdrop-saturate: var(--tw-empty); /*!*/ /*!*/
  --tw-backdrop-sepia: var(--tw-empty); /*!*/ /*!*/
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
}
.backdrop-filter-none {
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
}
.backdrop-blur-0 {
  --tw-backdrop-blur: blur(0);
}
.backdrop-blur-none {
  --tw-backdrop-blur: blur(0);
}
.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
}
.backdrop-blur {
  --tw-backdrop-blur: blur(8px);
}
.backdrop-blur-md {
  --tw-backdrop-blur: blur(12px);
}
.backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px);
}
.backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px);
}
.backdrop-blur-2xl {
  --tw-backdrop-blur: blur(40px);
}
.backdrop-blur-3xl {
  --tw-backdrop-blur: blur(64px);
}
.backdrop-brightness-0 {
  --tw-backdrop-brightness: brightness(0);
}
.backdrop-brightness-50 {
  --tw-backdrop-brightness: brightness(0.5);
}
.backdrop-brightness-75 {
  --tw-backdrop-brightness: brightness(0.75);
}
.backdrop-brightness-90 {
  --tw-backdrop-brightness: brightness(0.9);
}
.backdrop-brightness-95 {
  --tw-backdrop-brightness: brightness(0.95);
}
.backdrop-brightness-100 {
  --tw-backdrop-brightness: brightness(1);
}
.backdrop-brightness-105 {
  --tw-backdrop-brightness: brightness(1.05);
}
.backdrop-brightness-110 {
  --tw-backdrop-brightness: brightness(1.1);
}
.backdrop-brightness-125 {
  --tw-backdrop-brightness: brightness(1.25);
}
.backdrop-brightness-150 {
  --tw-backdrop-brightness: brightness(1.5);
}
.backdrop-brightness-200 {
  --tw-backdrop-brightness: brightness(2);
}
.backdrop-contrast-0 {
  --tw-backdrop-contrast: contrast(0);
}
.backdrop-contrast-50 {
  --tw-backdrop-contrast: contrast(0.5);
}
.backdrop-contrast-75 {
  --tw-backdrop-contrast: contrast(0.75);
}
.backdrop-contrast-100 {
  --tw-backdrop-contrast: contrast(1);
}
.backdrop-contrast-125 {
  --tw-backdrop-contrast: contrast(1.25);
}
.backdrop-contrast-150 {
  --tw-backdrop-contrast: contrast(1.5);
}
.backdrop-contrast-200 {
  --tw-backdrop-contrast: contrast(2);
}
.backdrop-grayscale-0 {
  --tw-backdrop-grayscale: grayscale(0);
}
.backdrop-grayscale {
  --tw-backdrop-grayscale: grayscale(100%);
}
.backdrop-hue-rotate-0 {
  --tw-backdrop-hue-rotate: hue-rotate(0deg);
}
.backdrop-hue-rotate-15 {
  --tw-backdrop-hue-rotate: hue-rotate(15deg);
}
.backdrop-hue-rotate-30 {
  --tw-backdrop-hue-rotate: hue-rotate(30deg);
}
.backdrop-hue-rotate-60 {
  --tw-backdrop-hue-rotate: hue-rotate(60deg);
}
.backdrop-hue-rotate-90 {
  --tw-backdrop-hue-rotate: hue-rotate(90deg);
}
.backdrop-hue-rotate-180 {
  --tw-backdrop-hue-rotate: hue-rotate(180deg);
}
.-backdrop-hue-rotate-180 {
  --tw-backdrop-hue-rotate: hue-rotate(-180deg);
}
.-backdrop-hue-rotate-90 {
  --tw-backdrop-hue-rotate: hue-rotate(-90deg);
}
.-backdrop-hue-rotate-60 {
  --tw-backdrop-hue-rotate: hue-rotate(-60deg);
}
.-backdrop-hue-rotate-30 {
  --tw-backdrop-hue-rotate: hue-rotate(-30deg);
}
.-backdrop-hue-rotate-15 {
  --tw-backdrop-hue-rotate: hue-rotate(-15deg);
}
.backdrop-invert-0 {
  --tw-backdrop-invert: invert(0);
}
.backdrop-invert {
  --tw-backdrop-invert: invert(100%);
}
.backdrop-opacity-0 {
  --tw-backdrop-opacity: opacity(0);
}
.backdrop-opacity-5 {
  --tw-backdrop-opacity: opacity(0.05);
}
.backdrop-opacity-10 {
  --tw-backdrop-opacity: opacity(0.1);
}
.backdrop-opacity-20 {
  --tw-backdrop-opacity: opacity(0.2);
}
.backdrop-opacity-25 {
  --tw-backdrop-opacity: opacity(0.25);
}
.backdrop-opacity-30 {
  --tw-backdrop-opacity: opacity(0.3);
}
.backdrop-opacity-40 {
  --tw-backdrop-opacity: opacity(0.4);
}
.backdrop-opacity-50 {
  --tw-backdrop-opacity: opacity(0.5);
}
.backdrop-opacity-60 {
  --tw-backdrop-opacity: opacity(0.6);
}
.backdrop-opacity-70 {
  --tw-backdrop-opacity: opacity(0.7);
}
.backdrop-opacity-75 {
  --tw-backdrop-opacity: opacity(0.75);
}
.backdrop-opacity-80 {
  --tw-backdrop-opacity: opacity(0.8);
}
.backdrop-opacity-90 {
  --tw-backdrop-opacity: opacity(0.9);
}
.backdrop-opacity-95 {
  --tw-backdrop-opacity: opacity(0.95);
}
.backdrop-opacity-100 {
  --tw-backdrop-opacity: opacity(1);
}
.backdrop-saturate-0 {
  --tw-backdrop-saturate: saturate(0);
}
.backdrop-saturate-50 {
  --tw-backdrop-saturate: saturate(0.5);
}
.backdrop-saturate-100 {
  --tw-backdrop-saturate: saturate(1);
}
.backdrop-saturate-150 {
  --tw-backdrop-saturate: saturate(1.5);
}
.backdrop-saturate-200 {
  --tw-backdrop-saturate: saturate(2);
}
.backdrop-sepia-0 {
  --tw-backdrop-sepia: sepia(0);
}
.backdrop-sepia {
  --tw-backdrop-sepia: sepia(100%);
}
.transition-none {
  transition-property: none;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter,
    -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-colors {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-shadow {
  transition-property: box-shadow;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.delay-75 {
  transition-delay: 75ms;
}
.delay-100 {
  transition-delay: 0.1s;
}
.delay-150 {
  transition-delay: 150ms;
}
.delay-200 {
  transition-delay: 0.2s;
}
.delay-300 {
  transition-delay: 0.3s;
}
.delay-500 {
  transition-delay: 0.5s;
}
.delay-700 {
  transition-delay: 0.7s;
}
.delay-1000 {
  transition-delay: 1s;
}
.duration-75 {
  transition-duration: 75ms;
}
.duration-100 {
  transition-duration: 0.1s;
}
.duration-150 {
  transition-duration: 150ms;
}
.duration-200 {
  transition-duration: 0.2s;
}
.duration-300 {
  transition-duration: 0.3s;
}
.duration-500 {
  transition-duration: 0.5s;
}
.duration-700 {
  transition-duration: 0.7s;
}
.duration-1000 {
  transition-duration: 1s;
}
.ease-linear {
  transition-timing-function: linear;
}
.ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
}
.ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
@media (min-width: 640px) {
  .sm\:container {
    width: 100%;
  }
  @media (min-width: 640px) {
    .sm\:container {
      max-width: 640px;
    }
  }
  @media (min-width: 768px) {
    .sm\:container {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
    .sm\:container {
      max-width: 1024px;
    }
  }
  @media (min-width: 1280px) {
    .sm\:container {
      max-width: 1280px;
    }
  }
  @media (min-width: 1536px) {
    .sm\:container {
      max-width: 1536px;
    }
  }
  .sm\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .sm\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .sm\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .sm\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .sm\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .sm\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .sm\:pointer-events-none {
    pointer-events: none;
  }
  .sm\:pointer-events-auto {
    pointer-events: auto;
  }
  .sm\:visible {
    visibility: visible;
  }
  .sm\:invisible {
    visibility: hidden;
  }
  .sm\:static {
    position: static;
  }
  .sm\:fixed {
    position: fixed;
  }
  .sm\:absolute {
    position: absolute;
  }
  .sm\:relative {
    position: relative;
  }
  .sm\:sticky {
    position: sticky;
  }
  .sm\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .sm\:inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }
  .sm\:inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }
  .sm\:inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }
  .sm\:inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
  .sm\:inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }
  .sm\:inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }
  .sm\:inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }
  .sm\:inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }
  .sm\:inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }
  .sm\:inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }
  .sm\:inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }
  .sm\:inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }
  .sm\:inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }
  .sm\:inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }
  .sm\:inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }
  .sm\:inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }
  .sm\:inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }
  .sm\:inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }
  .sm\:inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }
  .sm\:inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }
  .sm\:inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }
  .sm\:inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }
  .sm\:inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }
  .sm\:inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }
  .sm\:inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }
  .sm\:inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }
  .sm\:inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }
  .sm\:inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }
  .sm\:inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }
  .sm\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .sm\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }
  .sm\:inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }
  .sm\:inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }
  .sm\:inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }
  .sm\:inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }
  .sm\:-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .sm\:-inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }
  .sm\:-inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }
  .sm\:-inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }
  .sm\:-inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }
  .sm\:-inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }
  .sm\:-inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }
  .sm\:-inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }
  .sm\:-inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }
  .sm\:-inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }
  .sm\:-inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }
  .sm\:-inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }
  .sm\:-inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }
  .sm\:-inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }
  .sm\:-inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }
  .sm\:-inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }
  .sm\:-inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }
  .sm\:-inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }
  .sm\:-inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }
  .sm\:-inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }
  .sm\:-inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }
  .sm\:-inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }
  .sm\:-inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }
  .sm\:-inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }
  .sm\:-inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }
  .sm\:-inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }
  .sm\:-inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }
  .sm\:-inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }
  .sm\:-inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }
  .sm\:-inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }
  .sm\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }
  .sm\:-inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }
  .sm\:-inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }
  .sm\:-inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }
  .sm\:-inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }
  .sm\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .sm\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }
  .sm\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }
  .sm\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }
  .sm\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .sm\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }
  .sm\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }
  .sm\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .sm\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }
  .sm\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }
  .sm\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }
  .sm\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .sm\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }
  .sm\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }
  .sm\:inset-x-0 {
    left: 0;
    right: 0;
  }
  .sm\:inset-x-1 {
    left: 0.25rem;
    right: 0.25rem;
  }
  .sm\:inset-x-2 {
    left: 0.5rem;
    right: 0.5rem;
  }
  .sm\:inset-x-3 {
    left: 0.75rem;
    right: 0.75rem;
  }
  .sm\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }
  .sm\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }
  .sm\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }
  .sm\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }
  .sm\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }
  .sm\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }
  .sm\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }
  .sm\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }
  .sm\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }
  .sm\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }
  .sm\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }
  .sm\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }
  .sm\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }
  .sm\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }
  .sm\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }
  .sm\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }
  .sm\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }
  .sm\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }
  .sm\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }
  .sm\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }
  .sm\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }
  .sm\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }
  .sm\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }
  .sm\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }
  .sm\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }
  .sm\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }
  .sm\:inset-x-auto {
    left: auto;
    right: auto;
  }
  .sm\:inset-x-px {
    left: 1px;
    right: 1px;
  }
  .sm\:inset-x-0\.5 {
    left: 0.125rem;
    right: 0.125rem;
  }
  .sm\:inset-x-1\.5 {
    left: 0.375rem;
    right: 0.375rem;
  }
  .sm\:inset-x-2\.5 {
    left: 0.625rem;
    right: 0.625rem;
  }
  .sm\:inset-x-3\.5 {
    left: 0.875rem;
    right: 0.875rem;
  }
  .sm\:-inset-x-0 {
    left: 0;
    right: 0;
  }
  .sm\:-inset-x-1 {
    left: -0.25rem;
    right: -0.25rem;
  }
  .sm\:-inset-x-2 {
    left: -0.5rem;
    right: -0.5rem;
  }
  .sm\:-inset-x-3 {
    left: -0.75rem;
    right: -0.75rem;
  }
  .sm\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }
  .sm\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }
  .sm\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }
  .sm\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }
  .sm\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }
  .sm\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }
  .sm\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }
  .sm\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }
  .sm\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }
  .sm\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }
  .sm\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }
  .sm\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }
  .sm\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }
  .sm\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }
  .sm\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }
  .sm\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }
  .sm\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }
  .sm\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }
  .sm\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }
  .sm\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }
  .sm\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }
  .sm\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }
  .sm\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }
  .sm\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }
  .sm\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }
  .sm\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }
  .sm\:-inset-x-px {
    left: -1px;
    right: -1px;
  }
  .sm\:-inset-x-0\.5 {
    left: -0.125rem;
    right: -0.125rem;
  }
  .sm\:-inset-x-1\.5 {
    left: -0.375rem;
    right: -0.375rem;
  }
  .sm\:-inset-x-2\.5 {
    left: -0.625rem;
    right: -0.625rem;
  }
  .sm\:-inset-x-3\.5 {
    left: -0.875rem;
    right: -0.875rem;
  }
  .sm\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }
  .sm\:inset-x-1\/3 {
    left: 33.333333%;
    right: 33.333333%;
  }
  .sm\:inset-x-2\/3 {
    left: 66.666667%;
    right: 66.666667%;
  }
  .sm\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }
  .sm\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }
  .sm\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }
  .sm\:inset-x-full {
    left: 100%;
    right: 100%;
  }
  .sm\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }
  .sm\:-inset-x-1\/3 {
    left: -33.333333%;
    right: -33.333333%;
  }
  .sm\:-inset-x-2\/3 {
    left: -66.666667%;
    right: -66.666667%;
  }
  .sm\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }
  .sm\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }
  .sm\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }
  .sm\:-inset-x-full {
    left: -100%;
    right: -100%;
  }
  .sm\:inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .sm\:inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }
  .sm\:inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }
  .sm\:inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }
  .sm\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }
  .sm\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }
  .sm\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  .sm\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }
  .sm\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }
  .sm\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }
  .sm\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }
  .sm\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }
  .sm\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }
  .sm\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }
  .sm\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }
  .sm\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }
  .sm\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }
  .sm\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }
  .sm\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }
  .sm\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }
  .sm\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }
  .sm\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }
  .sm\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }
  .sm\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }
  .sm\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }
  .sm\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }
  .sm\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }
  .sm\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }
  .sm\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }
  .sm\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }
  .sm\:inset-y-auto {
    top: auto;
    bottom: auto;
  }
  .sm\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }
  .sm\:inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }
  .sm\:inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }
  .sm\:inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }
  .sm\:inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }
  .sm\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .sm\:-inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }
  .sm\:-inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }
  .sm\:-inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }
  .sm\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }
  .sm\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }
  .sm\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }
  .sm\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }
  .sm\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }
  .sm\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }
  .sm\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }
  .sm\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }
  .sm\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }
  .sm\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }
  .sm\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }
  .sm\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }
  .sm\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }
  .sm\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }
  .sm\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }
  .sm\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }
  .sm\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }
  .sm\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }
  .sm\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }
  .sm\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }
  .sm\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }
  .sm\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }
  .sm\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }
  .sm\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }
  .sm\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }
  .sm\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }
  .sm\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }
  .sm\:-inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }
  .sm\:-inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }
  .sm\:-inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }
  .sm\:-inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }
  .sm\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }
  .sm\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }
  .sm\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }
  .sm\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }
  .sm\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }
  .sm\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }
  .sm\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }
  .sm\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }
  .sm\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }
  .sm\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }
  .sm\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }
  .sm\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }
  .sm\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }
  .sm\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }
  .sm\:top-0 {
    top: 0;
  }
  .sm\:top-1 {
    top: 0.25rem;
  }
  .sm\:top-2 {
    top: 0.5rem;
  }
  .sm\:top-3 {
    top: 0.75rem;
  }
  .sm\:top-4 {
    top: 1rem;
  }
  .sm\:top-5 {
    top: 1.25rem;
  }
  .sm\:top-6 {
    top: 1.5rem;
  }
  .sm\:top-7 {
    top: 1.75rem;
  }
  .sm\:top-8 {
    top: 2rem;
  }
  .sm\:top-9 {
    top: 2.25rem;
  }
  .sm\:top-10 {
    top: 2.5rem;
  }
  .sm\:top-11 {
    top: 2.75rem;
  }
  .sm\:top-12 {
    top: 3rem;
  }
  .sm\:top-14 {
    top: 3.5rem;
  }
  .sm\:top-16 {
    top: 4rem;
  }
  .sm\:top-20 {
    top: 5rem;
  }
  .sm\:top-24 {
    top: 6rem;
  }
  .sm\:top-28 {
    top: 7rem;
  }
  .sm\:top-32 {
    top: 8rem;
  }
  .sm\:top-36 {
    top: 9rem;
  }
  .sm\:top-40 {
    top: 10rem;
  }
  .sm\:top-44 {
    top: 11rem;
  }
  .sm\:top-48 {
    top: 12rem;
  }
  .sm\:top-52 {
    top: 13rem;
  }
  .sm\:top-56 {
    top: 14rem;
  }
  .sm\:top-60 {
    top: 15rem;
  }
  .sm\:top-64 {
    top: 16rem;
  }
  .sm\:top-72 {
    top: 18rem;
  }
  .sm\:top-80 {
    top: 20rem;
  }
  .sm\:top-96 {
    top: 24rem;
  }
  .sm\:top-auto {
    top: auto;
  }
  .sm\:top-px {
    top: 1px;
  }
  .sm\:top-0\.5 {
    top: 0.125rem;
  }
  .sm\:top-1\.5 {
    top: 0.375rem;
  }
  .sm\:top-2\.5 {
    top: 0.625rem;
  }
  .sm\:top-3\.5 {
    top: 0.875rem;
  }
  .sm\:-top-0 {
    top: 0;
  }
  .sm\:-top-1 {
    top: -0.25rem;
  }
  .sm\:-top-2 {
    top: -0.5rem;
  }
  .sm\:-top-3 {
    top: -0.75rem;
  }
  .sm\:-top-4 {
    top: -1rem;
  }
  .sm\:-top-5 {
    top: -1.25rem;
  }
  .sm\:-top-6 {
    top: -1.5rem;
  }
  .sm\:-top-7 {
    top: -1.75rem;
  }
  .sm\:-top-8 {
    top: -2rem;
  }
  .sm\:-top-9 {
    top: -2.25rem;
  }
  .sm\:-top-10 {
    top: -2.5rem;
  }
  .sm\:-top-11 {
    top: -2.75rem;
  }
  .sm\:-top-12 {
    top: -3rem;
  }
  .sm\:-top-14 {
    top: -3.5rem;
  }
  .sm\:-top-16 {
    top: -4rem;
  }
  .sm\:-top-20 {
    top: -5rem;
  }
  .sm\:-top-24 {
    top: -6rem;
  }
  .sm\:-top-28 {
    top: -7rem;
  }
  .sm\:-top-32 {
    top: -8rem;
  }
  .sm\:-top-36 {
    top: -9rem;
  }
  .sm\:-top-40 {
    top: -10rem;
  }
  .sm\:-top-44 {
    top: -11rem;
  }
  .sm\:-top-48 {
    top: -12rem;
  }
  .sm\:-top-52 {
    top: -13rem;
  }
  .sm\:-top-56 {
    top: -14rem;
  }
  .sm\:-top-60 {
    top: -15rem;
  }
  .sm\:-top-64 {
    top: -16rem;
  }
  .sm\:-top-72 {
    top: -18rem;
  }
  .sm\:-top-80 {
    top: -20rem;
  }
  .sm\:-top-96 {
    top: -24rem;
  }
  .sm\:-top-px {
    top: -1px;
  }
  .sm\:-top-0\.5 {
    top: -0.125rem;
  }
  .sm\:-top-1\.5 {
    top: -0.375rem;
  }
  .sm\:-top-2\.5 {
    top: -0.625rem;
  }
  .sm\:-top-3\.5 {
    top: -0.875rem;
  }
  .sm\:top-1\/2 {
    top: 50%;
  }
  .sm\:top-1\/3 {
    top: 33.333333%;
  }
  .sm\:top-2\/3 {
    top: 66.666667%;
  }
  .sm\:top-1\/4 {
    top: 25%;
  }
  .sm\:top-2\/4 {
    top: 50%;
  }
  .sm\:top-3\/4 {
    top: 75%;
  }
  .sm\:top-full {
    top: 100%;
  }
  .sm\:-top-1\/2 {
    top: -50%;
  }
  .sm\:-top-1\/3 {
    top: -33.333333%;
  }
  .sm\:-top-2\/3 {
    top: -66.666667%;
  }
  .sm\:-top-1\/4 {
    top: -25%;
  }
  .sm\:-top-2\/4 {
    top: -50%;
  }
  .sm\:-top-3\/4 {
    top: -75%;
  }
  .sm\:-top-full {
    top: -100%;
  }
  .sm\:right-0 {
    right: 0;
  }
  .sm\:right-1 {
    right: 0.25rem;
  }
  .sm\:right-2 {
    right: 0.5rem;
  }
  .sm\:right-3 {
    right: 0.75rem;
  }
  .sm\:right-4 {
    right: 1rem;
  }
  .sm\:right-5 {
    right: 1.25rem;
  }
  .sm\:right-6 {
    right: 1.5rem;
  }
  .sm\:right-7 {
    right: 1.75rem;
  }
  .sm\:right-8 {
    right: 2rem;
  }
  .sm\:right-9 {
    right: 2.25rem;
  }
  .sm\:right-10 {
    right: 2.5rem;
  }
  .sm\:right-11 {
    right: 2.75rem;
  }
  .sm\:right-12 {
    right: 3rem;
  }
  .sm\:right-14 {
    right: 3.5rem;
  }
  .sm\:right-16 {
    right: 4rem;
  }
  .sm\:right-20 {
    right: 5rem;
  }
  .sm\:right-24 {
    right: 6rem;
  }
  .sm\:right-28 {
    right: 7rem;
  }
  .sm\:right-32 {
    right: 8rem;
  }
  .sm\:right-36 {
    right: 9rem;
  }
  .sm\:right-40 {
    right: 10rem;
  }
  .sm\:right-44 {
    right: 11rem;
  }
  .sm\:right-48 {
    right: 12rem;
  }
  .sm\:right-52 {
    right: 13rem;
  }
  .sm\:right-56 {
    right: 14rem;
  }
  .sm\:right-60 {
    right: 15rem;
  }
  .sm\:right-64 {
    right: 16rem;
  }
  .sm\:right-72 {
    right: 18rem;
  }
  .sm\:right-80 {
    right: 20rem;
  }
  .sm\:right-96 {
    right: 24rem;
  }
  .sm\:right-auto {
    right: auto;
  }
  .sm\:right-px {
    right: 1px;
  }
  .sm\:right-0\.5 {
    right: 0.125rem;
  }
  .sm\:right-1\.5 {
    right: 0.375rem;
  }
  .sm\:right-2\.5 {
    right: 0.625rem;
  }
  .sm\:right-3\.5 {
    right: 0.875rem;
  }
  .sm\:-right-0 {
    right: 0;
  }
  .sm\:-right-1 {
    right: -0.25rem;
  }
  .sm\:-right-2 {
    right: -0.5rem;
  }
  .sm\:-right-3 {
    right: -0.75rem;
  }
  .sm\:-right-4 {
    right: -1rem;
  }
  .sm\:-right-5 {
    right: -1.25rem;
  }
  .sm\:-right-6 {
    right: -1.5rem;
  }
  .sm\:-right-7 {
    right: -1.75rem;
  }
  .sm\:-right-8 {
    right: -2rem;
  }
  .sm\:-right-9 {
    right: -2.25rem;
  }
  .sm\:-right-10 {
    right: -2.5rem;
  }
  .sm\:-right-11 {
    right: -2.75rem;
  }
  .sm\:-right-12 {
    right: -3rem;
  }
  .sm\:-right-14 {
    right: -3.5rem;
  }
  .sm\:-right-16 {
    right: -4rem;
  }
  .sm\:-right-20 {
    right: -5rem;
  }
  .sm\:-right-24 {
    right: -6rem;
  }
  .sm\:-right-28 {
    right: -7rem;
  }
  .sm\:-right-32 {
    right: -8rem;
  }
  .sm\:-right-36 {
    right: -9rem;
  }
  .sm\:-right-40 {
    right: -10rem;
  }
  .sm\:-right-44 {
    right: -11rem;
  }
  .sm\:-right-48 {
    right: -12rem;
  }
  .sm\:-right-52 {
    right: -13rem;
  }
  .sm\:-right-56 {
    right: -14rem;
  }
  .sm\:-right-60 {
    right: -15rem;
  }
  .sm\:-right-64 {
    right: -16rem;
  }
  .sm\:-right-72 {
    right: -18rem;
  }
  .sm\:-right-80 {
    right: -20rem;
  }
  .sm\:-right-96 {
    right: -24rem;
  }
  .sm\:-right-px {
    right: -1px;
  }
  .sm\:-right-0\.5 {
    right: -0.125rem;
  }
  .sm\:-right-1\.5 {
    right: -0.375rem;
  }
  .sm\:-right-2\.5 {
    right: -0.625rem;
  }
  .sm\:-right-3\.5 {
    right: -0.875rem;
  }
  .sm\:right-1\/2 {
    right: 50%;
  }
  .sm\:right-1\/3 {
    right: 33.333333%;
  }
  .sm\:right-2\/3 {
    right: 66.666667%;
  }
  .sm\:right-1\/4 {
    right: 25%;
  }
  .sm\:right-2\/4 {
    right: 50%;
  }
  .sm\:right-3\/4 {
    right: 75%;
  }
  .sm\:right-full {
    right: 100%;
  }
  .sm\:-right-1\/2 {
    right: -50%;
  }
  .sm\:-right-1\/3 {
    right: -33.333333%;
  }
  .sm\:-right-2\/3 {
    right: -66.666667%;
  }
  .sm\:-right-1\/4 {
    right: -25%;
  }
  .sm\:-right-2\/4 {
    right: -50%;
  }
  .sm\:-right-3\/4 {
    right: -75%;
  }
  .sm\:-right-full {
    right: -100%;
  }
  .sm\:bottom-0 {
    bottom: 0;
  }
  .sm\:bottom-1 {
    bottom: 0.25rem;
  }
  .sm\:bottom-2 {
    bottom: 0.5rem;
  }
  .sm\:bottom-3 {
    bottom: 0.75rem;
  }
  .sm\:bottom-4 {
    bottom: 1rem;
  }
  .sm\:bottom-5 {
    bottom: 1.25rem;
  }
  .sm\:bottom-6 {
    bottom: 1.5rem;
  }
  .sm\:bottom-7 {
    bottom: 1.75rem;
  }
  .sm\:bottom-8 {
    bottom: 2rem;
  }
  .sm\:bottom-9 {
    bottom: 2.25rem;
  }
  .sm\:bottom-10 {
    bottom: 2.5rem;
  }
  .sm\:bottom-11 {
    bottom: 2.75rem;
  }
  .sm\:bottom-12 {
    bottom: 3rem;
  }
  .sm\:bottom-14 {
    bottom: 3.5rem;
  }
  .sm\:bottom-16 {
    bottom: 4rem;
  }
  .sm\:bottom-20 {
    bottom: 5rem;
  }
  .sm\:bottom-24 {
    bottom: 6rem;
  }
  .sm\:bottom-28 {
    bottom: 7rem;
  }
  .sm\:bottom-32 {
    bottom: 8rem;
  }
  .sm\:bottom-36 {
    bottom: 9rem;
  }
  .sm\:bottom-40 {
    bottom: 10rem;
  }
  .sm\:bottom-44 {
    bottom: 11rem;
  }
  .sm\:bottom-48 {
    bottom: 12rem;
  }
  .sm\:bottom-52 {
    bottom: 13rem;
  }
  .sm\:bottom-56 {
    bottom: 14rem;
  }
  .sm\:bottom-60 {
    bottom: 15rem;
  }
  .sm\:bottom-64 {
    bottom: 16rem;
  }
  .sm\:bottom-72 {
    bottom: 18rem;
  }
  .sm\:bottom-80 {
    bottom: 20rem;
  }
  .sm\:bottom-96 {
    bottom: 24rem;
  }
  .sm\:bottom-auto {
    bottom: auto;
  }
  .sm\:bottom-px {
    bottom: 1px;
  }
  .sm\:bottom-0\.5 {
    bottom: 0.125rem;
  }
  .sm\:bottom-1\.5 {
    bottom: 0.375rem;
  }
  .sm\:bottom-2\.5 {
    bottom: 0.625rem;
  }
  .sm\:bottom-3\.5 {
    bottom: 0.875rem;
  }
  .sm\:-bottom-0 {
    bottom: 0;
  }
  .sm\:-bottom-1 {
    bottom: -0.25rem;
  }
  .sm\:-bottom-2 {
    bottom: -0.5rem;
  }
  .sm\:-bottom-3 {
    bottom: -0.75rem;
  }
  .sm\:-bottom-4 {
    bottom: -1rem;
  }
  .sm\:-bottom-5 {
    bottom: -1.25rem;
  }
  .sm\:-bottom-6 {
    bottom: -1.5rem;
  }
  .sm\:-bottom-7 {
    bottom: -1.75rem;
  }
  .sm\:-bottom-8 {
    bottom: -2rem;
  }
  .sm\:-bottom-9 {
    bottom: -2.25rem;
  }
  .sm\:-bottom-10 {
    bottom: -2.5rem;
  }
  .sm\:-bottom-11 {
    bottom: -2.75rem;
  }
  .sm\:-bottom-12 {
    bottom: -3rem;
  }
  .sm\:-bottom-14 {
    bottom: -3.5rem;
  }
  .sm\:-bottom-16 {
    bottom: -4rem;
  }
  .sm\:-bottom-20 {
    bottom: -5rem;
  }
  .sm\:-bottom-24 {
    bottom: -6rem;
  }
  .sm\:-bottom-28 {
    bottom: -7rem;
  }
  .sm\:-bottom-32 {
    bottom: -8rem;
  }
  .sm\:-bottom-36 {
    bottom: -9rem;
  }
  .sm\:-bottom-40 {
    bottom: -10rem;
  }
  .sm\:-bottom-44 {
    bottom: -11rem;
  }
  .sm\:-bottom-48 {
    bottom: -12rem;
  }
  .sm\:-bottom-52 {
    bottom: -13rem;
  }
  .sm\:-bottom-56 {
    bottom: -14rem;
  }
  .sm\:-bottom-60 {
    bottom: -15rem;
  }
  .sm\:-bottom-64 {
    bottom: -16rem;
  }
  .sm\:-bottom-72 {
    bottom: -18rem;
  }
  .sm\:-bottom-80 {
    bottom: -20rem;
  }
  .sm\:-bottom-96 {
    bottom: -24rem;
  }
  .sm\:-bottom-px {
    bottom: -1px;
  }
  .sm\:-bottom-0\.5 {
    bottom: -0.125rem;
  }
  .sm\:-bottom-1\.5 {
    bottom: -0.375rem;
  }
  .sm\:-bottom-2\.5 {
    bottom: -0.625rem;
  }
  .sm\:-bottom-3\.5 {
    bottom: -0.875rem;
  }
  .sm\:bottom-1\/2 {
    bottom: 50%;
  }
  .sm\:bottom-1\/3 {
    bottom: 33.333333%;
  }
  .sm\:bottom-2\/3 {
    bottom: 66.666667%;
  }
  .sm\:bottom-1\/4 {
    bottom: 25%;
  }
  .sm\:bottom-2\/4 {
    bottom: 50%;
  }
  .sm\:bottom-3\/4 {
    bottom: 75%;
  }
  .sm\:bottom-full {
    bottom: 100%;
  }
  .sm\:-bottom-1\/2 {
    bottom: -50%;
  }
  .sm\:-bottom-1\/3 {
    bottom: -33.333333%;
  }
  .sm\:-bottom-2\/3 {
    bottom: -66.666667%;
  }
  .sm\:-bottom-1\/4 {
    bottom: -25%;
  }
  .sm\:-bottom-2\/4 {
    bottom: -50%;
  }
  .sm\:-bottom-3\/4 {
    bottom: -75%;
  }
  .sm\:-bottom-full {
    bottom: -100%;
  }
  .sm\:left-0 {
    left: 0;
  }
  .sm\:left-1 {
    left: 0.25rem;
  }
  .sm\:left-2 {
    left: 0.5rem;
  }
  .sm\:left-3 {
    left: 0.75rem;
  }
  .sm\:left-4 {
    left: 1rem;
  }
  .sm\:left-5 {
    left: 1.25rem;
  }
  .sm\:left-6 {
    left: 1.5rem;
  }
  .sm\:left-7 {
    left: 1.75rem;
  }
  .sm\:left-8 {
    left: 2rem;
  }
  .sm\:left-9 {
    left: 2.25rem;
  }
  .sm\:left-10 {
    left: 2.5rem;
  }
  .sm\:left-11 {
    left: 2.75rem;
  }
  .sm\:left-12 {
    left: 3rem;
  }
  .sm\:left-14 {
    left: 3.5rem;
  }
  .sm\:left-16 {
    left: 4rem;
  }
  .sm\:left-20 {
    left: 5rem;
  }
  .sm\:left-24 {
    left: 6rem;
  }
  .sm\:left-28 {
    left: 7rem;
  }
  .sm\:left-32 {
    left: 8rem;
  }
  .sm\:left-36 {
    left: 9rem;
  }
  .sm\:left-40 {
    left: 10rem;
  }
  .sm\:left-44 {
    left: 11rem;
  }
  .sm\:left-48 {
    left: 12rem;
  }
  .sm\:left-52 {
    left: 13rem;
  }
  .sm\:left-56 {
    left: 14rem;
  }
  .sm\:left-60 {
    left: 15rem;
  }
  .sm\:left-64 {
    left: 16rem;
  }
  .sm\:left-72 {
    left: 18rem;
  }
  .sm\:left-80 {
    left: 20rem;
  }
  .sm\:left-96 {
    left: 24rem;
  }
  .sm\:left-auto {
    left: auto;
  }
  .sm\:left-px {
    left: 1px;
  }
  .sm\:left-0\.5 {
    left: 0.125rem;
  }
  .sm\:left-1\.5 {
    left: 0.375rem;
  }
  .sm\:left-2\.5 {
    left: 0.625rem;
  }
  .sm\:left-3\.5 {
    left: 0.875rem;
  }
  .sm\:-left-0 {
    left: 0;
  }
  .sm\:-left-1 {
    left: -0.25rem;
  }
  .sm\:-left-2 {
    left: -0.5rem;
  }
  .sm\:-left-3 {
    left: -0.75rem;
  }
  .sm\:-left-4 {
    left: -1rem;
  }
  .sm\:-left-5 {
    left: -1.25rem;
  }
  .sm\:-left-6 {
    left: -1.5rem;
  }
  .sm\:-left-7 {
    left: -1.75rem;
  }
  .sm\:-left-8 {
    left: -2rem;
  }
  .sm\:-left-9 {
    left: -2.25rem;
  }
  .sm\:-left-10 {
    left: -2.5rem;
  }
  .sm\:-left-11 {
    left: -2.75rem;
  }
  .sm\:-left-12 {
    left: -3rem;
  }
  .sm\:-left-14 {
    left: -3.5rem;
  }
  .sm\:-left-16 {
    left: -4rem;
  }
  .sm\:-left-20 {
    left: -5rem;
  }
  .sm\:-left-24 {
    left: -6rem;
  }
  .sm\:-left-28 {
    left: -7rem;
  }
  .sm\:-left-32 {
    left: -8rem;
  }
  .sm\:-left-36 {
    left: -9rem;
  }
  .sm\:-left-40 {
    left: -10rem;
  }
  .sm\:-left-44 {
    left: -11rem;
  }
  .sm\:-left-48 {
    left: -12rem;
  }
  .sm\:-left-52 {
    left: -13rem;
  }
  .sm\:-left-56 {
    left: -14rem;
  }
  .sm\:-left-60 {
    left: -15rem;
  }
  .sm\:-left-64 {
    left: -16rem;
  }
  .sm\:-left-72 {
    left: -18rem;
  }
  .sm\:-left-80 {
    left: -20rem;
  }
  .sm\:-left-96 {
    left: -24rem;
  }
  .sm\:-left-px {
    left: -1px;
  }
  .sm\:-left-0\.5 {
    left: -0.125rem;
  }
  .sm\:-left-1\.5 {
    left: -0.375rem;
  }
  .sm\:-left-2\.5 {
    left: -0.625rem;
  }
  .sm\:-left-3\.5 {
    left: -0.875rem;
  }
  .sm\:left-1\/2 {
    left: 50%;
  }
  .sm\:left-1\/3 {
    left: 33.333333%;
  }
  .sm\:left-2\/3 {
    left: 66.666667%;
  }
  .sm\:left-1\/4 {
    left: 25%;
  }
  .sm\:left-2\/4 {
    left: 50%;
  }
  .sm\:left-3\/4 {
    left: 75%;
  }
  .sm\:left-full {
    left: 100%;
  }
  .sm\:-left-1\/2 {
    left: -50%;
  }
  .sm\:-left-1\/3 {
    left: -33.333333%;
  }
  .sm\:-left-2\/3 {
    left: -66.666667%;
  }
  .sm\:-left-1\/4 {
    left: -25%;
  }
  .sm\:-left-2\/4 {
    left: -50%;
  }
  .sm\:-left-3\/4 {
    left: -75%;
  }
  .sm\:-left-full {
    left: -100%;
  }
  .sm\:isolate {
    isolation: isolate;
  }
  .sm\:isolation-auto {
    isolation: auto;
  }
  .sm\:z-0 {
    z-index: 0;
  }
  .sm\:z-10 {
    z-index: 10;
  }
  .sm\:z-20 {
    z-index: 20;
  }
  .sm\:z-30 {
    z-index: 30;
  }
  .sm\:z-40 {
    z-index: 40;
  }
  .sm\:z-50 {
    z-index: 50;
  }
  .sm\:z-auto {
    z-index: auto;
  }
  .sm\:focus-within\:z-0:focus-within {
    z-index: 0;
  }
  .sm\:focus-within\:z-10:focus-within {
    z-index: 10;
  }
  .sm\:focus-within\:z-20:focus-within {
    z-index: 20;
  }
  .sm\:focus-within\:z-30:focus-within {
    z-index: 30;
  }
  .sm\:focus-within\:z-40:focus-within {
    z-index: 40;
  }
  .sm\:focus-within\:z-50:focus-within {
    z-index: 50;
  }
  .sm\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }
  .sm\:focus\:z-0:focus {
    z-index: 0;
  }
  .sm\:focus\:z-10:focus {
    z-index: 10;
  }
  .sm\:focus\:z-20:focus {
    z-index: 20;
  }
  .sm\:focus\:z-30:focus {
    z-index: 30;
  }
  .sm\:focus\:z-40:focus {
    z-index: 40;
  }
  .sm\:focus\:z-50:focus {
    z-index: 50;
  }
  .sm\:focus\:z-auto:focus {
    z-index: auto;
  }
  .sm\:order-1 {
    order: 1;
  }
  .sm\:order-2 {
    order: 2;
  }
  .sm\:order-3 {
    order: 3;
  }
  .sm\:order-4 {
    order: 4;
  }
  .sm\:order-5 {
    order: 5;
  }
  .sm\:order-6 {
    order: 6;
  }
  .sm\:order-7 {
    order: 7;
  }
  .sm\:order-8 {
    order: 8;
  }
  .sm\:order-9 {
    order: 9;
  }
  .sm\:order-10 {
    order: 10;
  }
  .sm\:order-11 {
    order: 11;
  }
  .sm\:order-12 {
    order: 12;
  }
  .sm\:order-first {
    order: -9999;
  }
  .sm\:order-last {
    order: 9999;
  }
  .sm\:order-none {
    order: 0;
  }
  .sm\:col-auto {
    grid-column: auto;
  }
  .sm\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .sm\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .sm\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .sm\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .sm\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  .sm\:col-span-7 {
    grid-column: span 7 / span 7;
  }
  .sm\:col-span-8 {
    grid-column: span 8 / span 8;
  }
  .sm\:col-span-9 {
    grid-column: span 9 / span 9;
  }
  .sm\:col-span-10 {
    grid-column: span 10 / span 10;
  }
  .sm\:col-span-11 {
    grid-column: span 11 / span 11;
  }
  .sm\:col-span-12 {
    grid-column: span 12 / span 12;
  }
  .sm\:col-span-full {
    grid-column: 1/-1;
  }
  .sm\:col-start-1 {
    grid-column-start: 1;
  }
  .sm\:col-start-2 {
    grid-column-start: 2;
  }
  .sm\:col-start-3 {
    grid-column-start: 3;
  }
  .sm\:col-start-4 {
    grid-column-start: 4;
  }
  .sm\:col-start-5 {
    grid-column-start: 5;
  }
  .sm\:col-start-6 {
    grid-column-start: 6;
  }
  .sm\:col-start-7 {
    grid-column-start: 7;
  }
  .sm\:col-start-8 {
    grid-column-start: 8;
  }
  .sm\:col-start-9 {
    grid-column-start: 9;
  }
  .sm\:col-start-10 {
    grid-column-start: 10;
  }
  .sm\:col-start-11 {
    grid-column-start: 11;
  }
  .sm\:col-start-12 {
    grid-column-start: 12;
  }
  .sm\:col-start-13 {
    grid-column-start: 13;
  }
  .sm\:col-start-auto {
    grid-column-start: auto;
  }
  .sm\:col-end-1 {
    grid-column-end: 1;
  }
  .sm\:col-end-2 {
    grid-column-end: 2;
  }
  .sm\:col-end-3 {
    grid-column-end: 3;
  }
  .sm\:col-end-4 {
    grid-column-end: 4;
  }
  .sm\:col-end-5 {
    grid-column-end: 5;
  }
  .sm\:col-end-6 {
    grid-column-end: 6;
  }
  .sm\:col-end-7 {
    grid-column-end: 7;
  }
  .sm\:col-end-8 {
    grid-column-end: 8;
  }
  .sm\:col-end-9 {
    grid-column-end: 9;
  }
  .sm\:col-end-10 {
    grid-column-end: 10;
  }
  .sm\:col-end-11 {
    grid-column-end: 11;
  }
  .sm\:col-end-12 {
    grid-column-end: 12;
  }
  .sm\:col-end-13 {
    grid-column-end: 13;
  }
  .sm\:col-end-auto {
    grid-column-end: auto;
  }
  .sm\:row-auto {
    grid-row: auto;
  }
  .sm\:row-span-1 {
    grid-row: span 1 / span 1;
  }
  .sm\:row-span-2 {
    grid-row: span 2 / span 2;
  }
  .sm\:row-span-3 {
    grid-row: span 3 / span 3;
  }
  .sm\:row-span-4 {
    grid-row: span 4 / span 4;
  }
  .sm\:row-span-5 {
    grid-row: span 5 / span 5;
  }
  .sm\:row-span-6 {
    grid-row: span 6 / span 6;
  }
  .sm\:row-span-full {
    grid-row: 1/-1;
  }
  .sm\:row-start-1 {
    grid-row-start: 1;
  }
  .sm\:row-start-2 {
    grid-row-start: 2;
  }
  .sm\:row-start-3 {
    grid-row-start: 3;
  }
  .sm\:row-start-4 {
    grid-row-start: 4;
  }
  .sm\:row-start-5 {
    grid-row-start: 5;
  }
  .sm\:row-start-6 {
    grid-row-start: 6;
  }
  .sm\:row-start-7 {
    grid-row-start: 7;
  }
  .sm\:row-start-auto {
    grid-row-start: auto;
  }
  .sm\:row-end-1 {
    grid-row-end: 1;
  }
  .sm\:row-end-2 {
    grid-row-end: 2;
  }
  .sm\:row-end-3 {
    grid-row-end: 3;
  }
  .sm\:row-end-4 {
    grid-row-end: 4;
  }
  .sm\:row-end-5 {
    grid-row-end: 5;
  }
  .sm\:row-end-6 {
    grid-row-end: 6;
  }
  .sm\:row-end-7 {
    grid-row-end: 7;
  }
  .sm\:row-end-auto {
    grid-row-end: auto;
  }
  .sm\:float-right {
    float: right;
  }
  .sm\:float-left {
    float: left;
  }
  .sm\:float-none {
    float: none;
  }
  .sm\:clear-left {
    clear: left;
  }
  .sm\:clear-right {
    clear: right;
  }
  .sm\:clear-both {
    clear: both;
  }
  .sm\:clear-none {
    clear: none;
  }
  .sm\:m-0 {
    margin: 0;
  }
  .sm\:m-1 {
    margin: 0.25rem;
  }
  .sm\:m-2 {
    margin: 0.5rem;
  }
  .sm\:m-3 {
    margin: 0.75rem;
  }
  .sm\:m-4 {
    margin: 1rem;
  }
  .sm\:m-5 {
    margin: 1.25rem;
  }
  .sm\:m-6 {
    margin: 1.5rem;
  }
  .sm\:m-7 {
    margin: 1.75rem;
  }
  .sm\:m-8 {
    margin: 2rem;
  }
  .sm\:m-9 {
    margin: 2.25rem;
  }
  .sm\:m-10 {
    margin: 2.5rem;
  }
  .sm\:m-11 {
    margin: 2.75rem;
  }
  .sm\:m-12 {
    margin: 3rem;
  }
  .sm\:m-14 {
    margin: 3.5rem;
  }
  .sm\:m-16 {
    margin: 4rem;
  }
  .sm\:m-20 {
    margin: 5rem;
  }
  .sm\:m-24 {
    margin: 6rem;
  }
  .sm\:m-28 {
    margin: 7rem;
  }
  .sm\:m-32 {
    margin: 8rem;
  }
  .sm\:m-36 {
    margin: 9rem;
  }
  .sm\:m-40 {
    margin: 10rem;
  }
  .sm\:m-44 {
    margin: 11rem;
  }
  .sm\:m-48 {
    margin: 12rem;
  }
  .sm\:m-52 {
    margin: 13rem;
  }
  .sm\:m-56 {
    margin: 14rem;
  }
  .sm\:m-60 {
    margin: 15rem;
  }
  .sm\:m-64 {
    margin: 16rem;
  }
  .sm\:m-72 {
    margin: 18rem;
  }
  .sm\:m-80 {
    margin: 20rem;
  }
  .sm\:m-96 {
    margin: 24rem;
  }
  .sm\:m-auto {
    margin: auto;
  }
  .sm\:m-px {
    margin: 1px;
  }
  .sm\:m-0\.5 {
    margin: 0.125rem;
  }
  .sm\:m-1\.5 {
    margin: 0.375rem;
  }
  .sm\:m-2\.5 {
    margin: 0.625rem;
  }
  .sm\:m-3\.5 {
    margin: 0.875rem;
  }
  .sm\:-m-0 {
    margin: 0;
  }
  .sm\:-m-1 {
    margin: -0.25rem;
  }
  .sm\:-m-2 {
    margin: -0.5rem;
  }
  .sm\:-m-3 {
    margin: -0.75rem;
  }
  .sm\:-m-4 {
    margin: -1rem;
  }
  .sm\:-m-5 {
    margin: -1.25rem;
  }
  .sm\:-m-6 {
    margin: -1.5rem;
  }
  .sm\:-m-7 {
    margin: -1.75rem;
  }
  .sm\:-m-8 {
    margin: -2rem;
  }
  .sm\:-m-9 {
    margin: -2.25rem;
  }
  .sm\:-m-10 {
    margin: -2.5rem;
  }
  .sm\:-m-11 {
    margin: -2.75rem;
  }
  .sm\:-m-12 {
    margin: -3rem;
  }
  .sm\:-m-14 {
    margin: -3.5rem;
  }
  .sm\:-m-16 {
    margin: -4rem;
  }
  .sm\:-m-20 {
    margin: -5rem;
  }
  .sm\:-m-24 {
    margin: -6rem;
  }
  .sm\:-m-28 {
    margin: -7rem;
  }
  .sm\:-m-32 {
    margin: -8rem;
  }
  .sm\:-m-36 {
    margin: -9rem;
  }
  .sm\:-m-40 {
    margin: -10rem;
  }
  .sm\:-m-44 {
    margin: -11rem;
  }
  .sm\:-m-48 {
    margin: -12rem;
  }
  .sm\:-m-52 {
    margin: -13rem;
  }
  .sm\:-m-56 {
    margin: -14rem;
  }
  .sm\:-m-60 {
    margin: -15rem;
  }
  .sm\:-m-64 {
    margin: -16rem;
  }
  .sm\:-m-72 {
    margin: -18rem;
  }
  .sm\:-m-80 {
    margin: -20rem;
  }
  .sm\:-m-96 {
    margin: -24rem;
  }
  .sm\:-m-px {
    margin: -1px;
  }
  .sm\:-m-0\.5 {
    margin: -0.125rem;
  }
  .sm\:-m-1\.5 {
    margin: -0.375rem;
  }
  .sm\:-m-2\.5 {
    margin: -0.625rem;
  }
  .sm\:-m-3\.5 {
    margin: -0.875rem;
  }
  .sm\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .sm\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .sm\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .sm\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .sm\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .sm\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .sm\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .sm\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .sm\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .sm\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .sm\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }
  .sm\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .sm\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .sm\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .sm\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .sm\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .sm\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .sm\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .sm\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .sm\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .sm\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .sm\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .sm\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }
  .sm\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }
  .sm\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .sm\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }
  .sm\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }
  .sm\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .sm\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .sm\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .sm\:mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .sm\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .sm\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .sm\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .sm\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .sm\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .sm\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .sm\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .sm\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .sm\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .sm\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .sm\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }
  .sm\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .sm\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
  .sm\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .sm\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }
  .sm\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .sm\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .sm\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .sm\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .sm\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .sm\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .sm\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .sm\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .sm\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .sm\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }
  .sm\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }
  .sm\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }
  .sm\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }
  .sm\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }
  .sm\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }
  .sm\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }
  .sm\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }
  .sm\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }
  .sm\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .sm\:-mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }
  .sm\:-mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }
  .sm\:-mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .sm\:-mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }
  .sm\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .sm\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .sm\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .sm\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .sm\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .sm\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .sm\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .sm\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .sm\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .sm\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .sm\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .sm\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .sm\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .sm\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .sm\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .sm\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .sm\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .sm\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .sm\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .sm\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .sm\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .sm\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .sm\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }
  .sm\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }
  .sm\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }
  .sm\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
  .sm\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }
  .sm\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }
  .sm\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }
  .sm\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .sm\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .sm\:my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .sm\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .sm\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .sm\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .sm\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .sm\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .sm\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .sm\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .sm\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .sm\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .sm\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .sm\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .sm\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .sm\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }
  .sm\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .sm\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .sm\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .sm\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .sm\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .sm\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .sm\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .sm\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .sm\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .sm\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .sm\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .sm\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }
  .sm\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }
  .sm\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }
  .sm\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }
  .sm\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }
  .sm\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }
  .sm\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }
  .sm\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }
  .sm\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }
  .sm\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .sm\:-my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }
  .sm\:-my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }
  .sm\:-my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .sm\:-my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }
  .sm\:mt-0 {
    margin-top: 0;
  }
  .sm\:mt-1 {
    margin-top: 0.25rem;
  }
  .sm\:mt-2 {
    margin-top: 0.5rem;
  }
  .sm\:mt-3 {
    margin-top: 0.75rem;
  }
  .sm\:mt-4 {
    margin-top: 1rem;
  }
  .sm\:mt-5 {
    margin-top: 1.25rem;
  }
  .sm\:mt-6 {
    margin-top: 1.5rem;
  }
  .sm\:mt-7 {
    margin-top: 1.75rem;
  }
  .sm\:mt-8 {
    margin-top: 2rem;
  }
  .sm\:mt-9 {
    margin-top: 2.25rem;
  }
  .sm\:mt-10 {
    margin-top: 2.5rem;
  }
  .sm\:mt-11 {
    margin-top: 2.75rem;
  }
  .sm\:mt-12 {
    margin-top: 3rem;
  }
  .sm\:mt-14 {
    margin-top: 3.5rem;
  }
  .sm\:mt-16 {
    margin-top: 4rem;
  }
  .sm\:mt-20 {
    margin-top: 5rem;
  }
  .sm\:mt-24 {
    margin-top: 6rem;
  }
  .sm\:mt-28 {
    margin-top: 7rem;
  }
  .sm\:mt-32 {
    margin-top: 8rem;
  }
  .sm\:mt-36 {
    margin-top: 9rem;
  }
  .sm\:mt-40 {
    margin-top: 10rem;
  }
  .sm\:mt-44 {
    margin-top: 11rem;
  }
  .sm\:mt-48 {
    margin-top: 12rem;
  }
  .sm\:mt-52 {
    margin-top: 13rem;
  }
  .sm\:mt-56 {
    margin-top: 14rem;
  }
  .sm\:mt-60 {
    margin-top: 15rem;
  }
  .sm\:mt-64 {
    margin-top: 16rem;
  }
  .sm\:mt-72 {
    margin-top: 18rem;
  }
  .sm\:mt-80 {
    margin-top: 20rem;
  }
  .sm\:mt-96 {
    margin-top: 24rem;
  }
  .sm\:mt-auto {
    margin-top: auto;
  }
  .sm\:mt-px {
    margin-top: 1px;
  }
  .sm\:mt-0\.5 {
    margin-top: 0.125rem;
  }
  .sm\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .sm\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .sm\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .sm\:-mt-0 {
    margin-top: 0;
  }
  .sm\:-mt-1 {
    margin-top: -0.25rem;
  }
  .sm\:-mt-2 {
    margin-top: -0.5rem;
  }
  .sm\:-mt-3 {
    margin-top: -0.75rem;
  }
  .sm\:-mt-4 {
    margin-top: -1rem;
  }
  .sm\:-mt-5 {
    margin-top: -1.25rem;
  }
  .sm\:-mt-6 {
    margin-top: -1.5rem;
  }
  .sm\:-mt-7 {
    margin-top: -1.75rem;
  }
  .sm\:-mt-8 {
    margin-top: -2rem;
  }
  .sm\:-mt-9 {
    margin-top: -2.25rem;
  }
  .sm\:-mt-10 {
    margin-top: -2.5rem;
  }
  .sm\:-mt-11 {
    margin-top: -2.75rem;
  }
  .sm\:-mt-12 {
    margin-top: -3rem;
  }
  .sm\:-mt-14 {
    margin-top: -3.5rem;
  }
  .sm\:-mt-16 {
    margin-top: -4rem;
  }
  .sm\:-mt-20 {
    margin-top: -5rem;
  }
  .sm\:-mt-24 {
    margin-top: -6rem;
  }
  .sm\:-mt-28 {
    margin-top: -7rem;
  }
  .sm\:-mt-32 {
    margin-top: -8rem;
  }
  .sm\:-mt-36 {
    margin-top: -9rem;
  }
  .sm\:-mt-40 {
    margin-top: -10rem;
  }
  .sm\:-mt-44 {
    margin-top: -11rem;
  }
  .sm\:-mt-48 {
    margin-top: -12rem;
  }
  .sm\:-mt-52 {
    margin-top: -13rem;
  }
  .sm\:-mt-56 {
    margin-top: -14rem;
  }
  .sm\:-mt-60 {
    margin-top: -15rem;
  }
  .sm\:-mt-64 {
    margin-top: -16rem;
  }
  .sm\:-mt-72 {
    margin-top: -18rem;
  }
  .sm\:-mt-80 {
    margin-top: -20rem;
  }
  .sm\:-mt-96 {
    margin-top: -24rem;
  }
  .sm\:-mt-px {
    margin-top: -1px;
  }
  .sm\:-mt-0\.5 {
    margin-top: -0.125rem;
  }
  .sm\:-mt-1\.5 {
    margin-top: -0.375rem;
  }
  .sm\:-mt-2\.5 {
    margin-top: -0.625rem;
  }
  .sm\:-mt-3\.5 {
    margin-top: -0.875rem;
  }
  .sm\:mr-0 {
    margin-right: 0;
  }
  .sm\:mr-1 {
    margin-right: 0.25rem;
  }
  .sm\:mr-2 {
    margin-right: 0.5rem;
  }
  .sm\:mr-3 {
    margin-right: 0.75rem;
  }
  .sm\:mr-4 {
    margin-right: 1rem;
  }
  .sm\:mr-5 {
    margin-right: 1.25rem;
  }
  .sm\:mr-6 {
    margin-right: 1.5rem;
  }
  .sm\:mr-7 {
    margin-right: 1.75rem;
  }
  .sm\:mr-8 {
    margin-right: 2rem;
  }
  .sm\:mr-9 {
    margin-right: 2.25rem;
  }
  .sm\:mr-10 {
    margin-right: 2.5rem;
  }
  .sm\:mr-11 {
    margin-right: 2.75rem;
  }
  .sm\:mr-12 {
    margin-right: 3rem;
  }
  .sm\:mr-14 {
    margin-right: 3.5rem;
  }
  .sm\:mr-16 {
    margin-right: 4rem;
  }
  .sm\:mr-20 {
    margin-right: 5rem;
  }
  .sm\:mr-24 {
    margin-right: 6rem;
  }
  .sm\:mr-28 {
    margin-right: 7rem;
  }
  .sm\:mr-32 {
    margin-right: 8rem;
  }
  .sm\:mr-36 {
    margin-right: 9rem;
  }
  .sm\:mr-40 {
    margin-right: 10rem;
  }
  .sm\:mr-44 {
    margin-right: 11rem;
  }
  .sm\:mr-48 {
    margin-right: 12rem;
  }
  .sm\:mr-52 {
    margin-right: 13rem;
  }
  .sm\:mr-56 {
    margin-right: 14rem;
  }
  .sm\:mr-60 {
    margin-right: 15rem;
  }
  .sm\:mr-64 {
    margin-right: 16rem;
  }
  .sm\:mr-72 {
    margin-right: 18rem;
  }
  .sm\:mr-80 {
    margin-right: 20rem;
  }
  .sm\:mr-96 {
    margin-right: 24rem;
  }
  .sm\:mr-auto {
    margin-right: auto;
  }
  .sm\:mr-px {
    margin-right: 1px;
  }
  .sm\:mr-0\.5 {
    margin-right: 0.125rem;
  }
  .sm\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .sm\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .sm\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .sm\:-mr-0 {
    margin-right: 0;
  }
  .sm\:-mr-1 {
    margin-right: -0.25rem;
  }
  .sm\:-mr-2 {
    margin-right: -0.5rem;
  }
  .sm\:-mr-3 {
    margin-right: -0.75rem;
  }
  .sm\:-mr-4 {
    margin-right: -1rem;
  }
  .sm\:-mr-5 {
    margin-right: -1.25rem;
  }
  .sm\:-mr-6 {
    margin-right: -1.5rem;
  }
  .sm\:-mr-7 {
    margin-right: -1.75rem;
  }
  .sm\:-mr-8 {
    margin-right: -2rem;
  }
  .sm\:-mr-9 {
    margin-right: -2.25rem;
  }
  .sm\:-mr-10 {
    margin-right: -2.5rem;
  }
  .sm\:-mr-11 {
    margin-right: -2.75rem;
  }
  .sm\:-mr-12 {
    margin-right: -3rem;
  }
  .sm\:-mr-14 {
    margin-right: -3.5rem;
  }
  .sm\:-mr-16 {
    margin-right: -4rem;
  }
  .sm\:-mr-20 {
    margin-right: -5rem;
  }
  .sm\:-mr-24 {
    margin-right: -6rem;
  }
  .sm\:-mr-28 {
    margin-right: -7rem;
  }
  .sm\:-mr-32 {
    margin-right: -8rem;
  }
  .sm\:-mr-36 {
    margin-right: -9rem;
  }
  .sm\:-mr-40 {
    margin-right: -10rem;
  }
  .sm\:-mr-44 {
    margin-right: -11rem;
  }
  .sm\:-mr-48 {
    margin-right: -12rem;
  }
  .sm\:-mr-52 {
    margin-right: -13rem;
  }
  .sm\:-mr-56 {
    margin-right: -14rem;
  }
  .sm\:-mr-60 {
    margin-right: -15rem;
  }
  .sm\:-mr-64 {
    margin-right: -16rem;
  }
  .sm\:-mr-72 {
    margin-right: -18rem;
  }
  .sm\:-mr-80 {
    margin-right: -20rem;
  }
  .sm\:-mr-96 {
    margin-right: -24rem;
  }
  .sm\:-mr-px {
    margin-right: -1px;
  }
  .sm\:-mr-0\.5 {
    margin-right: -0.125rem;
  }
  .sm\:-mr-1\.5 {
    margin-right: -0.375rem;
  }
  .sm\:-mr-2\.5 {
    margin-right: -0.625rem;
  }
  .sm\:-mr-3\.5 {
    margin-right: -0.875rem;
  }
  .sm\:mb-0 {
    margin-bottom: 0;
  }
  .sm\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .sm\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .sm\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .sm\:mb-4 {
    margin-bottom: 1rem;
  }
  .sm\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .sm\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .sm\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .sm\:mb-8 {
    margin-bottom: 2rem;
  }
  .sm\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .sm\:mb-10 {
    margin-bottom: 2.5rem;
  }
  .sm\:mb-11 {
    margin-bottom: 2.75rem;
  }
  .sm\:mb-12 {
    margin-bottom: 3rem;
  }
  .sm\:mb-14 {
    margin-bottom: 3.5rem;
  }
  .sm\:mb-16 {
    margin-bottom: 4rem;
  }
  .sm\:mb-20 {
    margin-bottom: 5rem;
  }
  .sm\:mb-24 {
    margin-bottom: 6rem;
  }
  .sm\:mb-28 {
    margin-bottom: 7rem;
  }
  .sm\:mb-32 {
    margin-bottom: 8rem;
  }
  .sm\:mb-36 {
    margin-bottom: 9rem;
  }
  .sm\:mb-40 {
    margin-bottom: 10rem;
  }
  .sm\:mb-44 {
    margin-bottom: 11rem;
  }
  .sm\:mb-48 {
    margin-bottom: 12rem;
  }
  .sm\:mb-52 {
    margin-bottom: 13rem;
  }
  .sm\:mb-56 {
    margin-bottom: 14rem;
  }
  .sm\:mb-60 {
    margin-bottom: 15rem;
  }
  .sm\:mb-64 {
    margin-bottom: 16rem;
  }
  .sm\:mb-72 {
    margin-bottom: 18rem;
  }
  .sm\:mb-80 {
    margin-bottom: 20rem;
  }
  .sm\:mb-96 {
    margin-bottom: 24rem;
  }
  .sm\:mb-auto {
    margin-bottom: auto;
  }
  .sm\:mb-px {
    margin-bottom: 1px;
  }
  .sm\:mb-0\.5 {
    margin-bottom: 0.125rem;
  }
  .sm\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .sm\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .sm\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .sm\:-mb-0 {
    margin-bottom: 0;
  }
  .sm\:-mb-1 {
    margin-bottom: -0.25rem;
  }
  .sm\:-mb-2 {
    margin-bottom: -0.5rem;
  }
  .sm\:-mb-3 {
    margin-bottom: -0.75rem;
  }
  .sm\:-mb-4 {
    margin-bottom: -1rem;
  }
  .sm\:-mb-5 {
    margin-bottom: -1.25rem;
  }
  .sm\:-mb-6 {
    margin-bottom: -1.5rem;
  }
  .sm\:-mb-7 {
    margin-bottom: -1.75rem;
  }
  .sm\:-mb-8 {
    margin-bottom: -2rem;
  }
  .sm\:-mb-9 {
    margin-bottom: -2.25rem;
  }
  .sm\:-mb-10 {
    margin-bottom: -2.5rem;
  }
  .sm\:-mb-11 {
    margin-bottom: -2.75rem;
  }
  .sm\:-mb-12 {
    margin-bottom: -3rem;
  }
  .sm\:-mb-14 {
    margin-bottom: -3.5rem;
  }
  .sm\:-mb-16 {
    margin-bottom: -4rem;
  }
  .sm\:-mb-20 {
    margin-bottom: -5rem;
  }
  .sm\:-mb-24 {
    margin-bottom: -6rem;
  }
  .sm\:-mb-28 {
    margin-bottom: -7rem;
  }
  .sm\:-mb-32 {
    margin-bottom: -8rem;
  }
  .sm\:-mb-36 {
    margin-bottom: -9rem;
  }
  .sm\:-mb-40 {
    margin-bottom: -10rem;
  }
  .sm\:-mb-44 {
    margin-bottom: -11rem;
  }
  .sm\:-mb-48 {
    margin-bottom: -12rem;
  }
  .sm\:-mb-52 {
    margin-bottom: -13rem;
  }
  .sm\:-mb-56 {
    margin-bottom: -14rem;
  }
  .sm\:-mb-60 {
    margin-bottom: -15rem;
  }
  .sm\:-mb-64 {
    margin-bottom: -16rem;
  }
  .sm\:-mb-72 {
    margin-bottom: -18rem;
  }
  .sm\:-mb-80 {
    margin-bottom: -20rem;
  }
  .sm\:-mb-96 {
    margin-bottom: -24rem;
  }
  .sm\:-mb-px {
    margin-bottom: -1px;
  }
  .sm\:-mb-0\.5 {
    margin-bottom: -0.125rem;
  }
  .sm\:-mb-1\.5 {
    margin-bottom: -0.375rem;
  }
  .sm\:-mb-2\.5 {
    margin-bottom: -0.625rem;
  }
  .sm\:-mb-3\.5 {
    margin-bottom: -0.875rem;
  }
  .sm\:ml-0 {
    margin-left: 0;
  }
  .sm\:ml-1 {
    margin-left: 0.25rem;
  }
  .sm\:ml-2 {
    margin-left: 0.5rem;
  }
  .sm\:ml-3 {
    margin-left: 0.75rem;
  }
  .sm\:ml-4 {
    margin-left: 1rem;
  }
  .sm\:ml-5 {
    margin-left: 1.25rem;
  }
  .sm\:ml-6 {
    margin-left: 1.5rem;
  }
  .sm\:ml-7 {
    margin-left: 1.75rem;
  }
  .sm\:ml-8 {
    margin-left: 2rem;
  }
  .sm\:ml-9 {
    margin-left: 2.25rem;
  }
  .sm\:ml-10 {
    margin-left: 2.5rem;
  }
  .sm\:ml-11 {
    margin-left: 2.75rem;
  }
  .sm\:ml-12 {
    margin-left: 3rem;
  }
  .sm\:ml-14 {
    margin-left: 3.5rem;
  }
  .sm\:ml-16 {
    margin-left: 4rem;
  }
  .sm\:ml-20 {
    margin-left: 5rem;
  }
  .sm\:ml-24 {
    margin-left: 6rem;
  }
  .sm\:ml-28 {
    margin-left: 7rem;
  }
  .sm\:ml-32 {
    margin-left: 8rem;
  }
  .sm\:ml-36 {
    margin-left: 9rem;
  }
  .sm\:ml-40 {
    margin-left: 10rem;
  }
  .sm\:ml-44 {
    margin-left: 11rem;
  }
  .sm\:ml-48 {
    margin-left: 12rem;
  }
  .sm\:ml-52 {
    margin-left: 13rem;
  }
  .sm\:ml-56 {
    margin-left: 14rem;
  }
  .sm\:ml-60 {
    margin-left: 15rem;
  }
  .sm\:ml-64 {
    margin-left: 16rem;
  }
  .sm\:ml-72 {
    margin-left: 18rem;
  }
  .sm\:ml-80 {
    margin-left: 20rem;
  }
  .sm\:ml-96 {
    margin-left: 24rem;
  }
  .sm\:ml-auto {
    margin-left: auto;
  }
  .sm\:ml-px {
    margin-left: 1px;
  }
  .sm\:ml-0\.5 {
    margin-left: 0.125rem;
  }
  .sm\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .sm\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .sm\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .sm\:-ml-0 {
    margin-left: 0;
  }
  .sm\:-ml-1 {
    margin-left: -0.25rem;
  }
  .sm\:-ml-2 {
    margin-left: -0.5rem;
  }
  .sm\:-ml-3 {
    margin-left: -0.75rem;
  }
  .sm\:-ml-4 {
    margin-left: -1rem;
  }
  .sm\:-ml-5 {
    margin-left: -1.25rem;
  }
  .sm\:-ml-6 {
    margin-left: -1.5rem;
  }
  .sm\:-ml-7 {
    margin-left: -1.75rem;
  }
  .sm\:-ml-8 {
    margin-left: -2rem;
  }
  .sm\:-ml-9 {
    margin-left: -2.25rem;
  }
  .sm\:-ml-10 {
    margin-left: -2.5rem;
  }
  .sm\:-ml-11 {
    margin-left: -2.75rem;
  }
  .sm\:-ml-12 {
    margin-left: -3rem;
  }
  .sm\:-ml-14 {
    margin-left: -3.5rem;
  }
  .sm\:-ml-16 {
    margin-left: -4rem;
  }
  .sm\:-ml-20 {
    margin-left: -5rem;
  }
  .sm\:-ml-24 {
    margin-left: -6rem;
  }
  .sm\:-ml-28 {
    margin-left: -7rem;
  }
  .sm\:-ml-32 {
    margin-left: -8rem;
  }
  .sm\:-ml-36 {
    margin-left: -9rem;
  }
  .sm\:-ml-40 {
    margin-left: -10rem;
  }
  .sm\:-ml-44 {
    margin-left: -11rem;
  }
  .sm\:-ml-48 {
    margin-left: -12rem;
  }
  .sm\:-ml-52 {
    margin-left: -13rem;
  }
  .sm\:-ml-56 {
    margin-left: -14rem;
  }
  .sm\:-ml-60 {
    margin-left: -15rem;
  }
  .sm\:-ml-64 {
    margin-left: -16rem;
  }
  .sm\:-ml-72 {
    margin-left: -18rem;
  }
  .sm\:-ml-80 {
    margin-left: -20rem;
  }
  .sm\:-ml-96 {
    margin-left: -24rem;
  }
  .sm\:-ml-px {
    margin-left: -1px;
  }
  .sm\:-ml-0\.5 {
    margin-left: -0.125rem;
  }
  .sm\:-ml-1\.5 {
    margin-left: -0.375rem;
  }
  .sm\:-ml-2\.5 {
    margin-left: -0.625rem;
  }
  .sm\:-ml-3\.5 {
    margin-left: -0.875rem;
  }
  .sm\:box-border {
    box-sizing: border-box;
  }
  .sm\:box-content {
    box-sizing: content-box;
  }
  .sm\:block {
    display: block;
  }
  .sm\:inline-block {
    display: inline-block;
  }
  .sm\:inline {
    display: inline;
  }
  .sm\:flex {
    display: flex;
  }
  .sm\:inline-flex {
    display: inline-flex;
  }
  .sm\:table {
    display: table;
  }
  .sm\:inline-table {
    display: inline-table;
  }
  .sm\:table-caption {
    display: table-caption;
  }
  .sm\:table-cell {
    display: table-cell;
  }
  .sm\:table-column {
    display: table-column;
  }
  .sm\:table-column-group {
    display: table-column-group;
  }
  .sm\:table-footer-group {
    display: table-footer-group;
  }
  .sm\:table-header-group {
    display: table-header-group;
  }
  .sm\:table-row-group {
    display: table-row-group;
  }
  .sm\:table-row {
    display: table-row;
  }
  .sm\:flow-root {
    display: flow-root;
  }
  .sm\:grid {
    display: grid;
  }
  .sm\:inline-grid {
    display: inline-grid;
  }
  .sm\:contents {
    display: contents;
  }
  .sm\:list-item {
    display: list-item;
  }
  .sm\:hidden {
    display: none;
  }
  .sm\:h-0 {
    height: 0;
  }
  .sm\:h-1 {
    height: 0.25rem;
  }
  .sm\:h-2 {
    height: 0.5rem;
  }
  .sm\:h-3 {
    height: 0.75rem;
  }
  .sm\:h-4 {
    height: 1rem;
  }
  .sm\:h-5 {
    height: 1.25rem;
  }
  .sm\:h-6 {
    height: 1.5rem;
  }
  .sm\:h-7 {
    height: 1.75rem;
  }
  .sm\:h-8 {
    height: 2rem;
  }
  .sm\:h-9 {
    height: 2.25rem;
  }
  .sm\:h-10 {
    height: 2.5rem;
  }
  .sm\:h-11 {
    height: 2.75rem;
  }
  .sm\:h-12 {
    height: 3rem;
  }
  .sm\:h-14 {
    height: 3.5rem;
  }
  .sm\:h-16 {
    height: 4rem;
  }
  .sm\:h-20 {
    height: 5rem;
  }
  .sm\:h-24 {
    height: 6rem;
  }
  .sm\:h-28 {
    height: 7rem;
  }
  .sm\:h-32 {
    height: 8rem;
  }
  .sm\:h-36 {
    height: 9rem;
  }
  .sm\:h-40 {
    height: 10rem;
  }
  .sm\:h-44 {
    height: 11rem;
  }
  .sm\:h-48 {
    height: 12rem;
  }
  .sm\:h-52 {
    height: 13rem;
  }
  .sm\:h-56 {
    height: 14rem;
  }
  .sm\:h-60 {
    height: 15rem;
  }
  .sm\:h-64 {
    height: 16rem;
  }
  .sm\:h-72 {
    height: 18rem;
  }
  .sm\:h-80 {
    height: 20rem;
  }
  .sm\:h-96 {
    height: 24rem;
  }
  .sm\:h-auto {
    height: auto;
  }
  .sm\:h-px {
    height: 1px;
  }
  .sm\:h-0\.5 {
    height: 0.125rem;
  }
  .sm\:h-1\.5 {
    height: 0.375rem;
  }
  .sm\:h-2\.5 {
    height: 0.625rem;
  }
  .sm\:h-3\.5 {
    height: 0.875rem;
  }
  .sm\:h-1\/2 {
    height: 50%;
  }
  .sm\:h-1\/3 {
    height: 33.333333%;
  }
  .sm\:h-2\/3 {
    height: 66.666667%;
  }
  .sm\:h-1\/4 {
    height: 25%;
  }
  .sm\:h-2\/4 {
    height: 50%;
  }
  .sm\:h-3\/4 {
    height: 75%;
  }
  .sm\:h-1\/5 {
    height: 20%;
  }
  .sm\:h-2\/5 {
    height: 40%;
  }
  .sm\:h-3\/5 {
    height: 60%;
  }
  .sm\:h-4\/5 {
    height: 80%;
  }
  .sm\:h-1\/6 {
    height: 16.666667%;
  }
  .sm\:h-2\/6 {
    height: 33.333333%;
  }
  .sm\:h-3\/6 {
    height: 50%;
  }
  .sm\:h-4\/6 {
    height: 66.666667%;
  }
  .sm\:h-5\/6 {
    height: 83.333333%;
  }
  .sm\:h-full {
    height: 100%;
  }
  .sm\:h-screen {
    height: 100vh;
  }
  .sm\:max-h-0 {
    max-height: 0;
  }
  .sm\:max-h-1 {
    max-height: 0.25rem;
  }
  .sm\:max-h-2 {
    max-height: 0.5rem;
  }
  .sm\:max-h-3 {
    max-height: 0.75rem;
  }
  .sm\:max-h-4 {
    max-height: 1rem;
  }
  .sm\:max-h-5 {
    max-height: 1.25rem;
  }
  .sm\:max-h-6 {
    max-height: 1.5rem;
  }
  .sm\:max-h-7 {
    max-height: 1.75rem;
  }
  .sm\:max-h-8 {
    max-height: 2rem;
  }
  .sm\:max-h-9 {
    max-height: 2.25rem;
  }
  .sm\:max-h-10 {
    max-height: 2.5rem;
  }
  .sm\:max-h-11 {
    max-height: 2.75rem;
  }
  .sm\:max-h-12 {
    max-height: 3rem;
  }
  .sm\:max-h-14 {
    max-height: 3.5rem;
  }
  .sm\:max-h-16 {
    max-height: 4rem;
  }
  .sm\:max-h-20 {
    max-height: 5rem;
  }
  .sm\:max-h-24 {
    max-height: 6rem;
  }
  .sm\:max-h-28 {
    max-height: 7rem;
  }
  .sm\:max-h-32 {
    max-height: 8rem;
  }
  .sm\:max-h-36 {
    max-height: 9rem;
  }
  .sm\:max-h-40 {
    max-height: 10rem;
  }
  .sm\:max-h-44 {
    max-height: 11rem;
  }
  .sm\:max-h-48 {
    max-height: 12rem;
  }
  .sm\:max-h-52 {
    max-height: 13rem;
  }
  .sm\:max-h-56 {
    max-height: 14rem;
  }
  .sm\:max-h-60 {
    max-height: 15rem;
  }
  .sm\:max-h-64 {
    max-height: 16rem;
  }
  .sm\:max-h-72 {
    max-height: 18rem;
  }
  .sm\:max-h-80 {
    max-height: 20rem;
  }
  .sm\:max-h-96 {
    max-height: 24rem;
  }
  .sm\:max-h-px {
    max-height: 1px;
  }
  .sm\:max-h-0\.5 {
    max-height: 0.125rem;
  }
  .sm\:max-h-1\.5 {
    max-height: 0.375rem;
  }
  .sm\:max-h-2\.5 {
    max-height: 0.625rem;
  }
  .sm\:max-h-3\.5 {
    max-height: 0.875rem;
  }
  .sm\:max-h-full {
    max-height: 100%;
  }
  .sm\:max-h-screen {
    max-height: 100vh;
  }
  .sm\:min-h-0 {
    min-height: 0;
  }
  .sm\:min-h-full {
    min-height: 100%;
  }
  .sm\:min-h-screen {
    min-height: 100vh;
  }
  .sm\:w-0 {
    width: 0;
  }
  .sm\:w-1 {
    width: 0.25rem;
  }
  .sm\:w-2 {
    width: 0.5rem;
  }
  .sm\:w-3 {
    width: 0.75rem;
  }
  .sm\:w-4 {
    width: 1rem;
  }
  .sm\:w-5 {
    width: 1.25rem;
  }
  .sm\:w-6 {
    width: 1.5rem;
  }
  .sm\:w-7 {
    width: 1.75rem;
  }
  .sm\:w-8 {
    width: 2rem;
  }
  .sm\:w-9 {
    width: 2.25rem;
  }
  .sm\:w-10 {
    width: 2.5rem;
  }
  .sm\:w-11 {
    width: 2.75rem;
  }
  .sm\:w-12 {
    width: 3rem;
  }
  .sm\:w-14 {
    width: 3.5rem;
  }
  .sm\:w-16 {
    width: 4rem;
  }
  .sm\:w-20 {
    width: 5rem;
  }
  .sm\:w-24 {
    width: 6rem;
  }
  .sm\:w-28 {
    width: 7rem;
  }
  .sm\:w-32 {
    width: 8rem;
  }
  .sm\:w-36 {
    width: 9rem;
  }
  .sm\:w-40 {
    width: 10rem;
  }
  .sm\:w-44 {
    width: 11rem;
  }
  .sm\:w-48 {
    width: 12rem;
  }
  .sm\:w-52 {
    width: 13rem;
  }
  .sm\:w-56 {
    width: 14rem;
  }
  .sm\:w-60 {
    width: 15rem;
  }
  .sm\:w-64 {
    width: 16rem;
  }
  .sm\:w-72 {
    width: 18rem;
  }
  .sm\:w-80 {
    width: 20rem;
  }
  .sm\:w-96 {
    width: 24rem;
  }
  .sm\:w-auto {
    width: auto;
  }
  .sm\:w-px {
    width: 1px;
  }
  .sm\:w-0\.5 {
    width: 0.125rem;
  }
  .sm\:w-1\.5 {
    width: 0.375rem;
  }
  .sm\:w-2\.5 {
    width: 0.625rem;
  }
  .sm\:w-3\.5 {
    width: 0.875rem;
  }
  .sm\:w-1\/2 {
    width: 50%;
  }
  .sm\:w-1\/3 {
    width: 33.333333%;
  }
  .sm\:w-2\/3 {
    width: 66.666667%;
  }
  .sm\:w-1\/4 {
    width: 25%;
  }
  .sm\:w-2\/4 {
    width: 50%;
  }
  .sm\:w-3\/4 {
    width: 75%;
  }
  .sm\:w-1\/5 {
    width: 20%;
  }
  .sm\:w-2\/5 {
    width: 40%;
  }
  .sm\:w-3\/5 {
    width: 60%;
  }
  .sm\:w-4\/5 {
    width: 80%;
  }
  .sm\:w-1\/6 {
    width: 16.666667%;
  }
  .sm\:w-2\/6 {
    width: 33.333333%;
  }
  .sm\:w-3\/6 {
    width: 50%;
  }
  .sm\:w-4\/6 {
    width: 66.666667%;
  }
  .sm\:w-5\/6 {
    width: 83.333333%;
  }
  .sm\:w-1\/12 {
    width: 8.333333%;
  }
  .sm\:w-2\/12 {
    width: 16.666667%;
  }
  .sm\:w-3\/12 {
    width: 25%;
  }
  .sm\:w-4\/12 {
    width: 33.333333%;
  }
  .sm\:w-5\/12 {
    width: 41.666667%;
  }
  .sm\:w-6\/12 {
    width: 50%;
  }
  .sm\:w-7\/12 {
    width: 58.333333%;
  }
  .sm\:w-8\/12 {
    width: 66.666667%;
  }
  .sm\:w-9\/12 {
    width: 75%;
  }
  .sm\:w-10\/12 {
    width: 83.333333%;
  }
  .sm\:w-11\/12 {
    width: 91.666667%;
  }
  .sm\:w-full {
    width: 100%;
  }
  .sm\:w-screen {
    width: 100vw;
  }
  .sm\:w-min {
    width: min-content;
  }
  .sm\:w-max {
    width: max-content;
  }
  .sm\:min-w-0 {
    min-width: 0;
  }
  .sm\:min-w-full {
    min-width: 100%;
  }
  .sm\:min-w-min {
    min-width: min-content;
  }
  .sm\:min-w-max {
    min-width: max-content;
  }
  .sm\:max-w-0 {
    max-width: 0;
  }
  .sm\:max-w-none {
    max-width: none;
  }
  .sm\:max-w-xs {
    max-width: 20rem;
  }
  .sm\:max-w-sm {
    max-width: 24rem;
  }
  .sm\:max-w-md {
    max-width: 28rem;
  }
  .sm\:max-w-lg {
    max-width: 32rem;
  }
  .sm\:max-w-xl {
    max-width: 36rem;
  }
  .sm\:max-w-2xl {
    max-width: 42rem;
  }
  .sm\:max-w-3xl {
    max-width: 48rem;
  }
  .sm\:max-w-4xl {
    max-width: 56rem;
  }
  .sm\:max-w-5xl {
    max-width: 64rem;
  }
  .sm\:max-w-6xl {
    max-width: 72rem;
  }
  .sm\:max-w-7xl {
    max-width: 80rem;
  }
  .sm\:max-w-full {
    max-width: 100%;
  }
  .sm\:max-w-min {
    max-width: min-content;
  }
  .sm\:max-w-max {
    max-width: max-content;
  }
  .sm\:max-w-prose {
    max-width: 65ch;
  }
  .sm\:max-w-screen-sm {
    max-width: 640px;
  }
  .sm\:max-w-screen-md {
    max-width: 768px;
  }
  .sm\:max-w-screen-lg {
    max-width: 1024px;
  }
  .sm\:max-w-screen-xl {
    max-width: 1280px;
  }
  .sm\:max-w-screen-2xl {
    max-width: 1536px;
  }
  .sm\:flex-1 {
    flex: 1 1 0%;
  }
  .sm\:flex-auto {
    flex: 1 1 auto;
  }
  .sm\:flex-initial {
    flex: 0 1 auto;
  }
  .sm\:flex-none {
    flex: none;
  }
  .sm\:flex-shrink-0 {
    flex-shrink: 0;
  }
  .sm\:flex-shrink {
    flex-shrink: 1;
  }
  .sm\:flex-grow-0 {
    flex-grow: 0;
  }
  .sm\:flex-grow {
    flex-grow: 1;
  }
  .sm\:table-auto {
    table-layout: auto;
  }
  .sm\:table-fixed {
    table-layout: fixed;
  }
  .sm\:border-collapse {
    border-collapse: collapse;
  }
  .sm\:border-separate {
    border-collapse: separate;
  }
  .sm\:origin-center {
    transform-origin: center;
  }
  .sm\:origin-top {
    transform-origin: top;
  }
  .sm\:origin-top-right {
    transform-origin: top right;
  }
  .sm\:origin-right {
    transform-origin: right;
  }
  .sm\:origin-bottom-right {
    transform-origin: bottom right;
  }
  .sm\:origin-bottom {
    transform-origin: bottom;
  }
  .sm\:origin-bottom-left {
    transform-origin: bottom left;
  }
  .sm\:origin-left {
    transform-origin: left;
  }
  .sm\:origin-top-left {
    transform-origin: top left;
  }
  .sm\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  .sm\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .sm\:transform-none {
    transform: none;
  }
  .sm\:translate-x-0 {
    --tw-translate-x: 0px;
  }
  .sm\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }
  .sm\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }
  .sm\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }
  .sm\:translate-x-4 {
    --tw-translate-x: 1rem;
  }
  .sm\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }
  .sm\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }
  .sm\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }
  .sm\:translate-x-8 {
    --tw-translate-x: 2rem;
  }
  .sm\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }
  .sm\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }
  .sm\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }
  .sm\:translate-x-12 {
    --tw-translate-x: 3rem;
  }
  .sm\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }
  .sm\:translate-x-16 {
    --tw-translate-x: 4rem;
  }
  .sm\:translate-x-20 {
    --tw-translate-x: 5rem;
  }
  .sm\:translate-x-24 {
    --tw-translate-x: 6rem;
  }
  .sm\:translate-x-28 {
    --tw-translate-x: 7rem;
  }
  .sm\:translate-x-32 {
    --tw-translate-x: 8rem;
  }
  .sm\:translate-x-36 {
    --tw-translate-x: 9rem;
  }
  .sm\:translate-x-40 {
    --tw-translate-x: 10rem;
  }
  .sm\:translate-x-44 {
    --tw-translate-x: 11rem;
  }
  .sm\:translate-x-48 {
    --tw-translate-x: 12rem;
  }
  .sm\:translate-x-52 {
    --tw-translate-x: 13rem;
  }
  .sm\:translate-x-56 {
    --tw-translate-x: 14rem;
  }
  .sm\:translate-x-60 {
    --tw-translate-x: 15rem;
  }
  .sm\:translate-x-64 {
    --tw-translate-x: 16rem;
  }
  .sm\:translate-x-72 {
    --tw-translate-x: 18rem;
  }
  .sm\:translate-x-80 {
    --tw-translate-x: 20rem;
  }
  .sm\:translate-x-96 {
    --tw-translate-x: 24rem;
  }
  .sm\:translate-x-px {
    --tw-translate-x: 1px;
  }
  .sm\:translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }
  .sm\:translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }
  .sm\:translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }
  .sm\:translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }
  .sm\:-translate-x-0 {
    --tw-translate-x: 0px;
  }
  .sm\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }
  .sm\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }
  .sm\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }
  .sm\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }
  .sm\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }
  .sm\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }
  .sm\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }
  .sm\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }
  .sm\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }
  .sm\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }
  .sm\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }
  .sm\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }
  .sm\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }
  .sm\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }
  .sm\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }
  .sm\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }
  .sm\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }
  .sm\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }
  .sm\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }
  .sm\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }
  .sm\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }
  .sm\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }
  .sm\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }
  .sm\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }
  .sm\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }
  .sm\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }
  .sm\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }
  .sm\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }
  .sm\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }
  .sm\:-translate-x-px {
    --tw-translate-x: -1px;
  }
  .sm\:-translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }
  .sm\:-translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }
  .sm\:-translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }
  .sm\:-translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }
  .sm\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }
  .sm\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }
  .sm\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }
  .sm\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }
  .sm\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }
  .sm\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }
  .sm\:translate-x-full {
    --tw-translate-x: 100%;
  }
  .sm\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }
  .sm\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }
  .sm\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }
  .sm\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }
  .sm\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }
  .sm\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }
  .sm\:-translate-x-full {
    --tw-translate-x: -100%;
  }
  .sm\:translate-y-0 {
    --tw-translate-y: 0px;
  }
  .sm\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }
  .sm\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }
  .sm\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }
  .sm\:translate-y-4 {
    --tw-translate-y: 1rem;
  }
  .sm\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }
  .sm\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }
  .sm\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }
  .sm\:translate-y-8 {
    --tw-translate-y: 2rem;
  }
  .sm\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }
  .sm\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }
  .sm\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }
  .sm\:translate-y-12 {
    --tw-translate-y: 3rem;
  }
  .sm\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }
  .sm\:translate-y-16 {
    --tw-translate-y: 4rem;
  }
  .sm\:translate-y-20 {
    --tw-translate-y: 5rem;
  }
  .sm\:translate-y-24 {
    --tw-translate-y: 6rem;
  }
  .sm\:translate-y-28 {
    --tw-translate-y: 7rem;
  }
  .sm\:translate-y-32 {
    --tw-translate-y: 8rem;
  }
  .sm\:translate-y-36 {
    --tw-translate-y: 9rem;
  }
  .sm\:translate-y-40 {
    --tw-translate-y: 10rem;
  }
  .sm\:translate-y-44 {
    --tw-translate-y: 11rem;
  }
  .sm\:translate-y-48 {
    --tw-translate-y: 12rem;
  }
  .sm\:translate-y-52 {
    --tw-translate-y: 13rem;
  }
  .sm\:translate-y-56 {
    --tw-translate-y: 14rem;
  }
  .sm\:translate-y-60 {
    --tw-translate-y: 15rem;
  }
  .sm\:translate-y-64 {
    --tw-translate-y: 16rem;
  }
  .sm\:translate-y-72 {
    --tw-translate-y: 18rem;
  }
  .sm\:translate-y-80 {
    --tw-translate-y: 20rem;
  }
  .sm\:translate-y-96 {
    --tw-translate-y: 24rem;
  }
  .sm\:translate-y-px {
    --tw-translate-y: 1px;
  }
  .sm\:translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }
  .sm\:translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }
  .sm\:translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }
  .sm\:translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }
  .sm\:-translate-y-0 {
    --tw-translate-y: 0px;
  }
  .sm\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }
  .sm\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }
  .sm\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }
  .sm\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }
  .sm\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }
  .sm\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }
  .sm\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }
  .sm\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }
  .sm\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }
  .sm\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }
  .sm\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }
  .sm\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }
  .sm\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }
  .sm\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }
  .sm\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }
  .sm\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }
  .sm\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }
  .sm\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }
  .sm\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }
  .sm\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }
  .sm\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }
  .sm\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }
  .sm\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }
  .sm\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }
  .sm\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }
  .sm\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }
  .sm\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }
  .sm\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }
  .sm\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }
  .sm\:-translate-y-px {
    --tw-translate-y: -1px;
  }
  .sm\:-translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }
  .sm\:-translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }
  .sm\:-translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }
  .sm\:-translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }
  .sm\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }
  .sm\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }
  .sm\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }
  .sm\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }
  .sm\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }
  .sm\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }
  .sm\:translate-y-full {
    --tw-translate-y: 100%;
  }
  .sm\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }
  .sm\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }
  .sm\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }
  .sm\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }
  .sm\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }
  .sm\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }
  .sm\:-translate-y-full {
    --tw-translate-y: -100%;
  }
  .sm\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .sm\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }
  .sm\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }
  .sm\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }
  .sm\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }
  .sm\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }
  .sm\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }
  .sm\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }
  .sm\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }
  .sm\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }
  .sm\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }
  .sm\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }
  .sm\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }
  .sm\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }
  .sm\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }
  .sm\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }
  .sm\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }
  .sm\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }
  .sm\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }
  .sm\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }
  .sm\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }
  .sm\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }
  .sm\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }
  .sm\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }
  .sm\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }
  .sm\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }
  .sm\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }
  .sm\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }
  .sm\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }
  .sm\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }
  .sm\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }
  .sm\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }
  .sm\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }
  .sm\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }
  .sm\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }
  .sm\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .sm\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }
  .sm\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }
  .sm\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }
  .sm\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }
  .sm\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }
  .sm\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }
  .sm\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }
  .sm\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }
  .sm\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }
  .sm\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }
  .sm\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }
  .sm\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }
  .sm\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }
  .sm\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }
  .sm\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }
  .sm\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }
  .sm\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }
  .sm\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }
  .sm\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }
  .sm\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }
  .sm\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }
  .sm\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }
  .sm\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }
  .sm\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }
  .sm\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }
  .sm\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }
  .sm\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }
  .sm\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }
  .sm\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }
  .sm\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }
  .sm\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }
  .sm\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }
  .sm\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }
  .sm\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }
  .sm\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }
  .sm\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }
  .sm\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }
  .sm\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }
  .sm\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }
  .sm\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }
  .sm\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }
  .sm\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }
  .sm\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }
  .sm\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }
  .sm\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }
  .sm\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }
  .sm\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }
  .sm\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }
  .sm\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .sm\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }
  .sm\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }
  .sm\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }
  .sm\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }
  .sm\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }
  .sm\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }
  .sm\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }
  .sm\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }
  .sm\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }
  .sm\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }
  .sm\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }
  .sm\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }
  .sm\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }
  .sm\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }
  .sm\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }
  .sm\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }
  .sm\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }
  .sm\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }
  .sm\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }
  .sm\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }
  .sm\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }
  .sm\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }
  .sm\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }
  .sm\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }
  .sm\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }
  .sm\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }
  .sm\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }
  .sm\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }
  .sm\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }
  .sm\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }
  .sm\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }
  .sm\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }
  .sm\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }
  .sm\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }
  .sm\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .sm\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }
  .sm\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }
  .sm\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }
  .sm\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }
  .sm\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }
  .sm\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }
  .sm\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }
  .sm\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }
  .sm\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }
  .sm\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }
  .sm\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }
  .sm\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }
  .sm\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }
  .sm\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }
  .sm\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }
  .sm\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }
  .sm\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }
  .sm\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }
  .sm\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }
  .sm\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }
  .sm\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }
  .sm\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }
  .sm\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }
  .sm\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }
  .sm\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }
  .sm\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }
  .sm\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }
  .sm\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }
  .sm\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }
  .sm\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }
  .sm\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }
  .sm\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }
  .sm\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }
  .sm\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }
  .sm\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }
  .sm\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }
  .sm\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }
  .sm\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }
  .sm\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }
  .sm\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }
  .sm\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }
  .sm\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }
  .sm\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }
  .sm\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }
  .sm\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }
  .sm\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }
  .sm\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }
  .sm\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }
  .sm\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .sm\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }
  .sm\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }
  .sm\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }
  .sm\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }
  .sm\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }
  .sm\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }
  .sm\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }
  .sm\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }
  .sm\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }
  .sm\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }
  .sm\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }
  .sm\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }
  .sm\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }
  .sm\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }
  .sm\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }
  .sm\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }
  .sm\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }
  .sm\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }
  .sm\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }
  .sm\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }
  .sm\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }
  .sm\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }
  .sm\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }
  .sm\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }
  .sm\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }
  .sm\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }
  .sm\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }
  .sm\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }
  .sm\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }
  .sm\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }
  .sm\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }
  .sm\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }
  .sm\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }
  .sm\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }
  .sm\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .sm\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }
  .sm\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }
  .sm\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }
  .sm\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }
  .sm\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }
  .sm\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }
  .sm\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }
  .sm\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }
  .sm\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }
  .sm\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }
  .sm\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }
  .sm\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }
  .sm\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }
  .sm\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }
  .sm\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }
  .sm\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }
  .sm\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }
  .sm\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }
  .sm\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }
  .sm\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }
  .sm\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }
  .sm\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }
  .sm\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }
  .sm\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }
  .sm\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }
  .sm\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }
  .sm\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }
  .sm\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }
  .sm\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }
  .sm\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }
  .sm\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }
  .sm\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }
  .sm\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }
  .sm\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }
  .sm\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }
  .sm\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }
  .sm\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }
  .sm\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }
  .sm\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }
  .sm\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }
  .sm\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }
  .sm\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }
  .sm\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }
  .sm\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }
  .sm\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }
  .sm\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }
  .sm\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }
  .sm\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }
  .sm\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .sm\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }
  .sm\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }
  .sm\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }
  .sm\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }
  .sm\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }
  .sm\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }
  .sm\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }
  .sm\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }
  .sm\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }
  .sm\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }
  .sm\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }
  .sm\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }
  .sm\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }
  .sm\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }
  .sm\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }
  .sm\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }
  .sm\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }
  .sm\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }
  .sm\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }
  .sm\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }
  .sm\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }
  .sm\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }
  .sm\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }
  .sm\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }
  .sm\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }
  .sm\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }
  .sm\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }
  .sm\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }
  .sm\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }
  .sm\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }
  .sm\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }
  .sm\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }
  .sm\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }
  .sm\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }
  .sm\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .sm\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }
  .sm\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }
  .sm\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }
  .sm\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }
  .sm\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }
  .sm\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }
  .sm\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }
  .sm\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }
  .sm\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }
  .sm\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }
  .sm\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }
  .sm\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }
  .sm\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }
  .sm\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }
  .sm\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }
  .sm\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }
  .sm\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }
  .sm\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }
  .sm\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }
  .sm\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }
  .sm\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }
  .sm\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }
  .sm\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }
  .sm\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }
  .sm\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }
  .sm\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }
  .sm\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }
  .sm\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }
  .sm\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }
  .sm\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }
  .sm\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }
  .sm\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }
  .sm\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }
  .sm\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }
  .sm\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }
  .sm\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }
  .sm\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }
  .sm\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }
  .sm\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }
  .sm\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }
  .sm\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }
  .sm\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }
  .sm\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }
  .sm\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }
  .sm\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }
  .sm\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }
  .sm\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }
  .sm\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }
  .sm\:rotate-0 {
    --tw-rotate: 0deg;
  }
  .sm\:rotate-1 {
    --tw-rotate: 1deg;
  }
  .sm\:rotate-2 {
    --tw-rotate: 2deg;
  }
  .sm\:rotate-3 {
    --tw-rotate: 3deg;
  }
  .sm\:rotate-6 {
    --tw-rotate: 6deg;
  }
  .sm\:rotate-12 {
    --tw-rotate: 12deg;
  }
  .sm\:rotate-45 {
    --tw-rotate: 45deg;
  }
  .sm\:rotate-90 {
    --tw-rotate: 90deg;
  }
  .sm\:rotate-180 {
    --tw-rotate: 180deg;
  }
  .sm\:-rotate-180 {
    --tw-rotate: -180deg;
  }
  .sm\:-rotate-90 {
    --tw-rotate: -90deg;
  }
  .sm\:-rotate-45 {
    --tw-rotate: -45deg;
  }
  .sm\:-rotate-12 {
    --tw-rotate: -12deg;
  }
  .sm\:-rotate-6 {
    --tw-rotate: -6deg;
  }
  .sm\:-rotate-3 {
    --tw-rotate: -3deg;
  }
  .sm\:-rotate-2 {
    --tw-rotate: -2deg;
  }
  .sm\:-rotate-1 {
    --tw-rotate: -1deg;
  }
  .sm\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }
  .sm\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }
  .sm\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }
  .sm\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }
  .sm\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }
  .sm\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }
  .sm\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }
  .sm\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }
  .sm\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }
  .sm\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }
  .sm\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }
  .sm\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }
  .sm\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }
  .sm\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }
  .sm\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }
  .sm\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }
  .sm\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }
  .sm\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }
  .sm\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }
  .sm\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }
  .sm\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }
  .sm\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }
  .sm\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }
  .sm\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }
  .sm\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }
  .sm\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }
  .sm\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }
  .sm\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }
  .sm\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }
  .sm\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }
  .sm\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }
  .sm\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }
  .sm\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }
  .sm\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }
  .sm\:skew-x-0 {
    --tw-skew-x: 0deg;
  }
  .sm\:skew-x-1 {
    --tw-skew-x: 1deg;
  }
  .sm\:skew-x-2 {
    --tw-skew-x: 2deg;
  }
  .sm\:skew-x-3 {
    --tw-skew-x: 3deg;
  }
  .sm\:skew-x-6 {
    --tw-skew-x: 6deg;
  }
  .sm\:skew-x-12 {
    --tw-skew-x: 12deg;
  }
  .sm\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }
  .sm\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }
  .sm\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }
  .sm\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }
  .sm\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }
  .sm\:skew-y-0 {
    --tw-skew-y: 0deg;
  }
  .sm\:skew-y-1 {
    --tw-skew-y: 1deg;
  }
  .sm\:skew-y-2 {
    --tw-skew-y: 2deg;
  }
  .sm\:skew-y-3 {
    --tw-skew-y: 3deg;
  }
  .sm\:skew-y-6 {
    --tw-skew-y: 6deg;
  }
  .sm\:skew-y-12 {
    --tw-skew-y: 12deg;
  }
  .sm\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }
  .sm\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }
  .sm\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }
  .sm\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }
  .sm\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }
  .sm\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }
  .sm\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }
  .sm\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }
  .sm\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }
  .sm\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }
  .sm\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }
  .sm\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }
  .sm\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }
  .sm\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }
  .sm\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }
  .sm\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }
  .sm\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }
  .sm\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }
  .sm\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }
  .sm\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }
  .sm\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }
  .sm\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }
  .sm\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }
  .sm\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }
  .sm\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }
  .sm\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }
  .sm\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }
  .sm\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }
  .sm\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }
  .sm\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }
  .sm\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }
  .sm\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }
  .sm\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }
  .sm\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }
  .sm\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }
  .sm\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }
  .sm\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }
  .sm\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }
  .sm\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }
  .sm\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }
  .sm\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }
  .sm\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }
  .sm\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }
  .sm\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }
  .sm\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }
  .sm\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }
  .sm\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }
  .sm\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }
  .sm\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }
  .sm\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .sm\:scale-50 {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .sm\:scale-75 {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .sm\:scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .sm\:scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .sm\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .sm\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .sm\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .sm\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .sm\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .sm\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .sm\:hover\:scale-50:hover {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .sm\:hover\:scale-75:hover {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .sm\:hover\:scale-90:hover {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .sm\:hover\:scale-95:hover {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .sm\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .sm\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .sm\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .sm\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .sm\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .sm\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .sm\:focus\:scale-50:focus {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .sm\:focus\:scale-75:focus {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .sm\:focus\:scale-90:focus {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .sm\:focus\:scale-95:focus {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .sm\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .sm\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .sm\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .sm\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .sm\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .sm\:scale-x-0 {
    --tw-scale-x: 0;
  }
  .sm\:scale-x-50 {
    --tw-scale-x: 0.5;
  }
  .sm\:scale-x-75 {
    --tw-scale-x: 0.75;
  }
  .sm\:scale-x-90 {
    --tw-scale-x: 0.9;
  }
  .sm\:scale-x-95 {
    --tw-scale-x: 0.95;
  }
  .sm\:scale-x-100 {
    --tw-scale-x: 1;
  }
  .sm\:scale-x-105 {
    --tw-scale-x: 1.05;
  }
  .sm\:scale-x-110 {
    --tw-scale-x: 1.1;
  }
  .sm\:scale-x-125 {
    --tw-scale-x: 1.25;
  }
  .sm\:scale-x-150 {
    --tw-scale-x: 1.5;
  }
  .sm\:scale-y-0 {
    --tw-scale-y: 0;
  }
  .sm\:scale-y-50 {
    --tw-scale-y: 0.5;
  }
  .sm\:scale-y-75 {
    --tw-scale-y: 0.75;
  }
  .sm\:scale-y-90 {
    --tw-scale-y: 0.9;
  }
  .sm\:scale-y-95 {
    --tw-scale-y: 0.95;
  }
  .sm\:scale-y-100 {
    --tw-scale-y: 1;
  }
  .sm\:scale-y-105 {
    --tw-scale-y: 1.05;
  }
  .sm\:scale-y-110 {
    --tw-scale-y: 1.1;
  }
  .sm\:scale-y-125 {
    --tw-scale-y: 1.25;
  }
  .sm\:scale-y-150 {
    --tw-scale-y: 1.5;
  }
  .sm\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }
  .sm\:hover\:scale-x-50:hover {
    --tw-scale-x: 0.5;
  }
  .sm\:hover\:scale-x-75:hover {
    --tw-scale-x: 0.75;
  }
  .sm\:hover\:scale-x-90:hover {
    --tw-scale-x: 0.9;
  }
  .sm\:hover\:scale-x-95:hover {
    --tw-scale-x: 0.95;
  }
  .sm\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }
  .sm\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }
  .sm\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }
  .sm\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }
  .sm\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }
  .sm\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }
  .sm\:hover\:scale-y-50:hover {
    --tw-scale-y: 0.5;
  }
  .sm\:hover\:scale-y-75:hover {
    --tw-scale-y: 0.75;
  }
  .sm\:hover\:scale-y-90:hover {
    --tw-scale-y: 0.9;
  }
  .sm\:hover\:scale-y-95:hover {
    --tw-scale-y: 0.95;
  }
  .sm\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }
  .sm\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }
  .sm\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }
  .sm\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }
  .sm\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }
  .sm\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }
  .sm\:focus\:scale-x-50:focus {
    --tw-scale-x: 0.5;
  }
  .sm\:focus\:scale-x-75:focus {
    --tw-scale-x: 0.75;
  }
  .sm\:focus\:scale-x-90:focus {
    --tw-scale-x: 0.9;
  }
  .sm\:focus\:scale-x-95:focus {
    --tw-scale-x: 0.95;
  }
  .sm\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }
  .sm\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }
  .sm\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }
  .sm\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }
  .sm\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }
  .sm\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }
  .sm\:focus\:scale-y-50:focus {
    --tw-scale-y: 0.5;
  }
  .sm\:focus\:scale-y-75:focus {
    --tw-scale-y: 0.75;
  }
  .sm\:focus\:scale-y-90:focus {
    --tw-scale-y: 0.9;
  }
  .sm\:focus\:scale-y-95:focus {
    --tw-scale-y: 0.95;
  }
  .sm\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }
  .sm\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }
  .sm\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }
  .sm\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }
  .sm\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }
  .sm\:animate-none {
    animation: none;
  }
  .sm\:animate-spin {
    animation: spin 1s linear infinite;
  }
  .sm\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .sm\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .sm\:animate-bounce {
    animation: bounce 1s infinite;
  }
  .sm\:cursor-auto {
    cursor: auto;
  }
  .sm\:cursor-default {
    cursor: default;
  }
  .sm\:cursor-pointer {
    cursor: pointer;
  }
  .sm\:cursor-wait {
    cursor: wait;
  }
  .sm\:cursor-text {
    cursor: text;
  }
  .sm\:cursor-move {
    cursor: move;
  }
  .sm\:cursor-help {
    cursor: help;
  }
  .sm\:cursor-not-allowed {
    cursor: not-allowed;
  }
  .sm\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }
  .sm\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }
  .sm\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }
  .sm\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }
  .sm\:resize-none {
    resize: none;
  }
  .sm\:resize-y {
    resize: vertical;
  }
  .sm\:resize-x {
    resize: horizontal;
  }
  .sm\:resize {
    resize: both;
  }
  .sm\:list-inside {
    list-style-position: inside;
  }
  .sm\:list-outside {
    list-style-position: outside;
  }
  .sm\:list-none {
    list-style-type: none;
  }
  .sm\:list-disc {
    list-style-type: disc;
  }
  .sm\:list-decimal {
    list-style-type: decimal;
  }
  .sm\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }
  .sm\:auto-cols-auto {
    grid-auto-columns: auto;
  }
  .sm\:auto-cols-min {
    grid-auto-columns: min-content;
  }
  .sm\:auto-cols-max {
    grid-auto-columns: max-content;
  }
  .sm\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }
  .sm\:grid-flow-row {
    grid-auto-flow: row;
  }
  .sm\:grid-flow-col {
    grid-auto-flow: column;
  }
  .sm\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }
  .sm\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }
  .sm\:auto-rows-auto {
    grid-auto-rows: auto;
  }
  .sm\:auto-rows-min {
    grid-auto-rows: min-content;
  }
  .sm\:auto-rows-max {
    grid-auto-rows: max-content;
  }
  .sm\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }
  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .sm\:grid-cols-none {
    grid-template-columns: none;
  }
  .sm\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  .sm\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .sm\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .sm\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .sm\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .sm\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .sm\:grid-rows-none {
    grid-template-rows: none;
  }
  .sm\:flex-row {
    flex-direction: row;
  }
  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .sm\:flex-col {
    flex-direction: column;
  }
  .sm\:flex-col-reverse {
    flex-direction: column-reverse;
  }
  .sm\:flex-wrap {
    flex-wrap: wrap;
  }
  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }
  .sm\:place-content-center {
    place-content: center;
  }
  .sm\:place-content-start {
    place-content: start;
  }
  .sm\:place-content-end {
    place-content: end;
  }
  .sm\:place-content-between {
    place-content: space-between;
  }
  .sm\:place-content-around {
    place-content: space-around;
  }
  .sm\:place-content-evenly {
    place-content: space-evenly;
  }
  .sm\:place-content-stretch {
    place-content: stretch;
  }
  .sm\:place-items-start {
    place-items: start;
  }
  .sm\:place-items-end {
    place-items: end;
  }
  .sm\:place-items-center {
    place-items: center;
  }
  .sm\:place-items-stretch {
    place-items: stretch;
  }
  .sm\:content-center {
    align-content: center;
  }
  .sm\:content-start {
    align-content: flex-start;
  }
  .sm\:content-end {
    align-content: flex-end;
  }
  .sm\:content-between {
    align-content: space-between;
  }
  .sm\:content-around {
    align-content: space-around;
  }
  .sm\:content-evenly {
    align-content: space-evenly;
  }
  .sm\:items-start {
    align-items: flex-start;
  }
  .sm\:items-end {
    align-items: flex-end;
  }
  .sm\:items-center {
    align-items: center;
  }
  .sm\:items-baseline {
    align-items: baseline;
  }
  .sm\:items-stretch {
    align-items: stretch;
  }
  .sm\:justify-start {
    justify-content: flex-start;
  }
  .sm\:justify-end {
    justify-content: flex-end;
  }
  .sm\:justify-center {
    justify-content: center;
  }
  .sm\:justify-between {
    justify-content: space-between;
  }
  .sm\:justify-around {
    justify-content: space-around;
  }
  .sm\:justify-evenly {
    justify-content: space-evenly;
  }
  .sm\:justify-items-start {
    justify-items: start;
  }
  .sm\:justify-items-end {
    justify-items: end;
  }
  .sm\:justify-items-center {
    justify-items: center;
  }
  .sm\:justify-items-stretch {
    justify-items: stretch;
  }
  .sm\:gap-0 {
    gap: 0;
  }
  .sm\:gap-1 {
    gap: 0.25rem;
  }
  .sm\:gap-2 {
    gap: 0.5rem;
  }
  .sm\:gap-3 {
    gap: 0.75rem;
  }
  .sm\:gap-4 {
    gap: 1rem;
  }
  .sm\:gap-5 {
    gap: 1.25rem;
  }
  .sm\:gap-6 {
    gap: 1.5rem;
  }
  .sm\:gap-7 {
    gap: 1.75rem;
  }
  .sm\:gap-8 {
    gap: 2rem;
  }
  .sm\:gap-9 {
    gap: 2.25rem;
  }
  .sm\:gap-10 {
    gap: 2.5rem;
  }
  .sm\:gap-11 {
    gap: 2.75rem;
  }
  .sm\:gap-12 {
    gap: 3rem;
  }
  .sm\:gap-14 {
    gap: 3.5rem;
  }
  .sm\:gap-16 {
    gap: 4rem;
  }
  .sm\:gap-20 {
    gap: 5rem;
  }
  .sm\:gap-24 {
    gap: 6rem;
  }
  .sm\:gap-28 {
    gap: 7rem;
  }
  .sm\:gap-32 {
    gap: 8rem;
  }
  .sm\:gap-36 {
    gap: 9rem;
  }
  .sm\:gap-40 {
    gap: 10rem;
  }
  .sm\:gap-44 {
    gap: 11rem;
  }
  .sm\:gap-48 {
    gap: 12rem;
  }
  .sm\:gap-52 {
    gap: 13rem;
  }
  .sm\:gap-56 {
    gap: 14rem;
  }
  .sm\:gap-60 {
    gap: 15rem;
  }
  .sm\:gap-64 {
    gap: 16rem;
  }
  .sm\:gap-72 {
    gap: 18rem;
  }
  .sm\:gap-80 {
    gap: 20rem;
  }
  .sm\:gap-96 {
    gap: 24rem;
  }
  .sm\:gap-px {
    gap: 1px;
  }
  .sm\:gap-0\.5 {
    gap: 0.125rem;
  }
  .sm\:gap-1\.5 {
    gap: 0.375rem;
  }
  .sm\:gap-2\.5 {
    gap: 0.625rem;
  }
  .sm\:gap-3\.5 {
    gap: 0.875rem;
  }
  .sm\:gap-x-0 {
    column-gap: 0;
  }
  .sm\:gap-x-1 {
    column-gap: 0.25rem;
  }
  .sm\:gap-x-2 {
    column-gap: 0.5rem;
  }
  .sm\:gap-x-3 {
    column-gap: 0.75rem;
  }
  .sm\:gap-x-4 {
    column-gap: 1rem;
  }
  .sm\:gap-x-5 {
    column-gap: 1.25rem;
  }
  .sm\:gap-x-6 {
    column-gap: 1.5rem;
  }
  .sm\:gap-x-7 {
    column-gap: 1.75rem;
  }
  .sm\:gap-x-8 {
    column-gap: 2rem;
  }
  .sm\:gap-x-9 {
    column-gap: 2.25rem;
  }
  .sm\:gap-x-10 {
    column-gap: 2.5rem;
  }
  .sm\:gap-x-11 {
    column-gap: 2.75rem;
  }
  .sm\:gap-x-12 {
    column-gap: 3rem;
  }
  .sm\:gap-x-14 {
    column-gap: 3.5rem;
  }
  .sm\:gap-x-16 {
    column-gap: 4rem;
  }
  .sm\:gap-x-20 {
    column-gap: 5rem;
  }
  .sm\:gap-x-24 {
    column-gap: 6rem;
  }
  .sm\:gap-x-28 {
    column-gap: 7rem;
  }
  .sm\:gap-x-32 {
    column-gap: 8rem;
  }
  .sm\:gap-x-36 {
    column-gap: 9rem;
  }
  .sm\:gap-x-40 {
    column-gap: 10rem;
  }
  .sm\:gap-x-44 {
    column-gap: 11rem;
  }
  .sm\:gap-x-48 {
    column-gap: 12rem;
  }
  .sm\:gap-x-52 {
    column-gap: 13rem;
  }
  .sm\:gap-x-56 {
    column-gap: 14rem;
  }
  .sm\:gap-x-60 {
    column-gap: 15rem;
  }
  .sm\:gap-x-64 {
    column-gap: 16rem;
  }
  .sm\:gap-x-72 {
    column-gap: 18rem;
  }
  .sm\:gap-x-80 {
    column-gap: 20rem;
  }
  .sm\:gap-x-96 {
    column-gap: 24rem;
  }
  .sm\:gap-x-px {
    column-gap: 1px;
  }
  .sm\:gap-x-0\.5 {
    column-gap: 0.125rem;
  }
  .sm\:gap-x-1\.5 {
    column-gap: 0.375rem;
  }
  .sm\:gap-x-2\.5 {
    column-gap: 0.625rem;
  }
  .sm\:gap-x-3\.5 {
    column-gap: 0.875rem;
  }
  .sm\:gap-y-0 {
    row-gap: 0;
  }
  .sm\:gap-y-1 {
    row-gap: 0.25rem;
  }
  .sm\:gap-y-2 {
    row-gap: 0.5rem;
  }
  .sm\:gap-y-3 {
    row-gap: 0.75rem;
  }
  .sm\:gap-y-4 {
    row-gap: 1rem;
  }
  .sm\:gap-y-5 {
    row-gap: 1.25rem;
  }
  .sm\:gap-y-6 {
    row-gap: 1.5rem;
  }
  .sm\:gap-y-7 {
    row-gap: 1.75rem;
  }
  .sm\:gap-y-8 {
    row-gap: 2rem;
  }
  .sm\:gap-y-9 {
    row-gap: 2.25rem;
  }
  .sm\:gap-y-10 {
    row-gap: 2.5rem;
  }
  .sm\:gap-y-11 {
    row-gap: 2.75rem;
  }
  .sm\:gap-y-12 {
    row-gap: 3rem;
  }
  .sm\:gap-y-14 {
    row-gap: 3.5rem;
  }
  .sm\:gap-y-16 {
    row-gap: 4rem;
  }
  .sm\:gap-y-20 {
    row-gap: 5rem;
  }
  .sm\:gap-y-24 {
    row-gap: 6rem;
  }
  .sm\:gap-y-28 {
    row-gap: 7rem;
  }
  .sm\:gap-y-32 {
    row-gap: 8rem;
  }
  .sm\:gap-y-36 {
    row-gap: 9rem;
  }
  .sm\:gap-y-40 {
    row-gap: 10rem;
  }
  .sm\:gap-y-44 {
    row-gap: 11rem;
  }
  .sm\:gap-y-48 {
    row-gap: 12rem;
  }
  .sm\:gap-y-52 {
    row-gap: 13rem;
  }
  .sm\:gap-y-56 {
    row-gap: 14rem;
  }
  .sm\:gap-y-60 {
    row-gap: 15rem;
  }
  .sm\:gap-y-64 {
    row-gap: 16rem;
  }
  .sm\:gap-y-72 {
    row-gap: 18rem;
  }
  .sm\:gap-y-80 {
    row-gap: 20rem;
  }
  .sm\:gap-y-96 {
    row-gap: 24rem;
  }
  .sm\:gap-y-px {
    row-gap: 1px;
  }
  .sm\:gap-y-0\.5 {
    row-gap: 0.125rem;
  }
  .sm\:gap-y-1\.5 {
    row-gap: 0.375rem;
  }
  .sm\:gap-y-2\.5 {
    row-gap: 0.625rem;
  }
  .sm\:gap-y-3\.5 {
    row-gap: 0.875rem;
  }
  .sm\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .sm\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .sm\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }
  .sm\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }
  .sm\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }
  .sm\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }
  .sm\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }
  .sm\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .sm\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .sm\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .sm\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .sm\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .sm\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }
  .sm\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }
  .sm\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }
  .sm\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }
  .sm\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }
  .sm\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }
  .sm\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }
  .sm\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }
  .sm\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }
  .sm\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }
  .sm\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }
  .sm\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }
  .sm\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }
  .sm\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }
  .sm\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }
  .sm\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }
  .sm\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }
  .sm\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }
  .sm\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }
  .sm\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }
  .sm\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }
  .sm\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }
  .sm\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }
  .sm\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }
  .sm\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }
  .sm\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }
  .sm\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }
  .sm\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }
  .sm\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }
  .sm\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }
  .sm\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }
  .sm\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }
  .sm\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }
  .sm\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }
  .sm\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }
  .sm\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }
  .sm\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }
  .sm\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }
  .sm\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }
  .sm\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }
  .sm\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }
  .sm\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }
  .sm\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }
  .sm\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }
  .sm\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }
  .sm\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }
  .sm\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }
  .sm\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }
  .sm\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }
  .sm\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }
  .sm\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }
  .sm\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }
  .sm\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }
  .sm\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }
  .sm\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }
  .sm\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }
  .sm\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }
  .sm\:place-self-auto {
    place-self: auto;
  }
  .sm\:place-self-start {
    place-self: start;
  }
  .sm\:place-self-end {
    place-self: end;
  }
  .sm\:place-self-center {
    place-self: center;
  }
  .sm\:place-self-stretch {
    place-self: stretch;
  }
  .sm\:self-auto {
    align-self: auto;
  }
  .sm\:self-start {
    align-self: flex-start;
  }
  .sm\:self-end {
    align-self: flex-end;
  }
  .sm\:self-center {
    align-self: center;
  }
  .sm\:self-stretch {
    align-self: stretch;
  }
  .sm\:self-baseline {
    align-self: baseline;
  }
  .sm\:justify-self-auto {
    justify-self: auto;
  }
  .sm\:justify-self-start {
    justify-self: start;
  }
  .sm\:justify-self-end {
    justify-self: end;
  }
  .sm\:justify-self-center {
    justify-self: center;
  }
  .sm\:justify-self-stretch {
    justify-self: stretch;
  }
  .sm\:overflow-auto {
    overflow: auto;
  }
  .sm\:overflow-hidden {
    overflow: hidden;
  }
  .sm\:overflow-visible {
    overflow: visible;
  }
  .sm\:overflow-scroll {
    overflow: scroll;
  }
  .sm\:overflow-x-auto {
    overflow-x: auto;
  }
  .sm\:overflow-y-auto {
    overflow-y: auto;
  }
  .sm\:overflow-x-hidden {
    overflow-x: hidden;
  }
  .sm\:overflow-y-hidden {
    overflow-y: hidden;
  }
  .sm\:overflow-x-visible {
    overflow-x: visible;
  }
  .sm\:overflow-y-visible {
    overflow-y: visible;
  }
  .sm\:overflow-x-scroll {
    overflow-x: scroll;
  }
  .sm\:overflow-y-scroll {
    overflow-y: scroll;
  }
  .sm\:overscroll-auto {
    overscroll-behavior: auto;
  }
  .sm\:overscroll-contain {
    overscroll-behavior: contain;
  }
  .sm\:overscroll-none {
    overscroll-behavior: none;
  }
  .sm\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }
  .sm\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }
  .sm\:overscroll-y-none {
    overscroll-behavior-y: none;
  }
  .sm\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }
  .sm\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }
  .sm\:overscroll-x-none {
    overscroll-behavior-x: none;
  }
  .sm\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .sm\:overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .sm\:overflow-clip {
    text-overflow: clip;
  }
  .sm\:whitespace-normal {
    white-space: normal;
  }
  .sm\:whitespace-nowrap {
    white-space: nowrap;
  }
  .sm\:whitespace-pre {
    white-space: pre;
  }
  .sm\:whitespace-pre-line {
    white-space: pre-line;
  }
  .sm\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }
  .sm\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .sm\:break-words {
    overflow-wrap: break-word;
  }
  .sm\:break-all {
    word-break: break-all;
  }
  .sm\:rounded-none {
    border-radius: 0;
  }
  .sm\:rounded-sm {
    border-radius: 0.125rem;
  }
  .sm\:rounded {
    border-radius: 0.25rem;
  }
  .sm\:rounded-md {
    border-radius: 0.375rem;
  }
  .sm\:rounded-lg {
    border-radius: 0.5rem;
  }
  .sm\:rounded-xl {
    border-radius: 0.75rem;
  }
  .sm\:rounded-2xl {
    border-radius: 1rem;
  }
  .sm\:rounded-3xl {
    border-radius: 1.5rem;
  }
  .sm\:rounded-full {
    border-radius: 9999px;
  }
  .sm\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .sm\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }
  .sm\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .sm\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .sm\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .sm\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .sm\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .sm\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
  .sm\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }
  .sm\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .sm\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
  .sm\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .sm\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .sm\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .sm\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .sm\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .sm\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  .sm\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }
  .sm\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .sm\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .sm\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .sm\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .sm\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .sm\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .sm\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .sm\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .sm\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .sm\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .sm\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .sm\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .sm\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .sm\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .sm\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .sm\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .sm\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .sm\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .sm\:rounded-tl-none {
    border-top-left-radius: 0;
  }
  .sm\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }
  .sm\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }
  .sm\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }
  .sm\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }
  .sm\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }
  .sm\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }
  .sm\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }
  .sm\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }
  .sm\:rounded-tr-none {
    border-top-right-radius: 0;
  }
  .sm\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }
  .sm\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }
  .sm\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }
  .sm\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }
  .sm\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }
  .sm\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }
  .sm\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }
  .sm\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }
  .sm\:rounded-br-none {
    border-bottom-right-radius: 0;
  }
  .sm\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }
  .sm\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }
  .sm\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }
  .sm\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }
  .sm\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }
  .sm\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }
  .sm\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }
  .sm\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }
  .sm\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }
  .sm\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }
  .sm\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }
  .sm\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }
  .sm\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }
  .sm\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }
  .sm\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }
  .sm\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }
  .sm\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }
  .sm\:border-0 {
    border-width: 0;
  }
  .sm\:border-2 {
    border-width: 2px;
  }
  .sm\:border-4 {
    border-width: 4px;
  }
  .sm\:border-8 {
    border-width: 8px;
  }
  .sm\:border {
    border-width: 1px;
  }
  .sm\:border-t-0 {
    border-top-width: 0;
  }
  .sm\:border-t-2 {
    border-top-width: 2px;
  }
  .sm\:border-t-4 {
    border-top-width: 4px;
  }
  .sm\:border-t-8 {
    border-top-width: 8px;
  }
  .sm\:border-t {
    border-top-width: 1px;
  }
  .sm\:border-r-0 {
    border-right-width: 0;
  }
  .sm\:border-r-2 {
    border-right-width: 2px;
  }
  .sm\:border-r-4 {
    border-right-width: 4px;
  }
  .sm\:border-r-8 {
    border-right-width: 8px;
  }
  .sm\:border-r {
    border-right-width: 1px;
  }
  .sm\:border-b-0 {
    border-bottom-width: 0;
  }
  .sm\:border-b-2 {
    border-bottom-width: 2px;
  }
  .sm\:border-b-4 {
    border-bottom-width: 4px;
  }
  .sm\:border-b-8 {
    border-bottom-width: 8px;
  }
  .sm\:border-b {
    border-bottom-width: 1px;
  }
  .sm\:border-l-0 {
    border-left-width: 0;
  }
  .sm\:border-l-2 {
    border-left-width: 2px;
  }
  .sm\:border-l-4 {
    border-left-width: 4px;
  }
  .sm\:border-l-8 {
    border-left-width: 8px;
  }
  .sm\:border-l {
    border-left-width: 1px;
  }
  .sm\:border-solid {
    border-style: solid;
  }
  .sm\:border-dashed {
    border-style: dashed;
  }
  .sm\:border-dotted {
    border-style: dotted;
  }
  .sm\:border-double {
    border-style: double;
  }
  .sm\:border-none {
    border-style: none;
  }
  .sm\:border-transparent {
    border-color: transparent;
  }
  .sm\:border-current {
    border-color: currentColor;
  }
  .sm\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .sm\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .sm\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .sm\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .sm\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .sm\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .sm\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .sm\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .sm\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .sm\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .sm\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .sm\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .sm\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .sm\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .sm\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .sm\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .sm\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .sm\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .sm\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .sm\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .sm\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .sm\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .sm\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .sm\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .sm\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .sm\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .sm\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .sm\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .sm\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .sm\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .sm\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .sm\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .sm\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .sm\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .sm\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .sm\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .sm\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .sm\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .sm\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .sm\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .sm\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .sm\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .sm\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .sm\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .sm\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .sm\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .sm\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .sm\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .sm\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .sm\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .sm\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .sm\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .sm\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .sm\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .sm\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .sm\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .sm\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .sm\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .sm\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .sm\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .sm\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .sm\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .sm\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .sm\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .sm\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .sm\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .sm\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .sm\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .sm\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .sm\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .sm\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .sm\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .sm\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .sm\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .sm\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .sm\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .sm\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .sm\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .sm\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .sm\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .sm\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .sm\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-transparent {
    border-color: transparent;
  }
  .group:hover .sm\:group-hover\:border-current {
    border-color: currentColor;
  }
  .group:hover .sm\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .group:hover .sm\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-transparent:focus-within {
    border-color: transparent;
  }
  .sm\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }
  .sm\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .sm\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .sm\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .sm\:hover\:border-current:hover {
    border-color: currentColor;
  }
  .sm\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .sm\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .sm\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .sm\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .sm\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .sm\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .sm\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .sm\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .sm\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .sm\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .sm\:focus\:border-transparent:focus {
    border-color: transparent;
  }
  .sm\:focus\:border-current:focus {
    border-color: currentColor;
  }
  .sm\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .sm\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .sm\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .sm\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .sm\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .sm\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .sm\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .sm\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .sm\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .sm\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .sm\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .sm\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .sm\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .sm\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .sm\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .sm\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .sm\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .sm\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .sm\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .sm\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .sm\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .sm\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .sm\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .sm\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .sm\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .group:hover .sm\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .group:hover .sm\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .group:hover .sm\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .group:hover .sm\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .group:hover .sm\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .group:hover .sm\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .group:hover .sm\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .group:hover .sm\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .group:hover .sm\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .group:hover .sm\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .group:hover .sm\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .group:hover .sm\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .group:hover .sm\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .group:hover .sm\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .group:hover .sm\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .sm\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }
  .sm\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }
  .sm\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }
  .sm\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }
  .sm\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }
  .sm\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }
  .sm\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }
  .sm\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }
  .sm\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }
  .sm\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }
  .sm\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }
  .sm\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }
  .sm\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }
  .sm\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }
  .sm\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }
  .sm\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }
  .sm\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }
  .sm\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }
  .sm\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }
  .sm\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }
  .sm\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }
  .sm\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }
  .sm\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }
  .sm\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }
  .sm\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }
  .sm\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }
  .sm\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }
  .sm\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }
  .sm\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }
  .sm\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }
  .sm\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }
  .sm\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }
  .sm\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }
  .sm\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }
  .sm\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }
  .sm\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }
  .sm\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }
  .sm\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }
  .sm\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }
  .sm\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }
  .sm\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }
  .sm\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }
  .sm\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }
  .sm\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }
  .sm\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }
  .sm\:bg-transparent {
    background-color: transparent;
  }
  .sm\:bg-current {
    background-color: currentColor;
  }
  .sm\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .sm\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .sm\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .sm\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .sm\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .sm\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .sm\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .sm\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .sm\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .sm\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .sm\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .sm\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .sm\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .sm\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .sm\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .sm\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .sm\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .sm\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .sm\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .sm\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .sm\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .sm\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .sm\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .sm\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .sm\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .sm\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .sm\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .sm\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-transparent {
    background-color: transparent;
  }
  .group:hover .sm\:group-hover\:bg-current {
    background-color: currentColor;
  }
  .group:hover .sm\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .group:hover .sm\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-transparent:focus-within {
    background-color: transparent;
  }
  .sm\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }
  .sm\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .sm\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-transparent:hover {
    background-color: transparent;
  }
  .sm\:hover\:bg-current:hover {
    background-color: currentColor;
  }
  .sm\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .sm\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-transparent:focus {
    background-color: transparent;
  }
  .sm\:focus\:bg-current:focus {
    background-color: currentColor;
  }
  .sm\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .sm\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .sm\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .sm\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .sm\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .sm\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .sm\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .sm\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .sm\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .sm\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .sm\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .sm\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .sm\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .sm\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .sm\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .sm\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .sm\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .group:hover .sm\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .group:hover .sm\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .group:hover .sm\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .group:hover .sm\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .group:hover .sm\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .group:hover .sm\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .group:hover .sm\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .group:hover .sm\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .group:hover .sm\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .group:hover .sm\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .group:hover .sm\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .group:hover .sm\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .group:hover .sm\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .group:hover .sm\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .group:hover .sm\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .sm\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }
  .sm\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }
  .sm\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }
  .sm\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }
  .sm\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }
  .sm\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }
  .sm\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }
  .sm\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }
  .sm\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }
  .sm\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }
  .sm\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }
  .sm\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }
  .sm\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }
  .sm\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }
  .sm\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }
  .sm\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }
  .sm\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }
  .sm\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }
  .sm\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }
  .sm\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }
  .sm\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }
  .sm\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }
  .sm\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }
  .sm\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }
  .sm\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }
  .sm\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }
  .sm\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }
  .sm\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }
  .sm\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }
  .sm\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }
  .sm\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }
  .sm\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }
  .sm\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }
  .sm\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }
  .sm\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }
  .sm\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }
  .sm\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }
  .sm\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }
  .sm\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }
  .sm\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }
  .sm\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }
  .sm\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }
  .sm\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }
  .sm\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }
  .sm\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }
  .sm\:bg-none {
    background-image: none;
  }
  .sm\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }
  .sm\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }
  .sm\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }
  .sm\:bg-gradient-to-br {
    background-image: linear-gradient(
      to bottom right,
      var(--tw-gradient-stops)
    );
  }
  .sm\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }
  .sm\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }
  .sm\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }
  .sm\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }
  .sm\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .sm\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .sm\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .sm\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .sm\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .sm\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .sm\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .sm\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .sm\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .sm\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .sm\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .sm\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .sm\:from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .sm\:from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .sm\:from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .sm\:from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .sm\:from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .sm\:from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .sm\:from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .sm\:from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .sm\:from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .sm\:from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .sm\:from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .sm\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .sm\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .sm\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .sm\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .sm\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .sm\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .sm\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .sm\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .sm\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .sm\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .sm\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .sm\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .sm\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .sm\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .sm\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .sm\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .sm\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .sm\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .sm\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .sm\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .sm\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .sm\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .sm\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .sm\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .sm\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .sm\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .sm\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .sm\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .sm\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .sm\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .sm\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .sm\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .sm\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .sm\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .sm\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .sm\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .sm\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .sm\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .sm\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .sm\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .sm\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .sm\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .sm\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .sm\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .sm\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .sm\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .sm\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .sm\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .sm\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .sm\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .sm\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .sm\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .sm\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .sm\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .sm\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .sm\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .sm\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .sm\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .sm\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .sm\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .sm\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .sm\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .sm\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .sm\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .sm\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .sm\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .sm\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:hover\:from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .sm\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .sm\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .sm\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .sm\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .sm\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .sm\:hover\:from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .sm\:hover\:from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .sm\:hover\:from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .sm\:hover\:from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .sm\:hover\:from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .sm\:hover\:from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .sm\:hover\:from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .sm\:hover\:from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .sm\:hover\:from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .sm\:hover\:from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .sm\:hover\:from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .sm\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .sm\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .sm\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .sm\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .sm\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .sm\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .sm\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .sm\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .sm\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .sm\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .sm\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .sm\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .sm\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .sm\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .sm\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .sm\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .sm\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .sm\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .sm\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .sm\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .sm\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .sm\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .sm\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .sm\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .sm\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .sm\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .sm\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .sm\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .sm\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .sm\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .sm\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .sm\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .sm\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .sm\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .sm\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .sm\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .sm\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .sm\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .sm\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .sm\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .sm\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .sm\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .sm\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .sm\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .sm\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .sm\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .sm\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .sm\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .sm\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .sm\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .sm\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .sm\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .sm\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .sm\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .sm\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .sm\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .sm\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .sm\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .sm\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .sm\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .sm\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .sm\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .sm\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .sm\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .sm\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .sm\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .sm\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:focus\:from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .sm\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .sm\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .sm\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .sm\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .sm\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .sm\:focus\:from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .sm\:focus\:from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .sm\:focus\:from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .sm\:focus\:from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .sm\:focus\:from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .sm\:focus\:from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .sm\:focus\:from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .sm\:focus\:from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .sm\:focus\:from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .sm\:focus\:from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .sm\:focus\:from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .sm\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .sm\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .sm\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .sm\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .sm\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .sm\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .sm\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .sm\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .sm\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .sm\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .sm\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .sm\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .sm\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .sm\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .sm\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .sm\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .sm\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .sm\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .sm\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .sm\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .sm\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .sm\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .sm\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .sm\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .sm\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .sm\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .sm\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .sm\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .sm\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .sm\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .sm\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .sm\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .sm\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .sm\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .sm\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .sm\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .sm\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .sm\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .sm\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .sm\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .sm\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .sm\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .sm\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .sm\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .sm\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .sm\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .sm\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .sm\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .sm\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .sm\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .sm\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .sm\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .sm\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .sm\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .sm\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .sm\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .sm\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .sm\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .sm\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .sm\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .sm\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .sm\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .sm\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .sm\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .sm\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .sm\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .sm\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .sm\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .sm\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .sm\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .sm\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .sm\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .sm\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827,
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .sm\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .sm\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .sm\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .sm\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .sm\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .sm\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .sm\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .sm\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .sm\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .sm\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .sm\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .sm\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .sm\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .sm\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .sm\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .sm\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .sm\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .sm\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .sm\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .sm\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .sm\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .sm\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .sm\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .sm\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .sm\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .sm\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .sm\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .sm\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .sm\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .sm\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .sm\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .sm\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .sm\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .sm\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .sm\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .sm\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .sm\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .sm\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .sm\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .sm\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .sm\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .sm\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .sm\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .sm\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .sm\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .sm\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .sm\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .sm\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .sm\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .sm\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .sm\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .sm\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .sm\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .sm\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .sm\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .sm\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .sm\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .sm\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .sm\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .sm\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .sm\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .sm\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .sm\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .sm\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .sm\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .sm\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .sm\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .sm\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .sm\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .sm\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .sm\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .sm\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .sm\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .sm\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .sm\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .sm\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .sm\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .sm\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .sm\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .sm\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .sm\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .sm\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .sm\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827,
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .sm\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .sm\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .sm\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .sm\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .sm\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .sm\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .sm\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .sm\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .sm\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .sm\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .sm\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .sm\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .sm\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .sm\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .sm\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .sm\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .sm\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .sm\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .sm\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .sm\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .sm\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .sm\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .sm\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .sm\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .sm\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .sm\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .sm\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .sm\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .sm\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .sm\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .sm\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .sm\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .sm\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .sm\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .sm\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .sm\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .sm\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .sm\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .sm\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .sm\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .sm\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .sm\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .sm\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .sm\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .sm\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .sm\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .sm\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .sm\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .sm\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .sm\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .sm\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .sm\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .sm\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .sm\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .sm\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .sm\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .sm\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .sm\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .sm\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .sm\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .sm\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .sm\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .sm\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .sm\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .sm\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .sm\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .sm\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .sm\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .sm\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .sm\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .sm\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .sm\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .sm\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .sm\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .sm\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .sm\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .sm\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .sm\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .sm\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .sm\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .sm\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .sm\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .sm\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .sm\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827,
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .sm\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .sm\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .sm\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .sm\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .sm\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .sm\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .sm\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .sm\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .sm\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .sm\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .sm\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .sm\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .sm\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .sm\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .sm\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .sm\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .sm\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .sm\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .sm\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .sm\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .sm\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .sm\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .sm\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .sm\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .sm\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .sm\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .sm\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .sm\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .sm\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .sm\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .sm\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .sm\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .sm\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .sm\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .sm\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .sm\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .sm\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .sm\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .sm\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .sm\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .sm\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .sm\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .sm\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .sm\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .sm\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .sm\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .sm\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .sm\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .sm\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .sm\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .sm\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .sm\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .sm\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .sm\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .sm\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .sm\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .sm\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .sm\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .sm\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .sm\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .sm\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .sm\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .sm\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .sm\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .sm\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .sm\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .sm\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .sm\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .sm\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .sm\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .sm\:to-transparent {
    --tw-gradient-to: transparent;
  }
  .sm\:to-current {
    --tw-gradient-to: currentColor;
  }
  .sm\:to-black {
    --tw-gradient-to: #000;
  }
  .sm\:to-white {
    --tw-gradient-to: #fff;
  }
  .sm\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }
  .sm\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }
  .sm\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }
  .sm\:to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }
  .sm\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }
  .sm\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }
  .sm\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }
  .sm\:to-gray-700 {
    --tw-gradient-to: #374151;
  }
  .sm\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }
  .sm\:to-gray-900 {
    --tw-gradient-to: #111827;
  }
  .sm\:to-red-50 {
    --tw-gradient-to: #fef2f2;
  }
  .sm\:to-red-100 {
    --tw-gradient-to: #fee2e2;
  }
  .sm\:to-red-200 {
    --tw-gradient-to: #fecaca;
  }
  .sm\:to-red-300 {
    --tw-gradient-to: #fca5a5;
  }
  .sm\:to-red-400 {
    --tw-gradient-to: #f87171;
  }
  .sm\:to-red-500 {
    --tw-gradient-to: #ef4444;
  }
  .sm\:to-red-600 {
    --tw-gradient-to: #dc2626;
  }
  .sm\:to-red-700 {
    --tw-gradient-to: #b91c1c;
  }
  .sm\:to-red-800 {
    --tw-gradient-to: #991b1b;
  }
  .sm\:to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }
  .sm\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }
  .sm\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }
  .sm\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }
  .sm\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }
  .sm\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }
  .sm\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }
  .sm\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }
  .sm\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }
  .sm\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }
  .sm\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }
  .sm\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }
  .sm\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }
  .sm\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }
  .sm\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }
  .sm\:to-green-400 {
    --tw-gradient-to: #34d399;
  }
  .sm\:to-green-500 {
    --tw-gradient-to: #10b981;
  }
  .sm\:to-green-600 {
    --tw-gradient-to: #059669;
  }
  .sm\:to-green-700 {
    --tw-gradient-to: #047857;
  }
  .sm\:to-green-800 {
    --tw-gradient-to: #065f46;
  }
  .sm\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }
  .sm\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }
  .sm\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }
  .sm\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }
  .sm\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }
  .sm\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }
  .sm\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }
  .sm\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }
  .sm\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }
  .sm\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }
  .sm\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }
  .sm\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }
  .sm\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }
  .sm\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }
  .sm\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }
  .sm\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }
  .sm\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }
  .sm\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }
  .sm\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }
  .sm\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }
  .sm\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }
  .sm\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }
  .sm\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }
  .sm\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }
  .sm\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }
  .sm\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }
  .sm\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }
  .sm\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }
  .sm\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }
  .sm\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }
  .sm\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }
  .sm\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }
  .sm\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }
  .sm\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }
  .sm\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }
  .sm\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }
  .sm\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }
  .sm\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }
  .sm\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }
  .sm\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }
  .sm\:to-pink-900 {
    --tw-gradient-to: #831843;
  }
  .sm\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }
  .sm\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }
  .sm\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }
  .sm\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }
  .sm\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }
  .sm\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }
  .sm\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .sm\:hover\:to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }
  .sm\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }
  .sm\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }
  .sm\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }
  .sm\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }
  .sm\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }
  .sm\:hover\:to-gray-900:hover {
    --tw-gradient-to: #111827;
  }
  .sm\:hover\:to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }
  .sm\:hover\:to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }
  .sm\:hover\:to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }
  .sm\:hover\:to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }
  .sm\:hover\:to-red-400:hover {
    --tw-gradient-to: #f87171;
  }
  .sm\:hover\:to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }
  .sm\:hover\:to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }
  .sm\:hover\:to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }
  .sm\:hover\:to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }
  .sm\:hover\:to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }
  .sm\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }
  .sm\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }
  .sm\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }
  .sm\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }
  .sm\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }
  .sm\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }
  .sm\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }
  .sm\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }
  .sm\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }
  .sm\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }
  .sm\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }
  .sm\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }
  .sm\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }
  .sm\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }
  .sm\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }
  .sm\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }
  .sm\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }
  .sm\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }
  .sm\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }
  .sm\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }
  .sm\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }
  .sm\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }
  .sm\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }
  .sm\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }
  .sm\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }
  .sm\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }
  .sm\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }
  .sm\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }
  .sm\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }
  .sm\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }
  .sm\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }
  .sm\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }
  .sm\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }
  .sm\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }
  .sm\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }
  .sm\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }
  .sm\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }
  .sm\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }
  .sm\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }
  .sm\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }
  .sm\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }
  .sm\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }
  .sm\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }
  .sm\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }
  .sm\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }
  .sm\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }
  .sm\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }
  .sm\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }
  .sm\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }
  .sm\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }
  .sm\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }
  .sm\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }
  .sm\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }
  .sm\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }
  .sm\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }
  .sm\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }
  .sm\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }
  .sm\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }
  .sm\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }
  .sm\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }
  .sm\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }
  .sm\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }
  .sm\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }
  .sm\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }
  .sm\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }
  .sm\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }
  .sm\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .sm\:focus\:to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }
  .sm\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }
  .sm\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }
  .sm\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }
  .sm\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }
  .sm\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }
  .sm\:focus\:to-gray-900:focus {
    --tw-gradient-to: #111827;
  }
  .sm\:focus\:to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }
  .sm\:focus\:to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }
  .sm\:focus\:to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }
  .sm\:focus\:to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }
  .sm\:focus\:to-red-400:focus {
    --tw-gradient-to: #f87171;
  }
  .sm\:focus\:to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }
  .sm\:focus\:to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }
  .sm\:focus\:to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }
  .sm\:focus\:to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }
  .sm\:focus\:to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }
  .sm\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }
  .sm\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }
  .sm\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }
  .sm\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }
  .sm\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }
  .sm\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }
  .sm\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }
  .sm\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }
  .sm\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }
  .sm\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }
  .sm\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }
  .sm\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }
  .sm\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }
  .sm\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }
  .sm\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }
  .sm\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }
  .sm\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }
  .sm\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }
  .sm\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }
  .sm\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }
  .sm\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }
  .sm\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }
  .sm\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }
  .sm\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }
  .sm\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }
  .sm\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }
  .sm\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }
  .sm\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }
  .sm\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }
  .sm\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }
  .sm\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }
  .sm\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }
  .sm\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }
  .sm\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }
  .sm\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }
  .sm\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }
  .sm\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }
  .sm\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }
  .sm\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }
  .sm\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }
  .sm\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }
  .sm\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }
  .sm\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }
  .sm\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }
  .sm\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }
  .sm\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }
  .sm\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }
  .sm\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }
  .sm\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }
  .sm\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }
  .sm\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }
  .sm\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }
  .sm\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }
  .sm\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }
  .sm\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }
  .sm\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }
  .sm\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }
  .sm\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }
  .sm\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }
  .sm\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }
  .sm\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }
  .sm\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
  .sm\:bg-auto {
    background-size: auto;
  }
  .sm\:bg-cover {
    background-size: cover;
  }
  .sm\:bg-contain {
    background-size: contain;
  }
  .sm\:bg-fixed {
    background-attachment: fixed;
  }
  .sm\:bg-local {
    background-attachment: local;
  }
  .sm\:bg-scroll {
    background-attachment: scroll;
  }
  .sm\:bg-clip-border {
    background-clip: border-box;
  }
  .sm\:bg-clip-padding {
    background-clip: padding-box;
  }
  .sm\:bg-clip-content {
    background-clip: content-box;
  }
  .sm\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }
  .sm\:bg-bottom {
    background-position: bottom;
  }
  .sm\:bg-center {
    background-position: center;
  }
  .sm\:bg-left {
    background-position: left;
  }
  .sm\:bg-left-bottom {
    background-position: left bottom;
  }
  .sm\:bg-left-top {
    background-position: left top;
  }
  .sm\:bg-right {
    background-position: right;
  }
  .sm\:bg-right-bottom {
    background-position: right bottom;
  }
  .sm\:bg-right-top {
    background-position: right top;
  }
  .sm\:bg-top {
    background-position: top;
  }
  .sm\:bg-repeat {
    background-repeat: repeat;
  }
  .sm\:bg-no-repeat {
    background-repeat: no-repeat;
  }
  .sm\:bg-repeat-x {
    background-repeat: repeat-x;
  }
  .sm\:bg-repeat-y {
    background-repeat: repeat-y;
  }
  .sm\:bg-repeat-round {
    background-repeat: round;
  }
  .sm\:bg-repeat-space {
    background-repeat: space;
  }
  .sm\:bg-origin-border {
    background-origin: border-box;
  }
  .sm\:bg-origin-padding {
    background-origin: padding-box;
  }
  .sm\:bg-origin-content {
    background-origin: content-box;
  }
  .sm\:fill-current {
    fill: currentColor;
  }
  .sm\:stroke-current {
    stroke: currentColor;
  }
  .sm\:stroke-0 {
    stroke-width: 0;
  }
  .sm\:stroke-1 {
    stroke-width: 1;
  }
  .sm\:stroke-2 {
    stroke-width: 2;
  }
  .sm\:object-contain {
    object-fit: contain;
  }
  .sm\:object-cover {
    object-fit: cover;
  }
  .sm\:object-fill {
    object-fit: fill;
  }
  .sm\:object-none {
    object-fit: none;
  }
  .sm\:object-scale-down {
    object-fit: scale-down;
  }
  .sm\:object-bottom {
    object-position: bottom;
  }
  .sm\:object-center {
    object-position: center;
  }
  .sm\:object-left {
    object-position: left;
  }
  .sm\:object-left-bottom {
    object-position: left bottom;
  }
  .sm\:object-left-top {
    object-position: left top;
  }
  .sm\:object-right {
    object-position: right;
  }
  .sm\:object-right-bottom {
    object-position: right bottom;
  }
  .sm\:object-right-top {
    object-position: right top;
  }
  .sm\:object-top {
    object-position: top;
  }
  .sm\:p-0 {
    padding: 0;
  }
  .sm\:p-1 {
    padding: 0.25rem;
  }
  .sm\:p-2 {
    padding: 0.5rem;
  }
  .sm\:p-3 {
    padding: 0.75rem;
  }
  .sm\:p-4 {
    padding: 1rem;
  }
  .sm\:p-5 {
    padding: 1.25rem;
  }
  .sm\:p-6 {
    padding: 1.5rem;
  }
  .sm\:p-7 {
    padding: 1.75rem;
  }
  .sm\:p-8 {
    padding: 2rem;
  }
  .sm\:p-9 {
    padding: 2.25rem;
  }
  .sm\:p-10 {
    padding: 2.5rem;
  }
  .sm\:p-11 {
    padding: 2.75rem;
  }
  .sm\:p-12 {
    padding: 3rem;
  }
  .sm\:p-14 {
    padding: 3.5rem;
  }
  .sm\:p-16 {
    padding: 4rem;
  }
  .sm\:p-20 {
    padding: 5rem;
  }
  .sm\:p-24 {
    padding: 6rem;
  }
  .sm\:p-28 {
    padding: 7rem;
  }
  .sm\:p-32 {
    padding: 8rem;
  }
  .sm\:p-36 {
    padding: 9rem;
  }
  .sm\:p-40 {
    padding: 10rem;
  }
  .sm\:p-44 {
    padding: 11rem;
  }
  .sm\:p-48 {
    padding: 12rem;
  }
  .sm\:p-52 {
    padding: 13rem;
  }
  .sm\:p-56 {
    padding: 14rem;
  }
  .sm\:p-60 {
    padding: 15rem;
  }
  .sm\:p-64 {
    padding: 16rem;
  }
  .sm\:p-72 {
    padding: 18rem;
  }
  .sm\:p-80 {
    padding: 20rem;
  }
  .sm\:p-96 {
    padding: 24rem;
  }
  .sm\:p-px {
    padding: 1px;
  }
  .sm\:p-0\.5 {
    padding: 0.125rem;
  }
  .sm\:p-1\.5 {
    padding: 0.375rem;
  }
  .sm\:p-2\.5 {
    padding: 0.625rem;
  }
  .sm\:p-3\.5 {
    padding: 0.875rem;
  }
  .sm\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .sm\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .sm\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .sm\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .sm\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .sm\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .sm\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .sm\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .sm\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .sm\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .sm\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .sm\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .sm\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .sm\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .sm\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .sm\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .sm\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }
  .sm\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }
  .sm\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }
  .sm\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .sm\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }
  .sm\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .sm\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }
  .sm\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .sm\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .sm\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .sm\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .sm\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .sm\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .sm\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .sm\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .sm\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .sm\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .sm\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .sm\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .sm\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .sm\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .sm\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .sm\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .sm\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .sm\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .sm\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .sm\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .sm\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .sm\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .sm\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .sm\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .sm\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .sm\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
  .sm\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
  .sm\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .sm\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  .sm\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }
  .sm\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
  .sm\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }
  .sm\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .sm\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .sm\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .sm\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .sm\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .sm\:pt-0 {
    padding-top: 0;
  }
  .sm\:pt-1 {
    padding-top: 0.25rem;
  }
  .sm\:pt-2 {
    padding-top: 0.5rem;
  }
  .sm\:pt-3 {
    padding-top: 0.75rem;
  }
  .sm\:pt-4 {
    padding-top: 1rem;
  }
  .sm\:pt-5 {
    padding-top: 1.25rem;
  }
  .sm\:pt-6 {
    padding-top: 1.5rem;
  }
  .sm\:pt-7 {
    padding-top: 1.75rem;
  }
  .sm\:pt-8 {
    padding-top: 2rem;
  }
  .sm\:pt-9 {
    padding-top: 2.25rem;
  }
  .sm\:pt-10 {
    padding-top: 2.5rem;
  }
  .sm\:pt-11 {
    padding-top: 2.75rem;
  }
  .sm\:pt-12 {
    padding-top: 3rem;
  }
  .sm\:pt-14 {
    padding-top: 3.5rem;
  }
  .sm\:pt-16 {
    padding-top: 4rem;
  }
  .sm\:pt-20 {
    padding-top: 5rem;
  }
  .sm\:pt-24 {
    padding-top: 6rem;
  }
  .sm\:pt-28 {
    padding-top: 7rem;
  }
  .sm\:pt-32 {
    padding-top: 8rem;
  }
  .sm\:pt-36 {
    padding-top: 9rem;
  }
  .sm\:pt-40 {
    padding-top: 10rem;
  }
  .sm\:pt-44 {
    padding-top: 11rem;
  }
  .sm\:pt-48 {
    padding-top: 12rem;
  }
  .sm\:pt-52 {
    padding-top: 13rem;
  }
  .sm\:pt-56 {
    padding-top: 14rem;
  }
  .sm\:pt-60 {
    padding-top: 15rem;
  }
  .sm\:pt-64 {
    padding-top: 16rem;
  }
  .sm\:pt-72 {
    padding-top: 18rem;
  }
  .sm\:pt-80 {
    padding-top: 20rem;
  }
  .sm\:pt-96 {
    padding-top: 24rem;
  }
  .sm\:pt-px {
    padding-top: 1px;
  }
  .sm\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .sm\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .sm\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .sm\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .sm\:pr-0 {
    padding-right: 0;
  }
  .sm\:pr-1 {
    padding-right: 0.25rem;
  }
  .sm\:pr-2 {
    padding-right: 0.5rem;
  }
  .sm\:pr-3 {
    padding-right: 0.75rem;
  }
  .sm\:pr-4 {
    padding-right: 1rem;
  }
  .sm\:pr-5 {
    padding-right: 1.25rem;
  }
  .sm\:pr-6 {
    padding-right: 1.5rem;
  }
  .sm\:pr-7 {
    padding-right: 1.75rem;
  }
  .sm\:pr-8 {
    padding-right: 2rem;
  }
  .sm\:pr-9 {
    padding-right: 2.25rem;
  }
  .sm\:pr-10 {
    padding-right: 2.5rem;
  }
  .sm\:pr-11 {
    padding-right: 2.75rem;
  }
  .sm\:pr-12 {
    padding-right: 3rem;
  }
  .sm\:pr-14 {
    padding-right: 3.5rem;
  }
  .sm\:pr-16 {
    padding-right: 4rem;
  }
  .sm\:pr-20 {
    padding-right: 5rem;
  }
  .sm\:pr-24 {
    padding-right: 6rem;
  }
  .sm\:pr-28 {
    padding-right: 7rem;
  }
  .sm\:pr-32 {
    padding-right: 8rem;
  }
  .sm\:pr-36 {
    padding-right: 9rem;
  }
  .sm\:pr-40 {
    padding-right: 10rem;
  }
  .sm\:pr-44 {
    padding-right: 11rem;
  }
  .sm\:pr-48 {
    padding-right: 12rem;
  }
  .sm\:pr-52 {
    padding-right: 13rem;
  }
  .sm\:pr-56 {
    padding-right: 14rem;
  }
  .sm\:pr-60 {
    padding-right: 15rem;
  }
  .sm\:pr-64 {
    padding-right: 16rem;
  }
  .sm\:pr-72 {
    padding-right: 18rem;
  }
  .sm\:pr-80 {
    padding-right: 20rem;
  }
  .sm\:pr-96 {
    padding-right: 24rem;
  }
  .sm\:pr-px {
    padding-right: 1px;
  }
  .sm\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .sm\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .sm\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .sm\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .sm\:pb-0 {
    padding-bottom: 0;
  }
  .sm\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .sm\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .sm\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .sm\:pb-4 {
    padding-bottom: 1rem;
  }
  .sm\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .sm\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .sm\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .sm\:pb-8 {
    padding-bottom: 2rem;
  }
  .sm\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .sm\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .sm\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .sm\:pb-12 {
    padding-bottom: 3rem;
  }
  .sm\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .sm\:pb-16 {
    padding-bottom: 4rem;
  }
  .sm\:pb-20 {
    padding-bottom: 5rem;
  }
  .sm\:pb-24 {
    padding-bottom: 6rem;
  }
  .sm\:pb-28 {
    padding-bottom: 7rem;
  }
  .sm\:pb-32 {
    padding-bottom: 8rem;
  }
  .sm\:pb-36 {
    padding-bottom: 9rem;
  }
  .sm\:pb-40 {
    padding-bottom: 10rem;
  }
  .sm\:pb-44 {
    padding-bottom: 11rem;
  }
  .sm\:pb-48 {
    padding-bottom: 12rem;
  }
  .sm\:pb-52 {
    padding-bottom: 13rem;
  }
  .sm\:pb-56 {
    padding-bottom: 14rem;
  }
  .sm\:pb-60 {
    padding-bottom: 15rem;
  }
  .sm\:pb-64 {
    padding-bottom: 16rem;
  }
  .sm\:pb-72 {
    padding-bottom: 18rem;
  }
  .sm\:pb-80 {
    padding-bottom: 20rem;
  }
  .sm\:pb-96 {
    padding-bottom: 24rem;
  }
  .sm\:pb-px {
    padding-bottom: 1px;
  }
  .sm\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .sm\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .sm\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .sm\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .sm\:pl-0 {
    padding-left: 0;
  }
  .sm\:pl-1 {
    padding-left: 0.25rem;
  }
  .sm\:pl-2 {
    padding-left: 0.5rem;
  }
  .sm\:pl-3 {
    padding-left: 0.75rem;
  }
  .sm\:pl-4 {
    padding-left: 1rem;
  }
  .sm\:pl-5 {
    padding-left: 1.25rem;
  }
  .sm\:pl-6 {
    padding-left: 1.5rem;
  }
  .sm\:pl-7 {
    padding-left: 1.75rem;
  }
  .sm\:pl-8 {
    padding-left: 2rem;
  }
  .sm\:pl-9 {
    padding-left: 2.25rem;
  }
  .sm\:pl-10 {
    padding-left: 2.5rem;
  }
  .sm\:pl-11 {
    padding-left: 2.75rem;
  }
  .sm\:pl-12 {
    padding-left: 3rem;
  }
  .sm\:pl-14 {
    padding-left: 3.5rem;
  }
  .sm\:pl-16 {
    padding-left: 4rem;
  }
  .sm\:pl-20 {
    padding-left: 5rem;
  }
  .sm\:pl-24 {
    padding-left: 6rem;
  }
  .sm\:pl-28 {
    padding-left: 7rem;
  }
  .sm\:pl-32 {
    padding-left: 8rem;
  }
  .sm\:pl-36 {
    padding-left: 9rem;
  }
  .sm\:pl-40 {
    padding-left: 10rem;
  }
  .sm\:pl-44 {
    padding-left: 11rem;
  }
  .sm\:pl-48 {
    padding-left: 12rem;
  }
  .sm\:pl-52 {
    padding-left: 13rem;
  }
  .sm\:pl-56 {
    padding-left: 14rem;
  }
  .sm\:pl-60 {
    padding-left: 15rem;
  }
  .sm\:pl-64 {
    padding-left: 16rem;
  }
  .sm\:pl-72 {
    padding-left: 18rem;
  }
  .sm\:pl-80 {
    padding-left: 20rem;
  }
  .sm\:pl-96 {
    padding-left: 24rem;
  }
  .sm\:pl-px {
    padding-left: 1px;
  }
  .sm\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .sm\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .sm\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .sm\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .sm\:text-left {
    text-align: left;
  }
  .sm\:text-center {
    text-align: center;
  }
  .sm\:text-right {
    text-align: right;
  }
  .sm\:text-justify {
    text-align: justify;
  }
  .sm\:align-baseline {
    vertical-align: baseline;
  }
  .sm\:align-top {
    vertical-align: top;
  }
  .sm\:align-middle {
    vertical-align: middle;
  }
  .sm\:align-bottom {
    vertical-align: bottom;
  }
  .sm\:align-text-top {
    vertical-align: text-top;
  }
  .sm\:align-text-bottom {
    vertical-align: text-bottom;
  }
  .sm\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  }
  .sm\:font-serif {
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  }
  .sm\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
  }
  .sm\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .sm\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .sm\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .sm\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .sm\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
  .sm\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
  .sm\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
  .sm\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }
  .sm\:font-thin {
    font-weight: 100;
  }
  .sm\:font-extralight {
    font-weight: 200;
  }
  .sm\:font-light {
    font-weight: 300;
  }
  .sm\:font-normal {
    font-weight: 400;
  }
  .sm\:font-medium {
    font-weight: 500;
  }
  .sm\:font-semibold {
    font-weight: 600;
  }
  .sm\:font-bold {
    font-weight: 700;
  }
  .sm\:font-extrabold {
    font-weight: 800;
  }
  .sm\:font-black {
    font-weight: 900;
  }
  .sm\:uppercase {
    text-transform: uppercase;
  }
  .sm\:lowercase {
    text-transform: lowercase;
  }
  .sm\:capitalize {
    text-transform: capitalize;
  }
  .sm\:normal-case {
    text-transform: none;
  }
  .sm\:italic {
    font-style: italic;
  }
  .sm\:not-italic {
    font-style: normal;
  }
  .sm\:diagonal-fractions,
  .sm\:lining-nums,
  .sm\:oldstyle-nums,
  .sm\:ordinal,
  .sm\:proportional-nums,
  .sm\:slashed-zero,
  .sm\:stacked-fractions,
  .sm\:tabular-nums {
    --tw-ordinal: var(--tw-empty); /*!*/ /*!*/
    --tw-slashed-zero: var(--tw-empty); /*!*/ /*!*/
    --tw-numeric-figure: var(--tw-empty); /*!*/ /*!*/
    --tw-numeric-spacing: var(--tw-empty); /*!*/ /*!*/
    --tw-numeric-fraction: var(--tw-empty); /*!*/ /*!*/
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
  }
  .sm\:normal-nums {
    font-variant-numeric: normal;
  }
  .sm\:ordinal {
    --tw-ordinal: ordinal;
  }
  .sm\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }
  .sm\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }
  .sm\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }
  .sm\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }
  .sm\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }
  .sm\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }
  .sm\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }
  .sm\:leading-3 {
    line-height: 0.75rem;
  }
  .sm\:leading-4 {
    line-height: 1rem;
  }
  .sm\:leading-5 {
    line-height: 1.25rem;
  }
  .sm\:leading-6 {
    line-height: 1.5rem;
  }
  .sm\:leading-7 {
    line-height: 1.75rem;
  }
  .sm\:leading-8 {
    line-height: 2rem;
  }
  .sm\:leading-9 {
    line-height: 2.25rem;
  }
  .sm\:leading-10 {
    line-height: 2.5rem;
  }
  .sm\:leading-none {
    line-height: 1;
  }
  .sm\:leading-tight {
    line-height: 1.25;
  }
  .sm\:leading-snug {
    line-height: 1.375;
  }
  .sm\:leading-normal {
    line-height: 1.5;
  }
  .sm\:leading-relaxed {
    line-height: 1.625;
  }
  .sm\:leading-loose {
    line-height: 2;
  }
  .sm\:tracking-tighter {
    letter-spacing: -0.05em;
  }
  .sm\:tracking-tight {
    letter-spacing: -0.025em;
  }
  .sm\:tracking-normal {
    letter-spacing: 0;
  }
  .sm\:tracking-wide {
    letter-spacing: 0.025em;
  }
  .sm\:tracking-wider {
    letter-spacing: 0.05em;
  }
  .sm\:tracking-widest {
    letter-spacing: 0.1em;
  }
  .sm\:text-transparent {
    color: transparent;
  }
  .sm\:text-current {
    color: currentColor;
  }
  .sm\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .sm\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .sm\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .sm\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .sm\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .sm\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .sm\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .sm\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .sm\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .sm\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .sm\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .sm\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .sm\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .sm\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .sm\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .sm\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .sm\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .sm\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .sm\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .sm\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .sm\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .sm\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .sm\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .sm\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .sm\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .sm\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .sm\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .sm\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .sm\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .sm\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .sm\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .sm\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .sm\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .sm\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .sm\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .sm\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .sm\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .sm\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .sm\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .sm\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .sm\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .sm\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .sm\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .sm\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .sm\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .sm\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .sm\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .sm\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .sm\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .sm\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .sm\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .sm\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .sm\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .sm\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .sm\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .sm\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .sm\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .sm\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .sm\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .sm\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .sm\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .sm\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .sm\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .sm\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .sm\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .sm\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .sm\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .sm\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .sm\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .sm\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .sm\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .sm\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .sm\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .sm\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .sm\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .sm\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .sm\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .sm\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .sm\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .sm\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .sm\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .sm\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-transparent {
    color: transparent;
  }
  .group:hover .sm\:group-hover\:text-current {
    color: currentColor;
  }
  .group:hover .sm\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .group:hover .sm\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-transparent:focus-within {
    color: transparent;
  }
  .sm\:focus-within\:text-current:focus-within {
    color: currentColor;
  }
  .sm\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .sm\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .sm\:hover\:text-transparent:hover {
    color: transparent;
  }
  .sm\:hover\:text-current:hover {
    color: currentColor;
  }
  .sm\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .sm\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .sm\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .sm\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .sm\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .sm\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .sm\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .sm\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .sm\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .sm\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .sm\:focus\:text-transparent:focus {
    color: transparent;
  }
  .sm\:focus\:text-current:focus {
    color: currentColor;
  }
  .sm\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .sm\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .sm\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .sm\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .sm\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .sm\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .sm\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .sm\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .sm\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .sm\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .sm\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .sm\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .sm\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .sm\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .sm\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .sm\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .sm\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .sm\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .sm\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .sm\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .sm\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .sm\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .sm\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .sm\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .sm\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .group:hover .sm\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .group:hover .sm\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .group:hover .sm\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .group:hover .sm\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .group:hover .sm\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .group:hover .sm\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .group:hover .sm\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .group:hover .sm\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .group:hover .sm\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .group:hover .sm\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .group:hover .sm\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .group:hover .sm\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .group:hover .sm\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .group:hover .sm\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .group:hover .sm\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .sm\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }
  .sm\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }
  .sm\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }
  .sm\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }
  .sm\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }
  .sm\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }
  .sm\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }
  .sm\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }
  .sm\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }
  .sm\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }
  .sm\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }
  .sm\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }
  .sm\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }
  .sm\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }
  .sm\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }
  .sm\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }
  .sm\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }
  .sm\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }
  .sm\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }
  .sm\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }
  .sm\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }
  .sm\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }
  .sm\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }
  .sm\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }
  .sm\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }
  .sm\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }
  .sm\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }
  .sm\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }
  .sm\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }
  .sm\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }
  .sm\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }
  .sm\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }
  .sm\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }
  .sm\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }
  .sm\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }
  .sm\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }
  .sm\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }
  .sm\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }
  .sm\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }
  .sm\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }
  .sm\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }
  .sm\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }
  .sm\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }
  .sm\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }
  .sm\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }
  .sm\:underline {
    text-decoration: underline;
  }
  .sm\:line-through {
    text-decoration: line-through;
  }
  .sm\:no-underline {
    text-decoration: none;
  }
  .group:hover .sm\:group-hover\:underline {
    text-decoration: underline;
  }
  .group:hover .sm\:group-hover\:line-through {
    text-decoration: line-through;
  }
  .group:hover .sm\:group-hover\:no-underline {
    text-decoration: none;
  }
  .sm\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }
  .sm\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }
  .sm\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }
  .sm\:hover\:underline:hover {
    text-decoration: underline;
  }
  .sm\:hover\:line-through:hover {
    text-decoration: line-through;
  }
  .sm\:hover\:no-underline:hover {
    text-decoration: none;
  }
  .sm\:focus\:underline:focus {
    text-decoration: underline;
  }
  .sm\:focus\:line-through:focus {
    text-decoration: line-through;
  }
  .sm\:focus\:no-underline:focus {
    text-decoration: none;
  }
  .sm\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .sm\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  .sm\:placeholder-transparent::placeholder {
    color: transparent;
  }
  .sm\:placeholder-current::placeholder {
    color: currentColor;
  }
  .sm\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }
  .sm\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }
  .sm\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .sm\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .sm\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .sm\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .sm\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .sm\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .sm\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .sm\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .sm\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .sm\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .sm\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .sm\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .sm\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .sm\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .sm\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .sm\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .sm\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .sm\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .sm\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .sm\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .sm\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .sm\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .sm\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .sm\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .sm\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .sm\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .sm\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .sm\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .sm\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .sm\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .sm\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .sm\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .sm\:opacity-0 {
    opacity: 0;
  }
  .sm\:opacity-5 {
    opacity: 0.05;
  }
  .sm\:opacity-10 {
    opacity: 0.1;
  }
  .sm\:opacity-20 {
    opacity: 0.2;
  }
  .sm\:opacity-25 {
    opacity: 0.25;
  }
  .sm\:opacity-30 {
    opacity: 0.3;
  }
  .sm\:opacity-40 {
    opacity: 0.4;
  }
  .sm\:opacity-50 {
    opacity: 0.5;
  }
  .sm\:opacity-60 {
    opacity: 0.6;
  }
  .sm\:opacity-70 {
    opacity: 0.7;
  }
  .sm\:opacity-75 {
    opacity: 0.75;
  }
  .sm\:opacity-80 {
    opacity: 0.8;
  }
  .sm\:opacity-90 {
    opacity: 0.9;
  }
  .sm\:opacity-95 {
    opacity: 0.95;
  }
  .sm\:opacity-100 {
    opacity: 1;
  }
  .group:hover .sm\:group-hover\:opacity-0 {
    opacity: 0;
  }
  .group:hover .sm\:group-hover\:opacity-5 {
    opacity: 0.05;
  }
  .group:hover .sm\:group-hover\:opacity-10 {
    opacity: 0.1;
  }
  .group:hover .sm\:group-hover\:opacity-20 {
    opacity: 0.2;
  }
  .group:hover .sm\:group-hover\:opacity-25 {
    opacity: 0.25;
  }
  .group:hover .sm\:group-hover\:opacity-30 {
    opacity: 0.3;
  }
  .group:hover .sm\:group-hover\:opacity-40 {
    opacity: 0.4;
  }
  .group:hover .sm\:group-hover\:opacity-50 {
    opacity: 0.5;
  }
  .group:hover .sm\:group-hover\:opacity-60 {
    opacity: 0.6;
  }
  .group:hover .sm\:group-hover\:opacity-70 {
    opacity: 0.7;
  }
  .group:hover .sm\:group-hover\:opacity-75 {
    opacity: 0.75;
  }
  .group:hover .sm\:group-hover\:opacity-80 {
    opacity: 0.8;
  }
  .group:hover .sm\:group-hover\:opacity-90 {
    opacity: 0.9;
  }
  .group:hover .sm\:group-hover\:opacity-95 {
    opacity: 0.95;
  }
  .group:hover .sm\:group-hover\:opacity-100 {
    opacity: 1;
  }
  .sm\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }
  .sm\:focus-within\:opacity-5:focus-within {
    opacity: 0.05;
  }
  .sm\:focus-within\:opacity-10:focus-within {
    opacity: 0.1;
  }
  .sm\:focus-within\:opacity-20:focus-within {
    opacity: 0.2;
  }
  .sm\:focus-within\:opacity-25:focus-within {
    opacity: 0.25;
  }
  .sm\:focus-within\:opacity-30:focus-within {
    opacity: 0.3;
  }
  .sm\:focus-within\:opacity-40:focus-within {
    opacity: 0.4;
  }
  .sm\:focus-within\:opacity-50:focus-within {
    opacity: 0.5;
  }
  .sm\:focus-within\:opacity-60:focus-within {
    opacity: 0.6;
  }
  .sm\:focus-within\:opacity-70:focus-within {
    opacity: 0.7;
  }
  .sm\:focus-within\:opacity-75:focus-within {
    opacity: 0.75;
  }
  .sm\:focus-within\:opacity-80:focus-within {
    opacity: 0.8;
  }
  .sm\:focus-within\:opacity-90:focus-within {
    opacity: 0.9;
  }
  .sm\:focus-within\:opacity-95:focus-within {
    opacity: 0.95;
  }
  .sm\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }
  .sm\:hover\:opacity-0:hover {
    opacity: 0;
  }
  .sm\:hover\:opacity-5:hover {
    opacity: 0.05;
  }
  .sm\:hover\:opacity-10:hover {
    opacity: 0.1;
  }
  .sm\:hover\:opacity-20:hover {
    opacity: 0.2;
  }
  .sm\:hover\:opacity-25:hover {
    opacity: 0.25;
  }
  .sm\:hover\:opacity-30:hover {
    opacity: 0.3;
  }
  .sm\:hover\:opacity-40:hover {
    opacity: 0.4;
  }
  .sm\:hover\:opacity-50:hover {
    opacity: 0.5;
  }
  .sm\:hover\:opacity-60:hover {
    opacity: 0.6;
  }
  .sm\:hover\:opacity-70:hover {
    opacity: 0.7;
  }
  .sm\:hover\:opacity-75:hover {
    opacity: 0.75;
  }
  .sm\:hover\:opacity-80:hover {
    opacity: 0.8;
  }
  .sm\:hover\:opacity-90:hover {
    opacity: 0.9;
  }
  .sm\:hover\:opacity-95:hover {
    opacity: 0.95;
  }
  .sm\:hover\:opacity-100:hover {
    opacity: 1;
  }
  .sm\:focus\:opacity-0:focus {
    opacity: 0;
  }
  .sm\:focus\:opacity-5:focus {
    opacity: 0.05;
  }
  .sm\:focus\:opacity-10:focus {
    opacity: 0.1;
  }
  .sm\:focus\:opacity-20:focus {
    opacity: 0.2;
  }
  .sm\:focus\:opacity-25:focus {
    opacity: 0.25;
  }
  .sm\:focus\:opacity-30:focus {
    opacity: 0.3;
  }
  .sm\:focus\:opacity-40:focus {
    opacity: 0.4;
  }
  .sm\:focus\:opacity-50:focus {
    opacity: 0.5;
  }
  .sm\:focus\:opacity-60:focus {
    opacity: 0.6;
  }
  .sm\:focus\:opacity-70:focus {
    opacity: 0.7;
  }
  .sm\:focus\:opacity-75:focus {
    opacity: 0.75;
  }
  .sm\:focus\:opacity-80:focus {
    opacity: 0.8;
  }
  .sm\:focus\:opacity-90:focus {
    opacity: 0.9;
  }
  .sm\:focus\:opacity-95:focus {
    opacity: 0.95;
  }
  .sm\:focus\:opacity-100:focus {
    opacity: 1;
  }
  .sm\:bg-blend-normal {
    background-blend-mode: normal;
  }
  .sm\:bg-blend-multiply {
    background-blend-mode: multiply;
  }
  .sm\:bg-blend-screen {
    background-blend-mode: screen;
  }
  .sm\:bg-blend-overlay {
    background-blend-mode: overlay;
  }
  .sm\:bg-blend-darken {
    background-blend-mode: darken;
  }
  .sm\:bg-blend-lighten {
    background-blend-mode: lighten;
  }
  .sm\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }
  .sm\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }
  .sm\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }
  .sm\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }
  .sm\:bg-blend-difference {
    background-blend-mode: difference;
  }
  .sm\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }
  .sm\:bg-blend-hue {
    background-blend-mode: hue;
  }
  .sm\:bg-blend-saturation {
    background-blend-mode: saturation;
  }
  .sm\:bg-blend-color {
    background-blend-mode: color;
  }
  .sm\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }
  .sm\:mix-blend-normal {
    mix-blend-mode: normal;
  }
  .sm\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }
  .sm\:mix-blend-screen {
    mix-blend-mode: screen;
  }
  .sm\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }
  .sm\:mix-blend-darken {
    mix-blend-mode: darken;
  }
  .sm\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }
  .sm\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }
  .sm\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }
  .sm\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }
  .sm\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }
  .sm\:mix-blend-difference {
    mix-blend-mode: difference;
  }
  .sm\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }
  .sm\:mix-blend-hue {
    mix-blend-mode: hue;
  }
  .sm\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }
  .sm\:mix-blend-color {
    mix-blend-mode: color;
  }
  .sm\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }
  .sm\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .sm\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .sm\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .sm\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .sm\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .sm\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .sm\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .sm\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .sm\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .sm\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .sm\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }
  .sm\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }
  .sm\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .sm\:focus-within\:outline-white:focus-within {
    outline: 2px dotted white;
    outline-offset: 2px;
  }
  .sm\:focus-within\:outline-black:focus-within {
    outline: 2px dotted black;
    outline-offset: 2px;
  }
  .sm\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .sm\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }
  .sm\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }
  .sm\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .sm\:ring-inset {
    --tw-ring-inset: inset;
  }
  .sm\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }
  .sm\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }
  .sm\:ring-transparent {
    --tw-ring-color: transparent;
  }
  .sm\:ring-current {
    --tw-ring-color: currentColor;
  }
  .sm\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .sm\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .sm\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }
  .sm\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .sm\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .sm\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .sm\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .sm\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .sm\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .sm\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .sm\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .sm\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .sm\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .sm\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .sm\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .sm\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .sm\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .sm\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .sm\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .sm\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .sm\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .sm\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .sm\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .sm\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .sm\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .sm\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .sm\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .sm\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }
  .sm\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }
  .sm\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .sm\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }
  .sm\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }
  .sm\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .sm\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .sm\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }
  .sm\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }
  .sm\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }
  .sm\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }
  .sm\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }
  .sm\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }
  .sm\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }
  .sm\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }
  .sm\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }
  .sm\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }
  .sm\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }
  .sm\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }
  .sm\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }
  .sm\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }
  .sm\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }
  .sm\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }
  .sm\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }
  .sm\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }
  .sm\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }
  .sm\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }
  .sm\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }
  .sm\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }
  .sm\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }
  .sm\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }
  .sm\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }
  .sm\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }
  .sm\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }
  .sm\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }
  .sm\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }
  .sm\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }
  .sm\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }
  .sm\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }
  .sm\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }
  .sm\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }
  .sm\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }
  .sm\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }
  .sm\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }
  .sm\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }
  .sm\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }
  .sm\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }
  .sm\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }
  .sm\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }
  .sm\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }
  .sm\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }
  .sm\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }
  .sm\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }
  .sm\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }
  .sm\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }
  .sm\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }
  .sm\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }
  .sm\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }
  .sm\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }
  .sm\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }
  .sm\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }
  .sm\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }
  .sm\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }
  .sm\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }
  .sm\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }
  .sm\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }
  .sm\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }
  .sm\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }
  .sm\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }
  .sm\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }
  .sm\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }
  .sm\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }
  .sm\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }
  .sm\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }
  .sm\:ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }
  .sm\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }
  .sm\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }
  .sm\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }
  .sm\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }
  .sm\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }
  .sm\:ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }
  .sm\:ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }
  .sm\:ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }
  .sm\:ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }
  .sm\:ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }
  .sm\:ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }
  .sm\:ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }
  .sm\:ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }
  .sm\:ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }
  .sm\:ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }
  .sm\:ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }
  .sm\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }
  .sm\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }
  .sm\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }
  .sm\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }
  .sm\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }
  .sm\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }
  .sm\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }
  .sm\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }
  .sm\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }
  .sm\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }
  .sm\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }
  .sm\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }
  .sm\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }
  .sm\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }
  .sm\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }
  .sm\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }
  .sm\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }
  .sm\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }
  .sm\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }
  .sm\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }
  .sm\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }
  .sm\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }
  .sm\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }
  .sm\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }
  .sm\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }
  .sm\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }
  .sm\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }
  .sm\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }
  .sm\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }
  .sm\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }
  .sm\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }
  .sm\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }
  .sm\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }
  .sm\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }
  .sm\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }
  .sm\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }
  .sm\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }
  .sm\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }
  .sm\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }
  .sm\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }
  .sm\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }
  .sm\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }
  .sm\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }
  .sm\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }
  .sm\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }
  .sm\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }
  .sm\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }
  .sm\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }
  .sm\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }
  .sm\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }
  .sm\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }
  .sm\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }
  .sm\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }
  .sm\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }
  .sm\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }
  .sm\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }
  .sm\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }
  .sm\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }
  .sm\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }
  .sm\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }
  .sm\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }
  .sm\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }
  .sm\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }
  .sm\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }
  .sm\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }
  .sm\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }
  .sm\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .sm\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }
  .sm\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }
  .sm\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }
  .sm\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }
  .sm\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }
  .sm\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }
  .sm\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }
  .sm\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }
  .sm\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }
  .sm\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }
  .sm\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }
  .sm\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }
  .sm\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }
  .sm\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }
  .sm\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }
  .sm\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }
  .sm\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }
  .sm\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }
  .sm\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }
  .sm\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }
  .sm\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }
  .sm\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }
  .sm\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }
  .sm\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }
  .sm\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }
  .sm\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }
  .sm\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }
  .sm\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }
  .sm\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }
  .sm\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }
  .sm\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }
  .sm\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }
  .sm\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }
  .sm\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }
  .sm\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }
  .sm\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }
  .sm\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }
  .sm\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }
  .sm\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }
  .sm\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }
  .sm\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }
  .sm\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }
  .sm\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }
  .sm\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }
  .sm\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }
  .sm\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }
  .sm\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }
  .sm\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }
  .sm\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }
  .sm\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }
  .sm\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }
  .sm\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }
  .sm\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }
  .sm\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }
  .sm\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }
  .sm\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }
  .sm\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }
  .sm\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }
  .sm\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }
  .sm\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }
  .sm\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }
  .sm\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }
  .sm\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }
  .sm\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }
  .sm\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }
  .sm\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }
  .sm\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }
  .sm\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }
  .sm\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }
  .sm\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }
  .sm\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }
  .sm\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }
  .sm\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }
  .sm\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }
  .sm\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }
  .sm\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }
  .sm\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }
  .sm\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }
  .sm\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }
  .sm\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }
  .sm\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }
  .sm\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }
  .sm\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }
  .sm\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .sm\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }
  .sm\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }
  .sm\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }
  .sm\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }
  .sm\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }
  .sm\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }
  .sm\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }
  .sm\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }
  .sm\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }
  .sm\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }
  .sm\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }
  .sm\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }
  .sm\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }
  .sm\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }
  .sm\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }
  .sm\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }
  .sm\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }
  .sm\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }
  .sm\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }
  .sm\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }
  .sm\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }
  .sm\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }
  .sm\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }
  .sm\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }
  .sm\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }
  .sm\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }
  .sm\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }
  .sm\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }
  .sm\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }
  .sm\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }
  .sm\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }
  .sm\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }
  .sm\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }
  .sm\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }
  .sm\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }
  .sm\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }
  .sm\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }
  .sm\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }
  .sm\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }
  .sm\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }
  .sm\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }
  .sm\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }
  .sm\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }
  .sm\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }
  .sm\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }
  .sm\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }
  .sm\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }
  .sm\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }
  .sm\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }
  .sm\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }
  .sm\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }
  .sm\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }
  .sm\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }
  .sm\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }
  .sm\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }
  .sm\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }
  .sm\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }
  .sm\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }
  .sm\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }
  .sm\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }
  .sm\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }
  .sm\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }
  .sm\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }
  .sm\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }
  .sm\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }
  .sm\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }
  .sm\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }
  .sm\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }
  .sm\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }
  .sm\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }
  .sm\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }
  .sm\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }
  .sm\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }
  .sm\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }
  .sm\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }
  .sm\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }
  .sm\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }
  .sm\:filter {
    --tw-blur: var(--tw-empty); /*!*/ /*!*/
    --tw-brightness: var(--tw-empty); /*!*/ /*!*/
    --tw-contrast: var(--tw-empty); /*!*/ /*!*/
    --tw-grayscale: var(--tw-empty); /*!*/ /*!*/
    --tw-hue-rotate: var(--tw-empty); /*!*/ /*!*/
    --tw-invert: var(--tw-empty); /*!*/ /*!*/
    --tw-saturate: var(--tw-empty); /*!*/ /*!*/
    --tw-sepia: var(--tw-empty); /*!*/ /*!*/
    --tw-drop-shadow: var(--tw-empty); /*!*/ /*!*/
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .sm\:filter-none {
    filter: none;
  }
  .sm\:blur-0 {
    --tw-blur: blur(0);
  }
  .sm\:blur-none {
    --tw-blur: blur(0);
  }
  .sm\:blur-sm {
    --tw-blur: blur(4px);
  }
  .sm\:blur {
    --tw-blur: blur(8px);
  }
  .sm\:blur-md {
    --tw-blur: blur(12px);
  }
  .sm\:blur-lg {
    --tw-blur: blur(16px);
  }
  .sm\:blur-xl {
    --tw-blur: blur(24px);
  }
  .sm\:blur-2xl {
    --tw-blur: blur(40px);
  }
  .sm\:blur-3xl {
    --tw-blur: blur(64px);
  }
  .sm\:brightness-0 {
    --tw-brightness: brightness(0);
  }
  .sm\:brightness-50 {
    --tw-brightness: brightness(0.5);
  }
  .sm\:brightness-75 {
    --tw-brightness: brightness(0.75);
  }
  .sm\:brightness-90 {
    --tw-brightness: brightness(0.9);
  }
  .sm\:brightness-95 {
    --tw-brightness: brightness(0.95);
  }
  .sm\:brightness-100 {
    --tw-brightness: brightness(1);
  }
  .sm\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }
  .sm\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }
  .sm\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }
  .sm\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }
  .sm\:brightness-200 {
    --tw-brightness: brightness(2);
  }
  .sm\:contrast-0 {
    --tw-contrast: contrast(0);
  }
  .sm\:contrast-50 {
    --tw-contrast: contrast(0.5);
  }
  .sm\:contrast-75 {
    --tw-contrast: contrast(0.75);
  }
  .sm\:contrast-100 {
    --tw-contrast: contrast(1);
  }
  .sm\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }
  .sm\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }
  .sm\:contrast-200 {
    --tw-contrast: contrast(2);
  }
  .sm\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.05));
  }
  .sm\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1))
      drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }
  .sm\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07))
      drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }
  .sm\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04))
      drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }
  .sm\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03))
      drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }
  .sm\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }
  .sm\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }
  .sm\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }
  .sm\:grayscale {
    --tw-grayscale: grayscale(100%);
  }
  .sm\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }
  .sm\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }
  .sm\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }
  .sm\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }
  .sm\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }
  .sm\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }
  .sm\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }
  .sm\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }
  .sm\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }
  .sm\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }
  .sm\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }
  .sm\:invert-0 {
    --tw-invert: invert(0);
  }
  .sm\:invert {
    --tw-invert: invert(100%);
  }
  .sm\:saturate-0 {
    --tw-saturate: saturate(0);
  }
  .sm\:saturate-50 {
    --tw-saturate: saturate(0.5);
  }
  .sm\:saturate-100 {
    --tw-saturate: saturate(1);
  }
  .sm\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }
  .sm\:saturate-200 {
    --tw-saturate: saturate(2);
  }
  .sm\:sepia-0 {
    --tw-sepia: sepia(0);
  }
  .sm\:sepia {
    --tw-sepia: sepia(100%);
  }
  .sm\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-brightness: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-contrast: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-grayscale: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-hue-rotate: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-invert: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-opacity: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-saturate: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-sepia: var(--tw-empty); /*!*/ /*!*/
    -webkit-backdrop-filter: var(--tw-backdrop-blur)
      var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
      var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate)
      var(--tw-backdrop-invert) var(--tw-backdrop-opacity)
      var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
      var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
      var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
      var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
      var(--tw-backdrop-sepia);
  }
  .sm\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }
  .sm\:backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
  }
  .sm\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }
  .sm\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }
  .sm\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }
  .sm\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }
  .sm\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }
  .sm\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }
  .sm\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }
  .sm\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }
  .sm\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }
  .sm\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(0.5);
  }
  .sm\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(0.75);
  }
  .sm\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(0.9);
  }
  .sm\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(0.95);
  }
  .sm\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }
  .sm\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }
  .sm\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }
  .sm\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }
  .sm\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }
  .sm\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }
  .sm\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }
  .sm\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(0.5);
  }
  .sm\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(0.75);
  }
  .sm\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }
  .sm\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }
  .sm\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }
  .sm\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }
  .sm\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }
  .sm\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }
  .sm\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }
  .sm\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }
  .sm\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }
  .sm\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }
  .sm\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }
  .sm\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }
  .sm\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }
  .sm\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }
  .sm\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }
  .sm\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }
  .sm\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }
  .sm\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }
  .sm\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }
  .sm\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }
  .sm\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(0.05);
  }
  .sm\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(0.1);
  }
  .sm\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(0.2);
  }
  .sm\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(0.25);
  }
  .sm\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(0.3);
  }
  .sm\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(0.4);
  }
  .sm\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(0.5);
  }
  .sm\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(0.6);
  }
  .sm\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(0.7);
  }
  .sm\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(0.75);
  }
  .sm\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(0.8);
  }
  .sm\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(0.9);
  }
  .sm\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(0.95);
  }
  .sm\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }
  .sm\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }
  .sm\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(0.5);
  }
  .sm\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }
  .sm\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }
  .sm\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }
  .sm\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }
  .sm\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }
  .sm\:transition-none {
    transition-property: none;
  }
  .sm\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .sm\:transition {
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, backdrop-filter,
      -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .sm\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .sm\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .sm\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .sm\:transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .sm\:delay-75 {
    transition-delay: 75ms;
  }
  .sm\:delay-100 {
    transition-delay: 0.1s;
  }
  .sm\:delay-150 {
    transition-delay: 150ms;
  }
  .sm\:delay-200 {
    transition-delay: 0.2s;
  }
  .sm\:delay-300 {
    transition-delay: 0.3s;
  }
  .sm\:delay-500 {
    transition-delay: 0.5s;
  }
  .sm\:delay-700 {
    transition-delay: 0.7s;
  }
  .sm\:delay-1000 {
    transition-delay: 1s;
  }
  .sm\:duration-75 {
    transition-duration: 75ms;
  }
  .sm\:duration-100 {
    transition-duration: 0.1s;
  }
  .sm\:duration-150 {
    transition-duration: 150ms;
  }
  .sm\:duration-200 {
    transition-duration: 0.2s;
  }
  .sm\:duration-300 {
    transition-duration: 0.3s;
  }
  .sm\:duration-500 {
    transition-duration: 0.5s;
  }
  .sm\:duration-700 {
    transition-duration: 0.7s;
  }
  .sm\:duration-1000 {
    transition-duration: 1s;
  }
  .sm\:ease-linear {
    transition-timing-function: linear;
  }
  .sm\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .sm\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .sm\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}
@media (min-width: 768px) {
  .md\:container {
    width: 100%;
  }
  @media (min-width: 640px) {
    .md\:container {
      max-width: 640px;
    }
  }
  @media (min-width: 768px) {
    .md\:container {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
    .md\:container {
      max-width: 1024px;
    }
  }
  @media (min-width: 1280px) {
    .md\:container {
      max-width: 1280px;
    }
  }
  @media (min-width: 1536px) {
    .md\:container {
      max-width: 1536px;
    }
  }
  .md\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .md\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .md\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .md\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .md\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .md\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .md\:pointer-events-none {
    pointer-events: none;
  }
  .md\:pointer-events-auto {
    pointer-events: auto;
  }
  .md\:visible {
    visibility: visible;
  }
  .md\:invisible {
    visibility: hidden;
  }
  .md\:static {
    position: static;
  }
  .md\:fixed {
    position: fixed;
  }
  .md\:absolute {
    position: absolute;
  }
  .md\:relative {
    position: relative;
  }
  .md\:sticky {
    position: sticky;
  }
  .md\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .md\:inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }
  .md\:inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }
  .md\:inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }
  .md\:inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
  .md\:inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }
  .md\:inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }
  .md\:inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }
  .md\:inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }
  .md\:inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }
  .md\:inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }
  .md\:inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }
  .md\:inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }
  .md\:inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }
  .md\:inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }
  .md\:inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }
  .md\:inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }
  .md\:inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }
  .md\:inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }
  .md\:inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }
  .md\:inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }
  .md\:inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }
  .md\:inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }
  .md\:inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }
  .md\:inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }
  .md\:inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }
  .md\:inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }
  .md\:inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }
  .md\:inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }
  .md\:inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }
  .md\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .md\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }
  .md\:inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }
  .md\:inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }
  .md\:inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }
  .md\:inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }
  .md\:-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .md\:-inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }
  .md\:-inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }
  .md\:-inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }
  .md\:-inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }
  .md\:-inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }
  .md\:-inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }
  .md\:-inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }
  .md\:-inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }
  .md\:-inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }
  .md\:-inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }
  .md\:-inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }
  .md\:-inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }
  .md\:-inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }
  .md\:-inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }
  .md\:-inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }
  .md\:-inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }
  .md\:-inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }
  .md\:-inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }
  .md\:-inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }
  .md\:-inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }
  .md\:-inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }
  .md\:-inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }
  .md\:-inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }
  .md\:-inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }
  .md\:-inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }
  .md\:-inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }
  .md\:-inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }
  .md\:-inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }
  .md\:-inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }
  .md\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }
  .md\:-inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }
  .md\:-inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }
  .md\:-inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }
  .md\:-inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }
  .md\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .md\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }
  .md\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }
  .md\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }
  .md\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .md\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }
  .md\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }
  .md\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .md\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }
  .md\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }
  .md\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }
  .md\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .md\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }
  .md\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }
  .md\:inset-x-0 {
    left: 0;
    right: 0;
  }
  .md\:inset-x-1 {
    left: 0.25rem;
    right: 0.25rem;
  }
  .md\:inset-x-2 {
    left: 0.5rem;
    right: 0.5rem;
  }
  .md\:inset-x-3 {
    left: 0.75rem;
    right: 0.75rem;
  }
  .md\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }
  .md\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }
  .md\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }
  .md\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }
  .md\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }
  .md\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }
  .md\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }
  .md\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }
  .md\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }
  .md\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }
  .md\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }
  .md\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }
  .md\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }
  .md\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }
  .md\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }
  .md\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }
  .md\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }
  .md\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }
  .md\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }
  .md\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }
  .md\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }
  .md\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }
  .md\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }
  .md\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }
  .md\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }
  .md\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }
  .md\:inset-x-auto {
    left: auto;
    right: auto;
  }
  .md\:inset-x-px {
    left: 1px;
    right: 1px;
  }
  .md\:inset-x-0\.5 {
    left: 0.125rem;
    right: 0.125rem;
  }
  .md\:inset-x-1\.5 {
    left: 0.375rem;
    right: 0.375rem;
  }
  .md\:inset-x-2\.5 {
    left: 0.625rem;
    right: 0.625rem;
  }
  .md\:inset-x-3\.5 {
    left: 0.875rem;
    right: 0.875rem;
  }
  .md\:-inset-x-0 {
    left: 0;
    right: 0;
  }
  .md\:-inset-x-1 {
    left: -0.25rem;
    right: -0.25rem;
  }
  .md\:-inset-x-2 {
    left: -0.5rem;
    right: -0.5rem;
  }
  .md\:-inset-x-3 {
    left: -0.75rem;
    right: -0.75rem;
  }
  .md\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }
  .md\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }
  .md\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }
  .md\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }
  .md\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }
  .md\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }
  .md\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }
  .md\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }
  .md\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }
  .md\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }
  .md\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }
  .md\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }
  .md\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }
  .md\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }
  .md\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }
  .md\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }
  .md\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }
  .md\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }
  .md\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }
  .md\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }
  .md\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }
  .md\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }
  .md\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }
  .md\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }
  .md\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }
  .md\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }
  .md\:-inset-x-px {
    left: -1px;
    right: -1px;
  }
  .md\:-inset-x-0\.5 {
    left: -0.125rem;
    right: -0.125rem;
  }
  .md\:-inset-x-1\.5 {
    left: -0.375rem;
    right: -0.375rem;
  }
  .md\:-inset-x-2\.5 {
    left: -0.625rem;
    right: -0.625rem;
  }
  .md\:-inset-x-3\.5 {
    left: -0.875rem;
    right: -0.875rem;
  }
  .md\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }
  .md\:inset-x-1\/3 {
    left: 33.333333%;
    right: 33.333333%;
  }
  .md\:inset-x-2\/3 {
    left: 66.666667%;
    right: 66.666667%;
  }
  .md\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }
  .md\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }
  .md\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }
  .md\:inset-x-full {
    left: 100%;
    right: 100%;
  }
  .md\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }
  .md\:-inset-x-1\/3 {
    left: -33.333333%;
    right: -33.333333%;
  }
  .md\:-inset-x-2\/3 {
    left: -66.666667%;
    right: -66.666667%;
  }
  .md\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }
  .md\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }
  .md\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }
  .md\:-inset-x-full {
    left: -100%;
    right: -100%;
  }
  .md\:inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .md\:inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }
  .md\:inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }
  .md\:inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }
  .md\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }
  .md\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }
  .md\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  .md\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }
  .md\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }
  .md\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }
  .md\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }
  .md\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }
  .md\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }
  .md\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }
  .md\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }
  .md\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }
  .md\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }
  .md\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }
  .md\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }
  .md\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }
  .md\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }
  .md\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }
  .md\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }
  .md\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }
  .md\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }
  .md\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }
  .md\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }
  .md\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }
  .md\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }
  .md\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }
  .md\:inset-y-auto {
    top: auto;
    bottom: auto;
  }
  .md\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }
  .md\:inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }
  .md\:inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }
  .md\:inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }
  .md\:inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }
  .md\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .md\:-inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }
  .md\:-inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }
  .md\:-inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }
  .md\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }
  .md\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }
  .md\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }
  .md\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }
  .md\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }
  .md\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }
  .md\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }
  .md\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }
  .md\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }
  .md\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }
  .md\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }
  .md\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }
  .md\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }
  .md\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }
  .md\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }
  .md\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }
  .md\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }
  .md\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }
  .md\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }
  .md\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }
  .md\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }
  .md\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }
  .md\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }
  .md\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }
  .md\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }
  .md\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }
  .md\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }
  .md\:-inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }
  .md\:-inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }
  .md\:-inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }
  .md\:-inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }
  .md\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }
  .md\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }
  .md\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }
  .md\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }
  .md\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }
  .md\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }
  .md\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }
  .md\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }
  .md\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }
  .md\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }
  .md\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }
  .md\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }
  .md\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }
  .md\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }
  .md\:top-0 {
    top: 0;
  }
  .md\:top-1 {
    top: 0.25rem;
  }
  .md\:top-2 {
    top: 0.5rem;
  }
  .md\:top-3 {
    top: 0.75rem;
  }
  .md\:top-4 {
    top: 1rem;
  }
  .md\:top-5 {
    top: 1.25rem;
  }
  .md\:top-6 {
    top: 1.5rem;
  }
  .md\:top-7 {
    top: 1.75rem;
  }
  .md\:top-8 {
    top: 2rem;
  }
  .md\:top-9 {
    top: 2.25rem;
  }
  .md\:top-10 {
    top: 2.5rem;
  }
  .md\:top-11 {
    top: 2.75rem;
  }
  .md\:top-12 {
    top: 3rem;
  }
  .md\:top-14 {
    top: 3.5rem;
  }
  .md\:top-16 {
    top: 4rem;
  }
  .md\:top-20 {
    top: 5rem;
  }
  .md\:top-24 {
    top: 6rem;
  }
  .md\:top-28 {
    top: 7rem;
  }
  .md\:top-32 {
    top: 8rem;
  }
  .md\:top-36 {
    top: 9rem;
  }
  .md\:top-40 {
    top: 10rem;
  }
  .md\:top-44 {
    top: 11rem;
  }
  .md\:top-48 {
    top: 12rem;
  }
  .md\:top-52 {
    top: 13rem;
  }
  .md\:top-56 {
    top: 14rem;
  }
  .md\:top-60 {
    top: 15rem;
  }
  .md\:top-64 {
    top: 16rem;
  }
  .md\:top-72 {
    top: 18rem;
  }
  .md\:top-80 {
    top: 20rem;
  }
  .md\:top-96 {
    top: 24rem;
  }
  .md\:top-auto {
    top: auto;
  }
  .md\:top-px {
    top: 1px;
  }
  .md\:top-0\.5 {
    top: 0.125rem;
  }
  .md\:top-1\.5 {
    top: 0.375rem;
  }
  .md\:top-2\.5 {
    top: 0.625rem;
  }
  .md\:top-3\.5 {
    top: 0.875rem;
  }
  .md\:-top-0 {
    top: 0;
  }
  .md\:-top-1 {
    top: -0.25rem;
  }
  .md\:-top-2 {
    top: -0.5rem;
  }
  .md\:-top-3 {
    top: -0.75rem;
  }
  .md\:-top-4 {
    top: -1rem;
  }
  .md\:-top-5 {
    top: -1.25rem;
  }
  .md\:-top-6 {
    top: -1.5rem;
  }
  .md\:-top-7 {
    top: -1.75rem;
  }
  .md\:-top-8 {
    top: -2rem;
  }
  .md\:-top-9 {
    top: -2.25rem;
  }
  .md\:-top-10 {
    top: -2.5rem;
  }
  .md\:-top-11 {
    top: -2.75rem;
  }
  .md\:-top-12 {
    top: -3rem;
  }
  .md\:-top-14 {
    top: -3.5rem;
  }
  .md\:-top-16 {
    top: -4rem;
  }
  .md\:-top-20 {
    top: -5rem;
  }
  .md\:-top-24 {
    top: -6rem;
  }
  .md\:-top-28 {
    top: -7rem;
  }
  .md\:-top-32 {
    top: -8rem;
  }
  .md\:-top-36 {
    top: -9rem;
  }
  .md\:-top-40 {
    top: -10rem;
  }
  .md\:-top-44 {
    top: -11rem;
  }
  .md\:-top-48 {
    top: -12rem;
  }
  .md\:-top-52 {
    top: -13rem;
  }
  .md\:-top-56 {
    top: -14rem;
  }
  .md\:-top-60 {
    top: -15rem;
  }
  .md\:-top-64 {
    top: -16rem;
  }
  .md\:-top-72 {
    top: -18rem;
  }
  .md\:-top-80 {
    top: -20rem;
  }
  .md\:-top-96 {
    top: -24rem;
  }
  .md\:-top-px {
    top: -1px;
  }
  .md\:-top-0\.5 {
    top: -0.125rem;
  }
  .md\:-top-1\.5 {
    top: -0.375rem;
  }
  .md\:-top-2\.5 {
    top: -0.625rem;
  }
  .md\:-top-3\.5 {
    top: -0.875rem;
  }
  .md\:top-1\/2 {
    top: 50%;
  }
  .md\:top-1\/3 {
    top: 33.333333%;
  }
  .md\:top-2\/3 {
    top: 66.666667%;
  }
  .md\:top-1\/4 {
    top: 25%;
  }
  .md\:top-2\/4 {
    top: 50%;
  }
  .md\:top-3\/4 {
    top: 75%;
  }
  .md\:top-full {
    top: 100%;
  }
  .md\:-top-1\/2 {
    top: -50%;
  }
  .md\:-top-1\/3 {
    top: -33.333333%;
  }
  .md\:-top-2\/3 {
    top: -66.666667%;
  }
  .md\:-top-1\/4 {
    top: -25%;
  }
  .md\:-top-2\/4 {
    top: -50%;
  }
  .md\:-top-3\/4 {
    top: -75%;
  }
  .md\:-top-full {
    top: -100%;
  }
  .md\:right-0 {
    right: 0;
  }
  .md\:right-1 {
    right: 0.25rem;
  }
  .md\:right-2 {
    right: 0.5rem;
  }
  .md\:right-3 {
    right: 0.75rem;
  }
  .md\:right-4 {
    right: 1rem;
  }
  .md\:right-5 {
    right: 1.25rem;
  }
  .md\:right-6 {
    right: 1.5rem;
  }
  .md\:right-7 {
    right: 1.75rem;
  }
  .md\:right-8 {
    right: 2rem;
  }
  .md\:right-9 {
    right: 2.25rem;
  }
  .md\:right-10 {
    right: 2.5rem;
  }
  .md\:right-11 {
    right: 2.75rem;
  }
  .md\:right-12 {
    right: 3rem;
  }
  .md\:right-14 {
    right: 3.5rem;
  }
  .md\:right-16 {
    right: 4rem;
  }
  .md\:right-20 {
    right: 5rem;
  }
  .md\:right-24 {
    right: 6rem;
  }
  .md\:right-28 {
    right: 7rem;
  }
  .md\:right-32 {
    right: 8rem;
  }
  .md\:right-36 {
    right: 9rem;
  }
  .md\:right-40 {
    right: 10rem;
  }
  .md\:right-44 {
    right: 11rem;
  }
  .md\:right-48 {
    right: 12rem;
  }
  .md\:right-52 {
    right: 13rem;
  }
  .md\:right-56 {
    right: 14rem;
  }
  .md\:right-60 {
    right: 15rem;
  }
  .md\:right-64 {
    right: 16rem;
  }
  .md\:right-72 {
    right: 18rem;
  }
  .md\:right-80 {
    right: 20rem;
  }
  .md\:right-96 {
    right: 24rem;
  }
  .md\:right-auto {
    right: auto;
  }
  .md\:right-px {
    right: 1px;
  }
  .md\:right-0\.5 {
    right: 0.125rem;
  }
  .md\:right-1\.5 {
    right: 0.375rem;
  }
  .md\:right-2\.5 {
    right: 0.625rem;
  }
  .md\:right-3\.5 {
    right: 0.875rem;
  }
  .md\:-right-0 {
    right: 0;
  }
  .md\:-right-1 {
    right: -0.25rem;
  }
  .md\:-right-2 {
    right: -0.5rem;
  }
  .md\:-right-3 {
    right: -0.75rem;
  }
  .md\:-right-4 {
    right: -1rem;
  }
  .md\:-right-5 {
    right: -1.25rem;
  }
  .md\:-right-6 {
    right: -1.5rem;
  }
  .md\:-right-7 {
    right: -1.75rem;
  }
  .md\:-right-8 {
    right: -2rem;
  }
  .md\:-right-9 {
    right: -2.25rem;
  }
  .md\:-right-10 {
    right: -2.5rem;
  }
  .md\:-right-11 {
    right: -2.75rem;
  }
  .md\:-right-12 {
    right: -3rem;
  }
  .md\:-right-14 {
    right: -3.5rem;
  }
  .md\:-right-16 {
    right: -4rem;
  }
  .md\:-right-20 {
    right: -5rem;
  }
  .md\:-right-24 {
    right: -6rem;
  }
  .md\:-right-28 {
    right: -7rem;
  }
  .md\:-right-32 {
    right: -8rem;
  }
  .md\:-right-36 {
    right: -9rem;
  }
  .md\:-right-40 {
    right: -10rem;
  }
  .md\:-right-44 {
    right: -11rem;
  }
  .md\:-right-48 {
    right: -12rem;
  }
  .md\:-right-52 {
    right: -13rem;
  }
  .md\:-right-56 {
    right: -14rem;
  }
  .md\:-right-60 {
    right: -15rem;
  }
  .md\:-right-64 {
    right: -16rem;
  }
  .md\:-right-72 {
    right: -18rem;
  }
  .md\:-right-80 {
    right: -20rem;
  }
  .md\:-right-96 {
    right: -24rem;
  }
  .md\:-right-px {
    right: -1px;
  }
  .md\:-right-0\.5 {
    right: -0.125rem;
  }
  .md\:-right-1\.5 {
    right: -0.375rem;
  }
  .md\:-right-2\.5 {
    right: -0.625rem;
  }
  .md\:-right-3\.5 {
    right: -0.875rem;
  }
  .md\:right-1\/2 {
    right: 50%;
  }
  .md\:right-1\/3 {
    right: 33.333333%;
  }
  .md\:right-2\/3 {
    right: 66.666667%;
  }
  .md\:right-1\/4 {
    right: 25%;
  }
  .md\:right-2\/4 {
    right: 50%;
  }
  .md\:right-3\/4 {
    right: 75%;
  }
  .md\:right-full {
    right: 100%;
  }
  .md\:-right-1\/2 {
    right: -50%;
  }
  .md\:-right-1\/3 {
    right: -33.333333%;
  }
  .md\:-right-2\/3 {
    right: -66.666667%;
  }
  .md\:-right-1\/4 {
    right: -25%;
  }
  .md\:-right-2\/4 {
    right: -50%;
  }
  .md\:-right-3\/4 {
    right: -75%;
  }
  .md\:-right-full {
    right: -100%;
  }
  .md\:bottom-0 {
    bottom: 0;
  }
  .md\:bottom-1 {
    bottom: 0.25rem;
  }
  .md\:bottom-2 {
    bottom: 0.5rem;
  }
  .md\:bottom-3 {
    bottom: 0.75rem;
  }
  .md\:bottom-4 {
    bottom: 1rem;
  }
  .md\:bottom-5 {
    bottom: 1.25rem;
  }
  .md\:bottom-6 {
    bottom: 1.5rem;
  }
  .md\:bottom-7 {
    bottom: 1.75rem;
  }
  .md\:bottom-8 {
    bottom: 2rem;
  }
  .md\:bottom-9 {
    bottom: 2.25rem;
  }
  .md\:bottom-10 {
    bottom: 2.5rem;
  }
  .md\:bottom-11 {
    bottom: 2.75rem;
  }
  .md\:bottom-12 {
    bottom: 3rem;
  }
  .md\:bottom-14 {
    bottom: 3.5rem;
  }
  .md\:bottom-16 {
    bottom: 4rem;
  }
  .md\:bottom-20 {
    bottom: 5rem;
  }
  .md\:bottom-24 {
    bottom: 6rem;
  }
  .md\:bottom-28 {
    bottom: 7rem;
  }
  .md\:bottom-32 {
    bottom: 8rem;
  }
  .md\:bottom-36 {
    bottom: 9rem;
  }
  .md\:bottom-40 {
    bottom: 10rem;
  }
  .md\:bottom-44 {
    bottom: 11rem;
  }
  .md\:bottom-48 {
    bottom: 12rem;
  }
  .md\:bottom-52 {
    bottom: 13rem;
  }
  .md\:bottom-56 {
    bottom: 14rem;
  }
  .md\:bottom-60 {
    bottom: 15rem;
  }
  .md\:bottom-64 {
    bottom: 16rem;
  }
  .md\:bottom-72 {
    bottom: 18rem;
  }
  .md\:bottom-80 {
    bottom: 20rem;
  }
  .md\:bottom-96 {
    bottom: 24rem;
  }
  .md\:bottom-auto {
    bottom: auto;
  }
  .md\:bottom-px {
    bottom: 1px;
  }
  .md\:bottom-0\.5 {
    bottom: 0.125rem;
  }
  .md\:bottom-1\.5 {
    bottom: 0.375rem;
  }
  .md\:bottom-2\.5 {
    bottom: 0.625rem;
  }
  .md\:bottom-3\.5 {
    bottom: 0.875rem;
  }
  .md\:-bottom-0 {
    bottom: 0;
  }
  .md\:-bottom-1 {
    bottom: -0.25rem;
  }
  .md\:-bottom-2 {
    bottom: -0.5rem;
  }
  .md\:-bottom-3 {
    bottom: -0.75rem;
  }
  .md\:-bottom-4 {
    bottom: -1rem;
  }
  .md\:-bottom-5 {
    bottom: -1.25rem;
  }
  .md\:-bottom-6 {
    bottom: -1.5rem;
  }
  .md\:-bottom-7 {
    bottom: -1.75rem;
  }
  .md\:-bottom-8 {
    bottom: -2rem;
  }
  .md\:-bottom-9 {
    bottom: -2.25rem;
  }
  .md\:-bottom-10 {
    bottom: -2.5rem;
  }
  .md\:-bottom-11 {
    bottom: -2.75rem;
  }
  .md\:-bottom-12 {
    bottom: -3rem;
  }
  .md\:-bottom-14 {
    bottom: -3.5rem;
  }
  .md\:-bottom-16 {
    bottom: -4rem;
  }
  .md\:-bottom-20 {
    bottom: -5rem;
  }
  .md\:-bottom-24 {
    bottom: -6rem;
  }
  .md\:-bottom-28 {
    bottom: -7rem;
  }
  .md\:-bottom-32 {
    bottom: -8rem;
  }
  .md\:-bottom-36 {
    bottom: -9rem;
  }
  .md\:-bottom-40 {
    bottom: -10rem;
  }
  .md\:-bottom-44 {
    bottom: -11rem;
  }
  .md\:-bottom-48 {
    bottom: -12rem;
  }
  .md\:-bottom-52 {
    bottom: -13rem;
  }
  .md\:-bottom-56 {
    bottom: -14rem;
  }
  .md\:-bottom-60 {
    bottom: -15rem;
  }
  .md\:-bottom-64 {
    bottom: -16rem;
  }
  .md\:-bottom-72 {
    bottom: -18rem;
  }
  .md\:-bottom-80 {
    bottom: -20rem;
  }
  .md\:-bottom-96 {
    bottom: -24rem;
  }
  .md\:-bottom-px {
    bottom: -1px;
  }
  .md\:-bottom-0\.5 {
    bottom: -0.125rem;
  }
  .md\:-bottom-1\.5 {
    bottom: -0.375rem;
  }
  .md\:-bottom-2\.5 {
    bottom: -0.625rem;
  }
  .md\:-bottom-3\.5 {
    bottom: -0.875rem;
  }
  .md\:bottom-1\/2 {
    bottom: 50%;
  }
  .md\:bottom-1\/3 {
    bottom: 33.333333%;
  }
  .md\:bottom-2\/3 {
    bottom: 66.666667%;
  }
  .md\:bottom-1\/4 {
    bottom: 25%;
  }
  .md\:bottom-2\/4 {
    bottom: 50%;
  }
  .md\:bottom-3\/4 {
    bottom: 75%;
  }
  .md\:bottom-full {
    bottom: 100%;
  }
  .md\:-bottom-1\/2 {
    bottom: -50%;
  }
  .md\:-bottom-1\/3 {
    bottom: -33.333333%;
  }
  .md\:-bottom-2\/3 {
    bottom: -66.666667%;
  }
  .md\:-bottom-1\/4 {
    bottom: -25%;
  }
  .md\:-bottom-2\/4 {
    bottom: -50%;
  }
  .md\:-bottom-3\/4 {
    bottom: -75%;
  }
  .md\:-bottom-full {
    bottom: -100%;
  }
  .md\:left-0 {
    left: 0;
  }
  .md\:left-1 {
    left: 0.25rem;
  }
  .md\:left-2 {
    left: 0.5rem;
  }
  .md\:left-3 {
    left: 0.75rem;
  }
  .md\:left-4 {
    left: 1rem;
  }
  .md\:left-5 {
    left: 1.25rem;
  }
  .md\:left-6 {
    left: 1.5rem;
  }
  .md\:left-7 {
    left: 1.75rem;
  }
  .md\:left-8 {
    left: 2rem;
  }
  .md\:left-9 {
    left: 2.25rem;
  }
  .md\:left-10 {
    left: 2.5rem;
  }
  .md\:left-11 {
    left: 2.75rem;
  }
  .md\:left-12 {
    left: 3rem;
  }
  .md\:left-14 {
    left: 3.5rem;
  }
  .md\:left-16 {
    left: 4rem;
  }
  .md\:left-20 {
    left: 5rem;
  }
  .md\:left-24 {
    left: 6rem;
  }
  .md\:left-28 {
    left: 7rem;
  }
  .md\:left-32 {
    left: 8rem;
  }
  .md\:left-36 {
    left: 9rem;
  }
  .md\:left-40 {
    left: 10rem;
  }
  .md\:left-44 {
    left: 11rem;
  }
  .md\:left-48 {
    left: 12rem;
  }
  .md\:left-52 {
    left: 13rem;
  }
  .md\:left-56 {
    left: 14rem;
  }
  .md\:left-60 {
    left: 15rem;
  }
  .md\:left-64 {
    left: 16rem;
  }
  .md\:left-72 {
    left: 18rem;
  }
  .md\:left-80 {
    left: 20rem;
  }
  .md\:left-96 {
    left: 24rem;
  }
  .md\:left-auto {
    left: auto;
  }
  .md\:left-px {
    left: 1px;
  }
  .md\:left-0\.5 {
    left: 0.125rem;
  }
  .md\:left-1\.5 {
    left: 0.375rem;
  }
  .md\:left-2\.5 {
    left: 0.625rem;
  }
  .md\:left-3\.5 {
    left: 0.875rem;
  }
  .md\:-left-0 {
    left: 0;
  }
  .md\:-left-1 {
    left: -0.25rem;
  }
  .md\:-left-2 {
    left: -0.5rem;
  }
  .md\:-left-3 {
    left: -0.75rem;
  }
  .md\:-left-4 {
    left: -1rem;
  }
  .md\:-left-5 {
    left: -1.25rem;
  }
  .md\:-left-6 {
    left: -1.5rem;
  }
  .md\:-left-7 {
    left: -1.75rem;
  }
  .md\:-left-8 {
    left: -2rem;
  }
  .md\:-left-9 {
    left: -2.25rem;
  }
  .md\:-left-10 {
    left: -2.5rem;
  }
  .md\:-left-11 {
    left: -2.75rem;
  }
  .md\:-left-12 {
    left: -3rem;
  }
  .md\:-left-14 {
    left: -3.5rem;
  }
  .md\:-left-16 {
    left: -4rem;
  }
  .md\:-left-20 {
    left: -5rem;
  }
  .md\:-left-24 {
    left: -6rem;
  }
  .md\:-left-28 {
    left: -7rem;
  }
  .md\:-left-32 {
    left: -8rem;
  }
  .md\:-left-36 {
    left: -9rem;
  }
  .md\:-left-40 {
    left: -10rem;
  }
  .md\:-left-44 {
    left: -11rem;
  }
  .md\:-left-48 {
    left: -12rem;
  }
  .md\:-left-52 {
    left: -13rem;
  }
  .md\:-left-56 {
    left: -14rem;
  }
  .md\:-left-60 {
    left: -15rem;
  }
  .md\:-left-64 {
    left: -16rem;
  }
  .md\:-left-72 {
    left: -18rem;
  }
  .md\:-left-80 {
    left: -20rem;
  }
  .md\:-left-96 {
    left: -24rem;
  }
  .md\:-left-px {
    left: -1px;
  }
  .md\:-left-0\.5 {
    left: -0.125rem;
  }
  .md\:-left-1\.5 {
    left: -0.375rem;
  }
  .md\:-left-2\.5 {
    left: -0.625rem;
  }
  .md\:-left-3\.5 {
    left: -0.875rem;
  }
  .md\:left-1\/2 {
    left: 50%;
  }
  .md\:left-1\/3 {
    left: 33.333333%;
  }
  .md\:left-2\/3 {
    left: 66.666667%;
  }
  .md\:left-1\/4 {
    left: 25%;
  }
  .md\:left-2\/4 {
    left: 50%;
  }
  .md\:left-3\/4 {
    left: 75%;
  }
  .md\:left-full {
    left: 100%;
  }
  .md\:-left-1\/2 {
    left: -50%;
  }
  .md\:-left-1\/3 {
    left: -33.333333%;
  }
  .md\:-left-2\/3 {
    left: -66.666667%;
  }
  .md\:-left-1\/4 {
    left: -25%;
  }
  .md\:-left-2\/4 {
    left: -50%;
  }
  .md\:-left-3\/4 {
    left: -75%;
  }
  .md\:-left-full {
    left: -100%;
  }
  .md\:isolate {
    isolation: isolate;
  }
  .md\:isolation-auto {
    isolation: auto;
  }
  .md\:z-0 {
    z-index: 0;
  }
  .md\:z-10 {
    z-index: 10;
  }
  .md\:z-20 {
    z-index: 20;
  }
  .md\:z-30 {
    z-index: 30;
  }
  .md\:z-40 {
    z-index: 40;
  }
  .md\:z-50 {
    z-index: 50;
  }
  .md\:z-auto {
    z-index: auto;
  }
  .md\:focus-within\:z-0:focus-within {
    z-index: 0;
  }
  .md\:focus-within\:z-10:focus-within {
    z-index: 10;
  }
  .md\:focus-within\:z-20:focus-within {
    z-index: 20;
  }
  .md\:focus-within\:z-30:focus-within {
    z-index: 30;
  }
  .md\:focus-within\:z-40:focus-within {
    z-index: 40;
  }
  .md\:focus-within\:z-50:focus-within {
    z-index: 50;
  }
  .md\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }
  .md\:focus\:z-0:focus {
    z-index: 0;
  }
  .md\:focus\:z-10:focus {
    z-index: 10;
  }
  .md\:focus\:z-20:focus {
    z-index: 20;
  }
  .md\:focus\:z-30:focus {
    z-index: 30;
  }
  .md\:focus\:z-40:focus {
    z-index: 40;
  }
  .md\:focus\:z-50:focus {
    z-index: 50;
  }
  .md\:focus\:z-auto:focus {
    z-index: auto;
  }
  .md\:order-1 {
    order: 1;
  }
  .md\:order-2 {
    order: 2;
  }
  .md\:order-3 {
    order: 3;
  }
  .md\:order-4 {
    order: 4;
  }
  .md\:order-5 {
    order: 5;
  }
  .md\:order-6 {
    order: 6;
  }
  .md\:order-7 {
    order: 7;
  }
  .md\:order-8 {
    order: 8;
  }
  .md\:order-9 {
    order: 9;
  }
  .md\:order-10 {
    order: 10;
  }
  .md\:order-11 {
    order: 11;
  }
  .md\:order-12 {
    order: 12;
  }
  .md\:order-first {
    order: -9999;
  }
  .md\:order-last {
    order: 9999;
  }
  .md\:order-none {
    order: 0;
  }
  .md\:col-auto {
    grid-column: auto;
  }
  .md\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .md\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .md\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .md\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  .md\:col-span-7 {
    grid-column: span 7 / span 7;
  }
  .md\:col-span-8 {
    grid-column: span 8 / span 8;
  }
  .md\:col-span-9 {
    grid-column: span 9 / span 9;
  }
  .md\:col-span-10 {
    grid-column: span 10 / span 10;
  }
  .md\:col-span-11 {
    grid-column: span 11 / span 11;
  }
  .md\:col-span-12 {
    grid-column: span 12 / span 12;
  }
  .md\:col-span-full {
    grid-column: 1/-1;
  }
  .md\:col-start-1 {
    grid-column-start: 1;
  }
  .md\:col-start-2 {
    grid-column-start: 2;
  }
  .md\:col-start-3 {
    grid-column-start: 3;
  }
  .md\:col-start-4 {
    grid-column-start: 4;
  }
  .md\:col-start-5 {
    grid-column-start: 5;
  }
  .md\:col-start-6 {
    grid-column-start: 6;
  }
  .md\:col-start-7 {
    grid-column-start: 7;
  }
  .md\:col-start-8 {
    grid-column-start: 8;
  }
  .md\:col-start-9 {
    grid-column-start: 9;
  }
  .md\:col-start-10 {
    grid-column-start: 10;
  }
  .md\:col-start-11 {
    grid-column-start: 11;
  }
  .md\:col-start-12 {
    grid-column-start: 12;
  }
  .md\:col-start-13 {
    grid-column-start: 13;
  }
  .md\:col-start-auto {
    grid-column-start: auto;
  }
  .md\:col-end-1 {
    grid-column-end: 1;
  }
  .md\:col-end-2 {
    grid-column-end: 2;
  }
  .md\:col-end-3 {
    grid-column-end: 3;
  }
  .md\:col-end-4 {
    grid-column-end: 4;
  }
  .md\:col-end-5 {
    grid-column-end: 5;
  }
  .md\:col-end-6 {
    grid-column-end: 6;
  }
  .md\:col-end-7 {
    grid-column-end: 7;
  }
  .md\:col-end-8 {
    grid-column-end: 8;
  }
  .md\:col-end-9 {
    grid-column-end: 9;
  }
  .md\:col-end-10 {
    grid-column-end: 10;
  }
  .md\:col-end-11 {
    grid-column-end: 11;
  }
  .md\:col-end-12 {
    grid-column-end: 12;
  }
  .md\:col-end-13 {
    grid-column-end: 13;
  }
  .md\:col-end-auto {
    grid-column-end: auto;
  }
  .md\:row-auto {
    grid-row: auto;
  }
  .md\:row-span-1 {
    grid-row: span 1 / span 1;
  }
  .md\:row-span-2 {
    grid-row: span 2 / span 2;
  }
  .md\:row-span-3 {
    grid-row: span 3 / span 3;
  }
  .md\:row-span-4 {
    grid-row: span 4 / span 4;
  }
  .md\:row-span-5 {
    grid-row: span 5 / span 5;
  }
  .md\:row-span-6 {
    grid-row: span 6 / span 6;
  }
  .md\:row-span-full {
    grid-row: 1/-1;
  }
  .md\:row-start-1 {
    grid-row-start: 1;
  }
  .md\:row-start-2 {
    grid-row-start: 2;
  }
  .md\:row-start-3 {
    grid-row-start: 3;
  }
  .md\:row-start-4 {
    grid-row-start: 4;
  }
  .md\:row-start-5 {
    grid-row-start: 5;
  }
  .md\:row-start-6 {
    grid-row-start: 6;
  }
  .md\:row-start-7 {
    grid-row-start: 7;
  }
  .md\:row-start-auto {
    grid-row-start: auto;
  }
  .md\:row-end-1 {
    grid-row-end: 1;
  }
  .md\:row-end-2 {
    grid-row-end: 2;
  }
  .md\:row-end-3 {
    grid-row-end: 3;
  }
  .md\:row-end-4 {
    grid-row-end: 4;
  }
  .md\:row-end-5 {
    grid-row-end: 5;
  }
  .md\:row-end-6 {
    grid-row-end: 6;
  }
  .md\:row-end-7 {
    grid-row-end: 7;
  }
  .md\:row-end-auto {
    grid-row-end: auto;
  }
  .md\:float-right {
    float: right;
  }
  .md\:float-left {
    float: left;
  }
  .md\:float-none {
    float: none;
  }
  .md\:clear-left {
    clear: left;
  }
  .md\:clear-right {
    clear: right;
  }
  .md\:clear-both {
    clear: both;
  }
  .md\:clear-none {
    clear: none;
  }
  .md\:m-0 {
    margin: 0;
  }
  .md\:m-1 {
    margin: 0.25rem;
  }
  .md\:m-2 {
    margin: 0.5rem;
  }
  .md\:m-3 {
    margin: 0.75rem;
  }
  .md\:m-4 {
    margin: 1rem;
  }
  .md\:m-5 {
    margin: 1.25rem;
  }
  .md\:m-6 {
    margin: 1.5rem;
  }
  .md\:m-7 {
    margin: 1.75rem;
  }
  .md\:m-8 {
    margin: 2rem;
  }
  .md\:m-9 {
    margin: 2.25rem;
  }
  .md\:m-10 {
    margin: 2.5rem;
  }
  .md\:m-11 {
    margin: 2.75rem;
  }
  .md\:m-12 {
    margin: 3rem;
  }
  .md\:m-14 {
    margin: 3.5rem;
  }
  .md\:m-16 {
    margin: 4rem;
  }
  .md\:m-20 {
    margin: 5rem;
  }
  .md\:m-24 {
    margin: 6rem;
  }
  .md\:m-28 {
    margin: 7rem;
  }
  .md\:m-32 {
    margin: 8rem;
  }
  .md\:m-36 {
    margin: 9rem;
  }
  .md\:m-40 {
    margin: 10rem;
  }
  .md\:m-44 {
    margin: 11rem;
  }
  .md\:m-48 {
    margin: 12rem;
  }
  .md\:m-52 {
    margin: 13rem;
  }
  .md\:m-56 {
    margin: 14rem;
  }
  .md\:m-60 {
    margin: 15rem;
  }
  .md\:m-64 {
    margin: 16rem;
  }
  .md\:m-72 {
    margin: 18rem;
  }
  .md\:m-80 {
    margin: 20rem;
  }
  .md\:m-96 {
    margin: 24rem;
  }
  .md\:m-auto {
    margin: auto;
  }
  .md\:m-px {
    margin: 1px;
  }
  .md\:m-0\.5 {
    margin: 0.125rem;
  }
  .md\:m-1\.5 {
    margin: 0.375rem;
  }
  .md\:m-2\.5 {
    margin: 0.625rem;
  }
  .md\:m-3\.5 {
    margin: 0.875rem;
  }
  .md\:-m-0 {
    margin: 0;
  }
  .md\:-m-1 {
    margin: -0.25rem;
  }
  .md\:-m-2 {
    margin: -0.5rem;
  }
  .md\:-m-3 {
    margin: -0.75rem;
  }
  .md\:-m-4 {
    margin: -1rem;
  }
  .md\:-m-5 {
    margin: -1.25rem;
  }
  .md\:-m-6 {
    margin: -1.5rem;
  }
  .md\:-m-7 {
    margin: -1.75rem;
  }
  .md\:-m-8 {
    margin: -2rem;
  }
  .md\:-m-9 {
    margin: -2.25rem;
  }
  .md\:-m-10 {
    margin: -2.5rem;
  }
  .md\:-m-11 {
    margin: -2.75rem;
  }
  .md\:-m-12 {
    margin: -3rem;
  }
  .md\:-m-14 {
    margin: -3.5rem;
  }
  .md\:-m-16 {
    margin: -4rem;
  }
  .md\:-m-20 {
    margin: -5rem;
  }
  .md\:-m-24 {
    margin: -6rem;
  }
  .md\:-m-28 {
    margin: -7rem;
  }
  .md\:-m-32 {
    margin: -8rem;
  }
  .md\:-m-36 {
    margin: -9rem;
  }
  .md\:-m-40 {
    margin: -10rem;
  }
  .md\:-m-44 {
    margin: -11rem;
  }
  .md\:-m-48 {
    margin: -12rem;
  }
  .md\:-m-52 {
    margin: -13rem;
  }
  .md\:-m-56 {
    margin: -14rem;
  }
  .md\:-m-60 {
    margin: -15rem;
  }
  .md\:-m-64 {
    margin: -16rem;
  }
  .md\:-m-72 {
    margin: -18rem;
  }
  .md\:-m-80 {
    margin: -20rem;
  }
  .md\:-m-96 {
    margin: -24rem;
  }
  .md\:-m-px {
    margin: -1px;
  }
  .md\:-m-0\.5 {
    margin: -0.125rem;
  }
  .md\:-m-1\.5 {
    margin: -0.375rem;
  }
  .md\:-m-2\.5 {
    margin: -0.625rem;
  }
  .md\:-m-3\.5 {
    margin: -0.875rem;
  }
  .md\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .md\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .md\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .md\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .md\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .md\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .md\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .md\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .md\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .md\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }
  .md\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .md\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .md\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .md\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .md\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .md\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .md\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .md\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .md\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .md\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .md\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .md\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }
  .md\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }
  .md\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .md\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }
  .md\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }
  .md\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .md\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }
  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .md\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .md\:mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .md\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .md\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .md\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .md\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .md\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .md\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .md\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .md\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .md\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .md\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .md\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }
  .md\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .md\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
  .md\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .md\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }
  .md\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .md\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .md\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .md\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .md\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .md\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .md\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .md\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .md\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .md\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }
  .md\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }
  .md\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }
  .md\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }
  .md\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }
  .md\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }
  .md\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }
  .md\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }
  .md\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }
  .md\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .md\:-mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }
  .md\:-mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }
  .md\:-mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .md\:-mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }
  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .md\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .md\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .md\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .md\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .md\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .md\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .md\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .md\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .md\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .md\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .md\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .md\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .md\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .md\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .md\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .md\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .md\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .md\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .md\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .md\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .md\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .md\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }
  .md\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }
  .md\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }
  .md\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
  .md\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }
  .md\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }
  .md\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }
  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .md\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .md\:my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .md\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .md\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .md\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .md\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .md\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .md\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .md\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .md\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .md\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .md\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .md\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .md\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .md\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }
  .md\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .md\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .md\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .md\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .md\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .md\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .md\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .md\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .md\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .md\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .md\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .md\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }
  .md\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }
  .md\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }
  .md\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }
  .md\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }
  .md\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }
  .md\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }
  .md\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }
  .md\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }
  .md\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .md\:-my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }
  .md\:-my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }
  .md\:-my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .md\:-my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }
  .md\:mt-0 {
    margin-top: 0;
  }
  .md\:mt-1 {
    margin-top: 0.25rem;
  }
  .md\:mt-2 {
    margin-top: 0.5rem;
  }
  .md\:mt-3 {
    margin-top: 0.75rem;
  }
  .md\:mt-4 {
    margin-top: 1rem;
  }
  .md\:mt-5 {
    margin-top: 1.25rem;
  }
  .md\:mt-6 {
    margin-top: 1.5rem;
  }
  .md\:mt-7 {
    margin-top: 1.75rem;
  }
  .md\:mt-8 {
    margin-top: 2rem;
  }
  .md\:mt-9 {
    margin-top: 2.25rem;
  }
  .md\:mt-10 {
    margin-top: 2.5rem;
  }
  .md\:mt-11 {
    margin-top: 2.75rem;
  }
  .md\:mt-12 {
    margin-top: 3rem;
  }
  .md\:mt-14 {
    margin-top: 3.5rem;
  }
  .md\:mt-16 {
    margin-top: 4rem;
  }
  .md\:mt-20 {
    margin-top: 5rem;
  }
  .md\:mt-24 {
    margin-top: 6rem;
  }
  .md\:mt-28 {
    margin-top: 7rem;
  }
  .md\:mt-32 {
    margin-top: 8rem;
  }
  .md\:mt-36 {
    margin-top: 9rem;
  }
  .md\:mt-40 {
    margin-top: 10rem;
  }
  .md\:mt-44 {
    margin-top: 11rem;
  }
  .md\:mt-48 {
    margin-top: 12rem;
  }
  .md\:mt-52 {
    margin-top: 13rem;
  }
  .md\:mt-56 {
    margin-top: 14rem;
  }
  .md\:mt-60 {
    margin-top: 15rem;
  }
  .md\:mt-64 {
    margin-top: 16rem;
  }
  .md\:mt-72 {
    margin-top: 18rem;
  }
  .md\:mt-80 {
    margin-top: 20rem;
  }
  .md\:mt-96 {
    margin-top: 24rem;
  }
  .md\:mt-auto {
    margin-top: auto;
  }
  .md\:mt-px {
    margin-top: 1px;
  }
  .md\:mt-0\.5 {
    margin-top: 0.125rem;
  }
  .md\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .md\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .md\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .md\:-mt-0 {
    margin-top: 0;
  }
  .md\:-mt-1 {
    margin-top: -0.25rem;
  }
  .md\:-mt-2 {
    margin-top: -0.5rem;
  }
  .md\:-mt-3 {
    margin-top: -0.75rem;
  }
  .md\:-mt-4 {
    margin-top: -1rem;
  }
  .md\:-mt-5 {
    margin-top: -1.25rem;
  }
  .md\:-mt-6 {
    margin-top: -1.5rem;
  }
  .md\:-mt-7 {
    margin-top: -1.75rem;
  }
  .md\:-mt-8 {
    margin-top: -2rem;
  }
  .md\:-mt-9 {
    margin-top: -2.25rem;
  }
  .md\:-mt-10 {
    margin-top: -2.5rem;
  }
  .md\:-mt-11 {
    margin-top: -2.75rem;
  }
  .md\:-mt-12 {
    margin-top: -3rem;
  }
  .md\:-mt-14 {
    margin-top: -3.5rem;
  }
  .md\:-mt-16 {
    margin-top: -4rem;
  }
  .md\:-mt-20 {
    margin-top: -5rem;
  }
  .md\:-mt-24 {
    margin-top: -6rem;
  }
  .md\:-mt-28 {
    margin-top: -7rem;
  }
  .md\:-mt-32 {
    margin-top: -8rem;
  }
  .md\:-mt-36 {
    margin-top: -9rem;
  }
  .md\:-mt-40 {
    margin-top: -10rem;
  }
  .md\:-mt-44 {
    margin-top: -11rem;
  }
  .md\:-mt-48 {
    margin-top: -12rem;
  }
  .md\:-mt-52 {
    margin-top: -13rem;
  }
  .md\:-mt-56 {
    margin-top: -14rem;
  }
  .md\:-mt-60 {
    margin-top: -15rem;
  }
  .md\:-mt-64 {
    margin-top: -16rem;
  }
  .md\:-mt-72 {
    margin-top: -18rem;
  }
  .md\:-mt-80 {
    margin-top: -20rem;
  }
  .md\:-mt-96 {
    margin-top: -24rem;
  }
  .md\:-mt-px {
    margin-top: -1px;
  }
  .md\:-mt-0\.5 {
    margin-top: -0.125rem;
  }
  .md\:-mt-1\.5 {
    margin-top: -0.375rem;
  }
  .md\:-mt-2\.5 {
    margin-top: -0.625rem;
  }
  .md\:-mt-3\.5 {
    margin-top: -0.875rem;
  }
  .md\:mr-0 {
    margin-right: 0;
  }
  .md\:mr-1 {
    margin-right: 0.25rem;
  }
  .md\:mr-2 {
    margin-right: 0.5rem;
  }
  .md\:mr-3 {
    margin-right: 0.75rem;
  }
  .md\:mr-4 {
    margin-right: 1rem;
  }
  .md\:mr-5 {
    margin-right: 1.25rem;
  }
  .md\:mr-6 {
    margin-right: 1.5rem;
  }
  .md\:mr-7 {
    margin-right: 1.75rem;
  }
  .md\:mr-8 {
    margin-right: 2rem;
  }
  .md\:mr-9 {
    margin-right: 2.25rem;
  }
  .md\:mr-10 {
    margin-right: 2.5rem;
  }
  .md\:mr-11 {
    margin-right: 2.75rem;
  }
  .md\:mr-12 {
    margin-right: 3rem;
  }
  .md\:mr-14 {
    margin-right: 3.5rem;
  }
  .md\:mr-16 {
    margin-right: 4rem;
  }
  .md\:mr-20 {
    margin-right: 5rem;
  }
  .md\:mr-24 {
    margin-right: 6rem;
  }
  .md\:mr-28 {
    margin-right: 7rem;
  }
  .md\:mr-32 {
    margin-right: 8rem;
  }
  .md\:mr-36 {
    margin-right: 9rem;
  }
  .md\:mr-40 {
    margin-right: 10rem;
  }
  .md\:mr-44 {
    margin-right: 11rem;
  }
  .md\:mr-48 {
    margin-right: 12rem;
  }
  .md\:mr-52 {
    margin-right: 13rem;
  }
  .md\:mr-56 {
    margin-right: 14rem;
  }
  .md\:mr-60 {
    margin-right: 15rem;
  }
  .md\:mr-64 {
    margin-right: 16rem;
  }
  .md\:mr-72 {
    margin-right: 18rem;
  }
  .md\:mr-80 {
    margin-right: 20rem;
  }
  .md\:mr-96 {
    margin-right: 24rem;
  }
  .md\:mr-auto {
    margin-right: auto;
  }
  .md\:mr-px {
    margin-right: 1px;
  }
  .md\:mr-0\.5 {
    margin-right: 0.125rem;
  }
  .md\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .md\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .md\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .md\:-mr-0 {
    margin-right: 0;
  }
  .md\:-mr-1 {
    margin-right: -0.25rem;
  }
  .md\:-mr-2 {
    margin-right: -0.5rem;
  }
  .md\:-mr-3 {
    margin-right: -0.75rem;
  }
  .md\:-mr-4 {
    margin-right: -1rem;
  }
  .md\:-mr-5 {
    margin-right: -1.25rem;
  }
  .md\:-mr-6 {
    margin-right: -1.5rem;
  }
  .md\:-mr-7 {
    margin-right: -1.75rem;
  }
  .md\:-mr-8 {
    margin-right: -2rem;
  }
  .md\:-mr-9 {
    margin-right: -2.25rem;
  }
  .md\:-mr-10 {
    margin-right: -2.5rem;
  }
  .md\:-mr-11 {
    margin-right: -2.75rem;
  }
  .md\:-mr-12 {
    margin-right: -3rem;
  }
  .md\:-mr-14 {
    margin-right: -3.5rem;
  }
  .md\:-mr-16 {
    margin-right: -4rem;
  }
  .md\:-mr-20 {
    margin-right: -5rem;
  }
  .md\:-mr-24 {
    margin-right: -6rem;
  }
  .md\:-mr-28 {
    margin-right: -7rem;
  }
  .md\:-mr-32 {
    margin-right: -8rem;
  }
  .md\:-mr-36 {
    margin-right: -9rem;
  }
  .md\:-mr-40 {
    margin-right: -10rem;
  }
  .md\:-mr-44 {
    margin-right: -11rem;
  }
  .md\:-mr-48 {
    margin-right: -12rem;
  }
  .md\:-mr-52 {
    margin-right: -13rem;
  }
  .md\:-mr-56 {
    margin-right: -14rem;
  }
  .md\:-mr-60 {
    margin-right: -15rem;
  }
  .md\:-mr-64 {
    margin-right: -16rem;
  }
  .md\:-mr-72 {
    margin-right: -18rem;
  }
  .md\:-mr-80 {
    margin-right: -20rem;
  }
  .md\:-mr-96 {
    margin-right: -24rem;
  }
  .md\:-mr-px {
    margin-right: -1px;
  }
  .md\:-mr-0\.5 {
    margin-right: -0.125rem;
  }
  .md\:-mr-1\.5 {
    margin-right: -0.375rem;
  }
  .md\:-mr-2\.5 {
    margin-right: -0.625rem;
  }
  .md\:-mr-3\.5 {
    margin-right: -0.875rem;
  }
  .md\:mb-0 {
    margin-bottom: 0;
  }
  .md\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .md\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .md\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .md\:mb-4 {
    margin-bottom: 1rem;
  }
  .md\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .md\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .md\:mb-8 {
    margin-bottom: 2rem;
  }
  .md\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .md\:mb-10 {
    margin-bottom: 2.5rem;
  }
  .md\:mb-11 {
    margin-bottom: 2.75rem;
  }
  .md\:mb-12 {
    margin-bottom: 3rem;
  }
  .md\:mb-14 {
    margin-bottom: 3.5rem;
  }
  .md\:mb-16 {
    margin-bottom: 4rem;
  }
  .md\:mb-20 {
    margin-bottom: 5rem;
  }
  .md\:mb-24 {
    margin-bottom: 6rem;
  }
  .md\:mb-28 {
    margin-bottom: 7rem;
  }
  .md\:mb-32 {
    margin-bottom: 8rem;
  }
  .md\:mb-36 {
    margin-bottom: 9rem;
  }
  .md\:mb-40 {
    margin-bottom: 10rem;
  }
  .md\:mb-44 {
    margin-bottom: 11rem;
  }
  .md\:mb-48 {
    margin-bottom: 12rem;
  }
  .md\:mb-52 {
    margin-bottom: 13rem;
  }
  .md\:mb-56 {
    margin-bottom: 14rem;
  }
  .md\:mb-60 {
    margin-bottom: 15rem;
  }
  .md\:mb-64 {
    margin-bottom: 16rem;
  }
  .md\:mb-72 {
    margin-bottom: 18rem;
  }
  .md\:mb-80 {
    margin-bottom: 20rem;
  }
  .md\:mb-96 {
    margin-bottom: 24rem;
  }
  .md\:mb-auto {
    margin-bottom: auto;
  }
  .md\:mb-px {
    margin-bottom: 1px;
  }
  .md\:mb-0\.5 {
    margin-bottom: 0.125rem;
  }
  .md\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .md\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .md\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .md\:-mb-0 {
    margin-bottom: 0;
  }
  .md\:-mb-1 {
    margin-bottom: -0.25rem;
  }
  .md\:-mb-2 {
    margin-bottom: -0.5rem;
  }
  .md\:-mb-3 {
    margin-bottom: -0.75rem;
  }
  .md\:-mb-4 {
    margin-bottom: -1rem;
  }
  .md\:-mb-5 {
    margin-bottom: -1.25rem;
  }
  .md\:-mb-6 {
    margin-bottom: -1.5rem;
  }
  .md\:-mb-7 {
    margin-bottom: -1.75rem;
  }
  .md\:-mb-8 {
    margin-bottom: -2rem;
  }
  .md\:-mb-9 {
    margin-bottom: -2.25rem;
  }
  .md\:-mb-10 {
    margin-bottom: -2.5rem;
  }
  .md\:-mb-11 {
    margin-bottom: -2.75rem;
  }
  .md\:-mb-12 {
    margin-bottom: -3rem;
  }
  .md\:-mb-14 {
    margin-bottom: -3.5rem;
  }
  .md\:-mb-16 {
    margin-bottom: -4rem;
  }
  .md\:-mb-20 {
    margin-bottom: -5rem;
  }
  .md\:-mb-24 {
    margin-bottom: -6rem;
  }
  .md\:-mb-28 {
    margin-bottom: -7rem;
  }
  .md\:-mb-32 {
    margin-bottom: -8rem;
  }
  .md\:-mb-36 {
    margin-bottom: -9rem;
  }
  .md\:-mb-40 {
    margin-bottom: -10rem;
  }
  .md\:-mb-44 {
    margin-bottom: -11rem;
  }
  .md\:-mb-48 {
    margin-bottom: -12rem;
  }
  .md\:-mb-52 {
    margin-bottom: -13rem;
  }
  .md\:-mb-56 {
    margin-bottom: -14rem;
  }
  .md\:-mb-60 {
    margin-bottom: -15rem;
  }
  .md\:-mb-64 {
    margin-bottom: -16rem;
  }
  .md\:-mb-72 {
    margin-bottom: -18rem;
  }
  .md\:-mb-80 {
    margin-bottom: -20rem;
  }
  .md\:-mb-96 {
    margin-bottom: -24rem;
  }
  .md\:-mb-px {
    margin-bottom: -1px;
  }
  .md\:-mb-0\.5 {
    margin-bottom: -0.125rem;
  }
  .md\:-mb-1\.5 {
    margin-bottom: -0.375rem;
  }
  .md\:-mb-2\.5 {
    margin-bottom: -0.625rem;
  }
  .md\:-mb-3\.5 {
    margin-bottom: -0.875rem;
  }
  .md\:ml-0 {
    margin-left: 0;
  }
  .md\:ml-1 {
    margin-left: 0.25rem;
  }
  .md\:ml-2 {
    margin-left: 0.5rem;
  }
  .md\:ml-3 {
    margin-left: 0.75rem;
  }
  .md\:ml-4 {
    margin-left: 1rem;
  }
  .md\:ml-5 {
    margin-left: 1.25rem;
  }
  .md\:ml-6 {
    margin-left: 1.5rem;
  }
  .md\:ml-7 {
    margin-left: 1.75rem;
  }
  .md\:ml-8 {
    margin-left: 2rem;
  }
  .md\:ml-9 {
    margin-left: 2.25rem;
  }
  .md\:ml-10 {
    margin-left: 2.5rem;
  }
  .md\:ml-11 {
    margin-left: 2.75rem;
  }
  .md\:ml-12 {
    margin-left: 3rem;
  }
  .md\:ml-14 {
    margin-left: 3.5rem;
  }
  .md\:ml-16 {
    margin-left: 4rem;
  }
  .md\:ml-20 {
    margin-left: 5rem;
  }
  .md\:ml-24 {
    margin-left: 6rem;
  }
  .md\:ml-28 {
    margin-left: 7rem;
  }
  .md\:ml-32 {
    margin-left: 8rem;
  }
  .md\:ml-36 {
    margin-left: 9rem;
  }
  .md\:ml-40 {
    margin-left: 10rem;
  }
  .md\:ml-44 {
    margin-left: 11rem;
  }
  .md\:ml-48 {
    margin-left: 12rem;
  }
  .md\:ml-52 {
    margin-left: 13rem;
  }
  .md\:ml-56 {
    margin-left: 14rem;
  }
  .md\:ml-60 {
    margin-left: 15rem;
  }
  .md\:ml-64 {
    margin-left: 16rem;
  }
  .md\:ml-72 {
    margin-left: 18rem;
  }
  .md\:ml-80 {
    margin-left: 20rem;
  }
  .md\:ml-96 {
    margin-left: 24rem;
  }
  .md\:ml-auto {
    margin-left: auto;
  }
  .md\:ml-px {
    margin-left: 1px;
  }
  .md\:ml-0\.5 {
    margin-left: 0.125rem;
  }
  .md\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .md\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .md\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .md\:-ml-0 {
    margin-left: 0;
  }
  .md\:-ml-1 {
    margin-left: -0.25rem;
  }
  .md\:-ml-2 {
    margin-left: -0.5rem;
  }
  .md\:-ml-3 {
    margin-left: -0.75rem;
  }
  .md\:-ml-4 {
    margin-left: -1rem;
  }
  .md\:-ml-5 {
    margin-left: -1.25rem;
  }
  .md\:-ml-6 {
    margin-left: -1.5rem;
  }
  .md\:-ml-7 {
    margin-left: -1.75rem;
  }
  .md\:-ml-8 {
    margin-left: -2rem;
  }
  .md\:-ml-9 {
    margin-left: -2.25rem;
  }
  .md\:-ml-10 {
    margin-left: -2.5rem;
  }
  .md\:-ml-11 {
    margin-left: -2.75rem;
  }
  .md\:-ml-12 {
    margin-left: -3rem;
  }
  .md\:-ml-14 {
    margin-left: -3.5rem;
  }
  .md\:-ml-16 {
    margin-left: -4rem;
  }
  .md\:-ml-20 {
    margin-left: -5rem;
  }
  .md\:-ml-24 {
    margin-left: -6rem;
  }
  .md\:-ml-28 {
    margin-left: -7rem;
  }
  .md\:-ml-32 {
    margin-left: -8rem;
  }
  .md\:-ml-36 {
    margin-left: -9rem;
  }
  .md\:-ml-40 {
    margin-left: -10rem;
  }
  .md\:-ml-44 {
    margin-left: -11rem;
  }
  .md\:-ml-48 {
    margin-left: -12rem;
  }
  .md\:-ml-52 {
    margin-left: -13rem;
  }
  .md\:-ml-56 {
    margin-left: -14rem;
  }
  .md\:-ml-60 {
    margin-left: -15rem;
  }
  .md\:-ml-64 {
    margin-left: -16rem;
  }
  .md\:-ml-72 {
    margin-left: -18rem;
  }
  .md\:-ml-80 {
    margin-left: -20rem;
  }
  .md\:-ml-96 {
    margin-left: -24rem;
  }
  .md\:-ml-px {
    margin-left: -1px;
  }
  .md\:-ml-0\.5 {
    margin-left: -0.125rem;
  }
  .md\:-ml-1\.5 {
    margin-left: -0.375rem;
  }
  .md\:-ml-2\.5 {
    margin-left: -0.625rem;
  }
  .md\:-ml-3\.5 {
    margin-left: -0.875rem;
  }
  .md\:box-border {
    box-sizing: border-box;
  }
  .md\:box-content {
    box-sizing: content-box;
  }
  .md\:block {
    display: block;
  }
  .md\:inline-block {
    display: inline-block;
  }
  .md\:inline {
    display: inline;
  }
  .md\:flex {
    display: flex;
  }
  .md\:inline-flex {
    display: inline-flex;
  }
  .md\:table {
    display: table;
  }
  .md\:inline-table {
    display: inline-table;
  }
  .md\:table-caption {
    display: table-caption;
  }
  .md\:table-cell {
    display: table-cell;
  }
  .md\:table-column {
    display: table-column;
  }
  .md\:table-column-group {
    display: table-column-group;
  }
  .md\:table-footer-group {
    display: table-footer-group;
  }
  .md\:table-header-group {
    display: table-header-group;
  }
  .md\:table-row-group {
    display: table-row-group;
  }
  .md\:table-row {
    display: table-row;
  }
  .md\:flow-root {
    display: flow-root;
  }
  .md\:grid {
    display: grid;
  }
  .md\:inline-grid {
    display: inline-grid;
  }
  .md\:contents {
    display: contents;
  }
  .md\:list-item {
    display: list-item;
  }
  .md\:hidden {
    display: none;
  }
  .md\:h-0 {
    height: 0;
  }
  .md\:h-1 {
    height: 0.25rem;
  }
  .md\:h-2 {
    height: 0.5rem;
  }
  .md\:h-3 {
    height: 0.75rem;
  }
  .md\:h-4 {
    height: 1rem;
  }
  .md\:h-5 {
    height: 1.25rem;
  }
  .md\:h-6 {
    height: 1.5rem;
  }
  .md\:h-7 {
    height: 1.75rem;
  }
  .md\:h-8 {
    height: 2rem;
  }
  .md\:h-9 {
    height: 2.25rem;
  }
  .md\:h-10 {
    height: 2.5rem;
  }
  .md\:h-11 {
    height: 2.75rem;
  }
  .md\:h-12 {
    height: 3rem;
  }
  .md\:h-14 {
    height: 3.5rem;
  }
  .md\:h-16 {
    height: 4rem;
  }
  .md\:h-20 {
    height: 5rem;
  }
  .md\:h-24 {
    height: 6rem;
  }
  .md\:h-28 {
    height: 7rem;
  }
  .md\:h-32 {
    height: 8rem;
  }
  .md\:h-36 {
    height: 9rem;
  }
  .md\:h-40 {
    height: 10rem;
  }
  .md\:h-44 {
    height: 11rem;
  }
  .md\:h-48 {
    height: 12rem;
  }
  .md\:h-52 {
    height: 13rem;
  }
  .md\:h-56 {
    height: 14rem;
  }
  .md\:h-60 {
    height: 15rem;
  }
  .md\:h-64 {
    height: 16rem;
  }
  .md\:h-72 {
    height: 18rem;
  }
  .md\:h-80 {
    height: 20rem;
  }
  .md\:h-96 {
    height: 24rem;
  }
  .md\:h-auto {
    height: auto;
  }
  .md\:h-px {
    height: 1px;
  }
  .md\:h-0\.5 {
    height: 0.125rem;
  }
  .md\:h-1\.5 {
    height: 0.375rem;
  }
  .md\:h-2\.5 {
    height: 0.625rem;
  }
  .md\:h-3\.5 {
    height: 0.875rem;
  }
  .md\:h-1\/2 {
    height: 50%;
  }
  .md\:h-1\/3 {
    height: 33.333333%;
  }
  .md\:h-2\/3 {
    height: 66.666667%;
  }
  .md\:h-1\/4 {
    height: 25%;
  }
  .md\:h-2\/4 {
    height: 50%;
  }
  .md\:h-3\/4 {
    height: 75%;
  }
  .md\:h-1\/5 {
    height: 20%;
  }
  .md\:h-2\/5 {
    height: 40%;
  }
  .md\:h-3\/5 {
    height: 60%;
  }
  .md\:h-4\/5 {
    height: 80%;
  }
  .md\:h-1\/6 {
    height: 16.666667%;
  }
  .md\:h-2\/6 {
    height: 33.333333%;
  }
  .md\:h-3\/6 {
    height: 50%;
  }
  .md\:h-4\/6 {
    height: 66.666667%;
  }
  .md\:h-5\/6 {
    height: 83.333333%;
  }
  .md\:h-full {
    height: 100%;
  }
  .md\:h-screen {
    height: 100vh;
  }
  .md\:max-h-0 {
    max-height: 0;
  }
  .md\:max-h-1 {
    max-height: 0.25rem;
  }
  .md\:max-h-2 {
    max-height: 0.5rem;
  }
  .md\:max-h-3 {
    max-height: 0.75rem;
  }
  .md\:max-h-4 {
    max-height: 1rem;
  }
  .md\:max-h-5 {
    max-height: 1.25rem;
  }
  .md\:max-h-6 {
    max-height: 1.5rem;
  }
  .md\:max-h-7 {
    max-height: 1.75rem;
  }
  .md\:max-h-8 {
    max-height: 2rem;
  }
  .md\:max-h-9 {
    max-height: 2.25rem;
  }
  .md\:max-h-10 {
    max-height: 2.5rem;
  }
  .md\:max-h-11 {
    max-height: 2.75rem;
  }
  .md\:max-h-12 {
    max-height: 3rem;
  }
  .md\:max-h-14 {
    max-height: 3.5rem;
  }
  .md\:max-h-16 {
    max-height: 4rem;
  }
  .md\:max-h-20 {
    max-height: 5rem;
  }
  .md\:max-h-24 {
    max-height: 6rem;
  }
  .md\:max-h-28 {
    max-height: 7rem;
  }
  .md\:max-h-32 {
    max-height: 8rem;
  }
  .md\:max-h-36 {
    max-height: 9rem;
  }
  .md\:max-h-40 {
    max-height: 10rem;
  }
  .md\:max-h-44 {
    max-height: 11rem;
  }
  .md\:max-h-48 {
    max-height: 12rem;
  }
  .md\:max-h-52 {
    max-height: 13rem;
  }
  .md\:max-h-56 {
    max-height: 14rem;
  }
  .md\:max-h-60 {
    max-height: 15rem;
  }
  .md\:max-h-64 {
    max-height: 16rem;
  }
  .md\:max-h-72 {
    max-height: 18rem;
  }
  .md\:max-h-80 {
    max-height: 20rem;
  }
  .md\:max-h-96 {
    max-height: 24rem;
  }
  .md\:max-h-px {
    max-height: 1px;
  }
  .md\:max-h-0\.5 {
    max-height: 0.125rem;
  }
  .md\:max-h-1\.5 {
    max-height: 0.375rem;
  }
  .md\:max-h-2\.5 {
    max-height: 0.625rem;
  }
  .md\:max-h-3\.5 {
    max-height: 0.875rem;
  }
  .md\:max-h-full {
    max-height: 100%;
  }
  .md\:max-h-screen {
    max-height: 100vh;
  }
  .md\:min-h-0 {
    min-height: 0;
  }
  .md\:min-h-full {
    min-height: 100%;
  }
  .md\:min-h-screen {
    min-height: 100vh;
  }
  .md\:w-0 {
    width: 0;
  }
  .md\:w-1 {
    width: 0.25rem;
  }
  .md\:w-2 {
    width: 0.5rem;
  }
  .md\:w-3 {
    width: 0.75rem;
  }
  .md\:w-4 {
    width: 1rem;
  }
  .md\:w-5 {
    width: 1.25rem;
  }
  .md\:w-6 {
    width: 1.5rem;
  }
  .md\:w-7 {
    width: 1.75rem;
  }
  .md\:w-8 {
    width: 2rem;
  }
  .md\:w-9 {
    width: 2.25rem;
  }
  .md\:w-10 {
    width: 2.5rem;
  }
  .md\:w-11 {
    width: 2.75rem;
  }
  .md\:w-12 {
    width: 3rem;
  }
  .md\:w-14 {
    width: 3.5rem;
  }
  .md\:w-16 {
    width: 4rem;
  }
  .md\:w-20 {
    width: 5rem;
  }
  .md\:w-24 {
    width: 6rem;
  }
  .md\:w-28 {
    width: 7rem;
  }
  .md\:w-32 {
    width: 8rem;
  }
  .md\:w-36 {
    width: 9rem;
  }
  .md\:w-40 {
    width: 10rem;
  }
  .md\:w-44 {
    width: 11rem;
  }
  .md\:w-48 {
    width: 12rem;
  }
  .md\:w-52 {
    width: 13rem;
  }
  .md\:w-56 {
    width: 14rem;
  }
  .md\:w-60 {
    width: 15rem;
  }
  .md\:w-64 {
    width: 16rem;
  }
  .md\:w-72 {
    width: 18rem;
  }
  .md\:w-80 {
    width: 20rem;
  }
  .md\:w-96 {
    width: 24rem;
  }
  .md\:w-auto {
    width: auto;
  }
  .md\:w-px {
    width: 1px;
  }
  .md\:w-0\.5 {
    width: 0.125rem;
  }
  .md\:w-1\.5 {
    width: 0.375rem;
  }
  .md\:w-2\.5 {
    width: 0.625rem;
  }
  .md\:w-3\.5 {
    width: 0.875rem;
  }
  .md\:w-1\/2 {
    width: 50%;
  }
  .md\:w-1\/3 {
    width: 33.333333%;
  }
  .md\:w-2\/3 {
    width: 66.666667%;
  }
  .md\:w-1\/4 {
    width: 25%;
  }
  .md\:w-2\/4 {
    width: 50%;
  }
  .md\:w-3\/4 {
    width: 75%;
  }
  .md\:w-1\/5 {
    width: 20%;
  }
  .md\:w-2\/5 {
    width: 40%;
  }
  .md\:w-3\/5 {
    width: 60%;
  }
  .md\:w-4\/5 {
    width: 80%;
  }
  .md\:w-1\/6 {
    width: 16.666667%;
  }
  .md\:w-2\/6 {
    width: 33.333333%;
  }
  .md\:w-3\/6 {
    width: 50%;
  }
  .md\:w-4\/6 {
    width: 66.666667%;
  }
  .md\:w-5\/6 {
    width: 83.333333%;
  }
  .md\:w-1\/12 {
    width: 8.333333%;
  }
  .md\:w-2\/12 {
    width: 16.666667%;
  }
  .md\:w-3\/12 {
    width: 25%;
  }
  .md\:w-4\/12 {
    width: 33.333333%;
  }
  .md\:w-5\/12 {
    width: 41.666667%;
  }
  .md\:w-6\/12 {
    width: 50%;
  }
  .md\:w-7\/12 {
    width: 58.333333%;
  }
  .md\:w-8\/12 {
    width: 66.666667%;
  }
  .md\:w-9\/12 {
    width: 75%;
  }
  .md\:w-10\/12 {
    width: 83.333333%;
  }
  .md\:w-11\/12 {
    width: 91.666667%;
  }
  .md\:w-full {
    width: 100%;
  }
  .md\:w-screen {
    width: 100vw;
  }
  .md\:w-min {
    width: min-content;
  }
  .md\:w-max {
    width: max-content;
  }
  .md\:min-w-0 {
    min-width: 0;
  }
  .md\:min-w-full {
    min-width: 100%;
  }
  .md\:min-w-min {
    min-width: min-content;
  }
  .md\:min-w-max {
    min-width: max-content;
  }
  .md\:max-w-0 {
    max-width: 0;
  }
  .md\:max-w-none {
    max-width: none;
  }
  .md\:max-w-xs {
    max-width: 20rem;
  }
  .md\:max-w-sm {
    max-width: 24rem;
  }
  .md\:max-w-md {
    max-width: 28rem;
  }
  .md\:max-w-lg {
    max-width: 32rem;
  }
  .md\:max-w-xl {
    max-width: 36rem;
  }
  .md\:max-w-2xl {
    max-width: 42rem;
  }
  .md\:max-w-3xl {
    max-width: 48rem;
  }
  .md\:max-w-4xl {
    max-width: 56rem;
  }
  .md\:max-w-5xl {
    max-width: 64rem;
  }
  .md\:max-w-6xl {
    max-width: 72rem;
  }
  .md\:max-w-7xl {
    max-width: 80rem;
  }
  .md\:max-w-full {
    max-width: 100%;
  }
  .md\:max-w-min {
    max-width: min-content;
  }
  .md\:max-w-max {
    max-width: max-content;
  }
  .md\:max-w-prose {
    max-width: 65ch;
  }
  .md\:max-w-screen-sm {
    max-width: 640px;
  }
  .md\:max-w-screen-md {
    max-width: 768px;
  }
  .md\:max-w-screen-lg {
    max-width: 1024px;
  }
  .md\:max-w-screen-xl {
    max-width: 1280px;
  }
  .md\:max-w-screen-2xl {
    max-width: 1536px;
  }
  .md\:flex-1 {
    flex: 1 1 0%;
  }
  .md\:flex-auto {
    flex: 1 1 auto;
  }
  .md\:flex-initial {
    flex: 0 1 auto;
  }
  .md\:flex-none {
    flex: none;
  }
  .md\:flex-shrink-0 {
    flex-shrink: 0;
  }
  .md\:flex-shrink {
    flex-shrink: 1;
  }
  .md\:flex-grow-0 {
    flex-grow: 0;
  }
  .md\:flex-grow {
    flex-grow: 1;
  }
  .md\:table-auto {
    table-layout: auto;
  }
  .md\:table-fixed {
    table-layout: fixed;
  }
  .md\:border-collapse {
    border-collapse: collapse;
  }
  .md\:border-separate {
    border-collapse: separate;
  }
  .md\:origin-center {
    transform-origin: center;
  }
  .md\:origin-top {
    transform-origin: top;
  }
  .md\:origin-top-right {
    transform-origin: top right;
  }
  .md\:origin-right {
    transform-origin: right;
  }
  .md\:origin-bottom-right {
    transform-origin: bottom right;
  }
  .md\:origin-bottom {
    transform-origin: bottom;
  }
  .md\:origin-bottom-left {
    transform-origin: bottom left;
  }
  .md\:origin-left {
    transform-origin: left;
  }
  .md\:origin-top-left {
    transform-origin: top left;
  }
  .md\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  .md\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .md\:transform-none {
    transform: none;
  }
  .md\:translate-x-0 {
    --tw-translate-x: 0px;
  }
  .md\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }
  .md\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }
  .md\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }
  .md\:translate-x-4 {
    --tw-translate-x: 1rem;
  }
  .md\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }
  .md\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }
  .md\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }
  .md\:translate-x-8 {
    --tw-translate-x: 2rem;
  }
  .md\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }
  .md\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }
  .md\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }
  .md\:translate-x-12 {
    --tw-translate-x: 3rem;
  }
  .md\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }
  .md\:translate-x-16 {
    --tw-translate-x: 4rem;
  }
  .md\:translate-x-20 {
    --tw-translate-x: 5rem;
  }
  .md\:translate-x-24 {
    --tw-translate-x: 6rem;
  }
  .md\:translate-x-28 {
    --tw-translate-x: 7rem;
  }
  .md\:translate-x-32 {
    --tw-translate-x: 8rem;
  }
  .md\:translate-x-36 {
    --tw-translate-x: 9rem;
  }
  .md\:translate-x-40 {
    --tw-translate-x: 10rem;
  }
  .md\:translate-x-44 {
    --tw-translate-x: 11rem;
  }
  .md\:translate-x-48 {
    --tw-translate-x: 12rem;
  }
  .md\:translate-x-52 {
    --tw-translate-x: 13rem;
  }
  .md\:translate-x-56 {
    --tw-translate-x: 14rem;
  }
  .md\:translate-x-60 {
    --tw-translate-x: 15rem;
  }
  .md\:translate-x-64 {
    --tw-translate-x: 16rem;
  }
  .md\:translate-x-72 {
    --tw-translate-x: 18rem;
  }
  .md\:translate-x-80 {
    --tw-translate-x: 20rem;
  }
  .md\:translate-x-96 {
    --tw-translate-x: 24rem;
  }
  .md\:translate-x-px {
    --tw-translate-x: 1px;
  }
  .md\:translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }
  .md\:translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }
  .md\:translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }
  .md\:translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }
  .md\:-translate-x-0 {
    --tw-translate-x: 0px;
  }
  .md\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }
  .md\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }
  .md\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }
  .md\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }
  .md\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }
  .md\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }
  .md\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }
  .md\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }
  .md\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }
  .md\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }
  .md\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }
  .md\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }
  .md\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }
  .md\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }
  .md\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }
  .md\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }
  .md\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }
  .md\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }
  .md\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }
  .md\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }
  .md\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }
  .md\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }
  .md\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }
  .md\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }
  .md\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }
  .md\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }
  .md\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }
  .md\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }
  .md\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }
  .md\:-translate-x-px {
    --tw-translate-x: -1px;
  }
  .md\:-translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }
  .md\:-translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }
  .md\:-translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }
  .md\:-translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }
  .md\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }
  .md\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }
  .md\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }
  .md\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }
  .md\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }
  .md\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }
  .md\:translate-x-full {
    --tw-translate-x: 100%;
  }
  .md\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }
  .md\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }
  .md\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }
  .md\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }
  .md\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }
  .md\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }
  .md\:-translate-x-full {
    --tw-translate-x: -100%;
  }
  .md\:translate-y-0 {
    --tw-translate-y: 0px;
  }
  .md\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }
  .md\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }
  .md\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }
  .md\:translate-y-4 {
    --tw-translate-y: 1rem;
  }
  .md\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }
  .md\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }
  .md\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }
  .md\:translate-y-8 {
    --tw-translate-y: 2rem;
  }
  .md\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }
  .md\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }
  .md\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }
  .md\:translate-y-12 {
    --tw-translate-y: 3rem;
  }
  .md\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }
  .md\:translate-y-16 {
    --tw-translate-y: 4rem;
  }
  .md\:translate-y-20 {
    --tw-translate-y: 5rem;
  }
  .md\:translate-y-24 {
    --tw-translate-y: 6rem;
  }
  .md\:translate-y-28 {
    --tw-translate-y: 7rem;
  }
  .md\:translate-y-32 {
    --tw-translate-y: 8rem;
  }
  .md\:translate-y-36 {
    --tw-translate-y: 9rem;
  }
  .md\:translate-y-40 {
    --tw-translate-y: 10rem;
  }
  .md\:translate-y-44 {
    --tw-translate-y: 11rem;
  }
  .md\:translate-y-48 {
    --tw-translate-y: 12rem;
  }
  .md\:translate-y-52 {
    --tw-translate-y: 13rem;
  }
  .md\:translate-y-56 {
    --tw-translate-y: 14rem;
  }
  .md\:translate-y-60 {
    --tw-translate-y: 15rem;
  }
  .md\:translate-y-64 {
    --tw-translate-y: 16rem;
  }
  .md\:translate-y-72 {
    --tw-translate-y: 18rem;
  }
  .md\:translate-y-80 {
    --tw-translate-y: 20rem;
  }
  .md\:translate-y-96 {
    --tw-translate-y: 24rem;
  }
  .md\:translate-y-px {
    --tw-translate-y: 1px;
  }
  .md\:translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }
  .md\:translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }
  .md\:translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }
  .md\:translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }
  .md\:-translate-y-0 {
    --tw-translate-y: 0px;
  }
  .md\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }
  .md\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }
  .md\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }
  .md\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }
  .md\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }
  .md\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }
  .md\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }
  .md\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }
  .md\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }
  .md\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }
  .md\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }
  .md\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }
  .md\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }
  .md\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }
  .md\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }
  .md\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }
  .md\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }
  .md\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }
  .md\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }
  .md\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }
  .md\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }
  .md\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }
  .md\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }
  .md\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }
  .md\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }
  .md\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }
  .md\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }
  .md\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }
  .md\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }
  .md\:-translate-y-px {
    --tw-translate-y: -1px;
  }
  .md\:-translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }
  .md\:-translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }
  .md\:-translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }
  .md\:-translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }
  .md\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }
  .md\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }
  .md\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }
  .md\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }
  .md\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }
  .md\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }
  .md\:translate-y-full {
    --tw-translate-y: 100%;
  }
  .md\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }
  .md\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }
  .md\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }
  .md\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }
  .md\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }
  .md\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }
  .md\:-translate-y-full {
    --tw-translate-y: -100%;
  }
  .md\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .md\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }
  .md\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }
  .md\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }
  .md\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }
  .md\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }
  .md\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }
  .md\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }
  .md\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }
  .md\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }
  .md\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }
  .md\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }
  .md\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }
  .md\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }
  .md\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }
  .md\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }
  .md\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }
  .md\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }
  .md\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }
  .md\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }
  .md\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }
  .md\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }
  .md\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }
  .md\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }
  .md\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }
  .md\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }
  .md\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }
  .md\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }
  .md\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }
  .md\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }
  .md\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }
  .md\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }
  .md\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }
  .md\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }
  .md\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }
  .md\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .md\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }
  .md\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }
  .md\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }
  .md\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }
  .md\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }
  .md\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }
  .md\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }
  .md\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }
  .md\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }
  .md\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }
  .md\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }
  .md\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }
  .md\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }
  .md\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }
  .md\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }
  .md\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }
  .md\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }
  .md\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }
  .md\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }
  .md\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }
  .md\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }
  .md\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }
  .md\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }
  .md\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }
  .md\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }
  .md\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }
  .md\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }
  .md\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }
  .md\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }
  .md\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }
  .md\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }
  .md\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }
  .md\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }
  .md\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }
  .md\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }
  .md\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }
  .md\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }
  .md\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }
  .md\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }
  .md\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }
  .md\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }
  .md\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }
  .md\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }
  .md\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }
  .md\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }
  .md\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }
  .md\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }
  .md\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }
  .md\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .md\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }
  .md\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }
  .md\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }
  .md\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }
  .md\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }
  .md\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }
  .md\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }
  .md\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }
  .md\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }
  .md\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }
  .md\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }
  .md\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }
  .md\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }
  .md\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }
  .md\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }
  .md\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }
  .md\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }
  .md\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }
  .md\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }
  .md\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }
  .md\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }
  .md\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }
  .md\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }
  .md\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }
  .md\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }
  .md\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }
  .md\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }
  .md\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }
  .md\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }
  .md\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }
  .md\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }
  .md\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }
  .md\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }
  .md\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }
  .md\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .md\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }
  .md\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }
  .md\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }
  .md\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }
  .md\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }
  .md\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }
  .md\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }
  .md\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }
  .md\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }
  .md\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }
  .md\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }
  .md\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }
  .md\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }
  .md\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }
  .md\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }
  .md\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }
  .md\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }
  .md\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }
  .md\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }
  .md\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }
  .md\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }
  .md\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }
  .md\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }
  .md\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }
  .md\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }
  .md\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }
  .md\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }
  .md\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }
  .md\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }
  .md\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }
  .md\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }
  .md\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }
  .md\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }
  .md\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }
  .md\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }
  .md\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }
  .md\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }
  .md\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }
  .md\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }
  .md\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }
  .md\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }
  .md\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }
  .md\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }
  .md\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }
  .md\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }
  .md\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }
  .md\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }
  .md\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }
  .md\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .md\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }
  .md\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }
  .md\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }
  .md\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }
  .md\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }
  .md\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }
  .md\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }
  .md\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }
  .md\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }
  .md\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }
  .md\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }
  .md\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }
  .md\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }
  .md\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }
  .md\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }
  .md\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }
  .md\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }
  .md\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }
  .md\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }
  .md\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }
  .md\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }
  .md\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }
  .md\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }
  .md\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }
  .md\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }
  .md\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }
  .md\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }
  .md\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }
  .md\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }
  .md\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }
  .md\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }
  .md\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }
  .md\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }
  .md\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }
  .md\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .md\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }
  .md\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }
  .md\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }
  .md\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }
  .md\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }
  .md\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }
  .md\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }
  .md\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }
  .md\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }
  .md\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }
  .md\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }
  .md\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }
  .md\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }
  .md\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }
  .md\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }
  .md\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }
  .md\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }
  .md\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }
  .md\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }
  .md\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }
  .md\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }
  .md\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }
  .md\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }
  .md\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }
  .md\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }
  .md\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }
  .md\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }
  .md\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }
  .md\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }
  .md\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }
  .md\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }
  .md\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }
  .md\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }
  .md\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }
  .md\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }
  .md\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }
  .md\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }
  .md\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }
  .md\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }
  .md\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }
  .md\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }
  .md\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }
  .md\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }
  .md\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }
  .md\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }
  .md\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }
  .md\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }
  .md\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }
  .md\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .md\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }
  .md\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }
  .md\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }
  .md\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }
  .md\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }
  .md\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }
  .md\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }
  .md\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }
  .md\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }
  .md\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }
  .md\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }
  .md\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }
  .md\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }
  .md\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }
  .md\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }
  .md\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }
  .md\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }
  .md\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }
  .md\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }
  .md\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }
  .md\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }
  .md\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }
  .md\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }
  .md\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }
  .md\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }
  .md\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }
  .md\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }
  .md\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }
  .md\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }
  .md\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }
  .md\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }
  .md\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }
  .md\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }
  .md\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }
  .md\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .md\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }
  .md\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }
  .md\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }
  .md\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }
  .md\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }
  .md\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }
  .md\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }
  .md\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }
  .md\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }
  .md\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }
  .md\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }
  .md\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }
  .md\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }
  .md\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }
  .md\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }
  .md\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }
  .md\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }
  .md\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }
  .md\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }
  .md\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }
  .md\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }
  .md\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }
  .md\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }
  .md\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }
  .md\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }
  .md\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }
  .md\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }
  .md\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }
  .md\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }
  .md\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }
  .md\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }
  .md\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }
  .md\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }
  .md\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }
  .md\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }
  .md\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }
  .md\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }
  .md\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }
  .md\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }
  .md\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }
  .md\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }
  .md\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }
  .md\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }
  .md\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }
  .md\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }
  .md\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }
  .md\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }
  .md\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }
  .md\:rotate-0 {
    --tw-rotate: 0deg;
  }
  .md\:rotate-1 {
    --tw-rotate: 1deg;
  }
  .md\:rotate-2 {
    --tw-rotate: 2deg;
  }
  .md\:rotate-3 {
    --tw-rotate: 3deg;
  }
  .md\:rotate-6 {
    --tw-rotate: 6deg;
  }
  .md\:rotate-12 {
    --tw-rotate: 12deg;
  }
  .md\:rotate-45 {
    --tw-rotate: 45deg;
  }
  .md\:rotate-90 {
    --tw-rotate: 90deg;
  }
  .md\:rotate-180 {
    --tw-rotate: 180deg;
  }
  .md\:-rotate-180 {
    --tw-rotate: -180deg;
  }
  .md\:-rotate-90 {
    --tw-rotate: -90deg;
  }
  .md\:-rotate-45 {
    --tw-rotate: -45deg;
  }
  .md\:-rotate-12 {
    --tw-rotate: -12deg;
  }
  .md\:-rotate-6 {
    --tw-rotate: -6deg;
  }
  .md\:-rotate-3 {
    --tw-rotate: -3deg;
  }
  .md\:-rotate-2 {
    --tw-rotate: -2deg;
  }
  .md\:-rotate-1 {
    --tw-rotate: -1deg;
  }
  .md\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }
  .md\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }
  .md\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }
  .md\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }
  .md\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }
  .md\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }
  .md\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }
  .md\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }
  .md\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }
  .md\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }
  .md\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }
  .md\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }
  .md\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }
  .md\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }
  .md\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }
  .md\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }
  .md\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }
  .md\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }
  .md\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }
  .md\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }
  .md\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }
  .md\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }
  .md\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }
  .md\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }
  .md\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }
  .md\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }
  .md\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }
  .md\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }
  .md\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }
  .md\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }
  .md\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }
  .md\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }
  .md\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }
  .md\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }
  .md\:skew-x-0 {
    --tw-skew-x: 0deg;
  }
  .md\:skew-x-1 {
    --tw-skew-x: 1deg;
  }
  .md\:skew-x-2 {
    --tw-skew-x: 2deg;
  }
  .md\:skew-x-3 {
    --tw-skew-x: 3deg;
  }
  .md\:skew-x-6 {
    --tw-skew-x: 6deg;
  }
  .md\:skew-x-12 {
    --tw-skew-x: 12deg;
  }
  .md\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }
  .md\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }
  .md\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }
  .md\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }
  .md\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }
  .md\:skew-y-0 {
    --tw-skew-y: 0deg;
  }
  .md\:skew-y-1 {
    --tw-skew-y: 1deg;
  }
  .md\:skew-y-2 {
    --tw-skew-y: 2deg;
  }
  .md\:skew-y-3 {
    --tw-skew-y: 3deg;
  }
  .md\:skew-y-6 {
    --tw-skew-y: 6deg;
  }
  .md\:skew-y-12 {
    --tw-skew-y: 12deg;
  }
  .md\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }
  .md\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }
  .md\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }
  .md\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }
  .md\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }
  .md\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }
  .md\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }
  .md\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }
  .md\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }
  .md\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }
  .md\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }
  .md\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }
  .md\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }
  .md\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }
  .md\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }
  .md\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }
  .md\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }
  .md\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }
  .md\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }
  .md\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }
  .md\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }
  .md\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }
  .md\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }
  .md\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }
  .md\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }
  .md\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }
  .md\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }
  .md\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }
  .md\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }
  .md\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }
  .md\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }
  .md\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }
  .md\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }
  .md\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }
  .md\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }
  .md\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }
  .md\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }
  .md\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }
  .md\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }
  .md\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }
  .md\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }
  .md\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }
  .md\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }
  .md\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }
  .md\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }
  .md\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }
  .md\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }
  .md\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }
  .md\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }
  .md\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .md\:scale-50 {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .md\:scale-75 {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .md\:scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .md\:scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .md\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .md\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .md\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .md\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .md\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .md\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .md\:hover\:scale-50:hover {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .md\:hover\:scale-75:hover {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .md\:hover\:scale-90:hover {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .md\:hover\:scale-95:hover {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .md\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .md\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .md\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .md\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .md\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .md\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .md\:focus\:scale-50:focus {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .md\:focus\:scale-75:focus {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .md\:focus\:scale-90:focus {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .md\:focus\:scale-95:focus {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .md\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .md\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .md\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .md\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .md\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .md\:scale-x-0 {
    --tw-scale-x: 0;
  }
  .md\:scale-x-50 {
    --tw-scale-x: 0.5;
  }
  .md\:scale-x-75 {
    --tw-scale-x: 0.75;
  }
  .md\:scale-x-90 {
    --tw-scale-x: 0.9;
  }
  .md\:scale-x-95 {
    --tw-scale-x: 0.95;
  }
  .md\:scale-x-100 {
    --tw-scale-x: 1;
  }
  .md\:scale-x-105 {
    --tw-scale-x: 1.05;
  }
  .md\:scale-x-110 {
    --tw-scale-x: 1.1;
  }
  .md\:scale-x-125 {
    --tw-scale-x: 1.25;
  }
  .md\:scale-x-150 {
    --tw-scale-x: 1.5;
  }
  .md\:scale-y-0 {
    --tw-scale-y: 0;
  }
  .md\:scale-y-50 {
    --tw-scale-y: 0.5;
  }
  .md\:scale-y-75 {
    --tw-scale-y: 0.75;
  }
  .md\:scale-y-90 {
    --tw-scale-y: 0.9;
  }
  .md\:scale-y-95 {
    --tw-scale-y: 0.95;
  }
  .md\:scale-y-100 {
    --tw-scale-y: 1;
  }
  .md\:scale-y-105 {
    --tw-scale-y: 1.05;
  }
  .md\:scale-y-110 {
    --tw-scale-y: 1.1;
  }
  .md\:scale-y-125 {
    --tw-scale-y: 1.25;
  }
  .md\:scale-y-150 {
    --tw-scale-y: 1.5;
  }
  .md\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }
  .md\:hover\:scale-x-50:hover {
    --tw-scale-x: 0.5;
  }
  .md\:hover\:scale-x-75:hover {
    --tw-scale-x: 0.75;
  }
  .md\:hover\:scale-x-90:hover {
    --tw-scale-x: 0.9;
  }
  .md\:hover\:scale-x-95:hover {
    --tw-scale-x: 0.95;
  }
  .md\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }
  .md\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }
  .md\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }
  .md\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }
  .md\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }
  .md\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }
  .md\:hover\:scale-y-50:hover {
    --tw-scale-y: 0.5;
  }
  .md\:hover\:scale-y-75:hover {
    --tw-scale-y: 0.75;
  }
  .md\:hover\:scale-y-90:hover {
    --tw-scale-y: 0.9;
  }
  .md\:hover\:scale-y-95:hover {
    --tw-scale-y: 0.95;
  }
  .md\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }
  .md\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }
  .md\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }
  .md\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }
  .md\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }
  .md\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }
  .md\:focus\:scale-x-50:focus {
    --tw-scale-x: 0.5;
  }
  .md\:focus\:scale-x-75:focus {
    --tw-scale-x: 0.75;
  }
  .md\:focus\:scale-x-90:focus {
    --tw-scale-x: 0.9;
  }
  .md\:focus\:scale-x-95:focus {
    --tw-scale-x: 0.95;
  }
  .md\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }
  .md\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }
  .md\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }
  .md\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }
  .md\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }
  .md\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }
  .md\:focus\:scale-y-50:focus {
    --tw-scale-y: 0.5;
  }
  .md\:focus\:scale-y-75:focus {
    --tw-scale-y: 0.75;
  }
  .md\:focus\:scale-y-90:focus {
    --tw-scale-y: 0.9;
  }
  .md\:focus\:scale-y-95:focus {
    --tw-scale-y: 0.95;
  }
  .md\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }
  .md\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }
  .md\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }
  .md\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }
  .md\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }
  .md\:animate-none {
    animation: none;
  }
  .md\:animate-spin {
    animation: spin 1s linear infinite;
  }
  .md\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .md\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .md\:animate-bounce {
    animation: bounce 1s infinite;
  }
  .md\:cursor-auto {
    cursor: auto;
  }
  .md\:cursor-default {
    cursor: default;
  }
  .md\:cursor-pointer {
    cursor: pointer;
  }
  .md\:cursor-wait {
    cursor: wait;
  }
  .md\:cursor-text {
    cursor: text;
  }
  .md\:cursor-move {
    cursor: move;
  }
  .md\:cursor-help {
    cursor: help;
  }
  .md\:cursor-not-allowed {
    cursor: not-allowed;
  }
  .md\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }
  .md\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }
  .md\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }
  .md\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }
  .md\:resize-none {
    resize: none;
  }
  .md\:resize-y {
    resize: vertical;
  }
  .md\:resize-x {
    resize: horizontal;
  }
  .md\:resize {
    resize: both;
  }
  .md\:list-inside {
    list-style-position: inside;
  }
  .md\:list-outside {
    list-style-position: outside;
  }
  .md\:list-none {
    list-style-type: none;
  }
  .md\:list-disc {
    list-style-type: disc;
  }
  .md\:list-decimal {
    list-style-type: decimal;
  }
  .md\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }
  .md\:auto-cols-auto {
    grid-auto-columns: auto;
  }
  .md\:auto-cols-min {
    grid-auto-columns: min-content;
  }
  .md\:auto-cols-max {
    grid-auto-columns: max-content;
  }
  .md\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }
  .md\:grid-flow-row {
    grid-auto-flow: row;
  }
  .md\:grid-flow-col {
    grid-auto-flow: column;
  }
  .md\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }
  .md\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }
  .md\:auto-rows-auto {
    grid-auto-rows: auto;
  }
  .md\:auto-rows-min {
    grid-auto-rows: min-content;
  }
  .md\:auto-rows-max {
    grid-auto-rows: max-content;
  }
  .md\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }
  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .md\:grid-cols-none {
    grid-template-columns: none;
  }
  .md\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  .md\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .md\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .md\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .md\:grid-rows-none {
    grid-template-rows: none;
  }
  .md\:flex-row {
    flex-direction: row;
  }
  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .md\:flex-col {
    flex-direction: column;
  }
  .md\:flex-col-reverse {
    flex-direction: column-reverse;
  }
  .md\:flex-wrap {
    flex-wrap: wrap;
  }
  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }
  .md\:place-content-center {
    place-content: center;
  }
  .md\:place-content-start {
    place-content: start;
  }
  .md\:place-content-end {
    place-content: end;
  }
  .md\:place-content-between {
    place-content: space-between;
  }
  .md\:place-content-around {
    place-content: space-around;
  }
  .md\:place-content-evenly {
    place-content: space-evenly;
  }
  .md\:place-content-stretch {
    place-content: stretch;
  }
  .md\:place-items-start {
    place-items: start;
  }
  .md\:place-items-end {
    place-items: end;
  }
  .md\:place-items-center {
    place-items: center;
  }
  .md\:place-items-stretch {
    place-items: stretch;
  }
  .md\:content-center {
    align-content: center;
  }
  .md\:content-start {
    align-content: flex-start;
  }
  .md\:content-end {
    align-content: flex-end;
  }
  .md\:content-between {
    align-content: space-between;
  }
  .md\:content-around {
    align-content: space-around;
  }
  .md\:content-evenly {
    align-content: space-evenly;
  }
  .md\:items-start {
    align-items: flex-start;
  }
  .md\:items-end {
    align-items: flex-end;
  }
  .md\:items-center {
    align-items: center;
  }
  .md\:items-baseline {
    align-items: baseline;
  }
  .md\:items-stretch {
    align-items: stretch;
  }
  .md\:justify-start {
    justify-content: flex-start;
  }
  .md\:justify-end {
    justify-content: flex-end;
  }
  .md\:justify-center {
    justify-content: center;
  }
  .md\:justify-between {
    justify-content: space-between;
  }
  .md\:justify-around {
    justify-content: space-around;
  }
  .md\:justify-evenly {
    justify-content: space-evenly;
  }
  .md\:justify-items-start {
    justify-items: start;
  }
  .md\:justify-items-end {
    justify-items: end;
  }
  .md\:justify-items-center {
    justify-items: center;
  }
  .md\:justify-items-stretch {
    justify-items: stretch;
  }
  .md\:gap-0 {
    gap: 0;
  }
  .md\:gap-1 {
    gap: 0.25rem;
  }
  .md\:gap-2 {
    gap: 0.5rem;
  }
  .md\:gap-3 {
    gap: 0.75rem;
  }
  .md\:gap-4 {
    gap: 1rem;
  }
  .md\:gap-5 {
    gap: 1.25rem;
  }
  .md\:gap-6 {
    gap: 1.5rem;
  }
  .md\:gap-7 {
    gap: 1.75rem;
  }
  .md\:gap-8 {
    gap: 2rem;
  }
  .md\:gap-9 {
    gap: 2.25rem;
  }
  .md\:gap-10 {
    gap: 2.5rem;
  }
  .md\:gap-11 {
    gap: 2.75rem;
  }
  .md\:gap-12 {
    gap: 3rem;
  }
  .md\:gap-14 {
    gap: 3.5rem;
  }
  .md\:gap-16 {
    gap: 4rem;
  }
  .md\:gap-20 {
    gap: 5rem;
  }
  .md\:gap-24 {
    gap: 6rem;
  }
  .md\:gap-28 {
    gap: 7rem;
  }
  .md\:gap-32 {
    gap: 8rem;
  }
  .md\:gap-36 {
    gap: 9rem;
  }
  .md\:gap-40 {
    gap: 10rem;
  }
  .md\:gap-44 {
    gap: 11rem;
  }
  .md\:gap-48 {
    gap: 12rem;
  }
  .md\:gap-52 {
    gap: 13rem;
  }
  .md\:gap-56 {
    gap: 14rem;
  }
  .md\:gap-60 {
    gap: 15rem;
  }
  .md\:gap-64 {
    gap: 16rem;
  }
  .md\:gap-72 {
    gap: 18rem;
  }
  .md\:gap-80 {
    gap: 20rem;
  }
  .md\:gap-96 {
    gap: 24rem;
  }
  .md\:gap-px {
    gap: 1px;
  }
  .md\:gap-0\.5 {
    gap: 0.125rem;
  }
  .md\:gap-1\.5 {
    gap: 0.375rem;
  }
  .md\:gap-2\.5 {
    gap: 0.625rem;
  }
  .md\:gap-3\.5 {
    gap: 0.875rem;
  }
  .md\:gap-x-0 {
    column-gap: 0;
  }
  .md\:gap-x-1 {
    column-gap: 0.25rem;
  }
  .md\:gap-x-2 {
    column-gap: 0.5rem;
  }
  .md\:gap-x-3 {
    column-gap: 0.75rem;
  }
  .md\:gap-x-4 {
    column-gap: 1rem;
  }
  .md\:gap-x-5 {
    column-gap: 1.25rem;
  }
  .md\:gap-x-6 {
    column-gap: 1.5rem;
  }
  .md\:gap-x-7 {
    column-gap: 1.75rem;
  }
  .md\:gap-x-8 {
    column-gap: 2rem;
  }
  .md\:gap-x-9 {
    column-gap: 2.25rem;
  }
  .md\:gap-x-10 {
    column-gap: 2.5rem;
  }
  .md\:gap-x-11 {
    column-gap: 2.75rem;
  }
  .md\:gap-x-12 {
    column-gap: 3rem;
  }
  .md\:gap-x-14 {
    column-gap: 3.5rem;
  }
  .md\:gap-x-16 {
    column-gap: 4rem;
  }
  .md\:gap-x-20 {
    column-gap: 5rem;
  }
  .md\:gap-x-24 {
    column-gap: 6rem;
  }
  .md\:gap-x-28 {
    column-gap: 7rem;
  }
  .md\:gap-x-32 {
    column-gap: 8rem;
  }
  .md\:gap-x-36 {
    column-gap: 9rem;
  }
  .md\:gap-x-40 {
    column-gap: 10rem;
  }
  .md\:gap-x-44 {
    column-gap: 11rem;
  }
  .md\:gap-x-48 {
    column-gap: 12rem;
  }
  .md\:gap-x-52 {
    column-gap: 13rem;
  }
  .md\:gap-x-56 {
    column-gap: 14rem;
  }
  .md\:gap-x-60 {
    column-gap: 15rem;
  }
  .md\:gap-x-64 {
    column-gap: 16rem;
  }
  .md\:gap-x-72 {
    column-gap: 18rem;
  }
  .md\:gap-x-80 {
    column-gap: 20rem;
  }
  .md\:gap-x-96 {
    column-gap: 24rem;
  }
  .md\:gap-x-px {
    column-gap: 1px;
  }
  .md\:gap-x-0\.5 {
    column-gap: 0.125rem;
  }
  .md\:gap-x-1\.5 {
    column-gap: 0.375rem;
  }
  .md\:gap-x-2\.5 {
    column-gap: 0.625rem;
  }
  .md\:gap-x-3\.5 {
    column-gap: 0.875rem;
  }
  .md\:gap-y-0 {
    row-gap: 0;
  }
  .md\:gap-y-1 {
    row-gap: 0.25rem;
  }
  .md\:gap-y-2 {
    row-gap: 0.5rem;
  }
  .md\:gap-y-3 {
    row-gap: 0.75rem;
  }
  .md\:gap-y-4 {
    row-gap: 1rem;
  }
  .md\:gap-y-5 {
    row-gap: 1.25rem;
  }
  .md\:gap-y-6 {
    row-gap: 1.5rem;
  }
  .md\:gap-y-7 {
    row-gap: 1.75rem;
  }
  .md\:gap-y-8 {
    row-gap: 2rem;
  }
  .md\:gap-y-9 {
    row-gap: 2.25rem;
  }
  .md\:gap-y-10 {
    row-gap: 2.5rem;
  }
  .md\:gap-y-11 {
    row-gap: 2.75rem;
  }
  .md\:gap-y-12 {
    row-gap: 3rem;
  }
  .md\:gap-y-14 {
    row-gap: 3.5rem;
  }
  .md\:gap-y-16 {
    row-gap: 4rem;
  }
  .md\:gap-y-20 {
    row-gap: 5rem;
  }
  .md\:gap-y-24 {
    row-gap: 6rem;
  }
  .md\:gap-y-28 {
    row-gap: 7rem;
  }
  .md\:gap-y-32 {
    row-gap: 8rem;
  }
  .md\:gap-y-36 {
    row-gap: 9rem;
  }
  .md\:gap-y-40 {
    row-gap: 10rem;
  }
  .md\:gap-y-44 {
    row-gap: 11rem;
  }
  .md\:gap-y-48 {
    row-gap: 12rem;
  }
  .md\:gap-y-52 {
    row-gap: 13rem;
  }
  .md\:gap-y-56 {
    row-gap: 14rem;
  }
  .md\:gap-y-60 {
    row-gap: 15rem;
  }
  .md\:gap-y-64 {
    row-gap: 16rem;
  }
  .md\:gap-y-72 {
    row-gap: 18rem;
  }
  .md\:gap-y-80 {
    row-gap: 20rem;
  }
  .md\:gap-y-96 {
    row-gap: 24rem;
  }
  .md\:gap-y-px {
    row-gap: 1px;
  }
  .md\:gap-y-0\.5 {
    row-gap: 0.125rem;
  }
  .md\:gap-y-1\.5 {
    row-gap: 0.375rem;
  }
  .md\:gap-y-2\.5 {
    row-gap: 0.625rem;
  }
  .md\:gap-y-3\.5 {
    row-gap: 0.875rem;
  }
  .md\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .md\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .md\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }
  .md\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .md\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }
  .md\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }
  .md\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }
  .md\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }
  .md\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }
  .md\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .md\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .md\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .md\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .md\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .md\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }
  .md\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }
  .md\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }
  .md\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }
  .md\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }
  .md\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }
  .md\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }
  .md\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }
  .md\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }
  .md\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }
  .md\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }
  .md\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }
  .md\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }
  .md\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }
  .md\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }
  .md\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }
  .md\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }
  .md\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }
  .md\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .md\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }
  .md\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }
  .md\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }
  .md\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }
  .md\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }
  .md\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }
  .md\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }
  .md\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }
  .md\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }
  .md\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }
  .md\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }
  .md\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }
  .md\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }
  .md\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }
  .md\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }
  .md\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }
  .md\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }
  .md\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }
  .md\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }
  .md\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }
  .md\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }
  .md\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }
  .md\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }
  .md\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }
  .md\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }
  .md\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }
  .md\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }
  .md\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }
  .md\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }
  .md\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }
  .md\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }
  .md\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }
  .md\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }
  .md\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }
  .md\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }
  .md\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }
  .md\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }
  .md\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }
  .md\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }
  .md\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }
  .md\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }
  .md\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }
  .md\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }
  .md\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }
  .md\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }
  .md\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }
  .md\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }
  .md\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }
  .md\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }
  .md\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }
  .md\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }
  .md\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }
  .md\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }
  .md\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }
  .md\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }
  .md\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }
  .md\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }
  .md\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }
  .md\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }
  .md\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }
  .md\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }
  .md\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }
  .md\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }
  .md\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }
  .md\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }
  .md\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }
  .md\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }
  .md\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }
  .md\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }
  .md\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }
  .md\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }
  .md\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }
  .md\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }
  .md\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }
  .md\:place-self-auto {
    place-self: auto;
  }
  .md\:place-self-start {
    place-self: start;
  }
  .md\:place-self-end {
    place-self: end;
  }
  .md\:place-self-center {
    place-self: center;
  }
  .md\:place-self-stretch {
    place-self: stretch;
  }
  .md\:self-auto {
    align-self: auto;
  }
  .md\:self-start {
    align-self: flex-start;
  }
  .md\:self-end {
    align-self: flex-end;
  }
  .md\:self-center {
    align-self: center;
  }
  .md\:self-stretch {
    align-self: stretch;
  }
  .md\:self-baseline {
    align-self: baseline;
  }
  .md\:justify-self-auto {
    justify-self: auto;
  }
  .md\:justify-self-start {
    justify-self: start;
  }
  .md\:justify-self-end {
    justify-self: end;
  }
  .md\:justify-self-center {
    justify-self: center;
  }
  .md\:justify-self-stretch {
    justify-self: stretch;
  }
  .md\:overflow-auto {
    overflow: auto;
  }
  .md\:overflow-hidden {
    overflow: hidden;
  }
  .md\:overflow-visible {
    overflow: visible;
  }
  .md\:overflow-scroll {
    overflow: scroll;
  }
  .md\:overflow-x-auto {
    overflow-x: auto;
  }
  .md\:overflow-y-auto {
    overflow-y: auto;
  }
  .md\:overflow-x-hidden {
    overflow-x: hidden;
  }
  .md\:overflow-y-hidden {
    overflow-y: hidden;
  }
  .md\:overflow-x-visible {
    overflow-x: visible;
  }
  .md\:overflow-y-visible {
    overflow-y: visible;
  }
  .md\:overflow-x-scroll {
    overflow-x: scroll;
  }
  .md\:overflow-y-scroll {
    overflow-y: scroll;
  }
  .md\:overscroll-auto {
    overscroll-behavior: auto;
  }
  .md\:overscroll-contain {
    overscroll-behavior: contain;
  }
  .md\:overscroll-none {
    overscroll-behavior: none;
  }
  .md\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }
  .md\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }
  .md\:overscroll-y-none {
    overscroll-behavior-y: none;
  }
  .md\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }
  .md\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }
  .md\:overscroll-x-none {
    overscroll-behavior-x: none;
  }
  .md\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .md\:overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .md\:overflow-clip {
    text-overflow: clip;
  }
  .md\:whitespace-normal {
    white-space: normal;
  }
  .md\:whitespace-nowrap {
    white-space: nowrap;
  }
  .md\:whitespace-pre {
    white-space: pre;
  }
  .md\:whitespace-pre-line {
    white-space: pre-line;
  }
  .md\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }
  .md\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .md\:break-words {
    overflow-wrap: break-word;
  }
  .md\:break-all {
    word-break: break-all;
  }
  .md\:rounded-none {
    border-radius: 0;
  }
  .md\:rounded-sm {
    border-radius: 0.125rem;
  }
  .md\:rounded {
    border-radius: 0.25rem;
  }
  .md\:rounded-md {
    border-radius: 0.375rem;
  }
  .md\:rounded-lg {
    border-radius: 0.5rem;
  }
  .md\:rounded-xl {
    border-radius: 0.75rem;
  }
  .md\:rounded-2xl {
    border-radius: 1rem;
  }
  .md\:rounded-3xl {
    border-radius: 1.5rem;
  }
  .md\:rounded-full {
    border-radius: 9999px;
  }
  .md\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .md\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }
  .md\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .md\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .md\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .md\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .md\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .md\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
  .md\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }
  .md\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .md\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
  .md\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .md\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .md\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .md\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .md\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .md\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  .md\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }
  .md\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .md\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .md\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .md\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .md\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .md\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .md\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .md\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .md\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .md\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .md\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .md\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .md\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .md\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .md\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .md\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .md\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .md\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .md\:rounded-tl-none {
    border-top-left-radius: 0;
  }
  .md\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }
  .md\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }
  .md\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }
  .md\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }
  .md\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }
  .md\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }
  .md\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }
  .md\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }
  .md\:rounded-tr-none {
    border-top-right-radius: 0;
  }
  .md\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }
  .md\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }
  .md\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }
  .md\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }
  .md\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }
  .md\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }
  .md\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }
  .md\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }
  .md\:rounded-br-none {
    border-bottom-right-radius: 0;
  }
  .md\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }
  .md\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }
  .md\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }
  .md\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }
  .md\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }
  .md\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }
  .md\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }
  .md\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }
  .md\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }
  .md\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }
  .md\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }
  .md\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }
  .md\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }
  .md\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }
  .md\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }
  .md\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }
  .md\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }
  .md\:border-0 {
    border-width: 0;
  }
  .md\:border-2 {
    border-width: 2px;
  }
  .md\:border-4 {
    border-width: 4px;
  }
  .md\:border-8 {
    border-width: 8px;
  }
  .md\:border {
    border-width: 1px;
  }
  .md\:border-t-0 {
    border-top-width: 0;
  }
  .md\:border-t-2 {
    border-top-width: 2px;
  }
  .md\:border-t-4 {
    border-top-width: 4px;
  }
  .md\:border-t-8 {
    border-top-width: 8px;
  }
  .md\:border-t {
    border-top-width: 1px;
  }
  .md\:border-r-0 {
    border-right-width: 0;
  }
  .md\:border-r-2 {
    border-right-width: 2px;
  }
  .md\:border-r-4 {
    border-right-width: 4px;
  }
  .md\:border-r-8 {
    border-right-width: 8px;
  }
  .md\:border-r {
    border-right-width: 1px;
  }
  .md\:border-b-0 {
    border-bottom-width: 0;
  }
  .md\:border-b-2 {
    border-bottom-width: 2px;
  }
  .md\:border-b-4 {
    border-bottom-width: 4px;
  }
  .md\:border-b-8 {
    border-bottom-width: 8px;
  }
  .md\:border-b {
    border-bottom-width: 1px;
  }
  .md\:border-l-0 {
    border-left-width: 0;
  }
  .md\:border-l-2 {
    border-left-width: 2px;
  }
  .md\:border-l-4 {
    border-left-width: 4px;
  }
  .md\:border-l-8 {
    border-left-width: 8px;
  }
  .md\:border-l {
    border-left-width: 1px;
  }
  .md\:border-solid {
    border-style: solid;
  }
  .md\:border-dashed {
    border-style: dashed;
  }
  .md\:border-dotted {
    border-style: dotted;
  }
  .md\:border-double {
    border-style: double;
  }
  .md\:border-none {
    border-style: none;
  }
  .md\:border-transparent {
    border-color: transparent;
  }
  .md\:border-current {
    border-color: currentColor;
  }
  .md\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .md\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .md\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .md\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .md\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .md\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .md\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .md\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .md\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .md\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .md\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .md\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .md\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .md\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .md\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .md\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .md\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .md\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .md\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .md\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .md\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .md\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .md\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .md\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .md\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .md\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .md\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .md\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .md\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .md\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .md\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .md\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .md\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .md\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .md\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .md\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .md\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .md\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .md\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .md\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .md\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .md\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .md\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .md\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .md\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .md\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .md\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .md\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .md\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .md\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .md\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .md\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .md\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .md\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .md\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .md\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .md\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .md\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .md\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .md\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .md\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .md\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .md\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .md\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .md\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .md\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .md\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .md\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .md\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .md\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .md\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .md\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .md\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .md\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .md\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .md\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .md\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .md\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .md\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .md\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .md\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .md\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-transparent {
    border-color: transparent;
  }
  .group:hover .md\:group-hover\:border-current {
    border-color: currentColor;
  }
  .group:hover .md\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .group:hover .md\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-transparent:focus-within {
    border-color: transparent;
  }
  .md\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }
  .md\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .md\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .md\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .md\:hover\:border-current:hover {
    border-color: currentColor;
  }
  .md\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .md\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .md\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .md\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .md\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .md\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .md\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .md\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .md\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .md\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .md\:focus\:border-transparent:focus {
    border-color: transparent;
  }
  .md\:focus\:border-current:focus {
    border-color: currentColor;
  }
  .md\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .md\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .md\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .md\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .md\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .md\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .md\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .md\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .md\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .md\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .md\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .md\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .md\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .md\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .md\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .md\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .md\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .md\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .md\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .md\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .md\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .md\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .md\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .md\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .md\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .group:hover .md\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .group:hover .md\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .group:hover .md\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .group:hover .md\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .group:hover .md\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .group:hover .md\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .group:hover .md\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .group:hover .md\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .group:hover .md\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .group:hover .md\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .group:hover .md\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .group:hover .md\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .group:hover .md\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .group:hover .md\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .group:hover .md\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .md\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }
  .md\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }
  .md\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }
  .md\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }
  .md\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }
  .md\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }
  .md\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }
  .md\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }
  .md\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }
  .md\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }
  .md\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }
  .md\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }
  .md\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }
  .md\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }
  .md\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }
  .md\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }
  .md\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }
  .md\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }
  .md\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }
  .md\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }
  .md\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }
  .md\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }
  .md\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }
  .md\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }
  .md\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }
  .md\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }
  .md\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }
  .md\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }
  .md\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }
  .md\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }
  .md\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }
  .md\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }
  .md\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }
  .md\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }
  .md\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }
  .md\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }
  .md\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }
  .md\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }
  .md\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }
  .md\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }
  .md\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }
  .md\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }
  .md\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }
  .md\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }
  .md\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }
  .md\:bg-transparent {
    background-color: transparent;
  }
  .md\:bg-current {
    background-color: currentColor;
  }
  .md\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .md\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .md\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .md\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .md\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .md\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .md\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .md\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .md\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .md\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .md\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .md\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .md\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .md\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .md\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .md\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .md\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .md\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .md\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .md\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .md\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .md\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .md\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .md\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .md\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .md\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .md\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .md\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .md\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .md\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .md\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .md\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .md\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .md\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .md\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .md\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .md\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .md\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .md\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .md\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .md\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .md\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .md\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .md\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .md\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .md\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .md\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .md\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .md\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .md\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .md\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .md\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .md\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .md\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .md\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .md\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .md\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .md\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .md\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .md\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .md\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .md\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .md\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .md\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-transparent {
    background-color: transparent;
  }
  .group:hover .md\:group-hover\:bg-current {
    background-color: currentColor;
  }
  .group:hover .md\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .group:hover .md\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-transparent:focus-within {
    background-color: transparent;
  }
  .md\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }
  .md\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .md\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-transparent:hover {
    background-color: transparent;
  }
  .md\:hover\:bg-current:hover {
    background-color: currentColor;
  }
  .md\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .md\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-transparent:focus {
    background-color: transparent;
  }
  .md\:focus\:bg-current:focus {
    background-color: currentColor;
  }
  .md\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .md\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .md\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .md\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .md\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .md\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .md\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .md\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .md\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .md\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .md\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .md\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .md\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .md\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .md\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .md\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .md\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .group:hover .md\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .group:hover .md\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .group:hover .md\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .group:hover .md\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .group:hover .md\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .group:hover .md\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .group:hover .md\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .group:hover .md\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .group:hover .md\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .group:hover .md\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .group:hover .md\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .group:hover .md\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .group:hover .md\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .group:hover .md\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .group:hover .md\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .md\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }
  .md\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }
  .md\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }
  .md\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }
  .md\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }
  .md\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }
  .md\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }
  .md\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }
  .md\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }
  .md\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }
  .md\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }
  .md\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }
  .md\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }
  .md\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }
  .md\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }
  .md\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }
  .md\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }
  .md\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }
  .md\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }
  .md\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }
  .md\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }
  .md\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }
  .md\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }
  .md\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }
  .md\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }
  .md\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }
  .md\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }
  .md\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }
  .md\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }
  .md\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }
  .md\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }
  .md\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }
  .md\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }
  .md\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }
  .md\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }
  .md\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }
  .md\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }
  .md\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }
  .md\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }
  .md\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }
  .md\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }
  .md\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }
  .md\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }
  .md\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }
  .md\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }
  .md\:bg-none {
    background-image: none;
  }
  .md\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }
  .md\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }
  .md\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }
  .md\:bg-gradient-to-br {
    background-image: linear-gradient(
      to bottom right,
      var(--tw-gradient-stops)
    );
  }
  .md\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }
  .md\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }
  .md\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }
  .md\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }
  .md\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .md\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .md\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .md\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .md\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .md\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .md\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .md\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .md\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .md\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .md\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .md\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .md\:from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .md\:from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .md\:from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .md\:from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .md\:from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .md\:from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .md\:from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .md\:from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .md\:from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .md\:from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .md\:from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .md\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .md\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .md\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .md\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .md\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .md\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .md\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .md\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .md\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .md\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .md\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .md\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .md\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .md\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .md\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .md\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .md\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .md\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .md\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .md\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .md\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .md\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .md\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .md\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .md\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .md\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .md\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .md\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .md\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .md\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .md\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .md\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .md\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .md\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .md\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .md\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .md\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .md\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .md\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .md\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .md\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .md\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .md\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .md\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .md\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .md\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .md\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .md\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .md\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .md\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .md\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .md\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .md\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .md\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .md\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .md\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .md\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .md\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .md\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .md\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .md\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .md\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .md\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .md\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .md\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .md\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .md\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:hover\:from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .md\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .md\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .md\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .md\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .md\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .md\:hover\:from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .md\:hover\:from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .md\:hover\:from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .md\:hover\:from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .md\:hover\:from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .md\:hover\:from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .md\:hover\:from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .md\:hover\:from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .md\:hover\:from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .md\:hover\:from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .md\:hover\:from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .md\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .md\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .md\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .md\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .md\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .md\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .md\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .md\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .md\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .md\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .md\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .md\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .md\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .md\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .md\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .md\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .md\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .md\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .md\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .md\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .md\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .md\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .md\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .md\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .md\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .md\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .md\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .md\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .md\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .md\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .md\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .md\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .md\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .md\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .md\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .md\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .md\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .md\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .md\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .md\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .md\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .md\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .md\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .md\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .md\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .md\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .md\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .md\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .md\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .md\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .md\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .md\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .md\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .md\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .md\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .md\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .md\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .md\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .md\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .md\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .md\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .md\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .md\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .md\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .md\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .md\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .md\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:focus\:from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .md\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .md\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .md\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .md\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .md\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .md\:focus\:from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .md\:focus\:from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .md\:focus\:from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .md\:focus\:from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .md\:focus\:from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .md\:focus\:from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .md\:focus\:from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .md\:focus\:from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .md\:focus\:from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .md\:focus\:from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .md\:focus\:from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .md\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .md\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .md\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .md\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .md\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .md\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .md\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .md\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .md\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .md\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .md\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .md\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .md\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .md\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .md\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .md\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .md\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .md\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .md\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .md\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .md\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .md\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .md\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .md\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .md\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .md\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .md\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .md\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .md\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .md\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .md\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .md\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .md\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .md\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .md\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .md\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .md\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .md\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .md\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .md\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .md\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .md\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .md\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .md\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .md\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .md\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .md\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .md\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .md\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .md\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .md\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .md\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .md\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .md\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .md\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .md\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .md\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .md\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .md\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .md\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .md\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .md\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .md\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .md\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .md\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .md\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .md\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .md\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .md\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .md\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .md\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .md\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .md\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827,
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .md\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .md\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .md\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .md\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .md\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .md\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .md\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .md\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .md\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .md\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .md\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .md\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .md\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .md\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .md\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .md\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .md\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .md\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .md\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .md\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .md\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .md\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .md\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .md\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .md\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .md\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .md\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .md\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .md\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .md\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .md\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .md\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .md\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .md\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .md\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .md\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .md\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .md\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .md\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .md\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .md\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .md\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .md\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .md\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .md\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .md\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .md\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .md\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .md\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .md\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .md\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .md\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .md\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .md\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .md\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .md\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .md\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .md\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .md\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .md\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .md\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .md\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .md\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .md\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .md\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .md\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .md\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .md\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .md\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .md\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .md\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .md\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .md\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .md\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .md\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .md\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .md\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .md\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .md\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .md\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .md\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .md\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .md\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827,
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .md\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .md\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .md\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .md\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .md\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .md\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .md\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .md\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .md\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .md\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .md\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .md\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .md\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .md\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .md\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .md\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .md\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .md\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .md\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .md\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .md\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .md\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .md\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .md\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .md\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .md\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .md\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .md\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .md\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .md\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .md\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .md\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .md\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .md\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .md\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .md\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .md\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .md\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .md\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .md\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .md\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .md\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .md\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .md\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .md\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .md\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .md\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .md\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .md\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .md\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .md\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .md\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .md\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .md\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .md\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .md\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .md\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .md\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .md\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .md\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .md\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .md\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .md\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .md\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .md\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .md\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .md\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .md\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .md\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .md\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .md\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .md\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .md\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .md\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .md\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .md\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .md\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .md\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .md\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .md\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .md\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .md\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .md\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .md\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827,
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .md\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .md\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .md\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .md\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .md\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .md\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .md\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .md\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .md\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .md\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .md\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .md\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .md\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .md\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .md\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .md\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .md\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .md\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .md\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .md\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .md\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .md\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .md\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .md\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .md\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .md\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .md\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .md\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .md\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .md\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .md\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .md\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .md\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .md\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .md\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .md\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .md\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .md\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .md\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .md\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .md\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .md\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .md\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .md\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .md\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .md\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .md\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .md\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .md\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .md\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .md\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .md\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .md\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .md\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .md\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .md\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .md\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .md\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .md\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .md\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .md\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .md\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .md\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .md\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .md\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .md\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .md\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .md\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .md\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .md\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .md\:to-transparent {
    --tw-gradient-to: transparent;
  }
  .md\:to-current {
    --tw-gradient-to: currentColor;
  }
  .md\:to-black {
    --tw-gradient-to: #000;
  }
  .md\:to-white {
    --tw-gradient-to: #fff;
  }
  .md\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }
  .md\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }
  .md\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }
  .md\:to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }
  .md\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }
  .md\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }
  .md\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }
  .md\:to-gray-700 {
    --tw-gradient-to: #374151;
  }
  .md\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }
  .md\:to-gray-900 {
    --tw-gradient-to: #111827;
  }
  .md\:to-red-50 {
    --tw-gradient-to: #fef2f2;
  }
  .md\:to-red-100 {
    --tw-gradient-to: #fee2e2;
  }
  .md\:to-red-200 {
    --tw-gradient-to: #fecaca;
  }
  .md\:to-red-300 {
    --tw-gradient-to: #fca5a5;
  }
  .md\:to-red-400 {
    --tw-gradient-to: #f87171;
  }
  .md\:to-red-500 {
    --tw-gradient-to: #ef4444;
  }
  .md\:to-red-600 {
    --tw-gradient-to: #dc2626;
  }
  .md\:to-red-700 {
    --tw-gradient-to: #b91c1c;
  }
  .md\:to-red-800 {
    --tw-gradient-to: #991b1b;
  }
  .md\:to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }
  .md\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }
  .md\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }
  .md\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }
  .md\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }
  .md\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }
  .md\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }
  .md\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }
  .md\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }
  .md\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }
  .md\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }
  .md\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }
  .md\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }
  .md\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }
  .md\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }
  .md\:to-green-400 {
    --tw-gradient-to: #34d399;
  }
  .md\:to-green-500 {
    --tw-gradient-to: #10b981;
  }
  .md\:to-green-600 {
    --tw-gradient-to: #059669;
  }
  .md\:to-green-700 {
    --tw-gradient-to: #047857;
  }
  .md\:to-green-800 {
    --tw-gradient-to: #065f46;
  }
  .md\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }
  .md\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }
  .md\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }
  .md\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }
  .md\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }
  .md\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }
  .md\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }
  .md\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }
  .md\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }
  .md\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }
  .md\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }
  .md\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }
  .md\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }
  .md\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }
  .md\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }
  .md\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }
  .md\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }
  .md\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }
  .md\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }
  .md\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }
  .md\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }
  .md\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }
  .md\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }
  .md\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }
  .md\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }
  .md\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }
  .md\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }
  .md\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }
  .md\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }
  .md\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }
  .md\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }
  .md\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }
  .md\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }
  .md\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }
  .md\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }
  .md\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }
  .md\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }
  .md\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }
  .md\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }
  .md\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }
  .md\:to-pink-900 {
    --tw-gradient-to: #831843;
  }
  .md\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }
  .md\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }
  .md\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }
  .md\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }
  .md\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }
  .md\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }
  .md\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .md\:hover\:to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }
  .md\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }
  .md\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }
  .md\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }
  .md\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }
  .md\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }
  .md\:hover\:to-gray-900:hover {
    --tw-gradient-to: #111827;
  }
  .md\:hover\:to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }
  .md\:hover\:to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }
  .md\:hover\:to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }
  .md\:hover\:to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }
  .md\:hover\:to-red-400:hover {
    --tw-gradient-to: #f87171;
  }
  .md\:hover\:to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }
  .md\:hover\:to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }
  .md\:hover\:to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }
  .md\:hover\:to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }
  .md\:hover\:to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }
  .md\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }
  .md\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }
  .md\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }
  .md\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }
  .md\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }
  .md\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }
  .md\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }
  .md\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }
  .md\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }
  .md\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }
  .md\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }
  .md\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }
  .md\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }
  .md\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }
  .md\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }
  .md\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }
  .md\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }
  .md\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }
  .md\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }
  .md\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }
  .md\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }
  .md\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }
  .md\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }
  .md\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }
  .md\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }
  .md\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }
  .md\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }
  .md\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }
  .md\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }
  .md\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }
  .md\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }
  .md\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }
  .md\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }
  .md\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }
  .md\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }
  .md\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }
  .md\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }
  .md\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }
  .md\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }
  .md\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }
  .md\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }
  .md\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }
  .md\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }
  .md\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }
  .md\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }
  .md\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }
  .md\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }
  .md\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }
  .md\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }
  .md\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }
  .md\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }
  .md\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }
  .md\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }
  .md\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }
  .md\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }
  .md\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }
  .md\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }
  .md\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }
  .md\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }
  .md\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }
  .md\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }
  .md\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }
  .md\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }
  .md\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }
  .md\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }
  .md\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }
  .md\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .md\:focus\:to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }
  .md\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }
  .md\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }
  .md\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }
  .md\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }
  .md\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }
  .md\:focus\:to-gray-900:focus {
    --tw-gradient-to: #111827;
  }
  .md\:focus\:to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }
  .md\:focus\:to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }
  .md\:focus\:to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }
  .md\:focus\:to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }
  .md\:focus\:to-red-400:focus {
    --tw-gradient-to: #f87171;
  }
  .md\:focus\:to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }
  .md\:focus\:to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }
  .md\:focus\:to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }
  .md\:focus\:to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }
  .md\:focus\:to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }
  .md\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }
  .md\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }
  .md\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }
  .md\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }
  .md\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }
  .md\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }
  .md\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }
  .md\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }
  .md\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }
  .md\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }
  .md\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }
  .md\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }
  .md\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }
  .md\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }
  .md\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }
  .md\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }
  .md\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }
  .md\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }
  .md\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }
  .md\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }
  .md\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }
  .md\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }
  .md\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }
  .md\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }
  .md\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }
  .md\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }
  .md\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }
  .md\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }
  .md\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }
  .md\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }
  .md\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }
  .md\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }
  .md\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }
  .md\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }
  .md\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }
  .md\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }
  .md\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }
  .md\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }
  .md\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }
  .md\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }
  .md\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }
  .md\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }
  .md\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }
  .md\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }
  .md\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }
  .md\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }
  .md\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }
  .md\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }
  .md\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }
  .md\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }
  .md\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }
  .md\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }
  .md\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }
  .md\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }
  .md\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }
  .md\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }
  .md\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }
  .md\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }
  .md\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }
  .md\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }
  .md\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }
  .md\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
  .md\:bg-auto {
    background-size: auto;
  }
  .md\:bg-cover {
    background-size: cover;
  }
  .md\:bg-contain {
    background-size: contain;
  }
  .md\:bg-fixed {
    background-attachment: fixed;
  }
  .md\:bg-local {
    background-attachment: local;
  }
  .md\:bg-scroll {
    background-attachment: scroll;
  }
  .md\:bg-clip-border {
    background-clip: border-box;
  }
  .md\:bg-clip-padding {
    background-clip: padding-box;
  }
  .md\:bg-clip-content {
    background-clip: content-box;
  }
  .md\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }
  .md\:bg-bottom {
    background-position: bottom;
  }
  .md\:bg-center {
    background-position: center;
  }
  .md\:bg-left {
    background-position: left;
  }
  .md\:bg-left-bottom {
    background-position: left bottom;
  }
  .md\:bg-left-top {
    background-position: left top;
  }
  .md\:bg-right {
    background-position: right;
  }
  .md\:bg-right-bottom {
    background-position: right bottom;
  }
  .md\:bg-right-top {
    background-position: right top;
  }
  .md\:bg-top {
    background-position: top;
  }
  .md\:bg-repeat {
    background-repeat: repeat;
  }
  .md\:bg-no-repeat {
    background-repeat: no-repeat;
  }
  .md\:bg-repeat-x {
    background-repeat: repeat-x;
  }
  .md\:bg-repeat-y {
    background-repeat: repeat-y;
  }
  .md\:bg-repeat-round {
    background-repeat: round;
  }
  .md\:bg-repeat-space {
    background-repeat: space;
  }
  .md\:bg-origin-border {
    background-origin: border-box;
  }
  .md\:bg-origin-padding {
    background-origin: padding-box;
  }
  .md\:bg-origin-content {
    background-origin: content-box;
  }
  .md\:fill-current {
    fill: currentColor;
  }
  .md\:stroke-current {
    stroke: currentColor;
  }
  .md\:stroke-0 {
    stroke-width: 0;
  }
  .md\:stroke-1 {
    stroke-width: 1;
  }
  .md\:stroke-2 {
    stroke-width: 2;
  }
  .md\:object-contain {
    object-fit: contain;
  }
  .md\:object-cover {
    object-fit: cover;
  }
  .md\:object-fill {
    object-fit: fill;
  }
  .md\:object-none {
    object-fit: none;
  }
  .md\:object-scale-down {
    object-fit: scale-down;
  }
  .md\:object-bottom {
    object-position: bottom;
  }
  .md\:object-center {
    object-position: center;
  }
  .md\:object-left {
    object-position: left;
  }
  .md\:object-left-bottom {
    object-position: left bottom;
  }
  .md\:object-left-top {
    object-position: left top;
  }
  .md\:object-right {
    object-position: right;
  }
  .md\:object-right-bottom {
    object-position: right bottom;
  }
  .md\:object-right-top {
    object-position: right top;
  }
  .md\:object-top {
    object-position: top;
  }
  .md\:p-0 {
    padding: 0;
  }
  .md\:p-1 {
    padding: 0.25rem;
  }
  .md\:p-2 {
    padding: 0.5rem;
  }
  .md\:p-3 {
    padding: 0.75rem;
  }
  .md\:p-4 {
    padding: 1rem;
  }
  .md\:p-5 {
    padding: 1.25rem;
  }
  .md\:p-6 {
    padding: 1.5rem;
  }
  .md\:p-7 {
    padding: 1.75rem;
  }
  .md\:p-8 {
    padding: 2rem;
  }
  .md\:p-9 {
    padding: 2.25rem;
  }
  .md\:p-10 {
    padding: 2.5rem;
  }
  .md\:p-11 {
    padding: 2.75rem;
  }
  .md\:p-12 {
    padding: 3rem;
  }
  .md\:p-14 {
    padding: 3.5rem;
  }
  .md\:p-16 {
    padding: 4rem;
  }
  .md\:p-20 {
    padding: 5rem;
  }
  .md\:p-24 {
    padding: 6rem;
  }
  .md\:p-28 {
    padding: 7rem;
  }
  .md\:p-32 {
    padding: 8rem;
  }
  .md\:p-36 {
    padding: 9rem;
  }
  .md\:p-40 {
    padding: 10rem;
  }
  .md\:p-44 {
    padding: 11rem;
  }
  .md\:p-48 {
    padding: 12rem;
  }
  .md\:p-52 {
    padding: 13rem;
  }
  .md\:p-56 {
    padding: 14rem;
  }
  .md\:p-60 {
    padding: 15rem;
  }
  .md\:p-64 {
    padding: 16rem;
  }
  .md\:p-72 {
    padding: 18rem;
  }
  .md\:p-80 {
    padding: 20rem;
  }
  .md\:p-96 {
    padding: 24rem;
  }
  .md\:p-px {
    padding: 1px;
  }
  .md\:p-0\.5 {
    padding: 0.125rem;
  }
  .md\:p-1\.5 {
    padding: 0.375rem;
  }
  .md\:p-2\.5 {
    padding: 0.625rem;
  }
  .md\:p-3\.5 {
    padding: 0.875rem;
  }
  .md\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .md\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .md\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .md\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .md\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .md\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .md\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .md\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .md\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .md\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .md\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .md\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .md\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .md\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .md\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .md\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .md\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }
  .md\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }
  .md\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }
  .md\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .md\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }
  .md\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .md\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }
  .md\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .md\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .md\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .md\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .md\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .md\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .md\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .md\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .md\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .md\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .md\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .md\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .md\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .md\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .md\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .md\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .md\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .md\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .md\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .md\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .md\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .md\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
  .md\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
  .md\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .md\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  .md\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }
  .md\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
  .md\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }
  .md\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .md\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .md\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .md\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .md\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .md\:pt-0 {
    padding-top: 0;
  }
  .md\:pt-1 {
    padding-top: 0.25rem;
  }
  .md\:pt-2 {
    padding-top: 0.5rem;
  }
  .md\:pt-3 {
    padding-top: 0.75rem;
  }
  .md\:pt-4 {
    padding-top: 1rem;
  }
  .md\:pt-5 {
    padding-top: 1.25rem;
  }
  .md\:pt-6 {
    padding-top: 1.5rem;
  }
  .md\:pt-7 {
    padding-top: 1.75rem;
  }
  .md\:pt-8 {
    padding-top: 2rem;
  }
  .md\:pt-9 {
    padding-top: 2.25rem;
  }
  .md\:pt-10 {
    padding-top: 2.5rem;
  }
  .md\:pt-11 {
    padding-top: 2.75rem;
  }
  .md\:pt-12 {
    padding-top: 3rem;
  }
  .md\:pt-14 {
    padding-top: 3.5rem;
  }
  .md\:pt-16 {
    padding-top: 4rem;
  }
  .md\:pt-20 {
    padding-top: 5rem;
  }
  .md\:pt-24 {
    padding-top: 6rem;
  }
  .md\:pt-28 {
    padding-top: 7rem;
  }
  .md\:pt-32 {
    padding-top: 8rem;
  }
  .md\:pt-36 {
    padding-top: 9rem;
  }
  .md\:pt-40 {
    padding-top: 10rem;
  }
  .md\:pt-44 {
    padding-top: 11rem;
  }
  .md\:pt-48 {
    padding-top: 12rem;
  }
  .md\:pt-52 {
    padding-top: 13rem;
  }
  .md\:pt-56 {
    padding-top: 14rem;
  }
  .md\:pt-60 {
    padding-top: 15rem;
  }
  .md\:pt-64 {
    padding-top: 16rem;
  }
  .md\:pt-72 {
    padding-top: 18rem;
  }
  .md\:pt-80 {
    padding-top: 20rem;
  }
  .md\:pt-96 {
    padding-top: 24rem;
  }
  .md\:pt-px {
    padding-top: 1px;
  }
  .md\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .md\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .md\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .md\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .md\:pr-0 {
    padding-right: 0;
  }
  .md\:pr-1 {
    padding-right: 0.25rem;
  }
  .md\:pr-2 {
    padding-right: 0.5rem;
  }
  .md\:pr-3 {
    padding-right: 0.75rem;
  }
  .md\:pr-4 {
    padding-right: 1rem;
  }
  .md\:pr-5 {
    padding-right: 1.25rem;
  }
  .md\:pr-6 {
    padding-right: 1.5rem;
  }
  .md\:pr-7 {
    padding-right: 1.75rem;
  }
  .md\:pr-8 {
    padding-right: 2rem;
  }
  .md\:pr-9 {
    padding-right: 2.25rem;
  }
  .md\:pr-10 {
    padding-right: 2.5rem;
  }
  .md\:pr-11 {
    padding-right: 2.75rem;
  }
  .md\:pr-12 {
    padding-right: 3rem;
  }
  .md\:pr-14 {
    padding-right: 3.5rem;
  }
  .md\:pr-16 {
    padding-right: 4rem;
  }
  .md\:pr-20 {
    padding-right: 5rem;
  }
  .md\:pr-24 {
    padding-right: 6rem;
  }
  .md\:pr-28 {
    padding-right: 7rem;
  }
  .md\:pr-32 {
    padding-right: 8rem;
  }
  .md\:pr-36 {
    padding-right: 9rem;
  }
  .md\:pr-40 {
    padding-right: 10rem;
  }
  .md\:pr-44 {
    padding-right: 11rem;
  }
  .md\:pr-48 {
    padding-right: 12rem;
  }
  .md\:pr-52 {
    padding-right: 13rem;
  }
  .md\:pr-56 {
    padding-right: 14rem;
  }
  .md\:pr-60 {
    padding-right: 15rem;
  }
  .md\:pr-64 {
    padding-right: 16rem;
  }
  .md\:pr-72 {
    padding-right: 18rem;
  }
  .md\:pr-80 {
    padding-right: 20rem;
  }
  .md\:pr-96 {
    padding-right: 24rem;
  }
  .md\:pr-px {
    padding-right: 1px;
  }
  .md\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .md\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .md\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .md\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .md\:pb-0 {
    padding-bottom: 0;
  }
  .md\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .md\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .md\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .md\:pb-4 {
    padding-bottom: 1rem;
  }
  .md\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .md\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .md\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .md\:pb-8 {
    padding-bottom: 2rem;
  }
  .md\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .md\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .md\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .md\:pb-12 {
    padding-bottom: 3rem;
  }
  .md\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .md\:pb-16 {
    padding-bottom: 4rem;
  }
  .md\:pb-20 {
    padding-bottom: 5rem;
  }
  .md\:pb-24 {
    padding-bottom: 6rem;
  }
  .md\:pb-28 {
    padding-bottom: 7rem;
  }
  .md\:pb-32 {
    padding-bottom: 8rem;
  }
  .md\:pb-36 {
    padding-bottom: 9rem;
  }
  .md\:pb-40 {
    padding-bottom: 10rem;
  }
  .md\:pb-44 {
    padding-bottom: 11rem;
  }
  .md\:pb-48 {
    padding-bottom: 12rem;
  }
  .md\:pb-52 {
    padding-bottom: 13rem;
  }
  .md\:pb-56 {
    padding-bottom: 14rem;
  }
  .md\:pb-60 {
    padding-bottom: 15rem;
  }
  .md\:pb-64 {
    padding-bottom: 16rem;
  }
  .md\:pb-72 {
    padding-bottom: 18rem;
  }
  .md\:pb-80 {
    padding-bottom: 20rem;
  }
  .md\:pb-96 {
    padding-bottom: 24rem;
  }
  .md\:pb-px {
    padding-bottom: 1px;
  }
  .md\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .md\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .md\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .md\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .md\:pl-0 {
    padding-left: 0;
  }
  .md\:pl-1 {
    padding-left: 0.25rem;
  }
  .md\:pl-2 {
    padding-left: 0.5rem;
  }
  .md\:pl-3 {
    padding-left: 0.75rem;
  }
  .md\:pl-4 {
    padding-left: 1rem;
  }
  .md\:pl-5 {
    padding-left: 1.25rem;
  }
  .md\:pl-6 {
    padding-left: 1.5rem;
  }
  .md\:pl-7 {
    padding-left: 1.75rem;
  }
  .md\:pl-8 {
    padding-left: 2rem;
  }
  .md\:pl-9 {
    padding-left: 2.25rem;
  }
  .md\:pl-10 {
    padding-left: 2.5rem;
  }
  .md\:pl-11 {
    padding-left: 2.75rem;
  }
  .md\:pl-12 {
    padding-left: 3rem;
  }
  .md\:pl-14 {
    padding-left: 3.5rem;
  }
  .md\:pl-16 {
    padding-left: 4rem;
  }
  .md\:pl-20 {
    padding-left: 5rem;
  }
  .md\:pl-24 {
    padding-left: 6rem;
  }
  .md\:pl-28 {
    padding-left: 7rem;
  }
  .md\:pl-32 {
    padding-left: 8rem;
  }
  .md\:pl-36 {
    padding-left: 9rem;
  }
  .md\:pl-40 {
    padding-left: 10rem;
  }
  .md\:pl-44 {
    padding-left: 11rem;
  }
  .md\:pl-48 {
    padding-left: 12rem;
  }
  .md\:pl-52 {
    padding-left: 13rem;
  }
  .md\:pl-56 {
    padding-left: 14rem;
  }
  .md\:pl-60 {
    padding-left: 15rem;
  }
  .md\:pl-64 {
    padding-left: 16rem;
  }
  .md\:pl-72 {
    padding-left: 18rem;
  }
  .md\:pl-80 {
    padding-left: 20rem;
  }
  .md\:pl-96 {
    padding-left: 24rem;
  }
  .md\:pl-px {
    padding-left: 1px;
  }
  .md\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .md\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .md\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .md\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .md\:text-left {
    text-align: left;
  }
  .md\:text-center {
    text-align: center;
  }
  .md\:text-right {
    text-align: right;
  }
  .md\:text-justify {
    text-align: justify;
  }
  .md\:align-baseline {
    vertical-align: baseline;
  }
  .md\:align-top {
    vertical-align: top;
  }
  .md\:align-middle {
    vertical-align: middle;
  }
  .md\:align-bottom {
    vertical-align: bottom;
  }
  .md\:align-text-top {
    vertical-align: text-top;
  }
  .md\:align-text-bottom {
    vertical-align: text-bottom;
  }
  .md\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  }
  .md\:font-serif {
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  }
  .md\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
  }
  .md\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .md\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .md\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
  .md\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
  .md\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
  .md\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }
  .md\:font-thin {
    font-weight: 100;
  }
  .md\:font-extralight {
    font-weight: 200;
  }
  .md\:font-light {
    font-weight: 300;
  }
  .md\:font-normal {
    font-weight: 400;
  }
  .md\:font-medium {
    font-weight: 500;
  }
  .md\:font-semibold {
    font-weight: 600;
  }
  .md\:font-bold {
    font-weight: 700;
  }
  .md\:font-extrabold {
    font-weight: 800;
  }
  .md\:font-black {
    font-weight: 900;
  }
  .md\:uppercase {
    text-transform: uppercase;
  }
  .md\:lowercase {
    text-transform: lowercase;
  }
  .md\:capitalize {
    text-transform: capitalize;
  }
  .md\:normal-case {
    text-transform: none;
  }
  .md\:italic {
    font-style: italic;
  }
  .md\:not-italic {
    font-style: normal;
  }
  .md\:diagonal-fractions,
  .md\:lining-nums,
  .md\:oldstyle-nums,
  .md\:ordinal,
  .md\:proportional-nums,
  .md\:slashed-zero,
  .md\:stacked-fractions,
  .md\:tabular-nums {
    --tw-ordinal: var(--tw-empty); /*!*/ /*!*/
    --tw-slashed-zero: var(--tw-empty); /*!*/ /*!*/
    --tw-numeric-figure: var(--tw-empty); /*!*/ /*!*/
    --tw-numeric-spacing: var(--tw-empty); /*!*/ /*!*/
    --tw-numeric-fraction: var(--tw-empty); /*!*/ /*!*/
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
  }
  .md\:normal-nums {
    font-variant-numeric: normal;
  }
  .md\:ordinal {
    --tw-ordinal: ordinal;
  }
  .md\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }
  .md\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }
  .md\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }
  .md\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }
  .md\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }
  .md\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }
  .md\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }
  .md\:leading-3 {
    line-height: 0.75rem;
  }
  .md\:leading-4 {
    line-height: 1rem;
  }
  .md\:leading-5 {
    line-height: 1.25rem;
  }
  .md\:leading-6 {
    line-height: 1.5rem;
  }
  .md\:leading-7 {
    line-height: 1.75rem;
  }
  .md\:leading-8 {
    line-height: 2rem;
  }
  .md\:leading-9 {
    line-height: 2.25rem;
  }
  .md\:leading-10 {
    line-height: 2.5rem;
  }
  .md\:leading-none {
    line-height: 1;
  }
  .md\:leading-tight {
    line-height: 1.25;
  }
  .md\:leading-snug {
    line-height: 1.375;
  }
  .md\:leading-normal {
    line-height: 1.5;
  }
  .md\:leading-relaxed {
    line-height: 1.625;
  }
  .md\:leading-loose {
    line-height: 2;
  }
  .md\:tracking-tighter {
    letter-spacing: -0.05em;
  }
  .md\:tracking-tight {
    letter-spacing: -0.025em;
  }
  .md\:tracking-normal {
    letter-spacing: 0;
  }
  .md\:tracking-wide {
    letter-spacing: 0.025em;
  }
  .md\:tracking-wider {
    letter-spacing: 0.05em;
  }
  .md\:tracking-widest {
    letter-spacing: 0.1em;
  }
  .md\:text-transparent {
    color: transparent;
  }
  .md\:text-current {
    color: currentColor;
  }
  .md\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .md\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .md\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .md\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .md\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .md\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .md\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .md\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .md\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .md\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .md\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .md\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .md\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .md\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .md\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .md\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .md\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .md\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .md\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .md\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .md\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .md\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .md\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .md\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .md\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .md\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .md\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .md\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .md\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .md\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .md\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .md\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .md\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .md\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .md\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .md\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .md\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .md\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .md\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .md\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .md\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .md\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .md\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .md\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .md\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .md\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .md\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .md\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .md\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .md\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .md\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .md\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .md\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .md\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .md\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .md\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .md\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .md\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .md\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .md\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .md\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .md\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .md\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .md\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .md\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .md\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .md\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .md\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .md\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .md\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .md\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .md\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .md\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .md\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .md\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .md\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .md\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .md\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .md\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .md\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .md\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .md\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-transparent {
    color: transparent;
  }
  .group:hover .md\:group-hover\:text-current {
    color: currentColor;
  }
  .group:hover .md\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .group:hover .md\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-transparent:focus-within {
    color: transparent;
  }
  .md\:focus-within\:text-current:focus-within {
    color: currentColor;
  }
  .md\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .md\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .md\:hover\:text-transparent:hover {
    color: transparent;
  }
  .md\:hover\:text-current:hover {
    color: currentColor;
  }
  .md\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .md\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .md\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .md\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .md\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .md\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .md\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .md\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .md\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .md\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .md\:focus\:text-transparent:focus {
    color: transparent;
  }
  .md\:focus\:text-current:focus {
    color: currentColor;
  }
  .md\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .md\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .md\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .md\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .md\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .md\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .md\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .md\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .md\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .md\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .md\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .md\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .md\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .md\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .md\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .md\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .md\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .md\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .md\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .md\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .md\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .md\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .md\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .md\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .md\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .group:hover .md\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .group:hover .md\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .group:hover .md\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .group:hover .md\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .group:hover .md\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .group:hover .md\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .group:hover .md\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .group:hover .md\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .group:hover .md\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .group:hover .md\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .group:hover .md\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .group:hover .md\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .group:hover .md\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .group:hover .md\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .group:hover .md\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .md\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }
  .md\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }
  .md\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }
  .md\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }
  .md\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }
  .md\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }
  .md\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }
  .md\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }
  .md\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }
  .md\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }
  .md\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }
  .md\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }
  .md\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }
  .md\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }
  .md\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }
  .md\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }
  .md\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }
  .md\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }
  .md\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }
  .md\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }
  .md\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }
  .md\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }
  .md\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }
  .md\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }
  .md\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }
  .md\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }
  .md\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }
  .md\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }
  .md\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }
  .md\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }
  .md\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }
  .md\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }
  .md\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }
  .md\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }
  .md\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }
  .md\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }
  .md\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }
  .md\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }
  .md\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }
  .md\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }
  .md\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }
  .md\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }
  .md\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }
  .md\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }
  .md\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }
  .md\:underline {
    text-decoration: underline;
  }
  .md\:line-through {
    text-decoration: line-through;
  }
  .md\:no-underline {
    text-decoration: none;
  }
  .group:hover .md\:group-hover\:underline {
    text-decoration: underline;
  }
  .group:hover .md\:group-hover\:line-through {
    text-decoration: line-through;
  }
  .group:hover .md\:group-hover\:no-underline {
    text-decoration: none;
  }
  .md\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }
  .md\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }
  .md\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }
  .md\:hover\:underline:hover {
    text-decoration: underline;
  }
  .md\:hover\:line-through:hover {
    text-decoration: line-through;
  }
  .md\:hover\:no-underline:hover {
    text-decoration: none;
  }
  .md\:focus\:underline:focus {
    text-decoration: underline;
  }
  .md\:focus\:line-through:focus {
    text-decoration: line-through;
  }
  .md\:focus\:no-underline:focus {
    text-decoration: none;
  }
  .md\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .md\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  .md\:placeholder-transparent::placeholder {
    color: transparent;
  }
  .md\:placeholder-current::placeholder {
    color: currentColor;
  }
  .md\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }
  .md\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }
  .md\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .md\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .md\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .md\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .md\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .md\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .md\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .md\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .md\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .md\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .md\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .md\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .md\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .md\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .md\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .md\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .md\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .md\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .md\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .md\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .md\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .md\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .md\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .md\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .md\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .md\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .md\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .md\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .md\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .md\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .md\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .md\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .md\:opacity-0 {
    opacity: 0;
  }
  .md\:opacity-5 {
    opacity: 0.05;
  }
  .md\:opacity-10 {
    opacity: 0.1;
  }
  .md\:opacity-20 {
    opacity: 0.2;
  }
  .md\:opacity-25 {
    opacity: 0.25;
  }
  .md\:opacity-30 {
    opacity: 0.3;
  }
  .md\:opacity-40 {
    opacity: 0.4;
  }
  .md\:opacity-50 {
    opacity: 0.5;
  }
  .md\:opacity-60 {
    opacity: 0.6;
  }
  .md\:opacity-70 {
    opacity: 0.7;
  }
  .md\:opacity-75 {
    opacity: 0.75;
  }
  .md\:opacity-80 {
    opacity: 0.8;
  }
  .md\:opacity-90 {
    opacity: 0.9;
  }
  .md\:opacity-95 {
    opacity: 0.95;
  }
  .md\:opacity-100 {
    opacity: 1;
  }
  .group:hover .md\:group-hover\:opacity-0 {
    opacity: 0;
  }
  .group:hover .md\:group-hover\:opacity-5 {
    opacity: 0.05;
  }
  .group:hover .md\:group-hover\:opacity-10 {
    opacity: 0.1;
  }
  .group:hover .md\:group-hover\:opacity-20 {
    opacity: 0.2;
  }
  .group:hover .md\:group-hover\:opacity-25 {
    opacity: 0.25;
  }
  .group:hover .md\:group-hover\:opacity-30 {
    opacity: 0.3;
  }
  .group:hover .md\:group-hover\:opacity-40 {
    opacity: 0.4;
  }
  .group:hover .md\:group-hover\:opacity-50 {
    opacity: 0.5;
  }
  .group:hover .md\:group-hover\:opacity-60 {
    opacity: 0.6;
  }
  .group:hover .md\:group-hover\:opacity-70 {
    opacity: 0.7;
  }
  .group:hover .md\:group-hover\:opacity-75 {
    opacity: 0.75;
  }
  .group:hover .md\:group-hover\:opacity-80 {
    opacity: 0.8;
  }
  .group:hover .md\:group-hover\:opacity-90 {
    opacity: 0.9;
  }
  .group:hover .md\:group-hover\:opacity-95 {
    opacity: 0.95;
  }
  .group:hover .md\:group-hover\:opacity-100 {
    opacity: 1;
  }
  .md\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }
  .md\:focus-within\:opacity-5:focus-within {
    opacity: 0.05;
  }
  .md\:focus-within\:opacity-10:focus-within {
    opacity: 0.1;
  }
  .md\:focus-within\:opacity-20:focus-within {
    opacity: 0.2;
  }
  .md\:focus-within\:opacity-25:focus-within {
    opacity: 0.25;
  }
  .md\:focus-within\:opacity-30:focus-within {
    opacity: 0.3;
  }
  .md\:focus-within\:opacity-40:focus-within {
    opacity: 0.4;
  }
  .md\:focus-within\:opacity-50:focus-within {
    opacity: 0.5;
  }
  .md\:focus-within\:opacity-60:focus-within {
    opacity: 0.6;
  }
  .md\:focus-within\:opacity-70:focus-within {
    opacity: 0.7;
  }
  .md\:focus-within\:opacity-75:focus-within {
    opacity: 0.75;
  }
  .md\:focus-within\:opacity-80:focus-within {
    opacity: 0.8;
  }
  .md\:focus-within\:opacity-90:focus-within {
    opacity: 0.9;
  }
  .md\:focus-within\:opacity-95:focus-within {
    opacity: 0.95;
  }
  .md\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }
  .md\:hover\:opacity-0:hover {
    opacity: 0;
  }
  .md\:hover\:opacity-5:hover {
    opacity: 0.05;
  }
  .md\:hover\:opacity-10:hover {
    opacity: 0.1;
  }
  .md\:hover\:opacity-20:hover {
    opacity: 0.2;
  }
  .md\:hover\:opacity-25:hover {
    opacity: 0.25;
  }
  .md\:hover\:opacity-30:hover {
    opacity: 0.3;
  }
  .md\:hover\:opacity-40:hover {
    opacity: 0.4;
  }
  .md\:hover\:opacity-50:hover {
    opacity: 0.5;
  }
  .md\:hover\:opacity-60:hover {
    opacity: 0.6;
  }
  .md\:hover\:opacity-70:hover {
    opacity: 0.7;
  }
  .md\:hover\:opacity-75:hover {
    opacity: 0.75;
  }
  .md\:hover\:opacity-80:hover {
    opacity: 0.8;
  }
  .md\:hover\:opacity-90:hover {
    opacity: 0.9;
  }
  .md\:hover\:opacity-95:hover {
    opacity: 0.95;
  }
  .md\:hover\:opacity-100:hover {
    opacity: 1;
  }
  .md\:focus\:opacity-0:focus {
    opacity: 0;
  }
  .md\:focus\:opacity-5:focus {
    opacity: 0.05;
  }
  .md\:focus\:opacity-10:focus {
    opacity: 0.1;
  }
  .md\:focus\:opacity-20:focus {
    opacity: 0.2;
  }
  .md\:focus\:opacity-25:focus {
    opacity: 0.25;
  }
  .md\:focus\:opacity-30:focus {
    opacity: 0.3;
  }
  .md\:focus\:opacity-40:focus {
    opacity: 0.4;
  }
  .md\:focus\:opacity-50:focus {
    opacity: 0.5;
  }
  .md\:focus\:opacity-60:focus {
    opacity: 0.6;
  }
  .md\:focus\:opacity-70:focus {
    opacity: 0.7;
  }
  .md\:focus\:opacity-75:focus {
    opacity: 0.75;
  }
  .md\:focus\:opacity-80:focus {
    opacity: 0.8;
  }
  .md\:focus\:opacity-90:focus {
    opacity: 0.9;
  }
  .md\:focus\:opacity-95:focus {
    opacity: 0.95;
  }
  .md\:focus\:opacity-100:focus {
    opacity: 1;
  }
  .md\:bg-blend-normal {
    background-blend-mode: normal;
  }
  .md\:bg-blend-multiply {
    background-blend-mode: multiply;
  }
  .md\:bg-blend-screen {
    background-blend-mode: screen;
  }
  .md\:bg-blend-overlay {
    background-blend-mode: overlay;
  }
  .md\:bg-blend-darken {
    background-blend-mode: darken;
  }
  .md\:bg-blend-lighten {
    background-blend-mode: lighten;
  }
  .md\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }
  .md\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }
  .md\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }
  .md\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }
  .md\:bg-blend-difference {
    background-blend-mode: difference;
  }
  .md\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }
  .md\:bg-blend-hue {
    background-blend-mode: hue;
  }
  .md\:bg-blend-saturation {
    background-blend-mode: saturation;
  }
  .md\:bg-blend-color {
    background-blend-mode: color;
  }
  .md\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }
  .md\:mix-blend-normal {
    mix-blend-mode: normal;
  }
  .md\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }
  .md\:mix-blend-screen {
    mix-blend-mode: screen;
  }
  .md\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }
  .md\:mix-blend-darken {
    mix-blend-mode: darken;
  }
  .md\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }
  .md\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }
  .md\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }
  .md\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }
  .md\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }
  .md\:mix-blend-difference {
    mix-blend-mode: difference;
  }
  .md\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }
  .md\:mix-blend-hue {
    mix-blend-mode: hue;
  }
  .md\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }
  .md\:mix-blend-color {
    mix-blend-mode: color;
  }
  .md\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }
  .md\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .md\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .md\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .md\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .md\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .md\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .md\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .md\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .md\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .md\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .md\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }
  .md\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }
  .md\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .md\:focus-within\:outline-white:focus-within {
    outline: 2px dotted white;
    outline-offset: 2px;
  }
  .md\:focus-within\:outline-black:focus-within {
    outline: 2px dotted black;
    outline-offset: 2px;
  }
  .md\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .md\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }
  .md\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }
  .md\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .md\:ring-inset {
    --tw-ring-inset: inset;
  }
  .md\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }
  .md\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }
  .md\:ring-transparent {
    --tw-ring-color: transparent;
  }
  .md\:ring-current {
    --tw-ring-color: currentColor;
  }
  .md\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .md\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .md\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .md\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .md\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .md\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }
  .md\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .md\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .md\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .md\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .md\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .md\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }
  .md\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .md\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .md\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .md\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .md\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .md\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .md\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .md\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .md\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .md\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .md\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .md\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .md\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .md\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .md\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .md\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .md\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .md\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .md\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .md\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .md\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .md\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .md\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .md\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .md\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .md\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .md\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .md\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .md\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .md\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .md\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .md\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .md\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .md\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .md\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .md\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .md\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .md\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .md\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .md\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .md\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .md\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .md\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .md\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .md\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .md\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .md\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .md\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .md\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .md\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .md\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .md\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }
  .md\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }
  .md\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .md\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }
  .md\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }
  .md\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .md\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .md\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }
  .md\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }
  .md\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }
  .md\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }
  .md\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }
  .md\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }
  .md\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }
  .md\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }
  .md\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }
  .md\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }
  .md\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }
  .md\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }
  .md\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }
  .md\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }
  .md\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }
  .md\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }
  .md\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }
  .md\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }
  .md\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }
  .md\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }
  .md\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }
  .md\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }
  .md\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }
  .md\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }
  .md\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }
  .md\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }
  .md\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }
  .md\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }
  .md\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }
  .md\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }
  .md\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }
  .md\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }
  .md\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }
  .md\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }
  .md\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }
  .md\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }
  .md\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }
  .md\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }
  .md\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }
  .md\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }
  .md\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }
  .md\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }
  .md\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }
  .md\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }
  .md\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }
  .md\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }
  .md\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }
  .md\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }
  .md\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }
  .md\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }
  .md\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }
  .md\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }
  .md\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }
  .md\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }
  .md\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }
  .md\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }
  .md\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }
  .md\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }
  .md\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }
  .md\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }
  .md\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }
  .md\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }
  .md\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }
  .md\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }
  .md\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }
  .md\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }
  .md\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }
  .md\:ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }
  .md\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }
  .md\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }
  .md\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }
  .md\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }
  .md\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }
  .md\:ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }
  .md\:ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }
  .md\:ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }
  .md\:ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }
  .md\:ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }
  .md\:ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }
  .md\:ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }
  .md\:ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }
  .md\:ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }
  .md\:ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }
  .md\:ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }
  .md\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }
  .md\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }
  .md\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }
  .md\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }
  .md\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }
  .md\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }
  .md\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }
  .md\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }
  .md\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }
  .md\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }
  .md\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }
  .md\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }
  .md\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }
  .md\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }
  .md\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }
  .md\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }
  .md\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }
  .md\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }
  .md\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }
  .md\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }
  .md\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }
  .md\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }
  .md\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }
  .md\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }
  .md\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }
  .md\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }
  .md\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }
  .md\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }
  .md\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }
  .md\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }
  .md\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }
  .md\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }
  .md\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }
  .md\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }
  .md\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }
  .md\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }
  .md\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }
  .md\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }
  .md\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }
  .md\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }
  .md\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }
  .md\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }
  .md\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }
  .md\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }
  .md\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }
  .md\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }
  .md\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }
  .md\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }
  .md\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }
  .md\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }
  .md\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }
  .md\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }
  .md\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }
  .md\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }
  .md\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }
  .md\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }
  .md\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }
  .md\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }
  .md\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }
  .md\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }
  .md\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }
  .md\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }
  .md\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }
  .md\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }
  .md\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }
  .md\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }
  .md\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .md\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }
  .md\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }
  .md\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }
  .md\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }
  .md\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }
  .md\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }
  .md\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }
  .md\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }
  .md\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }
  .md\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }
  .md\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }
  .md\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }
  .md\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }
  .md\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }
  .md\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }
  .md\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }
  .md\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }
  .md\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }
  .md\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }
  .md\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }
  .md\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }
  .md\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }
  .md\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }
  .md\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }
  .md\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }
  .md\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }
  .md\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }
  .md\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }
  .md\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }
  .md\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }
  .md\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }
  .md\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }
  .md\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }
  .md\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }
  .md\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }
  .md\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }
  .md\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }
  .md\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }
  .md\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }
  .md\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }
  .md\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }
  .md\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }
  .md\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }
  .md\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }
  .md\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }
  .md\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }
  .md\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }
  .md\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }
  .md\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }
  .md\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }
  .md\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }
  .md\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }
  .md\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }
  .md\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }
  .md\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }
  .md\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }
  .md\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }
  .md\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }
  .md\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }
  .md\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }
  .md\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }
  .md\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }
  .md\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }
  .md\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }
  .md\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }
  .md\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }
  .md\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }
  .md\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }
  .md\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }
  .md\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }
  .md\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }
  .md\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }
  .md\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }
  .md\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }
  .md\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }
  .md\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }
  .md\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }
  .md\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }
  .md\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }
  .md\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }
  .md\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }
  .md\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }
  .md\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }
  .md\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .md\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }
  .md\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }
  .md\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }
  .md\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }
  .md\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }
  .md\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }
  .md\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }
  .md\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }
  .md\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }
  .md\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }
  .md\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }
  .md\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }
  .md\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }
  .md\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }
  .md\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }
  .md\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }
  .md\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }
  .md\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }
  .md\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }
  .md\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }
  .md\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }
  .md\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }
  .md\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }
  .md\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }
  .md\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }
  .md\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }
  .md\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }
  .md\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }
  .md\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }
  .md\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }
  .md\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }
  .md\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }
  .md\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }
  .md\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }
  .md\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }
  .md\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }
  .md\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }
  .md\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }
  .md\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }
  .md\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }
  .md\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }
  .md\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }
  .md\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }
  .md\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }
  .md\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }
  .md\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }
  .md\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }
  .md\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }
  .md\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }
  .md\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }
  .md\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }
  .md\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }
  .md\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }
  .md\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }
  .md\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }
  .md\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }
  .md\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }
  .md\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }
  .md\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }
  .md\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }
  .md\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }
  .md\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }
  .md\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }
  .md\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }
  .md\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }
  .md\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }
  .md\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }
  .md\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }
  .md\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }
  .md\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }
  .md\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }
  .md\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }
  .md\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }
  .md\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }
  .md\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }
  .md\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }
  .md\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }
  .md\:filter {
    --tw-blur: var(--tw-empty); /*!*/ /*!*/
    --tw-brightness: var(--tw-empty); /*!*/ /*!*/
    --tw-contrast: var(--tw-empty); /*!*/ /*!*/
    --tw-grayscale: var(--tw-empty); /*!*/ /*!*/
    --tw-hue-rotate: var(--tw-empty); /*!*/ /*!*/
    --tw-invert: var(--tw-empty); /*!*/ /*!*/
    --tw-saturate: var(--tw-empty); /*!*/ /*!*/
    --tw-sepia: var(--tw-empty); /*!*/ /*!*/
    --tw-drop-shadow: var(--tw-empty); /*!*/ /*!*/
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .md\:filter-none {
    filter: none;
  }
  .md\:blur-0 {
    --tw-blur: blur(0);
  }
  .md\:blur-none {
    --tw-blur: blur(0);
  }
  .md\:blur-sm {
    --tw-blur: blur(4px);
  }
  .md\:blur {
    --tw-blur: blur(8px);
  }
  .md\:blur-md {
    --tw-blur: blur(12px);
  }
  .md\:blur-lg {
    --tw-blur: blur(16px);
  }
  .md\:blur-xl {
    --tw-blur: blur(24px);
  }
  .md\:blur-2xl {
    --tw-blur: blur(40px);
  }
  .md\:blur-3xl {
    --tw-blur: blur(64px);
  }
  .md\:brightness-0 {
    --tw-brightness: brightness(0);
  }
  .md\:brightness-50 {
    --tw-brightness: brightness(0.5);
  }
  .md\:brightness-75 {
    --tw-brightness: brightness(0.75);
  }
  .md\:brightness-90 {
    --tw-brightness: brightness(0.9);
  }
  .md\:brightness-95 {
    --tw-brightness: brightness(0.95);
  }
  .md\:brightness-100 {
    --tw-brightness: brightness(1);
  }
  .md\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }
  .md\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }
  .md\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }
  .md\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }
  .md\:brightness-200 {
    --tw-brightness: brightness(2);
  }
  .md\:contrast-0 {
    --tw-contrast: contrast(0);
  }
  .md\:contrast-50 {
    --tw-contrast: contrast(0.5);
  }
  .md\:contrast-75 {
    --tw-contrast: contrast(0.75);
  }
  .md\:contrast-100 {
    --tw-contrast: contrast(1);
  }
  .md\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }
  .md\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }
  .md\:contrast-200 {
    --tw-contrast: contrast(2);
  }
  .md\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.05));
  }
  .md\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1))
      drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }
  .md\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07))
      drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }
  .md\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04))
      drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }
  .md\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03))
      drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }
  .md\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }
  .md\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }
  .md\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }
  .md\:grayscale {
    --tw-grayscale: grayscale(100%);
  }
  .md\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }
  .md\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }
  .md\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }
  .md\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }
  .md\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }
  .md\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }
  .md\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }
  .md\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }
  .md\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }
  .md\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }
  .md\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }
  .md\:invert-0 {
    --tw-invert: invert(0);
  }
  .md\:invert {
    --tw-invert: invert(100%);
  }
  .md\:saturate-0 {
    --tw-saturate: saturate(0);
  }
  .md\:saturate-50 {
    --tw-saturate: saturate(0.5);
  }
  .md\:saturate-100 {
    --tw-saturate: saturate(1);
  }
  .md\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }
  .md\:saturate-200 {
    --tw-saturate: saturate(2);
  }
  .md\:sepia-0 {
    --tw-sepia: sepia(0);
  }
  .md\:sepia {
    --tw-sepia: sepia(100%);
  }
  .md\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-brightness: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-contrast: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-grayscale: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-hue-rotate: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-invert: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-opacity: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-saturate: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-sepia: var(--tw-empty); /*!*/ /*!*/
    -webkit-backdrop-filter: var(--tw-backdrop-blur)
      var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
      var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate)
      var(--tw-backdrop-invert) var(--tw-backdrop-opacity)
      var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
      var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
      var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
      var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
      var(--tw-backdrop-sepia);
  }
  .md\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }
  .md\:backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
  }
  .md\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }
  .md\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }
  .md\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }
  .md\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }
  .md\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }
  .md\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }
  .md\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }
  .md\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }
  .md\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }
  .md\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(0.5);
  }
  .md\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(0.75);
  }
  .md\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(0.9);
  }
  .md\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(0.95);
  }
  .md\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }
  .md\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }
  .md\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }
  .md\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }
  .md\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }
  .md\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }
  .md\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }
  .md\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(0.5);
  }
  .md\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(0.75);
  }
  .md\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }
  .md\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }
  .md\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }
  .md\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }
  .md\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }
  .md\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }
  .md\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }
  .md\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }
  .md\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }
  .md\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }
  .md\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }
  .md\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }
  .md\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }
  .md\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }
  .md\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }
  .md\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }
  .md\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }
  .md\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }
  .md\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }
  .md\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }
  .md\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(0.05);
  }
  .md\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(0.1);
  }
  .md\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(0.2);
  }
  .md\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(0.25);
  }
  .md\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(0.3);
  }
  .md\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(0.4);
  }
  .md\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(0.5);
  }
  .md\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(0.6);
  }
  .md\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(0.7);
  }
  .md\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(0.75);
  }
  .md\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(0.8);
  }
  .md\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(0.9);
  }
  .md\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(0.95);
  }
  .md\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }
  .md\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }
  .md\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(0.5);
  }
  .md\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }
  .md\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }
  .md\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }
  .md\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }
  .md\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }
  .md\:transition-none {
    transition-property: none;
  }
  .md\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .md\:transition {
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, backdrop-filter,
      -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .md\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .md\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .md\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .md\:transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .md\:delay-75 {
    transition-delay: 75ms;
  }
  .md\:delay-100 {
    transition-delay: 0.1s;
  }
  .md\:delay-150 {
    transition-delay: 150ms;
  }
  .md\:delay-200 {
    transition-delay: 0.2s;
  }
  .md\:delay-300 {
    transition-delay: 0.3s;
  }
  .md\:delay-500 {
    transition-delay: 0.5s;
  }
  .md\:delay-700 {
    transition-delay: 0.7s;
  }
  .md\:delay-1000 {
    transition-delay: 1s;
  }
  .md\:duration-75 {
    transition-duration: 75ms;
  }
  .md\:duration-100 {
    transition-duration: 0.1s;
  }
  .md\:duration-150 {
    transition-duration: 150ms;
  }
  .md\:duration-200 {
    transition-duration: 0.2s;
  }
  .md\:duration-300 {
    transition-duration: 0.3s;
  }
  .md\:duration-500 {
    transition-duration: 0.5s;
  }
  .md\:duration-700 {
    transition-duration: 0.7s;
  }
  .md\:duration-1000 {
    transition-duration: 1s;
  }
  .md\:ease-linear {
    transition-timing-function: linear;
  }
  .md\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .md\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .md\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}
@media (min-width: 1024px) {
  .lg\:container {
    width: 100%;
  }
  @media (min-width: 640px) {
    .lg\:container {
      max-width: 640px;
    }
  }
  @media (min-width: 768px) {
    .lg\:container {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
    .lg\:container {
      max-width: 1024px;
    }
  }
  @media (min-width: 1280px) {
    .lg\:container {
      max-width: 1280px;
    }
  }
  @media (min-width: 1536px) {
    .lg\:container {
      max-width: 1536px;
    }
  }
  .lg\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .lg\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .lg\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .lg\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .lg\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .lg\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .lg\:pointer-events-none {
    pointer-events: none;
  }
  .lg\:pointer-events-auto {
    pointer-events: auto;
  }
  .lg\:visible {
    visibility: visible;
  }
  .lg\:invisible {
    visibility: hidden;
  }
  .lg\:static {
    position: static;
  }
  .lg\:fixed {
    position: fixed;
  }
  .lg\:absolute {
    position: absolute;
  }
  .lg\:relative {
    position: relative;
  }
  .lg\:sticky {
    position: sticky;
  }
  .lg\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .lg\:inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }
  .lg\:inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }
  .lg\:inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }
  .lg\:inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
  .lg\:inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }
  .lg\:inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }
  .lg\:inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }
  .lg\:inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }
  .lg\:inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }
  .lg\:inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }
  .lg\:inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }
  .lg\:inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }
  .lg\:inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }
  .lg\:inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }
  .lg\:inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }
  .lg\:inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }
  .lg\:inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }
  .lg\:inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }
  .lg\:inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }
  .lg\:inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }
  .lg\:inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }
  .lg\:inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }
  .lg\:inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }
  .lg\:inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }
  .lg\:inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }
  .lg\:inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }
  .lg\:inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }
  .lg\:inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }
  .lg\:inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }
  .lg\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .lg\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }
  .lg\:inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }
  .lg\:inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }
  .lg\:inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }
  .lg\:inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }
  .lg\:-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .lg\:-inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }
  .lg\:-inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }
  .lg\:-inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }
  .lg\:-inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }
  .lg\:-inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }
  .lg\:-inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }
  .lg\:-inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }
  .lg\:-inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }
  .lg\:-inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }
  .lg\:-inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }
  .lg\:-inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }
  .lg\:-inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }
  .lg\:-inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }
  .lg\:-inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }
  .lg\:-inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }
  .lg\:-inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }
  .lg\:-inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }
  .lg\:-inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }
  .lg\:-inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }
  .lg\:-inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }
  .lg\:-inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }
  .lg\:-inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }
  .lg\:-inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }
  .lg\:-inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }
  .lg\:-inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }
  .lg\:-inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }
  .lg\:-inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }
  .lg\:-inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }
  .lg\:-inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }
  .lg\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }
  .lg\:-inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }
  .lg\:-inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }
  .lg\:-inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }
  .lg\:-inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }
  .lg\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .lg\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }
  .lg\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }
  .lg\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }
  .lg\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .lg\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }
  .lg\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }
  .lg\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .lg\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }
  .lg\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }
  .lg\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }
  .lg\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .lg\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }
  .lg\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }
  .lg\:inset-x-0 {
    left: 0;
    right: 0;
  }
  .lg\:inset-x-1 {
    left: 0.25rem;
    right: 0.25rem;
  }
  .lg\:inset-x-2 {
    left: 0.5rem;
    right: 0.5rem;
  }
  .lg\:inset-x-3 {
    left: 0.75rem;
    right: 0.75rem;
  }
  .lg\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }
  .lg\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }
  .lg\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }
  .lg\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }
  .lg\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }
  .lg\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }
  .lg\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }
  .lg\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }
  .lg\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }
  .lg\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }
  .lg\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }
  .lg\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }
  .lg\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }
  .lg\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }
  .lg\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }
  .lg\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }
  .lg\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }
  .lg\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }
  .lg\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }
  .lg\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }
  .lg\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }
  .lg\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }
  .lg\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }
  .lg\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }
  .lg\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }
  .lg\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }
  .lg\:inset-x-auto {
    left: auto;
    right: auto;
  }
  .lg\:inset-x-px {
    left: 1px;
    right: 1px;
  }
  .lg\:inset-x-0\.5 {
    left: 0.125rem;
    right: 0.125rem;
  }
  .lg\:inset-x-1\.5 {
    left: 0.375rem;
    right: 0.375rem;
  }
  .lg\:inset-x-2\.5 {
    left: 0.625rem;
    right: 0.625rem;
  }
  .lg\:inset-x-3\.5 {
    left: 0.875rem;
    right: 0.875rem;
  }
  .lg\:-inset-x-0 {
    left: 0;
    right: 0;
  }
  .lg\:-inset-x-1 {
    left: -0.25rem;
    right: -0.25rem;
  }
  .lg\:-inset-x-2 {
    left: -0.5rem;
    right: -0.5rem;
  }
  .lg\:-inset-x-3 {
    left: -0.75rem;
    right: -0.75rem;
  }
  .lg\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }
  .lg\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }
  .lg\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }
  .lg\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }
  .lg\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }
  .lg\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }
  .lg\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }
  .lg\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }
  .lg\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }
  .lg\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }
  .lg\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }
  .lg\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }
  .lg\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }
  .lg\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }
  .lg\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }
  .lg\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }
  .lg\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }
  .lg\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }
  .lg\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }
  .lg\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }
  .lg\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }
  .lg\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }
  .lg\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }
  .lg\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }
  .lg\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }
  .lg\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }
  .lg\:-inset-x-px {
    left: -1px;
    right: -1px;
  }
  .lg\:-inset-x-0\.5 {
    left: -0.125rem;
    right: -0.125rem;
  }
  .lg\:-inset-x-1\.5 {
    left: -0.375rem;
    right: -0.375rem;
  }
  .lg\:-inset-x-2\.5 {
    left: -0.625rem;
    right: -0.625rem;
  }
  .lg\:-inset-x-3\.5 {
    left: -0.875rem;
    right: -0.875rem;
  }
  .lg\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }
  .lg\:inset-x-1\/3 {
    left: 33.333333%;
    right: 33.333333%;
  }
  .lg\:inset-x-2\/3 {
    left: 66.666667%;
    right: 66.666667%;
  }
  .lg\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }
  .lg\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }
  .lg\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }
  .lg\:inset-x-full {
    left: 100%;
    right: 100%;
  }
  .lg\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }
  .lg\:-inset-x-1\/3 {
    left: -33.333333%;
    right: -33.333333%;
  }
  .lg\:-inset-x-2\/3 {
    left: -66.666667%;
    right: -66.666667%;
  }
  .lg\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }
  .lg\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }
  .lg\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }
  .lg\:-inset-x-full {
    left: -100%;
    right: -100%;
  }
  .lg\:inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .lg\:inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }
  .lg\:inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }
  .lg\:inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }
  .lg\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }
  .lg\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }
  .lg\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  .lg\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }
  .lg\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }
  .lg\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }
  .lg\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }
  .lg\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }
  .lg\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }
  .lg\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }
  .lg\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }
  .lg\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }
  .lg\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }
  .lg\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }
  .lg\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }
  .lg\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }
  .lg\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }
  .lg\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }
  .lg\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }
  .lg\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }
  .lg\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }
  .lg\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }
  .lg\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }
  .lg\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }
  .lg\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }
  .lg\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }
  .lg\:inset-y-auto {
    top: auto;
    bottom: auto;
  }
  .lg\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }
  .lg\:inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }
  .lg\:inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }
  .lg\:inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }
  .lg\:inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }
  .lg\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .lg\:-inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }
  .lg\:-inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }
  .lg\:-inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }
  .lg\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }
  .lg\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }
  .lg\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }
  .lg\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }
  .lg\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }
  .lg\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }
  .lg\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }
  .lg\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }
  .lg\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }
  .lg\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }
  .lg\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }
  .lg\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }
  .lg\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }
  .lg\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }
  .lg\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }
  .lg\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }
  .lg\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }
  .lg\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }
  .lg\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }
  .lg\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }
  .lg\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }
  .lg\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }
  .lg\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }
  .lg\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }
  .lg\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }
  .lg\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }
  .lg\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }
  .lg\:-inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }
  .lg\:-inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }
  .lg\:-inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }
  .lg\:-inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }
  .lg\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }
  .lg\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }
  .lg\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }
  .lg\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }
  .lg\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }
  .lg\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }
  .lg\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }
  .lg\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }
  .lg\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }
  .lg\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }
  .lg\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }
  .lg\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }
  .lg\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }
  .lg\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }
  .lg\:top-0 {
    top: 0;
  }
  .lg\:top-1 {
    top: 0.25rem;
  }
  .lg\:top-2 {
    top: 0.5rem;
  }
  .lg\:top-3 {
    top: 0.75rem;
  }
  .lg\:top-4 {
    top: 1rem;
  }
  .lg\:top-5 {
    top: 1.25rem;
  }
  .lg\:top-6 {
    top: 1.5rem;
  }
  .lg\:top-7 {
    top: 1.75rem;
  }
  .lg\:top-8 {
    top: 2rem;
  }
  .lg\:top-9 {
    top: 2.25rem;
  }
  .lg\:top-10 {
    top: 2.5rem;
  }
  .lg\:top-11 {
    top: 2.75rem;
  }
  .lg\:top-12 {
    top: 3rem;
  }
  .lg\:top-14 {
    top: 3.5rem;
  }
  .lg\:top-16 {
    top: 4rem;
  }
  .lg\:top-20 {
    top: 5rem;
  }
  .lg\:top-24 {
    top: 6rem;
  }
  .lg\:top-28 {
    top: 7rem;
  }
  .lg\:top-32 {
    top: 8rem;
  }
  .lg\:top-36 {
    top: 9rem;
  }
  .lg\:top-40 {
    top: 10rem;
  }
  .lg\:top-44 {
    top: 11rem;
  }
  .lg\:top-48 {
    top: 12rem;
  }
  .lg\:top-52 {
    top: 13rem;
  }
  .lg\:top-56 {
    top: 14rem;
  }
  .lg\:top-60 {
    top: 15rem;
  }
  .lg\:top-64 {
    top: 16rem;
  }
  .lg\:top-72 {
    top: 18rem;
  }
  .lg\:top-80 {
    top: 20rem;
  }
  .lg\:top-96 {
    top: 24rem;
  }
  .lg\:top-auto {
    top: auto;
  }
  .lg\:top-px {
    top: 1px;
  }
  .lg\:top-0\.5 {
    top: 0.125rem;
  }
  .lg\:top-1\.5 {
    top: 0.375rem;
  }
  .lg\:top-2\.5 {
    top: 0.625rem;
  }
  .lg\:top-3\.5 {
    top: 0.875rem;
  }
  .lg\:-top-0 {
    top: 0;
  }
  .lg\:-top-1 {
    top: -0.25rem;
  }
  .lg\:-top-2 {
    top: -0.5rem;
  }
  .lg\:-top-3 {
    top: -0.75rem;
  }
  .lg\:-top-4 {
    top: -1rem;
  }
  .lg\:-top-5 {
    top: -1.25rem;
  }
  .lg\:-top-6 {
    top: -1.5rem;
  }
  .lg\:-top-7 {
    top: -1.75rem;
  }
  .lg\:-top-8 {
    top: -2rem;
  }
  .lg\:-top-9 {
    top: -2.25rem;
  }
  .lg\:-top-10 {
    top: -2.5rem;
  }
  .lg\:-top-11 {
    top: -2.75rem;
  }
  .lg\:-top-12 {
    top: -3rem;
  }
  .lg\:-top-14 {
    top: -3.5rem;
  }
  .lg\:-top-16 {
    top: -4rem;
  }
  .lg\:-top-20 {
    top: -5rem;
  }
  .lg\:-top-24 {
    top: -6rem;
  }
  .lg\:-top-28 {
    top: -7rem;
  }
  .lg\:-top-32 {
    top: -8rem;
  }
  .lg\:-top-36 {
    top: -9rem;
  }
  .lg\:-top-40 {
    top: -10rem;
  }
  .lg\:-top-44 {
    top: -11rem;
  }
  .lg\:-top-48 {
    top: -12rem;
  }
  .lg\:-top-52 {
    top: -13rem;
  }
  .lg\:-top-56 {
    top: -14rem;
  }
  .lg\:-top-60 {
    top: -15rem;
  }
  .lg\:-top-64 {
    top: -16rem;
  }
  .lg\:-top-72 {
    top: -18rem;
  }
  .lg\:-top-80 {
    top: -20rem;
  }
  .lg\:-top-96 {
    top: -24rem;
  }
  .lg\:-top-px {
    top: -1px;
  }
  .lg\:-top-0\.5 {
    top: -0.125rem;
  }
  .lg\:-top-1\.5 {
    top: -0.375rem;
  }
  .lg\:-top-2\.5 {
    top: -0.625rem;
  }
  .lg\:-top-3\.5 {
    top: -0.875rem;
  }
  .lg\:top-1\/2 {
    top: 50%;
  }
  .lg\:top-1\/3 {
    top: 33.333333%;
  }
  .lg\:top-2\/3 {
    top: 66.666667%;
  }
  .lg\:top-1\/4 {
    top: 25%;
  }
  .lg\:top-2\/4 {
    top: 50%;
  }
  .lg\:top-3\/4 {
    top: 75%;
  }
  .lg\:top-full {
    top: 100%;
  }
  .lg\:-top-1\/2 {
    top: -50%;
  }
  .lg\:-top-1\/3 {
    top: -33.333333%;
  }
  .lg\:-top-2\/3 {
    top: -66.666667%;
  }
  .lg\:-top-1\/4 {
    top: -25%;
  }
  .lg\:-top-2\/4 {
    top: -50%;
  }
  .lg\:-top-3\/4 {
    top: -75%;
  }
  .lg\:-top-full {
    top: -100%;
  }
  .lg\:right-0 {
    right: 0;
  }
  .lg\:right-1 {
    right: 0.25rem;
  }
  .lg\:right-2 {
    right: 0.5rem;
  }
  .lg\:right-3 {
    right: 0.75rem;
  }
  .lg\:right-4 {
    right: 1rem;
  }
  .lg\:right-5 {
    right: 1.25rem;
  }
  .lg\:right-6 {
    right: 1.5rem;
  }
  .lg\:right-7 {
    right: 1.75rem;
  }
  .lg\:right-8 {
    right: 2rem;
  }
  .lg\:right-9 {
    right: 2.25rem;
  }
  .lg\:right-10 {
    right: 2.5rem;
  }
  .lg\:right-11 {
    right: 2.75rem;
  }
  .lg\:right-12 {
    right: 3rem;
  }
  .lg\:right-14 {
    right: 3.5rem;
  }
  .lg\:right-16 {
    right: 4rem;
  }
  .lg\:right-20 {
    right: 5rem;
  }
  .lg\:right-24 {
    right: 6rem;
  }
  .lg\:right-28 {
    right: 7rem;
  }
  .lg\:right-32 {
    right: 8rem;
  }
  .lg\:right-36 {
    right: 9rem;
  }
  .lg\:right-40 {
    right: 10rem;
  }
  .lg\:right-44 {
    right: 11rem;
  }
  .lg\:right-48 {
    right: 12rem;
  }
  .lg\:right-52 {
    right: 13rem;
  }
  .lg\:right-56 {
    right: 14rem;
  }
  .lg\:right-60 {
    right: 15rem;
  }
  .lg\:right-64 {
    right: 16rem;
  }
  .lg\:right-72 {
    right: 18rem;
  }
  .lg\:right-80 {
    right: 20rem;
  }
  .lg\:right-96 {
    right: 24rem;
  }
  .lg\:right-auto {
    right: auto;
  }
  .lg\:right-px {
    right: 1px;
  }
  .lg\:right-0\.5 {
    right: 0.125rem;
  }
  .lg\:right-1\.5 {
    right: 0.375rem;
  }
  .lg\:right-2\.5 {
    right: 0.625rem;
  }
  .lg\:right-3\.5 {
    right: 0.875rem;
  }
  .lg\:-right-0 {
    right: 0;
  }
  .lg\:-right-1 {
    right: -0.25rem;
  }
  .lg\:-right-2 {
    right: -0.5rem;
  }
  .lg\:-right-3 {
    right: -0.75rem;
  }
  .lg\:-right-4 {
    right: -1rem;
  }
  .lg\:-right-5 {
    right: -1.25rem;
  }
  .lg\:-right-6 {
    right: -1.5rem;
  }
  .lg\:-right-7 {
    right: -1.75rem;
  }
  .lg\:-right-8 {
    right: -2rem;
  }
  .lg\:-right-9 {
    right: -2.25rem;
  }
  .lg\:-right-10 {
    right: -2.5rem;
  }
  .lg\:-right-11 {
    right: -2.75rem;
  }
  .lg\:-right-12 {
    right: -3rem;
  }
  .lg\:-right-14 {
    right: -3.5rem;
  }
  .lg\:-right-16 {
    right: -4rem;
  }
  .lg\:-right-20 {
    right: -5rem;
  }
  .lg\:-right-24 {
    right: -6rem;
  }
  .lg\:-right-28 {
    right: -7rem;
  }
  .lg\:-right-32 {
    right: -8rem;
  }
  .lg\:-right-36 {
    right: -9rem;
  }
  .lg\:-right-40 {
    right: -10rem;
  }
  .lg\:-right-44 {
    right: -11rem;
  }
  .lg\:-right-48 {
    right: -12rem;
  }
  .lg\:-right-52 {
    right: -13rem;
  }
  .lg\:-right-56 {
    right: -14rem;
  }
  .lg\:-right-60 {
    right: -15rem;
  }
  .lg\:-right-64 {
    right: -16rem;
  }
  .lg\:-right-72 {
    right: -18rem;
  }
  .lg\:-right-80 {
    right: -20rem;
  }
  .lg\:-right-96 {
    right: -24rem;
  }
  .lg\:-right-px {
    right: -1px;
  }
  .lg\:-right-0\.5 {
    right: -0.125rem;
  }
  .lg\:-right-1\.5 {
    right: -0.375rem;
  }
  .lg\:-right-2\.5 {
    right: -0.625rem;
  }
  .lg\:-right-3\.5 {
    right: -0.875rem;
  }
  .lg\:right-1\/2 {
    right: 50%;
  }
  .lg\:right-1\/3 {
    right: 33.333333%;
  }
  .lg\:right-2\/3 {
    right: 66.666667%;
  }
  .lg\:right-1\/4 {
    right: 25%;
  }
  .lg\:right-2\/4 {
    right: 50%;
  }
  .lg\:right-3\/4 {
    right: 75%;
  }
  .lg\:right-full {
    right: 100%;
  }
  .lg\:-right-1\/2 {
    right: -50%;
  }
  .lg\:-right-1\/3 {
    right: -33.333333%;
  }
  .lg\:-right-2\/3 {
    right: -66.666667%;
  }
  .lg\:-right-1\/4 {
    right: -25%;
  }
  .lg\:-right-2\/4 {
    right: -50%;
  }
  .lg\:-right-3\/4 {
    right: -75%;
  }
  .lg\:-right-full {
    right: -100%;
  }
  .lg\:bottom-0 {
    bottom: 0;
  }
  .lg\:bottom-1 {
    bottom: 0.25rem;
  }
  .lg\:bottom-2 {
    bottom: 0.5rem;
  }
  .lg\:bottom-3 {
    bottom: 0.75rem;
  }
  .lg\:bottom-4 {
    bottom: 1rem;
  }
  .lg\:bottom-5 {
    bottom: 1.25rem;
  }
  .lg\:bottom-6 {
    bottom: 1.5rem;
  }
  .lg\:bottom-7 {
    bottom: 1.75rem;
  }
  .lg\:bottom-8 {
    bottom: 2rem;
  }
  .lg\:bottom-9 {
    bottom: 2.25rem;
  }
  .lg\:bottom-10 {
    bottom: 2.5rem;
  }
  .lg\:bottom-11 {
    bottom: 2.75rem;
  }
  .lg\:bottom-12 {
    bottom: 3rem;
  }
  .lg\:bottom-14 {
    bottom: 3.5rem;
  }
  .lg\:bottom-16 {
    bottom: 4rem;
  }
  .lg\:bottom-20 {
    bottom: 5rem;
  }
  .lg\:bottom-24 {
    bottom: 6rem;
  }
  .lg\:bottom-28 {
    bottom: 7rem;
  }
  .lg\:bottom-32 {
    bottom: 8rem;
  }
  .lg\:bottom-36 {
    bottom: 9rem;
  }
  .lg\:bottom-40 {
    bottom: 10rem;
  }
  .lg\:bottom-44 {
    bottom: 11rem;
  }
  .lg\:bottom-48 {
    bottom: 12rem;
  }
  .lg\:bottom-52 {
    bottom: 13rem;
  }
  .lg\:bottom-56 {
    bottom: 14rem;
  }
  .lg\:bottom-60 {
    bottom: 15rem;
  }
  .lg\:bottom-64 {
    bottom: 16rem;
  }
  .lg\:bottom-72 {
    bottom: 18rem;
  }
  .lg\:bottom-80 {
    bottom: 20rem;
  }
  .lg\:bottom-96 {
    bottom: 24rem;
  }
  .lg\:bottom-auto {
    bottom: auto;
  }
  .lg\:bottom-px {
    bottom: 1px;
  }
  .lg\:bottom-0\.5 {
    bottom: 0.125rem;
  }
  .lg\:bottom-1\.5 {
    bottom: 0.375rem;
  }
  .lg\:bottom-2\.5 {
    bottom: 0.625rem;
  }
  .lg\:bottom-3\.5 {
    bottom: 0.875rem;
  }
  .lg\:-bottom-0 {
    bottom: 0;
  }
  .lg\:-bottom-1 {
    bottom: -0.25rem;
  }
  .lg\:-bottom-2 {
    bottom: -0.5rem;
  }
  .lg\:-bottom-3 {
    bottom: -0.75rem;
  }
  .lg\:-bottom-4 {
    bottom: -1rem;
  }
  .lg\:-bottom-5 {
    bottom: -1.25rem;
  }
  .lg\:-bottom-6 {
    bottom: -1.5rem;
  }
  .lg\:-bottom-7 {
    bottom: -1.75rem;
  }
  .lg\:-bottom-8 {
    bottom: -2rem;
  }
  .lg\:-bottom-9 {
    bottom: -2.25rem;
  }
  .lg\:-bottom-10 {
    bottom: -2.5rem;
  }
  .lg\:-bottom-11 {
    bottom: -2.75rem;
  }
  .lg\:-bottom-12 {
    bottom: -3rem;
  }
  .lg\:-bottom-14 {
    bottom: -3.5rem;
  }
  .lg\:-bottom-16 {
    bottom: -4rem;
  }
  .lg\:-bottom-20 {
    bottom: -5rem;
  }
  .lg\:-bottom-24 {
    bottom: -6rem;
  }
  .lg\:-bottom-28 {
    bottom: -7rem;
  }
  .lg\:-bottom-32 {
    bottom: -8rem;
  }
  .lg\:-bottom-36 {
    bottom: -9rem;
  }
  .lg\:-bottom-40 {
    bottom: -10rem;
  }
  .lg\:-bottom-44 {
    bottom: -11rem;
  }
  .lg\:-bottom-48 {
    bottom: -12rem;
  }
  .lg\:-bottom-52 {
    bottom: -13rem;
  }
  .lg\:-bottom-56 {
    bottom: -14rem;
  }
  .lg\:-bottom-60 {
    bottom: -15rem;
  }
  .lg\:-bottom-64 {
    bottom: -16rem;
  }
  .lg\:-bottom-72 {
    bottom: -18rem;
  }
  .lg\:-bottom-80 {
    bottom: -20rem;
  }
  .lg\:-bottom-96 {
    bottom: -24rem;
  }
  .lg\:-bottom-px {
    bottom: -1px;
  }
  .lg\:-bottom-0\.5 {
    bottom: -0.125rem;
  }
  .lg\:-bottom-1\.5 {
    bottom: -0.375rem;
  }
  .lg\:-bottom-2\.5 {
    bottom: -0.625rem;
  }
  .lg\:-bottom-3\.5 {
    bottom: -0.875rem;
  }
  .lg\:bottom-1\/2 {
    bottom: 50%;
  }
  .lg\:bottom-1\/3 {
    bottom: 33.333333%;
  }
  .lg\:bottom-2\/3 {
    bottom: 66.666667%;
  }
  .lg\:bottom-1\/4 {
    bottom: 25%;
  }
  .lg\:bottom-2\/4 {
    bottom: 50%;
  }
  .lg\:bottom-3\/4 {
    bottom: 75%;
  }
  .lg\:bottom-full {
    bottom: 100%;
  }
  .lg\:-bottom-1\/2 {
    bottom: -50%;
  }
  .lg\:-bottom-1\/3 {
    bottom: -33.333333%;
  }
  .lg\:-bottom-2\/3 {
    bottom: -66.666667%;
  }
  .lg\:-bottom-1\/4 {
    bottom: -25%;
  }
  .lg\:-bottom-2\/4 {
    bottom: -50%;
  }
  .lg\:-bottom-3\/4 {
    bottom: -75%;
  }
  .lg\:-bottom-full {
    bottom: -100%;
  }
  .lg\:left-0 {
    left: 0;
  }
  .lg\:left-1 {
    left: 0.25rem;
  }
  .lg\:left-2 {
    left: 0.5rem;
  }
  .lg\:left-3 {
    left: 0.75rem;
  }
  .lg\:left-4 {
    left: 1rem;
  }
  .lg\:left-5 {
    left: 1.25rem;
  }
  .lg\:left-6 {
    left: 1.5rem;
  }
  .lg\:left-7 {
    left: 1.75rem;
  }
  .lg\:left-8 {
    left: 2rem;
  }
  .lg\:left-9 {
    left: 2.25rem;
  }
  .lg\:left-10 {
    left: 2.5rem;
  }
  .lg\:left-11 {
    left: 2.75rem;
  }
  .lg\:left-12 {
    left: 3rem;
  }
  .lg\:left-14 {
    left: 3.5rem;
  }
  .lg\:left-16 {
    left: 4rem;
  }
  .lg\:left-20 {
    left: 5rem;
  }
  .lg\:left-24 {
    left: 6rem;
  }
  .lg\:left-28 {
    left: 7rem;
  }
  .lg\:left-32 {
    left: 8rem;
  }
  .lg\:left-36 {
    left: 9rem;
  }
  .lg\:left-40 {
    left: 10rem;
  }
  .lg\:left-44 {
    left: 11rem;
  }
  .lg\:left-48 {
    left: 12rem;
  }
  .lg\:left-52 {
    left: 13rem;
  }
  .lg\:left-56 {
    left: 14rem;
  }
  .lg\:left-60 {
    left: 15rem;
  }
  .lg\:left-64 {
    left: 16rem;
  }
  .lg\:left-72 {
    left: 18rem;
  }
  .lg\:left-80 {
    left: 20rem;
  }
  .lg\:left-96 {
    left: 24rem;
  }
  .lg\:left-auto {
    left: auto;
  }
  .lg\:left-px {
    left: 1px;
  }
  .lg\:left-0\.5 {
    left: 0.125rem;
  }
  .lg\:left-1\.5 {
    left: 0.375rem;
  }
  .lg\:left-2\.5 {
    left: 0.625rem;
  }
  .lg\:left-3\.5 {
    left: 0.875rem;
  }
  .lg\:-left-0 {
    left: 0;
  }
  .lg\:-left-1 {
    left: -0.25rem;
  }
  .lg\:-left-2 {
    left: -0.5rem;
  }
  .lg\:-left-3 {
    left: -0.75rem;
  }
  .lg\:-left-4 {
    left: -1rem;
  }
  .lg\:-left-5 {
    left: -1.25rem;
  }
  .lg\:-left-6 {
    left: -1.5rem;
  }
  .lg\:-left-7 {
    left: -1.75rem;
  }
  .lg\:-left-8 {
    left: -2rem;
  }
  .lg\:-left-9 {
    left: -2.25rem;
  }
  .lg\:-left-10 {
    left: -2.5rem;
  }
  .lg\:-left-11 {
    left: -2.75rem;
  }
  .lg\:-left-12 {
    left: -3rem;
  }
  .lg\:-left-14 {
    left: -3.5rem;
  }
  .lg\:-left-16 {
    left: -4rem;
  }
  .lg\:-left-20 {
    left: -5rem;
  }
  .lg\:-left-24 {
    left: -6rem;
  }
  .lg\:-left-28 {
    left: -7rem;
  }
  .lg\:-left-32 {
    left: -8rem;
  }
  .lg\:-left-36 {
    left: -9rem;
  }
  .lg\:-left-40 {
    left: -10rem;
  }
  .lg\:-left-44 {
    left: -11rem;
  }
  .lg\:-left-48 {
    left: -12rem;
  }
  .lg\:-left-52 {
    left: -13rem;
  }
  .lg\:-left-56 {
    left: -14rem;
  }
  .lg\:-left-60 {
    left: -15rem;
  }
  .lg\:-left-64 {
    left: -16rem;
  }
  .lg\:-left-72 {
    left: -18rem;
  }
  .lg\:-left-80 {
    left: -20rem;
  }
  .lg\:-left-96 {
    left: -24rem;
  }
  .lg\:-left-px {
    left: -1px;
  }
  .lg\:-left-0\.5 {
    left: -0.125rem;
  }
  .lg\:-left-1\.5 {
    left: -0.375rem;
  }
  .lg\:-left-2\.5 {
    left: -0.625rem;
  }
  .lg\:-left-3\.5 {
    left: -0.875rem;
  }
  .lg\:left-1\/2 {
    left: 50%;
  }
  .lg\:left-1\/3 {
    left: 33.333333%;
  }
  .lg\:left-2\/3 {
    left: 66.666667%;
  }
  .lg\:left-1\/4 {
    left: 25%;
  }
  .lg\:left-2\/4 {
    left: 50%;
  }
  .lg\:left-3\/4 {
    left: 75%;
  }
  .lg\:left-full {
    left: 100%;
  }
  .lg\:-left-1\/2 {
    left: -50%;
  }
  .lg\:-left-1\/3 {
    left: -33.333333%;
  }
  .lg\:-left-2\/3 {
    left: -66.666667%;
  }
  .lg\:-left-1\/4 {
    left: -25%;
  }
  .lg\:-left-2\/4 {
    left: -50%;
  }
  .lg\:-left-3\/4 {
    left: -75%;
  }
  .lg\:-left-full {
    left: -100%;
  }
  .lg\:isolate {
    isolation: isolate;
  }
  .lg\:isolation-auto {
    isolation: auto;
  }
  .lg\:z-0 {
    z-index: 0;
  }
  .lg\:z-10 {
    z-index: 10;
  }
  .lg\:z-20 {
    z-index: 20;
  }
  .lg\:z-30 {
    z-index: 30;
  }
  .lg\:z-40 {
    z-index: 40;
  }
  .lg\:z-50 {
    z-index: 50;
  }
  .lg\:z-auto {
    z-index: auto;
  }
  .lg\:focus-within\:z-0:focus-within {
    z-index: 0;
  }
  .lg\:focus-within\:z-10:focus-within {
    z-index: 10;
  }
  .lg\:focus-within\:z-20:focus-within {
    z-index: 20;
  }
  .lg\:focus-within\:z-30:focus-within {
    z-index: 30;
  }
  .lg\:focus-within\:z-40:focus-within {
    z-index: 40;
  }
  .lg\:focus-within\:z-50:focus-within {
    z-index: 50;
  }
  .lg\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }
  .lg\:focus\:z-0:focus {
    z-index: 0;
  }
  .lg\:focus\:z-10:focus {
    z-index: 10;
  }
  .lg\:focus\:z-20:focus {
    z-index: 20;
  }
  .lg\:focus\:z-30:focus {
    z-index: 30;
  }
  .lg\:focus\:z-40:focus {
    z-index: 40;
  }
  .lg\:focus\:z-50:focus {
    z-index: 50;
  }
  .lg\:focus\:z-auto:focus {
    z-index: auto;
  }
  .lg\:order-1 {
    order: 1;
  }
  .lg\:order-2 {
    order: 2;
  }
  .lg\:order-3 {
    order: 3;
  }
  .lg\:order-4 {
    order: 4;
  }
  .lg\:order-5 {
    order: 5;
  }
  .lg\:order-6 {
    order: 6;
  }
  .lg\:order-7 {
    order: 7;
  }
  .lg\:order-8 {
    order: 8;
  }
  .lg\:order-9 {
    order: 9;
  }
  .lg\:order-10 {
    order: 10;
  }
  .lg\:order-11 {
    order: 11;
  }
  .lg\:order-12 {
    order: 12;
  }
  .lg\:order-first {
    order: -9999;
  }
  .lg\:order-last {
    order: 9999;
  }
  .lg\:order-none {
    order: 0;
  }
  .lg\:col-auto {
    grid-column: auto;
  }
  .lg\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .lg\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .lg\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .lg\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .lg\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .lg\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  .lg\:col-span-7 {
    grid-column: span 7 / span 7;
  }
  .lg\:col-span-8 {
    grid-column: span 8 / span 8;
  }
  .lg\:col-span-9 {
    grid-column: span 9 / span 9;
  }
  .lg\:col-span-10 {
    grid-column: span 10 / span 10;
  }
  .lg\:col-span-11 {
    grid-column: span 11 / span 11;
  }
  .lg\:col-span-12 {
    grid-column: span 12 / span 12;
  }
  .lg\:col-span-full {
    grid-column: 1/-1;
  }
  .lg\:col-start-1 {
    grid-column-start: 1;
  }
  .lg\:col-start-2 {
    grid-column-start: 2;
  }
  .lg\:col-start-3 {
    grid-column-start: 3;
  }
  .lg\:col-start-4 {
    grid-column-start: 4;
  }
  .lg\:col-start-5 {
    grid-column-start: 5;
  }
  .lg\:col-start-6 {
    grid-column-start: 6;
  }
  .lg\:col-start-7 {
    grid-column-start: 7;
  }
  .lg\:col-start-8 {
    grid-column-start: 8;
  }
  .lg\:col-start-9 {
    grid-column-start: 9;
  }
  .lg\:col-start-10 {
    grid-column-start: 10;
  }
  .lg\:col-start-11 {
    grid-column-start: 11;
  }
  .lg\:col-start-12 {
    grid-column-start: 12;
  }
  .lg\:col-start-13 {
    grid-column-start: 13;
  }
  .lg\:col-start-auto {
    grid-column-start: auto;
  }
  .lg\:col-end-1 {
    grid-column-end: 1;
  }
  .lg\:col-end-2 {
    grid-column-end: 2;
  }
  .lg\:col-end-3 {
    grid-column-end: 3;
  }
  .lg\:col-end-4 {
    grid-column-end: 4;
  }
  .lg\:col-end-5 {
    grid-column-end: 5;
  }
  .lg\:col-end-6 {
    grid-column-end: 6;
  }
  .lg\:col-end-7 {
    grid-column-end: 7;
  }
  .lg\:col-end-8 {
    grid-column-end: 8;
  }
  .lg\:col-end-9 {
    grid-column-end: 9;
  }
  .lg\:col-end-10 {
    grid-column-end: 10;
  }
  .lg\:col-end-11 {
    grid-column-end: 11;
  }
  .lg\:col-end-12 {
    grid-column-end: 12;
  }
  .lg\:col-end-13 {
    grid-column-end: 13;
  }
  .lg\:col-end-auto {
    grid-column-end: auto;
  }
  .lg\:row-auto {
    grid-row: auto;
  }
  .lg\:row-span-1 {
    grid-row: span 1 / span 1;
  }
  .lg\:row-span-2 {
    grid-row: span 2 / span 2;
  }
  .lg\:row-span-3 {
    grid-row: span 3 / span 3;
  }
  .lg\:row-span-4 {
    grid-row: span 4 / span 4;
  }
  .lg\:row-span-5 {
    grid-row: span 5 / span 5;
  }
  .lg\:row-span-6 {
    grid-row: span 6 / span 6;
  }
  .lg\:row-span-full {
    grid-row: 1/-1;
  }
  .lg\:row-start-1 {
    grid-row-start: 1;
  }
  .lg\:row-start-2 {
    grid-row-start: 2;
  }
  .lg\:row-start-3 {
    grid-row-start: 3;
  }
  .lg\:row-start-4 {
    grid-row-start: 4;
  }
  .lg\:row-start-5 {
    grid-row-start: 5;
  }
  .lg\:row-start-6 {
    grid-row-start: 6;
  }
  .lg\:row-start-7 {
    grid-row-start: 7;
  }
  .lg\:row-start-auto {
    grid-row-start: auto;
  }
  .lg\:row-end-1 {
    grid-row-end: 1;
  }
  .lg\:row-end-2 {
    grid-row-end: 2;
  }
  .lg\:row-end-3 {
    grid-row-end: 3;
  }
  .lg\:row-end-4 {
    grid-row-end: 4;
  }
  .lg\:row-end-5 {
    grid-row-end: 5;
  }
  .lg\:row-end-6 {
    grid-row-end: 6;
  }
  .lg\:row-end-7 {
    grid-row-end: 7;
  }
  .lg\:row-end-auto {
    grid-row-end: auto;
  }
  .lg\:float-right {
    float: right;
  }
  .lg\:float-left {
    float: left;
  }
  .lg\:float-none {
    float: none;
  }
  .lg\:clear-left {
    clear: left;
  }
  .lg\:clear-right {
    clear: right;
  }
  .lg\:clear-both {
    clear: both;
  }
  .lg\:clear-none {
    clear: none;
  }
  .lg\:m-0 {
    margin: 0;
  }
  .lg\:m-1 {
    margin: 0.25rem;
  }
  .lg\:m-2 {
    margin: 0.5rem;
  }
  .lg\:m-3 {
    margin: 0.75rem;
  }
  .lg\:m-4 {
    margin: 1rem;
  }
  .lg\:m-5 {
    margin: 1.25rem;
  }
  .lg\:m-6 {
    margin: 1.5rem;
  }
  .lg\:m-7 {
    margin: 1.75rem;
  }
  .lg\:m-8 {
    margin: 2rem;
  }
  .lg\:m-9 {
    margin: 2.25rem;
  }
  .lg\:m-10 {
    margin: 2.5rem;
  }
  .lg\:m-11 {
    margin: 2.75rem;
  }
  .lg\:m-12 {
    margin: 3rem;
  }
  .lg\:m-14 {
    margin: 3.5rem;
  }
  .lg\:m-16 {
    margin: 4rem;
  }
  .lg\:m-20 {
    margin: 5rem;
  }
  .lg\:m-24 {
    margin: 6rem;
  }
  .lg\:m-28 {
    margin: 7rem;
  }
  .lg\:m-32 {
    margin: 8rem;
  }
  .lg\:m-36 {
    margin: 9rem;
  }
  .lg\:m-40 {
    margin: 10rem;
  }
  .lg\:m-44 {
    margin: 11rem;
  }
  .lg\:m-48 {
    margin: 12rem;
  }
  .lg\:m-52 {
    margin: 13rem;
  }
  .lg\:m-56 {
    margin: 14rem;
  }
  .lg\:m-60 {
    margin: 15rem;
  }
  .lg\:m-64 {
    margin: 16rem;
  }
  .lg\:m-72 {
    margin: 18rem;
  }
  .lg\:m-80 {
    margin: 20rem;
  }
  .lg\:m-96 {
    margin: 24rem;
  }
  .lg\:m-auto {
    margin: auto;
  }
  .lg\:m-px {
    margin: 1px;
  }
  .lg\:m-0\.5 {
    margin: 0.125rem;
  }
  .lg\:m-1\.5 {
    margin: 0.375rem;
  }
  .lg\:m-2\.5 {
    margin: 0.625rem;
  }
  .lg\:m-3\.5 {
    margin: 0.875rem;
  }
  .lg\:-m-0 {
    margin: 0;
  }
  .lg\:-m-1 {
    margin: -0.25rem;
  }
  .lg\:-m-2 {
    margin: -0.5rem;
  }
  .lg\:-m-3 {
    margin: -0.75rem;
  }
  .lg\:-m-4 {
    margin: -1rem;
  }
  .lg\:-m-5 {
    margin: -1.25rem;
  }
  .lg\:-m-6 {
    margin: -1.5rem;
  }
  .lg\:-m-7 {
    margin: -1.75rem;
  }
  .lg\:-m-8 {
    margin: -2rem;
  }
  .lg\:-m-9 {
    margin: -2.25rem;
  }
  .lg\:-m-10 {
    margin: -2.5rem;
  }
  .lg\:-m-11 {
    margin: -2.75rem;
  }
  .lg\:-m-12 {
    margin: -3rem;
  }
  .lg\:-m-14 {
    margin: -3.5rem;
  }
  .lg\:-m-16 {
    margin: -4rem;
  }
  .lg\:-m-20 {
    margin: -5rem;
  }
  .lg\:-m-24 {
    margin: -6rem;
  }
  .lg\:-m-28 {
    margin: -7rem;
  }
  .lg\:-m-32 {
    margin: -8rem;
  }
  .lg\:-m-36 {
    margin: -9rem;
  }
  .lg\:-m-40 {
    margin: -10rem;
  }
  .lg\:-m-44 {
    margin: -11rem;
  }
  .lg\:-m-48 {
    margin: -12rem;
  }
  .lg\:-m-52 {
    margin: -13rem;
  }
  .lg\:-m-56 {
    margin: -14rem;
  }
  .lg\:-m-60 {
    margin: -15rem;
  }
  .lg\:-m-64 {
    margin: -16rem;
  }
  .lg\:-m-72 {
    margin: -18rem;
  }
  .lg\:-m-80 {
    margin: -20rem;
  }
  .lg\:-m-96 {
    margin: -24rem;
  }
  .lg\:-m-px {
    margin: -1px;
  }
  .lg\:-m-0\.5 {
    margin: -0.125rem;
  }
  .lg\:-m-1\.5 {
    margin: -0.375rem;
  }
  .lg\:-m-2\.5 {
    margin: -0.625rem;
  }
  .lg\:-m-3\.5 {
    margin: -0.875rem;
  }
  .lg\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .lg\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .lg\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .lg\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .lg\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .lg\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .lg\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .lg\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .lg\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .lg\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .lg\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .lg\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }
  .lg\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .lg\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .lg\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .lg\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .lg\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .lg\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .lg\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .lg\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .lg\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .lg\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .lg\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .lg\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }
  .lg\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }
  .lg\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .lg\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }
  .lg\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }
  .lg\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .lg\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }
  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .lg\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .lg\:mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .lg\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .lg\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .lg\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .lg\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .lg\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .lg\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .lg\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .lg\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .lg\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .lg\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .lg\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }
  .lg\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .lg\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
  .lg\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .lg\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }
  .lg\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .lg\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .lg\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .lg\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .lg\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .lg\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .lg\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .lg\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .lg\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .lg\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }
  .lg\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }
  .lg\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }
  .lg\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }
  .lg\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }
  .lg\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }
  .lg\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }
  .lg\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }
  .lg\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }
  .lg\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .lg\:-mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }
  .lg\:-mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }
  .lg\:-mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .lg\:-mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }
  .lg\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .lg\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .lg\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .lg\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .lg\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .lg\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .lg\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .lg\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .lg\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .lg\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .lg\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .lg\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .lg\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .lg\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .lg\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .lg\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .lg\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .lg\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .lg\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .lg\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .lg\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .lg\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .lg\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }
  .lg\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }
  .lg\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }
  .lg\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
  .lg\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }
  .lg\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }
  .lg\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }
  .lg\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .lg\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .lg\:my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .lg\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .lg\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .lg\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .lg\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .lg\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .lg\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .lg\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .lg\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .lg\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .lg\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .lg\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .lg\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .lg\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }
  .lg\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .lg\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .lg\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .lg\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .lg\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .lg\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .lg\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .lg\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .lg\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .lg\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .lg\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .lg\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }
  .lg\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }
  .lg\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }
  .lg\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }
  .lg\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }
  .lg\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }
  .lg\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }
  .lg\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }
  .lg\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }
  .lg\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .lg\:-my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }
  .lg\:-my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }
  .lg\:-my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .lg\:-my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }
  .lg\:mt-0 {
    margin-top: 0;
  }
  .lg\:mt-1 {
    margin-top: 0.25rem;
  }
  .lg\:mt-2 {
    margin-top: 0.5rem;
  }
  .lg\:mt-3 {
    margin-top: 0.75rem;
  }
  .lg\:mt-4 {
    margin-top: 1rem;
  }
  .lg\:mt-5 {
    margin-top: 1.25rem;
  }
  .lg\:mt-6 {
    margin-top: 1.5rem;
  }
  .lg\:mt-7 {
    margin-top: 1.75rem;
  }
  .lg\:mt-8 {
    margin-top: 2rem;
  }
  .lg\:mt-9 {
    margin-top: 2.25rem;
  }
  .lg\:mt-10 {
    margin-top: 2.5rem;
  }
  .lg\:mt-11 {
    margin-top: 2.75rem;
  }
  .lg\:mt-12 {
    margin-top: 3rem;
  }
  .lg\:mt-14 {
    margin-top: 3.5rem;
  }
  .lg\:mt-16 {
    margin-top: 4rem;
  }
  .lg\:mt-20 {
    margin-top: 5rem;
  }
  .lg\:mt-24 {
    margin-top: 6rem;
  }
  .lg\:mt-28 {
    margin-top: 7rem;
  }
  .lg\:mt-32 {
    margin-top: 8rem;
  }
  .lg\:mt-36 {
    margin-top: 9rem;
  }
  .lg\:mt-40 {
    margin-top: 10rem;
  }
  .lg\:mt-44 {
    margin-top: 11rem;
  }
  .lg\:mt-48 {
    margin-top: 12rem;
  }
  .lg\:mt-52 {
    margin-top: 13rem;
  }
  .lg\:mt-56 {
    margin-top: 14rem;
  }
  .lg\:mt-60 {
    margin-top: 15rem;
  }
  .lg\:mt-64 {
    margin-top: 16rem;
  }
  .lg\:mt-72 {
    margin-top: 18rem;
  }
  .lg\:mt-80 {
    margin-top: 20rem;
  }
  .lg\:mt-96 {
    margin-top: 24rem;
  }
  .lg\:mt-auto {
    margin-top: auto;
  }
  .lg\:mt-px {
    margin-top: 1px;
  }
  .lg\:mt-0\.5 {
    margin-top: 0.125rem;
  }
  .lg\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .lg\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .lg\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .lg\:-mt-0 {
    margin-top: 0;
  }
  .lg\:-mt-1 {
    margin-top: -0.25rem;
  }
  .lg\:-mt-2 {
    margin-top: -0.5rem;
  }
  .lg\:-mt-3 {
    margin-top: -0.75rem;
  }
  .lg\:-mt-4 {
    margin-top: -1rem;
  }
  .lg\:-mt-5 {
    margin-top: -1.25rem;
  }
  .lg\:-mt-6 {
    margin-top: -1.5rem;
  }
  .lg\:-mt-7 {
    margin-top: -1.75rem;
  }
  .lg\:-mt-8 {
    margin-top: -2rem;
  }
  .lg\:-mt-9 {
    margin-top: -2.25rem;
  }
  .lg\:-mt-10 {
    margin-top: -2.5rem;
  }
  .lg\:-mt-11 {
    margin-top: -2.75rem;
  }
  .lg\:-mt-12 {
    margin-top: -3rem;
  }
  .lg\:-mt-14 {
    margin-top: -3.5rem;
  }
  .lg\:-mt-16 {
    margin-top: -4rem;
  }
  .lg\:-mt-20 {
    margin-top: -5rem;
  }
  .lg\:-mt-24 {
    margin-top: -6rem;
  }
  .lg\:-mt-28 {
    margin-top: -7rem;
  }
  .lg\:-mt-32 {
    margin-top: -8rem;
  }
  .lg\:-mt-36 {
    margin-top: -9rem;
  }
  .lg\:-mt-40 {
    margin-top: -10rem;
  }
  .lg\:-mt-44 {
    margin-top: -11rem;
  }
  .lg\:-mt-48 {
    margin-top: -12rem;
  }
  .lg\:-mt-52 {
    margin-top: -13rem;
  }
  .lg\:-mt-56 {
    margin-top: -14rem;
  }
  .lg\:-mt-60 {
    margin-top: -15rem;
  }
  .lg\:-mt-64 {
    margin-top: -16rem;
  }
  .lg\:-mt-72 {
    margin-top: -18rem;
  }
  .lg\:-mt-80 {
    margin-top: -20rem;
  }
  .lg\:-mt-96 {
    margin-top: -24rem;
  }
  .lg\:-mt-px {
    margin-top: -1px;
  }
  .lg\:-mt-0\.5 {
    margin-top: -0.125rem;
  }
  .lg\:-mt-1\.5 {
    margin-top: -0.375rem;
  }
  .lg\:-mt-2\.5 {
    margin-top: -0.625rem;
  }
  .lg\:-mt-3\.5 {
    margin-top: -0.875rem;
  }
  .lg\:mr-0 {
    margin-right: 0;
  }
  .lg\:mr-1 {
    margin-right: 0.25rem;
  }
  .lg\:mr-2 {
    margin-right: 0.5rem;
  }
  .lg\:mr-3 {
    margin-right: 0.75rem;
  }
  .lg\:mr-4 {
    margin-right: 1rem;
  }
  .lg\:mr-5 {
    margin-right: 1.25rem;
  }
  .lg\:mr-6 {
    margin-right: 1.5rem;
  }
  .lg\:mr-7 {
    margin-right: 1.75rem;
  }
  .lg\:mr-8 {
    margin-right: 2rem;
  }
  .lg\:mr-9 {
    margin-right: 2.25rem;
  }
  .lg\:mr-10 {
    margin-right: 2.5rem;
  }
  .lg\:mr-11 {
    margin-right: 2.75rem;
  }
  .lg\:mr-12 {
    margin-right: 3rem;
  }
  .lg\:mr-14 {
    margin-right: 3.5rem;
  }
  .lg\:mr-16 {
    margin-right: 4rem;
  }
  .lg\:mr-20 {
    margin-right: 5rem;
  }
  .lg\:mr-24 {
    margin-right: 6rem;
  }
  .lg\:mr-28 {
    margin-right: 7rem;
  }
  .lg\:mr-32 {
    margin-right: 8rem;
  }
  .lg\:mr-36 {
    margin-right: 9rem;
  }
  .lg\:mr-40 {
    margin-right: 10rem;
  }
  .lg\:mr-44 {
    margin-right: 11rem;
  }
  .lg\:mr-48 {
    margin-right: 12rem;
  }
  .lg\:mr-52 {
    margin-right: 13rem;
  }
  .lg\:mr-56 {
    margin-right: 14rem;
  }
  .lg\:mr-60 {
    margin-right: 15rem;
  }
  .lg\:mr-64 {
    margin-right: 16rem;
  }
  .lg\:mr-72 {
    margin-right: 18rem;
  }
  .lg\:mr-80 {
    margin-right: 20rem;
  }
  .lg\:mr-96 {
    margin-right: 24rem;
  }
  .lg\:mr-auto {
    margin-right: auto;
  }
  .lg\:mr-px {
    margin-right: 1px;
  }
  .lg\:mr-0\.5 {
    margin-right: 0.125rem;
  }
  .lg\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .lg\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .lg\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .lg\:-mr-0 {
    margin-right: 0;
  }
  .lg\:-mr-1 {
    margin-right: -0.25rem;
  }
  .lg\:-mr-2 {
    margin-right: -0.5rem;
  }
  .lg\:-mr-3 {
    margin-right: -0.75rem;
  }
  .lg\:-mr-4 {
    margin-right: -1rem;
  }
  .lg\:-mr-5 {
    margin-right: -1.25rem;
  }
  .lg\:-mr-6 {
    margin-right: -1.5rem;
  }
  .lg\:-mr-7 {
    margin-right: -1.75rem;
  }
  .lg\:-mr-8 {
    margin-right: -2rem;
  }
  .lg\:-mr-9 {
    margin-right: -2.25rem;
  }
  .lg\:-mr-10 {
    margin-right: -2.5rem;
  }
  .lg\:-mr-11 {
    margin-right: -2.75rem;
  }
  .lg\:-mr-12 {
    margin-right: -3rem;
  }
  .lg\:-mr-14 {
    margin-right: -3.5rem;
  }
  .lg\:-mr-16 {
    margin-right: -4rem;
  }
  .lg\:-mr-20 {
    margin-right: -5rem;
  }
  .lg\:-mr-24 {
    margin-right: -6rem;
  }
  .lg\:-mr-28 {
    margin-right: -7rem;
  }
  .lg\:-mr-32 {
    margin-right: -8rem;
  }
  .lg\:-mr-36 {
    margin-right: -9rem;
  }
  .lg\:-mr-40 {
    margin-right: -10rem;
  }
  .lg\:-mr-44 {
    margin-right: -11rem;
  }
  .lg\:-mr-48 {
    margin-right: -12rem;
  }
  .lg\:-mr-52 {
    margin-right: -13rem;
  }
  .lg\:-mr-56 {
    margin-right: -14rem;
  }
  .lg\:-mr-60 {
    margin-right: -15rem;
  }
  .lg\:-mr-64 {
    margin-right: -16rem;
  }
  .lg\:-mr-72 {
    margin-right: -18rem;
  }
  .lg\:-mr-80 {
    margin-right: -20rem;
  }
  .lg\:-mr-96 {
    margin-right: -24rem;
  }
  .lg\:-mr-px {
    margin-right: -1px;
  }
  .lg\:-mr-0\.5 {
    margin-right: -0.125rem;
  }
  .lg\:-mr-1\.5 {
    margin-right: -0.375rem;
  }
  .lg\:-mr-2\.5 {
    margin-right: -0.625rem;
  }
  .lg\:-mr-3\.5 {
    margin-right: -0.875rem;
  }
  .lg\:mb-0 {
    margin-bottom: 0;
  }
  .lg\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .lg\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .lg\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .lg\:mb-4 {
    margin-bottom: 1rem;
  }
  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .lg\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .lg\:mb-8 {
    margin-bottom: 2rem;
  }
  .lg\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .lg\:mb-10 {
    margin-bottom: 2.5rem;
  }
  .lg\:mb-11 {
    margin-bottom: 2.75rem;
  }
  .lg\:mb-12 {
    margin-bottom: 3rem;
  }
  .lg\:mb-14 {
    margin-bottom: 3.5rem;
  }
  .lg\:mb-16 {
    margin-bottom: 4rem;
  }
  .lg\:mb-20 {
    margin-bottom: 5rem;
  }
  .lg\:mb-24 {
    margin-bottom: 6rem;
  }
  .lg\:mb-28 {
    margin-bottom: 7rem;
  }
  .lg\:mb-32 {
    margin-bottom: 8rem;
  }
  .lg\:mb-36 {
    margin-bottom: 9rem;
  }
  .lg\:mb-40 {
    margin-bottom: 10rem;
  }
  .lg\:mb-44 {
    margin-bottom: 11rem;
  }
  .lg\:mb-48 {
    margin-bottom: 12rem;
  }
  .lg\:mb-52 {
    margin-bottom: 13rem;
  }
  .lg\:mb-56 {
    margin-bottom: 14rem;
  }
  .lg\:mb-60 {
    margin-bottom: 15rem;
  }
  .lg\:mb-64 {
    margin-bottom: 16rem;
  }
  .lg\:mb-72 {
    margin-bottom: 18rem;
  }
  .lg\:mb-80 {
    margin-bottom: 20rem;
  }
  .lg\:mb-96 {
    margin-bottom: 24rem;
  }
  .lg\:mb-auto {
    margin-bottom: auto;
  }
  .lg\:mb-px {
    margin-bottom: 1px;
  }
  .lg\:mb-0\.5 {
    margin-bottom: 0.125rem;
  }
  .lg\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .lg\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .lg\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .lg\:-mb-0 {
    margin-bottom: 0;
  }
  .lg\:-mb-1 {
    margin-bottom: -0.25rem;
  }
  .lg\:-mb-2 {
    margin-bottom: -0.5rem;
  }
  .lg\:-mb-3 {
    margin-bottom: -0.75rem;
  }
  .lg\:-mb-4 {
    margin-bottom: -1rem;
  }
  .lg\:-mb-5 {
    margin-bottom: -1.25rem;
  }
  .lg\:-mb-6 {
    margin-bottom: -1.5rem;
  }
  .lg\:-mb-7 {
    margin-bottom: -1.75rem;
  }
  .lg\:-mb-8 {
    margin-bottom: -2rem;
  }
  .lg\:-mb-9 {
    margin-bottom: -2.25rem;
  }
  .lg\:-mb-10 {
    margin-bottom: -2.5rem;
  }
  .lg\:-mb-11 {
    margin-bottom: -2.75rem;
  }
  .lg\:-mb-12 {
    margin-bottom: -3rem;
  }
  .lg\:-mb-14 {
    margin-bottom: -3.5rem;
  }
  .lg\:-mb-16 {
    margin-bottom: -4rem;
  }
  .lg\:-mb-20 {
    margin-bottom: -5rem;
  }
  .lg\:-mb-24 {
    margin-bottom: -6rem;
  }
  .lg\:-mb-28 {
    margin-bottom: -7rem;
  }
  .lg\:-mb-32 {
    margin-bottom: -8rem;
  }
  .lg\:-mb-36 {
    margin-bottom: -9rem;
  }
  .lg\:-mb-40 {
    margin-bottom: -10rem;
  }
  .lg\:-mb-44 {
    margin-bottom: -11rem;
  }
  .lg\:-mb-48 {
    margin-bottom: -12rem;
  }
  .lg\:-mb-52 {
    margin-bottom: -13rem;
  }
  .lg\:-mb-56 {
    margin-bottom: -14rem;
  }
  .lg\:-mb-60 {
    margin-bottom: -15rem;
  }
  .lg\:-mb-64 {
    margin-bottom: -16rem;
  }
  .lg\:-mb-72 {
    margin-bottom: -18rem;
  }
  .lg\:-mb-80 {
    margin-bottom: -20rem;
  }
  .lg\:-mb-96 {
    margin-bottom: -24rem;
  }
  .lg\:-mb-px {
    margin-bottom: -1px;
  }
  .lg\:-mb-0\.5 {
    margin-bottom: -0.125rem;
  }
  .lg\:-mb-1\.5 {
    margin-bottom: -0.375rem;
  }
  .lg\:-mb-2\.5 {
    margin-bottom: -0.625rem;
  }
  .lg\:-mb-3\.5 {
    margin-bottom: -0.875rem;
  }
  .lg\:ml-0 {
    margin-left: 0;
  }
  .lg\:ml-1 {
    margin-left: 0.25rem;
  }
  .lg\:ml-2 {
    margin-left: 0.5rem;
  }
  .lg\:ml-3 {
    margin-left: 0.75rem;
  }
  .lg\:ml-4 {
    margin-left: 1rem;
  }
  .lg\:ml-5 {
    margin-left: 1.25rem;
  }
  .lg\:ml-6 {
    margin-left: 1.5rem;
  }
  .lg\:ml-7 {
    margin-left: 1.75rem;
  }
  .lg\:ml-8 {
    margin-left: 2rem;
  }
  .lg\:ml-9 {
    margin-left: 2.25rem;
  }
  .lg\:ml-10 {
    margin-left: 2.5rem;
  }
  .lg\:ml-11 {
    margin-left: 2.75rem;
  }
  .lg\:ml-12 {
    margin-left: 3rem;
  }
  .lg\:ml-14 {
    margin-left: 3.5rem;
  }
  .lg\:ml-16 {
    margin-left: 4rem;
  }
  .lg\:ml-20 {
    margin-left: 5rem;
  }
  .lg\:ml-24 {
    margin-left: 6rem;
  }
  .lg\:ml-28 {
    margin-left: 7rem;
  }
  .lg\:ml-32 {
    margin-left: 8rem;
  }
  .lg\:ml-36 {
    margin-left: 9rem;
  }
  .lg\:ml-40 {
    margin-left: 10rem;
  }
  .lg\:ml-44 {
    margin-left: 11rem;
  }
  .lg\:ml-48 {
    margin-left: 12rem;
  }
  .lg\:ml-52 {
    margin-left: 13rem;
  }
  .lg\:ml-56 {
    margin-left: 14rem;
  }
  .lg\:ml-60 {
    margin-left: 15rem;
  }
  .lg\:ml-64 {
    margin-left: 16rem;
  }
  .lg\:ml-72 {
    margin-left: 18rem;
  }
  .lg\:ml-80 {
    margin-left: 20rem;
  }
  .lg\:ml-96 {
    margin-left: 24rem;
  }
  .lg\:ml-auto {
    margin-left: auto;
  }
  .lg\:ml-px {
    margin-left: 1px;
  }
  .lg\:ml-0\.5 {
    margin-left: 0.125rem;
  }
  .lg\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .lg\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .lg\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .lg\:-ml-0 {
    margin-left: 0;
  }
  .lg\:-ml-1 {
    margin-left: -0.25rem;
  }
  .lg\:-ml-2 {
    margin-left: -0.5rem;
  }
  .lg\:-ml-3 {
    margin-left: -0.75rem;
  }
  .lg\:-ml-4 {
    margin-left: -1rem;
  }
  .lg\:-ml-5 {
    margin-left: -1.25rem;
  }
  .lg\:-ml-6 {
    margin-left: -1.5rem;
  }
  .lg\:-ml-7 {
    margin-left: -1.75rem;
  }
  .lg\:-ml-8 {
    margin-left: -2rem;
  }
  .lg\:-ml-9 {
    margin-left: -2.25rem;
  }
  .lg\:-ml-10 {
    margin-left: -2.5rem;
  }
  .lg\:-ml-11 {
    margin-left: -2.75rem;
  }
  .lg\:-ml-12 {
    margin-left: -3rem;
  }
  .lg\:-ml-14 {
    margin-left: -3.5rem;
  }
  .lg\:-ml-16 {
    margin-left: -4rem;
  }
  .lg\:-ml-20 {
    margin-left: -5rem;
  }
  .lg\:-ml-24 {
    margin-left: -6rem;
  }
  .lg\:-ml-28 {
    margin-left: -7rem;
  }
  .lg\:-ml-32 {
    margin-left: -8rem;
  }
  .lg\:-ml-36 {
    margin-left: -9rem;
  }
  .lg\:-ml-40 {
    margin-left: -10rem;
  }
  .lg\:-ml-44 {
    margin-left: -11rem;
  }
  .lg\:-ml-48 {
    margin-left: -12rem;
  }
  .lg\:-ml-52 {
    margin-left: -13rem;
  }
  .lg\:-ml-56 {
    margin-left: -14rem;
  }
  .lg\:-ml-60 {
    margin-left: -15rem;
  }
  .lg\:-ml-64 {
    margin-left: -16rem;
  }
  .lg\:-ml-72 {
    margin-left: -18rem;
  }
  .lg\:-ml-80 {
    margin-left: -20rem;
  }
  .lg\:-ml-96 {
    margin-left: -24rem;
  }
  .lg\:-ml-px {
    margin-left: -1px;
  }
  .lg\:-ml-0\.5 {
    margin-left: -0.125rem;
  }
  .lg\:-ml-1\.5 {
    margin-left: -0.375rem;
  }
  .lg\:-ml-2\.5 {
    margin-left: -0.625rem;
  }
  .lg\:-ml-3\.5 {
    margin-left: -0.875rem;
  }
  .lg\:box-border {
    box-sizing: border-box;
  }
  .lg\:box-content {
    box-sizing: content-box;
  }
  .lg\:block {
    display: block;
  }
  .lg\:inline-block {
    display: inline-block;
  }
  .lg\:inline {
    display: inline;
  }
  .lg\:flex {
    display: flex;
  }
  .lg\:inline-flex {
    display: inline-flex;
  }
  .lg\:table {
    display: table;
  }
  .lg\:inline-table {
    display: inline-table;
  }
  .lg\:table-caption {
    display: table-caption;
  }
  .lg\:table-cell {
    display: table-cell;
  }
  .lg\:table-column {
    display: table-column;
  }
  .lg\:table-column-group {
    display: table-column-group;
  }
  .lg\:table-footer-group {
    display: table-footer-group;
  }
  .lg\:table-header-group {
    display: table-header-group;
  }
  .lg\:table-row-group {
    display: table-row-group;
  }
  .lg\:table-row {
    display: table-row;
  }
  .lg\:flow-root {
    display: flow-root;
  }
  .lg\:grid {
    display: grid;
  }
  .lg\:inline-grid {
    display: inline-grid;
  }
  .lg\:contents {
    display: contents;
  }
  .lg\:list-item {
    display: list-item;
  }
  .lg\:hidden {
    display: none;
  }
  .lg\:h-0 {
    height: 0;
  }
  .lg\:h-1 {
    height: 0.25rem;
  }
  .lg\:h-2 {
    height: 0.5rem;
  }
  .lg\:h-3 {
    height: 0.75rem;
  }
  .lg\:h-4 {
    height: 1rem;
  }
  .lg\:h-5 {
    height: 1.25rem;
  }
  .lg\:h-6 {
    height: 1.5rem;
  }
  .lg\:h-7 {
    height: 1.75rem;
  }
  .lg\:h-8 {
    height: 2rem;
  }
  .lg\:h-9 {
    height: 2.25rem;
  }
  .lg\:h-10 {
    height: 2.5rem;
  }
  .lg\:h-11 {
    height: 2.75rem;
  }
  .lg\:h-12 {
    height: 3rem;
  }
  .lg\:h-14 {
    height: 3.5rem;
  }
  .lg\:h-16 {
    height: 4rem;
  }
  .lg\:h-20 {
    height: 5rem;
  }
  .lg\:h-24 {
    height: 6rem;
  }
  .lg\:h-28 {
    height: 7rem;
  }
  .lg\:h-32 {
    height: 8rem;
  }
  .lg\:h-36 {
    height: 9rem;
  }
  .lg\:h-40 {
    height: 10rem;
  }
  .lg\:h-44 {
    height: 11rem;
  }
  .lg\:h-48 {
    height: 12rem;
  }
  .lg\:h-52 {
    height: 13rem;
  }
  .lg\:h-56 {
    height: 14rem;
  }
  .lg\:h-60 {
    height: 15rem;
  }
  .lg\:h-64 {
    height: 16rem;
  }
  .lg\:h-72 {
    height: 18rem;
  }
  .lg\:h-80 {
    height: 20rem;
  }
  .lg\:h-96 {
    height: 24rem;
  }
  .lg\:h-auto {
    height: auto;
  }
  .lg\:h-px {
    height: 1px;
  }
  .lg\:h-0\.5 {
    height: 0.125rem;
  }
  .lg\:h-1\.5 {
    height: 0.375rem;
  }
  .lg\:h-2\.5 {
    height: 0.625rem;
  }
  .lg\:h-3\.5 {
    height: 0.875rem;
  }
  .lg\:h-1\/2 {
    height: 50%;
  }
  .lg\:h-1\/3 {
    height: 33.333333%;
  }
  .lg\:h-2\/3 {
    height: 66.666667%;
  }
  .lg\:h-1\/4 {
    height: 25%;
  }
  .lg\:h-2\/4 {
    height: 50%;
  }
  .lg\:h-3\/4 {
    height: 75%;
  }
  .lg\:h-1\/5 {
    height: 20%;
  }
  .lg\:h-2\/5 {
    height: 40%;
  }
  .lg\:h-3\/5 {
    height: 60%;
  }
  .lg\:h-4\/5 {
    height: 80%;
  }
  .lg\:h-1\/6 {
    height: 16.666667%;
  }
  .lg\:h-2\/6 {
    height: 33.333333%;
  }
  .lg\:h-3\/6 {
    height: 50%;
  }
  .lg\:h-4\/6 {
    height: 66.666667%;
  }
  .lg\:h-5\/6 {
    height: 83.333333%;
  }
  .lg\:h-full {
    height: 100%;
  }
  .lg\:h-screen {
    height: 100vh;
  }
  .lg\:max-h-0 {
    max-height: 0;
  }
  .lg\:max-h-1 {
    max-height: 0.25rem;
  }
  .lg\:max-h-2 {
    max-height: 0.5rem;
  }
  .lg\:max-h-3 {
    max-height: 0.75rem;
  }
  .lg\:max-h-4 {
    max-height: 1rem;
  }
  .lg\:max-h-5 {
    max-height: 1.25rem;
  }
  .lg\:max-h-6 {
    max-height: 1.5rem;
  }
  .lg\:max-h-7 {
    max-height: 1.75rem;
  }
  .lg\:max-h-8 {
    max-height: 2rem;
  }
  .lg\:max-h-9 {
    max-height: 2.25rem;
  }
  .lg\:max-h-10 {
    max-height: 2.5rem;
  }
  .lg\:max-h-11 {
    max-height: 2.75rem;
  }
  .lg\:max-h-12 {
    max-height: 3rem;
  }
  .lg\:max-h-14 {
    max-height: 3.5rem;
  }
  .lg\:max-h-16 {
    max-height: 4rem;
  }
  .lg\:max-h-20 {
    max-height: 5rem;
  }
  .lg\:max-h-24 {
    max-height: 6rem;
  }
  .lg\:max-h-28 {
    max-height: 7rem;
  }
  .lg\:max-h-32 {
    max-height: 8rem;
  }
  .lg\:max-h-36 {
    max-height: 9rem;
  }
  .lg\:max-h-40 {
    max-height: 10rem;
  }
  .lg\:max-h-44 {
    max-height: 11rem;
  }
  .lg\:max-h-48 {
    max-height: 12rem;
  }
  .lg\:max-h-52 {
    max-height: 13rem;
  }
  .lg\:max-h-56 {
    max-height: 14rem;
  }
  .lg\:max-h-60 {
    max-height: 15rem;
  }
  .lg\:max-h-64 {
    max-height: 16rem;
  }
  .lg\:max-h-72 {
    max-height: 18rem;
  }
  .lg\:max-h-80 {
    max-height: 20rem;
  }
  .lg\:max-h-96 {
    max-height: 24rem;
  }
  .lg\:max-h-px {
    max-height: 1px;
  }
  .lg\:max-h-0\.5 {
    max-height: 0.125rem;
  }
  .lg\:max-h-1\.5 {
    max-height: 0.375rem;
  }
  .lg\:max-h-2\.5 {
    max-height: 0.625rem;
  }
  .lg\:max-h-3\.5 {
    max-height: 0.875rem;
  }
  .lg\:max-h-full {
    max-height: 100%;
  }
  .lg\:max-h-screen {
    max-height: 100vh;
  }
  .lg\:min-h-0 {
    min-height: 0;
  }
  .lg\:min-h-full {
    min-height: 100%;
  }
  .lg\:min-h-screen {
    min-height: 100vh;
  }
  .lg\:w-0 {
    width: 0;
  }
  .lg\:w-1 {
    width: 0.25rem;
  }
  .lg\:w-2 {
    width: 0.5rem;
  }
  .lg\:w-3 {
    width: 0.75rem;
  }
  .lg\:w-4 {
    width: 1rem;
  }
  .lg\:w-5 {
    width: 1.25rem;
  }
  .lg\:w-6 {
    width: 1.5rem;
  }
  .lg\:w-7 {
    width: 1.75rem;
  }
  .lg\:w-8 {
    width: 2rem;
  }
  .lg\:w-9 {
    width: 2.25rem;
  }
  .lg\:w-10 {
    width: 2.5rem;
  }
  .lg\:w-11 {
    width: 2.75rem;
  }
  .lg\:w-12 {
    width: 3rem;
  }
  .lg\:w-14 {
    width: 3.5rem;
  }
  .lg\:w-16 {
    width: 4rem;
  }
  .lg\:w-20 {
    width: 5rem;
  }
  .lg\:w-24 {
    width: 6rem;
  }
  .lg\:w-28 {
    width: 7rem;
  }
  .lg\:w-32 {
    width: 8rem;
  }
  .lg\:w-36 {
    width: 9rem;
  }
  .lg\:w-40 {
    width: 10rem;
  }
  .lg\:w-44 {
    width: 11rem;
  }
  .lg\:w-48 {
    width: 12rem;
  }
  .lg\:w-52 {
    width: 13rem;
  }
  .lg\:w-56 {
    width: 14rem;
  }
  .lg\:w-60 {
    width: 15rem;
  }
  .lg\:w-64 {
    width: 16rem;
  }
  .lg\:w-72 {
    width: 18rem;
  }
  .lg\:w-80 {
    width: 20rem;
  }
  .lg\:w-96 {
    width: 24rem;
  }
  .lg\:w-auto {
    width: auto;
  }
  .lg\:w-px {
    width: 1px;
  }
  .lg\:w-0\.5 {
    width: 0.125rem;
  }
  .lg\:w-1\.5 {
    width: 0.375rem;
  }
  .lg\:w-2\.5 {
    width: 0.625rem;
  }
  .lg\:w-3\.5 {
    width: 0.875rem;
  }
  .lg\:w-1\/2 {
    width: 50%;
  }
  .lg\:w-1\/3 {
    width: 33.333333%;
  }
  .lg\:w-2\/3 {
    width: 66.666667%;
  }
  .lg\:w-1\/4 {
    width: 25%;
  }
  .lg\:w-2\/4 {
    width: 50%;
  }
  .lg\:w-3\/4 {
    width: 75%;
  }
  .lg\:w-1\/5 {
    width: 20%;
  }
  .lg\:w-2\/5 {
    width: 40%;
  }
  .lg\:w-3\/5 {
    width: 60%;
  }
  .lg\:w-4\/5 {
    width: 80%;
  }
  .lg\:w-1\/6 {
    width: 16.666667%;
  }
  .lg\:w-2\/6 {
    width: 33.333333%;
  }
  .lg\:w-3\/6 {
    width: 50%;
  }
  .lg\:w-4\/6 {
    width: 66.666667%;
  }
  .lg\:w-5\/6 {
    width: 83.333333%;
  }
  .lg\:w-1\/12 {
    width: 8.333333%;
  }
  .lg\:w-2\/12 {
    width: 16.666667%;
  }
  .lg\:w-3\/12 {
    width: 25%;
  }
  .lg\:w-4\/12 {
    width: 33.333333%;
  }
  .lg\:w-5\/12 {
    width: 41.666667%;
  }
  .lg\:w-6\/12 {
    width: 50%;
  }
  .lg\:w-7\/12 {
    width: 58.333333%;
  }
  .lg\:w-8\/12 {
    width: 66.666667%;
  }
  .lg\:w-9\/12 {
    width: 75%;
  }
  .lg\:w-10\/12 {
    width: 83.333333%;
  }
  .lg\:w-11\/12 {
    width: 91.666667%;
  }
  .lg\:w-full {
    width: 100%;
  }
  .lg\:w-screen {
    width: 100vw;
  }
  .lg\:w-min {
    width: min-content;
  }
  .lg\:w-max {
    width: max-content;
  }
  .lg\:min-w-0 {
    min-width: 0;
  }
  .lg\:min-w-full {
    min-width: 100%;
  }
  .lg\:min-w-min {
    min-width: min-content;
  }
  .lg\:min-w-max {
    min-width: max-content;
  }
  .lg\:max-w-0 {
    max-width: 0;
  }
  .lg\:max-w-none {
    max-width: none;
  }
  .lg\:max-w-xs {
    max-width: 20rem;
  }
  .lg\:max-w-sm {
    max-width: 24rem;
  }
  .lg\:max-w-md {
    max-width: 28rem;
  }
  .lg\:max-w-lg {
    max-width: 32rem;
  }
  .lg\:max-w-xl {
    max-width: 36rem;
  }
  .lg\:max-w-2xl {
    max-width: 42rem;
  }
  .lg\:max-w-3xl {
    max-width: 48rem;
  }
  .lg\:max-w-4xl {
    max-width: 56rem;
  }
  .lg\:max-w-5xl {
    max-width: 64rem;
  }
  .lg\:max-w-6xl {
    max-width: 72rem;
  }
  .lg\:max-w-7xl {
    max-width: 80rem;
  }
  .lg\:max-w-full {
    max-width: 100%;
  }
  .lg\:max-w-min {
    max-width: min-content;
  }
  .lg\:max-w-max {
    max-width: max-content;
  }
  .lg\:max-w-prose {
    max-width: 65ch;
  }
  .lg\:max-w-screen-sm {
    max-width: 640px;
  }
  .lg\:max-w-screen-md {
    max-width: 768px;
  }
  .lg\:max-w-screen-lg {
    max-width: 1024px;
  }
  .lg\:max-w-screen-xl {
    max-width: 1280px;
  }
  .lg\:max-w-screen-2xl {
    max-width: 1536px;
  }
  .lg\:flex-1 {
    flex: 1 1 0%;
  }
  .lg\:flex-auto {
    flex: 1 1 auto;
  }
  .lg\:flex-initial {
    flex: 0 1 auto;
  }
  .lg\:flex-none {
    flex: none;
  }
  .lg\:flex-shrink-0 {
    flex-shrink: 0;
  }
  .lg\:flex-shrink {
    flex-shrink: 1;
  }
  .lg\:flex-grow-0 {
    flex-grow: 0;
  }
  .lg\:flex-grow {
    flex-grow: 1;
  }
  .lg\:table-auto {
    table-layout: auto;
  }
  .lg\:table-fixed {
    table-layout: fixed;
  }
  .lg\:border-collapse {
    border-collapse: collapse;
  }
  .lg\:border-separate {
    border-collapse: separate;
  }
  .lg\:origin-center {
    transform-origin: center;
  }
  .lg\:origin-top {
    transform-origin: top;
  }
  .lg\:origin-top-right {
    transform-origin: top right;
  }
  .lg\:origin-right {
    transform-origin: right;
  }
  .lg\:origin-bottom-right {
    transform-origin: bottom right;
  }
  .lg\:origin-bottom {
    transform-origin: bottom;
  }
  .lg\:origin-bottom-left {
    transform-origin: bottom left;
  }
  .lg\:origin-left {
    transform-origin: left;
  }
  .lg\:origin-top-left {
    transform-origin: top left;
  }
  .lg\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  .lg\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .lg\:transform-none {
    transform: none;
  }
  .lg\:translate-x-0 {
    --tw-translate-x: 0px;
  }
  .lg\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }
  .lg\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }
  .lg\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }
  .lg\:translate-x-4 {
    --tw-translate-x: 1rem;
  }
  .lg\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }
  .lg\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }
  .lg\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }
  .lg\:translate-x-8 {
    --tw-translate-x: 2rem;
  }
  .lg\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }
  .lg\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }
  .lg\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }
  .lg\:translate-x-12 {
    --tw-translate-x: 3rem;
  }
  .lg\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }
  .lg\:translate-x-16 {
    --tw-translate-x: 4rem;
  }
  .lg\:translate-x-20 {
    --tw-translate-x: 5rem;
  }
  .lg\:translate-x-24 {
    --tw-translate-x: 6rem;
  }
  .lg\:translate-x-28 {
    --tw-translate-x: 7rem;
  }
  .lg\:translate-x-32 {
    --tw-translate-x: 8rem;
  }
  .lg\:translate-x-36 {
    --tw-translate-x: 9rem;
  }
  .lg\:translate-x-40 {
    --tw-translate-x: 10rem;
  }
  .lg\:translate-x-44 {
    --tw-translate-x: 11rem;
  }
  .lg\:translate-x-48 {
    --tw-translate-x: 12rem;
  }
  .lg\:translate-x-52 {
    --tw-translate-x: 13rem;
  }
  .lg\:translate-x-56 {
    --tw-translate-x: 14rem;
  }
  .lg\:translate-x-60 {
    --tw-translate-x: 15rem;
  }
  .lg\:translate-x-64 {
    --tw-translate-x: 16rem;
  }
  .lg\:translate-x-72 {
    --tw-translate-x: 18rem;
  }
  .lg\:translate-x-80 {
    --tw-translate-x: 20rem;
  }
  .lg\:translate-x-96 {
    --tw-translate-x: 24rem;
  }
  .lg\:translate-x-px {
    --tw-translate-x: 1px;
  }
  .lg\:translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }
  .lg\:translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }
  .lg\:translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }
  .lg\:translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }
  .lg\:-translate-x-0 {
    --tw-translate-x: 0px;
  }
  .lg\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }
  .lg\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }
  .lg\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }
  .lg\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }
  .lg\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }
  .lg\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }
  .lg\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }
  .lg\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }
  .lg\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }
  .lg\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }
  .lg\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }
  .lg\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }
  .lg\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }
  .lg\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }
  .lg\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }
  .lg\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }
  .lg\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }
  .lg\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }
  .lg\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }
  .lg\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }
  .lg\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }
  .lg\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }
  .lg\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }
  .lg\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }
  .lg\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }
  .lg\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }
  .lg\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }
  .lg\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }
  .lg\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }
  .lg\:-translate-x-px {
    --tw-translate-x: -1px;
  }
  .lg\:-translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }
  .lg\:-translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }
  .lg\:-translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }
  .lg\:-translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }
  .lg\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }
  .lg\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }
  .lg\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }
  .lg\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }
  .lg\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }
  .lg\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }
  .lg\:translate-x-full {
    --tw-translate-x: 100%;
  }
  .lg\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }
  .lg\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }
  .lg\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }
  .lg\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }
  .lg\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }
  .lg\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }
  .lg\:-translate-x-full {
    --tw-translate-x: -100%;
  }
  .lg\:translate-y-0 {
    --tw-translate-y: 0px;
  }
  .lg\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }
  .lg\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }
  .lg\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }
  .lg\:translate-y-4 {
    --tw-translate-y: 1rem;
  }
  .lg\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }
  .lg\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }
  .lg\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }
  .lg\:translate-y-8 {
    --tw-translate-y: 2rem;
  }
  .lg\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }
  .lg\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }
  .lg\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }
  .lg\:translate-y-12 {
    --tw-translate-y: 3rem;
  }
  .lg\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }
  .lg\:translate-y-16 {
    --tw-translate-y: 4rem;
  }
  .lg\:translate-y-20 {
    --tw-translate-y: 5rem;
  }
  .lg\:translate-y-24 {
    --tw-translate-y: 6rem;
  }
  .lg\:translate-y-28 {
    --tw-translate-y: 7rem;
  }
  .lg\:translate-y-32 {
    --tw-translate-y: 8rem;
  }
  .lg\:translate-y-36 {
    --tw-translate-y: 9rem;
  }
  .lg\:translate-y-40 {
    --tw-translate-y: 10rem;
  }
  .lg\:translate-y-44 {
    --tw-translate-y: 11rem;
  }
  .lg\:translate-y-48 {
    --tw-translate-y: 12rem;
  }
  .lg\:translate-y-52 {
    --tw-translate-y: 13rem;
  }
  .lg\:translate-y-56 {
    --tw-translate-y: 14rem;
  }
  .lg\:translate-y-60 {
    --tw-translate-y: 15rem;
  }
  .lg\:translate-y-64 {
    --tw-translate-y: 16rem;
  }
  .lg\:translate-y-72 {
    --tw-translate-y: 18rem;
  }
  .lg\:translate-y-80 {
    --tw-translate-y: 20rem;
  }
  .lg\:translate-y-96 {
    --tw-translate-y: 24rem;
  }
  .lg\:translate-y-px {
    --tw-translate-y: 1px;
  }
  .lg\:translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }
  .lg\:translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }
  .lg\:translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }
  .lg\:translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }
  .lg\:-translate-y-0 {
    --tw-translate-y: 0px;
  }
  .lg\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }
  .lg\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }
  .lg\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }
  .lg\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }
  .lg\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }
  .lg\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }
  .lg\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }
  .lg\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }
  .lg\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }
  .lg\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }
  .lg\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }
  .lg\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }
  .lg\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }
  .lg\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }
  .lg\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }
  .lg\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }
  .lg\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }
  .lg\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }
  .lg\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }
  .lg\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }
  .lg\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }
  .lg\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }
  .lg\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }
  .lg\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }
  .lg\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }
  .lg\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }
  .lg\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }
  .lg\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }
  .lg\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }
  .lg\:-translate-y-px {
    --tw-translate-y: -1px;
  }
  .lg\:-translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }
  .lg\:-translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }
  .lg\:-translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }
  .lg\:-translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }
  .lg\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }
  .lg\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }
  .lg\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }
  .lg\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }
  .lg\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }
  .lg\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }
  .lg\:translate-y-full {
    --tw-translate-y: 100%;
  }
  .lg\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }
  .lg\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }
  .lg\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }
  .lg\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }
  .lg\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }
  .lg\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }
  .lg\:-translate-y-full {
    --tw-translate-y: -100%;
  }
  .lg\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .lg\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }
  .lg\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }
  .lg\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }
  .lg\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }
  .lg\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }
  .lg\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }
  .lg\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }
  .lg\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }
  .lg\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }
  .lg\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }
  .lg\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }
  .lg\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }
  .lg\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }
  .lg\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }
  .lg\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }
  .lg\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }
  .lg\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }
  .lg\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }
  .lg\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }
  .lg\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }
  .lg\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }
  .lg\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }
  .lg\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }
  .lg\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }
  .lg\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }
  .lg\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }
  .lg\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }
  .lg\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }
  .lg\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }
  .lg\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }
  .lg\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }
  .lg\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }
  .lg\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }
  .lg\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }
  .lg\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .lg\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }
  .lg\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }
  .lg\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }
  .lg\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }
  .lg\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }
  .lg\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }
  .lg\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }
  .lg\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }
  .lg\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }
  .lg\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }
  .lg\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }
  .lg\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }
  .lg\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }
  .lg\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }
  .lg\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }
  .lg\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }
  .lg\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }
  .lg\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }
  .lg\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }
  .lg\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }
  .lg\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }
  .lg\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }
  .lg\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }
  .lg\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }
  .lg\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }
  .lg\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }
  .lg\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }
  .lg\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }
  .lg\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }
  .lg\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }
  .lg\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }
  .lg\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }
  .lg\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }
  .lg\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }
  .lg\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }
  .lg\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }
  .lg\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }
  .lg\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }
  .lg\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }
  .lg\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }
  .lg\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }
  .lg\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }
  .lg\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }
  .lg\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }
  .lg\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }
  .lg\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }
  .lg\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }
  .lg\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }
  .lg\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .lg\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }
  .lg\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }
  .lg\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }
  .lg\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }
  .lg\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }
  .lg\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }
  .lg\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }
  .lg\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }
  .lg\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }
  .lg\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }
  .lg\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }
  .lg\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }
  .lg\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }
  .lg\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }
  .lg\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }
  .lg\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }
  .lg\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }
  .lg\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }
  .lg\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }
  .lg\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }
  .lg\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }
  .lg\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }
  .lg\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }
  .lg\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }
  .lg\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }
  .lg\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }
  .lg\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }
  .lg\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }
  .lg\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }
  .lg\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }
  .lg\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }
  .lg\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }
  .lg\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }
  .lg\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }
  .lg\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .lg\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }
  .lg\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }
  .lg\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }
  .lg\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }
  .lg\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }
  .lg\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }
  .lg\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }
  .lg\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }
  .lg\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }
  .lg\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }
  .lg\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }
  .lg\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }
  .lg\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }
  .lg\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }
  .lg\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }
  .lg\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }
  .lg\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }
  .lg\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }
  .lg\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }
  .lg\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }
  .lg\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }
  .lg\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }
  .lg\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }
  .lg\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }
  .lg\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }
  .lg\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }
  .lg\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }
  .lg\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }
  .lg\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }
  .lg\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }
  .lg\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }
  .lg\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }
  .lg\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }
  .lg\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }
  .lg\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }
  .lg\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }
  .lg\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }
  .lg\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }
  .lg\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }
  .lg\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }
  .lg\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }
  .lg\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }
  .lg\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }
  .lg\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }
  .lg\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }
  .lg\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }
  .lg\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }
  .lg\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }
  .lg\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .lg\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }
  .lg\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }
  .lg\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }
  .lg\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }
  .lg\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }
  .lg\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }
  .lg\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }
  .lg\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }
  .lg\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }
  .lg\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }
  .lg\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }
  .lg\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }
  .lg\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }
  .lg\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }
  .lg\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }
  .lg\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }
  .lg\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }
  .lg\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }
  .lg\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }
  .lg\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }
  .lg\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }
  .lg\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }
  .lg\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }
  .lg\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }
  .lg\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }
  .lg\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }
  .lg\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }
  .lg\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }
  .lg\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }
  .lg\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }
  .lg\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }
  .lg\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }
  .lg\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }
  .lg\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }
  .lg\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .lg\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }
  .lg\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }
  .lg\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }
  .lg\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }
  .lg\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }
  .lg\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }
  .lg\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }
  .lg\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }
  .lg\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }
  .lg\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }
  .lg\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }
  .lg\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }
  .lg\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }
  .lg\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }
  .lg\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }
  .lg\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }
  .lg\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }
  .lg\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }
  .lg\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }
  .lg\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }
  .lg\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }
  .lg\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }
  .lg\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }
  .lg\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }
  .lg\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }
  .lg\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }
  .lg\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }
  .lg\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }
  .lg\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }
  .lg\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }
  .lg\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }
  .lg\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }
  .lg\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }
  .lg\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }
  .lg\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }
  .lg\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }
  .lg\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }
  .lg\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }
  .lg\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }
  .lg\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }
  .lg\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }
  .lg\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }
  .lg\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }
  .lg\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }
  .lg\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }
  .lg\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }
  .lg\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }
  .lg\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }
  .lg\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .lg\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }
  .lg\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }
  .lg\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }
  .lg\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }
  .lg\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }
  .lg\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }
  .lg\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }
  .lg\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }
  .lg\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }
  .lg\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }
  .lg\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }
  .lg\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }
  .lg\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }
  .lg\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }
  .lg\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }
  .lg\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }
  .lg\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }
  .lg\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }
  .lg\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }
  .lg\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }
  .lg\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }
  .lg\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }
  .lg\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }
  .lg\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }
  .lg\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }
  .lg\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }
  .lg\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }
  .lg\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }
  .lg\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }
  .lg\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }
  .lg\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }
  .lg\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }
  .lg\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }
  .lg\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }
  .lg\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .lg\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }
  .lg\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }
  .lg\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }
  .lg\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }
  .lg\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }
  .lg\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }
  .lg\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }
  .lg\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }
  .lg\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }
  .lg\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }
  .lg\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }
  .lg\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }
  .lg\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }
  .lg\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }
  .lg\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }
  .lg\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }
  .lg\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }
  .lg\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }
  .lg\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }
  .lg\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }
  .lg\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }
  .lg\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }
  .lg\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }
  .lg\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }
  .lg\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }
  .lg\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }
  .lg\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }
  .lg\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }
  .lg\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }
  .lg\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }
  .lg\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }
  .lg\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }
  .lg\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }
  .lg\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }
  .lg\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }
  .lg\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }
  .lg\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }
  .lg\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }
  .lg\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }
  .lg\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }
  .lg\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }
  .lg\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }
  .lg\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }
  .lg\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }
  .lg\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }
  .lg\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }
  .lg\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }
  .lg\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }
  .lg\:rotate-0 {
    --tw-rotate: 0deg;
  }
  .lg\:rotate-1 {
    --tw-rotate: 1deg;
  }
  .lg\:rotate-2 {
    --tw-rotate: 2deg;
  }
  .lg\:rotate-3 {
    --tw-rotate: 3deg;
  }
  .lg\:rotate-6 {
    --tw-rotate: 6deg;
  }
  .lg\:rotate-12 {
    --tw-rotate: 12deg;
  }
  .lg\:rotate-45 {
    --tw-rotate: 45deg;
  }
  .lg\:rotate-90 {
    --tw-rotate: 90deg;
  }
  .lg\:rotate-180 {
    --tw-rotate: 180deg;
  }
  .lg\:-rotate-180 {
    --tw-rotate: -180deg;
  }
  .lg\:-rotate-90 {
    --tw-rotate: -90deg;
  }
  .lg\:-rotate-45 {
    --tw-rotate: -45deg;
  }
  .lg\:-rotate-12 {
    --tw-rotate: -12deg;
  }
  .lg\:-rotate-6 {
    --tw-rotate: -6deg;
  }
  .lg\:-rotate-3 {
    --tw-rotate: -3deg;
  }
  .lg\:-rotate-2 {
    --tw-rotate: -2deg;
  }
  .lg\:-rotate-1 {
    --tw-rotate: -1deg;
  }
  .lg\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }
  .lg\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }
  .lg\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }
  .lg\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }
  .lg\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }
  .lg\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }
  .lg\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }
  .lg\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }
  .lg\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }
  .lg\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }
  .lg\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }
  .lg\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }
  .lg\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }
  .lg\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }
  .lg\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }
  .lg\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }
  .lg\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }
  .lg\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }
  .lg\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }
  .lg\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }
  .lg\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }
  .lg\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }
  .lg\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }
  .lg\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }
  .lg\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }
  .lg\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }
  .lg\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }
  .lg\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }
  .lg\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }
  .lg\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }
  .lg\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }
  .lg\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }
  .lg\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }
  .lg\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }
  .lg\:skew-x-0 {
    --tw-skew-x: 0deg;
  }
  .lg\:skew-x-1 {
    --tw-skew-x: 1deg;
  }
  .lg\:skew-x-2 {
    --tw-skew-x: 2deg;
  }
  .lg\:skew-x-3 {
    --tw-skew-x: 3deg;
  }
  .lg\:skew-x-6 {
    --tw-skew-x: 6deg;
  }
  .lg\:skew-x-12 {
    --tw-skew-x: 12deg;
  }
  .lg\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }
  .lg\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }
  .lg\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }
  .lg\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }
  .lg\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }
  .lg\:skew-y-0 {
    --tw-skew-y: 0deg;
  }
  .lg\:skew-y-1 {
    --tw-skew-y: 1deg;
  }
  .lg\:skew-y-2 {
    --tw-skew-y: 2deg;
  }
  .lg\:skew-y-3 {
    --tw-skew-y: 3deg;
  }
  .lg\:skew-y-6 {
    --tw-skew-y: 6deg;
  }
  .lg\:skew-y-12 {
    --tw-skew-y: 12deg;
  }
  .lg\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }
  .lg\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }
  .lg\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }
  .lg\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }
  .lg\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }
  .lg\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }
  .lg\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }
  .lg\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }
  .lg\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }
  .lg\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }
  .lg\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }
  .lg\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }
  .lg\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }
  .lg\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }
  .lg\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }
  .lg\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }
  .lg\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }
  .lg\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }
  .lg\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }
  .lg\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }
  .lg\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }
  .lg\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }
  .lg\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }
  .lg\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }
  .lg\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }
  .lg\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }
  .lg\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }
  .lg\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }
  .lg\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }
  .lg\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }
  .lg\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }
  .lg\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }
  .lg\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }
  .lg\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }
  .lg\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }
  .lg\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }
  .lg\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }
  .lg\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }
  .lg\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }
  .lg\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }
  .lg\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }
  .lg\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }
  .lg\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }
  .lg\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }
  .lg\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }
  .lg\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }
  .lg\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }
  .lg\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }
  .lg\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }
  .lg\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .lg\:scale-50 {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .lg\:scale-75 {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .lg\:scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .lg\:scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .lg\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .lg\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .lg\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .lg\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .lg\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .lg\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .lg\:hover\:scale-50:hover {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .lg\:hover\:scale-75:hover {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .lg\:hover\:scale-90:hover {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .lg\:hover\:scale-95:hover {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .lg\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .lg\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .lg\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .lg\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .lg\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .lg\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .lg\:focus\:scale-50:focus {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .lg\:focus\:scale-75:focus {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .lg\:focus\:scale-90:focus {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .lg\:focus\:scale-95:focus {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .lg\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .lg\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .lg\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .lg\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .lg\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .lg\:scale-x-0 {
    --tw-scale-x: 0;
  }
  .lg\:scale-x-50 {
    --tw-scale-x: 0.5;
  }
  .lg\:scale-x-75 {
    --tw-scale-x: 0.75;
  }
  .lg\:scale-x-90 {
    --tw-scale-x: 0.9;
  }
  .lg\:scale-x-95 {
    --tw-scale-x: 0.95;
  }
  .lg\:scale-x-100 {
    --tw-scale-x: 1;
  }
  .lg\:scale-x-105 {
    --tw-scale-x: 1.05;
  }
  .lg\:scale-x-110 {
    --tw-scale-x: 1.1;
  }
  .lg\:scale-x-125 {
    --tw-scale-x: 1.25;
  }
  .lg\:scale-x-150 {
    --tw-scale-x: 1.5;
  }
  .lg\:scale-y-0 {
    --tw-scale-y: 0;
  }
  .lg\:scale-y-50 {
    --tw-scale-y: 0.5;
  }
  .lg\:scale-y-75 {
    --tw-scale-y: 0.75;
  }
  .lg\:scale-y-90 {
    --tw-scale-y: 0.9;
  }
  .lg\:scale-y-95 {
    --tw-scale-y: 0.95;
  }
  .lg\:scale-y-100 {
    --tw-scale-y: 1;
  }
  .lg\:scale-y-105 {
    --tw-scale-y: 1.05;
  }
  .lg\:scale-y-110 {
    --tw-scale-y: 1.1;
  }
  .lg\:scale-y-125 {
    --tw-scale-y: 1.25;
  }
  .lg\:scale-y-150 {
    --tw-scale-y: 1.5;
  }
  .lg\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }
  .lg\:hover\:scale-x-50:hover {
    --tw-scale-x: 0.5;
  }
  .lg\:hover\:scale-x-75:hover {
    --tw-scale-x: 0.75;
  }
  .lg\:hover\:scale-x-90:hover {
    --tw-scale-x: 0.9;
  }
  .lg\:hover\:scale-x-95:hover {
    --tw-scale-x: 0.95;
  }
  .lg\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }
  .lg\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }
  .lg\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }
  .lg\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }
  .lg\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }
  .lg\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }
  .lg\:hover\:scale-y-50:hover {
    --tw-scale-y: 0.5;
  }
  .lg\:hover\:scale-y-75:hover {
    --tw-scale-y: 0.75;
  }
  .lg\:hover\:scale-y-90:hover {
    --tw-scale-y: 0.9;
  }
  .lg\:hover\:scale-y-95:hover {
    --tw-scale-y: 0.95;
  }
  .lg\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }
  .lg\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }
  .lg\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }
  .lg\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }
  .lg\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }
  .lg\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }
  .lg\:focus\:scale-x-50:focus {
    --tw-scale-x: 0.5;
  }
  .lg\:focus\:scale-x-75:focus {
    --tw-scale-x: 0.75;
  }
  .lg\:focus\:scale-x-90:focus {
    --tw-scale-x: 0.9;
  }
  .lg\:focus\:scale-x-95:focus {
    --tw-scale-x: 0.95;
  }
  .lg\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }
  .lg\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }
  .lg\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }
  .lg\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }
  .lg\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }
  .lg\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }
  .lg\:focus\:scale-y-50:focus {
    --tw-scale-y: 0.5;
  }
  .lg\:focus\:scale-y-75:focus {
    --tw-scale-y: 0.75;
  }
  .lg\:focus\:scale-y-90:focus {
    --tw-scale-y: 0.9;
  }
  .lg\:focus\:scale-y-95:focus {
    --tw-scale-y: 0.95;
  }
  .lg\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }
  .lg\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }
  .lg\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }
  .lg\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }
  .lg\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }
  .lg\:animate-none {
    animation: none;
  }
  .lg\:animate-spin {
    animation: spin 1s linear infinite;
  }
  .lg\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .lg\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .lg\:animate-bounce {
    animation: bounce 1s infinite;
  }
  .lg\:cursor-auto {
    cursor: auto;
  }
  .lg\:cursor-default {
    cursor: default;
  }
  .lg\:cursor-pointer {
    cursor: pointer;
  }
  .lg\:cursor-wait {
    cursor: wait;
  }
  .lg\:cursor-text {
    cursor: text;
  }
  .lg\:cursor-move {
    cursor: move;
  }
  .lg\:cursor-help {
    cursor: help;
  }
  .lg\:cursor-not-allowed {
    cursor: not-allowed;
  }
  .lg\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }
  .lg\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }
  .lg\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }
  .lg\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }
  .lg\:resize-none {
    resize: none;
  }
  .lg\:resize-y {
    resize: vertical;
  }
  .lg\:resize-x {
    resize: horizontal;
  }
  .lg\:resize {
    resize: both;
  }
  .lg\:list-inside {
    list-style-position: inside;
  }
  .lg\:list-outside {
    list-style-position: outside;
  }
  .lg\:list-none {
    list-style-type: none;
  }
  .lg\:list-disc {
    list-style-type: disc;
  }
  .lg\:list-decimal {
    list-style-type: decimal;
  }
  .lg\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }
  .lg\:auto-cols-auto {
    grid-auto-columns: auto;
  }
  .lg\:auto-cols-min {
    grid-auto-columns: min-content;
  }
  .lg\:auto-cols-max {
    grid-auto-columns: max-content;
  }
  .lg\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }
  .lg\:grid-flow-row {
    grid-auto-flow: row;
  }
  .lg\:grid-flow-col {
    grid-auto-flow: column;
  }
  .lg\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }
  .lg\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }
  .lg\:auto-rows-auto {
    grid-auto-rows: auto;
  }
  .lg\:auto-rows-min {
    grid-auto-rows: min-content;
  }
  .lg\:auto-rows-max {
    grid-auto-rows: max-content;
  }
  .lg\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }
  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .lg\:grid-cols-none {
    grid-template-columns: none;
  }
  .lg\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  .lg\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .lg\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .lg\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .lg\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .lg\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .lg\:grid-rows-none {
    grid-template-rows: none;
  }
  .lg\:flex-row {
    flex-direction: row;
  }
  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .lg\:flex-col {
    flex-direction: column;
  }
  .lg\:flex-col-reverse {
    flex-direction: column-reverse;
  }
  .lg\:flex-wrap {
    flex-wrap: wrap;
  }
  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }
  .lg\:place-content-center {
    place-content: center;
  }
  .lg\:place-content-start {
    place-content: start;
  }
  .lg\:place-content-end {
    place-content: end;
  }
  .lg\:place-content-between {
    place-content: space-between;
  }
  .lg\:place-content-around {
    place-content: space-around;
  }
  .lg\:place-content-evenly {
    place-content: space-evenly;
  }
  .lg\:place-content-stretch {
    place-content: stretch;
  }
  .lg\:place-items-start {
    place-items: start;
  }
  .lg\:place-items-end {
    place-items: end;
  }
  .lg\:place-items-center {
    place-items: center;
  }
  .lg\:place-items-stretch {
    place-items: stretch;
  }
  .lg\:content-center {
    align-content: center;
  }
  .lg\:content-start {
    align-content: flex-start;
  }
  .lg\:content-end {
    align-content: flex-end;
  }
  .lg\:content-between {
    align-content: space-between;
  }
  .lg\:content-around {
    align-content: space-around;
  }
  .lg\:content-evenly {
    align-content: space-evenly;
  }
  .lg\:items-start {
    align-items: flex-start;
  }
  .lg\:items-end {
    align-items: flex-end;
  }
  .lg\:items-center {
    align-items: center;
  }
  .lg\:items-baseline {
    align-items: baseline;
  }
  .lg\:items-stretch {
    align-items: stretch;
  }
  .lg\:justify-start {
    justify-content: flex-start;
  }
  .lg\:justify-end {
    justify-content: flex-end;
  }
  .lg\:justify-center {
    justify-content: center;
  }
  .lg\:justify-between {
    justify-content: space-between;
  }
  .lg\:justify-around {
    justify-content: space-around;
  }
  .lg\:justify-evenly {
    justify-content: space-evenly;
  }
  .lg\:justify-items-start {
    justify-items: start;
  }
  .lg\:justify-items-end {
    justify-items: end;
  }
  .lg\:justify-items-center {
    justify-items: center;
  }
  .lg\:justify-items-stretch {
    justify-items: stretch;
  }
  .lg\:gap-0 {
    gap: 0;
  }
  .lg\:gap-1 {
    gap: 0.25rem;
  }
  .lg\:gap-2 {
    gap: 0.5rem;
  }
  .lg\:gap-3 {
    gap: 0.75rem;
  }
  .lg\:gap-4 {
    gap: 1rem;
  }
  .lg\:gap-5 {
    gap: 1.25rem;
  }
  .lg\:gap-6 {
    gap: 1.5rem;
  }
  .lg\:gap-7 {
    gap: 1.75rem;
  }
  .lg\:gap-8 {
    gap: 2rem;
  }
  .lg\:gap-9 {
    gap: 2.25rem;
  }
  .lg\:gap-10 {
    gap: 2.5rem;
  }
  .lg\:gap-11 {
    gap: 2.75rem;
  }
  .lg\:gap-12 {
    gap: 3rem;
  }
  .lg\:gap-14 {
    gap: 3.5rem;
  }
  .lg\:gap-16 {
    gap: 4rem;
  }
  .lg\:gap-20 {
    gap: 5rem;
  }
  .lg\:gap-24 {
    gap: 6rem;
  }
  .lg\:gap-28 {
    gap: 7rem;
  }
  .lg\:gap-32 {
    gap: 8rem;
  }
  .lg\:gap-36 {
    gap: 9rem;
  }
  .lg\:gap-40 {
    gap: 10rem;
  }
  .lg\:gap-44 {
    gap: 11rem;
  }
  .lg\:gap-48 {
    gap: 12rem;
  }
  .lg\:gap-52 {
    gap: 13rem;
  }
  .lg\:gap-56 {
    gap: 14rem;
  }
  .lg\:gap-60 {
    gap: 15rem;
  }
  .lg\:gap-64 {
    gap: 16rem;
  }
  .lg\:gap-72 {
    gap: 18rem;
  }
  .lg\:gap-80 {
    gap: 20rem;
  }
  .lg\:gap-96 {
    gap: 24rem;
  }
  .lg\:gap-px {
    gap: 1px;
  }
  .lg\:gap-0\.5 {
    gap: 0.125rem;
  }
  .lg\:gap-1\.5 {
    gap: 0.375rem;
  }
  .lg\:gap-2\.5 {
    gap: 0.625rem;
  }
  .lg\:gap-3\.5 {
    gap: 0.875rem;
  }
  .lg\:gap-x-0 {
    column-gap: 0;
  }
  .lg\:gap-x-1 {
    column-gap: 0.25rem;
  }
  .lg\:gap-x-2 {
    column-gap: 0.5rem;
  }
  .lg\:gap-x-3 {
    column-gap: 0.75rem;
  }
  .lg\:gap-x-4 {
    column-gap: 1rem;
  }
  .lg\:gap-x-5 {
    column-gap: 1.25rem;
  }
  .lg\:gap-x-6 {
    column-gap: 1.5rem;
  }
  .lg\:gap-x-7 {
    column-gap: 1.75rem;
  }
  .lg\:gap-x-8 {
    column-gap: 2rem;
  }
  .lg\:gap-x-9 {
    column-gap: 2.25rem;
  }
  .lg\:gap-x-10 {
    column-gap: 2.5rem;
  }
  .lg\:gap-x-11 {
    column-gap: 2.75rem;
  }
  .lg\:gap-x-12 {
    column-gap: 3rem;
  }
  .lg\:gap-x-14 {
    column-gap: 3.5rem;
  }
  .lg\:gap-x-16 {
    column-gap: 4rem;
  }
  .lg\:gap-x-20 {
    column-gap: 5rem;
  }
  .lg\:gap-x-24 {
    column-gap: 6rem;
  }
  .lg\:gap-x-28 {
    column-gap: 7rem;
  }
  .lg\:gap-x-32 {
    column-gap: 8rem;
  }
  .lg\:gap-x-36 {
    column-gap: 9rem;
  }
  .lg\:gap-x-40 {
    column-gap: 10rem;
  }
  .lg\:gap-x-44 {
    column-gap: 11rem;
  }
  .lg\:gap-x-48 {
    column-gap: 12rem;
  }
  .lg\:gap-x-52 {
    column-gap: 13rem;
  }
  .lg\:gap-x-56 {
    column-gap: 14rem;
  }
  .lg\:gap-x-60 {
    column-gap: 15rem;
  }
  .lg\:gap-x-64 {
    column-gap: 16rem;
  }
  .lg\:gap-x-72 {
    column-gap: 18rem;
  }
  .lg\:gap-x-80 {
    column-gap: 20rem;
  }
  .lg\:gap-x-96 {
    column-gap: 24rem;
  }
  .lg\:gap-x-px {
    column-gap: 1px;
  }
  .lg\:gap-x-0\.5 {
    column-gap: 0.125rem;
  }
  .lg\:gap-x-1\.5 {
    column-gap: 0.375rem;
  }
  .lg\:gap-x-2\.5 {
    column-gap: 0.625rem;
  }
  .lg\:gap-x-3\.5 {
    column-gap: 0.875rem;
  }
  .lg\:gap-y-0 {
    row-gap: 0;
  }
  .lg\:gap-y-1 {
    row-gap: 0.25rem;
  }
  .lg\:gap-y-2 {
    row-gap: 0.5rem;
  }
  .lg\:gap-y-3 {
    row-gap: 0.75rem;
  }
  .lg\:gap-y-4 {
    row-gap: 1rem;
  }
  .lg\:gap-y-5 {
    row-gap: 1.25rem;
  }
  .lg\:gap-y-6 {
    row-gap: 1.5rem;
  }
  .lg\:gap-y-7 {
    row-gap: 1.75rem;
  }
  .lg\:gap-y-8 {
    row-gap: 2rem;
  }
  .lg\:gap-y-9 {
    row-gap: 2.25rem;
  }
  .lg\:gap-y-10 {
    row-gap: 2.5rem;
  }
  .lg\:gap-y-11 {
    row-gap: 2.75rem;
  }
  .lg\:gap-y-12 {
    row-gap: 3rem;
  }
  .lg\:gap-y-14 {
    row-gap: 3.5rem;
  }
  .lg\:gap-y-16 {
    row-gap: 4rem;
  }
  .lg\:gap-y-20 {
    row-gap: 5rem;
  }
  .lg\:gap-y-24 {
    row-gap: 6rem;
  }
  .lg\:gap-y-28 {
    row-gap: 7rem;
  }
  .lg\:gap-y-32 {
    row-gap: 8rem;
  }
  .lg\:gap-y-36 {
    row-gap: 9rem;
  }
  .lg\:gap-y-40 {
    row-gap: 10rem;
  }
  .lg\:gap-y-44 {
    row-gap: 11rem;
  }
  .lg\:gap-y-48 {
    row-gap: 12rem;
  }
  .lg\:gap-y-52 {
    row-gap: 13rem;
  }
  .lg\:gap-y-56 {
    row-gap: 14rem;
  }
  .lg\:gap-y-60 {
    row-gap: 15rem;
  }
  .lg\:gap-y-64 {
    row-gap: 16rem;
  }
  .lg\:gap-y-72 {
    row-gap: 18rem;
  }
  .lg\:gap-y-80 {
    row-gap: 20rem;
  }
  .lg\:gap-y-96 {
    row-gap: 24rem;
  }
  .lg\:gap-y-px {
    row-gap: 1px;
  }
  .lg\:gap-y-0\.5 {
    row-gap: 0.125rem;
  }
  .lg\:gap-y-1\.5 {
    row-gap: 0.375rem;
  }
  .lg\:gap-y-2\.5 {
    row-gap: 0.625rem;
  }
  .lg\:gap-y-3\.5 {
    row-gap: 0.875rem;
  }
  .lg\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .lg\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .lg\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }
  .lg\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }
  .lg\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }
  .lg\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }
  .lg\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }
  .lg\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .lg\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .lg\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .lg\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .lg\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .lg\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }
  .lg\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }
  .lg\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }
  .lg\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }
  .lg\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }
  .lg\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }
  .lg\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }
  .lg\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }
  .lg\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }
  .lg\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }
  .lg\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }
  .lg\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }
  .lg\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }
  .lg\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }
  .lg\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }
  .lg\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }
  .lg\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }
  .lg\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }
  .lg\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }
  .lg\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }
  .lg\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }
  .lg\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }
  .lg\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }
  .lg\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }
  .lg\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }
  .lg\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }
  .lg\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }
  .lg\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }
  .lg\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }
  .lg\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }
  .lg\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }
  .lg\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }
  .lg\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }
  .lg\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }
  .lg\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }
  .lg\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }
  .lg\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }
  .lg\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }
  .lg\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }
  .lg\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }
  .lg\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }
  .lg\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }
  .lg\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }
  .lg\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }
  .lg\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }
  .lg\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }
  .lg\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }
  .lg\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }
  .lg\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }
  .lg\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }
  .lg\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }
  .lg\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }
  .lg\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }
  .lg\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }
  .lg\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }
  .lg\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }
  .lg\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }
  .lg\:place-self-auto {
    place-self: auto;
  }
  .lg\:place-self-start {
    place-self: start;
  }
  .lg\:place-self-end {
    place-self: end;
  }
  .lg\:place-self-center {
    place-self: center;
  }
  .lg\:place-self-stretch {
    place-self: stretch;
  }
  .lg\:self-auto {
    align-self: auto;
  }
  .lg\:self-start {
    align-self: flex-start;
  }
  .lg\:self-end {
    align-self: flex-end;
  }
  .lg\:self-center {
    align-self: center;
  }
  .lg\:self-stretch {
    align-self: stretch;
  }
  .lg\:self-baseline {
    align-self: baseline;
  }
  .lg\:justify-self-auto {
    justify-self: auto;
  }
  .lg\:justify-self-start {
    justify-self: start;
  }
  .lg\:justify-self-end {
    justify-self: end;
  }
  .lg\:justify-self-center {
    justify-self: center;
  }
  .lg\:justify-self-stretch {
    justify-self: stretch;
  }
  .lg\:overflow-auto {
    overflow: auto;
  }
  .lg\:overflow-hidden {
    overflow: hidden;
  }
  .lg\:overflow-visible {
    overflow: visible;
  }
  .lg\:overflow-scroll {
    overflow: scroll;
  }
  .lg\:overflow-x-auto {
    overflow-x: auto;
  }
  .lg\:overflow-y-auto {
    overflow-y: auto;
  }
  .lg\:overflow-x-hidden {
    overflow-x: hidden;
  }
  .lg\:overflow-y-hidden {
    overflow-y: hidden;
  }
  .lg\:overflow-x-visible {
    overflow-x: visible;
  }
  .lg\:overflow-y-visible {
    overflow-y: visible;
  }
  .lg\:overflow-x-scroll {
    overflow-x: scroll;
  }
  .lg\:overflow-y-scroll {
    overflow-y: scroll;
  }
  .lg\:overscroll-auto {
    overscroll-behavior: auto;
  }
  .lg\:overscroll-contain {
    overscroll-behavior: contain;
  }
  .lg\:overscroll-none {
    overscroll-behavior: none;
  }
  .lg\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }
  .lg\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }
  .lg\:overscroll-y-none {
    overscroll-behavior-y: none;
  }
  .lg\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }
  .lg\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }
  .lg\:overscroll-x-none {
    overscroll-behavior-x: none;
  }
  .lg\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .lg\:overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .lg\:overflow-clip {
    text-overflow: clip;
  }
  .lg\:whitespace-normal {
    white-space: normal;
  }
  .lg\:whitespace-nowrap {
    white-space: nowrap;
  }
  .lg\:whitespace-pre {
    white-space: pre;
  }
  .lg\:whitespace-pre-line {
    white-space: pre-line;
  }
  .lg\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }
  .lg\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .lg\:break-words {
    overflow-wrap: break-word;
  }
  .lg\:break-all {
    word-break: break-all;
  }
  .lg\:rounded-none {
    border-radius: 0;
  }
  .lg\:rounded-sm {
    border-radius: 0.125rem;
  }
  .lg\:rounded {
    border-radius: 0.25rem;
  }
  .lg\:rounded-md {
    border-radius: 0.375rem;
  }
  .lg\:rounded-lg {
    border-radius: 0.5rem;
  }
  .lg\:rounded-xl {
    border-radius: 0.75rem;
  }
  .lg\:rounded-2xl {
    border-radius: 1rem;
  }
  .lg\:rounded-3xl {
    border-radius: 1.5rem;
  }
  .lg\:rounded-full {
    border-radius: 9999px;
  }
  .lg\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .lg\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }
  .lg\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .lg\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .lg\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .lg\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .lg\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .lg\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
  .lg\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }
  .lg\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .lg\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
  .lg\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .lg\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .lg\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .lg\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .lg\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .lg\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  .lg\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }
  .lg\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .lg\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .lg\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .lg\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .lg\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .lg\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .lg\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .lg\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .lg\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .lg\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .lg\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .lg\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .lg\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .lg\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .lg\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .lg\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .lg\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .lg\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .lg\:rounded-tl-none {
    border-top-left-radius: 0;
  }
  .lg\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }
  .lg\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }
  .lg\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }
  .lg\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }
  .lg\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }
  .lg\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }
  .lg\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }
  .lg\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }
  .lg\:rounded-tr-none {
    border-top-right-radius: 0;
  }
  .lg\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }
  .lg\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }
  .lg\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }
  .lg\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }
  .lg\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }
  .lg\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }
  .lg\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }
  .lg\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }
  .lg\:rounded-br-none {
    border-bottom-right-radius: 0;
  }
  .lg\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }
  .lg\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }
  .lg\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }
  .lg\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }
  .lg\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }
  .lg\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }
  .lg\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }
  .lg\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }
  .lg\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }
  .lg\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }
  .lg\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }
  .lg\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }
  .lg\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }
  .lg\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }
  .lg\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }
  .lg\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }
  .lg\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }
  .lg\:border-0 {
    border-width: 0;
  }
  .lg\:border-2 {
    border-width: 2px;
  }
  .lg\:border-4 {
    border-width: 4px;
  }
  .lg\:border-8 {
    border-width: 8px;
  }
  .lg\:border {
    border-width: 1px;
  }
  .lg\:border-t-0 {
    border-top-width: 0;
  }
  .lg\:border-t-2 {
    border-top-width: 2px;
  }
  .lg\:border-t-4 {
    border-top-width: 4px;
  }
  .lg\:border-t-8 {
    border-top-width: 8px;
  }
  .lg\:border-t {
    border-top-width: 1px;
  }
  .lg\:border-r-0 {
    border-right-width: 0;
  }
  .lg\:border-r-2 {
    border-right-width: 2px;
  }
  .lg\:border-r-4 {
    border-right-width: 4px;
  }
  .lg\:border-r-8 {
    border-right-width: 8px;
  }
  .lg\:border-r {
    border-right-width: 1px;
  }
  .lg\:border-b-0 {
    border-bottom-width: 0;
  }
  .lg\:border-b-2 {
    border-bottom-width: 2px;
  }
  .lg\:border-b-4 {
    border-bottom-width: 4px;
  }
  .lg\:border-b-8 {
    border-bottom-width: 8px;
  }
  .lg\:border-b {
    border-bottom-width: 1px;
  }
  .lg\:border-l-0 {
    border-left-width: 0;
  }
  .lg\:border-l-2 {
    border-left-width: 2px;
  }
  .lg\:border-l-4 {
    border-left-width: 4px;
  }
  .lg\:border-l-8 {
    border-left-width: 8px;
  }
  .lg\:border-l {
    border-left-width: 1px;
  }
  .lg\:border-solid {
    border-style: solid;
  }
  .lg\:border-dashed {
    border-style: dashed;
  }
  .lg\:border-dotted {
    border-style: dotted;
  }
  .lg\:border-double {
    border-style: double;
  }
  .lg\:border-none {
    border-style: none;
  }
  .lg\:border-transparent {
    border-color: transparent;
  }
  .lg\:border-current {
    border-color: currentColor;
  }
  .lg\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .lg\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .lg\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .lg\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .lg\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .lg\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .lg\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .lg\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .lg\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .lg\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .lg\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .lg\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .lg\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .lg\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .lg\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .lg\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .lg\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .lg\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .lg\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .lg\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .lg\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .lg\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .lg\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .lg\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .lg\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .lg\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .lg\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .lg\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .lg\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .lg\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .lg\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .lg\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .lg\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .lg\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .lg\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .lg\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .lg\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .lg\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .lg\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .lg\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .lg\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .lg\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .lg\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .lg\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .lg\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .lg\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .lg\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .lg\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .lg\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .lg\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .lg\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .lg\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .lg\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .lg\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .lg\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .lg\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .lg\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .lg\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .lg\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .lg\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .lg\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .lg\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .lg\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .lg\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .lg\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .lg\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .lg\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .lg\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .lg\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .lg\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .lg\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .lg\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .lg\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .lg\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .lg\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .lg\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .lg\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .lg\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .lg\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .lg\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .lg\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .lg\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-transparent {
    border-color: transparent;
  }
  .group:hover .lg\:group-hover\:border-current {
    border-color: currentColor;
  }
  .group:hover .lg\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .group:hover .lg\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-transparent:focus-within {
    border-color: transparent;
  }
  .lg\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }
  .lg\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .lg\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .lg\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .lg\:hover\:border-current:hover {
    border-color: currentColor;
  }
  .lg\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .lg\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .lg\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .lg\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .lg\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .lg\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .lg\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .lg\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .lg\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .lg\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .lg\:focus\:border-transparent:focus {
    border-color: transparent;
  }
  .lg\:focus\:border-current:focus {
    border-color: currentColor;
  }
  .lg\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .lg\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .lg\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .lg\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .lg\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .lg\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .lg\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .lg\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .lg\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .lg\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .lg\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .lg\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .lg\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .lg\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .lg\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .lg\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .lg\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .lg\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .lg\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .lg\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .lg\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .lg\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .lg\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .lg\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .lg\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .group:hover .lg\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .group:hover .lg\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .group:hover .lg\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .group:hover .lg\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .group:hover .lg\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .group:hover .lg\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .group:hover .lg\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .group:hover .lg\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .group:hover .lg\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .group:hover .lg\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .group:hover .lg\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .group:hover .lg\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .group:hover .lg\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .group:hover .lg\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .group:hover .lg\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .lg\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }
  .lg\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }
  .lg\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }
  .lg\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }
  .lg\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }
  .lg\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }
  .lg\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }
  .lg\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }
  .lg\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }
  .lg\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }
  .lg\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }
  .lg\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }
  .lg\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }
  .lg\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }
  .lg\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }
  .lg\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }
  .lg\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }
  .lg\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }
  .lg\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }
  .lg\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }
  .lg\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }
  .lg\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }
  .lg\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }
  .lg\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }
  .lg\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }
  .lg\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }
  .lg\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }
  .lg\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }
  .lg\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }
  .lg\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }
  .lg\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }
  .lg\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }
  .lg\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }
  .lg\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }
  .lg\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }
  .lg\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }
  .lg\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }
  .lg\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }
  .lg\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }
  .lg\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }
  .lg\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }
  .lg\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }
  .lg\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }
  .lg\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }
  .lg\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }
  .lg\:bg-transparent {
    background-color: transparent;
  }
  .lg\:bg-current {
    background-color: currentColor;
  }
  .lg\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .lg\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .lg\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .lg\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .lg\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .lg\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .lg\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .lg\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .lg\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .lg\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .lg\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .lg\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .lg\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .lg\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .lg\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .lg\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .lg\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .lg\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .lg\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .lg\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .lg\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .lg\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .lg\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .lg\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .lg\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .lg\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .lg\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-transparent {
    background-color: transparent;
  }
  .group:hover .lg\:group-hover\:bg-current {
    background-color: currentColor;
  }
  .group:hover .lg\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .group:hover .lg\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-transparent:focus-within {
    background-color: transparent;
  }
  .lg\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }
  .lg\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .lg\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-transparent:hover {
    background-color: transparent;
  }
  .lg\:hover\:bg-current:hover {
    background-color: currentColor;
  }
  .lg\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .lg\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-transparent:focus {
    background-color: transparent;
  }
  .lg\:focus\:bg-current:focus {
    background-color: currentColor;
  }
  .lg\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .lg\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .lg\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .lg\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .lg\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .lg\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .lg\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .lg\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .lg\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .lg\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .lg\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .lg\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .lg\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .lg\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .lg\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .lg\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .lg\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .group:hover .lg\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .group:hover .lg\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .group:hover .lg\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .group:hover .lg\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .group:hover .lg\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .group:hover .lg\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .group:hover .lg\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .group:hover .lg\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .group:hover .lg\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .group:hover .lg\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .group:hover .lg\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .group:hover .lg\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .group:hover .lg\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .group:hover .lg\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .group:hover .lg\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .lg\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }
  .lg\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }
  .lg\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }
  .lg\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }
  .lg\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }
  .lg\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }
  .lg\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }
  .lg\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }
  .lg\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }
  .lg\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }
  .lg\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }
  .lg\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }
  .lg\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }
  .lg\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }
  .lg\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }
  .lg\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }
  .lg\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }
  .lg\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }
  .lg\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }
  .lg\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }
  .lg\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }
  .lg\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }
  .lg\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }
  .lg\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }
  .lg\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }
  .lg\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }
  .lg\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }
  .lg\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }
  .lg\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }
  .lg\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }
  .lg\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }
  .lg\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }
  .lg\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }
  .lg\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }
  .lg\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }
  .lg\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }
  .lg\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }
  .lg\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }
  .lg\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }
  .lg\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }
  .lg\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }
  .lg\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }
  .lg\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }
  .lg\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }
  .lg\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }
  .lg\:bg-none {
    background-image: none;
  }
  .lg\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }
  .lg\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }
  .lg\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }
  .lg\:bg-gradient-to-br {
    background-image: linear-gradient(
      to bottom right,
      var(--tw-gradient-stops)
    );
  }
  .lg\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }
  .lg\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }
  .lg\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }
  .lg\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }
  .lg\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .lg\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .lg\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .lg\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .lg\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .lg\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .lg\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .lg\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .lg\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .lg\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .lg\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .lg\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .lg\:from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .lg\:from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .lg\:from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .lg\:from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .lg\:from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .lg\:from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .lg\:from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .lg\:from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .lg\:from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .lg\:from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .lg\:from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .lg\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .lg\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .lg\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .lg\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .lg\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .lg\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .lg\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .lg\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .lg\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .lg\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .lg\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .lg\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .lg\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .lg\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .lg\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .lg\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .lg\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .lg\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .lg\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .lg\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .lg\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .lg\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .lg\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .lg\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .lg\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .lg\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .lg\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .lg\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .lg\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .lg\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .lg\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .lg\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .lg\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .lg\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .lg\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .lg\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .lg\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .lg\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .lg\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .lg\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .lg\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .lg\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .lg\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .lg\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .lg\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .lg\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .lg\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .lg\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .lg\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .lg\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .lg\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .lg\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .lg\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .lg\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .lg\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .lg\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .lg\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .lg\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .lg\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .lg\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .lg\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .lg\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .lg\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .lg\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .lg\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .lg\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .lg\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:hover\:from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .lg\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .lg\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .lg\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .lg\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .lg\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .lg\:hover\:from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .lg\:hover\:from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .lg\:hover\:from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .lg\:hover\:from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .lg\:hover\:from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .lg\:hover\:from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .lg\:hover\:from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .lg\:hover\:from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .lg\:hover\:from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .lg\:hover\:from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .lg\:hover\:from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .lg\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .lg\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .lg\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .lg\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .lg\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .lg\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .lg\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .lg\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .lg\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .lg\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .lg\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .lg\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .lg\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .lg\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .lg\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .lg\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .lg\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .lg\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .lg\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .lg\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .lg\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .lg\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .lg\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .lg\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .lg\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .lg\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .lg\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .lg\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .lg\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .lg\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .lg\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .lg\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .lg\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .lg\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .lg\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .lg\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .lg\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .lg\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .lg\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .lg\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .lg\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .lg\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .lg\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .lg\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .lg\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .lg\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .lg\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .lg\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .lg\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .lg\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .lg\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .lg\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .lg\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .lg\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .lg\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .lg\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .lg\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .lg\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .lg\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .lg\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .lg\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .lg\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .lg\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .lg\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .lg\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .lg\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .lg\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:focus\:from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .lg\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .lg\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .lg\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .lg\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .lg\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .lg\:focus\:from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .lg\:focus\:from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .lg\:focus\:from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .lg\:focus\:from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .lg\:focus\:from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .lg\:focus\:from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .lg\:focus\:from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .lg\:focus\:from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .lg\:focus\:from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .lg\:focus\:from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .lg\:focus\:from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .lg\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .lg\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .lg\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .lg\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .lg\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .lg\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .lg\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .lg\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .lg\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .lg\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .lg\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .lg\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .lg\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .lg\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .lg\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .lg\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .lg\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .lg\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .lg\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .lg\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .lg\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .lg\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .lg\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .lg\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .lg\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .lg\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .lg\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .lg\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .lg\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .lg\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .lg\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .lg\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .lg\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .lg\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .lg\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .lg\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .lg\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .lg\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .lg\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .lg\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .lg\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .lg\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .lg\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .lg\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .lg\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .lg\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .lg\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .lg\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .lg\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .lg\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .lg\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .lg\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .lg\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .lg\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .lg\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .lg\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .lg\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .lg\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .lg\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .lg\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .lg\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .lg\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .lg\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .lg\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .lg\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .lg\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .lg\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .lg\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .lg\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .lg\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .lg\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .lg\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .lg\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827,
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .lg\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .lg\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .lg\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .lg\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .lg\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .lg\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .lg\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .lg\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .lg\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .lg\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .lg\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .lg\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .lg\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .lg\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .lg\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .lg\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .lg\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .lg\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .lg\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .lg\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .lg\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .lg\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .lg\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .lg\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .lg\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .lg\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .lg\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .lg\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .lg\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .lg\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .lg\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .lg\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .lg\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .lg\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .lg\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .lg\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .lg\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .lg\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .lg\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .lg\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .lg\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .lg\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .lg\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .lg\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .lg\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .lg\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .lg\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .lg\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .lg\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .lg\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .lg\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .lg\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .lg\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .lg\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .lg\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .lg\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .lg\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .lg\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .lg\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .lg\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .lg\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .lg\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .lg\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .lg\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .lg\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .lg\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .lg\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .lg\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .lg\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .lg\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .lg\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .lg\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .lg\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .lg\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .lg\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .lg\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .lg\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .lg\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .lg\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .lg\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .lg\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .lg\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .lg\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827,
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .lg\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .lg\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .lg\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .lg\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .lg\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .lg\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .lg\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .lg\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .lg\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .lg\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .lg\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .lg\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .lg\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .lg\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .lg\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .lg\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .lg\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .lg\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .lg\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .lg\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .lg\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .lg\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .lg\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .lg\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .lg\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .lg\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .lg\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .lg\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .lg\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .lg\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .lg\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .lg\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .lg\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .lg\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .lg\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .lg\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .lg\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .lg\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .lg\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .lg\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .lg\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .lg\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .lg\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .lg\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .lg\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .lg\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .lg\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .lg\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .lg\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .lg\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .lg\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .lg\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .lg\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .lg\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .lg\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .lg\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .lg\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .lg\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .lg\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .lg\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .lg\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .lg\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .lg\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .lg\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .lg\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .lg\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .lg\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .lg\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .lg\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .lg\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .lg\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .lg\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .lg\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .lg\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .lg\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .lg\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .lg\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .lg\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .lg\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .lg\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .lg\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .lg\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .lg\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .lg\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827,
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .lg\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .lg\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .lg\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .lg\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .lg\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .lg\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .lg\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .lg\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .lg\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .lg\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .lg\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .lg\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .lg\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .lg\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .lg\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .lg\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .lg\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .lg\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .lg\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .lg\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .lg\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .lg\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .lg\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .lg\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .lg\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .lg\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .lg\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .lg\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .lg\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .lg\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .lg\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .lg\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .lg\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .lg\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .lg\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .lg\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .lg\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .lg\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .lg\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .lg\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .lg\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .lg\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .lg\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .lg\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .lg\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .lg\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .lg\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .lg\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .lg\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .lg\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .lg\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .lg\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .lg\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .lg\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .lg\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .lg\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .lg\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .lg\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .lg\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .lg\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .lg\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .lg\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .lg\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .lg\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .lg\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .lg\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .lg\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .lg\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .lg\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .lg\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .lg\:to-transparent {
    --tw-gradient-to: transparent;
  }
  .lg\:to-current {
    --tw-gradient-to: currentColor;
  }
  .lg\:to-black {
    --tw-gradient-to: #000;
  }
  .lg\:to-white {
    --tw-gradient-to: #fff;
  }
  .lg\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }
  .lg\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }
  .lg\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }
  .lg\:to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }
  .lg\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }
  .lg\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }
  .lg\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }
  .lg\:to-gray-700 {
    --tw-gradient-to: #374151;
  }
  .lg\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }
  .lg\:to-gray-900 {
    --tw-gradient-to: #111827;
  }
  .lg\:to-red-50 {
    --tw-gradient-to: #fef2f2;
  }
  .lg\:to-red-100 {
    --tw-gradient-to: #fee2e2;
  }
  .lg\:to-red-200 {
    --tw-gradient-to: #fecaca;
  }
  .lg\:to-red-300 {
    --tw-gradient-to: #fca5a5;
  }
  .lg\:to-red-400 {
    --tw-gradient-to: #f87171;
  }
  .lg\:to-red-500 {
    --tw-gradient-to: #ef4444;
  }
  .lg\:to-red-600 {
    --tw-gradient-to: #dc2626;
  }
  .lg\:to-red-700 {
    --tw-gradient-to: #b91c1c;
  }
  .lg\:to-red-800 {
    --tw-gradient-to: #991b1b;
  }
  .lg\:to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }
  .lg\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }
  .lg\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }
  .lg\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }
  .lg\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }
  .lg\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }
  .lg\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }
  .lg\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }
  .lg\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }
  .lg\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }
  .lg\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }
  .lg\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }
  .lg\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }
  .lg\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }
  .lg\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }
  .lg\:to-green-400 {
    --tw-gradient-to: #34d399;
  }
  .lg\:to-green-500 {
    --tw-gradient-to: #10b981;
  }
  .lg\:to-green-600 {
    --tw-gradient-to: #059669;
  }
  .lg\:to-green-700 {
    --tw-gradient-to: #047857;
  }
  .lg\:to-green-800 {
    --tw-gradient-to: #065f46;
  }
  .lg\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }
  .lg\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }
  .lg\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }
  .lg\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }
  .lg\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }
  .lg\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }
  .lg\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }
  .lg\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }
  .lg\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }
  .lg\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }
  .lg\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }
  .lg\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }
  .lg\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }
  .lg\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }
  .lg\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }
  .lg\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }
  .lg\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }
  .lg\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }
  .lg\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }
  .lg\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }
  .lg\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }
  .lg\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }
  .lg\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }
  .lg\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }
  .lg\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }
  .lg\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }
  .lg\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }
  .lg\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }
  .lg\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }
  .lg\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }
  .lg\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }
  .lg\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }
  .lg\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }
  .lg\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }
  .lg\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }
  .lg\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }
  .lg\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }
  .lg\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }
  .lg\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }
  .lg\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }
  .lg\:to-pink-900 {
    --tw-gradient-to: #831843;
  }
  .lg\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }
  .lg\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }
  .lg\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }
  .lg\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }
  .lg\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }
  .lg\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }
  .lg\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .lg\:hover\:to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }
  .lg\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }
  .lg\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }
  .lg\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }
  .lg\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }
  .lg\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }
  .lg\:hover\:to-gray-900:hover {
    --tw-gradient-to: #111827;
  }
  .lg\:hover\:to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }
  .lg\:hover\:to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }
  .lg\:hover\:to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }
  .lg\:hover\:to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }
  .lg\:hover\:to-red-400:hover {
    --tw-gradient-to: #f87171;
  }
  .lg\:hover\:to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }
  .lg\:hover\:to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }
  .lg\:hover\:to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }
  .lg\:hover\:to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }
  .lg\:hover\:to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }
  .lg\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }
  .lg\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }
  .lg\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }
  .lg\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }
  .lg\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }
  .lg\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }
  .lg\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }
  .lg\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }
  .lg\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }
  .lg\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }
  .lg\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }
  .lg\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }
  .lg\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }
  .lg\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }
  .lg\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }
  .lg\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }
  .lg\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }
  .lg\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }
  .lg\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }
  .lg\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }
  .lg\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }
  .lg\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }
  .lg\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }
  .lg\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }
  .lg\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }
  .lg\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }
  .lg\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }
  .lg\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }
  .lg\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }
  .lg\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }
  .lg\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }
  .lg\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }
  .lg\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }
  .lg\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }
  .lg\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }
  .lg\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }
  .lg\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }
  .lg\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }
  .lg\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }
  .lg\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }
  .lg\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }
  .lg\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }
  .lg\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }
  .lg\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }
  .lg\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }
  .lg\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }
  .lg\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }
  .lg\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }
  .lg\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }
  .lg\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }
  .lg\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }
  .lg\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }
  .lg\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }
  .lg\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }
  .lg\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }
  .lg\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }
  .lg\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }
  .lg\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }
  .lg\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }
  .lg\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }
  .lg\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }
  .lg\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }
  .lg\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }
  .lg\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }
  .lg\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }
  .lg\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }
  .lg\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .lg\:focus\:to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }
  .lg\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }
  .lg\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }
  .lg\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }
  .lg\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }
  .lg\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }
  .lg\:focus\:to-gray-900:focus {
    --tw-gradient-to: #111827;
  }
  .lg\:focus\:to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }
  .lg\:focus\:to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }
  .lg\:focus\:to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }
  .lg\:focus\:to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }
  .lg\:focus\:to-red-400:focus {
    --tw-gradient-to: #f87171;
  }
  .lg\:focus\:to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }
  .lg\:focus\:to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }
  .lg\:focus\:to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }
  .lg\:focus\:to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }
  .lg\:focus\:to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }
  .lg\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }
  .lg\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }
  .lg\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }
  .lg\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }
  .lg\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }
  .lg\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }
  .lg\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }
  .lg\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }
  .lg\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }
  .lg\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }
  .lg\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }
  .lg\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }
  .lg\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }
  .lg\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }
  .lg\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }
  .lg\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }
  .lg\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }
  .lg\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }
  .lg\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }
  .lg\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }
  .lg\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }
  .lg\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }
  .lg\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }
  .lg\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }
  .lg\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }
  .lg\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }
  .lg\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }
  .lg\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }
  .lg\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }
  .lg\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }
  .lg\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }
  .lg\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }
  .lg\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }
  .lg\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }
  .lg\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }
  .lg\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }
  .lg\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }
  .lg\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }
  .lg\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }
  .lg\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }
  .lg\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }
  .lg\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }
  .lg\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }
  .lg\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }
  .lg\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }
  .lg\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }
  .lg\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }
  .lg\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }
  .lg\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }
  .lg\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }
  .lg\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }
  .lg\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }
  .lg\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }
  .lg\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }
  .lg\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }
  .lg\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }
  .lg\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }
  .lg\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }
  .lg\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }
  .lg\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }
  .lg\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }
  .lg\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
  .lg\:bg-auto {
    background-size: auto;
  }
  .lg\:bg-cover {
    background-size: cover;
  }
  .lg\:bg-contain {
    background-size: contain;
  }
  .lg\:bg-fixed {
    background-attachment: fixed;
  }
  .lg\:bg-local {
    background-attachment: local;
  }
  .lg\:bg-scroll {
    background-attachment: scroll;
  }
  .lg\:bg-clip-border {
    background-clip: border-box;
  }
  .lg\:bg-clip-padding {
    background-clip: padding-box;
  }
  .lg\:bg-clip-content {
    background-clip: content-box;
  }
  .lg\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }
  .lg\:bg-bottom {
    background-position: bottom;
  }
  .lg\:bg-center {
    background-position: center;
  }
  .lg\:bg-left {
    background-position: left;
  }
  .lg\:bg-left-bottom {
    background-position: left bottom;
  }
  .lg\:bg-left-top {
    background-position: left top;
  }
  .lg\:bg-right {
    background-position: right;
  }
  .lg\:bg-right-bottom {
    background-position: right bottom;
  }
  .lg\:bg-right-top {
    background-position: right top;
  }
  .lg\:bg-top {
    background-position: top;
  }
  .lg\:bg-repeat {
    background-repeat: repeat;
  }
  .lg\:bg-no-repeat {
    background-repeat: no-repeat;
  }
  .lg\:bg-repeat-x {
    background-repeat: repeat-x;
  }
  .lg\:bg-repeat-y {
    background-repeat: repeat-y;
  }
  .lg\:bg-repeat-round {
    background-repeat: round;
  }
  .lg\:bg-repeat-space {
    background-repeat: space;
  }
  .lg\:bg-origin-border {
    background-origin: border-box;
  }
  .lg\:bg-origin-padding {
    background-origin: padding-box;
  }
  .lg\:bg-origin-content {
    background-origin: content-box;
  }
  .lg\:fill-current {
    fill: currentColor;
  }
  .lg\:stroke-current {
    stroke: currentColor;
  }
  .lg\:stroke-0 {
    stroke-width: 0;
  }
  .lg\:stroke-1 {
    stroke-width: 1;
  }
  .lg\:stroke-2 {
    stroke-width: 2;
  }
  .lg\:object-contain {
    object-fit: contain;
  }
  .lg\:object-cover {
    object-fit: cover;
  }
  .lg\:object-fill {
    object-fit: fill;
  }
  .lg\:object-none {
    object-fit: none;
  }
  .lg\:object-scale-down {
    object-fit: scale-down;
  }
  .lg\:object-bottom {
    object-position: bottom;
  }
  .lg\:object-center {
    object-position: center;
  }
  .lg\:object-left {
    object-position: left;
  }
  .lg\:object-left-bottom {
    object-position: left bottom;
  }
  .lg\:object-left-top {
    object-position: left top;
  }
  .lg\:object-right {
    object-position: right;
  }
  .lg\:object-right-bottom {
    object-position: right bottom;
  }
  .lg\:object-right-top {
    object-position: right top;
  }
  .lg\:object-top {
    object-position: top;
  }
  .lg\:p-0 {
    padding: 0;
  }
  .lg\:p-1 {
    padding: 0.25rem;
  }
  .lg\:p-2 {
    padding: 0.5rem;
  }
  .lg\:p-3 {
    padding: 0.75rem;
  }
  .lg\:p-4 {
    padding: 1rem;
  }
  .lg\:p-5 {
    padding: 1.25rem;
  }
  .lg\:p-6 {
    padding: 1.5rem;
  }
  .lg\:p-7 {
    padding: 1.75rem;
  }
  .lg\:p-8 {
    padding: 2rem;
  }
  .lg\:p-9 {
    padding: 2.25rem;
  }
  .lg\:p-10 {
    padding: 2.5rem;
  }
  .lg\:p-11 {
    padding: 2.75rem;
  }
  .lg\:p-12 {
    padding: 3rem;
  }
  .lg\:p-14 {
    padding: 3.5rem;
  }
  .lg\:p-16 {
    padding: 4rem;
  }
  .lg\:p-20 {
    padding: 5rem;
  }
  .lg\:p-24 {
    padding: 6rem;
  }
  .lg\:p-28 {
    padding: 7rem;
  }
  .lg\:p-32 {
    padding: 8rem;
  }
  .lg\:p-36 {
    padding: 9rem;
  }
  .lg\:p-40 {
    padding: 10rem;
  }
  .lg\:p-44 {
    padding: 11rem;
  }
  .lg\:p-48 {
    padding: 12rem;
  }
  .lg\:p-52 {
    padding: 13rem;
  }
  .lg\:p-56 {
    padding: 14rem;
  }
  .lg\:p-60 {
    padding: 15rem;
  }
  .lg\:p-64 {
    padding: 16rem;
  }
  .lg\:p-72 {
    padding: 18rem;
  }
  .lg\:p-80 {
    padding: 20rem;
  }
  .lg\:p-96 {
    padding: 24rem;
  }
  .lg\:p-px {
    padding: 1px;
  }
  .lg\:p-0\.5 {
    padding: 0.125rem;
  }
  .lg\:p-1\.5 {
    padding: 0.375rem;
  }
  .lg\:p-2\.5 {
    padding: 0.625rem;
  }
  .lg\:p-3\.5 {
    padding: 0.875rem;
  }
  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .lg\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .lg\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .lg\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .lg\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .lg\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .lg\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .lg\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .lg\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .lg\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .lg\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .lg\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .lg\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .lg\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .lg\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .lg\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .lg\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }
  .lg\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }
  .lg\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }
  .lg\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .lg\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }
  .lg\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .lg\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }
  .lg\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .lg\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .lg\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .lg\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .lg\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .lg\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .lg\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .lg\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .lg\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .lg\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .lg\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .lg\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .lg\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .lg\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .lg\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .lg\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .lg\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .lg\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .lg\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .lg\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .lg\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
  .lg\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
  .lg\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .lg\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  .lg\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }
  .lg\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
  .lg\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }
  .lg\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .lg\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .lg\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .lg\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .lg\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .lg\:pt-0 {
    padding-top: 0;
  }
  .lg\:pt-1 {
    padding-top: 0.25rem;
  }
  .lg\:pt-2 {
    padding-top: 0.5rem;
  }
  .lg\:pt-3 {
    padding-top: 0.75rem;
  }
  .lg\:pt-4 {
    padding-top: 1rem;
  }
  .lg\:pt-5 {
    padding-top: 1.25rem;
  }
  .lg\:pt-6 {
    padding-top: 1.5rem;
  }
  .lg\:pt-7 {
    padding-top: 1.75rem;
  }
  .lg\:pt-8 {
    padding-top: 2rem;
  }
  .lg\:pt-9 {
    padding-top: 2.25rem;
  }
  .lg\:pt-10 {
    padding-top: 2.5rem;
  }
  .lg\:pt-11 {
    padding-top: 2.75rem;
  }
  .lg\:pt-12 {
    padding-top: 3rem;
  }
  .lg\:pt-14 {
    padding-top: 3.5rem;
  }
  .lg\:pt-16 {
    padding-top: 4rem;
  }
  .lg\:pt-20 {
    padding-top: 5rem;
  }
  .lg\:pt-24 {
    padding-top: 6rem;
  }
  .lg\:pt-28 {
    padding-top: 7rem;
  }
  .lg\:pt-32 {
    padding-top: 8rem;
  }
  .lg\:pt-36 {
    padding-top: 9rem;
  }
  .lg\:pt-40 {
    padding-top: 10rem;
  }
  .lg\:pt-44 {
    padding-top: 11rem;
  }
  .lg\:pt-48 {
    padding-top: 12rem;
  }
  .lg\:pt-52 {
    padding-top: 13rem;
  }
  .lg\:pt-56 {
    padding-top: 14rem;
  }
  .lg\:pt-60 {
    padding-top: 15rem;
  }
  .lg\:pt-64 {
    padding-top: 16rem;
  }
  .lg\:pt-72 {
    padding-top: 18rem;
  }
  .lg\:pt-80 {
    padding-top: 20rem;
  }
  .lg\:pt-96 {
    padding-top: 24rem;
  }
  .lg\:pt-px {
    padding-top: 1px;
  }
  .lg\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .lg\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .lg\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .lg\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .lg\:pr-0 {
    padding-right: 0;
  }
  .lg\:pr-1 {
    padding-right: 0.25rem;
  }
  .lg\:pr-2 {
    padding-right: 0.5rem;
  }
  .lg\:pr-3 {
    padding-right: 0.75rem;
  }
  .lg\:pr-4 {
    padding-right: 1rem;
  }
  .lg\:pr-5 {
    padding-right: 1.25rem;
  }
  .lg\:pr-6 {
    padding-right: 1.5rem;
  }
  .lg\:pr-7 {
    padding-right: 1.75rem;
  }
  .lg\:pr-8 {
    padding-right: 2rem;
  }
  .lg\:pr-9 {
    padding-right: 2.25rem;
  }
  .lg\:pr-10 {
    padding-right: 2.5rem;
  }
  .lg\:pr-11 {
    padding-right: 2.75rem;
  }
  .lg\:pr-12 {
    padding-right: 3rem;
  }
  .lg\:pr-14 {
    padding-right: 3.5rem;
  }
  .lg\:pr-16 {
    padding-right: 4rem;
  }
  .lg\:pr-20 {
    padding-right: 5rem;
  }
  .lg\:pr-24 {
    padding-right: 6rem;
  }
  .lg\:pr-28 {
    padding-right: 7rem;
  }
  .lg\:pr-32 {
    padding-right: 8rem;
  }
  .lg\:pr-36 {
    padding-right: 9rem;
  }
  .lg\:pr-40 {
    padding-right: 10rem;
  }
  .lg\:pr-44 {
    padding-right: 11rem;
  }
  .lg\:pr-48 {
    padding-right: 12rem;
  }
  .lg\:pr-52 {
    padding-right: 13rem;
  }
  .lg\:pr-56 {
    padding-right: 14rem;
  }
  .lg\:pr-60 {
    padding-right: 15rem;
  }
  .lg\:pr-64 {
    padding-right: 16rem;
  }
  .lg\:pr-72 {
    padding-right: 18rem;
  }
  .lg\:pr-80 {
    padding-right: 20rem;
  }
  .lg\:pr-96 {
    padding-right: 24rem;
  }
  .lg\:pr-px {
    padding-right: 1px;
  }
  .lg\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .lg\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .lg\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .lg\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .lg\:pb-0 {
    padding-bottom: 0;
  }
  .lg\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .lg\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .lg\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .lg\:pb-4 {
    padding-bottom: 1rem;
  }
  .lg\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .lg\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .lg\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .lg\:pb-8 {
    padding-bottom: 2rem;
  }
  .lg\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .lg\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .lg\:pb-12 {
    padding-bottom: 3rem;
  }
  .lg\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .lg\:pb-16 {
    padding-bottom: 4rem;
  }
  .lg\:pb-20 {
    padding-bottom: 5rem;
  }
  .lg\:pb-24 {
    padding-bottom: 6rem;
  }
  .lg\:pb-28 {
    padding-bottom: 7rem;
  }
  .lg\:pb-32 {
    padding-bottom: 8rem;
  }
  .lg\:pb-36 {
    padding-bottom: 9rem;
  }
  .lg\:pb-40 {
    padding-bottom: 10rem;
  }
  .lg\:pb-44 {
    padding-bottom: 11rem;
  }
  .lg\:pb-48 {
    padding-bottom: 12rem;
  }
  .lg\:pb-52 {
    padding-bottom: 13rem;
  }
  .lg\:pb-56 {
    padding-bottom: 14rem;
  }
  .lg\:pb-60 {
    padding-bottom: 15rem;
  }
  .lg\:pb-64 {
    padding-bottom: 16rem;
  }
  .lg\:pb-72 {
    padding-bottom: 18rem;
  }
  .lg\:pb-80 {
    padding-bottom: 20rem;
  }
  .lg\:pb-96 {
    padding-bottom: 24rem;
  }
  .lg\:pb-px {
    padding-bottom: 1px;
  }
  .lg\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .lg\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .lg\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .lg\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .lg\:pl-0 {
    padding-left: 0;
  }
  .lg\:pl-1 {
    padding-left: 0.25rem;
  }
  .lg\:pl-2 {
    padding-left: 0.5rem;
  }
  .lg\:pl-3 {
    padding-left: 0.75rem;
  }
  .lg\:pl-4 {
    padding-left: 1rem;
  }
  .lg\:pl-5 {
    padding-left: 1.25rem;
  }
  .lg\:pl-6 {
    padding-left: 1.5rem;
  }
  .lg\:pl-7 {
    padding-left: 1.75rem;
  }
  .lg\:pl-8 {
    padding-left: 2rem;
  }
  .lg\:pl-9 {
    padding-left: 2.25rem;
  }
  .lg\:pl-10 {
    padding-left: 2.5rem;
  }
  .lg\:pl-11 {
    padding-left: 2.75rem;
  }
  .lg\:pl-12 {
    padding-left: 3rem;
  }
  .lg\:pl-14 {
    padding-left: 3.5rem;
  }
  .lg\:pl-16 {
    padding-left: 4rem;
  }
  .lg\:pl-20 {
    padding-left: 5rem;
  }
  .lg\:pl-24 {
    padding-left: 6rem;
  }
  .lg\:pl-28 {
    padding-left: 7rem;
  }
  .lg\:pl-32 {
    padding-left: 8rem;
  }
  .lg\:pl-36 {
    padding-left: 9rem;
  }
  .lg\:pl-40 {
    padding-left: 10rem;
  }
  .lg\:pl-44 {
    padding-left: 11rem;
  }
  .lg\:pl-48 {
    padding-left: 12rem;
  }
  .lg\:pl-52 {
    padding-left: 13rem;
  }
  .lg\:pl-56 {
    padding-left: 14rem;
  }
  .lg\:pl-60 {
    padding-left: 15rem;
  }
  .lg\:pl-64 {
    padding-left: 16rem;
  }
  .lg\:pl-72 {
    padding-left: 18rem;
  }
  .lg\:pl-80 {
    padding-left: 20rem;
  }
  .lg\:pl-96 {
    padding-left: 24rem;
  }
  .lg\:pl-px {
    padding-left: 1px;
  }
  .lg\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .lg\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .lg\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .lg\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .lg\:text-left {
    text-align: left;
  }
  .lg\:text-center {
    text-align: center;
  }
  .lg\:text-right {
    text-align: right;
  }
  .lg\:text-justify {
    text-align: justify;
  }
  .lg\:align-baseline {
    vertical-align: baseline;
  }
  .lg\:align-top {
    vertical-align: top;
  }
  .lg\:align-middle {
    vertical-align: middle;
  }
  .lg\:align-bottom {
    vertical-align: bottom;
  }
  .lg\:align-text-top {
    vertical-align: text-top;
  }
  .lg\:align-text-bottom {
    vertical-align: text-bottom;
  }
  .lg\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  }
  .lg\:font-serif {
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  }
  .lg\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
  }
  .lg\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .lg\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .lg\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .lg\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .lg\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .lg\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
  .lg\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
  .lg\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
  .lg\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }
  .lg\:font-thin {
    font-weight: 100;
  }
  .lg\:font-extralight {
    font-weight: 200;
  }
  .lg\:font-light {
    font-weight: 300;
  }
  .lg\:font-normal {
    font-weight: 400;
  }
  .lg\:font-medium {
    font-weight: 500;
  }
  .lg\:font-semibold {
    font-weight: 600;
  }
  .lg\:font-bold {
    font-weight: 700;
  }
  .lg\:font-extrabold {
    font-weight: 800;
  }
  .lg\:font-black {
    font-weight: 900;
  }
  .lg\:uppercase {
    text-transform: uppercase;
  }
  .lg\:lowercase {
    text-transform: lowercase;
  }
  .lg\:capitalize {
    text-transform: capitalize;
  }
  .lg\:normal-case {
    text-transform: none;
  }
  .lg\:italic {
    font-style: italic;
  }
  .lg\:not-italic {
    font-style: normal;
  }
  .lg\:diagonal-fractions,
  .lg\:lining-nums,
  .lg\:oldstyle-nums,
  .lg\:ordinal,
  .lg\:proportional-nums,
  .lg\:slashed-zero,
  .lg\:stacked-fractions,
  .lg\:tabular-nums {
    --tw-ordinal: var(--tw-empty); /*!*/ /*!*/
    --tw-slashed-zero: var(--tw-empty); /*!*/ /*!*/
    --tw-numeric-figure: var(--tw-empty); /*!*/ /*!*/
    --tw-numeric-spacing: var(--tw-empty); /*!*/ /*!*/
    --tw-numeric-fraction: var(--tw-empty); /*!*/ /*!*/
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
  }
  .lg\:normal-nums {
    font-variant-numeric: normal;
  }
  .lg\:ordinal {
    --tw-ordinal: ordinal;
  }
  .lg\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }
  .lg\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }
  .lg\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }
  .lg\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }
  .lg\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }
  .lg\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }
  .lg\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }
  .lg\:leading-3 {
    line-height: 0.75rem;
  }
  .lg\:leading-4 {
    line-height: 1rem;
  }
  .lg\:leading-5 {
    line-height: 1.25rem;
  }
  .lg\:leading-6 {
    line-height: 1.5rem;
  }
  .lg\:leading-7 {
    line-height: 1.75rem;
  }
  .lg\:leading-8 {
    line-height: 2rem;
  }
  .lg\:leading-9 {
    line-height: 2.25rem;
  }
  .lg\:leading-10 {
    line-height: 2.5rem;
  }
  .lg\:leading-none {
    line-height: 1;
  }
  .lg\:leading-tight {
    line-height: 1.25;
  }
  .lg\:leading-snug {
    line-height: 1.375;
  }
  .lg\:leading-normal {
    line-height: 1.5;
  }
  .lg\:leading-relaxed {
    line-height: 1.625;
  }
  .lg\:leading-loose {
    line-height: 2;
  }
  .lg\:tracking-tighter {
    letter-spacing: -0.05em;
  }
  .lg\:tracking-tight {
    letter-spacing: -0.025em;
  }
  .lg\:tracking-normal {
    letter-spacing: 0;
  }
  .lg\:tracking-wide {
    letter-spacing: 0.025em;
  }
  .lg\:tracking-wider {
    letter-spacing: 0.05em;
  }
  .lg\:tracking-widest {
    letter-spacing: 0.1em;
  }
  .lg\:text-transparent {
    color: transparent;
  }
  .lg\:text-current {
    color: currentColor;
  }
  .lg\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .lg\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .lg\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .lg\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .lg\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .lg\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .lg\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .lg\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .lg\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .lg\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .lg\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .lg\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .lg\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .lg\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .lg\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .lg\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .lg\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .lg\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .lg\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .lg\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .lg\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .lg\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .lg\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .lg\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .lg\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .lg\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .lg\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .lg\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .lg\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .lg\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .lg\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .lg\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .lg\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .lg\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .lg\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .lg\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .lg\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .lg\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .lg\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .lg\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .lg\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .lg\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .lg\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .lg\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .lg\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .lg\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .lg\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .lg\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .lg\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .lg\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .lg\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .lg\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .lg\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .lg\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .lg\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .lg\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .lg\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .lg\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .lg\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .lg\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .lg\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .lg\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .lg\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .lg\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .lg\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .lg\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .lg\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .lg\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .lg\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .lg\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .lg\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .lg\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .lg\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .lg\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .lg\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .lg\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .lg\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .lg\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .lg\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .lg\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .lg\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .lg\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-transparent {
    color: transparent;
  }
  .group:hover .lg\:group-hover\:text-current {
    color: currentColor;
  }
  .group:hover .lg\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .group:hover .lg\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-transparent:focus-within {
    color: transparent;
  }
  .lg\:focus-within\:text-current:focus-within {
    color: currentColor;
  }
  .lg\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .lg\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .lg\:hover\:text-transparent:hover {
    color: transparent;
  }
  .lg\:hover\:text-current:hover {
    color: currentColor;
  }
  .lg\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .lg\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .lg\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .lg\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .lg\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .lg\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .lg\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .lg\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .lg\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .lg\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .lg\:focus\:text-transparent:focus {
    color: transparent;
  }
  .lg\:focus\:text-current:focus {
    color: currentColor;
  }
  .lg\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .lg\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .lg\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .lg\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .lg\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .lg\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .lg\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .lg\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .lg\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .lg\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .lg\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .lg\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .lg\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .lg\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .lg\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .lg\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .lg\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .lg\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .lg\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .lg\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .lg\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .lg\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .lg\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .lg\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .lg\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .group:hover .lg\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .group:hover .lg\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .group:hover .lg\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .group:hover .lg\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .group:hover .lg\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .group:hover .lg\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .group:hover .lg\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .group:hover .lg\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .group:hover .lg\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .group:hover .lg\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .group:hover .lg\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .group:hover .lg\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .group:hover .lg\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .group:hover .lg\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .group:hover .lg\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .lg\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }
  .lg\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }
  .lg\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }
  .lg\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }
  .lg\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }
  .lg\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }
  .lg\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }
  .lg\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }
  .lg\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }
  .lg\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }
  .lg\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }
  .lg\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }
  .lg\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }
  .lg\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }
  .lg\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }
  .lg\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }
  .lg\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }
  .lg\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }
  .lg\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }
  .lg\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }
  .lg\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }
  .lg\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }
  .lg\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }
  .lg\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }
  .lg\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }
  .lg\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }
  .lg\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }
  .lg\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }
  .lg\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }
  .lg\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }
  .lg\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }
  .lg\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }
  .lg\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }
  .lg\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }
  .lg\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }
  .lg\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }
  .lg\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }
  .lg\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }
  .lg\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }
  .lg\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }
  .lg\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }
  .lg\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }
  .lg\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }
  .lg\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }
  .lg\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }
  .lg\:underline {
    text-decoration: underline;
  }
  .lg\:line-through {
    text-decoration: line-through;
  }
  .lg\:no-underline {
    text-decoration: none;
  }
  .group:hover .lg\:group-hover\:underline {
    text-decoration: underline;
  }
  .group:hover .lg\:group-hover\:line-through {
    text-decoration: line-through;
  }
  .group:hover .lg\:group-hover\:no-underline {
    text-decoration: none;
  }
  .lg\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }
  .lg\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }
  .lg\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }
  .lg\:hover\:underline:hover {
    text-decoration: underline;
  }
  .lg\:hover\:line-through:hover {
    text-decoration: line-through;
  }
  .lg\:hover\:no-underline:hover {
    text-decoration: none;
  }
  .lg\:focus\:underline:focus {
    text-decoration: underline;
  }
  .lg\:focus\:line-through:focus {
    text-decoration: line-through;
  }
  .lg\:focus\:no-underline:focus {
    text-decoration: none;
  }
  .lg\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .lg\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  .lg\:placeholder-transparent::placeholder {
    color: transparent;
  }
  .lg\:placeholder-current::placeholder {
    color: currentColor;
  }
  .lg\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }
  .lg\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }
  .lg\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .lg\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .lg\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .lg\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .lg\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .lg\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .lg\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .lg\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .lg\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .lg\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .lg\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .lg\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .lg\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .lg\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .lg\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .lg\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .lg\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .lg\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .lg\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .lg\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .lg\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .lg\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .lg\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .lg\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .lg\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .lg\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .lg\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .lg\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .lg\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .lg\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .lg\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .lg\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .lg\:opacity-0 {
    opacity: 0;
  }
  .lg\:opacity-5 {
    opacity: 0.05;
  }
  .lg\:opacity-10 {
    opacity: 0.1;
  }
  .lg\:opacity-20 {
    opacity: 0.2;
  }
  .lg\:opacity-25 {
    opacity: 0.25;
  }
  .lg\:opacity-30 {
    opacity: 0.3;
  }
  .lg\:opacity-40 {
    opacity: 0.4;
  }
  .lg\:opacity-50 {
    opacity: 0.5;
  }
  .lg\:opacity-60 {
    opacity: 0.6;
  }
  .lg\:opacity-70 {
    opacity: 0.7;
  }
  .lg\:opacity-75 {
    opacity: 0.75;
  }
  .lg\:opacity-80 {
    opacity: 0.8;
  }
  .lg\:opacity-90 {
    opacity: 0.9;
  }
  .lg\:opacity-95 {
    opacity: 0.95;
  }
  .lg\:opacity-100 {
    opacity: 1;
  }
  .group:hover .lg\:group-hover\:opacity-0 {
    opacity: 0;
  }
  .group:hover .lg\:group-hover\:opacity-5 {
    opacity: 0.05;
  }
  .group:hover .lg\:group-hover\:opacity-10 {
    opacity: 0.1;
  }
  .group:hover .lg\:group-hover\:opacity-20 {
    opacity: 0.2;
  }
  .group:hover .lg\:group-hover\:opacity-25 {
    opacity: 0.25;
  }
  .group:hover .lg\:group-hover\:opacity-30 {
    opacity: 0.3;
  }
  .group:hover .lg\:group-hover\:opacity-40 {
    opacity: 0.4;
  }
  .group:hover .lg\:group-hover\:opacity-50 {
    opacity: 0.5;
  }
  .group:hover .lg\:group-hover\:opacity-60 {
    opacity: 0.6;
  }
  .group:hover .lg\:group-hover\:opacity-70 {
    opacity: 0.7;
  }
  .group:hover .lg\:group-hover\:opacity-75 {
    opacity: 0.75;
  }
  .group:hover .lg\:group-hover\:opacity-80 {
    opacity: 0.8;
  }
  .group:hover .lg\:group-hover\:opacity-90 {
    opacity: 0.9;
  }
  .group:hover .lg\:group-hover\:opacity-95 {
    opacity: 0.95;
  }
  .group:hover .lg\:group-hover\:opacity-100 {
    opacity: 1;
  }
  .lg\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }
  .lg\:focus-within\:opacity-5:focus-within {
    opacity: 0.05;
  }
  .lg\:focus-within\:opacity-10:focus-within {
    opacity: 0.1;
  }
  .lg\:focus-within\:opacity-20:focus-within {
    opacity: 0.2;
  }
  .lg\:focus-within\:opacity-25:focus-within {
    opacity: 0.25;
  }
  .lg\:focus-within\:opacity-30:focus-within {
    opacity: 0.3;
  }
  .lg\:focus-within\:opacity-40:focus-within {
    opacity: 0.4;
  }
  .lg\:focus-within\:opacity-50:focus-within {
    opacity: 0.5;
  }
  .lg\:focus-within\:opacity-60:focus-within {
    opacity: 0.6;
  }
  .lg\:focus-within\:opacity-70:focus-within {
    opacity: 0.7;
  }
  .lg\:focus-within\:opacity-75:focus-within {
    opacity: 0.75;
  }
  .lg\:focus-within\:opacity-80:focus-within {
    opacity: 0.8;
  }
  .lg\:focus-within\:opacity-90:focus-within {
    opacity: 0.9;
  }
  .lg\:focus-within\:opacity-95:focus-within {
    opacity: 0.95;
  }
  .lg\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }
  .lg\:hover\:opacity-0:hover {
    opacity: 0;
  }
  .lg\:hover\:opacity-5:hover {
    opacity: 0.05;
  }
  .lg\:hover\:opacity-10:hover {
    opacity: 0.1;
  }
  .lg\:hover\:opacity-20:hover {
    opacity: 0.2;
  }
  .lg\:hover\:opacity-25:hover {
    opacity: 0.25;
  }
  .lg\:hover\:opacity-30:hover {
    opacity: 0.3;
  }
  .lg\:hover\:opacity-40:hover {
    opacity: 0.4;
  }
  .lg\:hover\:opacity-50:hover {
    opacity: 0.5;
  }
  .lg\:hover\:opacity-60:hover {
    opacity: 0.6;
  }
  .lg\:hover\:opacity-70:hover {
    opacity: 0.7;
  }
  .lg\:hover\:opacity-75:hover {
    opacity: 0.75;
  }
  .lg\:hover\:opacity-80:hover {
    opacity: 0.8;
  }
  .lg\:hover\:opacity-90:hover {
    opacity: 0.9;
  }
  .lg\:hover\:opacity-95:hover {
    opacity: 0.95;
  }
  .lg\:hover\:opacity-100:hover {
    opacity: 1;
  }
  .lg\:focus\:opacity-0:focus {
    opacity: 0;
  }
  .lg\:focus\:opacity-5:focus {
    opacity: 0.05;
  }
  .lg\:focus\:opacity-10:focus {
    opacity: 0.1;
  }
  .lg\:focus\:opacity-20:focus {
    opacity: 0.2;
  }
  .lg\:focus\:opacity-25:focus {
    opacity: 0.25;
  }
  .lg\:focus\:opacity-30:focus {
    opacity: 0.3;
  }
  .lg\:focus\:opacity-40:focus {
    opacity: 0.4;
  }
  .lg\:focus\:opacity-50:focus {
    opacity: 0.5;
  }
  .lg\:focus\:opacity-60:focus {
    opacity: 0.6;
  }
  .lg\:focus\:opacity-70:focus {
    opacity: 0.7;
  }
  .lg\:focus\:opacity-75:focus {
    opacity: 0.75;
  }
  .lg\:focus\:opacity-80:focus {
    opacity: 0.8;
  }
  .lg\:focus\:opacity-90:focus {
    opacity: 0.9;
  }
  .lg\:focus\:opacity-95:focus {
    opacity: 0.95;
  }
  .lg\:focus\:opacity-100:focus {
    opacity: 1;
  }
  .lg\:bg-blend-normal {
    background-blend-mode: normal;
  }
  .lg\:bg-blend-multiply {
    background-blend-mode: multiply;
  }
  .lg\:bg-blend-screen {
    background-blend-mode: screen;
  }
  .lg\:bg-blend-overlay {
    background-blend-mode: overlay;
  }
  .lg\:bg-blend-darken {
    background-blend-mode: darken;
  }
  .lg\:bg-blend-lighten {
    background-blend-mode: lighten;
  }
  .lg\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }
  .lg\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }
  .lg\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }
  .lg\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }
  .lg\:bg-blend-difference {
    background-blend-mode: difference;
  }
  .lg\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }
  .lg\:bg-blend-hue {
    background-blend-mode: hue;
  }
  .lg\:bg-blend-saturation {
    background-blend-mode: saturation;
  }
  .lg\:bg-blend-color {
    background-blend-mode: color;
  }
  .lg\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }
  .lg\:mix-blend-normal {
    mix-blend-mode: normal;
  }
  .lg\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }
  .lg\:mix-blend-screen {
    mix-blend-mode: screen;
  }
  .lg\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }
  .lg\:mix-blend-darken {
    mix-blend-mode: darken;
  }
  .lg\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }
  .lg\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }
  .lg\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }
  .lg\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }
  .lg\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }
  .lg\:mix-blend-difference {
    mix-blend-mode: difference;
  }
  .lg\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }
  .lg\:mix-blend-hue {
    mix-blend-mode: hue;
  }
  .lg\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }
  .lg\:mix-blend-color {
    mix-blend-mode: color;
  }
  .lg\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }
  .lg\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .lg\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .lg\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .lg\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .lg\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .lg\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .lg\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .lg\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .lg\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .lg\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .lg\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }
  .lg\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }
  .lg\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .lg\:focus-within\:outline-white:focus-within {
    outline: 2px dotted white;
    outline-offset: 2px;
  }
  .lg\:focus-within\:outline-black:focus-within {
    outline: 2px dotted black;
    outline-offset: 2px;
  }
  .lg\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .lg\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }
  .lg\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }
  .lg\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .lg\:ring-inset {
    --tw-ring-inset: inset;
  }
  .lg\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }
  .lg\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }
  .lg\:ring-transparent {
    --tw-ring-color: transparent;
  }
  .lg\:ring-current {
    --tw-ring-color: currentColor;
  }
  .lg\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .lg\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .lg\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }
  .lg\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .lg\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .lg\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .lg\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .lg\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .lg\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .lg\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .lg\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .lg\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .lg\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .lg\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .lg\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .lg\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .lg\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .lg\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .lg\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .lg\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .lg\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .lg\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .lg\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .lg\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .lg\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .lg\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .lg\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .lg\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }
  .lg\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }
  .lg\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .lg\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }
  .lg\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }
  .lg\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .lg\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .lg\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }
  .lg\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }
  .lg\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }
  .lg\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }
  .lg\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }
  .lg\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }
  .lg\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }
  .lg\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }
  .lg\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }
  .lg\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }
  .lg\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }
  .lg\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }
  .lg\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }
  .lg\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }
  .lg\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }
  .lg\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }
  .lg\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }
  .lg\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }
  .lg\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }
  .lg\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }
  .lg\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }
  .lg\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }
  .lg\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }
  .lg\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }
  .lg\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }
  .lg\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }
  .lg\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }
  .lg\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }
  .lg\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }
  .lg\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }
  .lg\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }
  .lg\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }
  .lg\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }
  .lg\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }
  .lg\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }
  .lg\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }
  .lg\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }
  .lg\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }
  .lg\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }
  .lg\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }
  .lg\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }
  .lg\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }
  .lg\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }
  .lg\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }
  .lg\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }
  .lg\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }
  .lg\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }
  .lg\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }
  .lg\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }
  .lg\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }
  .lg\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }
  .lg\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }
  .lg\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }
  .lg\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }
  .lg\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }
  .lg\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }
  .lg\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }
  .lg\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }
  .lg\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }
  .lg\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }
  .lg\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }
  .lg\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }
  .lg\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }
  .lg\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }
  .lg\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }
  .lg\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }
  .lg\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }
  .lg\:ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }
  .lg\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }
  .lg\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }
  .lg\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }
  .lg\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }
  .lg\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }
  .lg\:ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }
  .lg\:ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }
  .lg\:ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }
  .lg\:ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }
  .lg\:ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }
  .lg\:ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }
  .lg\:ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }
  .lg\:ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }
  .lg\:ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }
  .lg\:ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }
  .lg\:ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }
  .lg\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }
  .lg\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }
  .lg\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }
  .lg\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }
  .lg\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }
  .lg\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }
  .lg\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }
  .lg\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }
  .lg\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }
  .lg\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }
  .lg\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }
  .lg\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }
  .lg\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }
  .lg\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }
  .lg\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }
  .lg\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }
  .lg\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }
  .lg\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }
  .lg\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }
  .lg\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }
  .lg\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }
  .lg\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }
  .lg\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }
  .lg\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }
  .lg\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }
  .lg\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }
  .lg\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }
  .lg\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }
  .lg\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }
  .lg\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }
  .lg\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }
  .lg\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }
  .lg\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }
  .lg\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }
  .lg\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }
  .lg\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }
  .lg\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }
  .lg\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }
  .lg\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }
  .lg\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }
  .lg\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }
  .lg\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }
  .lg\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }
  .lg\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }
  .lg\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }
  .lg\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }
  .lg\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }
  .lg\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }
  .lg\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }
  .lg\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }
  .lg\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }
  .lg\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }
  .lg\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }
  .lg\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }
  .lg\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }
  .lg\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }
  .lg\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }
  .lg\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }
  .lg\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }
  .lg\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }
  .lg\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }
  .lg\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }
  .lg\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }
  .lg\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }
  .lg\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }
  .lg\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }
  .lg\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .lg\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }
  .lg\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }
  .lg\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }
  .lg\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }
  .lg\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }
  .lg\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }
  .lg\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }
  .lg\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }
  .lg\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }
  .lg\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }
  .lg\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }
  .lg\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }
  .lg\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }
  .lg\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }
  .lg\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }
  .lg\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }
  .lg\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }
  .lg\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }
  .lg\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }
  .lg\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }
  .lg\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }
  .lg\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }
  .lg\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }
  .lg\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }
  .lg\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }
  .lg\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }
  .lg\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }
  .lg\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }
  .lg\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }
  .lg\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }
  .lg\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }
  .lg\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }
  .lg\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }
  .lg\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }
  .lg\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }
  .lg\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }
  .lg\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }
  .lg\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }
  .lg\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }
  .lg\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }
  .lg\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }
  .lg\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }
  .lg\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }
  .lg\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }
  .lg\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }
  .lg\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }
  .lg\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }
  .lg\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }
  .lg\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }
  .lg\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }
  .lg\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }
  .lg\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }
  .lg\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }
  .lg\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }
  .lg\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }
  .lg\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }
  .lg\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }
  .lg\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }
  .lg\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }
  .lg\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }
  .lg\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }
  .lg\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }
  .lg\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }
  .lg\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }
  .lg\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }
  .lg\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }
  .lg\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }
  .lg\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }
  .lg\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }
  .lg\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }
  .lg\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }
  .lg\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }
  .lg\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }
  .lg\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }
  .lg\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }
  .lg\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }
  .lg\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }
  .lg\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }
  .lg\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }
  .lg\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }
  .lg\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }
  .lg\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }
  .lg\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }
  .lg\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .lg\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }
  .lg\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }
  .lg\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }
  .lg\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }
  .lg\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }
  .lg\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }
  .lg\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }
  .lg\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }
  .lg\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }
  .lg\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }
  .lg\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }
  .lg\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }
  .lg\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }
  .lg\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }
  .lg\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }
  .lg\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }
  .lg\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }
  .lg\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }
  .lg\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }
  .lg\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }
  .lg\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }
  .lg\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }
  .lg\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }
  .lg\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }
  .lg\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }
  .lg\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }
  .lg\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }
  .lg\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }
  .lg\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }
  .lg\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }
  .lg\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }
  .lg\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }
  .lg\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }
  .lg\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }
  .lg\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }
  .lg\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }
  .lg\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }
  .lg\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }
  .lg\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }
  .lg\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }
  .lg\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }
  .lg\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }
  .lg\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }
  .lg\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }
  .lg\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }
  .lg\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }
  .lg\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }
  .lg\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }
  .lg\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }
  .lg\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }
  .lg\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }
  .lg\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }
  .lg\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }
  .lg\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }
  .lg\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }
  .lg\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }
  .lg\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }
  .lg\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }
  .lg\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }
  .lg\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }
  .lg\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }
  .lg\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }
  .lg\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }
  .lg\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }
  .lg\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }
  .lg\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }
  .lg\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }
  .lg\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }
  .lg\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }
  .lg\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }
  .lg\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }
  .lg\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }
  .lg\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }
  .lg\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }
  .lg\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }
  .lg\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }
  .lg\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }
  .lg\:filter {
    --tw-blur: var(--tw-empty); /*!*/ /*!*/
    --tw-brightness: var(--tw-empty); /*!*/ /*!*/
    --tw-contrast: var(--tw-empty); /*!*/ /*!*/
    --tw-grayscale: var(--tw-empty); /*!*/ /*!*/
    --tw-hue-rotate: var(--tw-empty); /*!*/ /*!*/
    --tw-invert: var(--tw-empty); /*!*/ /*!*/
    --tw-saturate: var(--tw-empty); /*!*/ /*!*/
    --tw-sepia: var(--tw-empty); /*!*/ /*!*/
    --tw-drop-shadow: var(--tw-empty); /*!*/ /*!*/
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .lg\:filter-none {
    filter: none;
  }
  .lg\:blur-0 {
    --tw-blur: blur(0);
  }
  .lg\:blur-none {
    --tw-blur: blur(0);
  }
  .lg\:blur-sm {
    --tw-blur: blur(4px);
  }
  .lg\:blur {
    --tw-blur: blur(8px);
  }
  .lg\:blur-md {
    --tw-blur: blur(12px);
  }
  .lg\:blur-lg {
    --tw-blur: blur(16px);
  }
  .lg\:blur-xl {
    --tw-blur: blur(24px);
  }
  .lg\:blur-2xl {
    --tw-blur: blur(40px);
  }
  .lg\:blur-3xl {
    --tw-blur: blur(64px);
  }
  .lg\:brightness-0 {
    --tw-brightness: brightness(0);
  }
  .lg\:brightness-50 {
    --tw-brightness: brightness(0.5);
  }
  .lg\:brightness-75 {
    --tw-brightness: brightness(0.75);
  }
  .lg\:brightness-90 {
    --tw-brightness: brightness(0.9);
  }
  .lg\:brightness-95 {
    --tw-brightness: brightness(0.95);
  }
  .lg\:brightness-100 {
    --tw-brightness: brightness(1);
  }
  .lg\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }
  .lg\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }
  .lg\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }
  .lg\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }
  .lg\:brightness-200 {
    --tw-brightness: brightness(2);
  }
  .lg\:contrast-0 {
    --tw-contrast: contrast(0);
  }
  .lg\:contrast-50 {
    --tw-contrast: contrast(0.5);
  }
  .lg\:contrast-75 {
    --tw-contrast: contrast(0.75);
  }
  .lg\:contrast-100 {
    --tw-contrast: contrast(1);
  }
  .lg\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }
  .lg\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }
  .lg\:contrast-200 {
    --tw-contrast: contrast(2);
  }
  .lg\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.05));
  }
  .lg\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1))
      drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }
  .lg\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07))
      drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }
  .lg\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04))
      drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }
  .lg\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03))
      drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }
  .lg\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }
  .lg\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }
  .lg\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }
  .lg\:grayscale {
    --tw-grayscale: grayscale(100%);
  }
  .lg\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }
  .lg\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }
  .lg\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }
  .lg\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }
  .lg\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }
  .lg\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }
  .lg\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }
  .lg\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }
  .lg\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }
  .lg\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }
  .lg\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }
  .lg\:invert-0 {
    --tw-invert: invert(0);
  }
  .lg\:invert {
    --tw-invert: invert(100%);
  }
  .lg\:saturate-0 {
    --tw-saturate: saturate(0);
  }
  .lg\:saturate-50 {
    --tw-saturate: saturate(0.5);
  }
  .lg\:saturate-100 {
    --tw-saturate: saturate(1);
  }
  .lg\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }
  .lg\:saturate-200 {
    --tw-saturate: saturate(2);
  }
  .lg\:sepia-0 {
    --tw-sepia: sepia(0);
  }
  .lg\:sepia {
    --tw-sepia: sepia(100%);
  }
  .lg\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-brightness: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-contrast: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-grayscale: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-hue-rotate: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-invert: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-opacity: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-saturate: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-sepia: var(--tw-empty); /*!*/ /*!*/
    -webkit-backdrop-filter: var(--tw-backdrop-blur)
      var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
      var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate)
      var(--tw-backdrop-invert) var(--tw-backdrop-opacity)
      var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
      var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
      var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
      var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
      var(--tw-backdrop-sepia);
  }
  .lg\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }
  .lg\:backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
  }
  .lg\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }
  .lg\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }
  .lg\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }
  .lg\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }
  .lg\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }
  .lg\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }
  .lg\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }
  .lg\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }
  .lg\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }
  .lg\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(0.5);
  }
  .lg\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(0.75);
  }
  .lg\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(0.9);
  }
  .lg\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(0.95);
  }
  .lg\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }
  .lg\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }
  .lg\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }
  .lg\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }
  .lg\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }
  .lg\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }
  .lg\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }
  .lg\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(0.5);
  }
  .lg\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(0.75);
  }
  .lg\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }
  .lg\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }
  .lg\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }
  .lg\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }
  .lg\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }
  .lg\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }
  .lg\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }
  .lg\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }
  .lg\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }
  .lg\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }
  .lg\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }
  .lg\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }
  .lg\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }
  .lg\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }
  .lg\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }
  .lg\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }
  .lg\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }
  .lg\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }
  .lg\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }
  .lg\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }
  .lg\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(0.05);
  }
  .lg\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(0.1);
  }
  .lg\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(0.2);
  }
  .lg\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(0.25);
  }
  .lg\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(0.3);
  }
  .lg\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(0.4);
  }
  .lg\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(0.5);
  }
  .lg\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(0.6);
  }
  .lg\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(0.7);
  }
  .lg\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(0.75);
  }
  .lg\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(0.8);
  }
  .lg\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(0.9);
  }
  .lg\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(0.95);
  }
  .lg\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }
  .lg\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }
  .lg\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(0.5);
  }
  .lg\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }
  .lg\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }
  .lg\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }
  .lg\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }
  .lg\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }
  .lg\:transition-none {
    transition-property: none;
  }
  .lg\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .lg\:transition {
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, backdrop-filter,
      -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .lg\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .lg\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .lg\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .lg\:transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .lg\:delay-75 {
    transition-delay: 75ms;
  }
  .lg\:delay-100 {
    transition-delay: 0.1s;
  }
  .lg\:delay-150 {
    transition-delay: 150ms;
  }
  .lg\:delay-200 {
    transition-delay: 0.2s;
  }
  .lg\:delay-300 {
    transition-delay: 0.3s;
  }
  .lg\:delay-500 {
    transition-delay: 0.5s;
  }
  .lg\:delay-700 {
    transition-delay: 0.7s;
  }
  .lg\:delay-1000 {
    transition-delay: 1s;
  }
  .lg\:duration-75 {
    transition-duration: 75ms;
  }
  .lg\:duration-100 {
    transition-duration: 0.1s;
  }
  .lg\:duration-150 {
    transition-duration: 150ms;
  }
  .lg\:duration-200 {
    transition-duration: 0.2s;
  }
  .lg\:duration-300 {
    transition-duration: 0.3s;
  }
  .lg\:duration-500 {
    transition-duration: 0.5s;
  }
  .lg\:duration-700 {
    transition-duration: 0.7s;
  }
  .lg\:duration-1000 {
    transition-duration: 1s;
  }
  .lg\:ease-linear {
    transition-timing-function: linear;
  }
  .lg\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .lg\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .lg\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}
@media (min-width: 1280px) {
  .xl\:container {
    width: 100%;
  }
  @media (min-width: 640px) {
    .xl\:container {
      max-width: 640px;
    }
  }
  @media (min-width: 768px) {
    .xl\:container {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
    .xl\:container {
      max-width: 1024px;
    }
  }
  @media (min-width: 1280px) {
    .xl\:container {
      max-width: 1280px;
    }
  }
  @media (min-width: 1536px) {
    .xl\:container {
      max-width: 1536px;
    }
  }
  .xl\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .xl\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .xl\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .xl\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .xl\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .xl\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .xl\:pointer-events-none {
    pointer-events: none;
  }
  .xl\:pointer-events-auto {
    pointer-events: auto;
  }
  .xl\:visible {
    visibility: visible;
  }
  .xl\:invisible {
    visibility: hidden;
  }
  .xl\:static {
    position: static;
  }
  .xl\:fixed {
    position: fixed;
  }
  .xl\:absolute {
    position: absolute;
  }
  .xl\:relative {
    position: relative;
  }
  .xl\:sticky {
    position: sticky;
  }
  .xl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .xl\:inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }
  .xl\:inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }
  .xl\:inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }
  .xl\:inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
  .xl\:inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }
  .xl\:inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }
  .xl\:inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }
  .xl\:inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }
  .xl\:inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }
  .xl\:inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }
  .xl\:inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }
  .xl\:inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }
  .xl\:inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }
  .xl\:inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }
  .xl\:inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }
  .xl\:inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }
  .xl\:inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }
  .xl\:inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }
  .xl\:inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }
  .xl\:inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }
  .xl\:inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }
  .xl\:inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }
  .xl\:inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }
  .xl\:inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }
  .xl\:inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }
  .xl\:inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }
  .xl\:inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }
  .xl\:inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }
  .xl\:inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }
  .xl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .xl\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }
  .xl\:inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }
  .xl\:inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }
  .xl\:inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }
  .xl\:inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }
  .xl\:-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .xl\:-inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }
  .xl\:-inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }
  .xl\:-inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }
  .xl\:-inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }
  .xl\:-inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }
  .xl\:-inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }
  .xl\:-inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }
  .xl\:-inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }
  .xl\:-inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }
  .xl\:-inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }
  .xl\:-inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }
  .xl\:-inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }
  .xl\:-inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }
  .xl\:-inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }
  .xl\:-inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }
  .xl\:-inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }
  .xl\:-inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }
  .xl\:-inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }
  .xl\:-inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }
  .xl\:-inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }
  .xl\:-inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }
  .xl\:-inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }
  .xl\:-inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }
  .xl\:-inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }
  .xl\:-inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }
  .xl\:-inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }
  .xl\:-inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }
  .xl\:-inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }
  .xl\:-inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }
  .xl\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }
  .xl\:-inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }
  .xl\:-inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }
  .xl\:-inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }
  .xl\:-inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }
  .xl\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .xl\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }
  .xl\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }
  .xl\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }
  .xl\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .xl\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }
  .xl\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }
  .xl\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .xl\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }
  .xl\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }
  .xl\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }
  .xl\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .xl\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }
  .xl\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }
  .xl\:inset-x-0 {
    left: 0;
    right: 0;
  }
  .xl\:inset-x-1 {
    left: 0.25rem;
    right: 0.25rem;
  }
  .xl\:inset-x-2 {
    left: 0.5rem;
    right: 0.5rem;
  }
  .xl\:inset-x-3 {
    left: 0.75rem;
    right: 0.75rem;
  }
  .xl\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }
  .xl\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }
  .xl\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }
  .xl\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }
  .xl\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }
  .xl\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }
  .xl\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }
  .xl\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }
  .xl\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }
  .xl\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }
  .xl\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }
  .xl\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }
  .xl\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }
  .xl\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }
  .xl\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }
  .xl\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }
  .xl\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }
  .xl\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }
  .xl\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }
  .xl\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }
  .xl\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }
  .xl\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }
  .xl\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }
  .xl\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }
  .xl\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }
  .xl\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }
  .xl\:inset-x-auto {
    left: auto;
    right: auto;
  }
  .xl\:inset-x-px {
    left: 1px;
    right: 1px;
  }
  .xl\:inset-x-0\.5 {
    left: 0.125rem;
    right: 0.125rem;
  }
  .xl\:inset-x-1\.5 {
    left: 0.375rem;
    right: 0.375rem;
  }
  .xl\:inset-x-2\.5 {
    left: 0.625rem;
    right: 0.625rem;
  }
  .xl\:inset-x-3\.5 {
    left: 0.875rem;
    right: 0.875rem;
  }
  .xl\:-inset-x-0 {
    left: 0;
    right: 0;
  }
  .xl\:-inset-x-1 {
    left: -0.25rem;
    right: -0.25rem;
  }
  .xl\:-inset-x-2 {
    left: -0.5rem;
    right: -0.5rem;
  }
  .xl\:-inset-x-3 {
    left: -0.75rem;
    right: -0.75rem;
  }
  .xl\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }
  .xl\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }
  .xl\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }
  .xl\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }
  .xl\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }
  .xl\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }
  .xl\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }
  .xl\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }
  .xl\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }
  .xl\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }
  .xl\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }
  .xl\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }
  .xl\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }
  .xl\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }
  .xl\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }
  .xl\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }
  .xl\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }
  .xl\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }
  .xl\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }
  .xl\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }
  .xl\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }
  .xl\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }
  .xl\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }
  .xl\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }
  .xl\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }
  .xl\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }
  .xl\:-inset-x-px {
    left: -1px;
    right: -1px;
  }
  .xl\:-inset-x-0\.5 {
    left: -0.125rem;
    right: -0.125rem;
  }
  .xl\:-inset-x-1\.5 {
    left: -0.375rem;
    right: -0.375rem;
  }
  .xl\:-inset-x-2\.5 {
    left: -0.625rem;
    right: -0.625rem;
  }
  .xl\:-inset-x-3\.5 {
    left: -0.875rem;
    right: -0.875rem;
  }
  .xl\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }
  .xl\:inset-x-1\/3 {
    left: 33.333333%;
    right: 33.333333%;
  }
  .xl\:inset-x-2\/3 {
    left: 66.666667%;
    right: 66.666667%;
  }
  .xl\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }
  .xl\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }
  .xl\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }
  .xl\:inset-x-full {
    left: 100%;
    right: 100%;
  }
  .xl\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }
  .xl\:-inset-x-1\/3 {
    left: -33.333333%;
    right: -33.333333%;
  }
  .xl\:-inset-x-2\/3 {
    left: -66.666667%;
    right: -66.666667%;
  }
  .xl\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }
  .xl\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }
  .xl\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }
  .xl\:-inset-x-full {
    left: -100%;
    right: -100%;
  }
  .xl\:inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .xl\:inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }
  .xl\:inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }
  .xl\:inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }
  .xl\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }
  .xl\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }
  .xl\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  .xl\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }
  .xl\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }
  .xl\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }
  .xl\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }
  .xl\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }
  .xl\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }
  .xl\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }
  .xl\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }
  .xl\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }
  .xl\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }
  .xl\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }
  .xl\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }
  .xl\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }
  .xl\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }
  .xl\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }
  .xl\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }
  .xl\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }
  .xl\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }
  .xl\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }
  .xl\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }
  .xl\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }
  .xl\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }
  .xl\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }
  .xl\:inset-y-auto {
    top: auto;
    bottom: auto;
  }
  .xl\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }
  .xl\:inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }
  .xl\:inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }
  .xl\:inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }
  .xl\:inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }
  .xl\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .xl\:-inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }
  .xl\:-inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }
  .xl\:-inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }
  .xl\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }
  .xl\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }
  .xl\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }
  .xl\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }
  .xl\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }
  .xl\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }
  .xl\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }
  .xl\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }
  .xl\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }
  .xl\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }
  .xl\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }
  .xl\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }
  .xl\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }
  .xl\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }
  .xl\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }
  .xl\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }
  .xl\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }
  .xl\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }
  .xl\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }
  .xl\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }
  .xl\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }
  .xl\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }
  .xl\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }
  .xl\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }
  .xl\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }
  .xl\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }
  .xl\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }
  .xl\:-inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }
  .xl\:-inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }
  .xl\:-inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }
  .xl\:-inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }
  .xl\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }
  .xl\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }
  .xl\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }
  .xl\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }
  .xl\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }
  .xl\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }
  .xl\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }
  .xl\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }
  .xl\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }
  .xl\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }
  .xl\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }
  .xl\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }
  .xl\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }
  .xl\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }
  .xl\:top-0 {
    top: 0;
  }
  .xl\:top-1 {
    top: 0.25rem;
  }
  .xl\:top-2 {
    top: 0.5rem;
  }
  .xl\:top-3 {
    top: 0.75rem;
  }
  .xl\:top-4 {
    top: 1rem;
  }
  .xl\:top-5 {
    top: 1.25rem;
  }
  .xl\:top-6 {
    top: 1.5rem;
  }
  .xl\:top-7 {
    top: 1.75rem;
  }
  .xl\:top-8 {
    top: 2rem;
  }
  .xl\:top-9 {
    top: 2.25rem;
  }
  .xl\:top-10 {
    top: 2.5rem;
  }
  .xl\:top-11 {
    top: 2.75rem;
  }
  .xl\:top-12 {
    top: 3rem;
  }
  .xl\:top-14 {
    top: 3.5rem;
  }
  .xl\:top-16 {
    top: 4rem;
  }
  .xl\:top-20 {
    top: 5rem;
  }
  .xl\:top-24 {
    top: 6rem;
  }
  .xl\:top-28 {
    top: 7rem;
  }
  .xl\:top-32 {
    top: 8rem;
  }
  .xl\:top-36 {
    top: 9rem;
  }
  .xl\:top-40 {
    top: 10rem;
  }
  .xl\:top-44 {
    top: 11rem;
  }
  .xl\:top-48 {
    top: 12rem;
  }
  .xl\:top-52 {
    top: 13rem;
  }
  .xl\:top-56 {
    top: 14rem;
  }
  .xl\:top-60 {
    top: 15rem;
  }
  .xl\:top-64 {
    top: 16rem;
  }
  .xl\:top-72 {
    top: 18rem;
  }
  .xl\:top-80 {
    top: 20rem;
  }
  .xl\:top-96 {
    top: 24rem;
  }
  .xl\:top-auto {
    top: auto;
  }
  .xl\:top-px {
    top: 1px;
  }
  .xl\:top-0\.5 {
    top: 0.125rem;
  }
  .xl\:top-1\.5 {
    top: 0.375rem;
  }
  .xl\:top-2\.5 {
    top: 0.625rem;
  }
  .xl\:top-3\.5 {
    top: 0.875rem;
  }
  .xl\:-top-0 {
    top: 0;
  }
  .xl\:-top-1 {
    top: -0.25rem;
  }
  .xl\:-top-2 {
    top: -0.5rem;
  }
  .xl\:-top-3 {
    top: -0.75rem;
  }
  .xl\:-top-4 {
    top: -1rem;
  }
  .xl\:-top-5 {
    top: -1.25rem;
  }
  .xl\:-top-6 {
    top: -1.5rem;
  }
  .xl\:-top-7 {
    top: -1.75rem;
  }
  .xl\:-top-8 {
    top: -2rem;
  }
  .xl\:-top-9 {
    top: -2.25rem;
  }
  .xl\:-top-10 {
    top: -2.5rem;
  }
  .xl\:-top-11 {
    top: -2.75rem;
  }
  .xl\:-top-12 {
    top: -3rem;
  }
  .xl\:-top-14 {
    top: -3.5rem;
  }
  .xl\:-top-16 {
    top: -4rem;
  }
  .xl\:-top-20 {
    top: -5rem;
  }
  .xl\:-top-24 {
    top: -6rem;
  }
  .xl\:-top-28 {
    top: -7rem;
  }
  .xl\:-top-32 {
    top: -8rem;
  }
  .xl\:-top-36 {
    top: -9rem;
  }
  .xl\:-top-40 {
    top: -10rem;
  }
  .xl\:-top-44 {
    top: -11rem;
  }
  .xl\:-top-48 {
    top: -12rem;
  }
  .xl\:-top-52 {
    top: -13rem;
  }
  .xl\:-top-56 {
    top: -14rem;
  }
  .xl\:-top-60 {
    top: -15rem;
  }
  .xl\:-top-64 {
    top: -16rem;
  }
  .xl\:-top-72 {
    top: -18rem;
  }
  .xl\:-top-80 {
    top: -20rem;
  }
  .xl\:-top-96 {
    top: -24rem;
  }
  .xl\:-top-px {
    top: -1px;
  }
  .xl\:-top-0\.5 {
    top: -0.125rem;
  }
  .xl\:-top-1\.5 {
    top: -0.375rem;
  }
  .xl\:-top-2\.5 {
    top: -0.625rem;
  }
  .xl\:-top-3\.5 {
    top: -0.875rem;
  }
  .xl\:top-1\/2 {
    top: 50%;
  }
  .xl\:top-1\/3 {
    top: 33.333333%;
  }
  .xl\:top-2\/3 {
    top: 66.666667%;
  }
  .xl\:top-1\/4 {
    top: 25%;
  }
  .xl\:top-2\/4 {
    top: 50%;
  }
  .xl\:top-3\/4 {
    top: 75%;
  }
  .xl\:top-full {
    top: 100%;
  }
  .xl\:-top-1\/2 {
    top: -50%;
  }
  .xl\:-top-1\/3 {
    top: -33.333333%;
  }
  .xl\:-top-2\/3 {
    top: -66.666667%;
  }
  .xl\:-top-1\/4 {
    top: -25%;
  }
  .xl\:-top-2\/4 {
    top: -50%;
  }
  .xl\:-top-3\/4 {
    top: -75%;
  }
  .xl\:-top-full {
    top: -100%;
  }
  .xl\:right-0 {
    right: 0;
  }
  .xl\:right-1 {
    right: 0.25rem;
  }
  .xl\:right-2 {
    right: 0.5rem;
  }
  .xl\:right-3 {
    right: 0.75rem;
  }
  .xl\:right-4 {
    right: 1rem;
  }
  .xl\:right-5 {
    right: 1.25rem;
  }
  .xl\:right-6 {
    right: 1.5rem;
  }
  .xl\:right-7 {
    right: 1.75rem;
  }
  .xl\:right-8 {
    right: 2rem;
  }
  .xl\:right-9 {
    right: 2.25rem;
  }
  .xl\:right-10 {
    right: 2.5rem;
  }
  .xl\:right-11 {
    right: 2.75rem;
  }
  .xl\:right-12 {
    right: 3rem;
  }
  .xl\:right-14 {
    right: 3.5rem;
  }
  .xl\:right-16 {
    right: 4rem;
  }
  .xl\:right-20 {
    right: 5rem;
  }
  .xl\:right-24 {
    right: 6rem;
  }
  .xl\:right-28 {
    right: 7rem;
  }
  .xl\:right-32 {
    right: 8rem;
  }
  .xl\:right-36 {
    right: 9rem;
  }
  .xl\:right-40 {
    right: 10rem;
  }
  .xl\:right-44 {
    right: 11rem;
  }
  .xl\:right-48 {
    right: 12rem;
  }
  .xl\:right-52 {
    right: 13rem;
  }
  .xl\:right-56 {
    right: 14rem;
  }
  .xl\:right-60 {
    right: 15rem;
  }
  .xl\:right-64 {
    right: 16rem;
  }
  .xl\:right-72 {
    right: 18rem;
  }
  .xl\:right-80 {
    right: 20rem;
  }
  .xl\:right-96 {
    right: 24rem;
  }
  .xl\:right-auto {
    right: auto;
  }
  .xl\:right-px {
    right: 1px;
  }
  .xl\:right-0\.5 {
    right: 0.125rem;
  }
  .xl\:right-1\.5 {
    right: 0.375rem;
  }
  .xl\:right-2\.5 {
    right: 0.625rem;
  }
  .xl\:right-3\.5 {
    right: 0.875rem;
  }
  .xl\:-right-0 {
    right: 0;
  }
  .xl\:-right-1 {
    right: -0.25rem;
  }
  .xl\:-right-2 {
    right: -0.5rem;
  }
  .xl\:-right-3 {
    right: -0.75rem;
  }
  .xl\:-right-4 {
    right: -1rem;
  }
  .xl\:-right-5 {
    right: -1.25rem;
  }
  .xl\:-right-6 {
    right: -1.5rem;
  }
  .xl\:-right-7 {
    right: -1.75rem;
  }
  .xl\:-right-8 {
    right: -2rem;
  }
  .xl\:-right-9 {
    right: -2.25rem;
  }
  .xl\:-right-10 {
    right: -2.5rem;
  }
  .xl\:-right-11 {
    right: -2.75rem;
  }
  .xl\:-right-12 {
    right: -3rem;
  }
  .xl\:-right-14 {
    right: -3.5rem;
  }
  .xl\:-right-16 {
    right: -4rem;
  }
  .xl\:-right-20 {
    right: -5rem;
  }
  .xl\:-right-24 {
    right: -6rem;
  }
  .xl\:-right-28 {
    right: -7rem;
  }
  .xl\:-right-32 {
    right: -8rem;
  }
  .xl\:-right-36 {
    right: -9rem;
  }
  .xl\:-right-40 {
    right: -10rem;
  }
  .xl\:-right-44 {
    right: -11rem;
  }
  .xl\:-right-48 {
    right: -12rem;
  }
  .xl\:-right-52 {
    right: -13rem;
  }
  .xl\:-right-56 {
    right: -14rem;
  }
  .xl\:-right-60 {
    right: -15rem;
  }
  .xl\:-right-64 {
    right: -16rem;
  }
  .xl\:-right-72 {
    right: -18rem;
  }
  .xl\:-right-80 {
    right: -20rem;
  }
  .xl\:-right-96 {
    right: -24rem;
  }
  .xl\:-right-px {
    right: -1px;
  }
  .xl\:-right-0\.5 {
    right: -0.125rem;
  }
  .xl\:-right-1\.5 {
    right: -0.375rem;
  }
  .xl\:-right-2\.5 {
    right: -0.625rem;
  }
  .xl\:-right-3\.5 {
    right: -0.875rem;
  }
  .xl\:right-1\/2 {
    right: 50%;
  }
  .xl\:right-1\/3 {
    right: 33.333333%;
  }
  .xl\:right-2\/3 {
    right: 66.666667%;
  }
  .xl\:right-1\/4 {
    right: 25%;
  }
  .xl\:right-2\/4 {
    right: 50%;
  }
  .xl\:right-3\/4 {
    right: 75%;
  }
  .xl\:right-full {
    right: 100%;
  }
  .xl\:-right-1\/2 {
    right: -50%;
  }
  .xl\:-right-1\/3 {
    right: -33.333333%;
  }
  .xl\:-right-2\/3 {
    right: -66.666667%;
  }
  .xl\:-right-1\/4 {
    right: -25%;
  }
  .xl\:-right-2\/4 {
    right: -50%;
  }
  .xl\:-right-3\/4 {
    right: -75%;
  }
  .xl\:-right-full {
    right: -100%;
  }
  .xl\:bottom-0 {
    bottom: 0;
  }
  .xl\:bottom-1 {
    bottom: 0.25rem;
  }
  .xl\:bottom-2 {
    bottom: 0.5rem;
  }
  .xl\:bottom-3 {
    bottom: 0.75rem;
  }
  .xl\:bottom-4 {
    bottom: 1rem;
  }
  .xl\:bottom-5 {
    bottom: 1.25rem;
  }
  .xl\:bottom-6 {
    bottom: 1.5rem;
  }
  .xl\:bottom-7 {
    bottom: 1.75rem;
  }
  .xl\:bottom-8 {
    bottom: 2rem;
  }
  .xl\:bottom-9 {
    bottom: 2.25rem;
  }
  .xl\:bottom-10 {
    bottom: 2.5rem;
  }
  .xl\:bottom-11 {
    bottom: 2.75rem;
  }
  .xl\:bottom-12 {
    bottom: 3rem;
  }
  .xl\:bottom-14 {
    bottom: 3.5rem;
  }
  .xl\:bottom-16 {
    bottom: 4rem;
  }
  .xl\:bottom-20 {
    bottom: 5rem;
  }
  .xl\:bottom-24 {
    bottom: 6rem;
  }
  .xl\:bottom-28 {
    bottom: 7rem;
  }
  .xl\:bottom-32 {
    bottom: 8rem;
  }
  .xl\:bottom-36 {
    bottom: 9rem;
  }
  .xl\:bottom-40 {
    bottom: 10rem;
  }
  .xl\:bottom-44 {
    bottom: 11rem;
  }
  .xl\:bottom-48 {
    bottom: 12rem;
  }
  .xl\:bottom-52 {
    bottom: 13rem;
  }
  .xl\:bottom-56 {
    bottom: 14rem;
  }
  .xl\:bottom-60 {
    bottom: 15rem;
  }
  .xl\:bottom-64 {
    bottom: 16rem;
  }
  .xl\:bottom-72 {
    bottom: 18rem;
  }
  .xl\:bottom-80 {
    bottom: 20rem;
  }
  .xl\:bottom-96 {
    bottom: 24rem;
  }
  .xl\:bottom-auto {
    bottom: auto;
  }
  .xl\:bottom-px {
    bottom: 1px;
  }
  .xl\:bottom-0\.5 {
    bottom: 0.125rem;
  }
  .xl\:bottom-1\.5 {
    bottom: 0.375rem;
  }
  .xl\:bottom-2\.5 {
    bottom: 0.625rem;
  }
  .xl\:bottom-3\.5 {
    bottom: 0.875rem;
  }
  .xl\:-bottom-0 {
    bottom: 0;
  }
  .xl\:-bottom-1 {
    bottom: -0.25rem;
  }
  .xl\:-bottom-2 {
    bottom: -0.5rem;
  }
  .xl\:-bottom-3 {
    bottom: -0.75rem;
  }
  .xl\:-bottom-4 {
    bottom: -1rem;
  }
  .xl\:-bottom-5 {
    bottom: -1.25rem;
  }
  .xl\:-bottom-6 {
    bottom: -1.5rem;
  }
  .xl\:-bottom-7 {
    bottom: -1.75rem;
  }
  .xl\:-bottom-8 {
    bottom: -2rem;
  }
  .xl\:-bottom-9 {
    bottom: -2.25rem;
  }
  .xl\:-bottom-10 {
    bottom: -2.5rem;
  }
  .xl\:-bottom-11 {
    bottom: -2.75rem;
  }
  .xl\:-bottom-12 {
    bottom: -3rem;
  }
  .xl\:-bottom-14 {
    bottom: -3.5rem;
  }
  .xl\:-bottom-16 {
    bottom: -4rem;
  }
  .xl\:-bottom-20 {
    bottom: -5rem;
  }
  .xl\:-bottom-24 {
    bottom: -6rem;
  }
  .xl\:-bottom-28 {
    bottom: -7rem;
  }
  .xl\:-bottom-32 {
    bottom: -8rem;
  }
  .xl\:-bottom-36 {
    bottom: -9rem;
  }
  .xl\:-bottom-40 {
    bottom: -10rem;
  }
  .xl\:-bottom-44 {
    bottom: -11rem;
  }
  .xl\:-bottom-48 {
    bottom: -12rem;
  }
  .xl\:-bottom-52 {
    bottom: -13rem;
  }
  .xl\:-bottom-56 {
    bottom: -14rem;
  }
  .xl\:-bottom-60 {
    bottom: -15rem;
  }
  .xl\:-bottom-64 {
    bottom: -16rem;
  }
  .xl\:-bottom-72 {
    bottom: -18rem;
  }
  .xl\:-bottom-80 {
    bottom: -20rem;
  }
  .xl\:-bottom-96 {
    bottom: -24rem;
  }
  .xl\:-bottom-px {
    bottom: -1px;
  }
  .xl\:-bottom-0\.5 {
    bottom: -0.125rem;
  }
  .xl\:-bottom-1\.5 {
    bottom: -0.375rem;
  }
  .xl\:-bottom-2\.5 {
    bottom: -0.625rem;
  }
  .xl\:-bottom-3\.5 {
    bottom: -0.875rem;
  }
  .xl\:bottom-1\/2 {
    bottom: 50%;
  }
  .xl\:bottom-1\/3 {
    bottom: 33.333333%;
  }
  .xl\:bottom-2\/3 {
    bottom: 66.666667%;
  }
  .xl\:bottom-1\/4 {
    bottom: 25%;
  }
  .xl\:bottom-2\/4 {
    bottom: 50%;
  }
  .xl\:bottom-3\/4 {
    bottom: 75%;
  }
  .xl\:bottom-full {
    bottom: 100%;
  }
  .xl\:-bottom-1\/2 {
    bottom: -50%;
  }
  .xl\:-bottom-1\/3 {
    bottom: -33.333333%;
  }
  .xl\:-bottom-2\/3 {
    bottom: -66.666667%;
  }
  .xl\:-bottom-1\/4 {
    bottom: -25%;
  }
  .xl\:-bottom-2\/4 {
    bottom: -50%;
  }
  .xl\:-bottom-3\/4 {
    bottom: -75%;
  }
  .xl\:-bottom-full {
    bottom: -100%;
  }
  .xl\:left-0 {
    left: 0;
  }
  .xl\:left-1 {
    left: 0.25rem;
  }
  .xl\:left-2 {
    left: 0.5rem;
  }
  .xl\:left-3 {
    left: 0.75rem;
  }
  .xl\:left-4 {
    left: 1rem;
  }
  .xl\:left-5 {
    left: 1.25rem;
  }
  .xl\:left-6 {
    left: 1.5rem;
  }
  .xl\:left-7 {
    left: 1.75rem;
  }
  .xl\:left-8 {
    left: 2rem;
  }
  .xl\:left-9 {
    left: 2.25rem;
  }
  .xl\:left-10 {
    left: 2.5rem;
  }
  .xl\:left-11 {
    left: 2.75rem;
  }
  .xl\:left-12 {
    left: 3rem;
  }
  .xl\:left-14 {
    left: 3.5rem;
  }
  .xl\:left-16 {
    left: 4rem;
  }
  .xl\:left-20 {
    left: 5rem;
  }
  .xl\:left-24 {
    left: 6rem;
  }
  .xl\:left-28 {
    left: 7rem;
  }
  .xl\:left-32 {
    left: 8rem;
  }
  .xl\:left-36 {
    left: 9rem;
  }
  .xl\:left-40 {
    left: 10rem;
  }
  .xl\:left-44 {
    left: 11rem;
  }
  .xl\:left-48 {
    left: 12rem;
  }
  .xl\:left-52 {
    left: 13rem;
  }
  .xl\:left-56 {
    left: 14rem;
  }
  .xl\:left-60 {
    left: 15rem;
  }
  .xl\:left-64 {
    left: 16rem;
  }
  .xl\:left-72 {
    left: 18rem;
  }
  .xl\:left-80 {
    left: 20rem;
  }
  .xl\:left-96 {
    left: 24rem;
  }
  .xl\:left-auto {
    left: auto;
  }
  .xl\:left-px {
    left: 1px;
  }
  .xl\:left-0\.5 {
    left: 0.125rem;
  }
  .xl\:left-1\.5 {
    left: 0.375rem;
  }
  .xl\:left-2\.5 {
    left: 0.625rem;
  }
  .xl\:left-3\.5 {
    left: 0.875rem;
  }
  .xl\:-left-0 {
    left: 0;
  }
  .xl\:-left-1 {
    left: -0.25rem;
  }
  .xl\:-left-2 {
    left: -0.5rem;
  }
  .xl\:-left-3 {
    left: -0.75rem;
  }
  .xl\:-left-4 {
    left: -1rem;
  }
  .xl\:-left-5 {
    left: -1.25rem;
  }
  .xl\:-left-6 {
    left: -1.5rem;
  }
  .xl\:-left-7 {
    left: -1.75rem;
  }
  .xl\:-left-8 {
    left: -2rem;
  }
  .xl\:-left-9 {
    left: -2.25rem;
  }
  .xl\:-left-10 {
    left: -2.5rem;
  }
  .xl\:-left-11 {
    left: -2.75rem;
  }
  .xl\:-left-12 {
    left: -3rem;
  }
  .xl\:-left-14 {
    left: -3.5rem;
  }
  .xl\:-left-16 {
    left: -4rem;
  }
  .xl\:-left-20 {
    left: -5rem;
  }
  .xl\:-left-24 {
    left: -6rem;
  }
  .xl\:-left-28 {
    left: -7rem;
  }
  .xl\:-left-32 {
    left: -8rem;
  }
  .xl\:-left-36 {
    left: -9rem;
  }
  .xl\:-left-40 {
    left: -10rem;
  }
  .xl\:-left-44 {
    left: -11rem;
  }
  .xl\:-left-48 {
    left: -12rem;
  }
  .xl\:-left-52 {
    left: -13rem;
  }
  .xl\:-left-56 {
    left: -14rem;
  }
  .xl\:-left-60 {
    left: -15rem;
  }
  .xl\:-left-64 {
    left: -16rem;
  }
  .xl\:-left-72 {
    left: -18rem;
  }
  .xl\:-left-80 {
    left: -20rem;
  }
  .xl\:-left-96 {
    left: -24rem;
  }
  .xl\:-left-px {
    left: -1px;
  }
  .xl\:-left-0\.5 {
    left: -0.125rem;
  }
  .xl\:-left-1\.5 {
    left: -0.375rem;
  }
  .xl\:-left-2\.5 {
    left: -0.625rem;
  }
  .xl\:-left-3\.5 {
    left: -0.875rem;
  }
  .xl\:left-1\/2 {
    left: 50%;
  }
  .xl\:left-1\/3 {
    left: 33.333333%;
  }
  .xl\:left-2\/3 {
    left: 66.666667%;
  }
  .xl\:left-1\/4 {
    left: 25%;
  }
  .xl\:left-2\/4 {
    left: 50%;
  }
  .xl\:left-3\/4 {
    left: 75%;
  }
  .xl\:left-full {
    left: 100%;
  }
  .xl\:-left-1\/2 {
    left: -50%;
  }
  .xl\:-left-1\/3 {
    left: -33.333333%;
  }
  .xl\:-left-2\/3 {
    left: -66.666667%;
  }
  .xl\:-left-1\/4 {
    left: -25%;
  }
  .xl\:-left-2\/4 {
    left: -50%;
  }
  .xl\:-left-3\/4 {
    left: -75%;
  }
  .xl\:-left-full {
    left: -100%;
  }
  .xl\:isolate {
    isolation: isolate;
  }
  .xl\:isolation-auto {
    isolation: auto;
  }
  .xl\:z-0 {
    z-index: 0;
  }
  .xl\:z-10 {
    z-index: 10;
  }
  .xl\:z-20 {
    z-index: 20;
  }
  .xl\:z-30 {
    z-index: 30;
  }
  .xl\:z-40 {
    z-index: 40;
  }
  .xl\:z-50 {
    z-index: 50;
  }
  .xl\:z-auto {
    z-index: auto;
  }
  .xl\:focus-within\:z-0:focus-within {
    z-index: 0;
  }
  .xl\:focus-within\:z-10:focus-within {
    z-index: 10;
  }
  .xl\:focus-within\:z-20:focus-within {
    z-index: 20;
  }
  .xl\:focus-within\:z-30:focus-within {
    z-index: 30;
  }
  .xl\:focus-within\:z-40:focus-within {
    z-index: 40;
  }
  .xl\:focus-within\:z-50:focus-within {
    z-index: 50;
  }
  .xl\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }
  .xl\:focus\:z-0:focus {
    z-index: 0;
  }
  .xl\:focus\:z-10:focus {
    z-index: 10;
  }
  .xl\:focus\:z-20:focus {
    z-index: 20;
  }
  .xl\:focus\:z-30:focus {
    z-index: 30;
  }
  .xl\:focus\:z-40:focus {
    z-index: 40;
  }
  .xl\:focus\:z-50:focus {
    z-index: 50;
  }
  .xl\:focus\:z-auto:focus {
    z-index: auto;
  }
  .xl\:order-1 {
    order: 1;
  }
  .xl\:order-2 {
    order: 2;
  }
  .xl\:order-3 {
    order: 3;
  }
  .xl\:order-4 {
    order: 4;
  }
  .xl\:order-5 {
    order: 5;
  }
  .xl\:order-6 {
    order: 6;
  }
  .xl\:order-7 {
    order: 7;
  }
  .xl\:order-8 {
    order: 8;
  }
  .xl\:order-9 {
    order: 9;
  }
  .xl\:order-10 {
    order: 10;
  }
  .xl\:order-11 {
    order: 11;
  }
  .xl\:order-12 {
    order: 12;
  }
  .xl\:order-first {
    order: -9999;
  }
  .xl\:order-last {
    order: 9999;
  }
  .xl\:order-none {
    order: 0;
  }
  .xl\:col-auto {
    grid-column: auto;
  }
  .xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  .xl\:col-span-7 {
    grid-column: span 7 / span 7;
  }
  .xl\:col-span-8 {
    grid-column: span 8 / span 8;
  }
  .xl\:col-span-9 {
    grid-column: span 9 / span 9;
  }
  .xl\:col-span-10 {
    grid-column: span 10 / span 10;
  }
  .xl\:col-span-11 {
    grid-column: span 11 / span 11;
  }
  .xl\:col-span-12 {
    grid-column: span 12 / span 12;
  }
  .xl\:col-span-full {
    grid-column: 1/-1;
  }
  .xl\:col-start-1 {
    grid-column-start: 1;
  }
  .xl\:col-start-2 {
    grid-column-start: 2;
  }
  .xl\:col-start-3 {
    grid-column-start: 3;
  }
  .xl\:col-start-4 {
    grid-column-start: 4;
  }
  .xl\:col-start-5 {
    grid-column-start: 5;
  }
  .xl\:col-start-6 {
    grid-column-start: 6;
  }
  .xl\:col-start-7 {
    grid-column-start: 7;
  }
  .xl\:col-start-8 {
    grid-column-start: 8;
  }
  .xl\:col-start-9 {
    grid-column-start: 9;
  }
  .xl\:col-start-10 {
    grid-column-start: 10;
  }
  .xl\:col-start-11 {
    grid-column-start: 11;
  }
  .xl\:col-start-12 {
    grid-column-start: 12;
  }
  .xl\:col-start-13 {
    grid-column-start: 13;
  }
  .xl\:col-start-auto {
    grid-column-start: auto;
  }
  .xl\:col-end-1 {
    grid-column-end: 1;
  }
  .xl\:col-end-2 {
    grid-column-end: 2;
  }
  .xl\:col-end-3 {
    grid-column-end: 3;
  }
  .xl\:col-end-4 {
    grid-column-end: 4;
  }
  .xl\:col-end-5 {
    grid-column-end: 5;
  }
  .xl\:col-end-6 {
    grid-column-end: 6;
  }
  .xl\:col-end-7 {
    grid-column-end: 7;
  }
  .xl\:col-end-8 {
    grid-column-end: 8;
  }
  .xl\:col-end-9 {
    grid-column-end: 9;
  }
  .xl\:col-end-10 {
    grid-column-end: 10;
  }
  .xl\:col-end-11 {
    grid-column-end: 11;
  }
  .xl\:col-end-12 {
    grid-column-end: 12;
  }
  .xl\:col-end-13 {
    grid-column-end: 13;
  }
  .xl\:col-end-auto {
    grid-column-end: auto;
  }
  .xl\:row-auto {
    grid-row: auto;
  }
  .xl\:row-span-1 {
    grid-row: span 1 / span 1;
  }
  .xl\:row-span-2 {
    grid-row: span 2 / span 2;
  }
  .xl\:row-span-3 {
    grid-row: span 3 / span 3;
  }
  .xl\:row-span-4 {
    grid-row: span 4 / span 4;
  }
  .xl\:row-span-5 {
    grid-row: span 5 / span 5;
  }
  .xl\:row-span-6 {
    grid-row: span 6 / span 6;
  }
  .xl\:row-span-full {
    grid-row: 1/-1;
  }
  .xl\:row-start-1 {
    grid-row-start: 1;
  }
  .xl\:row-start-2 {
    grid-row-start: 2;
  }
  .xl\:row-start-3 {
    grid-row-start: 3;
  }
  .xl\:row-start-4 {
    grid-row-start: 4;
  }
  .xl\:row-start-5 {
    grid-row-start: 5;
  }
  .xl\:row-start-6 {
    grid-row-start: 6;
  }
  .xl\:row-start-7 {
    grid-row-start: 7;
  }
  .xl\:row-start-auto {
    grid-row-start: auto;
  }
  .xl\:row-end-1 {
    grid-row-end: 1;
  }
  .xl\:row-end-2 {
    grid-row-end: 2;
  }
  .xl\:row-end-3 {
    grid-row-end: 3;
  }
  .xl\:row-end-4 {
    grid-row-end: 4;
  }
  .xl\:row-end-5 {
    grid-row-end: 5;
  }
  .xl\:row-end-6 {
    grid-row-end: 6;
  }
  .xl\:row-end-7 {
    grid-row-end: 7;
  }
  .xl\:row-end-auto {
    grid-row-end: auto;
  }
  .xl\:float-right {
    float: right;
  }
  .xl\:float-left {
    float: left;
  }
  .xl\:float-none {
    float: none;
  }
  .xl\:clear-left {
    clear: left;
  }
  .xl\:clear-right {
    clear: right;
  }
  .xl\:clear-both {
    clear: both;
  }
  .xl\:clear-none {
    clear: none;
  }
  .xl\:m-0 {
    margin: 0;
  }
  .xl\:m-1 {
    margin: 0.25rem;
  }
  .xl\:m-2 {
    margin: 0.5rem;
  }
  .xl\:m-3 {
    margin: 0.75rem;
  }
  .xl\:m-4 {
    margin: 1rem;
  }
  .xl\:m-5 {
    margin: 1.25rem;
  }
  .xl\:m-6 {
    margin: 1.5rem;
  }
  .xl\:m-7 {
    margin: 1.75rem;
  }
  .xl\:m-8 {
    margin: 2rem;
  }
  .xl\:m-9 {
    margin: 2.25rem;
  }
  .xl\:m-10 {
    margin: 2.5rem;
  }
  .xl\:m-11 {
    margin: 2.75rem;
  }
  .xl\:m-12 {
    margin: 3rem;
  }
  .xl\:m-14 {
    margin: 3.5rem;
  }
  .xl\:m-16 {
    margin: 4rem;
  }
  .xl\:m-20 {
    margin: 5rem;
  }
  .xl\:m-24 {
    margin: 6rem;
  }
  .xl\:m-28 {
    margin: 7rem;
  }
  .xl\:m-32 {
    margin: 8rem;
  }
  .xl\:m-36 {
    margin: 9rem;
  }
  .xl\:m-40 {
    margin: 10rem;
  }
  .xl\:m-44 {
    margin: 11rem;
  }
  .xl\:m-48 {
    margin: 12rem;
  }
  .xl\:m-52 {
    margin: 13rem;
  }
  .xl\:m-56 {
    margin: 14rem;
  }
  .xl\:m-60 {
    margin: 15rem;
  }
  .xl\:m-64 {
    margin: 16rem;
  }
  .xl\:m-72 {
    margin: 18rem;
  }
  .xl\:m-80 {
    margin: 20rem;
  }
  .xl\:m-96 {
    margin: 24rem;
  }
  .xl\:m-auto {
    margin: auto;
  }
  .xl\:m-px {
    margin: 1px;
  }
  .xl\:m-0\.5 {
    margin: 0.125rem;
  }
  .xl\:m-1\.5 {
    margin: 0.375rem;
  }
  .xl\:m-2\.5 {
    margin: 0.625rem;
  }
  .xl\:m-3\.5 {
    margin: 0.875rem;
  }
  .xl\:-m-0 {
    margin: 0;
  }
  .xl\:-m-1 {
    margin: -0.25rem;
  }
  .xl\:-m-2 {
    margin: -0.5rem;
  }
  .xl\:-m-3 {
    margin: -0.75rem;
  }
  .xl\:-m-4 {
    margin: -1rem;
  }
  .xl\:-m-5 {
    margin: -1.25rem;
  }
  .xl\:-m-6 {
    margin: -1.5rem;
  }
  .xl\:-m-7 {
    margin: -1.75rem;
  }
  .xl\:-m-8 {
    margin: -2rem;
  }
  .xl\:-m-9 {
    margin: -2.25rem;
  }
  .xl\:-m-10 {
    margin: -2.5rem;
  }
  .xl\:-m-11 {
    margin: -2.75rem;
  }
  .xl\:-m-12 {
    margin: -3rem;
  }
  .xl\:-m-14 {
    margin: -3.5rem;
  }
  .xl\:-m-16 {
    margin: -4rem;
  }
  .xl\:-m-20 {
    margin: -5rem;
  }
  .xl\:-m-24 {
    margin: -6rem;
  }
  .xl\:-m-28 {
    margin: -7rem;
  }
  .xl\:-m-32 {
    margin: -8rem;
  }
  .xl\:-m-36 {
    margin: -9rem;
  }
  .xl\:-m-40 {
    margin: -10rem;
  }
  .xl\:-m-44 {
    margin: -11rem;
  }
  .xl\:-m-48 {
    margin: -12rem;
  }
  .xl\:-m-52 {
    margin: -13rem;
  }
  .xl\:-m-56 {
    margin: -14rem;
  }
  .xl\:-m-60 {
    margin: -15rem;
  }
  .xl\:-m-64 {
    margin: -16rem;
  }
  .xl\:-m-72 {
    margin: -18rem;
  }
  .xl\:-m-80 {
    margin: -20rem;
  }
  .xl\:-m-96 {
    margin: -24rem;
  }
  .xl\:-m-px {
    margin: -1px;
  }
  .xl\:-m-0\.5 {
    margin: -0.125rem;
  }
  .xl\:-m-1\.5 {
    margin: -0.375rem;
  }
  .xl\:-m-2\.5 {
    margin: -0.625rem;
  }
  .xl\:-m-3\.5 {
    margin: -0.875rem;
  }
  .xl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .xl\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .xl\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .xl\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .xl\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .xl\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .xl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .xl\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .xl\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .xl\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .xl\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }
  .xl\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .xl\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .xl\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .xl\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .xl\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .xl\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .xl\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .xl\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .xl\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .xl\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .xl\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .xl\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }
  .xl\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }
  .xl\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .xl\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }
  .xl\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }
  .xl\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .xl\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }
  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .xl\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .xl\:mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .xl\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .xl\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .xl\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .xl\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .xl\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .xl\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .xl\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .xl\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .xl\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .xl\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .xl\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }
  .xl\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .xl\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
  .xl\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .xl\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }
  .xl\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .xl\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .xl\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .xl\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .xl\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .xl\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .xl\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .xl\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .xl\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .xl\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }
  .xl\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }
  .xl\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }
  .xl\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }
  .xl\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }
  .xl\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }
  .xl\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }
  .xl\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }
  .xl\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }
  .xl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .xl\:-mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }
  .xl\:-mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }
  .xl\:-mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .xl\:-mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }
  .xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xl\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .xl\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .xl\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .xl\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .xl\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .xl\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .xl\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .xl\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .xl\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .xl\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .xl\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .xl\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .xl\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .xl\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .xl\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .xl\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .xl\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .xl\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .xl\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .xl\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .xl\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .xl\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .xl\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }
  .xl\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }
  .xl\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }
  .xl\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
  .xl\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }
  .xl\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }
  .xl\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }
  .xl\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .xl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .xl\:my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .xl\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .xl\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .xl\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .xl\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .xl\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .xl\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .xl\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .xl\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .xl\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .xl\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .xl\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .xl\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .xl\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }
  .xl\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .xl\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .xl\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .xl\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .xl\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .xl\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .xl\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .xl\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .xl\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .xl\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .xl\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .xl\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }
  .xl\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }
  .xl\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }
  .xl\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }
  .xl\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }
  .xl\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }
  .xl\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }
  .xl\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }
  .xl\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }
  .xl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .xl\:-my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }
  .xl\:-my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }
  .xl\:-my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .xl\:-my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }
  .xl\:mt-0 {
    margin-top: 0;
  }
  .xl\:mt-1 {
    margin-top: 0.25rem;
  }
  .xl\:mt-2 {
    margin-top: 0.5rem;
  }
  .xl\:mt-3 {
    margin-top: 0.75rem;
  }
  .xl\:mt-4 {
    margin-top: 1rem;
  }
  .xl\:mt-5 {
    margin-top: 1.25rem;
  }
  .xl\:mt-6 {
    margin-top: 1.5rem;
  }
  .xl\:mt-7 {
    margin-top: 1.75rem;
  }
  .xl\:mt-8 {
    margin-top: 2rem;
  }
  .xl\:mt-9 {
    margin-top: 2.25rem;
  }
  .xl\:mt-10 {
    margin-top: 2.5rem;
  }
  .xl\:mt-11 {
    margin-top: 2.75rem;
  }
  .xl\:mt-12 {
    margin-top: 3rem;
  }
  .xl\:mt-14 {
    margin-top: 3.5rem;
  }
  .xl\:mt-16 {
    margin-top: 4rem;
  }
  .xl\:mt-20 {
    margin-top: 5rem;
  }
  .xl\:mt-24 {
    margin-top: 6rem;
  }
  .xl\:mt-28 {
    margin-top: 7rem;
  }
  .xl\:mt-32 {
    margin-top: 8rem;
  }
  .xl\:mt-36 {
    margin-top: 9rem;
  }
  .xl\:mt-40 {
    margin-top: 10rem;
  }
  .xl\:mt-44 {
    margin-top: 11rem;
  }
  .xl\:mt-48 {
    margin-top: 12rem;
  }
  .xl\:mt-52 {
    margin-top: 13rem;
  }
  .xl\:mt-56 {
    margin-top: 14rem;
  }
  .xl\:mt-60 {
    margin-top: 15rem;
  }
  .xl\:mt-64 {
    margin-top: 16rem;
  }
  .xl\:mt-72 {
    margin-top: 18rem;
  }
  .xl\:mt-80 {
    margin-top: 20rem;
  }
  .xl\:mt-96 {
    margin-top: 24rem;
  }
  .xl\:mt-auto {
    margin-top: auto;
  }
  .xl\:mt-px {
    margin-top: 1px;
  }
  .xl\:mt-0\.5 {
    margin-top: 0.125rem;
  }
  .xl\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .xl\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .xl\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .xl\:-mt-0 {
    margin-top: 0;
  }
  .xl\:-mt-1 {
    margin-top: -0.25rem;
  }
  .xl\:-mt-2 {
    margin-top: -0.5rem;
  }
  .xl\:-mt-3 {
    margin-top: -0.75rem;
  }
  .xl\:-mt-4 {
    margin-top: -1rem;
  }
  .xl\:-mt-5 {
    margin-top: -1.25rem;
  }
  .xl\:-mt-6 {
    margin-top: -1.5rem;
  }
  .xl\:-mt-7 {
    margin-top: -1.75rem;
  }
  .xl\:-mt-8 {
    margin-top: -2rem;
  }
  .xl\:-mt-9 {
    margin-top: -2.25rem;
  }
  .xl\:-mt-10 {
    margin-top: -2.5rem;
  }
  .xl\:-mt-11 {
    margin-top: -2.75rem;
  }
  .xl\:-mt-12 {
    margin-top: -3rem;
  }
  .xl\:-mt-14 {
    margin-top: -3.5rem;
  }
  .xl\:-mt-16 {
    margin-top: -4rem;
  }
  .xl\:-mt-20 {
    margin-top: -5rem;
  }
  .xl\:-mt-24 {
    margin-top: -6rem;
  }
  .xl\:-mt-28 {
    margin-top: -7rem;
  }
  .xl\:-mt-32 {
    margin-top: -8rem;
  }
  .xl\:-mt-36 {
    margin-top: -9rem;
  }
  .xl\:-mt-40 {
    margin-top: -10rem;
  }
  .xl\:-mt-44 {
    margin-top: -11rem;
  }
  .xl\:-mt-48 {
    margin-top: -12rem;
  }
  .xl\:-mt-52 {
    margin-top: -13rem;
  }
  .xl\:-mt-56 {
    margin-top: -14rem;
  }
  .xl\:-mt-60 {
    margin-top: -15rem;
  }
  .xl\:-mt-64 {
    margin-top: -16rem;
  }
  .xl\:-mt-72 {
    margin-top: -18rem;
  }
  .xl\:-mt-80 {
    margin-top: -20rem;
  }
  .xl\:-mt-96 {
    margin-top: -24rem;
  }
  .xl\:-mt-px {
    margin-top: -1px;
  }
  .xl\:-mt-0\.5 {
    margin-top: -0.125rem;
  }
  .xl\:-mt-1\.5 {
    margin-top: -0.375rem;
  }
  .xl\:-mt-2\.5 {
    margin-top: -0.625rem;
  }
  .xl\:-mt-3\.5 {
    margin-top: -0.875rem;
  }
  .xl\:mr-0 {
    margin-right: 0;
  }
  .xl\:mr-1 {
    margin-right: 0.25rem;
  }
  .xl\:mr-2 {
    margin-right: 0.5rem;
  }
  .xl\:mr-3 {
    margin-right: 0.75rem;
  }
  .xl\:mr-4 {
    margin-right: 1rem;
  }
  .xl\:mr-5 {
    margin-right: 1.25rem;
  }
  .xl\:mr-6 {
    margin-right: 1.5rem;
  }
  .xl\:mr-7 {
    margin-right: 1.75rem;
  }
  .xl\:mr-8 {
    margin-right: 2rem;
  }
  .xl\:mr-9 {
    margin-right: 2.25rem;
  }
  .xl\:mr-10 {
    margin-right: 2.5rem;
  }
  .xl\:mr-11 {
    margin-right: 2.75rem;
  }
  .xl\:mr-12 {
    margin-right: 3rem;
  }
  .xl\:mr-14 {
    margin-right: 3.5rem;
  }
  .xl\:mr-16 {
    margin-right: 4rem;
  }
  .xl\:mr-20 {
    margin-right: 5rem;
  }
  .xl\:mr-24 {
    margin-right: 6rem;
  }
  .xl\:mr-28 {
    margin-right: 7rem;
  }
  .xl\:mr-32 {
    margin-right: 8rem;
  }
  .xl\:mr-36 {
    margin-right: 9rem;
  }
  .xl\:mr-40 {
    margin-right: 10rem;
  }
  .xl\:mr-44 {
    margin-right: 11rem;
  }
  .xl\:mr-48 {
    margin-right: 12rem;
  }
  .xl\:mr-52 {
    margin-right: 13rem;
  }
  .xl\:mr-56 {
    margin-right: 14rem;
  }
  .xl\:mr-60 {
    margin-right: 15rem;
  }
  .xl\:mr-64 {
    margin-right: 16rem;
  }
  .xl\:mr-72 {
    margin-right: 18rem;
  }
  .xl\:mr-80 {
    margin-right: 20rem;
  }
  .xl\:mr-96 {
    margin-right: 24rem;
  }
  .xl\:mr-auto {
    margin-right: auto;
  }
  .xl\:mr-px {
    margin-right: 1px;
  }
  .xl\:mr-0\.5 {
    margin-right: 0.125rem;
  }
  .xl\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .xl\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .xl\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .xl\:-mr-0 {
    margin-right: 0;
  }
  .xl\:-mr-1 {
    margin-right: -0.25rem;
  }
  .xl\:-mr-2 {
    margin-right: -0.5rem;
  }
  .xl\:-mr-3 {
    margin-right: -0.75rem;
  }
  .xl\:-mr-4 {
    margin-right: -1rem;
  }
  .xl\:-mr-5 {
    margin-right: -1.25rem;
  }
  .xl\:-mr-6 {
    margin-right: -1.5rem;
  }
  .xl\:-mr-7 {
    margin-right: -1.75rem;
  }
  .xl\:-mr-8 {
    margin-right: -2rem;
  }
  .xl\:-mr-9 {
    margin-right: -2.25rem;
  }
  .xl\:-mr-10 {
    margin-right: -2.5rem;
  }
  .xl\:-mr-11 {
    margin-right: -2.75rem;
  }
  .xl\:-mr-12 {
    margin-right: -3rem;
  }
  .xl\:-mr-14 {
    margin-right: -3.5rem;
  }
  .xl\:-mr-16 {
    margin-right: -4rem;
  }
  .xl\:-mr-20 {
    margin-right: -5rem;
  }
  .xl\:-mr-24 {
    margin-right: -6rem;
  }
  .xl\:-mr-28 {
    margin-right: -7rem;
  }
  .xl\:-mr-32 {
    margin-right: -8rem;
  }
  .xl\:-mr-36 {
    margin-right: -9rem;
  }
  .xl\:-mr-40 {
    margin-right: -10rem;
  }
  .xl\:-mr-44 {
    margin-right: -11rem;
  }
  .xl\:-mr-48 {
    margin-right: -12rem;
  }
  .xl\:-mr-52 {
    margin-right: -13rem;
  }
  .xl\:-mr-56 {
    margin-right: -14rem;
  }
  .xl\:-mr-60 {
    margin-right: -15rem;
  }
  .xl\:-mr-64 {
    margin-right: -16rem;
  }
  .xl\:-mr-72 {
    margin-right: -18rem;
  }
  .xl\:-mr-80 {
    margin-right: -20rem;
  }
  .xl\:-mr-96 {
    margin-right: -24rem;
  }
  .xl\:-mr-px {
    margin-right: -1px;
  }
  .xl\:-mr-0\.5 {
    margin-right: -0.125rem;
  }
  .xl\:-mr-1\.5 {
    margin-right: -0.375rem;
  }
  .xl\:-mr-2\.5 {
    margin-right: -0.625rem;
  }
  .xl\:-mr-3\.5 {
    margin-right: -0.875rem;
  }
  .xl\:mb-0 {
    margin-bottom: 0;
  }
  .xl\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .xl\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .xl\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .xl\:mb-4 {
    margin-bottom: 1rem;
  }
  .xl\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .xl\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .xl\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .xl\:mb-8 {
    margin-bottom: 2rem;
  }
  .xl\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .xl\:mb-10 {
    margin-bottom: 2.5rem;
  }
  .xl\:mb-11 {
    margin-bottom: 2.75rem;
  }
  .xl\:mb-12 {
    margin-bottom: 3rem;
  }
  .xl\:mb-14 {
    margin-bottom: 3.5rem;
  }
  .xl\:mb-16 {
    margin-bottom: 4rem;
  }
  .xl\:mb-20 {
    margin-bottom: 5rem;
  }
  .xl\:mb-24 {
    margin-bottom: 6rem;
  }
  .xl\:mb-28 {
    margin-bottom: 7rem;
  }
  .xl\:mb-32 {
    margin-bottom: 8rem;
  }
  .xl\:mb-36 {
    margin-bottom: 9rem;
  }
  .xl\:mb-40 {
    margin-bottom: 10rem;
  }
  .xl\:mb-44 {
    margin-bottom: 11rem;
  }
  .xl\:mb-48 {
    margin-bottom: 12rem;
  }
  .xl\:mb-52 {
    margin-bottom: 13rem;
  }
  .xl\:mb-56 {
    margin-bottom: 14rem;
  }
  .xl\:mb-60 {
    margin-bottom: 15rem;
  }
  .xl\:mb-64 {
    margin-bottom: 16rem;
  }
  .xl\:mb-72 {
    margin-bottom: 18rem;
  }
  .xl\:mb-80 {
    margin-bottom: 20rem;
  }
  .xl\:mb-96 {
    margin-bottom: 24rem;
  }
  .xl\:mb-auto {
    margin-bottom: auto;
  }
  .xl\:mb-px {
    margin-bottom: 1px;
  }
  .xl\:mb-0\.5 {
    margin-bottom: 0.125rem;
  }
  .xl\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .xl\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .xl\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .xl\:-mb-0 {
    margin-bottom: 0;
  }
  .xl\:-mb-1 {
    margin-bottom: -0.25rem;
  }
  .xl\:-mb-2 {
    margin-bottom: -0.5rem;
  }
  .xl\:-mb-3 {
    margin-bottom: -0.75rem;
  }
  .xl\:-mb-4 {
    margin-bottom: -1rem;
  }
  .xl\:-mb-5 {
    margin-bottom: -1.25rem;
  }
  .xl\:-mb-6 {
    margin-bottom: -1.5rem;
  }
  .xl\:-mb-7 {
    margin-bottom: -1.75rem;
  }
  .xl\:-mb-8 {
    margin-bottom: -2rem;
  }
  .xl\:-mb-9 {
    margin-bottom: -2.25rem;
  }
  .xl\:-mb-10 {
    margin-bottom: -2.5rem;
  }
  .xl\:-mb-11 {
    margin-bottom: -2.75rem;
  }
  .xl\:-mb-12 {
    margin-bottom: -3rem;
  }
  .xl\:-mb-14 {
    margin-bottom: -3.5rem;
  }
  .xl\:-mb-16 {
    margin-bottom: -4rem;
  }
  .xl\:-mb-20 {
    margin-bottom: -5rem;
  }
  .xl\:-mb-24 {
    margin-bottom: -6rem;
  }
  .xl\:-mb-28 {
    margin-bottom: -7rem;
  }
  .xl\:-mb-32 {
    margin-bottom: -8rem;
  }
  .xl\:-mb-36 {
    margin-bottom: -9rem;
  }
  .xl\:-mb-40 {
    margin-bottom: -10rem;
  }
  .xl\:-mb-44 {
    margin-bottom: -11rem;
  }
  .xl\:-mb-48 {
    margin-bottom: -12rem;
  }
  .xl\:-mb-52 {
    margin-bottom: -13rem;
  }
  .xl\:-mb-56 {
    margin-bottom: -14rem;
  }
  .xl\:-mb-60 {
    margin-bottom: -15rem;
  }
  .xl\:-mb-64 {
    margin-bottom: -16rem;
  }
  .xl\:-mb-72 {
    margin-bottom: -18rem;
  }
  .xl\:-mb-80 {
    margin-bottom: -20rem;
  }
  .xl\:-mb-96 {
    margin-bottom: -24rem;
  }
  .xl\:-mb-px {
    margin-bottom: -1px;
  }
  .xl\:-mb-0\.5 {
    margin-bottom: -0.125rem;
  }
  .xl\:-mb-1\.5 {
    margin-bottom: -0.375rem;
  }
  .xl\:-mb-2\.5 {
    margin-bottom: -0.625rem;
  }
  .xl\:-mb-3\.5 {
    margin-bottom: -0.875rem;
  }
  .xl\:ml-0 {
    margin-left: 0;
  }
  .xl\:ml-1 {
    margin-left: 0.25rem;
  }
  .xl\:ml-2 {
    margin-left: 0.5rem;
  }
  .xl\:ml-3 {
    margin-left: 0.75rem;
  }
  .xl\:ml-4 {
    margin-left: 1rem;
  }
  .xl\:ml-5 {
    margin-left: 1.25rem;
  }
  .xl\:ml-6 {
    margin-left: 1.5rem;
  }
  .xl\:ml-7 {
    margin-left: 1.75rem;
  }
  .xl\:ml-8 {
    margin-left: 2rem;
  }
  .xl\:ml-9 {
    margin-left: 2.25rem;
  }
  .xl\:ml-10 {
    margin-left: 2.5rem;
  }
  .xl\:ml-11 {
    margin-left: 2.75rem;
  }
  .xl\:ml-12 {
    margin-left: 3rem;
  }
  .xl\:ml-14 {
    margin-left: 3.5rem;
  }
  .xl\:ml-16 {
    margin-left: 4rem;
  }
  .xl\:ml-20 {
    margin-left: 5rem;
  }
  .xl\:ml-24 {
    margin-left: 6rem;
  }
  .xl\:ml-28 {
    margin-left: 7rem;
  }
  .xl\:ml-32 {
    margin-left: 8rem;
  }
  .xl\:ml-36 {
    margin-left: 9rem;
  }
  .xl\:ml-40 {
    margin-left: 10rem;
  }
  .xl\:ml-44 {
    margin-left: 11rem;
  }
  .xl\:ml-48 {
    margin-left: 12rem;
  }
  .xl\:ml-52 {
    margin-left: 13rem;
  }
  .xl\:ml-56 {
    margin-left: 14rem;
  }
  .xl\:ml-60 {
    margin-left: 15rem;
  }
  .xl\:ml-64 {
    margin-left: 16rem;
  }
  .xl\:ml-72 {
    margin-left: 18rem;
  }
  .xl\:ml-80 {
    margin-left: 20rem;
  }
  .xl\:ml-96 {
    margin-left: 24rem;
  }
  .xl\:ml-auto {
    margin-left: auto;
  }
  .xl\:ml-px {
    margin-left: 1px;
  }
  .xl\:ml-0\.5 {
    margin-left: 0.125rem;
  }
  .xl\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .xl\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .xl\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .xl\:-ml-0 {
    margin-left: 0;
  }
  .xl\:-ml-1 {
    margin-left: -0.25rem;
  }
  .xl\:-ml-2 {
    margin-left: -0.5rem;
  }
  .xl\:-ml-3 {
    margin-left: -0.75rem;
  }
  .xl\:-ml-4 {
    margin-left: -1rem;
  }
  .xl\:-ml-5 {
    margin-left: -1.25rem;
  }
  .xl\:-ml-6 {
    margin-left: -1.5rem;
  }
  .xl\:-ml-7 {
    margin-left: -1.75rem;
  }
  .xl\:-ml-8 {
    margin-left: -2rem;
  }
  .xl\:-ml-9 {
    margin-left: -2.25rem;
  }
  .xl\:-ml-10 {
    margin-left: -2.5rem;
  }
  .xl\:-ml-11 {
    margin-left: -2.75rem;
  }
  .xl\:-ml-12 {
    margin-left: -3rem;
  }
  .xl\:-ml-14 {
    margin-left: -3.5rem;
  }
  .xl\:-ml-16 {
    margin-left: -4rem;
  }
  .xl\:-ml-20 {
    margin-left: -5rem;
  }
  .xl\:-ml-24 {
    margin-left: -6rem;
  }
  .xl\:-ml-28 {
    margin-left: -7rem;
  }
  .xl\:-ml-32 {
    margin-left: -8rem;
  }
  .xl\:-ml-36 {
    margin-left: -9rem;
  }
  .xl\:-ml-40 {
    margin-left: -10rem;
  }
  .xl\:-ml-44 {
    margin-left: -11rem;
  }
  .xl\:-ml-48 {
    margin-left: -12rem;
  }
  .xl\:-ml-52 {
    margin-left: -13rem;
  }
  .xl\:-ml-56 {
    margin-left: -14rem;
  }
  .xl\:-ml-60 {
    margin-left: -15rem;
  }
  .xl\:-ml-64 {
    margin-left: -16rem;
  }
  .xl\:-ml-72 {
    margin-left: -18rem;
  }
  .xl\:-ml-80 {
    margin-left: -20rem;
  }
  .xl\:-ml-96 {
    margin-left: -24rem;
  }
  .xl\:-ml-px {
    margin-left: -1px;
  }
  .xl\:-ml-0\.5 {
    margin-left: -0.125rem;
  }
  .xl\:-ml-1\.5 {
    margin-left: -0.375rem;
  }
  .xl\:-ml-2\.5 {
    margin-left: -0.625rem;
  }
  .xl\:-ml-3\.5 {
    margin-left: -0.875rem;
  }
  .xl\:box-border {
    box-sizing: border-box;
  }
  .xl\:box-content {
    box-sizing: content-box;
  }
  .xl\:block {
    display: block;
  }
  .xl\:inline-block {
    display: inline-block;
  }
  .xl\:inline {
    display: inline;
  }
  .xl\:flex {
    display: flex;
  }
  .xl\:inline-flex {
    display: inline-flex;
  }
  .xl\:table {
    display: table;
  }
  .xl\:inline-table {
    display: inline-table;
  }
  .xl\:table-caption {
    display: table-caption;
  }
  .xl\:table-cell {
    display: table-cell;
  }
  .xl\:table-column {
    display: table-column;
  }
  .xl\:table-column-group {
    display: table-column-group;
  }
  .xl\:table-footer-group {
    display: table-footer-group;
  }
  .xl\:table-header-group {
    display: table-header-group;
  }
  .xl\:table-row-group {
    display: table-row-group;
  }
  .xl\:table-row {
    display: table-row;
  }
  .xl\:flow-root {
    display: flow-root;
  }
  .xl\:grid {
    display: grid;
  }
  .xl\:inline-grid {
    display: inline-grid;
  }
  .xl\:contents {
    display: contents;
  }
  .xl\:list-item {
    display: list-item;
  }
  .xl\:hidden {
    display: none;
  }
  .xl\:h-0 {
    height: 0;
  }
  .xl\:h-1 {
    height: 0.25rem;
  }
  .xl\:h-2 {
    height: 0.5rem;
  }
  .xl\:h-3 {
    height: 0.75rem;
  }
  .xl\:h-4 {
    height: 1rem;
  }
  .xl\:h-5 {
    height: 1.25rem;
  }
  .xl\:h-6 {
    height: 1.5rem;
  }
  .xl\:h-7 {
    height: 1.75rem;
  }
  .xl\:h-8 {
    height: 2rem;
  }
  .xl\:h-9 {
    height: 2.25rem;
  }
  .xl\:h-10 {
    height: 2.5rem;
  }
  .xl\:h-11 {
    height: 2.75rem;
  }
  .xl\:h-12 {
    height: 3rem;
  }
  .xl\:h-14 {
    height: 3.5rem;
  }
  .xl\:h-16 {
    height: 4rem;
  }
  .xl\:h-20 {
    height: 5rem;
  }
  .xl\:h-24 {
    height: 6rem;
  }
  .xl\:h-28 {
    height: 7rem;
  }
  .xl\:h-32 {
    height: 8rem;
  }
  .xl\:h-36 {
    height: 9rem;
  }
  .xl\:h-40 {
    height: 10rem;
  }
  .xl\:h-44 {
    height: 11rem;
  }
  .xl\:h-48 {
    height: 12rem;
  }
  .xl\:h-52 {
    height: 13rem;
  }
  .xl\:h-56 {
    height: 14rem;
  }
  .xl\:h-60 {
    height: 15rem;
  }
  .xl\:h-64 {
    height: 16rem;
  }
  .xl\:h-72 {
    height: 18rem;
  }
  .xl\:h-80 {
    height: 20rem;
  }
  .xl\:h-96 {
    height: 24rem;
  }
  .xl\:h-auto {
    height: auto;
  }
  .xl\:h-px {
    height: 1px;
  }
  .xl\:h-0\.5 {
    height: 0.125rem;
  }
  .xl\:h-1\.5 {
    height: 0.375rem;
  }
  .xl\:h-2\.5 {
    height: 0.625rem;
  }
  .xl\:h-3\.5 {
    height: 0.875rem;
  }
  .xl\:h-1\/2 {
    height: 50%;
  }
  .xl\:h-1\/3 {
    height: 33.333333%;
  }
  .xl\:h-2\/3 {
    height: 66.666667%;
  }
  .xl\:h-1\/4 {
    height: 25%;
  }
  .xl\:h-2\/4 {
    height: 50%;
  }
  .xl\:h-3\/4 {
    height: 75%;
  }
  .xl\:h-1\/5 {
    height: 20%;
  }
  .xl\:h-2\/5 {
    height: 40%;
  }
  .xl\:h-3\/5 {
    height: 60%;
  }
  .xl\:h-4\/5 {
    height: 80%;
  }
  .xl\:h-1\/6 {
    height: 16.666667%;
  }
  .xl\:h-2\/6 {
    height: 33.333333%;
  }
  .xl\:h-3\/6 {
    height: 50%;
  }
  .xl\:h-4\/6 {
    height: 66.666667%;
  }
  .xl\:h-5\/6 {
    height: 83.333333%;
  }
  .xl\:h-full {
    height: 100%;
  }
  .xl\:h-screen {
    height: 100vh;
  }
  .xl\:max-h-0 {
    max-height: 0;
  }
  .xl\:max-h-1 {
    max-height: 0.25rem;
  }
  .xl\:max-h-2 {
    max-height: 0.5rem;
  }
  .xl\:max-h-3 {
    max-height: 0.75rem;
  }
  .xl\:max-h-4 {
    max-height: 1rem;
  }
  .xl\:max-h-5 {
    max-height: 1.25rem;
  }
  .xl\:max-h-6 {
    max-height: 1.5rem;
  }
  .xl\:max-h-7 {
    max-height: 1.75rem;
  }
  .xl\:max-h-8 {
    max-height: 2rem;
  }
  .xl\:max-h-9 {
    max-height: 2.25rem;
  }
  .xl\:max-h-10 {
    max-height: 2.5rem;
  }
  .xl\:max-h-11 {
    max-height: 2.75rem;
  }
  .xl\:max-h-12 {
    max-height: 3rem;
  }
  .xl\:max-h-14 {
    max-height: 3.5rem;
  }
  .xl\:max-h-16 {
    max-height: 4rem;
  }
  .xl\:max-h-20 {
    max-height: 5rem;
  }
  .xl\:max-h-24 {
    max-height: 6rem;
  }
  .xl\:max-h-28 {
    max-height: 7rem;
  }
  .xl\:max-h-32 {
    max-height: 8rem;
  }
  .xl\:max-h-36 {
    max-height: 9rem;
  }
  .xl\:max-h-40 {
    max-height: 10rem;
  }
  .xl\:max-h-44 {
    max-height: 11rem;
  }
  .xl\:max-h-48 {
    max-height: 12rem;
  }
  .xl\:max-h-52 {
    max-height: 13rem;
  }
  .xl\:max-h-56 {
    max-height: 14rem;
  }
  .xl\:max-h-60 {
    max-height: 15rem;
  }
  .xl\:max-h-64 {
    max-height: 16rem;
  }
  .xl\:max-h-72 {
    max-height: 18rem;
  }
  .xl\:max-h-80 {
    max-height: 20rem;
  }
  .xl\:max-h-96 {
    max-height: 24rem;
  }
  .xl\:max-h-px {
    max-height: 1px;
  }
  .xl\:max-h-0\.5 {
    max-height: 0.125rem;
  }
  .xl\:max-h-1\.5 {
    max-height: 0.375rem;
  }
  .xl\:max-h-2\.5 {
    max-height: 0.625rem;
  }
  .xl\:max-h-3\.5 {
    max-height: 0.875rem;
  }
  .xl\:max-h-full {
    max-height: 100%;
  }
  .xl\:max-h-screen {
    max-height: 100vh;
  }
  .xl\:min-h-0 {
    min-height: 0;
  }
  .xl\:min-h-full {
    min-height: 100%;
  }
  .xl\:min-h-screen {
    min-height: 100vh;
  }
  .xl\:w-0 {
    width: 0;
  }
  .xl\:w-1 {
    width: 0.25rem;
  }
  .xl\:w-2 {
    width: 0.5rem;
  }
  .xl\:w-3 {
    width: 0.75rem;
  }
  .xl\:w-4 {
    width: 1rem;
  }
  .xl\:w-5 {
    width: 1.25rem;
  }
  .xl\:w-6 {
    width: 1.5rem;
  }
  .xl\:w-7 {
    width: 1.75rem;
  }
  .xl\:w-8 {
    width: 2rem;
  }
  .xl\:w-9 {
    width: 2.25rem;
  }
  .xl\:w-10 {
    width: 2.5rem;
  }
  .xl\:w-11 {
    width: 2.75rem;
  }
  .xl\:w-12 {
    width: 3rem;
  }
  .xl\:w-14 {
    width: 3.5rem;
  }
  .xl\:w-16 {
    width: 4rem;
  }
  .xl\:w-20 {
    width: 5rem;
  }
  .xl\:w-24 {
    width: 6rem;
  }
  .xl\:w-28 {
    width: 7rem;
  }
  .xl\:w-32 {
    width: 8rem;
  }
  .xl\:w-36 {
    width: 9rem;
  }
  .xl\:w-40 {
    width: 10rem;
  }
  .xl\:w-44 {
    width: 11rem;
  }
  .xl\:w-48 {
    width: 12rem;
  }
  .xl\:w-52 {
    width: 13rem;
  }
  .xl\:w-56 {
    width: 14rem;
  }
  .xl\:w-60 {
    width: 15rem;
  }
  .xl\:w-64 {
    width: 16rem;
  }
  .xl\:w-72 {
    width: 18rem;
  }
  .xl\:w-80 {
    width: 20rem;
  }
  .xl\:w-96 {
    width: 24rem;
  }
  .xl\:w-auto {
    width: auto;
  }
  .xl\:w-px {
    width: 1px;
  }
  .xl\:w-0\.5 {
    width: 0.125rem;
  }
  .xl\:w-1\.5 {
    width: 0.375rem;
  }
  .xl\:w-2\.5 {
    width: 0.625rem;
  }
  .xl\:w-3\.5 {
    width: 0.875rem;
  }
  .xl\:w-1\/2 {
    width: 50%;
  }
  .xl\:w-1\/3 {
    width: 33.333333%;
  }
  .xl\:w-2\/3 {
    width: 66.666667%;
  }
  .xl\:w-1\/4 {
    width: 25%;
  }
  .xl\:w-2\/4 {
    width: 50%;
  }
  .xl\:w-3\/4 {
    width: 75%;
  }
  .xl\:w-1\/5 {
    width: 20%;
  }
  .xl\:w-2\/5 {
    width: 40%;
  }
  .xl\:w-3\/5 {
    width: 60%;
  }
  .xl\:w-4\/5 {
    width: 80%;
  }
  .xl\:w-1\/6 {
    width: 16.666667%;
  }
  .xl\:w-2\/6 {
    width: 33.333333%;
  }
  .xl\:w-3\/6 {
    width: 50%;
  }
  .xl\:w-4\/6 {
    width: 66.666667%;
  }
  .xl\:w-5\/6 {
    width: 83.333333%;
  }
  .xl\:w-1\/12 {
    width: 8.333333%;
  }
  .xl\:w-2\/12 {
    width: 16.666667%;
  }
  .xl\:w-3\/12 {
    width: 25%;
  }
  .xl\:w-4\/12 {
    width: 33.333333%;
  }
  .xl\:w-5\/12 {
    width: 41.666667%;
  }
  .xl\:w-6\/12 {
    width: 50%;
  }
  .xl\:w-7\/12 {
    width: 58.333333%;
  }
  .xl\:w-8\/12 {
    width: 66.666667%;
  }
  .xl\:w-9\/12 {
    width: 75%;
  }
  .xl\:w-10\/12 {
    width: 83.333333%;
  }
  .xl\:w-11\/12 {
    width: 91.666667%;
  }
  .xl\:w-full {
    width: 100%;
  }
  .xl\:w-screen {
    width: 100vw;
  }
  .xl\:w-min {
    width: min-content;
  }
  .xl\:w-max {
    width: max-content;
  }
  .xl\:min-w-0 {
    min-width: 0;
  }
  .xl\:min-w-full {
    min-width: 100%;
  }
  .xl\:min-w-min {
    min-width: min-content;
  }
  .xl\:min-w-max {
    min-width: max-content;
  }
  .xl\:max-w-0 {
    max-width: 0;
  }
  .xl\:max-w-none {
    max-width: none;
  }
  .xl\:max-w-xs {
    max-width: 20rem;
  }
  .xl\:max-w-sm {
    max-width: 24rem;
  }
  .xl\:max-w-md {
    max-width: 28rem;
  }
  .xl\:max-w-lg {
    max-width: 32rem;
  }
  .xl\:max-w-xl {
    max-width: 36rem;
  }
  .xl\:max-w-2xl {
    max-width: 42rem;
  }
  .xl\:max-w-3xl {
    max-width: 48rem;
  }
  .xl\:max-w-4xl {
    max-width: 56rem;
  }
  .xl\:max-w-5xl {
    max-width: 64rem;
  }
  .xl\:max-w-6xl {
    max-width: 72rem;
  }
  .xl\:max-w-7xl {
    max-width: 80rem;
  }
  .xl\:max-w-full {
    max-width: 100%;
  }
  .xl\:max-w-min {
    max-width: min-content;
  }
  .xl\:max-w-max {
    max-width: max-content;
  }
  .xl\:max-w-prose {
    max-width: 65ch;
  }
  .xl\:max-w-screen-sm {
    max-width: 640px;
  }
  .xl\:max-w-screen-md {
    max-width: 768px;
  }
  .xl\:max-w-screen-lg {
    max-width: 1024px;
  }
  .xl\:max-w-screen-xl {
    max-width: 1280px;
  }
  .xl\:max-w-screen-2xl {
    max-width: 1536px;
  }
  .xl\:flex-1 {
    flex: 1 1 0%;
  }
  .xl\:flex-auto {
    flex: 1 1 auto;
  }
  .xl\:flex-initial {
    flex: 0 1 auto;
  }
  .xl\:flex-none {
    flex: none;
  }
  .xl\:flex-shrink-0 {
    flex-shrink: 0;
  }
  .xl\:flex-shrink {
    flex-shrink: 1;
  }
  .xl\:flex-grow-0 {
    flex-grow: 0;
  }
  .xl\:flex-grow {
    flex-grow: 1;
  }
  .xl\:table-auto {
    table-layout: auto;
  }
  .xl\:table-fixed {
    table-layout: fixed;
  }
  .xl\:border-collapse {
    border-collapse: collapse;
  }
  .xl\:border-separate {
    border-collapse: separate;
  }
  .xl\:origin-center {
    transform-origin: center;
  }
  .xl\:origin-top {
    transform-origin: top;
  }
  .xl\:origin-top-right {
    transform-origin: top right;
  }
  .xl\:origin-right {
    transform-origin: right;
  }
  .xl\:origin-bottom-right {
    transform-origin: bottom right;
  }
  .xl\:origin-bottom {
    transform-origin: bottom;
  }
  .xl\:origin-bottom-left {
    transform-origin: bottom left;
  }
  .xl\:origin-left {
    transform-origin: left;
  }
  .xl\:origin-top-left {
    transform-origin: top left;
  }
  .xl\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  .xl\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .xl\:transform-none {
    transform: none;
  }
  .xl\:translate-x-0 {
    --tw-translate-x: 0px;
  }
  .xl\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }
  .xl\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }
  .xl\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }
  .xl\:translate-x-4 {
    --tw-translate-x: 1rem;
  }
  .xl\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }
  .xl\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }
  .xl\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }
  .xl\:translate-x-8 {
    --tw-translate-x: 2rem;
  }
  .xl\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }
  .xl\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }
  .xl\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }
  .xl\:translate-x-12 {
    --tw-translate-x: 3rem;
  }
  .xl\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }
  .xl\:translate-x-16 {
    --tw-translate-x: 4rem;
  }
  .xl\:translate-x-20 {
    --tw-translate-x: 5rem;
  }
  .xl\:translate-x-24 {
    --tw-translate-x: 6rem;
  }
  .xl\:translate-x-28 {
    --tw-translate-x: 7rem;
  }
  .xl\:translate-x-32 {
    --tw-translate-x: 8rem;
  }
  .xl\:translate-x-36 {
    --tw-translate-x: 9rem;
  }
  .xl\:translate-x-40 {
    --tw-translate-x: 10rem;
  }
  .xl\:translate-x-44 {
    --tw-translate-x: 11rem;
  }
  .xl\:translate-x-48 {
    --tw-translate-x: 12rem;
  }
  .xl\:translate-x-52 {
    --tw-translate-x: 13rem;
  }
  .xl\:translate-x-56 {
    --tw-translate-x: 14rem;
  }
  .xl\:translate-x-60 {
    --tw-translate-x: 15rem;
  }
  .xl\:translate-x-64 {
    --tw-translate-x: 16rem;
  }
  .xl\:translate-x-72 {
    --tw-translate-x: 18rem;
  }
  .xl\:translate-x-80 {
    --tw-translate-x: 20rem;
  }
  .xl\:translate-x-96 {
    --tw-translate-x: 24rem;
  }
  .xl\:translate-x-px {
    --tw-translate-x: 1px;
  }
  .xl\:translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }
  .xl\:translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }
  .xl\:translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }
  .xl\:translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }
  .xl\:-translate-x-0 {
    --tw-translate-x: 0px;
  }
  .xl\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }
  .xl\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }
  .xl\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }
  .xl\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }
  .xl\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }
  .xl\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }
  .xl\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }
  .xl\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }
  .xl\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }
  .xl\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }
  .xl\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }
  .xl\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }
  .xl\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }
  .xl\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }
  .xl\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }
  .xl\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }
  .xl\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }
  .xl\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }
  .xl\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }
  .xl\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }
  .xl\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }
  .xl\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }
  .xl\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }
  .xl\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }
  .xl\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }
  .xl\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }
  .xl\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }
  .xl\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }
  .xl\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }
  .xl\:-translate-x-px {
    --tw-translate-x: -1px;
  }
  .xl\:-translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }
  .xl\:-translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }
  .xl\:-translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }
  .xl\:-translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }
  .xl\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }
  .xl\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }
  .xl\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }
  .xl\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }
  .xl\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }
  .xl\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }
  .xl\:translate-x-full {
    --tw-translate-x: 100%;
  }
  .xl\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }
  .xl\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }
  .xl\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }
  .xl\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }
  .xl\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }
  .xl\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }
  .xl\:-translate-x-full {
    --tw-translate-x: -100%;
  }
  .xl\:translate-y-0 {
    --tw-translate-y: 0px;
  }
  .xl\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }
  .xl\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }
  .xl\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }
  .xl\:translate-y-4 {
    --tw-translate-y: 1rem;
  }
  .xl\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }
  .xl\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }
  .xl\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }
  .xl\:translate-y-8 {
    --tw-translate-y: 2rem;
  }
  .xl\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }
  .xl\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }
  .xl\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }
  .xl\:translate-y-12 {
    --tw-translate-y: 3rem;
  }
  .xl\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }
  .xl\:translate-y-16 {
    --tw-translate-y: 4rem;
  }
  .xl\:translate-y-20 {
    --tw-translate-y: 5rem;
  }
  .xl\:translate-y-24 {
    --tw-translate-y: 6rem;
  }
  .xl\:translate-y-28 {
    --tw-translate-y: 7rem;
  }
  .xl\:translate-y-32 {
    --tw-translate-y: 8rem;
  }
  .xl\:translate-y-36 {
    --tw-translate-y: 9rem;
  }
  .xl\:translate-y-40 {
    --tw-translate-y: 10rem;
  }
  .xl\:translate-y-44 {
    --tw-translate-y: 11rem;
  }
  .xl\:translate-y-48 {
    --tw-translate-y: 12rem;
  }
  .xl\:translate-y-52 {
    --tw-translate-y: 13rem;
  }
  .xl\:translate-y-56 {
    --tw-translate-y: 14rem;
  }
  .xl\:translate-y-60 {
    --tw-translate-y: 15rem;
  }
  .xl\:translate-y-64 {
    --tw-translate-y: 16rem;
  }
  .xl\:translate-y-72 {
    --tw-translate-y: 18rem;
  }
  .xl\:translate-y-80 {
    --tw-translate-y: 20rem;
  }
  .xl\:translate-y-96 {
    --tw-translate-y: 24rem;
  }
  .xl\:translate-y-px {
    --tw-translate-y: 1px;
  }
  .xl\:translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }
  .xl\:translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }
  .xl\:translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }
  .xl\:translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }
  .xl\:-translate-y-0 {
    --tw-translate-y: 0px;
  }
  .xl\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }
  .xl\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }
  .xl\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }
  .xl\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }
  .xl\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }
  .xl\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }
  .xl\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }
  .xl\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }
  .xl\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }
  .xl\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }
  .xl\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }
  .xl\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }
  .xl\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }
  .xl\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }
  .xl\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }
  .xl\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }
  .xl\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }
  .xl\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }
  .xl\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }
  .xl\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }
  .xl\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }
  .xl\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }
  .xl\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }
  .xl\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }
  .xl\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }
  .xl\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }
  .xl\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }
  .xl\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }
  .xl\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }
  .xl\:-translate-y-px {
    --tw-translate-y: -1px;
  }
  .xl\:-translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }
  .xl\:-translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }
  .xl\:-translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }
  .xl\:-translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }
  .xl\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }
  .xl\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }
  .xl\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }
  .xl\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }
  .xl\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }
  .xl\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }
  .xl\:translate-y-full {
    --tw-translate-y: 100%;
  }
  .xl\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }
  .xl\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }
  .xl\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }
  .xl\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }
  .xl\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }
  .xl\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }
  .xl\:-translate-y-full {
    --tw-translate-y: -100%;
  }
  .xl\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .xl\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }
  .xl\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }
  .xl\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }
  .xl\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }
  .xl\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }
  .xl\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }
  .xl\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }
  .xl\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }
  .xl\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }
  .xl\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }
  .xl\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }
  .xl\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }
  .xl\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }
  .xl\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }
  .xl\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }
  .xl\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }
  .xl\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }
  .xl\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }
  .xl\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }
  .xl\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }
  .xl\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }
  .xl\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }
  .xl\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }
  .xl\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }
  .xl\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }
  .xl\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }
  .xl\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }
  .xl\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }
  .xl\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }
  .xl\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }
  .xl\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }
  .xl\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }
  .xl\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }
  .xl\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }
  .xl\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .xl\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }
  .xl\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }
  .xl\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }
  .xl\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }
  .xl\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }
  .xl\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }
  .xl\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }
  .xl\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }
  .xl\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }
  .xl\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }
  .xl\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }
  .xl\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }
  .xl\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }
  .xl\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }
  .xl\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }
  .xl\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }
  .xl\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }
  .xl\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }
  .xl\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }
  .xl\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }
  .xl\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }
  .xl\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }
  .xl\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }
  .xl\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }
  .xl\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }
  .xl\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }
  .xl\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }
  .xl\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }
  .xl\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }
  .xl\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }
  .xl\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }
  .xl\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }
  .xl\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }
  .xl\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }
  .xl\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }
  .xl\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }
  .xl\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }
  .xl\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }
  .xl\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }
  .xl\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }
  .xl\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }
  .xl\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }
  .xl\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }
  .xl\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }
  .xl\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }
  .xl\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }
  .xl\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }
  .xl\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }
  .xl\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .xl\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }
  .xl\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }
  .xl\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }
  .xl\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }
  .xl\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }
  .xl\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }
  .xl\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }
  .xl\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }
  .xl\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }
  .xl\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }
  .xl\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }
  .xl\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }
  .xl\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }
  .xl\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }
  .xl\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }
  .xl\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }
  .xl\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }
  .xl\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }
  .xl\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }
  .xl\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }
  .xl\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }
  .xl\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }
  .xl\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }
  .xl\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }
  .xl\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }
  .xl\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }
  .xl\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }
  .xl\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }
  .xl\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }
  .xl\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }
  .xl\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }
  .xl\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }
  .xl\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }
  .xl\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }
  .xl\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .xl\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }
  .xl\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }
  .xl\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }
  .xl\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }
  .xl\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }
  .xl\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }
  .xl\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }
  .xl\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }
  .xl\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }
  .xl\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }
  .xl\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }
  .xl\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }
  .xl\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }
  .xl\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }
  .xl\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }
  .xl\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }
  .xl\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }
  .xl\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }
  .xl\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }
  .xl\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }
  .xl\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }
  .xl\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }
  .xl\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }
  .xl\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }
  .xl\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }
  .xl\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }
  .xl\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }
  .xl\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }
  .xl\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }
  .xl\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }
  .xl\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }
  .xl\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }
  .xl\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }
  .xl\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }
  .xl\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }
  .xl\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }
  .xl\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }
  .xl\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }
  .xl\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }
  .xl\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }
  .xl\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }
  .xl\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }
  .xl\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }
  .xl\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }
  .xl\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }
  .xl\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }
  .xl\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }
  .xl\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }
  .xl\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .xl\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }
  .xl\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }
  .xl\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }
  .xl\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }
  .xl\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }
  .xl\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }
  .xl\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }
  .xl\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }
  .xl\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }
  .xl\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }
  .xl\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }
  .xl\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }
  .xl\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }
  .xl\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }
  .xl\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }
  .xl\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }
  .xl\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }
  .xl\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }
  .xl\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }
  .xl\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }
  .xl\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }
  .xl\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }
  .xl\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }
  .xl\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }
  .xl\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }
  .xl\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }
  .xl\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }
  .xl\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }
  .xl\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }
  .xl\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }
  .xl\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }
  .xl\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }
  .xl\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }
  .xl\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }
  .xl\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .xl\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }
  .xl\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }
  .xl\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }
  .xl\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }
  .xl\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }
  .xl\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }
  .xl\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }
  .xl\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }
  .xl\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }
  .xl\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }
  .xl\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }
  .xl\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }
  .xl\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }
  .xl\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }
  .xl\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }
  .xl\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }
  .xl\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }
  .xl\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }
  .xl\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }
  .xl\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }
  .xl\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }
  .xl\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }
  .xl\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }
  .xl\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }
  .xl\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }
  .xl\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }
  .xl\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }
  .xl\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }
  .xl\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }
  .xl\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }
  .xl\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }
  .xl\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }
  .xl\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }
  .xl\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }
  .xl\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }
  .xl\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }
  .xl\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }
  .xl\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }
  .xl\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }
  .xl\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }
  .xl\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }
  .xl\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }
  .xl\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }
  .xl\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }
  .xl\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }
  .xl\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }
  .xl\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }
  .xl\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }
  .xl\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .xl\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }
  .xl\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }
  .xl\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }
  .xl\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }
  .xl\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }
  .xl\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }
  .xl\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }
  .xl\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }
  .xl\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }
  .xl\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }
  .xl\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }
  .xl\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }
  .xl\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }
  .xl\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }
  .xl\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }
  .xl\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }
  .xl\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }
  .xl\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }
  .xl\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }
  .xl\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }
  .xl\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }
  .xl\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }
  .xl\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }
  .xl\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }
  .xl\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }
  .xl\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }
  .xl\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }
  .xl\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }
  .xl\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }
  .xl\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }
  .xl\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }
  .xl\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }
  .xl\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }
  .xl\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }
  .xl\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .xl\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }
  .xl\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }
  .xl\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }
  .xl\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }
  .xl\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }
  .xl\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }
  .xl\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }
  .xl\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }
  .xl\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }
  .xl\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }
  .xl\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }
  .xl\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }
  .xl\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }
  .xl\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }
  .xl\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }
  .xl\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }
  .xl\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }
  .xl\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }
  .xl\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }
  .xl\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }
  .xl\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }
  .xl\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }
  .xl\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }
  .xl\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }
  .xl\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }
  .xl\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }
  .xl\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }
  .xl\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }
  .xl\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }
  .xl\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }
  .xl\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }
  .xl\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }
  .xl\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }
  .xl\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }
  .xl\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }
  .xl\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }
  .xl\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }
  .xl\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }
  .xl\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }
  .xl\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }
  .xl\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }
  .xl\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }
  .xl\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }
  .xl\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }
  .xl\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }
  .xl\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }
  .xl\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }
  .xl\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }
  .xl\:rotate-0 {
    --tw-rotate: 0deg;
  }
  .xl\:rotate-1 {
    --tw-rotate: 1deg;
  }
  .xl\:rotate-2 {
    --tw-rotate: 2deg;
  }
  .xl\:rotate-3 {
    --tw-rotate: 3deg;
  }
  .xl\:rotate-6 {
    --tw-rotate: 6deg;
  }
  .xl\:rotate-12 {
    --tw-rotate: 12deg;
  }
  .xl\:rotate-45 {
    --tw-rotate: 45deg;
  }
  .xl\:rotate-90 {
    --tw-rotate: 90deg;
  }
  .xl\:rotate-180 {
    --tw-rotate: 180deg;
  }
  .xl\:-rotate-180 {
    --tw-rotate: -180deg;
  }
  .xl\:-rotate-90 {
    --tw-rotate: -90deg;
  }
  .xl\:-rotate-45 {
    --tw-rotate: -45deg;
  }
  .xl\:-rotate-12 {
    --tw-rotate: -12deg;
  }
  .xl\:-rotate-6 {
    --tw-rotate: -6deg;
  }
  .xl\:-rotate-3 {
    --tw-rotate: -3deg;
  }
  .xl\:-rotate-2 {
    --tw-rotate: -2deg;
  }
  .xl\:-rotate-1 {
    --tw-rotate: -1deg;
  }
  .xl\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }
  .xl\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }
  .xl\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }
  .xl\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }
  .xl\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }
  .xl\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }
  .xl\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }
  .xl\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }
  .xl\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }
  .xl\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }
  .xl\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }
  .xl\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }
  .xl\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }
  .xl\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }
  .xl\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }
  .xl\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }
  .xl\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }
  .xl\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }
  .xl\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }
  .xl\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }
  .xl\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }
  .xl\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }
  .xl\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }
  .xl\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }
  .xl\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }
  .xl\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }
  .xl\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }
  .xl\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }
  .xl\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }
  .xl\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }
  .xl\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }
  .xl\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }
  .xl\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }
  .xl\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }
  .xl\:skew-x-0 {
    --tw-skew-x: 0deg;
  }
  .xl\:skew-x-1 {
    --tw-skew-x: 1deg;
  }
  .xl\:skew-x-2 {
    --tw-skew-x: 2deg;
  }
  .xl\:skew-x-3 {
    --tw-skew-x: 3deg;
  }
  .xl\:skew-x-6 {
    --tw-skew-x: 6deg;
  }
  .xl\:skew-x-12 {
    --tw-skew-x: 12deg;
  }
  .xl\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }
  .xl\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }
  .xl\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }
  .xl\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }
  .xl\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }
  .xl\:skew-y-0 {
    --tw-skew-y: 0deg;
  }
  .xl\:skew-y-1 {
    --tw-skew-y: 1deg;
  }
  .xl\:skew-y-2 {
    --tw-skew-y: 2deg;
  }
  .xl\:skew-y-3 {
    --tw-skew-y: 3deg;
  }
  .xl\:skew-y-6 {
    --tw-skew-y: 6deg;
  }
  .xl\:skew-y-12 {
    --tw-skew-y: 12deg;
  }
  .xl\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }
  .xl\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }
  .xl\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }
  .xl\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }
  .xl\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }
  .xl\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }
  .xl\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }
  .xl\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }
  .xl\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }
  .xl\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }
  .xl\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }
  .xl\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }
  .xl\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }
  .xl\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }
  .xl\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }
  .xl\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }
  .xl\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }
  .xl\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }
  .xl\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }
  .xl\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }
  .xl\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }
  .xl\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }
  .xl\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }
  .xl\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }
  .xl\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }
  .xl\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }
  .xl\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }
  .xl\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }
  .xl\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }
  .xl\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }
  .xl\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }
  .xl\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }
  .xl\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }
  .xl\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }
  .xl\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }
  .xl\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }
  .xl\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }
  .xl\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }
  .xl\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }
  .xl\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }
  .xl\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }
  .xl\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }
  .xl\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }
  .xl\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }
  .xl\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }
  .xl\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }
  .xl\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }
  .xl\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }
  .xl\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }
  .xl\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .xl\:scale-50 {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .xl\:scale-75 {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .xl\:scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .xl\:scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .xl\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xl\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .xl\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .xl\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .xl\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .xl\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .xl\:hover\:scale-50:hover {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .xl\:hover\:scale-75:hover {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .xl\:hover\:scale-90:hover {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .xl\:hover\:scale-95:hover {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .xl\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xl\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .xl\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .xl\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .xl\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .xl\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .xl\:focus\:scale-50:focus {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .xl\:focus\:scale-75:focus {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .xl\:focus\:scale-90:focus {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .xl\:focus\:scale-95:focus {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .xl\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .xl\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .xl\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .xl\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .xl\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .xl\:scale-x-0 {
    --tw-scale-x: 0;
  }
  .xl\:scale-x-50 {
    --tw-scale-x: 0.5;
  }
  .xl\:scale-x-75 {
    --tw-scale-x: 0.75;
  }
  .xl\:scale-x-90 {
    --tw-scale-x: 0.9;
  }
  .xl\:scale-x-95 {
    --tw-scale-x: 0.95;
  }
  .xl\:scale-x-100 {
    --tw-scale-x: 1;
  }
  .xl\:scale-x-105 {
    --tw-scale-x: 1.05;
  }
  .xl\:scale-x-110 {
    --tw-scale-x: 1.1;
  }
  .xl\:scale-x-125 {
    --tw-scale-x: 1.25;
  }
  .xl\:scale-x-150 {
    --tw-scale-x: 1.5;
  }
  .xl\:scale-y-0 {
    --tw-scale-y: 0;
  }
  .xl\:scale-y-50 {
    --tw-scale-y: 0.5;
  }
  .xl\:scale-y-75 {
    --tw-scale-y: 0.75;
  }
  .xl\:scale-y-90 {
    --tw-scale-y: 0.9;
  }
  .xl\:scale-y-95 {
    --tw-scale-y: 0.95;
  }
  .xl\:scale-y-100 {
    --tw-scale-y: 1;
  }
  .xl\:scale-y-105 {
    --tw-scale-y: 1.05;
  }
  .xl\:scale-y-110 {
    --tw-scale-y: 1.1;
  }
  .xl\:scale-y-125 {
    --tw-scale-y: 1.25;
  }
  .xl\:scale-y-150 {
    --tw-scale-y: 1.5;
  }
  .xl\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }
  .xl\:hover\:scale-x-50:hover {
    --tw-scale-x: 0.5;
  }
  .xl\:hover\:scale-x-75:hover {
    --tw-scale-x: 0.75;
  }
  .xl\:hover\:scale-x-90:hover {
    --tw-scale-x: 0.9;
  }
  .xl\:hover\:scale-x-95:hover {
    --tw-scale-x: 0.95;
  }
  .xl\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }
  .xl\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }
  .xl\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }
  .xl\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }
  .xl\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }
  .xl\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }
  .xl\:hover\:scale-y-50:hover {
    --tw-scale-y: 0.5;
  }
  .xl\:hover\:scale-y-75:hover {
    --tw-scale-y: 0.75;
  }
  .xl\:hover\:scale-y-90:hover {
    --tw-scale-y: 0.9;
  }
  .xl\:hover\:scale-y-95:hover {
    --tw-scale-y: 0.95;
  }
  .xl\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }
  .xl\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }
  .xl\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }
  .xl\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }
  .xl\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }
  .xl\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }
  .xl\:focus\:scale-x-50:focus {
    --tw-scale-x: 0.5;
  }
  .xl\:focus\:scale-x-75:focus {
    --tw-scale-x: 0.75;
  }
  .xl\:focus\:scale-x-90:focus {
    --tw-scale-x: 0.9;
  }
  .xl\:focus\:scale-x-95:focus {
    --tw-scale-x: 0.95;
  }
  .xl\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }
  .xl\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }
  .xl\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }
  .xl\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }
  .xl\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }
  .xl\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }
  .xl\:focus\:scale-y-50:focus {
    --tw-scale-y: 0.5;
  }
  .xl\:focus\:scale-y-75:focus {
    --tw-scale-y: 0.75;
  }
  .xl\:focus\:scale-y-90:focus {
    --tw-scale-y: 0.9;
  }
  .xl\:focus\:scale-y-95:focus {
    --tw-scale-y: 0.95;
  }
  .xl\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }
  .xl\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }
  .xl\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }
  .xl\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }
  .xl\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }
  .xl\:animate-none {
    animation: none;
  }
  .xl\:animate-spin {
    animation: spin 1s linear infinite;
  }
  .xl\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .xl\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .xl\:animate-bounce {
    animation: bounce 1s infinite;
  }
  .xl\:cursor-auto {
    cursor: auto;
  }
  .xl\:cursor-default {
    cursor: default;
  }
  .xl\:cursor-pointer {
    cursor: pointer;
  }
  .xl\:cursor-wait {
    cursor: wait;
  }
  .xl\:cursor-text {
    cursor: text;
  }
  .xl\:cursor-move {
    cursor: move;
  }
  .xl\:cursor-help {
    cursor: help;
  }
  .xl\:cursor-not-allowed {
    cursor: not-allowed;
  }
  .xl\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }
  .xl\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }
  .xl\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }
  .xl\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }
  .xl\:resize-none {
    resize: none;
  }
  .xl\:resize-y {
    resize: vertical;
  }
  .xl\:resize-x {
    resize: horizontal;
  }
  .xl\:resize {
    resize: both;
  }
  .xl\:list-inside {
    list-style-position: inside;
  }
  .xl\:list-outside {
    list-style-position: outside;
  }
  .xl\:list-none {
    list-style-type: none;
  }
  .xl\:list-disc {
    list-style-type: disc;
  }
  .xl\:list-decimal {
    list-style-type: decimal;
  }
  .xl\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }
  .xl\:auto-cols-auto {
    grid-auto-columns: auto;
  }
  .xl\:auto-cols-min {
    grid-auto-columns: min-content;
  }
  .xl\:auto-cols-max {
    grid-auto-columns: max-content;
  }
  .xl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }
  .xl\:grid-flow-row {
    grid-auto-flow: row;
  }
  .xl\:grid-flow-col {
    grid-auto-flow: column;
  }
  .xl\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }
  .xl\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }
  .xl\:auto-rows-auto {
    grid-auto-rows: auto;
  }
  .xl\:auto-rows-min {
    grid-auto-rows: min-content;
  }
  .xl\:auto-rows-max {
    grid-auto-rows: max-content;
  }
  .xl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }
  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .xl\:grid-cols-none {
    grid-template-columns: none;
  }
  .xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  .xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .xl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .xl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .xl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .xl\:grid-rows-none {
    grid-template-rows: none;
  }
  .xl\:flex-row {
    flex-direction: row;
  }
  .xl\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .xl\:flex-col {
    flex-direction: column;
  }
  .xl\:flex-col-reverse {
    flex-direction: column-reverse;
  }
  .xl\:flex-wrap {
    flex-wrap: wrap;
  }
  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .xl\:flex-nowrap {
    flex-wrap: nowrap;
  }
  .xl\:place-content-center {
    place-content: center;
  }
  .xl\:place-content-start {
    place-content: start;
  }
  .xl\:place-content-end {
    place-content: end;
  }
  .xl\:place-content-between {
    place-content: space-between;
  }
  .xl\:place-content-around {
    place-content: space-around;
  }
  .xl\:place-content-evenly {
    place-content: space-evenly;
  }
  .xl\:place-content-stretch {
    place-content: stretch;
  }
  .xl\:place-items-start {
    place-items: start;
  }
  .xl\:place-items-end {
    place-items: end;
  }
  .xl\:place-items-center {
    place-items: center;
  }
  .xl\:place-items-stretch {
    place-items: stretch;
  }
  .xl\:content-center {
    align-content: center;
  }
  .xl\:content-start {
    align-content: flex-start;
  }
  .xl\:content-end {
    align-content: flex-end;
  }
  .xl\:content-between {
    align-content: space-between;
  }
  .xl\:content-around {
    align-content: space-around;
  }
  .xl\:content-evenly {
    align-content: space-evenly;
  }
  .xl\:items-start {
    align-items: flex-start;
  }
  .xl\:items-end {
    align-items: flex-end;
  }
  .xl\:items-center {
    align-items: center;
  }
  .xl\:items-baseline {
    align-items: baseline;
  }
  .xl\:items-stretch {
    align-items: stretch;
  }
  .xl\:justify-start {
    justify-content: flex-start;
  }
  .xl\:justify-end {
    justify-content: flex-end;
  }
  .xl\:justify-center {
    justify-content: center;
  }
  .xl\:justify-between {
    justify-content: space-between;
  }
  .xl\:justify-around {
    justify-content: space-around;
  }
  .xl\:justify-evenly {
    justify-content: space-evenly;
  }
  .xl\:justify-items-start {
    justify-items: start;
  }
  .xl\:justify-items-end {
    justify-items: end;
  }
  .xl\:justify-items-center {
    justify-items: center;
  }
  .xl\:justify-items-stretch {
    justify-items: stretch;
  }
  .xl\:gap-0 {
    gap: 0;
  }
  .xl\:gap-1 {
    gap: 0.25rem;
  }
  .xl\:gap-2 {
    gap: 0.5rem;
  }
  .xl\:gap-3 {
    gap: 0.75rem;
  }
  .xl\:gap-4 {
    gap: 1rem;
  }
  .xl\:gap-5 {
    gap: 1.25rem;
  }
  .xl\:gap-6 {
    gap: 1.5rem;
  }
  .xl\:gap-7 {
    gap: 1.75rem;
  }
  .xl\:gap-8 {
    gap: 2rem;
  }
  .xl\:gap-9 {
    gap: 2.25rem;
  }
  .xl\:gap-10 {
    gap: 2.5rem;
  }
  .xl\:gap-11 {
    gap: 2.75rem;
  }
  .xl\:gap-12 {
    gap: 3rem;
  }
  .xl\:gap-14 {
    gap: 3.5rem;
  }
  .xl\:gap-16 {
    gap: 4rem;
  }
  .xl\:gap-20 {
    gap: 5rem;
  }
  .xl\:gap-24 {
    gap: 6rem;
  }
  .xl\:gap-28 {
    gap: 7rem;
  }
  .xl\:gap-32 {
    gap: 8rem;
  }
  .xl\:gap-36 {
    gap: 9rem;
  }
  .xl\:gap-40 {
    gap: 10rem;
  }
  .xl\:gap-44 {
    gap: 11rem;
  }
  .xl\:gap-48 {
    gap: 12rem;
  }
  .xl\:gap-52 {
    gap: 13rem;
  }
  .xl\:gap-56 {
    gap: 14rem;
  }
  .xl\:gap-60 {
    gap: 15rem;
  }
  .xl\:gap-64 {
    gap: 16rem;
  }
  .xl\:gap-72 {
    gap: 18rem;
  }
  .xl\:gap-80 {
    gap: 20rem;
  }
  .xl\:gap-96 {
    gap: 24rem;
  }
  .xl\:gap-px {
    gap: 1px;
  }
  .xl\:gap-0\.5 {
    gap: 0.125rem;
  }
  .xl\:gap-1\.5 {
    gap: 0.375rem;
  }
  .xl\:gap-2\.5 {
    gap: 0.625rem;
  }
  .xl\:gap-3\.5 {
    gap: 0.875rem;
  }
  .xl\:gap-x-0 {
    column-gap: 0;
  }
  .xl\:gap-x-1 {
    column-gap: 0.25rem;
  }
  .xl\:gap-x-2 {
    column-gap: 0.5rem;
  }
  .xl\:gap-x-3 {
    column-gap: 0.75rem;
  }
  .xl\:gap-x-4 {
    column-gap: 1rem;
  }
  .xl\:gap-x-5 {
    column-gap: 1.25rem;
  }
  .xl\:gap-x-6 {
    column-gap: 1.5rem;
  }
  .xl\:gap-x-7 {
    column-gap: 1.75rem;
  }
  .xl\:gap-x-8 {
    column-gap: 2rem;
  }
  .xl\:gap-x-9 {
    column-gap: 2.25rem;
  }
  .xl\:gap-x-10 {
    column-gap: 2.5rem;
  }
  .xl\:gap-x-11 {
    column-gap: 2.75rem;
  }
  .xl\:gap-x-12 {
    column-gap: 3rem;
  }
  .xl\:gap-x-14 {
    column-gap: 3.5rem;
  }
  .xl\:gap-x-16 {
    column-gap: 4rem;
  }
  .xl\:gap-x-20 {
    column-gap: 5rem;
  }
  .xl\:gap-x-24 {
    column-gap: 6rem;
  }
  .xl\:gap-x-28 {
    column-gap: 7rem;
  }
  .xl\:gap-x-32 {
    column-gap: 8rem;
  }
  .xl\:gap-x-36 {
    column-gap: 9rem;
  }
  .xl\:gap-x-40 {
    column-gap: 10rem;
  }
  .xl\:gap-x-44 {
    column-gap: 11rem;
  }
  .xl\:gap-x-48 {
    column-gap: 12rem;
  }
  .xl\:gap-x-52 {
    column-gap: 13rem;
  }
  .xl\:gap-x-56 {
    column-gap: 14rem;
  }
  .xl\:gap-x-60 {
    column-gap: 15rem;
  }
  .xl\:gap-x-64 {
    column-gap: 16rem;
  }
  .xl\:gap-x-72 {
    column-gap: 18rem;
  }
  .xl\:gap-x-80 {
    column-gap: 20rem;
  }
  .xl\:gap-x-96 {
    column-gap: 24rem;
  }
  .xl\:gap-x-px {
    column-gap: 1px;
  }
  .xl\:gap-x-0\.5 {
    column-gap: 0.125rem;
  }
  .xl\:gap-x-1\.5 {
    column-gap: 0.375rem;
  }
  .xl\:gap-x-2\.5 {
    column-gap: 0.625rem;
  }
  .xl\:gap-x-3\.5 {
    column-gap: 0.875rem;
  }
  .xl\:gap-y-0 {
    row-gap: 0;
  }
  .xl\:gap-y-1 {
    row-gap: 0.25rem;
  }
  .xl\:gap-y-2 {
    row-gap: 0.5rem;
  }
  .xl\:gap-y-3 {
    row-gap: 0.75rem;
  }
  .xl\:gap-y-4 {
    row-gap: 1rem;
  }
  .xl\:gap-y-5 {
    row-gap: 1.25rem;
  }
  .xl\:gap-y-6 {
    row-gap: 1.5rem;
  }
  .xl\:gap-y-7 {
    row-gap: 1.75rem;
  }
  .xl\:gap-y-8 {
    row-gap: 2rem;
  }
  .xl\:gap-y-9 {
    row-gap: 2.25rem;
  }
  .xl\:gap-y-10 {
    row-gap: 2.5rem;
  }
  .xl\:gap-y-11 {
    row-gap: 2.75rem;
  }
  .xl\:gap-y-12 {
    row-gap: 3rem;
  }
  .xl\:gap-y-14 {
    row-gap: 3.5rem;
  }
  .xl\:gap-y-16 {
    row-gap: 4rem;
  }
  .xl\:gap-y-20 {
    row-gap: 5rem;
  }
  .xl\:gap-y-24 {
    row-gap: 6rem;
  }
  .xl\:gap-y-28 {
    row-gap: 7rem;
  }
  .xl\:gap-y-32 {
    row-gap: 8rem;
  }
  .xl\:gap-y-36 {
    row-gap: 9rem;
  }
  .xl\:gap-y-40 {
    row-gap: 10rem;
  }
  .xl\:gap-y-44 {
    row-gap: 11rem;
  }
  .xl\:gap-y-48 {
    row-gap: 12rem;
  }
  .xl\:gap-y-52 {
    row-gap: 13rem;
  }
  .xl\:gap-y-56 {
    row-gap: 14rem;
  }
  .xl\:gap-y-60 {
    row-gap: 15rem;
  }
  .xl\:gap-y-64 {
    row-gap: 16rem;
  }
  .xl\:gap-y-72 {
    row-gap: 18rem;
  }
  .xl\:gap-y-80 {
    row-gap: 20rem;
  }
  .xl\:gap-y-96 {
    row-gap: 24rem;
  }
  .xl\:gap-y-px {
    row-gap: 1px;
  }
  .xl\:gap-y-0\.5 {
    row-gap: 0.125rem;
  }
  .xl\:gap-y-1\.5 {
    row-gap: 0.375rem;
  }
  .xl\:gap-y-2\.5 {
    row-gap: 0.625rem;
  }
  .xl\:gap-y-3\.5 {
    row-gap: 0.875rem;
  }
  .xl\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .xl\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .xl\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }
  .xl\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }
  .xl\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }
  .xl\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }
  .xl\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }
  .xl\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .xl\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .xl\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .xl\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .xl\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .xl\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }
  .xl\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }
  .xl\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }
  .xl\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }
  .xl\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }
  .xl\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }
  .xl\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }
  .xl\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }
  .xl\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }
  .xl\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }
  .xl\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }
  .xl\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }
  .xl\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }
  .xl\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }
  .xl\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }
  .xl\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }
  .xl\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }
  .xl\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }
  .xl\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }
  .xl\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }
  .xl\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }
  .xl\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }
  .xl\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }
  .xl\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }
  .xl\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }
  .xl\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }
  .xl\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }
  .xl\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }
  .xl\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }
  .xl\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }
  .xl\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }
  .xl\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }
  .xl\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }
  .xl\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }
  .xl\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }
  .xl\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }
  .xl\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }
  .xl\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }
  .xl\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }
  .xl\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }
  .xl\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }
  .xl\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }
  .xl\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }
  .xl\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }
  .xl\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }
  .xl\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }
  .xl\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }
  .xl\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }
  .xl\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }
  .xl\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }
  .xl\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }
  .xl\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }
  .xl\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }
  .xl\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }
  .xl\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }
  .xl\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }
  .xl\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }
  .xl\:place-self-auto {
    place-self: auto;
  }
  .xl\:place-self-start {
    place-self: start;
  }
  .xl\:place-self-end {
    place-self: end;
  }
  .xl\:place-self-center {
    place-self: center;
  }
  .xl\:place-self-stretch {
    place-self: stretch;
  }
  .xl\:self-auto {
    align-self: auto;
  }
  .xl\:self-start {
    align-self: flex-start;
  }
  .xl\:self-end {
    align-self: flex-end;
  }
  .xl\:self-center {
    align-self: center;
  }
  .xl\:self-stretch {
    align-self: stretch;
  }
  .xl\:self-baseline {
    align-self: baseline;
  }
  .xl\:justify-self-auto {
    justify-self: auto;
  }
  .xl\:justify-self-start {
    justify-self: start;
  }
  .xl\:justify-self-end {
    justify-self: end;
  }
  .xl\:justify-self-center {
    justify-self: center;
  }
  .xl\:justify-self-stretch {
    justify-self: stretch;
  }
  .xl\:overflow-auto {
    overflow: auto;
  }
  .xl\:overflow-hidden {
    overflow: hidden;
  }
  .xl\:overflow-visible {
    overflow: visible;
  }
  .xl\:overflow-scroll {
    overflow: scroll;
  }
  .xl\:overflow-x-auto {
    overflow-x: auto;
  }
  .xl\:overflow-y-auto {
    overflow-y: auto;
  }
  .xl\:overflow-x-hidden {
    overflow-x: hidden;
  }
  .xl\:overflow-y-hidden {
    overflow-y: hidden;
  }
  .xl\:overflow-x-visible {
    overflow-x: visible;
  }
  .xl\:overflow-y-visible {
    overflow-y: visible;
  }
  .xl\:overflow-x-scroll {
    overflow-x: scroll;
  }
  .xl\:overflow-y-scroll {
    overflow-y: scroll;
  }
  .xl\:overscroll-auto {
    overscroll-behavior: auto;
  }
  .xl\:overscroll-contain {
    overscroll-behavior: contain;
  }
  .xl\:overscroll-none {
    overscroll-behavior: none;
  }
  .xl\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }
  .xl\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }
  .xl\:overscroll-y-none {
    overscroll-behavior-y: none;
  }
  .xl\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }
  .xl\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }
  .xl\:overscroll-x-none {
    overscroll-behavior-x: none;
  }
  .xl\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .xl\:overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .xl\:overflow-clip {
    text-overflow: clip;
  }
  .xl\:whitespace-normal {
    white-space: normal;
  }
  .xl\:whitespace-nowrap {
    white-space: nowrap;
  }
  .xl\:whitespace-pre {
    white-space: pre;
  }
  .xl\:whitespace-pre-line {
    white-space: pre-line;
  }
  .xl\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }
  .xl\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .xl\:break-words {
    overflow-wrap: break-word;
  }
  .xl\:break-all {
    word-break: break-all;
  }
  .xl\:rounded-none {
    border-radius: 0;
  }
  .xl\:rounded-sm {
    border-radius: 0.125rem;
  }
  .xl\:rounded {
    border-radius: 0.25rem;
  }
  .xl\:rounded-md {
    border-radius: 0.375rem;
  }
  .xl\:rounded-lg {
    border-radius: 0.5rem;
  }
  .xl\:rounded-xl {
    border-radius: 0.75rem;
  }
  .xl\:rounded-2xl {
    border-radius: 1rem;
  }
  .xl\:rounded-3xl {
    border-radius: 1.5rem;
  }
  .xl\:rounded-full {
    border-radius: 9999px;
  }
  .xl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .xl\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }
  .xl\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .xl\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .xl\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .xl\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .xl\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .xl\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
  .xl\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }
  .xl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .xl\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
  .xl\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .xl\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .xl\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .xl\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .xl\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .xl\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  .xl\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }
  .xl\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .xl\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .xl\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .xl\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .xl\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .xl\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .xl\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .xl\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .xl\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .xl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .xl\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .xl\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .xl\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .xl\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .xl\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .xl\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .xl\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .xl\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .xl\:rounded-tl-none {
    border-top-left-radius: 0;
  }
  .xl\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }
  .xl\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }
  .xl\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }
  .xl\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }
  .xl\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }
  .xl\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }
  .xl\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }
  .xl\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }
  .xl\:rounded-tr-none {
    border-top-right-radius: 0;
  }
  .xl\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }
  .xl\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }
  .xl\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }
  .xl\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }
  .xl\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }
  .xl\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }
  .xl\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }
  .xl\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }
  .xl\:rounded-br-none {
    border-bottom-right-radius: 0;
  }
  .xl\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }
  .xl\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }
  .xl\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }
  .xl\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }
  .xl\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }
  .xl\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }
  .xl\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }
  .xl\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }
  .xl\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }
  .xl\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }
  .xl\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }
  .xl\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }
  .xl\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }
  .xl\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }
  .xl\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }
  .xl\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }
  .xl\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }
  .xl\:border-0 {
    border-width: 0;
  }
  .xl\:border-2 {
    border-width: 2px;
  }
  .xl\:border-4 {
    border-width: 4px;
  }
  .xl\:border-8 {
    border-width: 8px;
  }
  .xl\:border {
    border-width: 1px;
  }
  .xl\:border-t-0 {
    border-top-width: 0;
  }
  .xl\:border-t-2 {
    border-top-width: 2px;
  }
  .xl\:border-t-4 {
    border-top-width: 4px;
  }
  .xl\:border-t-8 {
    border-top-width: 8px;
  }
  .xl\:border-t {
    border-top-width: 1px;
  }
  .xl\:border-r-0 {
    border-right-width: 0;
  }
  .xl\:border-r-2 {
    border-right-width: 2px;
  }
  .xl\:border-r-4 {
    border-right-width: 4px;
  }
  .xl\:border-r-8 {
    border-right-width: 8px;
  }
  .xl\:border-r {
    border-right-width: 1px;
  }
  .xl\:border-b-0 {
    border-bottom-width: 0;
  }
  .xl\:border-b-2 {
    border-bottom-width: 2px;
  }
  .xl\:border-b-4 {
    border-bottom-width: 4px;
  }
  .xl\:border-b-8 {
    border-bottom-width: 8px;
  }
  .xl\:border-b {
    border-bottom-width: 1px;
  }
  .xl\:border-l-0 {
    border-left-width: 0;
  }
  .xl\:border-l-2 {
    border-left-width: 2px;
  }
  .xl\:border-l-4 {
    border-left-width: 4px;
  }
  .xl\:border-l-8 {
    border-left-width: 8px;
  }
  .xl\:border-l {
    border-left-width: 1px;
  }
  .xl\:border-solid {
    border-style: solid;
  }
  .xl\:border-dashed {
    border-style: dashed;
  }
  .xl\:border-dotted {
    border-style: dotted;
  }
  .xl\:border-double {
    border-style: double;
  }
  .xl\:border-none {
    border-style: none;
  }
  .xl\:border-transparent {
    border-color: transparent;
  }
  .xl\:border-current {
    border-color: currentColor;
  }
  .xl\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xl\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xl\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xl\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xl\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xl\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .xl\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xl\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xl\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xl\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xl\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xl\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .xl\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xl\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xl\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xl\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xl\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xl\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xl\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xl\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xl\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xl\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xl\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xl\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xl\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xl\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xl\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xl\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xl\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xl\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xl\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xl\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xl\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xl\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xl\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xl\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xl\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xl\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xl\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xl\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xl\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xl\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xl\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xl\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xl\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xl\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xl\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xl\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xl\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xl\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xl\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xl\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xl\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xl\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xl\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xl\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xl\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xl\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xl\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xl\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xl\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xl\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xl\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xl\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xl\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xl\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xl\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xl\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xl\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xl\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xl\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xl\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xl\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xl\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xl\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xl\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xl\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xl\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xl\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xl\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xl\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xl\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-transparent {
    border-color: transparent;
  }
  .group:hover .xl\:group-hover\:border-current {
    border-color: currentColor;
  }
  .group:hover .xl\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .group:hover .xl\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-transparent:focus-within {
    border-color: transparent;
  }
  .xl\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }
  .xl\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xl\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xl\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .xl\:hover\:border-current:hover {
    border-color: currentColor;
  }
  .xl\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xl\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xl\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xl\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xl\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xl\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xl\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xl\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xl\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xl\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xl\:focus\:border-transparent:focus {
    border-color: transparent;
  }
  .xl\:focus\:border-current:focus {
    border-color: currentColor;
  }
  .xl\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .xl\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .xl\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .xl\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .xl\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .xl\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .xl\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .xl\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .xl\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .xl\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .xl\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .xl\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .xl\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .xl\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .xl\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .xl\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .xl\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .xl\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .xl\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .xl\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .xl\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .xl\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .xl\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .xl\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .xl\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .group:hover .xl\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .group:hover .xl\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .group:hover .xl\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .group:hover .xl\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .group:hover .xl\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .group:hover .xl\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .group:hover .xl\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .group:hover .xl\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .group:hover .xl\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .group:hover .xl\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .group:hover .xl\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .group:hover .xl\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .group:hover .xl\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .group:hover .xl\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .group:hover .xl\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .xl\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }
  .xl\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }
  .xl\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }
  .xl\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }
  .xl\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }
  .xl\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }
  .xl\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }
  .xl\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }
  .xl\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }
  .xl\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }
  .xl\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }
  .xl\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }
  .xl\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }
  .xl\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }
  .xl\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }
  .xl\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }
  .xl\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }
  .xl\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }
  .xl\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }
  .xl\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }
  .xl\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }
  .xl\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }
  .xl\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }
  .xl\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }
  .xl\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }
  .xl\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }
  .xl\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }
  .xl\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }
  .xl\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }
  .xl\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }
  .xl\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }
  .xl\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }
  .xl\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }
  .xl\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }
  .xl\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }
  .xl\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }
  .xl\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }
  .xl\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }
  .xl\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }
  .xl\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }
  .xl\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }
  .xl\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }
  .xl\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }
  .xl\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }
  .xl\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }
  .xl\:bg-transparent {
    background-color: transparent;
  }
  .xl\:bg-current {
    background-color: currentColor;
  }
  .xl\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xl\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xl\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .xl\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xl\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xl\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xl\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xl\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xl\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xl\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xl\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xl\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xl\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xl\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xl\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xl\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xl\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xl\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xl\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xl\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xl\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xl\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xl\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xl\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xl\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xl\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xl\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xl\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-transparent {
    background-color: transparent;
  }
  .group:hover .xl\:group-hover\:bg-current {
    background-color: currentColor;
  }
  .group:hover .xl\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .group:hover .xl\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-transparent:focus-within {
    background-color: transparent;
  }
  .xl\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }
  .xl\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xl\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-transparent:hover {
    background-color: transparent;
  }
  .xl\:hover\:bg-current:hover {
    background-color: currentColor;
  }
  .xl\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xl\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-transparent:focus {
    background-color: transparent;
  }
  .xl\:focus\:bg-current:focus {
    background-color: currentColor;
  }
  .xl\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .xl\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .xl\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .xl\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .xl\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .xl\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .xl\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .xl\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .xl\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .xl\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .xl\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .xl\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .xl\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .xl\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .xl\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .xl\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .xl\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .group:hover .xl\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .group:hover .xl\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .group:hover .xl\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .group:hover .xl\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .group:hover .xl\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .group:hover .xl\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .group:hover .xl\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .group:hover .xl\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .group:hover .xl\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .group:hover .xl\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .group:hover .xl\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .group:hover .xl\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .group:hover .xl\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .group:hover .xl\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .group:hover .xl\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .xl\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }
  .xl\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }
  .xl\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }
  .xl\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }
  .xl\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }
  .xl\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }
  .xl\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }
  .xl\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }
  .xl\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }
  .xl\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }
  .xl\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }
  .xl\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }
  .xl\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }
  .xl\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }
  .xl\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }
  .xl\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }
  .xl\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }
  .xl\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }
  .xl\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }
  .xl\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }
  .xl\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }
  .xl\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }
  .xl\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }
  .xl\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }
  .xl\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }
  .xl\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }
  .xl\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }
  .xl\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }
  .xl\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }
  .xl\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }
  .xl\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }
  .xl\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }
  .xl\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }
  .xl\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }
  .xl\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }
  .xl\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }
  .xl\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }
  .xl\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }
  .xl\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }
  .xl\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }
  .xl\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }
  .xl\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }
  .xl\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }
  .xl\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }
  .xl\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }
  .xl\:bg-none {
    background-image: none;
  }
  .xl\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }
  .xl\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }
  .xl\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }
  .xl\:bg-gradient-to-br {
    background-image: linear-gradient(
      to bottom right,
      var(--tw-gradient-stops)
    );
  }
  .xl\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }
  .xl\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }
  .xl\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }
  .xl\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }
  .xl\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .xl\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .xl\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .xl\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .xl\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xl\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xl\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .xl\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xl\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xl\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xl\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xl\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xl\:from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .xl\:from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xl\:from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xl\:from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xl\:from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xl\:from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xl\:from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xl\:from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xl\:from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xl\:from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xl\:from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xl\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xl\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xl\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xl\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xl\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xl\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xl\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xl\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xl\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xl\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xl\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xl\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xl\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xl\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xl\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xl\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xl\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xl\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xl\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xl\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xl\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xl\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xl\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xl\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xl\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xl\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xl\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xl\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xl\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xl\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xl\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xl\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xl\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xl\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xl\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xl\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xl\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xl\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xl\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xl\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xl\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xl\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xl\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xl\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xl\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xl\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xl\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xl\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xl\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xl\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xl\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xl\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xl\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xl\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xl\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xl\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xl\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xl\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xl\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xl\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xl\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .xl\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .xl\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .xl\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .xl\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xl\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xl\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:hover\:from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .xl\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xl\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xl\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xl\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xl\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xl\:hover\:from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .xl\:hover\:from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xl\:hover\:from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xl\:hover\:from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xl\:hover\:from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xl\:hover\:from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xl\:hover\:from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xl\:hover\:from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xl\:hover\:from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xl\:hover\:from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xl\:hover\:from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xl\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xl\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xl\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xl\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xl\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xl\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xl\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xl\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xl\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xl\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xl\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xl\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xl\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xl\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xl\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xl\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xl\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xl\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xl\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xl\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xl\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xl\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xl\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xl\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xl\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xl\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xl\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xl\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xl\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xl\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xl\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xl\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xl\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xl\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xl\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xl\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xl\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xl\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xl\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xl\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xl\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xl\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xl\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xl\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xl\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xl\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xl\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xl\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xl\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xl\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xl\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xl\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xl\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xl\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xl\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xl\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xl\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xl\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xl\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xl\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xl\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .xl\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .xl\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .xl\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .xl\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xl\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xl\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:focus\:from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .xl\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xl\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xl\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xl\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xl\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xl\:focus\:from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .xl\:focus\:from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xl\:focus\:from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xl\:focus\:from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xl\:focus\:from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xl\:focus\:from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xl\:focus\:from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xl\:focus\:from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xl\:focus\:from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xl\:focus\:from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xl\:focus\:from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xl\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xl\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xl\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xl\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xl\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xl\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xl\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xl\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xl\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xl\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xl\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xl\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xl\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xl\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xl\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xl\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xl\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xl\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xl\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xl\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xl\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xl\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xl\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xl\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xl\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xl\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xl\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xl\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xl\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xl\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xl\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xl\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xl\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xl\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xl\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xl\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xl\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xl\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xl\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xl\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xl\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xl\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xl\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xl\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xl\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xl\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xl\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xl\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xl\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xl\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xl\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xl\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xl\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xl\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xl\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xl\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xl\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xl\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xl\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xl\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xl\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .xl\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .xl\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .xl\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .xl\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xl\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xl\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .xl\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xl\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xl\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xl\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xl\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xl\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827,
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .xl\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xl\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xl\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xl\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xl\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xl\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xl\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xl\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xl\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xl\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xl\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xl\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xl\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xl\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xl\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xl\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xl\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xl\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xl\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xl\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xl\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xl\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xl\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xl\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xl\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xl\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xl\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xl\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xl\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xl\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xl\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xl\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xl\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xl\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xl\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xl\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xl\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xl\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xl\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xl\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xl\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xl\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xl\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xl\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xl\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xl\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xl\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xl\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xl\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xl\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xl\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xl\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xl\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xl\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xl\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xl\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xl\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xl\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xl\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xl\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xl\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xl\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xl\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xl\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xl\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xl\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xl\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xl\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xl\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xl\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xl\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .xl\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .xl\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .xl\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .xl\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xl\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xl\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .xl\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xl\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xl\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xl\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xl\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xl\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827,
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .xl\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xl\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xl\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xl\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xl\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xl\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xl\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xl\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xl\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xl\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xl\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xl\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xl\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xl\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xl\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xl\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xl\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xl\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xl\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xl\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xl\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xl\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xl\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xl\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xl\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xl\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xl\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xl\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xl\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xl\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xl\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xl\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xl\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xl\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xl\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xl\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xl\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xl\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xl\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xl\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xl\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xl\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xl\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xl\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xl\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xl\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xl\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xl\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xl\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xl\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xl\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xl\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xl\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xl\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xl\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xl\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xl\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xl\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xl\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xl\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xl\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xl\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xl\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xl\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xl\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xl\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xl\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xl\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xl\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xl\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xl\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .xl\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .xl\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .xl\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .xl\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .xl\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .xl\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .xl\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .xl\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .xl\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .xl\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .xl\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .xl\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .xl\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827,
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .xl\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .xl\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .xl\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .xl\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .xl\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .xl\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .xl\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .xl\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .xl\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .xl\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .xl\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .xl\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .xl\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .xl\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .xl\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .xl\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .xl\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .xl\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .xl\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .xl\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .xl\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .xl\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .xl\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .xl\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .xl\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .xl\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .xl\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .xl\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .xl\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .xl\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .xl\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .xl\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .xl\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .xl\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .xl\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .xl\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .xl\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .xl\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .xl\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .xl\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .xl\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .xl\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .xl\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .xl\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .xl\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .xl\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .xl\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .xl\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .xl\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .xl\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .xl\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .xl\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .xl\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .xl\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .xl\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .xl\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .xl\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .xl\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .xl\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .xl\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .xl\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .xl\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .xl\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .xl\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .xl\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .xl\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .xl\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .xl\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .xl\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .xl\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .xl\:to-transparent {
    --tw-gradient-to: transparent;
  }
  .xl\:to-current {
    --tw-gradient-to: currentColor;
  }
  .xl\:to-black {
    --tw-gradient-to: #000;
  }
  .xl\:to-white {
    --tw-gradient-to: #fff;
  }
  .xl\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }
  .xl\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }
  .xl\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }
  .xl\:to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }
  .xl\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }
  .xl\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }
  .xl\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }
  .xl\:to-gray-700 {
    --tw-gradient-to: #374151;
  }
  .xl\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }
  .xl\:to-gray-900 {
    --tw-gradient-to: #111827;
  }
  .xl\:to-red-50 {
    --tw-gradient-to: #fef2f2;
  }
  .xl\:to-red-100 {
    --tw-gradient-to: #fee2e2;
  }
  .xl\:to-red-200 {
    --tw-gradient-to: #fecaca;
  }
  .xl\:to-red-300 {
    --tw-gradient-to: #fca5a5;
  }
  .xl\:to-red-400 {
    --tw-gradient-to: #f87171;
  }
  .xl\:to-red-500 {
    --tw-gradient-to: #ef4444;
  }
  .xl\:to-red-600 {
    --tw-gradient-to: #dc2626;
  }
  .xl\:to-red-700 {
    --tw-gradient-to: #b91c1c;
  }
  .xl\:to-red-800 {
    --tw-gradient-to: #991b1b;
  }
  .xl\:to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }
  .xl\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }
  .xl\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }
  .xl\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }
  .xl\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }
  .xl\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }
  .xl\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }
  .xl\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }
  .xl\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }
  .xl\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }
  .xl\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }
  .xl\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }
  .xl\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }
  .xl\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }
  .xl\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }
  .xl\:to-green-400 {
    --tw-gradient-to: #34d399;
  }
  .xl\:to-green-500 {
    --tw-gradient-to: #10b981;
  }
  .xl\:to-green-600 {
    --tw-gradient-to: #059669;
  }
  .xl\:to-green-700 {
    --tw-gradient-to: #047857;
  }
  .xl\:to-green-800 {
    --tw-gradient-to: #065f46;
  }
  .xl\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }
  .xl\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }
  .xl\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }
  .xl\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }
  .xl\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }
  .xl\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }
  .xl\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }
  .xl\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }
  .xl\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }
  .xl\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }
  .xl\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }
  .xl\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }
  .xl\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }
  .xl\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }
  .xl\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }
  .xl\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }
  .xl\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }
  .xl\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }
  .xl\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }
  .xl\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }
  .xl\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }
  .xl\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }
  .xl\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }
  .xl\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }
  .xl\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }
  .xl\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }
  .xl\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }
  .xl\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }
  .xl\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }
  .xl\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }
  .xl\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }
  .xl\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }
  .xl\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }
  .xl\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }
  .xl\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }
  .xl\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }
  .xl\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }
  .xl\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }
  .xl\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }
  .xl\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }
  .xl\:to-pink-900 {
    --tw-gradient-to: #831843;
  }
  .xl\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }
  .xl\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }
  .xl\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }
  .xl\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }
  .xl\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }
  .xl\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }
  .xl\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .xl\:hover\:to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }
  .xl\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }
  .xl\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }
  .xl\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }
  .xl\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }
  .xl\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }
  .xl\:hover\:to-gray-900:hover {
    --tw-gradient-to: #111827;
  }
  .xl\:hover\:to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }
  .xl\:hover\:to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }
  .xl\:hover\:to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }
  .xl\:hover\:to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }
  .xl\:hover\:to-red-400:hover {
    --tw-gradient-to: #f87171;
  }
  .xl\:hover\:to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }
  .xl\:hover\:to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }
  .xl\:hover\:to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }
  .xl\:hover\:to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }
  .xl\:hover\:to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }
  .xl\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }
  .xl\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }
  .xl\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }
  .xl\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }
  .xl\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }
  .xl\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }
  .xl\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }
  .xl\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }
  .xl\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }
  .xl\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }
  .xl\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }
  .xl\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }
  .xl\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }
  .xl\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }
  .xl\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }
  .xl\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }
  .xl\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }
  .xl\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }
  .xl\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }
  .xl\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }
  .xl\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }
  .xl\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }
  .xl\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }
  .xl\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }
  .xl\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }
  .xl\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }
  .xl\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }
  .xl\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }
  .xl\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }
  .xl\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }
  .xl\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }
  .xl\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }
  .xl\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }
  .xl\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }
  .xl\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }
  .xl\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }
  .xl\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }
  .xl\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }
  .xl\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }
  .xl\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }
  .xl\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }
  .xl\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }
  .xl\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }
  .xl\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }
  .xl\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }
  .xl\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }
  .xl\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }
  .xl\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }
  .xl\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }
  .xl\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }
  .xl\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }
  .xl\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }
  .xl\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }
  .xl\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }
  .xl\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }
  .xl\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }
  .xl\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }
  .xl\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }
  .xl\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }
  .xl\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }
  .xl\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }
  .xl\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }
  .xl\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }
  .xl\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }
  .xl\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }
  .xl\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }
  .xl\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .xl\:focus\:to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }
  .xl\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }
  .xl\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }
  .xl\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }
  .xl\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }
  .xl\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }
  .xl\:focus\:to-gray-900:focus {
    --tw-gradient-to: #111827;
  }
  .xl\:focus\:to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }
  .xl\:focus\:to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }
  .xl\:focus\:to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }
  .xl\:focus\:to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }
  .xl\:focus\:to-red-400:focus {
    --tw-gradient-to: #f87171;
  }
  .xl\:focus\:to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }
  .xl\:focus\:to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }
  .xl\:focus\:to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }
  .xl\:focus\:to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }
  .xl\:focus\:to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }
  .xl\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }
  .xl\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }
  .xl\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }
  .xl\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }
  .xl\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }
  .xl\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }
  .xl\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }
  .xl\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }
  .xl\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }
  .xl\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }
  .xl\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }
  .xl\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }
  .xl\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }
  .xl\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }
  .xl\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }
  .xl\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }
  .xl\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }
  .xl\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }
  .xl\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }
  .xl\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }
  .xl\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }
  .xl\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }
  .xl\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }
  .xl\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }
  .xl\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }
  .xl\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }
  .xl\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }
  .xl\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }
  .xl\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }
  .xl\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }
  .xl\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }
  .xl\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }
  .xl\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }
  .xl\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }
  .xl\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }
  .xl\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }
  .xl\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }
  .xl\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }
  .xl\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }
  .xl\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }
  .xl\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }
  .xl\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }
  .xl\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }
  .xl\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }
  .xl\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }
  .xl\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }
  .xl\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }
  .xl\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }
  .xl\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }
  .xl\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }
  .xl\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }
  .xl\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }
  .xl\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }
  .xl\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }
  .xl\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }
  .xl\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }
  .xl\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }
  .xl\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }
  .xl\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }
  .xl\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }
  .xl\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }
  .xl\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
  .xl\:bg-auto {
    background-size: auto;
  }
  .xl\:bg-cover {
    background-size: cover;
  }
  .xl\:bg-contain {
    background-size: contain;
  }
  .xl\:bg-fixed {
    background-attachment: fixed;
  }
  .xl\:bg-local {
    background-attachment: local;
  }
  .xl\:bg-scroll {
    background-attachment: scroll;
  }
  .xl\:bg-clip-border {
    background-clip: border-box;
  }
  .xl\:bg-clip-padding {
    background-clip: padding-box;
  }
  .xl\:bg-clip-content {
    background-clip: content-box;
  }
  .xl\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }
  .xl\:bg-bottom {
    background-position: bottom;
  }
  .xl\:bg-center {
    background-position: center;
  }
  .xl\:bg-left {
    background-position: left;
  }
  .xl\:bg-left-bottom {
    background-position: left bottom;
  }
  .xl\:bg-left-top {
    background-position: left top;
  }
  .xl\:bg-right {
    background-position: right;
  }
  .xl\:bg-right-bottom {
    background-position: right bottom;
  }
  .xl\:bg-right-top {
    background-position: right top;
  }
  .xl\:bg-top {
    background-position: top;
  }
  .xl\:bg-repeat {
    background-repeat: repeat;
  }
  .xl\:bg-no-repeat {
    background-repeat: no-repeat;
  }
  .xl\:bg-repeat-x {
    background-repeat: repeat-x;
  }
  .xl\:bg-repeat-y {
    background-repeat: repeat-y;
  }
  .xl\:bg-repeat-round {
    background-repeat: round;
  }
  .xl\:bg-repeat-space {
    background-repeat: space;
  }
  .xl\:bg-origin-border {
    background-origin: border-box;
  }
  .xl\:bg-origin-padding {
    background-origin: padding-box;
  }
  .xl\:bg-origin-content {
    background-origin: content-box;
  }
  .xl\:fill-current {
    fill: currentColor;
  }
  .xl\:stroke-current {
    stroke: currentColor;
  }
  .xl\:stroke-0 {
    stroke-width: 0;
  }
  .xl\:stroke-1 {
    stroke-width: 1;
  }
  .xl\:stroke-2 {
    stroke-width: 2;
  }
  .xl\:object-contain {
    object-fit: contain;
  }
  .xl\:object-cover {
    object-fit: cover;
  }
  .xl\:object-fill {
    object-fit: fill;
  }
  .xl\:object-none {
    object-fit: none;
  }
  .xl\:object-scale-down {
    object-fit: scale-down;
  }
  .xl\:object-bottom {
    object-position: bottom;
  }
  .xl\:object-center {
    object-position: center;
  }
  .xl\:object-left {
    object-position: left;
  }
  .xl\:object-left-bottom {
    object-position: left bottom;
  }
  .xl\:object-left-top {
    object-position: left top;
  }
  .xl\:object-right {
    object-position: right;
  }
  .xl\:object-right-bottom {
    object-position: right bottom;
  }
  .xl\:object-right-top {
    object-position: right top;
  }
  .xl\:object-top {
    object-position: top;
  }
  .xl\:p-0 {
    padding: 0;
  }
  .xl\:p-1 {
    padding: 0.25rem;
  }
  .xl\:p-2 {
    padding: 0.5rem;
  }
  .xl\:p-3 {
    padding: 0.75rem;
  }
  .xl\:p-4 {
    padding: 1rem;
  }
  .xl\:p-5 {
    padding: 1.25rem;
  }
  .xl\:p-6 {
    padding: 1.5rem;
  }
  .xl\:p-7 {
    padding: 1.75rem;
  }
  .xl\:p-8 {
    padding: 2rem;
  }
  .xl\:p-9 {
    padding: 2.25rem;
  }
  .xl\:p-10 {
    padding: 2.5rem;
  }
  .xl\:p-11 {
    padding: 2.75rem;
  }
  .xl\:p-12 {
    padding: 3rem;
  }
  .xl\:p-14 {
    padding: 3.5rem;
  }
  .xl\:p-16 {
    padding: 4rem;
  }
  .xl\:p-20 {
    padding: 5rem;
  }
  .xl\:p-24 {
    padding: 6rem;
  }
  .xl\:p-28 {
    padding: 7rem;
  }
  .xl\:p-32 {
    padding: 8rem;
  }
  .xl\:p-36 {
    padding: 9rem;
  }
  .xl\:p-40 {
    padding: 10rem;
  }
  .xl\:p-44 {
    padding: 11rem;
  }
  .xl\:p-48 {
    padding: 12rem;
  }
  .xl\:p-52 {
    padding: 13rem;
  }
  .xl\:p-56 {
    padding: 14rem;
  }
  .xl\:p-60 {
    padding: 15rem;
  }
  .xl\:p-64 {
    padding: 16rem;
  }
  .xl\:p-72 {
    padding: 18rem;
  }
  .xl\:p-80 {
    padding: 20rem;
  }
  .xl\:p-96 {
    padding: 24rem;
  }
  .xl\:p-px {
    padding: 1px;
  }
  .xl\:p-0\.5 {
    padding: 0.125rem;
  }
  .xl\:p-1\.5 {
    padding: 0.375rem;
  }
  .xl\:p-2\.5 {
    padding: 0.625rem;
  }
  .xl\:p-3\.5 {
    padding: 0.875rem;
  }
  .xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .xl\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .xl\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .xl\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .xl\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .xl\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .xl\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .xl\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .xl\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .xl\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .xl\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .xl\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .xl\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .xl\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }
  .xl\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }
  .xl\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }
  .xl\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .xl\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }
  .xl\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .xl\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }
  .xl\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .xl\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .xl\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .xl\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .xl\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .xl\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .xl\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .xl\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .xl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .xl\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .xl\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .xl\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .xl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .xl\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .xl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .xl\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .xl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .xl\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .xl\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .xl\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .xl\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .xl\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
  .xl\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
  .xl\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .xl\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  .xl\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }
  .xl\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
  .xl\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }
  .xl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .xl\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .xl\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .xl\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .xl\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .xl\:pt-0 {
    padding-top: 0;
  }
  .xl\:pt-1 {
    padding-top: 0.25rem;
  }
  .xl\:pt-2 {
    padding-top: 0.5rem;
  }
  .xl\:pt-3 {
    padding-top: 0.75rem;
  }
  .xl\:pt-4 {
    padding-top: 1rem;
  }
  .xl\:pt-5 {
    padding-top: 1.25rem;
  }
  .xl\:pt-6 {
    padding-top: 1.5rem;
  }
  .xl\:pt-7 {
    padding-top: 1.75rem;
  }
  .xl\:pt-8 {
    padding-top: 2rem;
  }
  .xl\:pt-9 {
    padding-top: 2.25rem;
  }
  .xl\:pt-10 {
    padding-top: 2.5rem;
  }
  .xl\:pt-11 {
    padding-top: 2.75rem;
  }
  .xl\:pt-12 {
    padding-top: 3rem;
  }
  .xl\:pt-14 {
    padding-top: 3.5rem;
  }
  .xl\:pt-16 {
    padding-top: 4rem;
  }
  .xl\:pt-20 {
    padding-top: 5rem;
  }
  .xl\:pt-24 {
    padding-top: 6rem;
  }
  .xl\:pt-28 {
    padding-top: 7rem;
  }
  .xl\:pt-32 {
    padding-top: 8rem;
  }
  .xl\:pt-36 {
    padding-top: 9rem;
  }
  .xl\:pt-40 {
    padding-top: 10rem;
  }
  .xl\:pt-44 {
    padding-top: 11rem;
  }
  .xl\:pt-48 {
    padding-top: 12rem;
  }
  .xl\:pt-52 {
    padding-top: 13rem;
  }
  .xl\:pt-56 {
    padding-top: 14rem;
  }
  .xl\:pt-60 {
    padding-top: 15rem;
  }
  .xl\:pt-64 {
    padding-top: 16rem;
  }
  .xl\:pt-72 {
    padding-top: 18rem;
  }
  .xl\:pt-80 {
    padding-top: 20rem;
  }
  .xl\:pt-96 {
    padding-top: 24rem;
  }
  .xl\:pt-px {
    padding-top: 1px;
  }
  .xl\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .xl\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .xl\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .xl\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .xl\:pr-0 {
    padding-right: 0;
  }
  .xl\:pr-1 {
    padding-right: 0.25rem;
  }
  .xl\:pr-2 {
    padding-right: 0.5rem;
  }
  .xl\:pr-3 {
    padding-right: 0.75rem;
  }
  .xl\:pr-4 {
    padding-right: 1rem;
  }
  .xl\:pr-5 {
    padding-right: 1.25rem;
  }
  .xl\:pr-6 {
    padding-right: 1.5rem;
  }
  .xl\:pr-7 {
    padding-right: 1.75rem;
  }
  .xl\:pr-8 {
    padding-right: 2rem;
  }
  .xl\:pr-9 {
    padding-right: 2.25rem;
  }
  .xl\:pr-10 {
    padding-right: 2.5rem;
  }
  .xl\:pr-11 {
    padding-right: 2.75rem;
  }
  .xl\:pr-12 {
    padding-right: 3rem;
  }
  .xl\:pr-14 {
    padding-right: 3.5rem;
  }
  .xl\:pr-16 {
    padding-right: 4rem;
  }
  .xl\:pr-20 {
    padding-right: 5rem;
  }
  .xl\:pr-24 {
    padding-right: 6rem;
  }
  .xl\:pr-28 {
    padding-right: 7rem;
  }
  .xl\:pr-32 {
    padding-right: 8rem;
  }
  .xl\:pr-36 {
    padding-right: 9rem;
  }
  .xl\:pr-40 {
    padding-right: 10rem;
  }
  .xl\:pr-44 {
    padding-right: 11rem;
  }
  .xl\:pr-48 {
    padding-right: 12rem;
  }
  .xl\:pr-52 {
    padding-right: 13rem;
  }
  .xl\:pr-56 {
    padding-right: 14rem;
  }
  .xl\:pr-60 {
    padding-right: 15rem;
  }
  .xl\:pr-64 {
    padding-right: 16rem;
  }
  .xl\:pr-72 {
    padding-right: 18rem;
  }
  .xl\:pr-80 {
    padding-right: 20rem;
  }
  .xl\:pr-96 {
    padding-right: 24rem;
  }
  .xl\:pr-px {
    padding-right: 1px;
  }
  .xl\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .xl\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .xl\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .xl\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .xl\:pb-0 {
    padding-bottom: 0;
  }
  .xl\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .xl\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .xl\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .xl\:pb-4 {
    padding-bottom: 1rem;
  }
  .xl\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .xl\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .xl\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .xl\:pb-8 {
    padding-bottom: 2rem;
  }
  .xl\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .xl\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .xl\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .xl\:pb-12 {
    padding-bottom: 3rem;
  }
  .xl\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .xl\:pb-16 {
    padding-bottom: 4rem;
  }
  .xl\:pb-20 {
    padding-bottom: 5rem;
  }
  .xl\:pb-24 {
    padding-bottom: 6rem;
  }
  .xl\:pb-28 {
    padding-bottom: 7rem;
  }
  .xl\:pb-32 {
    padding-bottom: 8rem;
  }
  .xl\:pb-36 {
    padding-bottom: 9rem;
  }
  .xl\:pb-40 {
    padding-bottom: 10rem;
  }
  .xl\:pb-44 {
    padding-bottom: 11rem;
  }
  .xl\:pb-48 {
    padding-bottom: 12rem;
  }
  .xl\:pb-52 {
    padding-bottom: 13rem;
  }
  .xl\:pb-56 {
    padding-bottom: 14rem;
  }
  .xl\:pb-60 {
    padding-bottom: 15rem;
  }
  .xl\:pb-64 {
    padding-bottom: 16rem;
  }
  .xl\:pb-72 {
    padding-bottom: 18rem;
  }
  .xl\:pb-80 {
    padding-bottom: 20rem;
  }
  .xl\:pb-96 {
    padding-bottom: 24rem;
  }
  .xl\:pb-px {
    padding-bottom: 1px;
  }
  .xl\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .xl\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .xl\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .xl\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .xl\:pl-0 {
    padding-left: 0;
  }
  .xl\:pl-1 {
    padding-left: 0.25rem;
  }
  .xl\:pl-2 {
    padding-left: 0.5rem;
  }
  .xl\:pl-3 {
    padding-left: 0.75rem;
  }
  .xl\:pl-4 {
    padding-left: 1rem;
  }
  .xl\:pl-5 {
    padding-left: 1.25rem;
  }
  .xl\:pl-6 {
    padding-left: 1.5rem;
  }
  .xl\:pl-7 {
    padding-left: 1.75rem;
  }
  .xl\:pl-8 {
    padding-left: 2rem;
  }
  .xl\:pl-9 {
    padding-left: 2.25rem;
  }
  .xl\:pl-10 {
    padding-left: 2.5rem;
  }
  .xl\:pl-11 {
    padding-left: 2.75rem;
  }
  .xl\:pl-12 {
    padding-left: 3rem;
  }
  .xl\:pl-14 {
    padding-left: 3.5rem;
  }
  .xl\:pl-16 {
    padding-left: 4rem;
  }
  .xl\:pl-20 {
    padding-left: 5rem;
  }
  .xl\:pl-24 {
    padding-left: 6rem;
  }
  .xl\:pl-28 {
    padding-left: 7rem;
  }
  .xl\:pl-32 {
    padding-left: 8rem;
  }
  .xl\:pl-36 {
    padding-left: 9rem;
  }
  .xl\:pl-40 {
    padding-left: 10rem;
  }
  .xl\:pl-44 {
    padding-left: 11rem;
  }
  .xl\:pl-48 {
    padding-left: 12rem;
  }
  .xl\:pl-52 {
    padding-left: 13rem;
  }
  .xl\:pl-56 {
    padding-left: 14rem;
  }
  .xl\:pl-60 {
    padding-left: 15rem;
  }
  .xl\:pl-64 {
    padding-left: 16rem;
  }
  .xl\:pl-72 {
    padding-left: 18rem;
  }
  .xl\:pl-80 {
    padding-left: 20rem;
  }
  .xl\:pl-96 {
    padding-left: 24rem;
  }
  .xl\:pl-px {
    padding-left: 1px;
  }
  .xl\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .xl\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .xl\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .xl\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .xl\:text-left {
    text-align: left;
  }
  .xl\:text-center {
    text-align: center;
  }
  .xl\:text-right {
    text-align: right;
  }
  .xl\:text-justify {
    text-align: justify;
  }
  .xl\:align-baseline {
    vertical-align: baseline;
  }
  .xl\:align-top {
    vertical-align: top;
  }
  .xl\:align-middle {
    vertical-align: middle;
  }
  .xl\:align-bottom {
    vertical-align: bottom;
  }
  .xl\:align-text-top {
    vertical-align: text-top;
  }
  .xl\:align-text-bottom {
    vertical-align: text-bottom;
  }
  .xl\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  }
  .xl\:font-serif {
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  }
  .xl\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
  }
  .xl\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .xl\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
  .xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
  .xl\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
  .xl\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }
  .xl\:font-thin {
    font-weight: 100;
  }
  .xl\:font-extralight {
    font-weight: 200;
  }
  .xl\:font-light {
    font-weight: 300;
  }
  .xl\:font-normal {
    font-weight: 400;
  }
  .xl\:font-medium {
    font-weight: 500;
  }
  .xl\:font-semibold {
    font-weight: 600;
  }
  .xl\:font-bold {
    font-weight: 700;
  }
  .xl\:font-extrabold {
    font-weight: 800;
  }
  .xl\:font-black {
    font-weight: 900;
  }
  .xl\:uppercase {
    text-transform: uppercase;
  }
  .xl\:lowercase {
    text-transform: lowercase;
  }
  .xl\:capitalize {
    text-transform: capitalize;
  }
  .xl\:normal-case {
    text-transform: none;
  }
  .xl\:italic {
    font-style: italic;
  }
  .xl\:not-italic {
    font-style: normal;
  }
  .xl\:diagonal-fractions,
  .xl\:lining-nums,
  .xl\:oldstyle-nums,
  .xl\:ordinal,
  .xl\:proportional-nums,
  .xl\:slashed-zero,
  .xl\:stacked-fractions,
  .xl\:tabular-nums {
    --tw-ordinal: var(--tw-empty); /*!*/ /*!*/
    --tw-slashed-zero: var(--tw-empty); /*!*/ /*!*/
    --tw-numeric-figure: var(--tw-empty); /*!*/ /*!*/
    --tw-numeric-spacing: var(--tw-empty); /*!*/ /*!*/
    --tw-numeric-fraction: var(--tw-empty); /*!*/ /*!*/
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
  }
  .xl\:normal-nums {
    font-variant-numeric: normal;
  }
  .xl\:ordinal {
    --tw-ordinal: ordinal;
  }
  .xl\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }
  .xl\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }
  .xl\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }
  .xl\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }
  .xl\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }
  .xl\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }
  .xl\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }
  .xl\:leading-3 {
    line-height: 0.75rem;
  }
  .xl\:leading-4 {
    line-height: 1rem;
  }
  .xl\:leading-5 {
    line-height: 1.25rem;
  }
  .xl\:leading-6 {
    line-height: 1.5rem;
  }
  .xl\:leading-7 {
    line-height: 1.75rem;
  }
  .xl\:leading-8 {
    line-height: 2rem;
  }
  .xl\:leading-9 {
    line-height: 2.25rem;
  }
  .xl\:leading-10 {
    line-height: 2.5rem;
  }
  .xl\:leading-none {
    line-height: 1;
  }
  .xl\:leading-tight {
    line-height: 1.25;
  }
  .xl\:leading-snug {
    line-height: 1.375;
  }
  .xl\:leading-normal {
    line-height: 1.5;
  }
  .xl\:leading-relaxed {
    line-height: 1.625;
  }
  .xl\:leading-loose {
    line-height: 2;
  }
  .xl\:tracking-tighter {
    letter-spacing: -0.05em;
  }
  .xl\:tracking-tight {
    letter-spacing: -0.025em;
  }
  .xl\:tracking-normal {
    letter-spacing: 0;
  }
  .xl\:tracking-wide {
    letter-spacing: 0.025em;
  }
  .xl\:tracking-wider {
    letter-spacing: 0.05em;
  }
  .xl\:tracking-widest {
    letter-spacing: 0.1em;
  }
  .xl\:text-transparent {
    color: transparent;
  }
  .xl\:text-current {
    color: currentColor;
  }
  .xl\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xl\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xl\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xl\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xl\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xl\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .xl\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xl\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xl\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xl\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xl\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xl\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .xl\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xl\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xl\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xl\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xl\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xl\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xl\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xl\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xl\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xl\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xl\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xl\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xl\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xl\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xl\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xl\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xl\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xl\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xl\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xl\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xl\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xl\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xl\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xl\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xl\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xl\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xl\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xl\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xl\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xl\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xl\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xl\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xl\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xl\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xl\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xl\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xl\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xl\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xl\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xl\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xl\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xl\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xl\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xl\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xl\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xl\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xl\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xl\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xl\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xl\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xl\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xl\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xl\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xl\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xl\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xl\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xl\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xl\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xl\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xl\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xl\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xl\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xl\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xl\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xl\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xl\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xl\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xl\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xl\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xl\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-transparent {
    color: transparent;
  }
  .group:hover .xl\:group-hover\:text-current {
    color: currentColor;
  }
  .group:hover .xl\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .group:hover .xl\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-transparent:focus-within {
    color: transparent;
  }
  .xl\:focus-within\:text-current:focus-within {
    color: currentColor;
  }
  .xl\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xl\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xl\:hover\:text-transparent:hover {
    color: transparent;
  }
  .xl\:hover\:text-current:hover {
    color: currentColor;
  }
  .xl\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xl\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xl\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xl\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xl\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xl\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xl\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xl\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xl\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xl\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xl\:focus\:text-transparent:focus {
    color: transparent;
  }
  .xl\:focus\:text-current:focus {
    color: currentColor;
  }
  .xl\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .xl\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .xl\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .xl\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .xl\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .xl\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .xl\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .xl\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .xl\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .xl\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .xl\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .xl\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .xl\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .xl\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .xl\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .xl\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .xl\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .xl\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .xl\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .xl\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .xl\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .xl\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .xl\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .xl\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .xl\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .group:hover .xl\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .group:hover .xl\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .group:hover .xl\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .group:hover .xl\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .group:hover .xl\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .group:hover .xl\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .group:hover .xl\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .group:hover .xl\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .group:hover .xl\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .group:hover .xl\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .group:hover .xl\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .group:hover .xl\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .group:hover .xl\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .group:hover .xl\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .group:hover .xl\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .xl\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }
  .xl\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }
  .xl\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }
  .xl\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }
  .xl\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }
  .xl\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }
  .xl\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }
  .xl\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }
  .xl\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }
  .xl\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }
  .xl\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }
  .xl\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }
  .xl\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }
  .xl\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }
  .xl\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }
  .xl\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }
  .xl\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }
  .xl\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }
  .xl\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }
  .xl\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }
  .xl\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }
  .xl\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }
  .xl\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }
  .xl\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }
  .xl\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }
  .xl\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }
  .xl\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }
  .xl\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }
  .xl\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }
  .xl\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }
  .xl\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }
  .xl\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }
  .xl\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }
  .xl\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }
  .xl\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }
  .xl\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }
  .xl\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }
  .xl\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }
  .xl\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }
  .xl\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }
  .xl\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }
  .xl\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }
  .xl\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }
  .xl\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }
  .xl\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }
  .xl\:underline {
    text-decoration: underline;
  }
  .xl\:line-through {
    text-decoration: line-through;
  }
  .xl\:no-underline {
    text-decoration: none;
  }
  .group:hover .xl\:group-hover\:underline {
    text-decoration: underline;
  }
  .group:hover .xl\:group-hover\:line-through {
    text-decoration: line-through;
  }
  .group:hover .xl\:group-hover\:no-underline {
    text-decoration: none;
  }
  .xl\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }
  .xl\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }
  .xl\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }
  .xl\:hover\:underline:hover {
    text-decoration: underline;
  }
  .xl\:hover\:line-through:hover {
    text-decoration: line-through;
  }
  .xl\:hover\:no-underline:hover {
    text-decoration: none;
  }
  .xl\:focus\:underline:focus {
    text-decoration: underline;
  }
  .xl\:focus\:line-through:focus {
    text-decoration: line-through;
  }
  .xl\:focus\:no-underline:focus {
    text-decoration: none;
  }
  .xl\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .xl\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  .xl\:placeholder-transparent::placeholder {
    color: transparent;
  }
  .xl\:placeholder-current::placeholder {
    color: currentColor;
  }
  .xl\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }
  .xl\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }
  .xl\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .xl\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .xl\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xl\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xl\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xl\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xl\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xl\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xl\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xl\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xl\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xl\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xl\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xl\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xl\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xl\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xl\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xl\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .xl\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .xl\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .xl\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .xl\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .xl\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .xl\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .xl\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .xl\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .xl\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .xl\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .xl\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .xl\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .xl\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .xl\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .xl\:opacity-0 {
    opacity: 0;
  }
  .xl\:opacity-5 {
    opacity: 0.05;
  }
  .xl\:opacity-10 {
    opacity: 0.1;
  }
  .xl\:opacity-20 {
    opacity: 0.2;
  }
  .xl\:opacity-25 {
    opacity: 0.25;
  }
  .xl\:opacity-30 {
    opacity: 0.3;
  }
  .xl\:opacity-40 {
    opacity: 0.4;
  }
  .xl\:opacity-50 {
    opacity: 0.5;
  }
  .xl\:opacity-60 {
    opacity: 0.6;
  }
  .xl\:opacity-70 {
    opacity: 0.7;
  }
  .xl\:opacity-75 {
    opacity: 0.75;
  }
  .xl\:opacity-80 {
    opacity: 0.8;
  }
  .xl\:opacity-90 {
    opacity: 0.9;
  }
  .xl\:opacity-95 {
    opacity: 0.95;
  }
  .xl\:opacity-100 {
    opacity: 1;
  }
  .group:hover .xl\:group-hover\:opacity-0 {
    opacity: 0;
  }
  .group:hover .xl\:group-hover\:opacity-5 {
    opacity: 0.05;
  }
  .group:hover .xl\:group-hover\:opacity-10 {
    opacity: 0.1;
  }
  .group:hover .xl\:group-hover\:opacity-20 {
    opacity: 0.2;
  }
  .group:hover .xl\:group-hover\:opacity-25 {
    opacity: 0.25;
  }
  .group:hover .xl\:group-hover\:opacity-30 {
    opacity: 0.3;
  }
  .group:hover .xl\:group-hover\:opacity-40 {
    opacity: 0.4;
  }
  .group:hover .xl\:group-hover\:opacity-50 {
    opacity: 0.5;
  }
  .group:hover .xl\:group-hover\:opacity-60 {
    opacity: 0.6;
  }
  .group:hover .xl\:group-hover\:opacity-70 {
    opacity: 0.7;
  }
  .group:hover .xl\:group-hover\:opacity-75 {
    opacity: 0.75;
  }
  .group:hover .xl\:group-hover\:opacity-80 {
    opacity: 0.8;
  }
  .group:hover .xl\:group-hover\:opacity-90 {
    opacity: 0.9;
  }
  .group:hover .xl\:group-hover\:opacity-95 {
    opacity: 0.95;
  }
  .group:hover .xl\:group-hover\:opacity-100 {
    opacity: 1;
  }
  .xl\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }
  .xl\:focus-within\:opacity-5:focus-within {
    opacity: 0.05;
  }
  .xl\:focus-within\:opacity-10:focus-within {
    opacity: 0.1;
  }
  .xl\:focus-within\:opacity-20:focus-within {
    opacity: 0.2;
  }
  .xl\:focus-within\:opacity-25:focus-within {
    opacity: 0.25;
  }
  .xl\:focus-within\:opacity-30:focus-within {
    opacity: 0.3;
  }
  .xl\:focus-within\:opacity-40:focus-within {
    opacity: 0.4;
  }
  .xl\:focus-within\:opacity-50:focus-within {
    opacity: 0.5;
  }
  .xl\:focus-within\:opacity-60:focus-within {
    opacity: 0.6;
  }
  .xl\:focus-within\:opacity-70:focus-within {
    opacity: 0.7;
  }
  .xl\:focus-within\:opacity-75:focus-within {
    opacity: 0.75;
  }
  .xl\:focus-within\:opacity-80:focus-within {
    opacity: 0.8;
  }
  .xl\:focus-within\:opacity-90:focus-within {
    opacity: 0.9;
  }
  .xl\:focus-within\:opacity-95:focus-within {
    opacity: 0.95;
  }
  .xl\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }
  .xl\:hover\:opacity-0:hover {
    opacity: 0;
  }
  .xl\:hover\:opacity-5:hover {
    opacity: 0.05;
  }
  .xl\:hover\:opacity-10:hover {
    opacity: 0.1;
  }
  .xl\:hover\:opacity-20:hover {
    opacity: 0.2;
  }
  .xl\:hover\:opacity-25:hover {
    opacity: 0.25;
  }
  .xl\:hover\:opacity-30:hover {
    opacity: 0.3;
  }
  .xl\:hover\:opacity-40:hover {
    opacity: 0.4;
  }
  .xl\:hover\:opacity-50:hover {
    opacity: 0.5;
  }
  .xl\:hover\:opacity-60:hover {
    opacity: 0.6;
  }
  .xl\:hover\:opacity-70:hover {
    opacity: 0.7;
  }
  .xl\:hover\:opacity-75:hover {
    opacity: 0.75;
  }
  .xl\:hover\:opacity-80:hover {
    opacity: 0.8;
  }
  .xl\:hover\:opacity-90:hover {
    opacity: 0.9;
  }
  .xl\:hover\:opacity-95:hover {
    opacity: 0.95;
  }
  .xl\:hover\:opacity-100:hover {
    opacity: 1;
  }
  .xl\:focus\:opacity-0:focus {
    opacity: 0;
  }
  .xl\:focus\:opacity-5:focus {
    opacity: 0.05;
  }
  .xl\:focus\:opacity-10:focus {
    opacity: 0.1;
  }
  .xl\:focus\:opacity-20:focus {
    opacity: 0.2;
  }
  .xl\:focus\:opacity-25:focus {
    opacity: 0.25;
  }
  .xl\:focus\:opacity-30:focus {
    opacity: 0.3;
  }
  .xl\:focus\:opacity-40:focus {
    opacity: 0.4;
  }
  .xl\:focus\:opacity-50:focus {
    opacity: 0.5;
  }
  .xl\:focus\:opacity-60:focus {
    opacity: 0.6;
  }
  .xl\:focus\:opacity-70:focus {
    opacity: 0.7;
  }
  .xl\:focus\:opacity-75:focus {
    opacity: 0.75;
  }
  .xl\:focus\:opacity-80:focus {
    opacity: 0.8;
  }
  .xl\:focus\:opacity-90:focus {
    opacity: 0.9;
  }
  .xl\:focus\:opacity-95:focus {
    opacity: 0.95;
  }
  .xl\:focus\:opacity-100:focus {
    opacity: 1;
  }
  .xl\:bg-blend-normal {
    background-blend-mode: normal;
  }
  .xl\:bg-blend-multiply {
    background-blend-mode: multiply;
  }
  .xl\:bg-blend-screen {
    background-blend-mode: screen;
  }
  .xl\:bg-blend-overlay {
    background-blend-mode: overlay;
  }
  .xl\:bg-blend-darken {
    background-blend-mode: darken;
  }
  .xl\:bg-blend-lighten {
    background-blend-mode: lighten;
  }
  .xl\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }
  .xl\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }
  .xl\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }
  .xl\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }
  .xl\:bg-blend-difference {
    background-blend-mode: difference;
  }
  .xl\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }
  .xl\:bg-blend-hue {
    background-blend-mode: hue;
  }
  .xl\:bg-blend-saturation {
    background-blend-mode: saturation;
  }
  .xl\:bg-blend-color {
    background-blend-mode: color;
  }
  .xl\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }
  .xl\:mix-blend-normal {
    mix-blend-mode: normal;
  }
  .xl\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }
  .xl\:mix-blend-screen {
    mix-blend-mode: screen;
  }
  .xl\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }
  .xl\:mix-blend-darken {
    mix-blend-mode: darken;
  }
  .xl\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }
  .xl\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }
  .xl\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }
  .xl\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }
  .xl\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }
  .xl\:mix-blend-difference {
    mix-blend-mode: difference;
  }
  .xl\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }
  .xl\:mix-blend-hue {
    mix-blend-mode: hue;
  }
  .xl\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }
  .xl\:mix-blend-color {
    mix-blend-mode: color;
  }
  .xl\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }
  .xl\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .xl\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .xl\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .xl\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .xl\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .xl\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .xl\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .xl\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .xl\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .xl\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .xl\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }
  .xl\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }
  .xl\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .xl\:focus-within\:outline-white:focus-within {
    outline: 2px dotted white;
    outline-offset: 2px;
  }
  .xl\:focus-within\:outline-black:focus-within {
    outline: 2px dotted black;
    outline-offset: 2px;
  }
  .xl\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .xl\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }
  .xl\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }
  .xl\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .xl\:ring-inset {
    --tw-ring-inset: inset;
  }
  .xl\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }
  .xl\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }
  .xl\:ring-transparent {
    --tw-ring-color: transparent;
  }
  .xl\:ring-current {
    --tw-ring-color: currentColor;
  }
  .xl\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .xl\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .xl\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }
  .xl\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .xl\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .xl\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .xl\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .xl\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .xl\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .xl\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .xl\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .xl\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .xl\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .xl\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .xl\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .xl\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .xl\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .xl\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .xl\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .xl\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .xl\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .xl\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .xl\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .xl\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .xl\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .xl\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .xl\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .xl\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }
  .xl\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }
  .xl\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .xl\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }
  .xl\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }
  .xl\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .xl\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .xl\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }
  .xl\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }
  .xl\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }
  .xl\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }
  .xl\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }
  .xl\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }
  .xl\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }
  .xl\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }
  .xl\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }
  .xl\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }
  .xl\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }
  .xl\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }
  .xl\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }
  .xl\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }
  .xl\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }
  .xl\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }
  .xl\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }
  .xl\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }
  .xl\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }
  .xl\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }
  .xl\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }
  .xl\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }
  .xl\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }
  .xl\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }
  .xl\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }
  .xl\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }
  .xl\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }
  .xl\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }
  .xl\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }
  .xl\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }
  .xl\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }
  .xl\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }
  .xl\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }
  .xl\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }
  .xl\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }
  .xl\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }
  .xl\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }
  .xl\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }
  .xl\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }
  .xl\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }
  .xl\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }
  .xl\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }
  .xl\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }
  .xl\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }
  .xl\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }
  .xl\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }
  .xl\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }
  .xl\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }
  .xl\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }
  .xl\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }
  .xl\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }
  .xl\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }
  .xl\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }
  .xl\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }
  .xl\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }
  .xl\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }
  .xl\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }
  .xl\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }
  .xl\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }
  .xl\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }
  .xl\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }
  .xl\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }
  .xl\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }
  .xl\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }
  .xl\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }
  .xl\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }
  .xl\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xl\:ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }
  .xl\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }
  .xl\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }
  .xl\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }
  .xl\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }
  .xl\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }
  .xl\:ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }
  .xl\:ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }
  .xl\:ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }
  .xl\:ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }
  .xl\:ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }
  .xl\:ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }
  .xl\:ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }
  .xl\:ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }
  .xl\:ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }
  .xl\:ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }
  .xl\:ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }
  .xl\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }
  .xl\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }
  .xl\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }
  .xl\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }
  .xl\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }
  .xl\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }
  .xl\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }
  .xl\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }
  .xl\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }
  .xl\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }
  .xl\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }
  .xl\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }
  .xl\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }
  .xl\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }
  .xl\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }
  .xl\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }
  .xl\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }
  .xl\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }
  .xl\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }
  .xl\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }
  .xl\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }
  .xl\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }
  .xl\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }
  .xl\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }
  .xl\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }
  .xl\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }
  .xl\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }
  .xl\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }
  .xl\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }
  .xl\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }
  .xl\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }
  .xl\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }
  .xl\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }
  .xl\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }
  .xl\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }
  .xl\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }
  .xl\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }
  .xl\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }
  .xl\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }
  .xl\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }
  .xl\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }
  .xl\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }
  .xl\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }
  .xl\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }
  .xl\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }
  .xl\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }
  .xl\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }
  .xl\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }
  .xl\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }
  .xl\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }
  .xl\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }
  .xl\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }
  .xl\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }
  .xl\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }
  .xl\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }
  .xl\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }
  .xl\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }
  .xl\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }
  .xl\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }
  .xl\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }
  .xl\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }
  .xl\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }
  .xl\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }
  .xl\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }
  .xl\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }
  .xl\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }
  .xl\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xl\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }
  .xl\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }
  .xl\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }
  .xl\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }
  .xl\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }
  .xl\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }
  .xl\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }
  .xl\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }
  .xl\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }
  .xl\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }
  .xl\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }
  .xl\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }
  .xl\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }
  .xl\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }
  .xl\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }
  .xl\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }
  .xl\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }
  .xl\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }
  .xl\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }
  .xl\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }
  .xl\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }
  .xl\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }
  .xl\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }
  .xl\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }
  .xl\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }
  .xl\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }
  .xl\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }
  .xl\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }
  .xl\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }
  .xl\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }
  .xl\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }
  .xl\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }
  .xl\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }
  .xl\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }
  .xl\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }
  .xl\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }
  .xl\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }
  .xl\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }
  .xl\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }
  .xl\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }
  .xl\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }
  .xl\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }
  .xl\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }
  .xl\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }
  .xl\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }
  .xl\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }
  .xl\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }
  .xl\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }
  .xl\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }
  .xl\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }
  .xl\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }
  .xl\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }
  .xl\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }
  .xl\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }
  .xl\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }
  .xl\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }
  .xl\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }
  .xl\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }
  .xl\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }
  .xl\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }
  .xl\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }
  .xl\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }
  .xl\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }
  .xl\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }
  .xl\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }
  .xl\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }
  .xl\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }
  .xl\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }
  .xl\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }
  .xl\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }
  .xl\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }
  .xl\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }
  .xl\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }
  .xl\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }
  .xl\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }
  .xl\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }
  .xl\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }
  .xl\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }
  .xl\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }
  .xl\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }
  .xl\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }
  .xl\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }
  .xl\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }
  .xl\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .xl\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }
  .xl\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }
  .xl\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }
  .xl\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }
  .xl\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }
  .xl\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }
  .xl\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }
  .xl\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }
  .xl\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }
  .xl\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }
  .xl\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }
  .xl\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }
  .xl\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }
  .xl\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }
  .xl\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }
  .xl\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }
  .xl\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }
  .xl\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }
  .xl\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }
  .xl\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }
  .xl\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }
  .xl\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }
  .xl\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }
  .xl\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }
  .xl\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }
  .xl\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }
  .xl\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }
  .xl\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }
  .xl\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }
  .xl\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }
  .xl\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }
  .xl\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }
  .xl\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }
  .xl\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }
  .xl\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }
  .xl\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }
  .xl\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }
  .xl\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }
  .xl\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }
  .xl\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }
  .xl\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }
  .xl\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }
  .xl\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }
  .xl\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }
  .xl\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }
  .xl\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }
  .xl\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }
  .xl\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }
  .xl\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }
  .xl\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }
  .xl\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }
  .xl\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }
  .xl\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }
  .xl\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }
  .xl\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }
  .xl\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }
  .xl\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }
  .xl\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }
  .xl\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }
  .xl\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }
  .xl\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }
  .xl\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }
  .xl\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }
  .xl\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }
  .xl\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }
  .xl\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }
  .xl\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }
  .xl\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }
  .xl\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }
  .xl\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }
  .xl\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }
  .xl\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }
  .xl\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }
  .xl\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }
  .xl\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }
  .xl\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }
  .xl\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }
  .xl\:filter {
    --tw-blur: var(--tw-empty); /*!*/ /*!*/
    --tw-brightness: var(--tw-empty); /*!*/ /*!*/
    --tw-contrast: var(--tw-empty); /*!*/ /*!*/
    --tw-grayscale: var(--tw-empty); /*!*/ /*!*/
    --tw-hue-rotate: var(--tw-empty); /*!*/ /*!*/
    --tw-invert: var(--tw-empty); /*!*/ /*!*/
    --tw-saturate: var(--tw-empty); /*!*/ /*!*/
    --tw-sepia: var(--tw-empty); /*!*/ /*!*/
    --tw-drop-shadow: var(--tw-empty); /*!*/ /*!*/
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .xl\:filter-none {
    filter: none;
  }
  .xl\:blur-0 {
    --tw-blur: blur(0);
  }
  .xl\:blur-none {
    --tw-blur: blur(0);
  }
  .xl\:blur-sm {
    --tw-blur: blur(4px);
  }
  .xl\:blur {
    --tw-blur: blur(8px);
  }
  .xl\:blur-md {
    --tw-blur: blur(12px);
  }
  .xl\:blur-lg {
    --tw-blur: blur(16px);
  }
  .xl\:blur-xl {
    --tw-blur: blur(24px);
  }
  .xl\:blur-2xl {
    --tw-blur: blur(40px);
  }
  .xl\:blur-3xl {
    --tw-blur: blur(64px);
  }
  .xl\:brightness-0 {
    --tw-brightness: brightness(0);
  }
  .xl\:brightness-50 {
    --tw-brightness: brightness(0.5);
  }
  .xl\:brightness-75 {
    --tw-brightness: brightness(0.75);
  }
  .xl\:brightness-90 {
    --tw-brightness: brightness(0.9);
  }
  .xl\:brightness-95 {
    --tw-brightness: brightness(0.95);
  }
  .xl\:brightness-100 {
    --tw-brightness: brightness(1);
  }
  .xl\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }
  .xl\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }
  .xl\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }
  .xl\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }
  .xl\:brightness-200 {
    --tw-brightness: brightness(2);
  }
  .xl\:contrast-0 {
    --tw-contrast: contrast(0);
  }
  .xl\:contrast-50 {
    --tw-contrast: contrast(0.5);
  }
  .xl\:contrast-75 {
    --tw-contrast: contrast(0.75);
  }
  .xl\:contrast-100 {
    --tw-contrast: contrast(1);
  }
  .xl\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }
  .xl\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }
  .xl\:contrast-200 {
    --tw-contrast: contrast(2);
  }
  .xl\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.05));
  }
  .xl\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1))
      drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }
  .xl\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07))
      drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }
  .xl\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04))
      drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }
  .xl\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03))
      drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }
  .xl\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }
  .xl\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }
  .xl\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }
  .xl\:grayscale {
    --tw-grayscale: grayscale(100%);
  }
  .xl\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }
  .xl\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }
  .xl\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }
  .xl\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }
  .xl\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }
  .xl\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }
  .xl\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }
  .xl\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }
  .xl\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }
  .xl\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }
  .xl\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }
  .xl\:invert-0 {
    --tw-invert: invert(0);
  }
  .xl\:invert {
    --tw-invert: invert(100%);
  }
  .xl\:saturate-0 {
    --tw-saturate: saturate(0);
  }
  .xl\:saturate-50 {
    --tw-saturate: saturate(0.5);
  }
  .xl\:saturate-100 {
    --tw-saturate: saturate(1);
  }
  .xl\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }
  .xl\:saturate-200 {
    --tw-saturate: saturate(2);
  }
  .xl\:sepia-0 {
    --tw-sepia: sepia(0);
  }
  .xl\:sepia {
    --tw-sepia: sepia(100%);
  }
  .xl\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-brightness: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-contrast: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-grayscale: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-hue-rotate: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-invert: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-opacity: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-saturate: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-sepia: var(--tw-empty); /*!*/ /*!*/
    -webkit-backdrop-filter: var(--tw-backdrop-blur)
      var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
      var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate)
      var(--tw-backdrop-invert) var(--tw-backdrop-opacity)
      var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
      var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
      var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
      var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
      var(--tw-backdrop-sepia);
  }
  .xl\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }
  .xl\:backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
  }
  .xl\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }
  .xl\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }
  .xl\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }
  .xl\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }
  .xl\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }
  .xl\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }
  .xl\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }
  .xl\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }
  .xl\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }
  .xl\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(0.5);
  }
  .xl\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(0.75);
  }
  .xl\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(0.9);
  }
  .xl\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(0.95);
  }
  .xl\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }
  .xl\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }
  .xl\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }
  .xl\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }
  .xl\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }
  .xl\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }
  .xl\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }
  .xl\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(0.5);
  }
  .xl\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(0.75);
  }
  .xl\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }
  .xl\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }
  .xl\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }
  .xl\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }
  .xl\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }
  .xl\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }
  .xl\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }
  .xl\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }
  .xl\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }
  .xl\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }
  .xl\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }
  .xl\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }
  .xl\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }
  .xl\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }
  .xl\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }
  .xl\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }
  .xl\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }
  .xl\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }
  .xl\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }
  .xl\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }
  .xl\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(0.05);
  }
  .xl\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(0.1);
  }
  .xl\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(0.2);
  }
  .xl\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(0.25);
  }
  .xl\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(0.3);
  }
  .xl\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(0.4);
  }
  .xl\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(0.5);
  }
  .xl\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(0.6);
  }
  .xl\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(0.7);
  }
  .xl\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(0.75);
  }
  .xl\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(0.8);
  }
  .xl\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(0.9);
  }
  .xl\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(0.95);
  }
  .xl\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }
  .xl\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }
  .xl\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(0.5);
  }
  .xl\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }
  .xl\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }
  .xl\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }
  .xl\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }
  .xl\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }
  .xl\:transition-none {
    transition-property: none;
  }
  .xl\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .xl\:transition {
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, backdrop-filter,
      -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .xl\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .xl\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .xl\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .xl\:transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .xl\:delay-75 {
    transition-delay: 75ms;
  }
  .xl\:delay-100 {
    transition-delay: 0.1s;
  }
  .xl\:delay-150 {
    transition-delay: 150ms;
  }
  .xl\:delay-200 {
    transition-delay: 0.2s;
  }
  .xl\:delay-300 {
    transition-delay: 0.3s;
  }
  .xl\:delay-500 {
    transition-delay: 0.5s;
  }
  .xl\:delay-700 {
    transition-delay: 0.7s;
  }
  .xl\:delay-1000 {
    transition-delay: 1s;
  }
  .xl\:duration-75 {
    transition-duration: 75ms;
  }
  .xl\:duration-100 {
    transition-duration: 0.1s;
  }
  .xl\:duration-150 {
    transition-duration: 150ms;
  }
  .xl\:duration-200 {
    transition-duration: 0.2s;
  }
  .xl\:duration-300 {
    transition-duration: 0.3s;
  }
  .xl\:duration-500 {
    transition-duration: 0.5s;
  }
  .xl\:duration-700 {
    transition-duration: 0.7s;
  }
  .xl\:duration-1000 {
    transition-duration: 1s;
  }
  .xl\:ease-linear {
    transition-timing-function: linear;
  }
  .xl\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .xl\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .xl\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}
@media (min-width: 1536px) {
  .\32xl\:container {
    width: 100%;
  }
  @media (min-width: 640px) {
    .\32xl\:container {
      max-width: 640px;
    }
  }
  @media (min-width: 768px) {
    .\32xl\:container {
      max-width: 768px;
    }
  }
  @media (min-width: 1024px) {
    .\32xl\:container {
      max-width: 1024px;
    }
  }
  @media (min-width: 1280px) {
    .\32xl\:container {
      max-width: 1280px;
    }
  }
  @media (min-width: 1536px) {
    .\32xl\:container {
      max-width: 1536px;
    }
  }
  .\32xl\:sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .\32xl\:not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .\32xl\:focus-within\:sr-only:focus-within {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .\32xl\:focus-within\:not-sr-only:focus-within {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .\32xl\:focus\:sr-only:focus {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
  .\32xl\:focus\:not-sr-only:focus {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
  .\32xl\:pointer-events-none {
    pointer-events: none;
  }
  .\32xl\:pointer-events-auto {
    pointer-events: auto;
  }
  .\32xl\:visible {
    visibility: visible;
  }
  .\32xl\:invisible {
    visibility: hidden;
  }
  .\32xl\:static {
    position: static;
  }
  .\32xl\:fixed {
    position: fixed;
  }
  .\32xl\:absolute {
    position: absolute;
  }
  .\32xl\:relative {
    position: relative;
  }
  .\32xl\:sticky {
    position: sticky;
  }
  .\32xl\:inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .\32xl\:inset-1 {
    top: 0.25rem;
    right: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
  }
  .\32xl\:inset-2 {
    top: 0.5rem;
    right: 0.5rem;
    bottom: 0.5rem;
    left: 0.5rem;
  }
  .\32xl\:inset-3 {
    top: 0.75rem;
    right: 0.75rem;
    bottom: 0.75rem;
    left: 0.75rem;
  }
  .\32xl\:inset-4 {
    top: 1rem;
    right: 1rem;
    bottom: 1rem;
    left: 1rem;
  }
  .\32xl\:inset-5 {
    top: 1.25rem;
    right: 1.25rem;
    bottom: 1.25rem;
    left: 1.25rem;
  }
  .\32xl\:inset-6 {
    top: 1.5rem;
    right: 1.5rem;
    bottom: 1.5rem;
    left: 1.5rem;
  }
  .\32xl\:inset-7 {
    top: 1.75rem;
    right: 1.75rem;
    bottom: 1.75rem;
    left: 1.75rem;
  }
  .\32xl\:inset-8 {
    top: 2rem;
    right: 2rem;
    bottom: 2rem;
    left: 2rem;
  }
  .\32xl\:inset-9 {
    top: 2.25rem;
    right: 2.25rem;
    bottom: 2.25rem;
    left: 2.25rem;
  }
  .\32xl\:inset-10 {
    top: 2.5rem;
    right: 2.5rem;
    bottom: 2.5rem;
    left: 2.5rem;
  }
  .\32xl\:inset-11 {
    top: 2.75rem;
    right: 2.75rem;
    bottom: 2.75rem;
    left: 2.75rem;
  }
  .\32xl\:inset-12 {
    top: 3rem;
    right: 3rem;
    bottom: 3rem;
    left: 3rem;
  }
  .\32xl\:inset-14 {
    top: 3.5rem;
    right: 3.5rem;
    bottom: 3.5rem;
    left: 3.5rem;
  }
  .\32xl\:inset-16 {
    top: 4rem;
    right: 4rem;
    bottom: 4rem;
    left: 4rem;
  }
  .\32xl\:inset-20 {
    top: 5rem;
    right: 5rem;
    bottom: 5rem;
    left: 5rem;
  }
  .\32xl\:inset-24 {
    top: 6rem;
    right: 6rem;
    bottom: 6rem;
    left: 6rem;
  }
  .\32xl\:inset-28 {
    top: 7rem;
    right: 7rem;
    bottom: 7rem;
    left: 7rem;
  }
  .\32xl\:inset-32 {
    top: 8rem;
    right: 8rem;
    bottom: 8rem;
    left: 8rem;
  }
  .\32xl\:inset-36 {
    top: 9rem;
    right: 9rem;
    bottom: 9rem;
    left: 9rem;
  }
  .\32xl\:inset-40 {
    top: 10rem;
    right: 10rem;
    bottom: 10rem;
    left: 10rem;
  }
  .\32xl\:inset-44 {
    top: 11rem;
    right: 11rem;
    bottom: 11rem;
    left: 11rem;
  }
  .\32xl\:inset-48 {
    top: 12rem;
    right: 12rem;
    bottom: 12rem;
    left: 12rem;
  }
  .\32xl\:inset-52 {
    top: 13rem;
    right: 13rem;
    bottom: 13rem;
    left: 13rem;
  }
  .\32xl\:inset-56 {
    top: 14rem;
    right: 14rem;
    bottom: 14rem;
    left: 14rem;
  }
  .\32xl\:inset-60 {
    top: 15rem;
    right: 15rem;
    bottom: 15rem;
    left: 15rem;
  }
  .\32xl\:inset-64 {
    top: 16rem;
    right: 16rem;
    bottom: 16rem;
    left: 16rem;
  }
  .\32xl\:inset-72 {
    top: 18rem;
    right: 18rem;
    bottom: 18rem;
    left: 18rem;
  }
  .\32xl\:inset-80 {
    top: 20rem;
    right: 20rem;
    bottom: 20rem;
    left: 20rem;
  }
  .\32xl\:inset-96 {
    top: 24rem;
    right: 24rem;
    bottom: 24rem;
    left: 24rem;
  }
  .\32xl\:inset-auto {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
  }
  .\32xl\:inset-px {
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
  }
  .\32xl\:inset-0\.5 {
    top: 0.125rem;
    right: 0.125rem;
    bottom: 0.125rem;
    left: 0.125rem;
  }
  .\32xl\:inset-1\.5 {
    top: 0.375rem;
    right: 0.375rem;
    bottom: 0.375rem;
    left: 0.375rem;
  }
  .\32xl\:inset-2\.5 {
    top: 0.625rem;
    right: 0.625rem;
    bottom: 0.625rem;
    left: 0.625rem;
  }
  .\32xl\:inset-3\.5 {
    top: 0.875rem;
    right: 0.875rem;
    bottom: 0.875rem;
    left: 0.875rem;
  }
  .\32xl\:-inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .\32xl\:-inset-1 {
    top: -0.25rem;
    right: -0.25rem;
    bottom: -0.25rem;
    left: -0.25rem;
  }
  .\32xl\:-inset-2 {
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }
  .\32xl\:-inset-3 {
    top: -0.75rem;
    right: -0.75rem;
    bottom: -0.75rem;
    left: -0.75rem;
  }
  .\32xl\:-inset-4 {
    top: -1rem;
    right: -1rem;
    bottom: -1rem;
    left: -1rem;
  }
  .\32xl\:-inset-5 {
    top: -1.25rem;
    right: -1.25rem;
    bottom: -1.25rem;
    left: -1.25rem;
  }
  .\32xl\:-inset-6 {
    top: -1.5rem;
    right: -1.5rem;
    bottom: -1.5rem;
    left: -1.5rem;
  }
  .\32xl\:-inset-7 {
    top: -1.75rem;
    right: -1.75rem;
    bottom: -1.75rem;
    left: -1.75rem;
  }
  .\32xl\:-inset-8 {
    top: -2rem;
    right: -2rem;
    bottom: -2rem;
    left: -2rem;
  }
  .\32xl\:-inset-9 {
    top: -2.25rem;
    right: -2.25rem;
    bottom: -2.25rem;
    left: -2.25rem;
  }
  .\32xl\:-inset-10 {
    top: -2.5rem;
    right: -2.5rem;
    bottom: -2.5rem;
    left: -2.5rem;
  }
  .\32xl\:-inset-11 {
    top: -2.75rem;
    right: -2.75rem;
    bottom: -2.75rem;
    left: -2.75rem;
  }
  .\32xl\:-inset-12 {
    top: -3rem;
    right: -3rem;
    bottom: -3rem;
    left: -3rem;
  }
  .\32xl\:-inset-14 {
    top: -3.5rem;
    right: -3.5rem;
    bottom: -3.5rem;
    left: -3.5rem;
  }
  .\32xl\:-inset-16 {
    top: -4rem;
    right: -4rem;
    bottom: -4rem;
    left: -4rem;
  }
  .\32xl\:-inset-20 {
    top: -5rem;
    right: -5rem;
    bottom: -5rem;
    left: -5rem;
  }
  .\32xl\:-inset-24 {
    top: -6rem;
    right: -6rem;
    bottom: -6rem;
    left: -6rem;
  }
  .\32xl\:-inset-28 {
    top: -7rem;
    right: -7rem;
    bottom: -7rem;
    left: -7rem;
  }
  .\32xl\:-inset-32 {
    top: -8rem;
    right: -8rem;
    bottom: -8rem;
    left: -8rem;
  }
  .\32xl\:-inset-36 {
    top: -9rem;
    right: -9rem;
    bottom: -9rem;
    left: -9rem;
  }
  .\32xl\:-inset-40 {
    top: -10rem;
    right: -10rem;
    bottom: -10rem;
    left: -10rem;
  }
  .\32xl\:-inset-44 {
    top: -11rem;
    right: -11rem;
    bottom: -11rem;
    left: -11rem;
  }
  .\32xl\:-inset-48 {
    top: -12rem;
    right: -12rem;
    bottom: -12rem;
    left: -12rem;
  }
  .\32xl\:-inset-52 {
    top: -13rem;
    right: -13rem;
    bottom: -13rem;
    left: -13rem;
  }
  .\32xl\:-inset-56 {
    top: -14rem;
    right: -14rem;
    bottom: -14rem;
    left: -14rem;
  }
  .\32xl\:-inset-60 {
    top: -15rem;
    right: -15rem;
    bottom: -15rem;
    left: -15rem;
  }
  .\32xl\:-inset-64 {
    top: -16rem;
    right: -16rem;
    bottom: -16rem;
    left: -16rem;
  }
  .\32xl\:-inset-72 {
    top: -18rem;
    right: -18rem;
    bottom: -18rem;
    left: -18rem;
  }
  .\32xl\:-inset-80 {
    top: -20rem;
    right: -20rem;
    bottom: -20rem;
    left: -20rem;
  }
  .\32xl\:-inset-96 {
    top: -24rem;
    right: -24rem;
    bottom: -24rem;
    left: -24rem;
  }
  .\32xl\:-inset-px {
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
  }
  .\32xl\:-inset-0\.5 {
    top: -0.125rem;
    right: -0.125rem;
    bottom: -0.125rem;
    left: -0.125rem;
  }
  .\32xl\:-inset-1\.5 {
    top: -0.375rem;
    right: -0.375rem;
    bottom: -0.375rem;
    left: -0.375rem;
  }
  .\32xl\:-inset-2\.5 {
    top: -0.625rem;
    right: -0.625rem;
    bottom: -0.625rem;
    left: -0.625rem;
  }
  .\32xl\:-inset-3\.5 {
    top: -0.875rem;
    right: -0.875rem;
    bottom: -0.875rem;
    left: -0.875rem;
  }
  .\32xl\:inset-1\/2 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .\32xl\:inset-1\/3 {
    top: 33.333333%;
    right: 33.333333%;
    bottom: 33.333333%;
    left: 33.333333%;
  }
  .\32xl\:inset-2\/3 {
    top: 66.666667%;
    right: 66.666667%;
    bottom: 66.666667%;
    left: 66.666667%;
  }
  .\32xl\:inset-1\/4 {
    top: 25%;
    right: 25%;
    bottom: 25%;
    left: 25%;
  }
  .\32xl\:inset-2\/4 {
    top: 50%;
    right: 50%;
    bottom: 50%;
    left: 50%;
  }
  .\32xl\:inset-3\/4 {
    top: 75%;
    right: 75%;
    bottom: 75%;
    left: 75%;
  }
  .\32xl\:inset-full {
    top: 100%;
    right: 100%;
    bottom: 100%;
    left: 100%;
  }
  .\32xl\:-inset-1\/2 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .\32xl\:-inset-1\/3 {
    top: -33.333333%;
    right: -33.333333%;
    bottom: -33.333333%;
    left: -33.333333%;
  }
  .\32xl\:-inset-2\/3 {
    top: -66.666667%;
    right: -66.666667%;
    bottom: -66.666667%;
    left: -66.666667%;
  }
  .\32xl\:-inset-1\/4 {
    top: -25%;
    right: -25%;
    bottom: -25%;
    left: -25%;
  }
  .\32xl\:-inset-2\/4 {
    top: -50%;
    right: -50%;
    bottom: -50%;
    left: -50%;
  }
  .\32xl\:-inset-3\/4 {
    top: -75%;
    right: -75%;
    bottom: -75%;
    left: -75%;
  }
  .\32xl\:-inset-full {
    top: -100%;
    right: -100%;
    bottom: -100%;
    left: -100%;
  }
  .\32xl\:inset-x-0 {
    left: 0;
    right: 0;
  }
  .\32xl\:inset-x-1 {
    left: 0.25rem;
    right: 0.25rem;
  }
  .\32xl\:inset-x-2 {
    left: 0.5rem;
    right: 0.5rem;
  }
  .\32xl\:inset-x-3 {
    left: 0.75rem;
    right: 0.75rem;
  }
  .\32xl\:inset-x-4 {
    left: 1rem;
    right: 1rem;
  }
  .\32xl\:inset-x-5 {
    left: 1.25rem;
    right: 1.25rem;
  }
  .\32xl\:inset-x-6 {
    left: 1.5rem;
    right: 1.5rem;
  }
  .\32xl\:inset-x-7 {
    left: 1.75rem;
    right: 1.75rem;
  }
  .\32xl\:inset-x-8 {
    left: 2rem;
    right: 2rem;
  }
  .\32xl\:inset-x-9 {
    left: 2.25rem;
    right: 2.25rem;
  }
  .\32xl\:inset-x-10 {
    left: 2.5rem;
    right: 2.5rem;
  }
  .\32xl\:inset-x-11 {
    left: 2.75rem;
    right: 2.75rem;
  }
  .\32xl\:inset-x-12 {
    left: 3rem;
    right: 3rem;
  }
  .\32xl\:inset-x-14 {
    left: 3.5rem;
    right: 3.5rem;
  }
  .\32xl\:inset-x-16 {
    left: 4rem;
    right: 4rem;
  }
  .\32xl\:inset-x-20 {
    left: 5rem;
    right: 5rem;
  }
  .\32xl\:inset-x-24 {
    left: 6rem;
    right: 6rem;
  }
  .\32xl\:inset-x-28 {
    left: 7rem;
    right: 7rem;
  }
  .\32xl\:inset-x-32 {
    left: 8rem;
    right: 8rem;
  }
  .\32xl\:inset-x-36 {
    left: 9rem;
    right: 9rem;
  }
  .\32xl\:inset-x-40 {
    left: 10rem;
    right: 10rem;
  }
  .\32xl\:inset-x-44 {
    left: 11rem;
    right: 11rem;
  }
  .\32xl\:inset-x-48 {
    left: 12rem;
    right: 12rem;
  }
  .\32xl\:inset-x-52 {
    left: 13rem;
    right: 13rem;
  }
  .\32xl\:inset-x-56 {
    left: 14rem;
    right: 14rem;
  }
  .\32xl\:inset-x-60 {
    left: 15rem;
    right: 15rem;
  }
  .\32xl\:inset-x-64 {
    left: 16rem;
    right: 16rem;
  }
  .\32xl\:inset-x-72 {
    left: 18rem;
    right: 18rem;
  }
  .\32xl\:inset-x-80 {
    left: 20rem;
    right: 20rem;
  }
  .\32xl\:inset-x-96 {
    left: 24rem;
    right: 24rem;
  }
  .\32xl\:inset-x-auto {
    left: auto;
    right: auto;
  }
  .\32xl\:inset-x-px {
    left: 1px;
    right: 1px;
  }
  .\32xl\:inset-x-0\.5 {
    left: 0.125rem;
    right: 0.125rem;
  }
  .\32xl\:inset-x-1\.5 {
    left: 0.375rem;
    right: 0.375rem;
  }
  .\32xl\:inset-x-2\.5 {
    left: 0.625rem;
    right: 0.625rem;
  }
  .\32xl\:inset-x-3\.5 {
    left: 0.875rem;
    right: 0.875rem;
  }
  .\32xl\:-inset-x-0 {
    left: 0;
    right: 0;
  }
  .\32xl\:-inset-x-1 {
    left: -0.25rem;
    right: -0.25rem;
  }
  .\32xl\:-inset-x-2 {
    left: -0.5rem;
    right: -0.5rem;
  }
  .\32xl\:-inset-x-3 {
    left: -0.75rem;
    right: -0.75rem;
  }
  .\32xl\:-inset-x-4 {
    left: -1rem;
    right: -1rem;
  }
  .\32xl\:-inset-x-5 {
    left: -1.25rem;
    right: -1.25rem;
  }
  .\32xl\:-inset-x-6 {
    left: -1.5rem;
    right: -1.5rem;
  }
  .\32xl\:-inset-x-7 {
    left: -1.75rem;
    right: -1.75rem;
  }
  .\32xl\:-inset-x-8 {
    left: -2rem;
    right: -2rem;
  }
  .\32xl\:-inset-x-9 {
    left: -2.25rem;
    right: -2.25rem;
  }
  .\32xl\:-inset-x-10 {
    left: -2.5rem;
    right: -2.5rem;
  }
  .\32xl\:-inset-x-11 {
    left: -2.75rem;
    right: -2.75rem;
  }
  .\32xl\:-inset-x-12 {
    left: -3rem;
    right: -3rem;
  }
  .\32xl\:-inset-x-14 {
    left: -3.5rem;
    right: -3.5rem;
  }
  .\32xl\:-inset-x-16 {
    left: -4rem;
    right: -4rem;
  }
  .\32xl\:-inset-x-20 {
    left: -5rem;
    right: -5rem;
  }
  .\32xl\:-inset-x-24 {
    left: -6rem;
    right: -6rem;
  }
  .\32xl\:-inset-x-28 {
    left: -7rem;
    right: -7rem;
  }
  .\32xl\:-inset-x-32 {
    left: -8rem;
    right: -8rem;
  }
  .\32xl\:-inset-x-36 {
    left: -9rem;
    right: -9rem;
  }
  .\32xl\:-inset-x-40 {
    left: -10rem;
    right: -10rem;
  }
  .\32xl\:-inset-x-44 {
    left: -11rem;
    right: -11rem;
  }
  .\32xl\:-inset-x-48 {
    left: -12rem;
    right: -12rem;
  }
  .\32xl\:-inset-x-52 {
    left: -13rem;
    right: -13rem;
  }
  .\32xl\:-inset-x-56 {
    left: -14rem;
    right: -14rem;
  }
  .\32xl\:-inset-x-60 {
    left: -15rem;
    right: -15rem;
  }
  .\32xl\:-inset-x-64 {
    left: -16rem;
    right: -16rem;
  }
  .\32xl\:-inset-x-72 {
    left: -18rem;
    right: -18rem;
  }
  .\32xl\:-inset-x-80 {
    left: -20rem;
    right: -20rem;
  }
  .\32xl\:-inset-x-96 {
    left: -24rem;
    right: -24rem;
  }
  .\32xl\:-inset-x-px {
    left: -1px;
    right: -1px;
  }
  .\32xl\:-inset-x-0\.5 {
    left: -0.125rem;
    right: -0.125rem;
  }
  .\32xl\:-inset-x-1\.5 {
    left: -0.375rem;
    right: -0.375rem;
  }
  .\32xl\:-inset-x-2\.5 {
    left: -0.625rem;
    right: -0.625rem;
  }
  .\32xl\:-inset-x-3\.5 {
    left: -0.875rem;
    right: -0.875rem;
  }
  .\32xl\:inset-x-1\/2 {
    left: 50%;
    right: 50%;
  }
  .\32xl\:inset-x-1\/3 {
    left: 33.333333%;
    right: 33.333333%;
  }
  .\32xl\:inset-x-2\/3 {
    left: 66.666667%;
    right: 66.666667%;
  }
  .\32xl\:inset-x-1\/4 {
    left: 25%;
    right: 25%;
  }
  .\32xl\:inset-x-2\/4 {
    left: 50%;
    right: 50%;
  }
  .\32xl\:inset-x-3\/4 {
    left: 75%;
    right: 75%;
  }
  .\32xl\:inset-x-full {
    left: 100%;
    right: 100%;
  }
  .\32xl\:-inset-x-1\/2 {
    left: -50%;
    right: -50%;
  }
  .\32xl\:-inset-x-1\/3 {
    left: -33.333333%;
    right: -33.333333%;
  }
  .\32xl\:-inset-x-2\/3 {
    left: -66.666667%;
    right: -66.666667%;
  }
  .\32xl\:-inset-x-1\/4 {
    left: -25%;
    right: -25%;
  }
  .\32xl\:-inset-x-2\/4 {
    left: -50%;
    right: -50%;
  }
  .\32xl\:-inset-x-3\/4 {
    left: -75%;
    right: -75%;
  }
  .\32xl\:-inset-x-full {
    left: -100%;
    right: -100%;
  }
  .\32xl\:inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .\32xl\:inset-y-1 {
    top: 0.25rem;
    bottom: 0.25rem;
  }
  .\32xl\:inset-y-2 {
    top: 0.5rem;
    bottom: 0.5rem;
  }
  .\32xl\:inset-y-3 {
    top: 0.75rem;
    bottom: 0.75rem;
  }
  .\32xl\:inset-y-4 {
    top: 1rem;
    bottom: 1rem;
  }
  .\32xl\:inset-y-5 {
    top: 1.25rem;
    bottom: 1.25rem;
  }
  .\32xl\:inset-y-6 {
    top: 1.5rem;
    bottom: 1.5rem;
  }
  .\32xl\:inset-y-7 {
    top: 1.75rem;
    bottom: 1.75rem;
  }
  .\32xl\:inset-y-8 {
    top: 2rem;
    bottom: 2rem;
  }
  .\32xl\:inset-y-9 {
    top: 2.25rem;
    bottom: 2.25rem;
  }
  .\32xl\:inset-y-10 {
    top: 2.5rem;
    bottom: 2.5rem;
  }
  .\32xl\:inset-y-11 {
    top: 2.75rem;
    bottom: 2.75rem;
  }
  .\32xl\:inset-y-12 {
    top: 3rem;
    bottom: 3rem;
  }
  .\32xl\:inset-y-14 {
    top: 3.5rem;
    bottom: 3.5rem;
  }
  .\32xl\:inset-y-16 {
    top: 4rem;
    bottom: 4rem;
  }
  .\32xl\:inset-y-20 {
    top: 5rem;
    bottom: 5rem;
  }
  .\32xl\:inset-y-24 {
    top: 6rem;
    bottom: 6rem;
  }
  .\32xl\:inset-y-28 {
    top: 7rem;
    bottom: 7rem;
  }
  .\32xl\:inset-y-32 {
    top: 8rem;
    bottom: 8rem;
  }
  .\32xl\:inset-y-36 {
    top: 9rem;
    bottom: 9rem;
  }
  .\32xl\:inset-y-40 {
    top: 10rem;
    bottom: 10rem;
  }
  .\32xl\:inset-y-44 {
    top: 11rem;
    bottom: 11rem;
  }
  .\32xl\:inset-y-48 {
    top: 12rem;
    bottom: 12rem;
  }
  .\32xl\:inset-y-52 {
    top: 13rem;
    bottom: 13rem;
  }
  .\32xl\:inset-y-56 {
    top: 14rem;
    bottom: 14rem;
  }
  .\32xl\:inset-y-60 {
    top: 15rem;
    bottom: 15rem;
  }
  .\32xl\:inset-y-64 {
    top: 16rem;
    bottom: 16rem;
  }
  .\32xl\:inset-y-72 {
    top: 18rem;
    bottom: 18rem;
  }
  .\32xl\:inset-y-80 {
    top: 20rem;
    bottom: 20rem;
  }
  .\32xl\:inset-y-96 {
    top: 24rem;
    bottom: 24rem;
  }
  .\32xl\:inset-y-auto {
    top: auto;
    bottom: auto;
  }
  .\32xl\:inset-y-px {
    top: 1px;
    bottom: 1px;
  }
  .\32xl\:inset-y-0\.5 {
    top: 0.125rem;
    bottom: 0.125rem;
  }
  .\32xl\:inset-y-1\.5 {
    top: 0.375rem;
    bottom: 0.375rem;
  }
  .\32xl\:inset-y-2\.5 {
    top: 0.625rem;
    bottom: 0.625rem;
  }
  .\32xl\:inset-y-3\.5 {
    top: 0.875rem;
    bottom: 0.875rem;
  }
  .\32xl\:-inset-y-0 {
    top: 0;
    bottom: 0;
  }
  .\32xl\:-inset-y-1 {
    top: -0.25rem;
    bottom: -0.25rem;
  }
  .\32xl\:-inset-y-2 {
    top: -0.5rem;
    bottom: -0.5rem;
  }
  .\32xl\:-inset-y-3 {
    top: -0.75rem;
    bottom: -0.75rem;
  }
  .\32xl\:-inset-y-4 {
    top: -1rem;
    bottom: -1rem;
  }
  .\32xl\:-inset-y-5 {
    top: -1.25rem;
    bottom: -1.25rem;
  }
  .\32xl\:-inset-y-6 {
    top: -1.5rem;
    bottom: -1.5rem;
  }
  .\32xl\:-inset-y-7 {
    top: -1.75rem;
    bottom: -1.75rem;
  }
  .\32xl\:-inset-y-8 {
    top: -2rem;
    bottom: -2rem;
  }
  .\32xl\:-inset-y-9 {
    top: -2.25rem;
    bottom: -2.25rem;
  }
  .\32xl\:-inset-y-10 {
    top: -2.5rem;
    bottom: -2.5rem;
  }
  .\32xl\:-inset-y-11 {
    top: -2.75rem;
    bottom: -2.75rem;
  }
  .\32xl\:-inset-y-12 {
    top: -3rem;
    bottom: -3rem;
  }
  .\32xl\:-inset-y-14 {
    top: -3.5rem;
    bottom: -3.5rem;
  }
  .\32xl\:-inset-y-16 {
    top: -4rem;
    bottom: -4rem;
  }
  .\32xl\:-inset-y-20 {
    top: -5rem;
    bottom: -5rem;
  }
  .\32xl\:-inset-y-24 {
    top: -6rem;
    bottom: -6rem;
  }
  .\32xl\:-inset-y-28 {
    top: -7rem;
    bottom: -7rem;
  }
  .\32xl\:-inset-y-32 {
    top: -8rem;
    bottom: -8rem;
  }
  .\32xl\:-inset-y-36 {
    top: -9rem;
    bottom: -9rem;
  }
  .\32xl\:-inset-y-40 {
    top: -10rem;
    bottom: -10rem;
  }
  .\32xl\:-inset-y-44 {
    top: -11rem;
    bottom: -11rem;
  }
  .\32xl\:-inset-y-48 {
    top: -12rem;
    bottom: -12rem;
  }
  .\32xl\:-inset-y-52 {
    top: -13rem;
    bottom: -13rem;
  }
  .\32xl\:-inset-y-56 {
    top: -14rem;
    bottom: -14rem;
  }
  .\32xl\:-inset-y-60 {
    top: -15rem;
    bottom: -15rem;
  }
  .\32xl\:-inset-y-64 {
    top: -16rem;
    bottom: -16rem;
  }
  .\32xl\:-inset-y-72 {
    top: -18rem;
    bottom: -18rem;
  }
  .\32xl\:-inset-y-80 {
    top: -20rem;
    bottom: -20rem;
  }
  .\32xl\:-inset-y-96 {
    top: -24rem;
    bottom: -24rem;
  }
  .\32xl\:-inset-y-px {
    top: -1px;
    bottom: -1px;
  }
  .\32xl\:-inset-y-0\.5 {
    top: -0.125rem;
    bottom: -0.125rem;
  }
  .\32xl\:-inset-y-1\.5 {
    top: -0.375rem;
    bottom: -0.375rem;
  }
  .\32xl\:-inset-y-2\.5 {
    top: -0.625rem;
    bottom: -0.625rem;
  }
  .\32xl\:-inset-y-3\.5 {
    top: -0.875rem;
    bottom: -0.875rem;
  }
  .\32xl\:inset-y-1\/2 {
    top: 50%;
    bottom: 50%;
  }
  .\32xl\:inset-y-1\/3 {
    top: 33.333333%;
    bottom: 33.333333%;
  }
  .\32xl\:inset-y-2\/3 {
    top: 66.666667%;
    bottom: 66.666667%;
  }
  .\32xl\:inset-y-1\/4 {
    top: 25%;
    bottom: 25%;
  }
  .\32xl\:inset-y-2\/4 {
    top: 50%;
    bottom: 50%;
  }
  .\32xl\:inset-y-3\/4 {
    top: 75%;
    bottom: 75%;
  }
  .\32xl\:inset-y-full {
    top: 100%;
    bottom: 100%;
  }
  .\32xl\:-inset-y-1\/2 {
    top: -50%;
    bottom: -50%;
  }
  .\32xl\:-inset-y-1\/3 {
    top: -33.333333%;
    bottom: -33.333333%;
  }
  .\32xl\:-inset-y-2\/3 {
    top: -66.666667%;
    bottom: -66.666667%;
  }
  .\32xl\:-inset-y-1\/4 {
    top: -25%;
    bottom: -25%;
  }
  .\32xl\:-inset-y-2\/4 {
    top: -50%;
    bottom: -50%;
  }
  .\32xl\:-inset-y-3\/4 {
    top: -75%;
    bottom: -75%;
  }
  .\32xl\:-inset-y-full {
    top: -100%;
    bottom: -100%;
  }
  .\32xl\:top-0 {
    top: 0;
  }
  .\32xl\:top-1 {
    top: 0.25rem;
  }
  .\32xl\:top-2 {
    top: 0.5rem;
  }
  .\32xl\:top-3 {
    top: 0.75rem;
  }
  .\32xl\:top-4 {
    top: 1rem;
  }
  .\32xl\:top-5 {
    top: 1.25rem;
  }
  .\32xl\:top-6 {
    top: 1.5rem;
  }
  .\32xl\:top-7 {
    top: 1.75rem;
  }
  .\32xl\:top-8 {
    top: 2rem;
  }
  .\32xl\:top-9 {
    top: 2.25rem;
  }
  .\32xl\:top-10 {
    top: 2.5rem;
  }
  .\32xl\:top-11 {
    top: 2.75rem;
  }
  .\32xl\:top-12 {
    top: 3rem;
  }
  .\32xl\:top-14 {
    top: 3.5rem;
  }
  .\32xl\:top-16 {
    top: 4rem;
  }
  .\32xl\:top-20 {
    top: 5rem;
  }
  .\32xl\:top-24 {
    top: 6rem;
  }
  .\32xl\:top-28 {
    top: 7rem;
  }
  .\32xl\:top-32 {
    top: 8rem;
  }
  .\32xl\:top-36 {
    top: 9rem;
  }
  .\32xl\:top-40 {
    top: 10rem;
  }
  .\32xl\:top-44 {
    top: 11rem;
  }
  .\32xl\:top-48 {
    top: 12rem;
  }
  .\32xl\:top-52 {
    top: 13rem;
  }
  .\32xl\:top-56 {
    top: 14rem;
  }
  .\32xl\:top-60 {
    top: 15rem;
  }
  .\32xl\:top-64 {
    top: 16rem;
  }
  .\32xl\:top-72 {
    top: 18rem;
  }
  .\32xl\:top-80 {
    top: 20rem;
  }
  .\32xl\:top-96 {
    top: 24rem;
  }
  .\32xl\:top-auto {
    top: auto;
  }
  .\32xl\:top-px {
    top: 1px;
  }
  .\32xl\:top-0\.5 {
    top: 0.125rem;
  }
  .\32xl\:top-1\.5 {
    top: 0.375rem;
  }
  .\32xl\:top-2\.5 {
    top: 0.625rem;
  }
  .\32xl\:top-3\.5 {
    top: 0.875rem;
  }
  .\32xl\:-top-0 {
    top: 0;
  }
  .\32xl\:-top-1 {
    top: -0.25rem;
  }
  .\32xl\:-top-2 {
    top: -0.5rem;
  }
  .\32xl\:-top-3 {
    top: -0.75rem;
  }
  .\32xl\:-top-4 {
    top: -1rem;
  }
  .\32xl\:-top-5 {
    top: -1.25rem;
  }
  .\32xl\:-top-6 {
    top: -1.5rem;
  }
  .\32xl\:-top-7 {
    top: -1.75rem;
  }
  .\32xl\:-top-8 {
    top: -2rem;
  }
  .\32xl\:-top-9 {
    top: -2.25rem;
  }
  .\32xl\:-top-10 {
    top: -2.5rem;
  }
  .\32xl\:-top-11 {
    top: -2.75rem;
  }
  .\32xl\:-top-12 {
    top: -3rem;
  }
  .\32xl\:-top-14 {
    top: -3.5rem;
  }
  .\32xl\:-top-16 {
    top: -4rem;
  }
  .\32xl\:-top-20 {
    top: -5rem;
  }
  .\32xl\:-top-24 {
    top: -6rem;
  }
  .\32xl\:-top-28 {
    top: -7rem;
  }
  .\32xl\:-top-32 {
    top: -8rem;
  }
  .\32xl\:-top-36 {
    top: -9rem;
  }
  .\32xl\:-top-40 {
    top: -10rem;
  }
  .\32xl\:-top-44 {
    top: -11rem;
  }
  .\32xl\:-top-48 {
    top: -12rem;
  }
  .\32xl\:-top-52 {
    top: -13rem;
  }
  .\32xl\:-top-56 {
    top: -14rem;
  }
  .\32xl\:-top-60 {
    top: -15rem;
  }
  .\32xl\:-top-64 {
    top: -16rem;
  }
  .\32xl\:-top-72 {
    top: -18rem;
  }
  .\32xl\:-top-80 {
    top: -20rem;
  }
  .\32xl\:-top-96 {
    top: -24rem;
  }
  .\32xl\:-top-px {
    top: -1px;
  }
  .\32xl\:-top-0\.5 {
    top: -0.125rem;
  }
  .\32xl\:-top-1\.5 {
    top: -0.375rem;
  }
  .\32xl\:-top-2\.5 {
    top: -0.625rem;
  }
  .\32xl\:-top-3\.5 {
    top: -0.875rem;
  }
  .\32xl\:top-1\/2 {
    top: 50%;
  }
  .\32xl\:top-1\/3 {
    top: 33.333333%;
  }
  .\32xl\:top-2\/3 {
    top: 66.666667%;
  }
  .\32xl\:top-1\/4 {
    top: 25%;
  }
  .\32xl\:top-2\/4 {
    top: 50%;
  }
  .\32xl\:top-3\/4 {
    top: 75%;
  }
  .\32xl\:top-full {
    top: 100%;
  }
  .\32xl\:-top-1\/2 {
    top: -50%;
  }
  .\32xl\:-top-1\/3 {
    top: -33.333333%;
  }
  .\32xl\:-top-2\/3 {
    top: -66.666667%;
  }
  .\32xl\:-top-1\/4 {
    top: -25%;
  }
  .\32xl\:-top-2\/4 {
    top: -50%;
  }
  .\32xl\:-top-3\/4 {
    top: -75%;
  }
  .\32xl\:-top-full {
    top: -100%;
  }
  .\32xl\:right-0 {
    right: 0;
  }
  .\32xl\:right-1 {
    right: 0.25rem;
  }
  .\32xl\:right-2 {
    right: 0.5rem;
  }
  .\32xl\:right-3 {
    right: 0.75rem;
  }
  .\32xl\:right-4 {
    right: 1rem;
  }
  .\32xl\:right-5 {
    right: 1.25rem;
  }
  .\32xl\:right-6 {
    right: 1.5rem;
  }
  .\32xl\:right-7 {
    right: 1.75rem;
  }
  .\32xl\:right-8 {
    right: 2rem;
  }
  .\32xl\:right-9 {
    right: 2.25rem;
  }
  .\32xl\:right-10 {
    right: 2.5rem;
  }
  .\32xl\:right-11 {
    right: 2.75rem;
  }
  .\32xl\:right-12 {
    right: 3rem;
  }
  .\32xl\:right-14 {
    right: 3.5rem;
  }
  .\32xl\:right-16 {
    right: 4rem;
  }
  .\32xl\:right-20 {
    right: 5rem;
  }
  .\32xl\:right-24 {
    right: 6rem;
  }
  .\32xl\:right-28 {
    right: 7rem;
  }
  .\32xl\:right-32 {
    right: 8rem;
  }
  .\32xl\:right-36 {
    right: 9rem;
  }
  .\32xl\:right-40 {
    right: 10rem;
  }
  .\32xl\:right-44 {
    right: 11rem;
  }
  .\32xl\:right-48 {
    right: 12rem;
  }
  .\32xl\:right-52 {
    right: 13rem;
  }
  .\32xl\:right-56 {
    right: 14rem;
  }
  .\32xl\:right-60 {
    right: 15rem;
  }
  .\32xl\:right-64 {
    right: 16rem;
  }
  .\32xl\:right-72 {
    right: 18rem;
  }
  .\32xl\:right-80 {
    right: 20rem;
  }
  .\32xl\:right-96 {
    right: 24rem;
  }
  .\32xl\:right-auto {
    right: auto;
  }
  .\32xl\:right-px {
    right: 1px;
  }
  .\32xl\:right-0\.5 {
    right: 0.125rem;
  }
  .\32xl\:right-1\.5 {
    right: 0.375rem;
  }
  .\32xl\:right-2\.5 {
    right: 0.625rem;
  }
  .\32xl\:right-3\.5 {
    right: 0.875rem;
  }
  .\32xl\:-right-0 {
    right: 0;
  }
  .\32xl\:-right-1 {
    right: -0.25rem;
  }
  .\32xl\:-right-2 {
    right: -0.5rem;
  }
  .\32xl\:-right-3 {
    right: -0.75rem;
  }
  .\32xl\:-right-4 {
    right: -1rem;
  }
  .\32xl\:-right-5 {
    right: -1.25rem;
  }
  .\32xl\:-right-6 {
    right: -1.5rem;
  }
  .\32xl\:-right-7 {
    right: -1.75rem;
  }
  .\32xl\:-right-8 {
    right: -2rem;
  }
  .\32xl\:-right-9 {
    right: -2.25rem;
  }
  .\32xl\:-right-10 {
    right: -2.5rem;
  }
  .\32xl\:-right-11 {
    right: -2.75rem;
  }
  .\32xl\:-right-12 {
    right: -3rem;
  }
  .\32xl\:-right-14 {
    right: -3.5rem;
  }
  .\32xl\:-right-16 {
    right: -4rem;
  }
  .\32xl\:-right-20 {
    right: -5rem;
  }
  .\32xl\:-right-24 {
    right: -6rem;
  }
  .\32xl\:-right-28 {
    right: -7rem;
  }
  .\32xl\:-right-32 {
    right: -8rem;
  }
  .\32xl\:-right-36 {
    right: -9rem;
  }
  .\32xl\:-right-40 {
    right: -10rem;
  }
  .\32xl\:-right-44 {
    right: -11rem;
  }
  .\32xl\:-right-48 {
    right: -12rem;
  }
  .\32xl\:-right-52 {
    right: -13rem;
  }
  .\32xl\:-right-56 {
    right: -14rem;
  }
  .\32xl\:-right-60 {
    right: -15rem;
  }
  .\32xl\:-right-64 {
    right: -16rem;
  }
  .\32xl\:-right-72 {
    right: -18rem;
  }
  .\32xl\:-right-80 {
    right: -20rem;
  }
  .\32xl\:-right-96 {
    right: -24rem;
  }
  .\32xl\:-right-px {
    right: -1px;
  }
  .\32xl\:-right-0\.5 {
    right: -0.125rem;
  }
  .\32xl\:-right-1\.5 {
    right: -0.375rem;
  }
  .\32xl\:-right-2\.5 {
    right: -0.625rem;
  }
  .\32xl\:-right-3\.5 {
    right: -0.875rem;
  }
  .\32xl\:right-1\/2 {
    right: 50%;
  }
  .\32xl\:right-1\/3 {
    right: 33.333333%;
  }
  .\32xl\:right-2\/3 {
    right: 66.666667%;
  }
  .\32xl\:right-1\/4 {
    right: 25%;
  }
  .\32xl\:right-2\/4 {
    right: 50%;
  }
  .\32xl\:right-3\/4 {
    right: 75%;
  }
  .\32xl\:right-full {
    right: 100%;
  }
  .\32xl\:-right-1\/2 {
    right: -50%;
  }
  .\32xl\:-right-1\/3 {
    right: -33.333333%;
  }
  .\32xl\:-right-2\/3 {
    right: -66.666667%;
  }
  .\32xl\:-right-1\/4 {
    right: -25%;
  }
  .\32xl\:-right-2\/4 {
    right: -50%;
  }
  .\32xl\:-right-3\/4 {
    right: -75%;
  }
  .\32xl\:-right-full {
    right: -100%;
  }
  .\32xl\:bottom-0 {
    bottom: 0;
  }
  .\32xl\:bottom-1 {
    bottom: 0.25rem;
  }
  .\32xl\:bottom-2 {
    bottom: 0.5rem;
  }
  .\32xl\:bottom-3 {
    bottom: 0.75rem;
  }
  .\32xl\:bottom-4 {
    bottom: 1rem;
  }
  .\32xl\:bottom-5 {
    bottom: 1.25rem;
  }
  .\32xl\:bottom-6 {
    bottom: 1.5rem;
  }
  .\32xl\:bottom-7 {
    bottom: 1.75rem;
  }
  .\32xl\:bottom-8 {
    bottom: 2rem;
  }
  .\32xl\:bottom-9 {
    bottom: 2.25rem;
  }
  .\32xl\:bottom-10 {
    bottom: 2.5rem;
  }
  .\32xl\:bottom-11 {
    bottom: 2.75rem;
  }
  .\32xl\:bottom-12 {
    bottom: 3rem;
  }
  .\32xl\:bottom-14 {
    bottom: 3.5rem;
  }
  .\32xl\:bottom-16 {
    bottom: 4rem;
  }
  .\32xl\:bottom-20 {
    bottom: 5rem;
  }
  .\32xl\:bottom-24 {
    bottom: 6rem;
  }
  .\32xl\:bottom-28 {
    bottom: 7rem;
  }
  .\32xl\:bottom-32 {
    bottom: 8rem;
  }
  .\32xl\:bottom-36 {
    bottom: 9rem;
  }
  .\32xl\:bottom-40 {
    bottom: 10rem;
  }
  .\32xl\:bottom-44 {
    bottom: 11rem;
  }
  .\32xl\:bottom-48 {
    bottom: 12rem;
  }
  .\32xl\:bottom-52 {
    bottom: 13rem;
  }
  .\32xl\:bottom-56 {
    bottom: 14rem;
  }
  .\32xl\:bottom-60 {
    bottom: 15rem;
  }
  .\32xl\:bottom-64 {
    bottom: 16rem;
  }
  .\32xl\:bottom-72 {
    bottom: 18rem;
  }
  .\32xl\:bottom-80 {
    bottom: 20rem;
  }
  .\32xl\:bottom-96 {
    bottom: 24rem;
  }
  .\32xl\:bottom-auto {
    bottom: auto;
  }
  .\32xl\:bottom-px {
    bottom: 1px;
  }
  .\32xl\:bottom-0\.5 {
    bottom: 0.125rem;
  }
  .\32xl\:bottom-1\.5 {
    bottom: 0.375rem;
  }
  .\32xl\:bottom-2\.5 {
    bottom: 0.625rem;
  }
  .\32xl\:bottom-3\.5 {
    bottom: 0.875rem;
  }
  .\32xl\:-bottom-0 {
    bottom: 0;
  }
  .\32xl\:-bottom-1 {
    bottom: -0.25rem;
  }
  .\32xl\:-bottom-2 {
    bottom: -0.5rem;
  }
  .\32xl\:-bottom-3 {
    bottom: -0.75rem;
  }
  .\32xl\:-bottom-4 {
    bottom: -1rem;
  }
  .\32xl\:-bottom-5 {
    bottom: -1.25rem;
  }
  .\32xl\:-bottom-6 {
    bottom: -1.5rem;
  }
  .\32xl\:-bottom-7 {
    bottom: -1.75rem;
  }
  .\32xl\:-bottom-8 {
    bottom: -2rem;
  }
  .\32xl\:-bottom-9 {
    bottom: -2.25rem;
  }
  .\32xl\:-bottom-10 {
    bottom: -2.5rem;
  }
  .\32xl\:-bottom-11 {
    bottom: -2.75rem;
  }
  .\32xl\:-bottom-12 {
    bottom: -3rem;
  }
  .\32xl\:-bottom-14 {
    bottom: -3.5rem;
  }
  .\32xl\:-bottom-16 {
    bottom: -4rem;
  }
  .\32xl\:-bottom-20 {
    bottom: -5rem;
  }
  .\32xl\:-bottom-24 {
    bottom: -6rem;
  }
  .\32xl\:-bottom-28 {
    bottom: -7rem;
  }
  .\32xl\:-bottom-32 {
    bottom: -8rem;
  }
  .\32xl\:-bottom-36 {
    bottom: -9rem;
  }
  .\32xl\:-bottom-40 {
    bottom: -10rem;
  }
  .\32xl\:-bottom-44 {
    bottom: -11rem;
  }
  .\32xl\:-bottom-48 {
    bottom: -12rem;
  }
  .\32xl\:-bottom-52 {
    bottom: -13rem;
  }
  .\32xl\:-bottom-56 {
    bottom: -14rem;
  }
  .\32xl\:-bottom-60 {
    bottom: -15rem;
  }
  .\32xl\:-bottom-64 {
    bottom: -16rem;
  }
  .\32xl\:-bottom-72 {
    bottom: -18rem;
  }
  .\32xl\:-bottom-80 {
    bottom: -20rem;
  }
  .\32xl\:-bottom-96 {
    bottom: -24rem;
  }
  .\32xl\:-bottom-px {
    bottom: -1px;
  }
  .\32xl\:-bottom-0\.5 {
    bottom: -0.125rem;
  }
  .\32xl\:-bottom-1\.5 {
    bottom: -0.375rem;
  }
  .\32xl\:-bottom-2\.5 {
    bottom: -0.625rem;
  }
  .\32xl\:-bottom-3\.5 {
    bottom: -0.875rem;
  }
  .\32xl\:bottom-1\/2 {
    bottom: 50%;
  }
  .\32xl\:bottom-1\/3 {
    bottom: 33.333333%;
  }
  .\32xl\:bottom-2\/3 {
    bottom: 66.666667%;
  }
  .\32xl\:bottom-1\/4 {
    bottom: 25%;
  }
  .\32xl\:bottom-2\/4 {
    bottom: 50%;
  }
  .\32xl\:bottom-3\/4 {
    bottom: 75%;
  }
  .\32xl\:bottom-full {
    bottom: 100%;
  }
  .\32xl\:-bottom-1\/2 {
    bottom: -50%;
  }
  .\32xl\:-bottom-1\/3 {
    bottom: -33.333333%;
  }
  .\32xl\:-bottom-2\/3 {
    bottom: -66.666667%;
  }
  .\32xl\:-bottom-1\/4 {
    bottom: -25%;
  }
  .\32xl\:-bottom-2\/4 {
    bottom: -50%;
  }
  .\32xl\:-bottom-3\/4 {
    bottom: -75%;
  }
  .\32xl\:-bottom-full {
    bottom: -100%;
  }
  .\32xl\:left-0 {
    left: 0;
  }
  .\32xl\:left-1 {
    left: 0.25rem;
  }
  .\32xl\:left-2 {
    left: 0.5rem;
  }
  .\32xl\:left-3 {
    left: 0.75rem;
  }
  .\32xl\:left-4 {
    left: 1rem;
  }
  .\32xl\:left-5 {
    left: 1.25rem;
  }
  .\32xl\:left-6 {
    left: 1.5rem;
  }
  .\32xl\:left-7 {
    left: 1.75rem;
  }
  .\32xl\:left-8 {
    left: 2rem;
  }
  .\32xl\:left-9 {
    left: 2.25rem;
  }
  .\32xl\:left-10 {
    left: 2.5rem;
  }
  .\32xl\:left-11 {
    left: 2.75rem;
  }
  .\32xl\:left-12 {
    left: 3rem;
  }
  .\32xl\:left-14 {
    left: 3.5rem;
  }
  .\32xl\:left-16 {
    left: 4rem;
  }
  .\32xl\:left-20 {
    left: 5rem;
  }
  .\32xl\:left-24 {
    left: 6rem;
  }
  .\32xl\:left-28 {
    left: 7rem;
  }
  .\32xl\:left-32 {
    left: 8rem;
  }
  .\32xl\:left-36 {
    left: 9rem;
  }
  .\32xl\:left-40 {
    left: 10rem;
  }
  .\32xl\:left-44 {
    left: 11rem;
  }
  .\32xl\:left-48 {
    left: 12rem;
  }
  .\32xl\:left-52 {
    left: 13rem;
  }
  .\32xl\:left-56 {
    left: 14rem;
  }
  .\32xl\:left-60 {
    left: 15rem;
  }
  .\32xl\:left-64 {
    left: 16rem;
  }
  .\32xl\:left-72 {
    left: 18rem;
  }
  .\32xl\:left-80 {
    left: 20rem;
  }
  .\32xl\:left-96 {
    left: 24rem;
  }
  .\32xl\:left-auto {
    left: auto;
  }
  .\32xl\:left-px {
    left: 1px;
  }
  .\32xl\:left-0\.5 {
    left: 0.125rem;
  }
  .\32xl\:left-1\.5 {
    left: 0.375rem;
  }
  .\32xl\:left-2\.5 {
    left: 0.625rem;
  }
  .\32xl\:left-3\.5 {
    left: 0.875rem;
  }
  .\32xl\:-left-0 {
    left: 0;
  }
  .\32xl\:-left-1 {
    left: -0.25rem;
  }
  .\32xl\:-left-2 {
    left: -0.5rem;
  }
  .\32xl\:-left-3 {
    left: -0.75rem;
  }
  .\32xl\:-left-4 {
    left: -1rem;
  }
  .\32xl\:-left-5 {
    left: -1.25rem;
  }
  .\32xl\:-left-6 {
    left: -1.5rem;
  }
  .\32xl\:-left-7 {
    left: -1.75rem;
  }
  .\32xl\:-left-8 {
    left: -2rem;
  }
  .\32xl\:-left-9 {
    left: -2.25rem;
  }
  .\32xl\:-left-10 {
    left: -2.5rem;
  }
  .\32xl\:-left-11 {
    left: -2.75rem;
  }
  .\32xl\:-left-12 {
    left: -3rem;
  }
  .\32xl\:-left-14 {
    left: -3.5rem;
  }
  .\32xl\:-left-16 {
    left: -4rem;
  }
  .\32xl\:-left-20 {
    left: -5rem;
  }
  .\32xl\:-left-24 {
    left: -6rem;
  }
  .\32xl\:-left-28 {
    left: -7rem;
  }
  .\32xl\:-left-32 {
    left: -8rem;
  }
  .\32xl\:-left-36 {
    left: -9rem;
  }
  .\32xl\:-left-40 {
    left: -10rem;
  }
  .\32xl\:-left-44 {
    left: -11rem;
  }
  .\32xl\:-left-48 {
    left: -12rem;
  }
  .\32xl\:-left-52 {
    left: -13rem;
  }
  .\32xl\:-left-56 {
    left: -14rem;
  }
  .\32xl\:-left-60 {
    left: -15rem;
  }
  .\32xl\:-left-64 {
    left: -16rem;
  }
  .\32xl\:-left-72 {
    left: -18rem;
  }
  .\32xl\:-left-80 {
    left: -20rem;
  }
  .\32xl\:-left-96 {
    left: -24rem;
  }
  .\32xl\:-left-px {
    left: -1px;
  }
  .\32xl\:-left-0\.5 {
    left: -0.125rem;
  }
  .\32xl\:-left-1\.5 {
    left: -0.375rem;
  }
  .\32xl\:-left-2\.5 {
    left: -0.625rem;
  }
  .\32xl\:-left-3\.5 {
    left: -0.875rem;
  }
  .\32xl\:left-1\/2 {
    left: 50%;
  }
  .\32xl\:left-1\/3 {
    left: 33.333333%;
  }
  .\32xl\:left-2\/3 {
    left: 66.666667%;
  }
  .\32xl\:left-1\/4 {
    left: 25%;
  }
  .\32xl\:left-2\/4 {
    left: 50%;
  }
  .\32xl\:left-3\/4 {
    left: 75%;
  }
  .\32xl\:left-full {
    left: 100%;
  }
  .\32xl\:-left-1\/2 {
    left: -50%;
  }
  .\32xl\:-left-1\/3 {
    left: -33.333333%;
  }
  .\32xl\:-left-2\/3 {
    left: -66.666667%;
  }
  .\32xl\:-left-1\/4 {
    left: -25%;
  }
  .\32xl\:-left-2\/4 {
    left: -50%;
  }
  .\32xl\:-left-3\/4 {
    left: -75%;
  }
  .\32xl\:-left-full {
    left: -100%;
  }
  .\32xl\:isolate {
    isolation: isolate;
  }
  .\32xl\:isolation-auto {
    isolation: auto;
  }
  .\32xl\:z-0 {
    z-index: 0;
  }
  .\32xl\:z-10 {
    z-index: 10;
  }
  .\32xl\:z-20 {
    z-index: 20;
  }
  .\32xl\:z-30 {
    z-index: 30;
  }
  .\32xl\:z-40 {
    z-index: 40;
  }
  .\32xl\:z-50 {
    z-index: 50;
  }
  .\32xl\:z-auto {
    z-index: auto;
  }
  .\32xl\:focus-within\:z-0:focus-within {
    z-index: 0;
  }
  .\32xl\:focus-within\:z-10:focus-within {
    z-index: 10;
  }
  .\32xl\:focus-within\:z-20:focus-within {
    z-index: 20;
  }
  .\32xl\:focus-within\:z-30:focus-within {
    z-index: 30;
  }
  .\32xl\:focus-within\:z-40:focus-within {
    z-index: 40;
  }
  .\32xl\:focus-within\:z-50:focus-within {
    z-index: 50;
  }
  .\32xl\:focus-within\:z-auto:focus-within {
    z-index: auto;
  }
  .\32xl\:focus\:z-0:focus {
    z-index: 0;
  }
  .\32xl\:focus\:z-10:focus {
    z-index: 10;
  }
  .\32xl\:focus\:z-20:focus {
    z-index: 20;
  }
  .\32xl\:focus\:z-30:focus {
    z-index: 30;
  }
  .\32xl\:focus\:z-40:focus {
    z-index: 40;
  }
  .\32xl\:focus\:z-50:focus {
    z-index: 50;
  }
  .\32xl\:focus\:z-auto:focus {
    z-index: auto;
  }
  .\32xl\:order-1 {
    order: 1;
  }
  .\32xl\:order-2 {
    order: 2;
  }
  .\32xl\:order-3 {
    order: 3;
  }
  .\32xl\:order-4 {
    order: 4;
  }
  .\32xl\:order-5 {
    order: 5;
  }
  .\32xl\:order-6 {
    order: 6;
  }
  .\32xl\:order-7 {
    order: 7;
  }
  .\32xl\:order-8 {
    order: 8;
  }
  .\32xl\:order-9 {
    order: 9;
  }
  .\32xl\:order-10 {
    order: 10;
  }
  .\32xl\:order-11 {
    order: 11;
  }
  .\32xl\:order-12 {
    order: 12;
  }
  .\32xl\:order-first {
    order: -9999;
  }
  .\32xl\:order-last {
    order: 9999;
  }
  .\32xl\:order-none {
    order: 0;
  }
  .\32xl\:col-auto {
    grid-column: auto;
  }
  .\32xl\:col-span-1 {
    grid-column: span 1 / span 1;
  }
  .\32xl\:col-span-2 {
    grid-column: span 2 / span 2;
  }
  .\32xl\:col-span-3 {
    grid-column: span 3 / span 3;
  }
  .\32xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  .\32xl\:col-span-5 {
    grid-column: span 5 / span 5;
  }
  .\32xl\:col-span-6 {
    grid-column: span 6 / span 6;
  }
  .\32xl\:col-span-7 {
    grid-column: span 7 / span 7;
  }
  .\32xl\:col-span-8 {
    grid-column: span 8 / span 8;
  }
  .\32xl\:col-span-9 {
    grid-column: span 9 / span 9;
  }
  .\32xl\:col-span-10 {
    grid-column: span 10 / span 10;
  }
  .\32xl\:col-span-11 {
    grid-column: span 11 / span 11;
  }
  .\32xl\:col-span-12 {
    grid-column: span 12 / span 12;
  }
  .\32xl\:col-span-full {
    grid-column: 1/-1;
  }
  .\32xl\:col-start-1 {
    grid-column-start: 1;
  }
  .\32xl\:col-start-2 {
    grid-column-start: 2;
  }
  .\32xl\:col-start-3 {
    grid-column-start: 3;
  }
  .\32xl\:col-start-4 {
    grid-column-start: 4;
  }
  .\32xl\:col-start-5 {
    grid-column-start: 5;
  }
  .\32xl\:col-start-6 {
    grid-column-start: 6;
  }
  .\32xl\:col-start-7 {
    grid-column-start: 7;
  }
  .\32xl\:col-start-8 {
    grid-column-start: 8;
  }
  .\32xl\:col-start-9 {
    grid-column-start: 9;
  }
  .\32xl\:col-start-10 {
    grid-column-start: 10;
  }
  .\32xl\:col-start-11 {
    grid-column-start: 11;
  }
  .\32xl\:col-start-12 {
    grid-column-start: 12;
  }
  .\32xl\:col-start-13 {
    grid-column-start: 13;
  }
  .\32xl\:col-start-auto {
    grid-column-start: auto;
  }
  .\32xl\:col-end-1 {
    grid-column-end: 1;
  }
  .\32xl\:col-end-2 {
    grid-column-end: 2;
  }
  .\32xl\:col-end-3 {
    grid-column-end: 3;
  }
  .\32xl\:col-end-4 {
    grid-column-end: 4;
  }
  .\32xl\:col-end-5 {
    grid-column-end: 5;
  }
  .\32xl\:col-end-6 {
    grid-column-end: 6;
  }
  .\32xl\:col-end-7 {
    grid-column-end: 7;
  }
  .\32xl\:col-end-8 {
    grid-column-end: 8;
  }
  .\32xl\:col-end-9 {
    grid-column-end: 9;
  }
  .\32xl\:col-end-10 {
    grid-column-end: 10;
  }
  .\32xl\:col-end-11 {
    grid-column-end: 11;
  }
  .\32xl\:col-end-12 {
    grid-column-end: 12;
  }
  .\32xl\:col-end-13 {
    grid-column-end: 13;
  }
  .\32xl\:col-end-auto {
    grid-column-end: auto;
  }
  .\32xl\:row-auto {
    grid-row: auto;
  }
  .\32xl\:row-span-1 {
    grid-row: span 1 / span 1;
  }
  .\32xl\:row-span-2 {
    grid-row: span 2 / span 2;
  }
  .\32xl\:row-span-3 {
    grid-row: span 3 / span 3;
  }
  .\32xl\:row-span-4 {
    grid-row: span 4 / span 4;
  }
  .\32xl\:row-span-5 {
    grid-row: span 5 / span 5;
  }
  .\32xl\:row-span-6 {
    grid-row: span 6 / span 6;
  }
  .\32xl\:row-span-full {
    grid-row: 1/-1;
  }
  .\32xl\:row-start-1 {
    grid-row-start: 1;
  }
  .\32xl\:row-start-2 {
    grid-row-start: 2;
  }
  .\32xl\:row-start-3 {
    grid-row-start: 3;
  }
  .\32xl\:row-start-4 {
    grid-row-start: 4;
  }
  .\32xl\:row-start-5 {
    grid-row-start: 5;
  }
  .\32xl\:row-start-6 {
    grid-row-start: 6;
  }
  .\32xl\:row-start-7 {
    grid-row-start: 7;
  }
  .\32xl\:row-start-auto {
    grid-row-start: auto;
  }
  .\32xl\:row-end-1 {
    grid-row-end: 1;
  }
  .\32xl\:row-end-2 {
    grid-row-end: 2;
  }
  .\32xl\:row-end-3 {
    grid-row-end: 3;
  }
  .\32xl\:row-end-4 {
    grid-row-end: 4;
  }
  .\32xl\:row-end-5 {
    grid-row-end: 5;
  }
  .\32xl\:row-end-6 {
    grid-row-end: 6;
  }
  .\32xl\:row-end-7 {
    grid-row-end: 7;
  }
  .\32xl\:row-end-auto {
    grid-row-end: auto;
  }
  .\32xl\:float-right {
    float: right;
  }
  .\32xl\:float-left {
    float: left;
  }
  .\32xl\:float-none {
    float: none;
  }
  .\32xl\:clear-left {
    clear: left;
  }
  .\32xl\:clear-right {
    clear: right;
  }
  .\32xl\:clear-both {
    clear: both;
  }
  .\32xl\:clear-none {
    clear: none;
  }
  .\32xl\:m-0 {
    margin: 0;
  }
  .\32xl\:m-1 {
    margin: 0.25rem;
  }
  .\32xl\:m-2 {
    margin: 0.5rem;
  }
  .\32xl\:m-3 {
    margin: 0.75rem;
  }
  .\32xl\:m-4 {
    margin: 1rem;
  }
  .\32xl\:m-5 {
    margin: 1.25rem;
  }
  .\32xl\:m-6 {
    margin: 1.5rem;
  }
  .\32xl\:m-7 {
    margin: 1.75rem;
  }
  .\32xl\:m-8 {
    margin: 2rem;
  }
  .\32xl\:m-9 {
    margin: 2.25rem;
  }
  .\32xl\:m-10 {
    margin: 2.5rem;
  }
  .\32xl\:m-11 {
    margin: 2.75rem;
  }
  .\32xl\:m-12 {
    margin: 3rem;
  }
  .\32xl\:m-14 {
    margin: 3.5rem;
  }
  .\32xl\:m-16 {
    margin: 4rem;
  }
  .\32xl\:m-20 {
    margin: 5rem;
  }
  .\32xl\:m-24 {
    margin: 6rem;
  }
  .\32xl\:m-28 {
    margin: 7rem;
  }
  .\32xl\:m-32 {
    margin: 8rem;
  }
  .\32xl\:m-36 {
    margin: 9rem;
  }
  .\32xl\:m-40 {
    margin: 10rem;
  }
  .\32xl\:m-44 {
    margin: 11rem;
  }
  .\32xl\:m-48 {
    margin: 12rem;
  }
  .\32xl\:m-52 {
    margin: 13rem;
  }
  .\32xl\:m-56 {
    margin: 14rem;
  }
  .\32xl\:m-60 {
    margin: 15rem;
  }
  .\32xl\:m-64 {
    margin: 16rem;
  }
  .\32xl\:m-72 {
    margin: 18rem;
  }
  .\32xl\:m-80 {
    margin: 20rem;
  }
  .\32xl\:m-96 {
    margin: 24rem;
  }
  .\32xl\:m-auto {
    margin: auto;
  }
  .\32xl\:m-px {
    margin: 1px;
  }
  .\32xl\:m-0\.5 {
    margin: 0.125rem;
  }
  .\32xl\:m-1\.5 {
    margin: 0.375rem;
  }
  .\32xl\:m-2\.5 {
    margin: 0.625rem;
  }
  .\32xl\:m-3\.5 {
    margin: 0.875rem;
  }
  .\32xl\:-m-0 {
    margin: 0;
  }
  .\32xl\:-m-1 {
    margin: -0.25rem;
  }
  .\32xl\:-m-2 {
    margin: -0.5rem;
  }
  .\32xl\:-m-3 {
    margin: -0.75rem;
  }
  .\32xl\:-m-4 {
    margin: -1rem;
  }
  .\32xl\:-m-5 {
    margin: -1.25rem;
  }
  .\32xl\:-m-6 {
    margin: -1.5rem;
  }
  .\32xl\:-m-7 {
    margin: -1.75rem;
  }
  .\32xl\:-m-8 {
    margin: -2rem;
  }
  .\32xl\:-m-9 {
    margin: -2.25rem;
  }
  .\32xl\:-m-10 {
    margin: -2.5rem;
  }
  .\32xl\:-m-11 {
    margin: -2.75rem;
  }
  .\32xl\:-m-12 {
    margin: -3rem;
  }
  .\32xl\:-m-14 {
    margin: -3.5rem;
  }
  .\32xl\:-m-16 {
    margin: -4rem;
  }
  .\32xl\:-m-20 {
    margin: -5rem;
  }
  .\32xl\:-m-24 {
    margin: -6rem;
  }
  .\32xl\:-m-28 {
    margin: -7rem;
  }
  .\32xl\:-m-32 {
    margin: -8rem;
  }
  .\32xl\:-m-36 {
    margin: -9rem;
  }
  .\32xl\:-m-40 {
    margin: -10rem;
  }
  .\32xl\:-m-44 {
    margin: -11rem;
  }
  .\32xl\:-m-48 {
    margin: -12rem;
  }
  .\32xl\:-m-52 {
    margin: -13rem;
  }
  .\32xl\:-m-56 {
    margin: -14rem;
  }
  .\32xl\:-m-60 {
    margin: -15rem;
  }
  .\32xl\:-m-64 {
    margin: -16rem;
  }
  .\32xl\:-m-72 {
    margin: -18rem;
  }
  .\32xl\:-m-80 {
    margin: -20rem;
  }
  .\32xl\:-m-96 {
    margin: -24rem;
  }
  .\32xl\:-m-px {
    margin: -1px;
  }
  .\32xl\:-m-0\.5 {
    margin: -0.125rem;
  }
  .\32xl\:-m-1\.5 {
    margin: -0.375rem;
  }
  .\32xl\:-m-2\.5 {
    margin: -0.625rem;
  }
  .\32xl\:-m-3\.5 {
    margin: -0.875rem;
  }
  .\32xl\:mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .\32xl\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .\32xl\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .\32xl\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .\32xl\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .\32xl\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .\32xl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .\32xl\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .\32xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .\32xl\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .\32xl\:mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }
  .\32xl\:mx-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem;
  }
  .\32xl\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .\32xl\:mx-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem;
  }
  .\32xl\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }
  .\32xl\:mx-20 {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  .\32xl\:mx-24 {
    margin-left: 6rem;
    margin-right: 6rem;
  }
  .\32xl\:mx-28 {
    margin-left: 7rem;
    margin-right: 7rem;
  }
  .\32xl\:mx-32 {
    margin-left: 8rem;
    margin-right: 8rem;
  }
  .\32xl\:mx-36 {
    margin-left: 9rem;
    margin-right: 9rem;
  }
  .\32xl\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }
  .\32xl\:mx-44 {
    margin-left: 11rem;
    margin-right: 11rem;
  }
  .\32xl\:mx-48 {
    margin-left: 12rem;
    margin-right: 12rem;
  }
  .\32xl\:mx-52 {
    margin-left: 13rem;
    margin-right: 13rem;
  }
  .\32xl\:mx-56 {
    margin-left: 14rem;
    margin-right: 14rem;
  }
  .\32xl\:mx-60 {
    margin-left: 15rem;
    margin-right: 15rem;
  }
  .\32xl\:mx-64 {
    margin-left: 16rem;
    margin-right: 16rem;
  }
  .\32xl\:mx-72 {
    margin-left: 18rem;
    margin-right: 18rem;
  }
  .\32xl\:mx-80 {
    margin-left: 20rem;
    margin-right: 20rem;
  }
  .\32xl\:mx-96 {
    margin-left: 24rem;
    margin-right: 24rem;
  }
  .\32xl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .\32xl\:mx-px {
    margin-left: 1px;
    margin-right: 1px;
  }
  .\32xl\:mx-0\.5 {
    margin-left: 0.125rem;
    margin-right: 0.125rem;
  }
  .\32xl\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .\32xl\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .\32xl\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .\32xl\:-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }
  .\32xl\:-mx-1 {
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .\32xl\:-mx-2 {
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  .\32xl\:-mx-3 {
    margin-left: -0.75rem;
    margin-right: -0.75rem;
  }
  .\32xl\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .\32xl\:-mx-5 {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
  }
  .\32xl\:-mx-6 {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .\32xl\:-mx-7 {
    margin-left: -1.75rem;
    margin-right: -1.75rem;
  }
  .\32xl\:-mx-8 {
    margin-left: -2rem;
    margin-right: -2rem;
  }
  .\32xl\:-mx-9 {
    margin-left: -2.25rem;
    margin-right: -2.25rem;
  }
  .\32xl\:-mx-10 {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
  }
  .\32xl\:-mx-11 {
    margin-left: -2.75rem;
    margin-right: -2.75rem;
  }
  .\32xl\:-mx-12 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
  .\32xl\:-mx-14 {
    margin-left: -3.5rem;
    margin-right: -3.5rem;
  }
  .\32xl\:-mx-16 {
    margin-left: -4rem;
    margin-right: -4rem;
  }
  .\32xl\:-mx-20 {
    margin-left: -5rem;
    margin-right: -5rem;
  }
  .\32xl\:-mx-24 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
  .\32xl\:-mx-28 {
    margin-left: -7rem;
    margin-right: -7rem;
  }
  .\32xl\:-mx-32 {
    margin-left: -8rem;
    margin-right: -8rem;
  }
  .\32xl\:-mx-36 {
    margin-left: -9rem;
    margin-right: -9rem;
  }
  .\32xl\:-mx-40 {
    margin-left: -10rem;
    margin-right: -10rem;
  }
  .\32xl\:-mx-44 {
    margin-left: -11rem;
    margin-right: -11rem;
  }
  .\32xl\:-mx-48 {
    margin-left: -12rem;
    margin-right: -12rem;
  }
  .\32xl\:-mx-52 {
    margin-left: -13rem;
    margin-right: -13rem;
  }
  .\32xl\:-mx-56 {
    margin-left: -14rem;
    margin-right: -14rem;
  }
  .\32xl\:-mx-60 {
    margin-left: -15rem;
    margin-right: -15rem;
  }
  .\32xl\:-mx-64 {
    margin-left: -16rem;
    margin-right: -16rem;
  }
  .\32xl\:-mx-72 {
    margin-left: -18rem;
    margin-right: -18rem;
  }
  .\32xl\:-mx-80 {
    margin-left: -20rem;
    margin-right: -20rem;
  }
  .\32xl\:-mx-96 {
    margin-left: -24rem;
    margin-right: -24rem;
  }
  .\32xl\:-mx-px {
    margin-left: -1px;
    margin-right: -1px;
  }
  .\32xl\:-mx-0\.5 {
    margin-left: -0.125rem;
    margin-right: -0.125rem;
  }
  .\32xl\:-mx-1\.5 {
    margin-left: -0.375rem;
    margin-right: -0.375rem;
  }
  .\32xl\:-mx-2\.5 {
    margin-left: -0.625rem;
    margin-right: -0.625rem;
  }
  .\32xl\:-mx-3\.5 {
    margin-left: -0.875rem;
    margin-right: -0.875rem;
  }
  .\32xl\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .\32xl\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .\32xl\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .\32xl\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .\32xl\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .\32xl\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .\32xl\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .\32xl\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .\32xl\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .\32xl\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .\32xl\:my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }
  .\32xl\:my-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem;
  }
  .\32xl\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .\32xl\:my-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  .\32xl\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }
  .\32xl\:my-20 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .\32xl\:my-24 {
    margin-top: 6rem;
    margin-bottom: 6rem;
  }
  .\32xl\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
  .\32xl\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .\32xl\:my-36 {
    margin-top: 9rem;
    margin-bottom: 9rem;
  }
  .\32xl\:my-40 {
    margin-top: 10rem;
    margin-bottom: 10rem;
  }
  .\32xl\:my-44 {
    margin-top: 11rem;
    margin-bottom: 11rem;
  }
  .\32xl\:my-48 {
    margin-top: 12rem;
    margin-bottom: 12rem;
  }
  .\32xl\:my-52 {
    margin-top: 13rem;
    margin-bottom: 13rem;
  }
  .\32xl\:my-56 {
    margin-top: 14rem;
    margin-bottom: 14rem;
  }
  .\32xl\:my-60 {
    margin-top: 15rem;
    margin-bottom: 15rem;
  }
  .\32xl\:my-64 {
    margin-top: 16rem;
    margin-bottom: 16rem;
  }
  .\32xl\:my-72 {
    margin-top: 18rem;
    margin-bottom: 18rem;
  }
  .\32xl\:my-80 {
    margin-top: 20rem;
    margin-bottom: 20rem;
  }
  .\32xl\:my-96 {
    margin-top: 24rem;
    margin-bottom: 24rem;
  }
  .\32xl\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .\32xl\:my-px {
    margin-top: 1px;
    margin-bottom: 1px;
  }
  .\32xl\:my-0\.5 {
    margin-top: 0.125rem;
    margin-bottom: 0.125rem;
  }
  .\32xl\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .\32xl\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .\32xl\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .\32xl\:-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .\32xl\:-my-1 {
    margin-top: -0.25rem;
    margin-bottom: -0.25rem;
  }
  .\32xl\:-my-2 {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
  }
  .\32xl\:-my-3 {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
  }
  .\32xl\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .\32xl\:-my-5 {
    margin-top: -1.25rem;
    margin-bottom: -1.25rem;
  }
  .\32xl\:-my-6 {
    margin-top: -1.5rem;
    margin-bottom: -1.5rem;
  }
  .\32xl\:-my-7 {
    margin-top: -1.75rem;
    margin-bottom: -1.75rem;
  }
  .\32xl\:-my-8 {
    margin-top: -2rem;
    margin-bottom: -2rem;
  }
  .\32xl\:-my-9 {
    margin-top: -2.25rem;
    margin-bottom: -2.25rem;
  }
  .\32xl\:-my-10 {
    margin-top: -2.5rem;
    margin-bottom: -2.5rem;
  }
  .\32xl\:-my-11 {
    margin-top: -2.75rem;
    margin-bottom: -2.75rem;
  }
  .\32xl\:-my-12 {
    margin-top: -3rem;
    margin-bottom: -3rem;
  }
  .\32xl\:-my-14 {
    margin-top: -3.5rem;
    margin-bottom: -3.5rem;
  }
  .\32xl\:-my-16 {
    margin-top: -4rem;
    margin-bottom: -4rem;
  }
  .\32xl\:-my-20 {
    margin-top: -5rem;
    margin-bottom: -5rem;
  }
  .\32xl\:-my-24 {
    margin-top: -6rem;
    margin-bottom: -6rem;
  }
  .\32xl\:-my-28 {
    margin-top: -7rem;
    margin-bottom: -7rem;
  }
  .\32xl\:-my-32 {
    margin-top: -8rem;
    margin-bottom: -8rem;
  }
  .\32xl\:-my-36 {
    margin-top: -9rem;
    margin-bottom: -9rem;
  }
  .\32xl\:-my-40 {
    margin-top: -10rem;
    margin-bottom: -10rem;
  }
  .\32xl\:-my-44 {
    margin-top: -11rem;
    margin-bottom: -11rem;
  }
  .\32xl\:-my-48 {
    margin-top: -12rem;
    margin-bottom: -12rem;
  }
  .\32xl\:-my-52 {
    margin-top: -13rem;
    margin-bottom: -13rem;
  }
  .\32xl\:-my-56 {
    margin-top: -14rem;
    margin-bottom: -14rem;
  }
  .\32xl\:-my-60 {
    margin-top: -15rem;
    margin-bottom: -15rem;
  }
  .\32xl\:-my-64 {
    margin-top: -16rem;
    margin-bottom: -16rem;
  }
  .\32xl\:-my-72 {
    margin-top: -18rem;
    margin-bottom: -18rem;
  }
  .\32xl\:-my-80 {
    margin-top: -20rem;
    margin-bottom: -20rem;
  }
  .\32xl\:-my-96 {
    margin-top: -24rem;
    margin-bottom: -24rem;
  }
  .\32xl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .\32xl\:-my-0\.5 {
    margin-top: -0.125rem;
    margin-bottom: -0.125rem;
  }
  .\32xl\:-my-1\.5 {
    margin-top: -0.375rem;
    margin-bottom: -0.375rem;
  }
  .\32xl\:-my-2\.5 {
    margin-top: -0.625rem;
    margin-bottom: -0.625rem;
  }
  .\32xl\:-my-3\.5 {
    margin-top: -0.875rem;
    margin-bottom: -0.875rem;
  }
  .\32xl\:mt-0 {
    margin-top: 0;
  }
  .\32xl\:mt-1 {
    margin-top: 0.25rem;
  }
  .\32xl\:mt-2 {
    margin-top: 0.5rem;
  }
  .\32xl\:mt-3 {
    margin-top: 0.75rem;
  }
  .\32xl\:mt-4 {
    margin-top: 1rem;
  }
  .\32xl\:mt-5 {
    margin-top: 1.25rem;
  }
  .\32xl\:mt-6 {
    margin-top: 1.5rem;
  }
  .\32xl\:mt-7 {
    margin-top: 1.75rem;
  }
  .\32xl\:mt-8 {
    margin-top: 2rem;
  }
  .\32xl\:mt-9 {
    margin-top: 2.25rem;
  }
  .\32xl\:mt-10 {
    margin-top: 2.5rem;
  }
  .\32xl\:mt-11 {
    margin-top: 2.75rem;
  }
  .\32xl\:mt-12 {
    margin-top: 3rem;
  }
  .\32xl\:mt-14 {
    margin-top: 3.5rem;
  }
  .\32xl\:mt-16 {
    margin-top: 4rem;
  }
  .\32xl\:mt-20 {
    margin-top: 5rem;
  }
  .\32xl\:mt-24 {
    margin-top: 6rem;
  }
  .\32xl\:mt-28 {
    margin-top: 7rem;
  }
  .\32xl\:mt-32 {
    margin-top: 8rem;
  }
  .\32xl\:mt-36 {
    margin-top: 9rem;
  }
  .\32xl\:mt-40 {
    margin-top: 10rem;
  }
  .\32xl\:mt-44 {
    margin-top: 11rem;
  }
  .\32xl\:mt-48 {
    margin-top: 12rem;
  }
  .\32xl\:mt-52 {
    margin-top: 13rem;
  }
  .\32xl\:mt-56 {
    margin-top: 14rem;
  }
  .\32xl\:mt-60 {
    margin-top: 15rem;
  }
  .\32xl\:mt-64 {
    margin-top: 16rem;
  }
  .\32xl\:mt-72 {
    margin-top: 18rem;
  }
  .\32xl\:mt-80 {
    margin-top: 20rem;
  }
  .\32xl\:mt-96 {
    margin-top: 24rem;
  }
  .\32xl\:mt-auto {
    margin-top: auto;
  }
  .\32xl\:mt-px {
    margin-top: 1px;
  }
  .\32xl\:mt-0\.5 {
    margin-top: 0.125rem;
  }
  .\32xl\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .\32xl\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .\32xl\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .\32xl\:-mt-0 {
    margin-top: 0;
  }
  .\32xl\:-mt-1 {
    margin-top: -0.25rem;
  }
  .\32xl\:-mt-2 {
    margin-top: -0.5rem;
  }
  .\32xl\:-mt-3 {
    margin-top: -0.75rem;
  }
  .\32xl\:-mt-4 {
    margin-top: -1rem;
  }
  .\32xl\:-mt-5 {
    margin-top: -1.25rem;
  }
  .\32xl\:-mt-6 {
    margin-top: -1.5rem;
  }
  .\32xl\:-mt-7 {
    margin-top: -1.75rem;
  }
  .\32xl\:-mt-8 {
    margin-top: -2rem;
  }
  .\32xl\:-mt-9 {
    margin-top: -2.25rem;
  }
  .\32xl\:-mt-10 {
    margin-top: -2.5rem;
  }
  .\32xl\:-mt-11 {
    margin-top: -2.75rem;
  }
  .\32xl\:-mt-12 {
    margin-top: -3rem;
  }
  .\32xl\:-mt-14 {
    margin-top: -3.5rem;
  }
  .\32xl\:-mt-16 {
    margin-top: -4rem;
  }
  .\32xl\:-mt-20 {
    margin-top: -5rem;
  }
  .\32xl\:-mt-24 {
    margin-top: -6rem;
  }
  .\32xl\:-mt-28 {
    margin-top: -7rem;
  }
  .\32xl\:-mt-32 {
    margin-top: -8rem;
  }
  .\32xl\:-mt-36 {
    margin-top: -9rem;
  }
  .\32xl\:-mt-40 {
    margin-top: -10rem;
  }
  .\32xl\:-mt-44 {
    margin-top: -11rem;
  }
  .\32xl\:-mt-48 {
    margin-top: -12rem;
  }
  .\32xl\:-mt-52 {
    margin-top: -13rem;
  }
  .\32xl\:-mt-56 {
    margin-top: -14rem;
  }
  .\32xl\:-mt-60 {
    margin-top: -15rem;
  }
  .\32xl\:-mt-64 {
    margin-top: -16rem;
  }
  .\32xl\:-mt-72 {
    margin-top: -18rem;
  }
  .\32xl\:-mt-80 {
    margin-top: -20rem;
  }
  .\32xl\:-mt-96 {
    margin-top: -24rem;
  }
  .\32xl\:-mt-px {
    margin-top: -1px;
  }
  .\32xl\:-mt-0\.5 {
    margin-top: -0.125rem;
  }
  .\32xl\:-mt-1\.5 {
    margin-top: -0.375rem;
  }
  .\32xl\:-mt-2\.5 {
    margin-top: -0.625rem;
  }
  .\32xl\:-mt-3\.5 {
    margin-top: -0.875rem;
  }
  .\32xl\:mr-0 {
    margin-right: 0;
  }
  .\32xl\:mr-1 {
    margin-right: 0.25rem;
  }
  .\32xl\:mr-2 {
    margin-right: 0.5rem;
  }
  .\32xl\:mr-3 {
    margin-right: 0.75rem;
  }
  .\32xl\:mr-4 {
    margin-right: 1rem;
  }
  .\32xl\:mr-5 {
    margin-right: 1.25rem;
  }
  .\32xl\:mr-6 {
    margin-right: 1.5rem;
  }
  .\32xl\:mr-7 {
    margin-right: 1.75rem;
  }
  .\32xl\:mr-8 {
    margin-right: 2rem;
  }
  .\32xl\:mr-9 {
    margin-right: 2.25rem;
  }
  .\32xl\:mr-10 {
    margin-right: 2.5rem;
  }
  .\32xl\:mr-11 {
    margin-right: 2.75rem;
  }
  .\32xl\:mr-12 {
    margin-right: 3rem;
  }
  .\32xl\:mr-14 {
    margin-right: 3.5rem;
  }
  .\32xl\:mr-16 {
    margin-right: 4rem;
  }
  .\32xl\:mr-20 {
    margin-right: 5rem;
  }
  .\32xl\:mr-24 {
    margin-right: 6rem;
  }
  .\32xl\:mr-28 {
    margin-right: 7rem;
  }
  .\32xl\:mr-32 {
    margin-right: 8rem;
  }
  .\32xl\:mr-36 {
    margin-right: 9rem;
  }
  .\32xl\:mr-40 {
    margin-right: 10rem;
  }
  .\32xl\:mr-44 {
    margin-right: 11rem;
  }
  .\32xl\:mr-48 {
    margin-right: 12rem;
  }
  .\32xl\:mr-52 {
    margin-right: 13rem;
  }
  .\32xl\:mr-56 {
    margin-right: 14rem;
  }
  .\32xl\:mr-60 {
    margin-right: 15rem;
  }
  .\32xl\:mr-64 {
    margin-right: 16rem;
  }
  .\32xl\:mr-72 {
    margin-right: 18rem;
  }
  .\32xl\:mr-80 {
    margin-right: 20rem;
  }
  .\32xl\:mr-96 {
    margin-right: 24rem;
  }
  .\32xl\:mr-auto {
    margin-right: auto;
  }
  .\32xl\:mr-px {
    margin-right: 1px;
  }
  .\32xl\:mr-0\.5 {
    margin-right: 0.125rem;
  }
  .\32xl\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .\32xl\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .\32xl\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .\32xl\:-mr-0 {
    margin-right: 0;
  }
  .\32xl\:-mr-1 {
    margin-right: -0.25rem;
  }
  .\32xl\:-mr-2 {
    margin-right: -0.5rem;
  }
  .\32xl\:-mr-3 {
    margin-right: -0.75rem;
  }
  .\32xl\:-mr-4 {
    margin-right: -1rem;
  }
  .\32xl\:-mr-5 {
    margin-right: -1.25rem;
  }
  .\32xl\:-mr-6 {
    margin-right: -1.5rem;
  }
  .\32xl\:-mr-7 {
    margin-right: -1.75rem;
  }
  .\32xl\:-mr-8 {
    margin-right: -2rem;
  }
  .\32xl\:-mr-9 {
    margin-right: -2.25rem;
  }
  .\32xl\:-mr-10 {
    margin-right: -2.5rem;
  }
  .\32xl\:-mr-11 {
    margin-right: -2.75rem;
  }
  .\32xl\:-mr-12 {
    margin-right: -3rem;
  }
  .\32xl\:-mr-14 {
    margin-right: -3.5rem;
  }
  .\32xl\:-mr-16 {
    margin-right: -4rem;
  }
  .\32xl\:-mr-20 {
    margin-right: -5rem;
  }
  .\32xl\:-mr-24 {
    margin-right: -6rem;
  }
  .\32xl\:-mr-28 {
    margin-right: -7rem;
  }
  .\32xl\:-mr-32 {
    margin-right: -8rem;
  }
  .\32xl\:-mr-36 {
    margin-right: -9rem;
  }
  .\32xl\:-mr-40 {
    margin-right: -10rem;
  }
  .\32xl\:-mr-44 {
    margin-right: -11rem;
  }
  .\32xl\:-mr-48 {
    margin-right: -12rem;
  }
  .\32xl\:-mr-52 {
    margin-right: -13rem;
  }
  .\32xl\:-mr-56 {
    margin-right: -14rem;
  }
  .\32xl\:-mr-60 {
    margin-right: -15rem;
  }
  .\32xl\:-mr-64 {
    margin-right: -16rem;
  }
  .\32xl\:-mr-72 {
    margin-right: -18rem;
  }
  .\32xl\:-mr-80 {
    margin-right: -20rem;
  }
  .\32xl\:-mr-96 {
    margin-right: -24rem;
  }
  .\32xl\:-mr-px {
    margin-right: -1px;
  }
  .\32xl\:-mr-0\.5 {
    margin-right: -0.125rem;
  }
  .\32xl\:-mr-1\.5 {
    margin-right: -0.375rem;
  }
  .\32xl\:-mr-2\.5 {
    margin-right: -0.625rem;
  }
  .\32xl\:-mr-3\.5 {
    margin-right: -0.875rem;
  }
  .\32xl\:mb-0 {
    margin-bottom: 0;
  }
  .\32xl\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .\32xl\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .\32xl\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .\32xl\:mb-4 {
    margin-bottom: 1rem;
  }
  .\32xl\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .\32xl\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .\32xl\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .\32xl\:mb-8 {
    margin-bottom: 2rem;
  }
  .\32xl\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .\32xl\:mb-10 {
    margin-bottom: 2.5rem;
  }
  .\32xl\:mb-11 {
    margin-bottom: 2.75rem;
  }
  .\32xl\:mb-12 {
    margin-bottom: 3rem;
  }
  .\32xl\:mb-14 {
    margin-bottom: 3.5rem;
  }
  .\32xl\:mb-16 {
    margin-bottom: 4rem;
  }
  .\32xl\:mb-20 {
    margin-bottom: 5rem;
  }
  .\32xl\:mb-24 {
    margin-bottom: 6rem;
  }
  .\32xl\:mb-28 {
    margin-bottom: 7rem;
  }
  .\32xl\:mb-32 {
    margin-bottom: 8rem;
  }
  .\32xl\:mb-36 {
    margin-bottom: 9rem;
  }
  .\32xl\:mb-40 {
    margin-bottom: 10rem;
  }
  .\32xl\:mb-44 {
    margin-bottom: 11rem;
  }
  .\32xl\:mb-48 {
    margin-bottom: 12rem;
  }
  .\32xl\:mb-52 {
    margin-bottom: 13rem;
  }
  .\32xl\:mb-56 {
    margin-bottom: 14rem;
  }
  .\32xl\:mb-60 {
    margin-bottom: 15rem;
  }
  .\32xl\:mb-64 {
    margin-bottom: 16rem;
  }
  .\32xl\:mb-72 {
    margin-bottom: 18rem;
  }
  .\32xl\:mb-80 {
    margin-bottom: 20rem;
  }
  .\32xl\:mb-96 {
    margin-bottom: 24rem;
  }
  .\32xl\:mb-auto {
    margin-bottom: auto;
  }
  .\32xl\:mb-px {
    margin-bottom: 1px;
  }
  .\32xl\:mb-0\.5 {
    margin-bottom: 0.125rem;
  }
  .\32xl\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .\32xl\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .\32xl\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .\32xl\:-mb-0 {
    margin-bottom: 0;
  }
  .\32xl\:-mb-1 {
    margin-bottom: -0.25rem;
  }
  .\32xl\:-mb-2 {
    margin-bottom: -0.5rem;
  }
  .\32xl\:-mb-3 {
    margin-bottom: -0.75rem;
  }
  .\32xl\:-mb-4 {
    margin-bottom: -1rem;
  }
  .\32xl\:-mb-5 {
    margin-bottom: -1.25rem;
  }
  .\32xl\:-mb-6 {
    margin-bottom: -1.5rem;
  }
  .\32xl\:-mb-7 {
    margin-bottom: -1.75rem;
  }
  .\32xl\:-mb-8 {
    margin-bottom: -2rem;
  }
  .\32xl\:-mb-9 {
    margin-bottom: -2.25rem;
  }
  .\32xl\:-mb-10 {
    margin-bottom: -2.5rem;
  }
  .\32xl\:-mb-11 {
    margin-bottom: -2.75rem;
  }
  .\32xl\:-mb-12 {
    margin-bottom: -3rem;
  }
  .\32xl\:-mb-14 {
    margin-bottom: -3.5rem;
  }
  .\32xl\:-mb-16 {
    margin-bottom: -4rem;
  }
  .\32xl\:-mb-20 {
    margin-bottom: -5rem;
  }
  .\32xl\:-mb-24 {
    margin-bottom: -6rem;
  }
  .\32xl\:-mb-28 {
    margin-bottom: -7rem;
  }
  .\32xl\:-mb-32 {
    margin-bottom: -8rem;
  }
  .\32xl\:-mb-36 {
    margin-bottom: -9rem;
  }
  .\32xl\:-mb-40 {
    margin-bottom: -10rem;
  }
  .\32xl\:-mb-44 {
    margin-bottom: -11rem;
  }
  .\32xl\:-mb-48 {
    margin-bottom: -12rem;
  }
  .\32xl\:-mb-52 {
    margin-bottom: -13rem;
  }
  .\32xl\:-mb-56 {
    margin-bottom: -14rem;
  }
  .\32xl\:-mb-60 {
    margin-bottom: -15rem;
  }
  .\32xl\:-mb-64 {
    margin-bottom: -16rem;
  }
  .\32xl\:-mb-72 {
    margin-bottom: -18rem;
  }
  .\32xl\:-mb-80 {
    margin-bottom: -20rem;
  }
  .\32xl\:-mb-96 {
    margin-bottom: -24rem;
  }
  .\32xl\:-mb-px {
    margin-bottom: -1px;
  }
  .\32xl\:-mb-0\.5 {
    margin-bottom: -0.125rem;
  }
  .\32xl\:-mb-1\.5 {
    margin-bottom: -0.375rem;
  }
  .\32xl\:-mb-2\.5 {
    margin-bottom: -0.625rem;
  }
  .\32xl\:-mb-3\.5 {
    margin-bottom: -0.875rem;
  }
  .\32xl\:ml-0 {
    margin-left: 0;
  }
  .\32xl\:ml-1 {
    margin-left: 0.25rem;
  }
  .\32xl\:ml-2 {
    margin-left: 0.5rem;
  }
  .\32xl\:ml-3 {
    margin-left: 0.75rem;
  }
  .\32xl\:ml-4 {
    margin-left: 1rem;
  }
  .\32xl\:ml-5 {
    margin-left: 1.25rem;
  }
  .\32xl\:ml-6 {
    margin-left: 1.5rem;
  }
  .\32xl\:ml-7 {
    margin-left: 1.75rem;
  }
  .\32xl\:ml-8 {
    margin-left: 2rem;
  }
  .\32xl\:ml-9 {
    margin-left: 2.25rem;
  }
  .\32xl\:ml-10 {
    margin-left: 2.5rem;
  }
  .\32xl\:ml-11 {
    margin-left: 2.75rem;
  }
  .\32xl\:ml-12 {
    margin-left: 3rem;
  }
  .\32xl\:ml-14 {
    margin-left: 3.5rem;
  }
  .\32xl\:ml-16 {
    margin-left: 4rem;
  }
  .\32xl\:ml-20 {
    margin-left: 5rem;
  }
  .\32xl\:ml-24 {
    margin-left: 6rem;
  }
  .\32xl\:ml-28 {
    margin-left: 7rem;
  }
  .\32xl\:ml-32 {
    margin-left: 8rem;
  }
  .\32xl\:ml-36 {
    margin-left: 9rem;
  }
  .\32xl\:ml-40 {
    margin-left: 10rem;
  }
  .\32xl\:ml-44 {
    margin-left: 11rem;
  }
  .\32xl\:ml-48 {
    margin-left: 12rem;
  }
  .\32xl\:ml-52 {
    margin-left: 13rem;
  }
  .\32xl\:ml-56 {
    margin-left: 14rem;
  }
  .\32xl\:ml-60 {
    margin-left: 15rem;
  }
  .\32xl\:ml-64 {
    margin-left: 16rem;
  }
  .\32xl\:ml-72 {
    margin-left: 18rem;
  }
  .\32xl\:ml-80 {
    margin-left: 20rem;
  }
  .\32xl\:ml-96 {
    margin-left: 24rem;
  }
  .\32xl\:ml-auto {
    margin-left: auto;
  }
  .\32xl\:ml-px {
    margin-left: 1px;
  }
  .\32xl\:ml-0\.5 {
    margin-left: 0.125rem;
  }
  .\32xl\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .\32xl\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .\32xl\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .\32xl\:-ml-0 {
    margin-left: 0;
  }
  .\32xl\:-ml-1 {
    margin-left: -0.25rem;
  }
  .\32xl\:-ml-2 {
    margin-left: -0.5rem;
  }
  .\32xl\:-ml-3 {
    margin-left: -0.75rem;
  }
  .\32xl\:-ml-4 {
    margin-left: -1rem;
  }
  .\32xl\:-ml-5 {
    margin-left: -1.25rem;
  }
  .\32xl\:-ml-6 {
    margin-left: -1.5rem;
  }
  .\32xl\:-ml-7 {
    margin-left: -1.75rem;
  }
  .\32xl\:-ml-8 {
    margin-left: -2rem;
  }
  .\32xl\:-ml-9 {
    margin-left: -2.25rem;
  }
  .\32xl\:-ml-10 {
    margin-left: -2.5rem;
  }
  .\32xl\:-ml-11 {
    margin-left: -2.75rem;
  }
  .\32xl\:-ml-12 {
    margin-left: -3rem;
  }
  .\32xl\:-ml-14 {
    margin-left: -3.5rem;
  }
  .\32xl\:-ml-16 {
    margin-left: -4rem;
  }
  .\32xl\:-ml-20 {
    margin-left: -5rem;
  }
  .\32xl\:-ml-24 {
    margin-left: -6rem;
  }
  .\32xl\:-ml-28 {
    margin-left: -7rem;
  }
  .\32xl\:-ml-32 {
    margin-left: -8rem;
  }
  .\32xl\:-ml-36 {
    margin-left: -9rem;
  }
  .\32xl\:-ml-40 {
    margin-left: -10rem;
  }
  .\32xl\:-ml-44 {
    margin-left: -11rem;
  }
  .\32xl\:-ml-48 {
    margin-left: -12rem;
  }
  .\32xl\:-ml-52 {
    margin-left: -13rem;
  }
  .\32xl\:-ml-56 {
    margin-left: -14rem;
  }
  .\32xl\:-ml-60 {
    margin-left: -15rem;
  }
  .\32xl\:-ml-64 {
    margin-left: -16rem;
  }
  .\32xl\:-ml-72 {
    margin-left: -18rem;
  }
  .\32xl\:-ml-80 {
    margin-left: -20rem;
  }
  .\32xl\:-ml-96 {
    margin-left: -24rem;
  }
  .\32xl\:-ml-px {
    margin-left: -1px;
  }
  .\32xl\:-ml-0\.5 {
    margin-left: -0.125rem;
  }
  .\32xl\:-ml-1\.5 {
    margin-left: -0.375rem;
  }
  .\32xl\:-ml-2\.5 {
    margin-left: -0.625rem;
  }
  .\32xl\:-ml-3\.5 {
    margin-left: -0.875rem;
  }
  .\32xl\:box-border {
    box-sizing: border-box;
  }
  .\32xl\:box-content {
    box-sizing: content-box;
  }
  .\32xl\:block {
    display: block;
  }
  .\32xl\:inline-block {
    display: inline-block;
  }
  .\32xl\:inline {
    display: inline;
  }
  .\32xl\:flex {
    display: flex;
  }
  .\32xl\:inline-flex {
    display: inline-flex;
  }
  .\32xl\:table {
    display: table;
  }
  .\32xl\:inline-table {
    display: inline-table;
  }
  .\32xl\:table-caption {
    display: table-caption;
  }
  .\32xl\:table-cell {
    display: table-cell;
  }
  .\32xl\:table-column {
    display: table-column;
  }
  .\32xl\:table-column-group {
    display: table-column-group;
  }
  .\32xl\:table-footer-group {
    display: table-footer-group;
  }
  .\32xl\:table-header-group {
    display: table-header-group;
  }
  .\32xl\:table-row-group {
    display: table-row-group;
  }
  .\32xl\:table-row {
    display: table-row;
  }
  .\32xl\:flow-root {
    display: flow-root;
  }
  .\32xl\:grid {
    display: grid;
  }
  .\32xl\:inline-grid {
    display: inline-grid;
  }
  .\32xl\:contents {
    display: contents;
  }
  .\32xl\:list-item {
    display: list-item;
  }
  .\32xl\:hidden {
    display: none;
  }
  .\32xl\:h-0 {
    height: 0;
  }
  .\32xl\:h-1 {
    height: 0.25rem;
  }
  .\32xl\:h-2 {
    height: 0.5rem;
  }
  .\32xl\:h-3 {
    height: 0.75rem;
  }
  .\32xl\:h-4 {
    height: 1rem;
  }
  .\32xl\:h-5 {
    height: 1.25rem;
  }
  .\32xl\:h-6 {
    height: 1.5rem;
  }
  .\32xl\:h-7 {
    height: 1.75rem;
  }
  .\32xl\:h-8 {
    height: 2rem;
  }
  .\32xl\:h-9 {
    height: 2.25rem;
  }
  .\32xl\:h-10 {
    height: 2.5rem;
  }
  .\32xl\:h-11 {
    height: 2.75rem;
  }
  .\32xl\:h-12 {
    height: 3rem;
  }
  .\32xl\:h-14 {
    height: 3.5rem;
  }
  .\32xl\:h-16 {
    height: 4rem;
  }
  .\32xl\:h-20 {
    height: 5rem;
  }
  .\32xl\:h-24 {
    height: 6rem;
  }
  .\32xl\:h-28 {
    height: 7rem;
  }
  .\32xl\:h-32 {
    height: 8rem;
  }
  .\32xl\:h-36 {
    height: 9rem;
  }
  .\32xl\:h-40 {
    height: 10rem;
  }
  .\32xl\:h-44 {
    height: 11rem;
  }
  .\32xl\:h-48 {
    height: 12rem;
  }
  .\32xl\:h-52 {
    height: 13rem;
  }
  .\32xl\:h-56 {
    height: 14rem;
  }
  .\32xl\:h-60 {
    height: 15rem;
  }
  .\32xl\:h-64 {
    height: 16rem;
  }
  .\32xl\:h-72 {
    height: 18rem;
  }
  .\32xl\:h-80 {
    height: 20rem;
  }
  .\32xl\:h-96 {
    height: 24rem;
  }
  .\32xl\:h-auto {
    height: auto;
  }
  .\32xl\:h-px {
    height: 1px;
  }
  .\32xl\:h-0\.5 {
    height: 0.125rem;
  }
  .\32xl\:h-1\.5 {
    height: 0.375rem;
  }
  .\32xl\:h-2\.5 {
    height: 0.625rem;
  }
  .\32xl\:h-3\.5 {
    height: 0.875rem;
  }
  .\32xl\:h-1\/2 {
    height: 50%;
  }
  .\32xl\:h-1\/3 {
    height: 33.333333%;
  }
  .\32xl\:h-2\/3 {
    height: 66.666667%;
  }
  .\32xl\:h-1\/4 {
    height: 25%;
  }
  .\32xl\:h-2\/4 {
    height: 50%;
  }
  .\32xl\:h-3\/4 {
    height: 75%;
  }
  .\32xl\:h-1\/5 {
    height: 20%;
  }
  .\32xl\:h-2\/5 {
    height: 40%;
  }
  .\32xl\:h-3\/5 {
    height: 60%;
  }
  .\32xl\:h-4\/5 {
    height: 80%;
  }
  .\32xl\:h-1\/6 {
    height: 16.666667%;
  }
  .\32xl\:h-2\/6 {
    height: 33.333333%;
  }
  .\32xl\:h-3\/6 {
    height: 50%;
  }
  .\32xl\:h-4\/6 {
    height: 66.666667%;
  }
  .\32xl\:h-5\/6 {
    height: 83.333333%;
  }
  .\32xl\:h-full {
    height: 100%;
  }
  .\32xl\:h-screen {
    height: 100vh;
  }
  .\32xl\:max-h-0 {
    max-height: 0;
  }
  .\32xl\:max-h-1 {
    max-height: 0.25rem;
  }
  .\32xl\:max-h-2 {
    max-height: 0.5rem;
  }
  .\32xl\:max-h-3 {
    max-height: 0.75rem;
  }
  .\32xl\:max-h-4 {
    max-height: 1rem;
  }
  .\32xl\:max-h-5 {
    max-height: 1.25rem;
  }
  .\32xl\:max-h-6 {
    max-height: 1.5rem;
  }
  .\32xl\:max-h-7 {
    max-height: 1.75rem;
  }
  .\32xl\:max-h-8 {
    max-height: 2rem;
  }
  .\32xl\:max-h-9 {
    max-height: 2.25rem;
  }
  .\32xl\:max-h-10 {
    max-height: 2.5rem;
  }
  .\32xl\:max-h-11 {
    max-height: 2.75rem;
  }
  .\32xl\:max-h-12 {
    max-height: 3rem;
  }
  .\32xl\:max-h-14 {
    max-height: 3.5rem;
  }
  .\32xl\:max-h-16 {
    max-height: 4rem;
  }
  .\32xl\:max-h-20 {
    max-height: 5rem;
  }
  .\32xl\:max-h-24 {
    max-height: 6rem;
  }
  .\32xl\:max-h-28 {
    max-height: 7rem;
  }
  .\32xl\:max-h-32 {
    max-height: 8rem;
  }
  .\32xl\:max-h-36 {
    max-height: 9rem;
  }
  .\32xl\:max-h-40 {
    max-height: 10rem;
  }
  .\32xl\:max-h-44 {
    max-height: 11rem;
  }
  .\32xl\:max-h-48 {
    max-height: 12rem;
  }
  .\32xl\:max-h-52 {
    max-height: 13rem;
  }
  .\32xl\:max-h-56 {
    max-height: 14rem;
  }
  .\32xl\:max-h-60 {
    max-height: 15rem;
  }
  .\32xl\:max-h-64 {
    max-height: 16rem;
  }
  .\32xl\:max-h-72 {
    max-height: 18rem;
  }
  .\32xl\:max-h-80 {
    max-height: 20rem;
  }
  .\32xl\:max-h-96 {
    max-height: 24rem;
  }
  .\32xl\:max-h-px {
    max-height: 1px;
  }
  .\32xl\:max-h-0\.5 {
    max-height: 0.125rem;
  }
  .\32xl\:max-h-1\.5 {
    max-height: 0.375rem;
  }
  .\32xl\:max-h-2\.5 {
    max-height: 0.625rem;
  }
  .\32xl\:max-h-3\.5 {
    max-height: 0.875rem;
  }
  .\32xl\:max-h-full {
    max-height: 100%;
  }
  .\32xl\:max-h-screen {
    max-height: 100vh;
  }
  .\32xl\:min-h-0 {
    min-height: 0;
  }
  .\32xl\:min-h-full {
    min-height: 100%;
  }
  .\32xl\:min-h-screen {
    min-height: 100vh;
  }
  .\32xl\:w-0 {
    width: 0;
  }
  .\32xl\:w-1 {
    width: 0.25rem;
  }
  .\32xl\:w-2 {
    width: 0.5rem;
  }
  .\32xl\:w-3 {
    width: 0.75rem;
  }
  .\32xl\:w-4 {
    width: 1rem;
  }
  .\32xl\:w-5 {
    width: 1.25rem;
  }
  .\32xl\:w-6 {
    width: 1.5rem;
  }
  .\32xl\:w-7 {
    width: 1.75rem;
  }
  .\32xl\:w-8 {
    width: 2rem;
  }
  .\32xl\:w-9 {
    width: 2.25rem;
  }
  .\32xl\:w-10 {
    width: 2.5rem;
  }
  .\32xl\:w-11 {
    width: 2.75rem;
  }
  .\32xl\:w-12 {
    width: 3rem;
  }
  .\32xl\:w-14 {
    width: 3.5rem;
  }
  .\32xl\:w-16 {
    width: 4rem;
  }
  .\32xl\:w-20 {
    width: 5rem;
  }
  .\32xl\:w-24 {
    width: 6rem;
  }
  .\32xl\:w-28 {
    width: 7rem;
  }
  .\32xl\:w-32 {
    width: 8rem;
  }
  .\32xl\:w-36 {
    width: 9rem;
  }
  .\32xl\:w-40 {
    width: 10rem;
  }
  .\32xl\:w-44 {
    width: 11rem;
  }
  .\32xl\:w-48 {
    width: 12rem;
  }
  .\32xl\:w-52 {
    width: 13rem;
  }
  .\32xl\:w-56 {
    width: 14rem;
  }
  .\32xl\:w-60 {
    width: 15rem;
  }
  .\32xl\:w-64 {
    width: 16rem;
  }
  .\32xl\:w-72 {
    width: 18rem;
  }
  .\32xl\:w-80 {
    width: 20rem;
  }
  .\32xl\:w-96 {
    width: 24rem;
  }
  .\32xl\:w-auto {
    width: auto;
  }
  .\32xl\:w-px {
    width: 1px;
  }
  .\32xl\:w-0\.5 {
    width: 0.125rem;
  }
  .\32xl\:w-1\.5 {
    width: 0.375rem;
  }
  .\32xl\:w-2\.5 {
    width: 0.625rem;
  }
  .\32xl\:w-3\.5 {
    width: 0.875rem;
  }
  .\32xl\:w-1\/2 {
    width: 50%;
  }
  .\32xl\:w-1\/3 {
    width: 33.333333%;
  }
  .\32xl\:w-2\/3 {
    width: 66.666667%;
  }
  .\32xl\:w-1\/4 {
    width: 25%;
  }
  .\32xl\:w-2\/4 {
    width: 50%;
  }
  .\32xl\:w-3\/4 {
    width: 75%;
  }
  .\32xl\:w-1\/5 {
    width: 20%;
  }
  .\32xl\:w-2\/5 {
    width: 40%;
  }
  .\32xl\:w-3\/5 {
    width: 60%;
  }
  .\32xl\:w-4\/5 {
    width: 80%;
  }
  .\32xl\:w-1\/6 {
    width: 16.666667%;
  }
  .\32xl\:w-2\/6 {
    width: 33.333333%;
  }
  .\32xl\:w-3\/6 {
    width: 50%;
  }
  .\32xl\:w-4\/6 {
    width: 66.666667%;
  }
  .\32xl\:w-5\/6 {
    width: 83.333333%;
  }
  .\32xl\:w-1\/12 {
    width: 8.333333%;
  }
  .\32xl\:w-2\/12 {
    width: 16.666667%;
  }
  .\32xl\:w-3\/12 {
    width: 25%;
  }
  .\32xl\:w-4\/12 {
    width: 33.333333%;
  }
  .\32xl\:w-5\/12 {
    width: 41.666667%;
  }
  .\32xl\:w-6\/12 {
    width: 50%;
  }
  .\32xl\:w-7\/12 {
    width: 58.333333%;
  }
  .\32xl\:w-8\/12 {
    width: 66.666667%;
  }
  .\32xl\:w-9\/12 {
    width: 75%;
  }
  .\32xl\:w-10\/12 {
    width: 83.333333%;
  }
  .\32xl\:w-11\/12 {
    width: 91.666667%;
  }
  .\32xl\:w-full {
    width: 100%;
  }
  .\32xl\:w-screen {
    width: 100vw;
  }
  .\32xl\:w-min {
    width: min-content;
  }
  .\32xl\:w-max {
    width: max-content;
  }
  .\32xl\:min-w-0 {
    min-width: 0;
  }
  .\32xl\:min-w-full {
    min-width: 100%;
  }
  .\32xl\:min-w-min {
    min-width: min-content;
  }
  .\32xl\:min-w-max {
    min-width: max-content;
  }
  .\32xl\:max-w-0 {
    max-width: 0;
  }
  .\32xl\:max-w-none {
    max-width: none;
  }
  .\32xl\:max-w-xs {
    max-width: 20rem;
  }
  .\32xl\:max-w-sm {
    max-width: 24rem;
  }
  .\32xl\:max-w-md {
    max-width: 28rem;
  }
  .\32xl\:max-w-lg {
    max-width: 32rem;
  }
  .\32xl\:max-w-xl {
    max-width: 36rem;
  }
  .\32xl\:max-w-2xl {
    max-width: 42rem;
  }
  .\32xl\:max-w-3xl {
    max-width: 48rem;
  }
  .\32xl\:max-w-4xl {
    max-width: 56rem;
  }
  .\32xl\:max-w-5xl {
    max-width: 64rem;
  }
  .\32xl\:max-w-6xl {
    max-width: 72rem;
  }
  .\32xl\:max-w-7xl {
    max-width: 80rem;
  }
  .\32xl\:max-w-full {
    max-width: 100%;
  }
  .\32xl\:max-w-min {
    max-width: min-content;
  }
  .\32xl\:max-w-max {
    max-width: max-content;
  }
  .\32xl\:max-w-prose {
    max-width: 65ch;
  }
  .\32xl\:max-w-screen-sm {
    max-width: 640px;
  }
  .\32xl\:max-w-screen-md {
    max-width: 768px;
  }
  .\32xl\:max-w-screen-lg {
    max-width: 1024px;
  }
  .\32xl\:max-w-screen-xl {
    max-width: 1280px;
  }
  .\32xl\:max-w-screen-2xl {
    max-width: 1536px;
  }
  .\32xl\:flex-1 {
    flex: 1 1 0%;
  }
  .\32xl\:flex-auto {
    flex: 1 1 auto;
  }
  .\32xl\:flex-initial {
    flex: 0 1 auto;
  }
  .\32xl\:flex-none {
    flex: none;
  }
  .\32xl\:flex-shrink-0 {
    flex-shrink: 0;
  }
  .\32xl\:flex-shrink {
    flex-shrink: 1;
  }
  .\32xl\:flex-grow-0 {
    flex-grow: 0;
  }
  .\32xl\:flex-grow {
    flex-grow: 1;
  }
  .\32xl\:table-auto {
    table-layout: auto;
  }
  .\32xl\:table-fixed {
    table-layout: fixed;
  }
  .\32xl\:border-collapse {
    border-collapse: collapse;
  }
  .\32xl\:border-separate {
    border-collapse: separate;
  }
  .\32xl\:origin-center {
    transform-origin: center;
  }
  .\32xl\:origin-top {
    transform-origin: top;
  }
  .\32xl\:origin-top-right {
    transform-origin: top right;
  }
  .\32xl\:origin-right {
    transform-origin: right;
  }
  .\32xl\:origin-bottom-right {
    transform-origin: bottom right;
  }
  .\32xl\:origin-bottom {
    transform-origin: bottom;
  }
  .\32xl\:origin-bottom-left {
    transform-origin: bottom left;
  }
  .\32xl\:origin-left {
    transform-origin: left;
  }
  .\32xl\:origin-top-left {
    transform-origin: top left;
  }
  .\32xl\:transform {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translateX(var(--tw-translate-x))
      translateY(var(--tw-translate-y)) rotate(var(--tw-rotate))
      skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x))
      scaleY(var(--tw-scale-y));
  }
  .\32xl\:transform-gpu {
    --tw-translate-x: 0;
    --tw-translate-y: 0;
    --tw-rotate: 0;
    --tw-skew-x: 0;
    --tw-skew-y: 0;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0)
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  .\32xl\:transform-none {
    transform: none;
  }
  .\32xl\:translate-x-0 {
    --tw-translate-x: 0px;
  }
  .\32xl\:translate-x-1 {
    --tw-translate-x: 0.25rem;
  }
  .\32xl\:translate-x-2 {
    --tw-translate-x: 0.5rem;
  }
  .\32xl\:translate-x-3 {
    --tw-translate-x: 0.75rem;
  }
  .\32xl\:translate-x-4 {
    --tw-translate-x: 1rem;
  }
  .\32xl\:translate-x-5 {
    --tw-translate-x: 1.25rem;
  }
  .\32xl\:translate-x-6 {
    --tw-translate-x: 1.5rem;
  }
  .\32xl\:translate-x-7 {
    --tw-translate-x: 1.75rem;
  }
  .\32xl\:translate-x-8 {
    --tw-translate-x: 2rem;
  }
  .\32xl\:translate-x-9 {
    --tw-translate-x: 2.25rem;
  }
  .\32xl\:translate-x-10 {
    --tw-translate-x: 2.5rem;
  }
  .\32xl\:translate-x-11 {
    --tw-translate-x: 2.75rem;
  }
  .\32xl\:translate-x-12 {
    --tw-translate-x: 3rem;
  }
  .\32xl\:translate-x-14 {
    --tw-translate-x: 3.5rem;
  }
  .\32xl\:translate-x-16 {
    --tw-translate-x: 4rem;
  }
  .\32xl\:translate-x-20 {
    --tw-translate-x: 5rem;
  }
  .\32xl\:translate-x-24 {
    --tw-translate-x: 6rem;
  }
  .\32xl\:translate-x-28 {
    --tw-translate-x: 7rem;
  }
  .\32xl\:translate-x-32 {
    --tw-translate-x: 8rem;
  }
  .\32xl\:translate-x-36 {
    --tw-translate-x: 9rem;
  }
  .\32xl\:translate-x-40 {
    --tw-translate-x: 10rem;
  }
  .\32xl\:translate-x-44 {
    --tw-translate-x: 11rem;
  }
  .\32xl\:translate-x-48 {
    --tw-translate-x: 12rem;
  }
  .\32xl\:translate-x-52 {
    --tw-translate-x: 13rem;
  }
  .\32xl\:translate-x-56 {
    --tw-translate-x: 14rem;
  }
  .\32xl\:translate-x-60 {
    --tw-translate-x: 15rem;
  }
  .\32xl\:translate-x-64 {
    --tw-translate-x: 16rem;
  }
  .\32xl\:translate-x-72 {
    --tw-translate-x: 18rem;
  }
  .\32xl\:translate-x-80 {
    --tw-translate-x: 20rem;
  }
  .\32xl\:translate-x-96 {
    --tw-translate-x: 24rem;
  }
  .\32xl\:translate-x-px {
    --tw-translate-x: 1px;
  }
  .\32xl\:translate-x-0\.5 {
    --tw-translate-x: 0.125rem;
  }
  .\32xl\:translate-x-1\.5 {
    --tw-translate-x: 0.375rem;
  }
  .\32xl\:translate-x-2\.5 {
    --tw-translate-x: 0.625rem;
  }
  .\32xl\:translate-x-3\.5 {
    --tw-translate-x: 0.875rem;
  }
  .\32xl\:-translate-x-0 {
    --tw-translate-x: 0px;
  }
  .\32xl\:-translate-x-1 {
    --tw-translate-x: -0.25rem;
  }
  .\32xl\:-translate-x-2 {
    --tw-translate-x: -0.5rem;
  }
  .\32xl\:-translate-x-3 {
    --tw-translate-x: -0.75rem;
  }
  .\32xl\:-translate-x-4 {
    --tw-translate-x: -1rem;
  }
  .\32xl\:-translate-x-5 {
    --tw-translate-x: -1.25rem;
  }
  .\32xl\:-translate-x-6 {
    --tw-translate-x: -1.5rem;
  }
  .\32xl\:-translate-x-7 {
    --tw-translate-x: -1.75rem;
  }
  .\32xl\:-translate-x-8 {
    --tw-translate-x: -2rem;
  }
  .\32xl\:-translate-x-9 {
    --tw-translate-x: -2.25rem;
  }
  .\32xl\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
  }
  .\32xl\:-translate-x-11 {
    --tw-translate-x: -2.75rem;
  }
  .\32xl\:-translate-x-12 {
    --tw-translate-x: -3rem;
  }
  .\32xl\:-translate-x-14 {
    --tw-translate-x: -3.5rem;
  }
  .\32xl\:-translate-x-16 {
    --tw-translate-x: -4rem;
  }
  .\32xl\:-translate-x-20 {
    --tw-translate-x: -5rem;
  }
  .\32xl\:-translate-x-24 {
    --tw-translate-x: -6rem;
  }
  .\32xl\:-translate-x-28 {
    --tw-translate-x: -7rem;
  }
  .\32xl\:-translate-x-32 {
    --tw-translate-x: -8rem;
  }
  .\32xl\:-translate-x-36 {
    --tw-translate-x: -9rem;
  }
  .\32xl\:-translate-x-40 {
    --tw-translate-x: -10rem;
  }
  .\32xl\:-translate-x-44 {
    --tw-translate-x: -11rem;
  }
  .\32xl\:-translate-x-48 {
    --tw-translate-x: -12rem;
  }
  .\32xl\:-translate-x-52 {
    --tw-translate-x: -13rem;
  }
  .\32xl\:-translate-x-56 {
    --tw-translate-x: -14rem;
  }
  .\32xl\:-translate-x-60 {
    --tw-translate-x: -15rem;
  }
  .\32xl\:-translate-x-64 {
    --tw-translate-x: -16rem;
  }
  .\32xl\:-translate-x-72 {
    --tw-translate-x: -18rem;
  }
  .\32xl\:-translate-x-80 {
    --tw-translate-x: -20rem;
  }
  .\32xl\:-translate-x-96 {
    --tw-translate-x: -24rem;
  }
  .\32xl\:-translate-x-px {
    --tw-translate-x: -1px;
  }
  .\32xl\:-translate-x-0\.5 {
    --tw-translate-x: -0.125rem;
  }
  .\32xl\:-translate-x-1\.5 {
    --tw-translate-x: -0.375rem;
  }
  .\32xl\:-translate-x-2\.5 {
    --tw-translate-x: -0.625rem;
  }
  .\32xl\:-translate-x-3\.5 {
    --tw-translate-x: -0.875rem;
  }
  .\32xl\:translate-x-1\/2 {
    --tw-translate-x: 50%;
  }
  .\32xl\:translate-x-1\/3 {
    --tw-translate-x: 33.333333%;
  }
  .\32xl\:translate-x-2\/3 {
    --tw-translate-x: 66.666667%;
  }
  .\32xl\:translate-x-1\/4 {
    --tw-translate-x: 25%;
  }
  .\32xl\:translate-x-2\/4 {
    --tw-translate-x: 50%;
  }
  .\32xl\:translate-x-3\/4 {
    --tw-translate-x: 75%;
  }
  .\32xl\:translate-x-full {
    --tw-translate-x: 100%;
  }
  .\32xl\:-translate-x-1\/2 {
    --tw-translate-x: -50%;
  }
  .\32xl\:-translate-x-1\/3 {
    --tw-translate-x: -33.333333%;
  }
  .\32xl\:-translate-x-2\/3 {
    --tw-translate-x: -66.666667%;
  }
  .\32xl\:-translate-x-1\/4 {
    --tw-translate-x: -25%;
  }
  .\32xl\:-translate-x-2\/4 {
    --tw-translate-x: -50%;
  }
  .\32xl\:-translate-x-3\/4 {
    --tw-translate-x: -75%;
  }
  .\32xl\:-translate-x-full {
    --tw-translate-x: -100%;
  }
  .\32xl\:translate-y-0 {
    --tw-translate-y: 0px;
  }
  .\32xl\:translate-y-1 {
    --tw-translate-y: 0.25rem;
  }
  .\32xl\:translate-y-2 {
    --tw-translate-y: 0.5rem;
  }
  .\32xl\:translate-y-3 {
    --tw-translate-y: 0.75rem;
  }
  .\32xl\:translate-y-4 {
    --tw-translate-y: 1rem;
  }
  .\32xl\:translate-y-5 {
    --tw-translate-y: 1.25rem;
  }
  .\32xl\:translate-y-6 {
    --tw-translate-y: 1.5rem;
  }
  .\32xl\:translate-y-7 {
    --tw-translate-y: 1.75rem;
  }
  .\32xl\:translate-y-8 {
    --tw-translate-y: 2rem;
  }
  .\32xl\:translate-y-9 {
    --tw-translate-y: 2.25rem;
  }
  .\32xl\:translate-y-10 {
    --tw-translate-y: 2.5rem;
  }
  .\32xl\:translate-y-11 {
    --tw-translate-y: 2.75rem;
  }
  .\32xl\:translate-y-12 {
    --tw-translate-y: 3rem;
  }
  .\32xl\:translate-y-14 {
    --tw-translate-y: 3.5rem;
  }
  .\32xl\:translate-y-16 {
    --tw-translate-y: 4rem;
  }
  .\32xl\:translate-y-20 {
    --tw-translate-y: 5rem;
  }
  .\32xl\:translate-y-24 {
    --tw-translate-y: 6rem;
  }
  .\32xl\:translate-y-28 {
    --tw-translate-y: 7rem;
  }
  .\32xl\:translate-y-32 {
    --tw-translate-y: 8rem;
  }
  .\32xl\:translate-y-36 {
    --tw-translate-y: 9rem;
  }
  .\32xl\:translate-y-40 {
    --tw-translate-y: 10rem;
  }
  .\32xl\:translate-y-44 {
    --tw-translate-y: 11rem;
  }
  .\32xl\:translate-y-48 {
    --tw-translate-y: 12rem;
  }
  .\32xl\:translate-y-52 {
    --tw-translate-y: 13rem;
  }
  .\32xl\:translate-y-56 {
    --tw-translate-y: 14rem;
  }
  .\32xl\:translate-y-60 {
    --tw-translate-y: 15rem;
  }
  .\32xl\:translate-y-64 {
    --tw-translate-y: 16rem;
  }
  .\32xl\:translate-y-72 {
    --tw-translate-y: 18rem;
  }
  .\32xl\:translate-y-80 {
    --tw-translate-y: 20rem;
  }
  .\32xl\:translate-y-96 {
    --tw-translate-y: 24rem;
  }
  .\32xl\:translate-y-px {
    --tw-translate-y: 1px;
  }
  .\32xl\:translate-y-0\.5 {
    --tw-translate-y: 0.125rem;
  }
  .\32xl\:translate-y-1\.5 {
    --tw-translate-y: 0.375rem;
  }
  .\32xl\:translate-y-2\.5 {
    --tw-translate-y: 0.625rem;
  }
  .\32xl\:translate-y-3\.5 {
    --tw-translate-y: 0.875rem;
  }
  .\32xl\:-translate-y-0 {
    --tw-translate-y: 0px;
  }
  .\32xl\:-translate-y-1 {
    --tw-translate-y: -0.25rem;
  }
  .\32xl\:-translate-y-2 {
    --tw-translate-y: -0.5rem;
  }
  .\32xl\:-translate-y-3 {
    --tw-translate-y: -0.75rem;
  }
  .\32xl\:-translate-y-4 {
    --tw-translate-y: -1rem;
  }
  .\32xl\:-translate-y-5 {
    --tw-translate-y: -1.25rem;
  }
  .\32xl\:-translate-y-6 {
    --tw-translate-y: -1.5rem;
  }
  .\32xl\:-translate-y-7 {
    --tw-translate-y: -1.75rem;
  }
  .\32xl\:-translate-y-8 {
    --tw-translate-y: -2rem;
  }
  .\32xl\:-translate-y-9 {
    --tw-translate-y: -2.25rem;
  }
  .\32xl\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
  }
  .\32xl\:-translate-y-11 {
    --tw-translate-y: -2.75rem;
  }
  .\32xl\:-translate-y-12 {
    --tw-translate-y: -3rem;
  }
  .\32xl\:-translate-y-14 {
    --tw-translate-y: -3.5rem;
  }
  .\32xl\:-translate-y-16 {
    --tw-translate-y: -4rem;
  }
  .\32xl\:-translate-y-20 {
    --tw-translate-y: -5rem;
  }
  .\32xl\:-translate-y-24 {
    --tw-translate-y: -6rem;
  }
  .\32xl\:-translate-y-28 {
    --tw-translate-y: -7rem;
  }
  .\32xl\:-translate-y-32 {
    --tw-translate-y: -8rem;
  }
  .\32xl\:-translate-y-36 {
    --tw-translate-y: -9rem;
  }
  .\32xl\:-translate-y-40 {
    --tw-translate-y: -10rem;
  }
  .\32xl\:-translate-y-44 {
    --tw-translate-y: -11rem;
  }
  .\32xl\:-translate-y-48 {
    --tw-translate-y: -12rem;
  }
  .\32xl\:-translate-y-52 {
    --tw-translate-y: -13rem;
  }
  .\32xl\:-translate-y-56 {
    --tw-translate-y: -14rem;
  }
  .\32xl\:-translate-y-60 {
    --tw-translate-y: -15rem;
  }
  .\32xl\:-translate-y-64 {
    --tw-translate-y: -16rem;
  }
  .\32xl\:-translate-y-72 {
    --tw-translate-y: -18rem;
  }
  .\32xl\:-translate-y-80 {
    --tw-translate-y: -20rem;
  }
  .\32xl\:-translate-y-96 {
    --tw-translate-y: -24rem;
  }
  .\32xl\:-translate-y-px {
    --tw-translate-y: -1px;
  }
  .\32xl\:-translate-y-0\.5 {
    --tw-translate-y: -0.125rem;
  }
  .\32xl\:-translate-y-1\.5 {
    --tw-translate-y: -0.375rem;
  }
  .\32xl\:-translate-y-2\.5 {
    --tw-translate-y: -0.625rem;
  }
  .\32xl\:-translate-y-3\.5 {
    --tw-translate-y: -0.875rem;
  }
  .\32xl\:translate-y-1\/2 {
    --tw-translate-y: 50%;
  }
  .\32xl\:translate-y-1\/3 {
    --tw-translate-y: 33.333333%;
  }
  .\32xl\:translate-y-2\/3 {
    --tw-translate-y: 66.666667%;
  }
  .\32xl\:translate-y-1\/4 {
    --tw-translate-y: 25%;
  }
  .\32xl\:translate-y-2\/4 {
    --tw-translate-y: 50%;
  }
  .\32xl\:translate-y-3\/4 {
    --tw-translate-y: 75%;
  }
  .\32xl\:translate-y-full {
    --tw-translate-y: 100%;
  }
  .\32xl\:-translate-y-1\/2 {
    --tw-translate-y: -50%;
  }
  .\32xl\:-translate-y-1\/3 {
    --tw-translate-y: -33.333333%;
  }
  .\32xl\:-translate-y-2\/3 {
    --tw-translate-y: -66.666667%;
  }
  .\32xl\:-translate-y-1\/4 {
    --tw-translate-y: -25%;
  }
  .\32xl\:-translate-y-2\/4 {
    --tw-translate-y: -50%;
  }
  .\32xl\:-translate-y-3\/4 {
    --tw-translate-y: -75%;
  }
  .\32xl\:-translate-y-full {
    --tw-translate-y: -100%;
  }
  .\32xl\:hover\:translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .\32xl\:hover\:translate-x-1:hover {
    --tw-translate-x: 0.25rem;
  }
  .\32xl\:hover\:translate-x-2:hover {
    --tw-translate-x: 0.5rem;
  }
  .\32xl\:hover\:translate-x-3:hover {
    --tw-translate-x: 0.75rem;
  }
  .\32xl\:hover\:translate-x-4:hover {
    --tw-translate-x: 1rem;
  }
  .\32xl\:hover\:translate-x-5:hover {
    --tw-translate-x: 1.25rem;
  }
  .\32xl\:hover\:translate-x-6:hover {
    --tw-translate-x: 1.5rem;
  }
  .\32xl\:hover\:translate-x-7:hover {
    --tw-translate-x: 1.75rem;
  }
  .\32xl\:hover\:translate-x-8:hover {
    --tw-translate-x: 2rem;
  }
  .\32xl\:hover\:translate-x-9:hover {
    --tw-translate-x: 2.25rem;
  }
  .\32xl\:hover\:translate-x-10:hover {
    --tw-translate-x: 2.5rem;
  }
  .\32xl\:hover\:translate-x-11:hover {
    --tw-translate-x: 2.75rem;
  }
  .\32xl\:hover\:translate-x-12:hover {
    --tw-translate-x: 3rem;
  }
  .\32xl\:hover\:translate-x-14:hover {
    --tw-translate-x: 3.5rem;
  }
  .\32xl\:hover\:translate-x-16:hover {
    --tw-translate-x: 4rem;
  }
  .\32xl\:hover\:translate-x-20:hover {
    --tw-translate-x: 5rem;
  }
  .\32xl\:hover\:translate-x-24:hover {
    --tw-translate-x: 6rem;
  }
  .\32xl\:hover\:translate-x-28:hover {
    --tw-translate-x: 7rem;
  }
  .\32xl\:hover\:translate-x-32:hover {
    --tw-translate-x: 8rem;
  }
  .\32xl\:hover\:translate-x-36:hover {
    --tw-translate-x: 9rem;
  }
  .\32xl\:hover\:translate-x-40:hover {
    --tw-translate-x: 10rem;
  }
  .\32xl\:hover\:translate-x-44:hover {
    --tw-translate-x: 11rem;
  }
  .\32xl\:hover\:translate-x-48:hover {
    --tw-translate-x: 12rem;
  }
  .\32xl\:hover\:translate-x-52:hover {
    --tw-translate-x: 13rem;
  }
  .\32xl\:hover\:translate-x-56:hover {
    --tw-translate-x: 14rem;
  }
  .\32xl\:hover\:translate-x-60:hover {
    --tw-translate-x: 15rem;
  }
  .\32xl\:hover\:translate-x-64:hover {
    --tw-translate-x: 16rem;
  }
  .\32xl\:hover\:translate-x-72:hover {
    --tw-translate-x: 18rem;
  }
  .\32xl\:hover\:translate-x-80:hover {
    --tw-translate-x: 20rem;
  }
  .\32xl\:hover\:translate-x-96:hover {
    --tw-translate-x: 24rem;
  }
  .\32xl\:hover\:translate-x-px:hover {
    --tw-translate-x: 1px;
  }
  .\32xl\:hover\:translate-x-0\.5:hover {
    --tw-translate-x: 0.125rem;
  }
  .\32xl\:hover\:translate-x-1\.5:hover {
    --tw-translate-x: 0.375rem;
  }
  .\32xl\:hover\:translate-x-2\.5:hover {
    --tw-translate-x: 0.625rem;
  }
  .\32xl\:hover\:translate-x-3\.5:hover {
    --tw-translate-x: 0.875rem;
  }
  .\32xl\:hover\:-translate-x-0:hover {
    --tw-translate-x: 0px;
  }
  .\32xl\:hover\:-translate-x-1:hover {
    --tw-translate-x: -0.25rem;
  }
  .\32xl\:hover\:-translate-x-2:hover {
    --tw-translate-x: -0.5rem;
  }
  .\32xl\:hover\:-translate-x-3:hover {
    --tw-translate-x: -0.75rem;
  }
  .\32xl\:hover\:-translate-x-4:hover {
    --tw-translate-x: -1rem;
  }
  .\32xl\:hover\:-translate-x-5:hover {
    --tw-translate-x: -1.25rem;
  }
  .\32xl\:hover\:-translate-x-6:hover {
    --tw-translate-x: -1.5rem;
  }
  .\32xl\:hover\:-translate-x-7:hover {
    --tw-translate-x: -1.75rem;
  }
  .\32xl\:hover\:-translate-x-8:hover {
    --tw-translate-x: -2rem;
  }
  .\32xl\:hover\:-translate-x-9:hover {
    --tw-translate-x: -2.25rem;
  }
  .\32xl\:hover\:-translate-x-10:hover {
    --tw-translate-x: -2.5rem;
  }
  .\32xl\:hover\:-translate-x-11:hover {
    --tw-translate-x: -2.75rem;
  }
  .\32xl\:hover\:-translate-x-12:hover {
    --tw-translate-x: -3rem;
  }
  .\32xl\:hover\:-translate-x-14:hover {
    --tw-translate-x: -3.5rem;
  }
  .\32xl\:hover\:-translate-x-16:hover {
    --tw-translate-x: -4rem;
  }
  .\32xl\:hover\:-translate-x-20:hover {
    --tw-translate-x: -5rem;
  }
  .\32xl\:hover\:-translate-x-24:hover {
    --tw-translate-x: -6rem;
  }
  .\32xl\:hover\:-translate-x-28:hover {
    --tw-translate-x: -7rem;
  }
  .\32xl\:hover\:-translate-x-32:hover {
    --tw-translate-x: -8rem;
  }
  .\32xl\:hover\:-translate-x-36:hover {
    --tw-translate-x: -9rem;
  }
  .\32xl\:hover\:-translate-x-40:hover {
    --tw-translate-x: -10rem;
  }
  .\32xl\:hover\:-translate-x-44:hover {
    --tw-translate-x: -11rem;
  }
  .\32xl\:hover\:-translate-x-48:hover {
    --tw-translate-x: -12rem;
  }
  .\32xl\:hover\:-translate-x-52:hover {
    --tw-translate-x: -13rem;
  }
  .\32xl\:hover\:-translate-x-56:hover {
    --tw-translate-x: -14rem;
  }
  .\32xl\:hover\:-translate-x-60:hover {
    --tw-translate-x: -15rem;
  }
  .\32xl\:hover\:-translate-x-64:hover {
    --tw-translate-x: -16rem;
  }
  .\32xl\:hover\:-translate-x-72:hover {
    --tw-translate-x: -18rem;
  }
  .\32xl\:hover\:-translate-x-80:hover {
    --tw-translate-x: -20rem;
  }
  .\32xl\:hover\:-translate-x-96:hover {
    --tw-translate-x: -24rem;
  }
  .\32xl\:hover\:-translate-x-px:hover {
    --tw-translate-x: -1px;
  }
  .\32xl\:hover\:-translate-x-0\.5:hover {
    --tw-translate-x: -0.125rem;
  }
  .\32xl\:hover\:-translate-x-1\.5:hover {
    --tw-translate-x: -0.375rem;
  }
  .\32xl\:hover\:-translate-x-2\.5:hover {
    --tw-translate-x: -0.625rem;
  }
  .\32xl\:hover\:-translate-x-3\.5:hover {
    --tw-translate-x: -0.875rem;
  }
  .\32xl\:hover\:translate-x-1\/2:hover {
    --tw-translate-x: 50%;
  }
  .\32xl\:hover\:translate-x-1\/3:hover {
    --tw-translate-x: 33.333333%;
  }
  .\32xl\:hover\:translate-x-2\/3:hover {
    --tw-translate-x: 66.666667%;
  }
  .\32xl\:hover\:translate-x-1\/4:hover {
    --tw-translate-x: 25%;
  }
  .\32xl\:hover\:translate-x-2\/4:hover {
    --tw-translate-x: 50%;
  }
  .\32xl\:hover\:translate-x-3\/4:hover {
    --tw-translate-x: 75%;
  }
  .\32xl\:hover\:translate-x-full:hover {
    --tw-translate-x: 100%;
  }
  .\32xl\:hover\:-translate-x-1\/2:hover {
    --tw-translate-x: -50%;
  }
  .\32xl\:hover\:-translate-x-1\/3:hover {
    --tw-translate-x: -33.333333%;
  }
  .\32xl\:hover\:-translate-x-2\/3:hover {
    --tw-translate-x: -66.666667%;
  }
  .\32xl\:hover\:-translate-x-1\/4:hover {
    --tw-translate-x: -25%;
  }
  .\32xl\:hover\:-translate-x-2\/4:hover {
    --tw-translate-x: -50%;
  }
  .\32xl\:hover\:-translate-x-3\/4:hover {
    --tw-translate-x: -75%;
  }
  .\32xl\:hover\:-translate-x-full:hover {
    --tw-translate-x: -100%;
  }
  .\32xl\:hover\:translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .\32xl\:hover\:translate-y-1:hover {
    --tw-translate-y: 0.25rem;
  }
  .\32xl\:hover\:translate-y-2:hover {
    --tw-translate-y: 0.5rem;
  }
  .\32xl\:hover\:translate-y-3:hover {
    --tw-translate-y: 0.75rem;
  }
  .\32xl\:hover\:translate-y-4:hover {
    --tw-translate-y: 1rem;
  }
  .\32xl\:hover\:translate-y-5:hover {
    --tw-translate-y: 1.25rem;
  }
  .\32xl\:hover\:translate-y-6:hover {
    --tw-translate-y: 1.5rem;
  }
  .\32xl\:hover\:translate-y-7:hover {
    --tw-translate-y: 1.75rem;
  }
  .\32xl\:hover\:translate-y-8:hover {
    --tw-translate-y: 2rem;
  }
  .\32xl\:hover\:translate-y-9:hover {
    --tw-translate-y: 2.25rem;
  }
  .\32xl\:hover\:translate-y-10:hover {
    --tw-translate-y: 2.5rem;
  }
  .\32xl\:hover\:translate-y-11:hover {
    --tw-translate-y: 2.75rem;
  }
  .\32xl\:hover\:translate-y-12:hover {
    --tw-translate-y: 3rem;
  }
  .\32xl\:hover\:translate-y-14:hover {
    --tw-translate-y: 3.5rem;
  }
  .\32xl\:hover\:translate-y-16:hover {
    --tw-translate-y: 4rem;
  }
  .\32xl\:hover\:translate-y-20:hover {
    --tw-translate-y: 5rem;
  }
  .\32xl\:hover\:translate-y-24:hover {
    --tw-translate-y: 6rem;
  }
  .\32xl\:hover\:translate-y-28:hover {
    --tw-translate-y: 7rem;
  }
  .\32xl\:hover\:translate-y-32:hover {
    --tw-translate-y: 8rem;
  }
  .\32xl\:hover\:translate-y-36:hover {
    --tw-translate-y: 9rem;
  }
  .\32xl\:hover\:translate-y-40:hover {
    --tw-translate-y: 10rem;
  }
  .\32xl\:hover\:translate-y-44:hover {
    --tw-translate-y: 11rem;
  }
  .\32xl\:hover\:translate-y-48:hover {
    --tw-translate-y: 12rem;
  }
  .\32xl\:hover\:translate-y-52:hover {
    --tw-translate-y: 13rem;
  }
  .\32xl\:hover\:translate-y-56:hover {
    --tw-translate-y: 14rem;
  }
  .\32xl\:hover\:translate-y-60:hover {
    --tw-translate-y: 15rem;
  }
  .\32xl\:hover\:translate-y-64:hover {
    --tw-translate-y: 16rem;
  }
  .\32xl\:hover\:translate-y-72:hover {
    --tw-translate-y: 18rem;
  }
  .\32xl\:hover\:translate-y-80:hover {
    --tw-translate-y: 20rem;
  }
  .\32xl\:hover\:translate-y-96:hover {
    --tw-translate-y: 24rem;
  }
  .\32xl\:hover\:translate-y-px:hover {
    --tw-translate-y: 1px;
  }
  .\32xl\:hover\:translate-y-0\.5:hover {
    --tw-translate-y: 0.125rem;
  }
  .\32xl\:hover\:translate-y-1\.5:hover {
    --tw-translate-y: 0.375rem;
  }
  .\32xl\:hover\:translate-y-2\.5:hover {
    --tw-translate-y: 0.625rem;
  }
  .\32xl\:hover\:translate-y-3\.5:hover {
    --tw-translate-y: 0.875rem;
  }
  .\32xl\:hover\:-translate-y-0:hover {
    --tw-translate-y: 0px;
  }
  .\32xl\:hover\:-translate-y-1:hover {
    --tw-translate-y: -0.25rem;
  }
  .\32xl\:hover\:-translate-y-2:hover {
    --tw-translate-y: -0.5rem;
  }
  .\32xl\:hover\:-translate-y-3:hover {
    --tw-translate-y: -0.75rem;
  }
  .\32xl\:hover\:-translate-y-4:hover {
    --tw-translate-y: -1rem;
  }
  .\32xl\:hover\:-translate-y-5:hover {
    --tw-translate-y: -1.25rem;
  }
  .\32xl\:hover\:-translate-y-6:hover {
    --tw-translate-y: -1.5rem;
  }
  .\32xl\:hover\:-translate-y-7:hover {
    --tw-translate-y: -1.75rem;
  }
  .\32xl\:hover\:-translate-y-8:hover {
    --tw-translate-y: -2rem;
  }
  .\32xl\:hover\:-translate-y-9:hover {
    --tw-translate-y: -2.25rem;
  }
  .\32xl\:hover\:-translate-y-10:hover {
    --tw-translate-y: -2.5rem;
  }
  .\32xl\:hover\:-translate-y-11:hover {
    --tw-translate-y: -2.75rem;
  }
  .\32xl\:hover\:-translate-y-12:hover {
    --tw-translate-y: -3rem;
  }
  .\32xl\:hover\:-translate-y-14:hover {
    --tw-translate-y: -3.5rem;
  }
  .\32xl\:hover\:-translate-y-16:hover {
    --tw-translate-y: -4rem;
  }
  .\32xl\:hover\:-translate-y-20:hover {
    --tw-translate-y: -5rem;
  }
  .\32xl\:hover\:-translate-y-24:hover {
    --tw-translate-y: -6rem;
  }
  .\32xl\:hover\:-translate-y-28:hover {
    --tw-translate-y: -7rem;
  }
  .\32xl\:hover\:-translate-y-32:hover {
    --tw-translate-y: -8rem;
  }
  .\32xl\:hover\:-translate-y-36:hover {
    --tw-translate-y: -9rem;
  }
  .\32xl\:hover\:-translate-y-40:hover {
    --tw-translate-y: -10rem;
  }
  .\32xl\:hover\:-translate-y-44:hover {
    --tw-translate-y: -11rem;
  }
  .\32xl\:hover\:-translate-y-48:hover {
    --tw-translate-y: -12rem;
  }
  .\32xl\:hover\:-translate-y-52:hover {
    --tw-translate-y: -13rem;
  }
  .\32xl\:hover\:-translate-y-56:hover {
    --tw-translate-y: -14rem;
  }
  .\32xl\:hover\:-translate-y-60:hover {
    --tw-translate-y: -15rem;
  }
  .\32xl\:hover\:-translate-y-64:hover {
    --tw-translate-y: -16rem;
  }
  .\32xl\:hover\:-translate-y-72:hover {
    --tw-translate-y: -18rem;
  }
  .\32xl\:hover\:-translate-y-80:hover {
    --tw-translate-y: -20rem;
  }
  .\32xl\:hover\:-translate-y-96:hover {
    --tw-translate-y: -24rem;
  }
  .\32xl\:hover\:-translate-y-px:hover {
    --tw-translate-y: -1px;
  }
  .\32xl\:hover\:-translate-y-0\.5:hover {
    --tw-translate-y: -0.125rem;
  }
  .\32xl\:hover\:-translate-y-1\.5:hover {
    --tw-translate-y: -0.375rem;
  }
  .\32xl\:hover\:-translate-y-2\.5:hover {
    --tw-translate-y: -0.625rem;
  }
  .\32xl\:hover\:-translate-y-3\.5:hover {
    --tw-translate-y: -0.875rem;
  }
  .\32xl\:hover\:translate-y-1\/2:hover {
    --tw-translate-y: 50%;
  }
  .\32xl\:hover\:translate-y-1\/3:hover {
    --tw-translate-y: 33.333333%;
  }
  .\32xl\:hover\:translate-y-2\/3:hover {
    --tw-translate-y: 66.666667%;
  }
  .\32xl\:hover\:translate-y-1\/4:hover {
    --tw-translate-y: 25%;
  }
  .\32xl\:hover\:translate-y-2\/4:hover {
    --tw-translate-y: 50%;
  }
  .\32xl\:hover\:translate-y-3\/4:hover {
    --tw-translate-y: 75%;
  }
  .\32xl\:hover\:translate-y-full:hover {
    --tw-translate-y: 100%;
  }
  .\32xl\:hover\:-translate-y-1\/2:hover {
    --tw-translate-y: -50%;
  }
  .\32xl\:hover\:-translate-y-1\/3:hover {
    --tw-translate-y: -33.333333%;
  }
  .\32xl\:hover\:-translate-y-2\/3:hover {
    --tw-translate-y: -66.666667%;
  }
  .\32xl\:hover\:-translate-y-1\/4:hover {
    --tw-translate-y: -25%;
  }
  .\32xl\:hover\:-translate-y-2\/4:hover {
    --tw-translate-y: -50%;
  }
  .\32xl\:hover\:-translate-y-3\/4:hover {
    --tw-translate-y: -75%;
  }
  .\32xl\:hover\:-translate-y-full:hover {
    --tw-translate-y: -100%;
  }
  .\32xl\:focus\:translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .\32xl\:focus\:translate-x-1:focus {
    --tw-translate-x: 0.25rem;
  }
  .\32xl\:focus\:translate-x-2:focus {
    --tw-translate-x: 0.5rem;
  }
  .\32xl\:focus\:translate-x-3:focus {
    --tw-translate-x: 0.75rem;
  }
  .\32xl\:focus\:translate-x-4:focus {
    --tw-translate-x: 1rem;
  }
  .\32xl\:focus\:translate-x-5:focus {
    --tw-translate-x: 1.25rem;
  }
  .\32xl\:focus\:translate-x-6:focus {
    --tw-translate-x: 1.5rem;
  }
  .\32xl\:focus\:translate-x-7:focus {
    --tw-translate-x: 1.75rem;
  }
  .\32xl\:focus\:translate-x-8:focus {
    --tw-translate-x: 2rem;
  }
  .\32xl\:focus\:translate-x-9:focus {
    --tw-translate-x: 2.25rem;
  }
  .\32xl\:focus\:translate-x-10:focus {
    --tw-translate-x: 2.5rem;
  }
  .\32xl\:focus\:translate-x-11:focus {
    --tw-translate-x: 2.75rem;
  }
  .\32xl\:focus\:translate-x-12:focus {
    --tw-translate-x: 3rem;
  }
  .\32xl\:focus\:translate-x-14:focus {
    --tw-translate-x: 3.5rem;
  }
  .\32xl\:focus\:translate-x-16:focus {
    --tw-translate-x: 4rem;
  }
  .\32xl\:focus\:translate-x-20:focus {
    --tw-translate-x: 5rem;
  }
  .\32xl\:focus\:translate-x-24:focus {
    --tw-translate-x: 6rem;
  }
  .\32xl\:focus\:translate-x-28:focus {
    --tw-translate-x: 7rem;
  }
  .\32xl\:focus\:translate-x-32:focus {
    --tw-translate-x: 8rem;
  }
  .\32xl\:focus\:translate-x-36:focus {
    --tw-translate-x: 9rem;
  }
  .\32xl\:focus\:translate-x-40:focus {
    --tw-translate-x: 10rem;
  }
  .\32xl\:focus\:translate-x-44:focus {
    --tw-translate-x: 11rem;
  }
  .\32xl\:focus\:translate-x-48:focus {
    --tw-translate-x: 12rem;
  }
  .\32xl\:focus\:translate-x-52:focus {
    --tw-translate-x: 13rem;
  }
  .\32xl\:focus\:translate-x-56:focus {
    --tw-translate-x: 14rem;
  }
  .\32xl\:focus\:translate-x-60:focus {
    --tw-translate-x: 15rem;
  }
  .\32xl\:focus\:translate-x-64:focus {
    --tw-translate-x: 16rem;
  }
  .\32xl\:focus\:translate-x-72:focus {
    --tw-translate-x: 18rem;
  }
  .\32xl\:focus\:translate-x-80:focus {
    --tw-translate-x: 20rem;
  }
  .\32xl\:focus\:translate-x-96:focus {
    --tw-translate-x: 24rem;
  }
  .\32xl\:focus\:translate-x-px:focus {
    --tw-translate-x: 1px;
  }
  .\32xl\:focus\:translate-x-0\.5:focus {
    --tw-translate-x: 0.125rem;
  }
  .\32xl\:focus\:translate-x-1\.5:focus {
    --tw-translate-x: 0.375rem;
  }
  .\32xl\:focus\:translate-x-2\.5:focus {
    --tw-translate-x: 0.625rem;
  }
  .\32xl\:focus\:translate-x-3\.5:focus {
    --tw-translate-x: 0.875rem;
  }
  .\32xl\:focus\:-translate-x-0:focus {
    --tw-translate-x: 0px;
  }
  .\32xl\:focus\:-translate-x-1:focus {
    --tw-translate-x: -0.25rem;
  }
  .\32xl\:focus\:-translate-x-2:focus {
    --tw-translate-x: -0.5rem;
  }
  .\32xl\:focus\:-translate-x-3:focus {
    --tw-translate-x: -0.75rem;
  }
  .\32xl\:focus\:-translate-x-4:focus {
    --tw-translate-x: -1rem;
  }
  .\32xl\:focus\:-translate-x-5:focus {
    --tw-translate-x: -1.25rem;
  }
  .\32xl\:focus\:-translate-x-6:focus {
    --tw-translate-x: -1.5rem;
  }
  .\32xl\:focus\:-translate-x-7:focus {
    --tw-translate-x: -1.75rem;
  }
  .\32xl\:focus\:-translate-x-8:focus {
    --tw-translate-x: -2rem;
  }
  .\32xl\:focus\:-translate-x-9:focus {
    --tw-translate-x: -2.25rem;
  }
  .\32xl\:focus\:-translate-x-10:focus {
    --tw-translate-x: -2.5rem;
  }
  .\32xl\:focus\:-translate-x-11:focus {
    --tw-translate-x: -2.75rem;
  }
  .\32xl\:focus\:-translate-x-12:focus {
    --tw-translate-x: -3rem;
  }
  .\32xl\:focus\:-translate-x-14:focus {
    --tw-translate-x: -3.5rem;
  }
  .\32xl\:focus\:-translate-x-16:focus {
    --tw-translate-x: -4rem;
  }
  .\32xl\:focus\:-translate-x-20:focus {
    --tw-translate-x: -5rem;
  }
  .\32xl\:focus\:-translate-x-24:focus {
    --tw-translate-x: -6rem;
  }
  .\32xl\:focus\:-translate-x-28:focus {
    --tw-translate-x: -7rem;
  }
  .\32xl\:focus\:-translate-x-32:focus {
    --tw-translate-x: -8rem;
  }
  .\32xl\:focus\:-translate-x-36:focus {
    --tw-translate-x: -9rem;
  }
  .\32xl\:focus\:-translate-x-40:focus {
    --tw-translate-x: -10rem;
  }
  .\32xl\:focus\:-translate-x-44:focus {
    --tw-translate-x: -11rem;
  }
  .\32xl\:focus\:-translate-x-48:focus {
    --tw-translate-x: -12rem;
  }
  .\32xl\:focus\:-translate-x-52:focus {
    --tw-translate-x: -13rem;
  }
  .\32xl\:focus\:-translate-x-56:focus {
    --tw-translate-x: -14rem;
  }
  .\32xl\:focus\:-translate-x-60:focus {
    --tw-translate-x: -15rem;
  }
  .\32xl\:focus\:-translate-x-64:focus {
    --tw-translate-x: -16rem;
  }
  .\32xl\:focus\:-translate-x-72:focus {
    --tw-translate-x: -18rem;
  }
  .\32xl\:focus\:-translate-x-80:focus {
    --tw-translate-x: -20rem;
  }
  .\32xl\:focus\:-translate-x-96:focus {
    --tw-translate-x: -24rem;
  }
  .\32xl\:focus\:-translate-x-px:focus {
    --tw-translate-x: -1px;
  }
  .\32xl\:focus\:-translate-x-0\.5:focus {
    --tw-translate-x: -0.125rem;
  }
  .\32xl\:focus\:-translate-x-1\.5:focus {
    --tw-translate-x: -0.375rem;
  }
  .\32xl\:focus\:-translate-x-2\.5:focus {
    --tw-translate-x: -0.625rem;
  }
  .\32xl\:focus\:-translate-x-3\.5:focus {
    --tw-translate-x: -0.875rem;
  }
  .\32xl\:focus\:translate-x-1\/2:focus {
    --tw-translate-x: 50%;
  }
  .\32xl\:focus\:translate-x-1\/3:focus {
    --tw-translate-x: 33.333333%;
  }
  .\32xl\:focus\:translate-x-2\/3:focus {
    --tw-translate-x: 66.666667%;
  }
  .\32xl\:focus\:translate-x-1\/4:focus {
    --tw-translate-x: 25%;
  }
  .\32xl\:focus\:translate-x-2\/4:focus {
    --tw-translate-x: 50%;
  }
  .\32xl\:focus\:translate-x-3\/4:focus {
    --tw-translate-x: 75%;
  }
  .\32xl\:focus\:translate-x-full:focus {
    --tw-translate-x: 100%;
  }
  .\32xl\:focus\:-translate-x-1\/2:focus {
    --tw-translate-x: -50%;
  }
  .\32xl\:focus\:-translate-x-1\/3:focus {
    --tw-translate-x: -33.333333%;
  }
  .\32xl\:focus\:-translate-x-2\/3:focus {
    --tw-translate-x: -66.666667%;
  }
  .\32xl\:focus\:-translate-x-1\/4:focus {
    --tw-translate-x: -25%;
  }
  .\32xl\:focus\:-translate-x-2\/4:focus {
    --tw-translate-x: -50%;
  }
  .\32xl\:focus\:-translate-x-3\/4:focus {
    --tw-translate-x: -75%;
  }
  .\32xl\:focus\:-translate-x-full:focus {
    --tw-translate-x: -100%;
  }
  .\32xl\:focus\:translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .\32xl\:focus\:translate-y-1:focus {
    --tw-translate-y: 0.25rem;
  }
  .\32xl\:focus\:translate-y-2:focus {
    --tw-translate-y: 0.5rem;
  }
  .\32xl\:focus\:translate-y-3:focus {
    --tw-translate-y: 0.75rem;
  }
  .\32xl\:focus\:translate-y-4:focus {
    --tw-translate-y: 1rem;
  }
  .\32xl\:focus\:translate-y-5:focus {
    --tw-translate-y: 1.25rem;
  }
  .\32xl\:focus\:translate-y-6:focus {
    --tw-translate-y: 1.5rem;
  }
  .\32xl\:focus\:translate-y-7:focus {
    --tw-translate-y: 1.75rem;
  }
  .\32xl\:focus\:translate-y-8:focus {
    --tw-translate-y: 2rem;
  }
  .\32xl\:focus\:translate-y-9:focus {
    --tw-translate-y: 2.25rem;
  }
  .\32xl\:focus\:translate-y-10:focus {
    --tw-translate-y: 2.5rem;
  }
  .\32xl\:focus\:translate-y-11:focus {
    --tw-translate-y: 2.75rem;
  }
  .\32xl\:focus\:translate-y-12:focus {
    --tw-translate-y: 3rem;
  }
  .\32xl\:focus\:translate-y-14:focus {
    --tw-translate-y: 3.5rem;
  }
  .\32xl\:focus\:translate-y-16:focus {
    --tw-translate-y: 4rem;
  }
  .\32xl\:focus\:translate-y-20:focus {
    --tw-translate-y: 5rem;
  }
  .\32xl\:focus\:translate-y-24:focus {
    --tw-translate-y: 6rem;
  }
  .\32xl\:focus\:translate-y-28:focus {
    --tw-translate-y: 7rem;
  }
  .\32xl\:focus\:translate-y-32:focus {
    --tw-translate-y: 8rem;
  }
  .\32xl\:focus\:translate-y-36:focus {
    --tw-translate-y: 9rem;
  }
  .\32xl\:focus\:translate-y-40:focus {
    --tw-translate-y: 10rem;
  }
  .\32xl\:focus\:translate-y-44:focus {
    --tw-translate-y: 11rem;
  }
  .\32xl\:focus\:translate-y-48:focus {
    --tw-translate-y: 12rem;
  }
  .\32xl\:focus\:translate-y-52:focus {
    --tw-translate-y: 13rem;
  }
  .\32xl\:focus\:translate-y-56:focus {
    --tw-translate-y: 14rem;
  }
  .\32xl\:focus\:translate-y-60:focus {
    --tw-translate-y: 15rem;
  }
  .\32xl\:focus\:translate-y-64:focus {
    --tw-translate-y: 16rem;
  }
  .\32xl\:focus\:translate-y-72:focus {
    --tw-translate-y: 18rem;
  }
  .\32xl\:focus\:translate-y-80:focus {
    --tw-translate-y: 20rem;
  }
  .\32xl\:focus\:translate-y-96:focus {
    --tw-translate-y: 24rem;
  }
  .\32xl\:focus\:translate-y-px:focus {
    --tw-translate-y: 1px;
  }
  .\32xl\:focus\:translate-y-0\.5:focus {
    --tw-translate-y: 0.125rem;
  }
  .\32xl\:focus\:translate-y-1\.5:focus {
    --tw-translate-y: 0.375rem;
  }
  .\32xl\:focus\:translate-y-2\.5:focus {
    --tw-translate-y: 0.625rem;
  }
  .\32xl\:focus\:translate-y-3\.5:focus {
    --tw-translate-y: 0.875rem;
  }
  .\32xl\:focus\:-translate-y-0:focus {
    --tw-translate-y: 0px;
  }
  .\32xl\:focus\:-translate-y-1:focus {
    --tw-translate-y: -0.25rem;
  }
  .\32xl\:focus\:-translate-y-2:focus {
    --tw-translate-y: -0.5rem;
  }
  .\32xl\:focus\:-translate-y-3:focus {
    --tw-translate-y: -0.75rem;
  }
  .\32xl\:focus\:-translate-y-4:focus {
    --tw-translate-y: -1rem;
  }
  .\32xl\:focus\:-translate-y-5:focus {
    --tw-translate-y: -1.25rem;
  }
  .\32xl\:focus\:-translate-y-6:focus {
    --tw-translate-y: -1.5rem;
  }
  .\32xl\:focus\:-translate-y-7:focus {
    --tw-translate-y: -1.75rem;
  }
  .\32xl\:focus\:-translate-y-8:focus {
    --tw-translate-y: -2rem;
  }
  .\32xl\:focus\:-translate-y-9:focus {
    --tw-translate-y: -2.25rem;
  }
  .\32xl\:focus\:-translate-y-10:focus {
    --tw-translate-y: -2.5rem;
  }
  .\32xl\:focus\:-translate-y-11:focus {
    --tw-translate-y: -2.75rem;
  }
  .\32xl\:focus\:-translate-y-12:focus {
    --tw-translate-y: -3rem;
  }
  .\32xl\:focus\:-translate-y-14:focus {
    --tw-translate-y: -3.5rem;
  }
  .\32xl\:focus\:-translate-y-16:focus {
    --tw-translate-y: -4rem;
  }
  .\32xl\:focus\:-translate-y-20:focus {
    --tw-translate-y: -5rem;
  }
  .\32xl\:focus\:-translate-y-24:focus {
    --tw-translate-y: -6rem;
  }
  .\32xl\:focus\:-translate-y-28:focus {
    --tw-translate-y: -7rem;
  }
  .\32xl\:focus\:-translate-y-32:focus {
    --tw-translate-y: -8rem;
  }
  .\32xl\:focus\:-translate-y-36:focus {
    --tw-translate-y: -9rem;
  }
  .\32xl\:focus\:-translate-y-40:focus {
    --tw-translate-y: -10rem;
  }
  .\32xl\:focus\:-translate-y-44:focus {
    --tw-translate-y: -11rem;
  }
  .\32xl\:focus\:-translate-y-48:focus {
    --tw-translate-y: -12rem;
  }
  .\32xl\:focus\:-translate-y-52:focus {
    --tw-translate-y: -13rem;
  }
  .\32xl\:focus\:-translate-y-56:focus {
    --tw-translate-y: -14rem;
  }
  .\32xl\:focus\:-translate-y-60:focus {
    --tw-translate-y: -15rem;
  }
  .\32xl\:focus\:-translate-y-64:focus {
    --tw-translate-y: -16rem;
  }
  .\32xl\:focus\:-translate-y-72:focus {
    --tw-translate-y: -18rem;
  }
  .\32xl\:focus\:-translate-y-80:focus {
    --tw-translate-y: -20rem;
  }
  .\32xl\:focus\:-translate-y-96:focus {
    --tw-translate-y: -24rem;
  }
  .\32xl\:focus\:-translate-y-px:focus {
    --tw-translate-y: -1px;
  }
  .\32xl\:focus\:-translate-y-0\.5:focus {
    --tw-translate-y: -0.125rem;
  }
  .\32xl\:focus\:-translate-y-1\.5:focus {
    --tw-translate-y: -0.375rem;
  }
  .\32xl\:focus\:-translate-y-2\.5:focus {
    --tw-translate-y: -0.625rem;
  }
  .\32xl\:focus\:-translate-y-3\.5:focus {
    --tw-translate-y: -0.875rem;
  }
  .\32xl\:focus\:translate-y-1\/2:focus {
    --tw-translate-y: 50%;
  }
  .\32xl\:focus\:translate-y-1\/3:focus {
    --tw-translate-y: 33.333333%;
  }
  .\32xl\:focus\:translate-y-2\/3:focus {
    --tw-translate-y: 66.666667%;
  }
  .\32xl\:focus\:translate-y-1\/4:focus {
    --tw-translate-y: 25%;
  }
  .\32xl\:focus\:translate-y-2\/4:focus {
    --tw-translate-y: 50%;
  }
  .\32xl\:focus\:translate-y-3\/4:focus {
    --tw-translate-y: 75%;
  }
  .\32xl\:focus\:translate-y-full:focus {
    --tw-translate-y: 100%;
  }
  .\32xl\:focus\:-translate-y-1\/2:focus {
    --tw-translate-y: -50%;
  }
  .\32xl\:focus\:-translate-y-1\/3:focus {
    --tw-translate-y: -33.333333%;
  }
  .\32xl\:focus\:-translate-y-2\/3:focus {
    --tw-translate-y: -66.666667%;
  }
  .\32xl\:focus\:-translate-y-1\/4:focus {
    --tw-translate-y: -25%;
  }
  .\32xl\:focus\:-translate-y-2\/4:focus {
    --tw-translate-y: -50%;
  }
  .\32xl\:focus\:-translate-y-3\/4:focus {
    --tw-translate-y: -75%;
  }
  .\32xl\:focus\:-translate-y-full:focus {
    --tw-translate-y: -100%;
  }
  .\32xl\:rotate-0 {
    --tw-rotate: 0deg;
  }
  .\32xl\:rotate-1 {
    --tw-rotate: 1deg;
  }
  .\32xl\:rotate-2 {
    --tw-rotate: 2deg;
  }
  .\32xl\:rotate-3 {
    --tw-rotate: 3deg;
  }
  .\32xl\:rotate-6 {
    --tw-rotate: 6deg;
  }
  .\32xl\:rotate-12 {
    --tw-rotate: 12deg;
  }
  .\32xl\:rotate-45 {
    --tw-rotate: 45deg;
  }
  .\32xl\:rotate-90 {
    --tw-rotate: 90deg;
  }
  .\32xl\:rotate-180 {
    --tw-rotate: 180deg;
  }
  .\32xl\:-rotate-180 {
    --tw-rotate: -180deg;
  }
  .\32xl\:-rotate-90 {
    --tw-rotate: -90deg;
  }
  .\32xl\:-rotate-45 {
    --tw-rotate: -45deg;
  }
  .\32xl\:-rotate-12 {
    --tw-rotate: -12deg;
  }
  .\32xl\:-rotate-6 {
    --tw-rotate: -6deg;
  }
  .\32xl\:-rotate-3 {
    --tw-rotate: -3deg;
  }
  .\32xl\:-rotate-2 {
    --tw-rotate: -2deg;
  }
  .\32xl\:-rotate-1 {
    --tw-rotate: -1deg;
  }
  .\32xl\:hover\:rotate-0:hover {
    --tw-rotate: 0deg;
  }
  .\32xl\:hover\:rotate-1:hover {
    --tw-rotate: 1deg;
  }
  .\32xl\:hover\:rotate-2:hover {
    --tw-rotate: 2deg;
  }
  .\32xl\:hover\:rotate-3:hover {
    --tw-rotate: 3deg;
  }
  .\32xl\:hover\:rotate-6:hover {
    --tw-rotate: 6deg;
  }
  .\32xl\:hover\:rotate-12:hover {
    --tw-rotate: 12deg;
  }
  .\32xl\:hover\:rotate-45:hover {
    --tw-rotate: 45deg;
  }
  .\32xl\:hover\:rotate-90:hover {
    --tw-rotate: 90deg;
  }
  .\32xl\:hover\:rotate-180:hover {
    --tw-rotate: 180deg;
  }
  .\32xl\:hover\:-rotate-180:hover {
    --tw-rotate: -180deg;
  }
  .\32xl\:hover\:-rotate-90:hover {
    --tw-rotate: -90deg;
  }
  .\32xl\:hover\:-rotate-45:hover {
    --tw-rotate: -45deg;
  }
  .\32xl\:hover\:-rotate-12:hover {
    --tw-rotate: -12deg;
  }
  .\32xl\:hover\:-rotate-6:hover {
    --tw-rotate: -6deg;
  }
  .\32xl\:hover\:-rotate-3:hover {
    --tw-rotate: -3deg;
  }
  .\32xl\:hover\:-rotate-2:hover {
    --tw-rotate: -2deg;
  }
  .\32xl\:hover\:-rotate-1:hover {
    --tw-rotate: -1deg;
  }
  .\32xl\:focus\:rotate-0:focus {
    --tw-rotate: 0deg;
  }
  .\32xl\:focus\:rotate-1:focus {
    --tw-rotate: 1deg;
  }
  .\32xl\:focus\:rotate-2:focus {
    --tw-rotate: 2deg;
  }
  .\32xl\:focus\:rotate-3:focus {
    --tw-rotate: 3deg;
  }
  .\32xl\:focus\:rotate-6:focus {
    --tw-rotate: 6deg;
  }
  .\32xl\:focus\:rotate-12:focus {
    --tw-rotate: 12deg;
  }
  .\32xl\:focus\:rotate-45:focus {
    --tw-rotate: 45deg;
  }
  .\32xl\:focus\:rotate-90:focus {
    --tw-rotate: 90deg;
  }
  .\32xl\:focus\:rotate-180:focus {
    --tw-rotate: 180deg;
  }
  .\32xl\:focus\:-rotate-180:focus {
    --tw-rotate: -180deg;
  }
  .\32xl\:focus\:-rotate-90:focus {
    --tw-rotate: -90deg;
  }
  .\32xl\:focus\:-rotate-45:focus {
    --tw-rotate: -45deg;
  }
  .\32xl\:focus\:-rotate-12:focus {
    --tw-rotate: -12deg;
  }
  .\32xl\:focus\:-rotate-6:focus {
    --tw-rotate: -6deg;
  }
  .\32xl\:focus\:-rotate-3:focus {
    --tw-rotate: -3deg;
  }
  .\32xl\:focus\:-rotate-2:focus {
    --tw-rotate: -2deg;
  }
  .\32xl\:focus\:-rotate-1:focus {
    --tw-rotate: -1deg;
  }
  .\32xl\:skew-x-0 {
    --tw-skew-x: 0deg;
  }
  .\32xl\:skew-x-1 {
    --tw-skew-x: 1deg;
  }
  .\32xl\:skew-x-2 {
    --tw-skew-x: 2deg;
  }
  .\32xl\:skew-x-3 {
    --tw-skew-x: 3deg;
  }
  .\32xl\:skew-x-6 {
    --tw-skew-x: 6deg;
  }
  .\32xl\:skew-x-12 {
    --tw-skew-x: 12deg;
  }
  .\32xl\:-skew-x-12 {
    --tw-skew-x: -12deg;
  }
  .\32xl\:-skew-x-6 {
    --tw-skew-x: -6deg;
  }
  .\32xl\:-skew-x-3 {
    --tw-skew-x: -3deg;
  }
  .\32xl\:-skew-x-2 {
    --tw-skew-x: -2deg;
  }
  .\32xl\:-skew-x-1 {
    --tw-skew-x: -1deg;
  }
  .\32xl\:skew-y-0 {
    --tw-skew-y: 0deg;
  }
  .\32xl\:skew-y-1 {
    --tw-skew-y: 1deg;
  }
  .\32xl\:skew-y-2 {
    --tw-skew-y: 2deg;
  }
  .\32xl\:skew-y-3 {
    --tw-skew-y: 3deg;
  }
  .\32xl\:skew-y-6 {
    --tw-skew-y: 6deg;
  }
  .\32xl\:skew-y-12 {
    --tw-skew-y: 12deg;
  }
  .\32xl\:-skew-y-12 {
    --tw-skew-y: -12deg;
  }
  .\32xl\:-skew-y-6 {
    --tw-skew-y: -6deg;
  }
  .\32xl\:-skew-y-3 {
    --tw-skew-y: -3deg;
  }
  .\32xl\:-skew-y-2 {
    --tw-skew-y: -2deg;
  }
  .\32xl\:-skew-y-1 {
    --tw-skew-y: -1deg;
  }
  .\32xl\:hover\:skew-x-0:hover {
    --tw-skew-x: 0deg;
  }
  .\32xl\:hover\:skew-x-1:hover {
    --tw-skew-x: 1deg;
  }
  .\32xl\:hover\:skew-x-2:hover {
    --tw-skew-x: 2deg;
  }
  .\32xl\:hover\:skew-x-3:hover {
    --tw-skew-x: 3deg;
  }
  .\32xl\:hover\:skew-x-6:hover {
    --tw-skew-x: 6deg;
  }
  .\32xl\:hover\:skew-x-12:hover {
    --tw-skew-x: 12deg;
  }
  .\32xl\:hover\:-skew-x-12:hover {
    --tw-skew-x: -12deg;
  }
  .\32xl\:hover\:-skew-x-6:hover {
    --tw-skew-x: -6deg;
  }
  .\32xl\:hover\:-skew-x-3:hover {
    --tw-skew-x: -3deg;
  }
  .\32xl\:hover\:-skew-x-2:hover {
    --tw-skew-x: -2deg;
  }
  .\32xl\:hover\:-skew-x-1:hover {
    --tw-skew-x: -1deg;
  }
  .\32xl\:hover\:skew-y-0:hover {
    --tw-skew-y: 0deg;
  }
  .\32xl\:hover\:skew-y-1:hover {
    --tw-skew-y: 1deg;
  }
  .\32xl\:hover\:skew-y-2:hover {
    --tw-skew-y: 2deg;
  }
  .\32xl\:hover\:skew-y-3:hover {
    --tw-skew-y: 3deg;
  }
  .\32xl\:hover\:skew-y-6:hover {
    --tw-skew-y: 6deg;
  }
  .\32xl\:hover\:skew-y-12:hover {
    --tw-skew-y: 12deg;
  }
  .\32xl\:hover\:-skew-y-12:hover {
    --tw-skew-y: -12deg;
  }
  .\32xl\:hover\:-skew-y-6:hover {
    --tw-skew-y: -6deg;
  }
  .\32xl\:hover\:-skew-y-3:hover {
    --tw-skew-y: -3deg;
  }
  .\32xl\:hover\:-skew-y-2:hover {
    --tw-skew-y: -2deg;
  }
  .\32xl\:hover\:-skew-y-1:hover {
    --tw-skew-y: -1deg;
  }
  .\32xl\:focus\:skew-x-0:focus {
    --tw-skew-x: 0deg;
  }
  .\32xl\:focus\:skew-x-1:focus {
    --tw-skew-x: 1deg;
  }
  .\32xl\:focus\:skew-x-2:focus {
    --tw-skew-x: 2deg;
  }
  .\32xl\:focus\:skew-x-3:focus {
    --tw-skew-x: 3deg;
  }
  .\32xl\:focus\:skew-x-6:focus {
    --tw-skew-x: 6deg;
  }
  .\32xl\:focus\:skew-x-12:focus {
    --tw-skew-x: 12deg;
  }
  .\32xl\:focus\:-skew-x-12:focus {
    --tw-skew-x: -12deg;
  }
  .\32xl\:focus\:-skew-x-6:focus {
    --tw-skew-x: -6deg;
  }
  .\32xl\:focus\:-skew-x-3:focus {
    --tw-skew-x: -3deg;
  }
  .\32xl\:focus\:-skew-x-2:focus {
    --tw-skew-x: -2deg;
  }
  .\32xl\:focus\:-skew-x-1:focus {
    --tw-skew-x: -1deg;
  }
  .\32xl\:focus\:skew-y-0:focus {
    --tw-skew-y: 0deg;
  }
  .\32xl\:focus\:skew-y-1:focus {
    --tw-skew-y: 1deg;
  }
  .\32xl\:focus\:skew-y-2:focus {
    --tw-skew-y: 2deg;
  }
  .\32xl\:focus\:skew-y-3:focus {
    --tw-skew-y: 3deg;
  }
  .\32xl\:focus\:skew-y-6:focus {
    --tw-skew-y: 6deg;
  }
  .\32xl\:focus\:skew-y-12:focus {
    --tw-skew-y: 12deg;
  }
  .\32xl\:focus\:-skew-y-12:focus {
    --tw-skew-y: -12deg;
  }
  .\32xl\:focus\:-skew-y-6:focus {
    --tw-skew-y: -6deg;
  }
  .\32xl\:focus\:-skew-y-3:focus {
    --tw-skew-y: -3deg;
  }
  .\32xl\:focus\:-skew-y-2:focus {
    --tw-skew-y: -2deg;
  }
  .\32xl\:focus\:-skew-y-1:focus {
    --tw-skew-y: -1deg;
  }
  .\32xl\:scale-0 {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .\32xl\:scale-50 {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .\32xl\:scale-75 {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .\32xl\:scale-90 {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .\32xl\:scale-95 {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .\32xl\:scale-100 {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .\32xl\:scale-105 {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .\32xl\:scale-110 {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .\32xl\:scale-125 {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .\32xl\:scale-150 {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .\32xl\:hover\:scale-0:hover {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .\32xl\:hover\:scale-50:hover {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .\32xl\:hover\:scale-75:hover {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .\32xl\:hover\:scale-90:hover {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .\32xl\:hover\:scale-95:hover {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .\32xl\:hover\:scale-100:hover {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .\32xl\:hover\:scale-105:hover {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .\32xl\:hover\:scale-110:hover {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .\32xl\:hover\:scale-125:hover {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .\32xl\:hover\:scale-150:hover {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .\32xl\:focus\:scale-0:focus {
    --tw-scale-x: 0;
    --tw-scale-y: 0;
  }
  .\32xl\:focus\:scale-50:focus {
    --tw-scale-x: 0.5;
    --tw-scale-y: 0.5;
  }
  .\32xl\:focus\:scale-75:focus {
    --tw-scale-x: 0.75;
    --tw-scale-y: 0.75;
  }
  .\32xl\:focus\:scale-90:focus {
    --tw-scale-x: 0.9;
    --tw-scale-y: 0.9;
  }
  .\32xl\:focus\:scale-95:focus {
    --tw-scale-x: 0.95;
    --tw-scale-y: 0.95;
  }
  .\32xl\:focus\:scale-100:focus {
    --tw-scale-x: 1;
    --tw-scale-y: 1;
  }
  .\32xl\:focus\:scale-105:focus {
    --tw-scale-x: 1.05;
    --tw-scale-y: 1.05;
  }
  .\32xl\:focus\:scale-110:focus {
    --tw-scale-x: 1.1;
    --tw-scale-y: 1.1;
  }
  .\32xl\:focus\:scale-125:focus {
    --tw-scale-x: 1.25;
    --tw-scale-y: 1.25;
  }
  .\32xl\:focus\:scale-150:focus {
    --tw-scale-x: 1.5;
    --tw-scale-y: 1.5;
  }
  .\32xl\:scale-x-0 {
    --tw-scale-x: 0;
  }
  .\32xl\:scale-x-50 {
    --tw-scale-x: 0.5;
  }
  .\32xl\:scale-x-75 {
    --tw-scale-x: 0.75;
  }
  .\32xl\:scale-x-90 {
    --tw-scale-x: 0.9;
  }
  .\32xl\:scale-x-95 {
    --tw-scale-x: 0.95;
  }
  .\32xl\:scale-x-100 {
    --tw-scale-x: 1;
  }
  .\32xl\:scale-x-105 {
    --tw-scale-x: 1.05;
  }
  .\32xl\:scale-x-110 {
    --tw-scale-x: 1.1;
  }
  .\32xl\:scale-x-125 {
    --tw-scale-x: 1.25;
  }
  .\32xl\:scale-x-150 {
    --tw-scale-x: 1.5;
  }
  .\32xl\:scale-y-0 {
    --tw-scale-y: 0;
  }
  .\32xl\:scale-y-50 {
    --tw-scale-y: 0.5;
  }
  .\32xl\:scale-y-75 {
    --tw-scale-y: 0.75;
  }
  .\32xl\:scale-y-90 {
    --tw-scale-y: 0.9;
  }
  .\32xl\:scale-y-95 {
    --tw-scale-y: 0.95;
  }
  .\32xl\:scale-y-100 {
    --tw-scale-y: 1;
  }
  .\32xl\:scale-y-105 {
    --tw-scale-y: 1.05;
  }
  .\32xl\:scale-y-110 {
    --tw-scale-y: 1.1;
  }
  .\32xl\:scale-y-125 {
    --tw-scale-y: 1.25;
  }
  .\32xl\:scale-y-150 {
    --tw-scale-y: 1.5;
  }
  .\32xl\:hover\:scale-x-0:hover {
    --tw-scale-x: 0;
  }
  .\32xl\:hover\:scale-x-50:hover {
    --tw-scale-x: 0.5;
  }
  .\32xl\:hover\:scale-x-75:hover {
    --tw-scale-x: 0.75;
  }
  .\32xl\:hover\:scale-x-90:hover {
    --tw-scale-x: 0.9;
  }
  .\32xl\:hover\:scale-x-95:hover {
    --tw-scale-x: 0.95;
  }
  .\32xl\:hover\:scale-x-100:hover {
    --tw-scale-x: 1;
  }
  .\32xl\:hover\:scale-x-105:hover {
    --tw-scale-x: 1.05;
  }
  .\32xl\:hover\:scale-x-110:hover {
    --tw-scale-x: 1.1;
  }
  .\32xl\:hover\:scale-x-125:hover {
    --tw-scale-x: 1.25;
  }
  .\32xl\:hover\:scale-x-150:hover {
    --tw-scale-x: 1.5;
  }
  .\32xl\:hover\:scale-y-0:hover {
    --tw-scale-y: 0;
  }
  .\32xl\:hover\:scale-y-50:hover {
    --tw-scale-y: 0.5;
  }
  .\32xl\:hover\:scale-y-75:hover {
    --tw-scale-y: 0.75;
  }
  .\32xl\:hover\:scale-y-90:hover {
    --tw-scale-y: 0.9;
  }
  .\32xl\:hover\:scale-y-95:hover {
    --tw-scale-y: 0.95;
  }
  .\32xl\:hover\:scale-y-100:hover {
    --tw-scale-y: 1;
  }
  .\32xl\:hover\:scale-y-105:hover {
    --tw-scale-y: 1.05;
  }
  .\32xl\:hover\:scale-y-110:hover {
    --tw-scale-y: 1.1;
  }
  .\32xl\:hover\:scale-y-125:hover {
    --tw-scale-y: 1.25;
  }
  .\32xl\:hover\:scale-y-150:hover {
    --tw-scale-y: 1.5;
  }
  .\32xl\:focus\:scale-x-0:focus {
    --tw-scale-x: 0;
  }
  .\32xl\:focus\:scale-x-50:focus {
    --tw-scale-x: 0.5;
  }
  .\32xl\:focus\:scale-x-75:focus {
    --tw-scale-x: 0.75;
  }
  .\32xl\:focus\:scale-x-90:focus {
    --tw-scale-x: 0.9;
  }
  .\32xl\:focus\:scale-x-95:focus {
    --tw-scale-x: 0.95;
  }
  .\32xl\:focus\:scale-x-100:focus {
    --tw-scale-x: 1;
  }
  .\32xl\:focus\:scale-x-105:focus {
    --tw-scale-x: 1.05;
  }
  .\32xl\:focus\:scale-x-110:focus {
    --tw-scale-x: 1.1;
  }
  .\32xl\:focus\:scale-x-125:focus {
    --tw-scale-x: 1.25;
  }
  .\32xl\:focus\:scale-x-150:focus {
    --tw-scale-x: 1.5;
  }
  .\32xl\:focus\:scale-y-0:focus {
    --tw-scale-y: 0;
  }
  .\32xl\:focus\:scale-y-50:focus {
    --tw-scale-y: 0.5;
  }
  .\32xl\:focus\:scale-y-75:focus {
    --tw-scale-y: 0.75;
  }
  .\32xl\:focus\:scale-y-90:focus {
    --tw-scale-y: 0.9;
  }
  .\32xl\:focus\:scale-y-95:focus {
    --tw-scale-y: 0.95;
  }
  .\32xl\:focus\:scale-y-100:focus {
    --tw-scale-y: 1;
  }
  .\32xl\:focus\:scale-y-105:focus {
    --tw-scale-y: 1.05;
  }
  .\32xl\:focus\:scale-y-110:focus {
    --tw-scale-y: 1.1;
  }
  .\32xl\:focus\:scale-y-125:focus {
    --tw-scale-y: 1.25;
  }
  .\32xl\:focus\:scale-y-150:focus {
    --tw-scale-y: 1.5;
  }
  .\32xl\:animate-none {
    animation: none;
  }
  .\32xl\:animate-spin {
    animation: spin 1s linear infinite;
  }
  .\32xl\:animate-ping {
    animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }
  .\32xl\:animate-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
  .\32xl\:animate-bounce {
    animation: bounce 1s infinite;
  }
  .\32xl\:cursor-auto {
    cursor: auto;
  }
  .\32xl\:cursor-default {
    cursor: default;
  }
  .\32xl\:cursor-pointer {
    cursor: pointer;
  }
  .\32xl\:cursor-wait {
    cursor: wait;
  }
  .\32xl\:cursor-text {
    cursor: text;
  }
  .\32xl\:cursor-move {
    cursor: move;
  }
  .\32xl\:cursor-help {
    cursor: help;
  }
  .\32xl\:cursor-not-allowed {
    cursor: not-allowed;
  }
  .\32xl\:select-none {
    -webkit-user-select: none;
    user-select: none;
  }
  .\32xl\:select-text {
    -webkit-user-select: text;
    user-select: text;
  }
  .\32xl\:select-all {
    -webkit-user-select: all;
    user-select: all;
  }
  .\32xl\:select-auto {
    -webkit-user-select: auto;
    user-select: auto;
  }
  .\32xl\:resize-none {
    resize: none;
  }
  .\32xl\:resize-y {
    resize: vertical;
  }
  .\32xl\:resize-x {
    resize: horizontal;
  }
  .\32xl\:resize {
    resize: both;
  }
  .\32xl\:list-inside {
    list-style-position: inside;
  }
  .\32xl\:list-outside {
    list-style-position: outside;
  }
  .\32xl\:list-none {
    list-style-type: none;
  }
  .\32xl\:list-disc {
    list-style-type: disc;
  }
  .\32xl\:list-decimal {
    list-style-type: decimal;
  }
  .\32xl\:appearance-none {
    -webkit-appearance: none;
    appearance: none;
  }
  .\32xl\:auto-cols-auto {
    grid-auto-columns: auto;
  }
  .\32xl\:auto-cols-min {
    grid-auto-columns: min-content;
  }
  .\32xl\:auto-cols-max {
    grid-auto-columns: max-content;
  }
  .\32xl\:auto-cols-fr {
    grid-auto-columns: minmax(0, 1fr);
  }
  .\32xl\:grid-flow-row {
    grid-auto-flow: row;
  }
  .\32xl\:grid-flow-col {
    grid-auto-flow: column;
  }
  .\32xl\:grid-flow-row-dense {
    grid-auto-flow: row dense;
  }
  .\32xl\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }
  .\32xl\:auto-rows-auto {
    grid-auto-rows: auto;
  }
  .\32xl\:auto-rows-min {
    grid-auto-rows: min-content;
  }
  .\32xl\:auto-rows-max {
    grid-auto-rows: max-content;
  }
  .\32xl\:auto-rows-fr {
    grid-auto-rows: minmax(0, 1fr);
  }
  .\32xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .\32xl\:grid-cols-none {
    grid-template-columns: none;
  }
  .\32xl\:grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr));
  }
  .\32xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
  .\32xl\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
  .\32xl\:grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr));
  }
  .\32xl\:grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr));
  }
  .\32xl\:grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr));
  }
  .\32xl\:grid-rows-none {
    grid-template-rows: none;
  }
  .\32xl\:flex-row {
    flex-direction: row;
  }
  .\32xl\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .\32xl\:flex-col {
    flex-direction: column;
  }
  .\32xl\:flex-col-reverse {
    flex-direction: column-reverse;
  }
  .\32xl\:flex-wrap {
    flex-wrap: wrap;
  }
  .\32xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .\32xl\:flex-nowrap {
    flex-wrap: nowrap;
  }
  .\32xl\:place-content-center {
    place-content: center;
  }
  .\32xl\:place-content-start {
    place-content: start;
  }
  .\32xl\:place-content-end {
    place-content: end;
  }
  .\32xl\:place-content-between {
    place-content: space-between;
  }
  .\32xl\:place-content-around {
    place-content: space-around;
  }
  .\32xl\:place-content-evenly {
    place-content: space-evenly;
  }
  .\32xl\:place-content-stretch {
    place-content: stretch;
  }
  .\32xl\:place-items-start {
    place-items: start;
  }
  .\32xl\:place-items-end {
    place-items: end;
  }
  .\32xl\:place-items-center {
    place-items: center;
  }
  .\32xl\:place-items-stretch {
    place-items: stretch;
  }
  .\32xl\:content-center {
    align-content: center;
  }
  .\32xl\:content-start {
    align-content: flex-start;
  }
  .\32xl\:content-end {
    align-content: flex-end;
  }
  .\32xl\:content-between {
    align-content: space-between;
  }
  .\32xl\:content-around {
    align-content: space-around;
  }
  .\32xl\:content-evenly {
    align-content: space-evenly;
  }
  .\32xl\:items-start {
    align-items: flex-start;
  }
  .\32xl\:items-end {
    align-items: flex-end;
  }
  .\32xl\:items-center {
    align-items: center;
  }
  .\32xl\:items-baseline {
    align-items: baseline;
  }
  .\32xl\:items-stretch {
    align-items: stretch;
  }
  .\32xl\:justify-start {
    justify-content: flex-start;
  }
  .\32xl\:justify-end {
    justify-content: flex-end;
  }
  .\32xl\:justify-center {
    justify-content: center;
  }
  .\32xl\:justify-between {
    justify-content: space-between;
  }
  .\32xl\:justify-around {
    justify-content: space-around;
  }
  .\32xl\:justify-evenly {
    justify-content: space-evenly;
  }
  .\32xl\:justify-items-start {
    justify-items: start;
  }
  .\32xl\:justify-items-end {
    justify-items: end;
  }
  .\32xl\:justify-items-center {
    justify-items: center;
  }
  .\32xl\:justify-items-stretch {
    justify-items: stretch;
  }
  .\32xl\:gap-0 {
    gap: 0;
  }
  .\32xl\:gap-1 {
    gap: 0.25rem;
  }
  .\32xl\:gap-2 {
    gap: 0.5rem;
  }
  .\32xl\:gap-3 {
    gap: 0.75rem;
  }
  .\32xl\:gap-4 {
    gap: 1rem;
  }
  .\32xl\:gap-5 {
    gap: 1.25rem;
  }
  .\32xl\:gap-6 {
    gap: 1.5rem;
  }
  .\32xl\:gap-7 {
    gap: 1.75rem;
  }
  .\32xl\:gap-8 {
    gap: 2rem;
  }
  .\32xl\:gap-9 {
    gap: 2.25rem;
  }
  .\32xl\:gap-10 {
    gap: 2.5rem;
  }
  .\32xl\:gap-11 {
    gap: 2.75rem;
  }
  .\32xl\:gap-12 {
    gap: 3rem;
  }
  .\32xl\:gap-14 {
    gap: 3.5rem;
  }
  .\32xl\:gap-16 {
    gap: 4rem;
  }
  .\32xl\:gap-20 {
    gap: 5rem;
  }
  .\32xl\:gap-24 {
    gap: 6rem;
  }
  .\32xl\:gap-28 {
    gap: 7rem;
  }
  .\32xl\:gap-32 {
    gap: 8rem;
  }
  .\32xl\:gap-36 {
    gap: 9rem;
  }
  .\32xl\:gap-40 {
    gap: 10rem;
  }
  .\32xl\:gap-44 {
    gap: 11rem;
  }
  .\32xl\:gap-48 {
    gap: 12rem;
  }
  .\32xl\:gap-52 {
    gap: 13rem;
  }
  .\32xl\:gap-56 {
    gap: 14rem;
  }
  .\32xl\:gap-60 {
    gap: 15rem;
  }
  .\32xl\:gap-64 {
    gap: 16rem;
  }
  .\32xl\:gap-72 {
    gap: 18rem;
  }
  .\32xl\:gap-80 {
    gap: 20rem;
  }
  .\32xl\:gap-96 {
    gap: 24rem;
  }
  .\32xl\:gap-px {
    gap: 1px;
  }
  .\32xl\:gap-0\.5 {
    gap: 0.125rem;
  }
  .\32xl\:gap-1\.5 {
    gap: 0.375rem;
  }
  .\32xl\:gap-2\.5 {
    gap: 0.625rem;
  }
  .\32xl\:gap-3\.5 {
    gap: 0.875rem;
  }
  .\32xl\:gap-x-0 {
    column-gap: 0;
  }
  .\32xl\:gap-x-1 {
    column-gap: 0.25rem;
  }
  .\32xl\:gap-x-2 {
    column-gap: 0.5rem;
  }
  .\32xl\:gap-x-3 {
    column-gap: 0.75rem;
  }
  .\32xl\:gap-x-4 {
    column-gap: 1rem;
  }
  .\32xl\:gap-x-5 {
    column-gap: 1.25rem;
  }
  .\32xl\:gap-x-6 {
    column-gap: 1.5rem;
  }
  .\32xl\:gap-x-7 {
    column-gap: 1.75rem;
  }
  .\32xl\:gap-x-8 {
    column-gap: 2rem;
  }
  .\32xl\:gap-x-9 {
    column-gap: 2.25rem;
  }
  .\32xl\:gap-x-10 {
    column-gap: 2.5rem;
  }
  .\32xl\:gap-x-11 {
    column-gap: 2.75rem;
  }
  .\32xl\:gap-x-12 {
    column-gap: 3rem;
  }
  .\32xl\:gap-x-14 {
    column-gap: 3.5rem;
  }
  .\32xl\:gap-x-16 {
    column-gap: 4rem;
  }
  .\32xl\:gap-x-20 {
    column-gap: 5rem;
  }
  .\32xl\:gap-x-24 {
    column-gap: 6rem;
  }
  .\32xl\:gap-x-28 {
    column-gap: 7rem;
  }
  .\32xl\:gap-x-32 {
    column-gap: 8rem;
  }
  .\32xl\:gap-x-36 {
    column-gap: 9rem;
  }
  .\32xl\:gap-x-40 {
    column-gap: 10rem;
  }
  .\32xl\:gap-x-44 {
    column-gap: 11rem;
  }
  .\32xl\:gap-x-48 {
    column-gap: 12rem;
  }
  .\32xl\:gap-x-52 {
    column-gap: 13rem;
  }
  .\32xl\:gap-x-56 {
    column-gap: 14rem;
  }
  .\32xl\:gap-x-60 {
    column-gap: 15rem;
  }
  .\32xl\:gap-x-64 {
    column-gap: 16rem;
  }
  .\32xl\:gap-x-72 {
    column-gap: 18rem;
  }
  .\32xl\:gap-x-80 {
    column-gap: 20rem;
  }
  .\32xl\:gap-x-96 {
    column-gap: 24rem;
  }
  .\32xl\:gap-x-px {
    column-gap: 1px;
  }
  .\32xl\:gap-x-0\.5 {
    column-gap: 0.125rem;
  }
  .\32xl\:gap-x-1\.5 {
    column-gap: 0.375rem;
  }
  .\32xl\:gap-x-2\.5 {
    column-gap: 0.625rem;
  }
  .\32xl\:gap-x-3\.5 {
    column-gap: 0.875rem;
  }
  .\32xl\:gap-y-0 {
    row-gap: 0;
  }
  .\32xl\:gap-y-1 {
    row-gap: 0.25rem;
  }
  .\32xl\:gap-y-2 {
    row-gap: 0.5rem;
  }
  .\32xl\:gap-y-3 {
    row-gap: 0.75rem;
  }
  .\32xl\:gap-y-4 {
    row-gap: 1rem;
  }
  .\32xl\:gap-y-5 {
    row-gap: 1.25rem;
  }
  .\32xl\:gap-y-6 {
    row-gap: 1.5rem;
  }
  .\32xl\:gap-y-7 {
    row-gap: 1.75rem;
  }
  .\32xl\:gap-y-8 {
    row-gap: 2rem;
  }
  .\32xl\:gap-y-9 {
    row-gap: 2.25rem;
  }
  .\32xl\:gap-y-10 {
    row-gap: 2.5rem;
  }
  .\32xl\:gap-y-11 {
    row-gap: 2.75rem;
  }
  .\32xl\:gap-y-12 {
    row-gap: 3rem;
  }
  .\32xl\:gap-y-14 {
    row-gap: 3.5rem;
  }
  .\32xl\:gap-y-16 {
    row-gap: 4rem;
  }
  .\32xl\:gap-y-20 {
    row-gap: 5rem;
  }
  .\32xl\:gap-y-24 {
    row-gap: 6rem;
  }
  .\32xl\:gap-y-28 {
    row-gap: 7rem;
  }
  .\32xl\:gap-y-32 {
    row-gap: 8rem;
  }
  .\32xl\:gap-y-36 {
    row-gap: 9rem;
  }
  .\32xl\:gap-y-40 {
    row-gap: 10rem;
  }
  .\32xl\:gap-y-44 {
    row-gap: 11rem;
  }
  .\32xl\:gap-y-48 {
    row-gap: 12rem;
  }
  .\32xl\:gap-y-52 {
    row-gap: 13rem;
  }
  .\32xl\:gap-y-56 {
    row-gap: 14rem;
  }
  .\32xl\:gap-y-60 {
    row-gap: 15rem;
  }
  .\32xl\:gap-y-64 {
    row-gap: 16rem;
  }
  .\32xl\:gap-y-72 {
    row-gap: 18rem;
  }
  .\32xl\:gap-y-80 {
    row-gap: 20rem;
  }
  .\32xl\:gap-y-96 {
    row-gap: 24rem;
  }
  .\32xl\:gap-y-px {
    row-gap: 1px;
  }
  .\32xl\:gap-y-0\.5 {
    row-gap: 0.125rem;
  }
  .\32xl\:gap-y-1\.5 {
    row-gap: 0.375rem;
  }
  .\32xl\:gap-y-2\.5 {
    row-gap: 0.625rem;
  }
  .\32xl\:gap-y-3\.5 {
    row-gap: 0.875rem;
  }
  .\32xl\:space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3rem * var(--tw-space-x-reverse));
    margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(5rem * var(--tw-space-x-reverse));
    margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(6rem * var(--tw-space-x-reverse));
    margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(7rem * var(--tw-space-x-reverse));
    margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(8rem * var(--tw-space-x-reverse));
    margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(9rem * var(--tw-space-x-reverse));
    margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(10rem * var(--tw-space-x-reverse));
    margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(11rem * var(--tw-space-x-reverse));
    margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(12rem * var(--tw-space-x-reverse));
    margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(13rem * var(--tw-space-x-reverse));
    margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(14rem * var(--tw-space-x-reverse));
    margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(15rem * var(--tw-space-x-reverse));
    margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(16rem * var(--tw-space-x-reverse));
    margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(18rem * var(--tw-space-x-reverse));
    margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(20rem * var(--tw-space-x-reverse));
    margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(24rem * var(--tw-space-x-reverse));
    margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1px * var(--tw-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0px * var(--tw-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1rem * var(--tw-space-x-reverse));
    margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2rem * var(--tw-space-x-reverse));
    margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.25rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-2.75rem * var(--tw-space-x-reverse));
    margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3rem * var(--tw-space-x-reverse));
    margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-3.5rem * var(--tw-space-x-reverse));
    margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-4rem * var(--tw-space-x-reverse));
    margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-5rem * var(--tw-space-x-reverse));
    margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-6rem * var(--tw-space-x-reverse));
    margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-7rem * var(--tw-space-x-reverse));
    margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-8rem * var(--tw-space-x-reverse));
    margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-9rem * var(--tw-space-x-reverse));
    margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-10rem * var(--tw-space-x-reverse));
    margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-11rem * var(--tw-space-x-reverse));
    margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-12rem * var(--tw-space-x-reverse));
    margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-13rem * var(--tw-space-x-reverse));
    margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-14rem * var(--tw-space-x-reverse));
    margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-15rem * var(--tw-space-x-reverse));
    margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-16rem * var(--tw-space-x-reverse));
    margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-18rem * var(--tw-space-x-reverse));
    margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-20rem * var(--tw-space-x-reverse));
    margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-24rem * var(--tw-space-x-reverse));
    margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-1px * var(--tw-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.125rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.375rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.625rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(-0.875rem * var(--tw-space-x-reverse));
    margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse)));
  }
  .\32xl\:space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2.75rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(3.5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(4rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(6rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(7rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(8rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(9rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(10rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(11rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(12rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(13rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(14rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(15rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(16rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(18rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(20rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(24rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1px * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.375rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.625rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.875rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-6 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-7 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-9 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-11 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-12 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-14 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-4rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-5rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-24 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-6rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-28 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-7rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-32 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-8rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-36 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-9rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-10rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-44 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-11rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-48 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-12rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-52 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-13rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-56 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-14rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-15rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-64 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-16rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-72 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-18rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-20rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-96 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-24rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-1px * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse));
  }
  .\32xl\:-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse));
  }
  .\32xl\:space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 1;
  }
  .\32xl\:space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 1;
  }
  .\32xl\:divide-x-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(0px * var(--tw-divide-x-reverse));
    border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .\32xl\:divide-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(2px * var(--tw-divide-x-reverse));
    border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .\32xl\:divide-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(4px * var(--tw-divide-x-reverse));
    border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .\32xl\:divide-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(8px * var(--tw-divide-x-reverse));
    border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .\32xl\:divide-x > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 0;
    border-right-width: calc(1px * var(--tw-divide-x-reverse));
    border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse)));
  }
  .\32xl\:divide-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(0px * var(--tw-divide-y-reverse));
  }
  .\32xl\:divide-y-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(2px * var(--tw-divide-y-reverse));
  }
  .\32xl\:divide-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(4px * var(--tw-divide-y-reverse));
  }
  .\32xl\:divide-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(8px * var(--tw-divide-y-reverse));
  }
  .\32xl\:divide-y > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 0;
    border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
    border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
  }
  .\32xl\:divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-y-reverse: 1;
  }
  .\32xl\:divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-x-reverse: 1;
  }
  .\32xl\:divide-solid > :not([hidden]) ~ :not([hidden]) {
    border-style: solid;
  }
  .\32xl\:divide-dashed > :not([hidden]) ~ :not([hidden]) {
    border-style: dashed;
  }
  .\32xl\:divide-dotted > :not([hidden]) ~ :not([hidden]) {
    border-style: dotted;
  }
  .\32xl\:divide-double > :not([hidden]) ~ :not([hidden]) {
    border-style: double;
  }
  .\32xl\:divide-none > :not([hidden]) ~ :not([hidden]) {
    border-style: none;
  }
  .\32xl\:divide-transparent > :not([hidden]) ~ :not([hidden]) {
    border-color: transparent;
  }
  .\32xl\:divide-current > :not([hidden]) ~ :not([hidden]) {
    border-color: currentColor;
  }
  .\32xl\:divide-black > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-divide-opacity));
  }
  .\32xl\:divide-white > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-divide-opacity));
  }
  .\32xl\:divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-divide-opacity));
  }
  .\32xl\:divide-red-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-divide-opacity));
  }
  .\32xl\:divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-divide-opacity));
  }
  .\32xl\:divide-green-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-divide-opacity));
  }
  .\32xl\:divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-divide-opacity));
  }
  .\32xl\:divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-divide-opacity));
  }
  .\32xl\:divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-divide-opacity));
  }
  .\32xl\:divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-divide-opacity));
  }
  .\32xl\:divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0;
  }
  .\32xl\:divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.05;
  }
  .\32xl\:divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.1;
  }
  .\32xl\:divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.2;
  }
  .\32xl\:divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.25;
  }
  .\32xl\:divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.3;
  }
  .\32xl\:divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.4;
  }
  .\32xl\:divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.5;
  }
  .\32xl\:divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.6;
  }
  .\32xl\:divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.7;
  }
  .\32xl\:divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.75;
  }
  .\32xl\:divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.8;
  }
  .\32xl\:divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.9;
  }
  .\32xl\:divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 0.95;
  }
  .\32xl\:divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
    --tw-divide-opacity: 1;
  }
  .\32xl\:place-self-auto {
    place-self: auto;
  }
  .\32xl\:place-self-start {
    place-self: start;
  }
  .\32xl\:place-self-end {
    place-self: end;
  }
  .\32xl\:place-self-center {
    place-self: center;
  }
  .\32xl\:place-self-stretch {
    place-self: stretch;
  }
  .\32xl\:self-auto {
    align-self: auto;
  }
  .\32xl\:self-start {
    align-self: flex-start;
  }
  .\32xl\:self-end {
    align-self: flex-end;
  }
  .\32xl\:self-center {
    align-self: center;
  }
  .\32xl\:self-stretch {
    align-self: stretch;
  }
  .\32xl\:self-baseline {
    align-self: baseline;
  }
  .\32xl\:justify-self-auto {
    justify-self: auto;
  }
  .\32xl\:justify-self-start {
    justify-self: start;
  }
  .\32xl\:justify-self-end {
    justify-self: end;
  }
  .\32xl\:justify-self-center {
    justify-self: center;
  }
  .\32xl\:justify-self-stretch {
    justify-self: stretch;
  }
  .\32xl\:overflow-auto {
    overflow: auto;
  }
  .\32xl\:overflow-hidden {
    overflow: hidden;
  }
  .\32xl\:overflow-visible {
    overflow: visible;
  }
  .\32xl\:overflow-scroll {
    overflow: scroll;
  }
  .\32xl\:overflow-x-auto {
    overflow-x: auto;
  }
  .\32xl\:overflow-y-auto {
    overflow-y: auto;
  }
  .\32xl\:overflow-x-hidden {
    overflow-x: hidden;
  }
  .\32xl\:overflow-y-hidden {
    overflow-y: hidden;
  }
  .\32xl\:overflow-x-visible {
    overflow-x: visible;
  }
  .\32xl\:overflow-y-visible {
    overflow-y: visible;
  }
  .\32xl\:overflow-x-scroll {
    overflow-x: scroll;
  }
  .\32xl\:overflow-y-scroll {
    overflow-y: scroll;
  }
  .\32xl\:overscroll-auto {
    overscroll-behavior: auto;
  }
  .\32xl\:overscroll-contain {
    overscroll-behavior: contain;
  }
  .\32xl\:overscroll-none {
    overscroll-behavior: none;
  }
  .\32xl\:overscroll-y-auto {
    overscroll-behavior-y: auto;
  }
  .\32xl\:overscroll-y-contain {
    overscroll-behavior-y: contain;
  }
  .\32xl\:overscroll-y-none {
    overscroll-behavior-y: none;
  }
  .\32xl\:overscroll-x-auto {
    overscroll-behavior-x: auto;
  }
  .\32xl\:overscroll-x-contain {
    overscroll-behavior-x: contain;
  }
  .\32xl\:overscroll-x-none {
    overscroll-behavior-x: none;
  }
  .\32xl\:truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .\32xl\:overflow-ellipsis {
    text-overflow: ellipsis;
  }
  .\32xl\:overflow-clip {
    text-overflow: clip;
  }
  .\32xl\:whitespace-normal {
    white-space: normal;
  }
  .\32xl\:whitespace-nowrap {
    white-space: nowrap;
  }
  .\32xl\:whitespace-pre {
    white-space: pre;
  }
  .\32xl\:whitespace-pre-line {
    white-space: pre-line;
  }
  .\32xl\:whitespace-pre-wrap {
    white-space: pre-wrap;
  }
  .\32xl\:break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }
  .\32xl\:break-words {
    overflow-wrap: break-word;
  }
  .\32xl\:break-all {
    word-break: break-all;
  }
  .\32xl\:rounded-none {
    border-radius: 0;
  }
  .\32xl\:rounded-sm {
    border-radius: 0.125rem;
  }
  .\32xl\:rounded {
    border-radius: 0.25rem;
  }
  .\32xl\:rounded-md {
    border-radius: 0.375rem;
  }
  .\32xl\:rounded-lg {
    border-radius: 0.5rem;
  }
  .\32xl\:rounded-xl {
    border-radius: 0.75rem;
  }
  .\32xl\:rounded-2xl {
    border-radius: 1rem;
  }
  .\32xl\:rounded-3xl {
    border-radius: 1.5rem;
  }
  .\32xl\:rounded-full {
    border-radius: 9999px;
  }
  .\32xl\:rounded-t-none {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .\32xl\:rounded-t-sm {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
  }
  .\32xl\:rounded-t {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .\32xl\:rounded-t-md {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }
  .\32xl\:rounded-t-lg {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
  .\32xl\:rounded-t-xl {
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
  }
  .\32xl\:rounded-t-2xl {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }
  .\32xl\:rounded-t-3xl {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
  .\32xl\:rounded-t-full {
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
  }
  .\32xl\:rounded-r-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .\32xl\:rounded-r-sm {
    border-top-right-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
  }
  .\32xl\:rounded-r {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .\32xl\:rounded-r-md {
    border-top-right-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
  }
  .\32xl\:rounded-r-lg {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
  .\32xl\:rounded-r-xl {
    border-top-right-radius: 0.75rem;
    border-bottom-right-radius: 0.75rem;
  }
  .\32xl\:rounded-r-2xl {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }
  .\32xl\:rounded-r-3xl {
    border-top-right-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  .\32xl\:rounded-r-full {
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
  }
  .\32xl\:rounded-b-none {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .\32xl\:rounded-b-sm {
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .\32xl\:rounded-b {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .\32xl\:rounded-b-md {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .\32xl\:rounded-b-lg {
    border-bottom-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .\32xl\:rounded-b-xl {
    border-bottom-right-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .\32xl\:rounded-b-2xl {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .\32xl\:rounded-b-3xl {
    border-bottom-right-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .\32xl\:rounded-b-full {
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .\32xl\:rounded-l-none {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .\32xl\:rounded-l-sm {
    border-top-left-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
  }
  .\32xl\:rounded-l {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .\32xl\:rounded-l-md {
    border-top-left-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
  .\32xl\:rounded-l-lg {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  }
  .\32xl\:rounded-l-xl {
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
  }
  .\32xl\:rounded-l-2xl {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }
  .\32xl\:rounded-l-3xl {
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
  }
  .\32xl\:rounded-l-full {
    border-top-left-radius: 9999px;
    border-bottom-left-radius: 9999px;
  }
  .\32xl\:rounded-tl-none {
    border-top-left-radius: 0;
  }
  .\32xl\:rounded-tl-sm {
    border-top-left-radius: 0.125rem;
  }
  .\32xl\:rounded-tl {
    border-top-left-radius: 0.25rem;
  }
  .\32xl\:rounded-tl-md {
    border-top-left-radius: 0.375rem;
  }
  .\32xl\:rounded-tl-lg {
    border-top-left-radius: 0.5rem;
  }
  .\32xl\:rounded-tl-xl {
    border-top-left-radius: 0.75rem;
  }
  .\32xl\:rounded-tl-2xl {
    border-top-left-radius: 1rem;
  }
  .\32xl\:rounded-tl-3xl {
    border-top-left-radius: 1.5rem;
  }
  .\32xl\:rounded-tl-full {
    border-top-left-radius: 9999px;
  }
  .\32xl\:rounded-tr-none {
    border-top-right-radius: 0;
  }
  .\32xl\:rounded-tr-sm {
    border-top-right-radius: 0.125rem;
  }
  .\32xl\:rounded-tr {
    border-top-right-radius: 0.25rem;
  }
  .\32xl\:rounded-tr-md {
    border-top-right-radius: 0.375rem;
  }
  .\32xl\:rounded-tr-lg {
    border-top-right-radius: 0.5rem;
  }
  .\32xl\:rounded-tr-xl {
    border-top-right-radius: 0.75rem;
  }
  .\32xl\:rounded-tr-2xl {
    border-top-right-radius: 1rem;
  }
  .\32xl\:rounded-tr-3xl {
    border-top-right-radius: 1.5rem;
  }
  .\32xl\:rounded-tr-full {
    border-top-right-radius: 9999px;
  }
  .\32xl\:rounded-br-none {
    border-bottom-right-radius: 0;
  }
  .\32xl\:rounded-br-sm {
    border-bottom-right-radius: 0.125rem;
  }
  .\32xl\:rounded-br {
    border-bottom-right-radius: 0.25rem;
  }
  .\32xl\:rounded-br-md {
    border-bottom-right-radius: 0.375rem;
  }
  .\32xl\:rounded-br-lg {
    border-bottom-right-radius: 0.5rem;
  }
  .\32xl\:rounded-br-xl {
    border-bottom-right-radius: 0.75rem;
  }
  .\32xl\:rounded-br-2xl {
    border-bottom-right-radius: 1rem;
  }
  .\32xl\:rounded-br-3xl {
    border-bottom-right-radius: 1.5rem;
  }
  .\32xl\:rounded-br-full {
    border-bottom-right-radius: 9999px;
  }
  .\32xl\:rounded-bl-none {
    border-bottom-left-radius: 0;
  }
  .\32xl\:rounded-bl-sm {
    border-bottom-left-radius: 0.125rem;
  }
  .\32xl\:rounded-bl {
    border-bottom-left-radius: 0.25rem;
  }
  .\32xl\:rounded-bl-md {
    border-bottom-left-radius: 0.375rem;
  }
  .\32xl\:rounded-bl-lg {
    border-bottom-left-radius: 0.5rem;
  }
  .\32xl\:rounded-bl-xl {
    border-bottom-left-radius: 0.75rem;
  }
  .\32xl\:rounded-bl-2xl {
    border-bottom-left-radius: 1rem;
  }
  .\32xl\:rounded-bl-3xl {
    border-bottom-left-radius: 1.5rem;
  }
  .\32xl\:rounded-bl-full {
    border-bottom-left-radius: 9999px;
  }
  .\32xl\:border-0 {
    border-width: 0;
  }
  .\32xl\:border-2 {
    border-width: 2px;
  }
  .\32xl\:border-4 {
    border-width: 4px;
  }
  .\32xl\:border-8 {
    border-width: 8px;
  }
  .\32xl\:border {
    border-width: 1px;
  }
  .\32xl\:border-t-0 {
    border-top-width: 0;
  }
  .\32xl\:border-t-2 {
    border-top-width: 2px;
  }
  .\32xl\:border-t-4 {
    border-top-width: 4px;
  }
  .\32xl\:border-t-8 {
    border-top-width: 8px;
  }
  .\32xl\:border-t {
    border-top-width: 1px;
  }
  .\32xl\:border-r-0 {
    border-right-width: 0;
  }
  .\32xl\:border-r-2 {
    border-right-width: 2px;
  }
  .\32xl\:border-r-4 {
    border-right-width: 4px;
  }
  .\32xl\:border-r-8 {
    border-right-width: 8px;
  }
  .\32xl\:border-r {
    border-right-width: 1px;
  }
  .\32xl\:border-b-0 {
    border-bottom-width: 0;
  }
  .\32xl\:border-b-2 {
    border-bottom-width: 2px;
  }
  .\32xl\:border-b-4 {
    border-bottom-width: 4px;
  }
  .\32xl\:border-b-8 {
    border-bottom-width: 8px;
  }
  .\32xl\:border-b {
    border-bottom-width: 1px;
  }
  .\32xl\:border-l-0 {
    border-left-width: 0;
  }
  .\32xl\:border-l-2 {
    border-left-width: 2px;
  }
  .\32xl\:border-l-4 {
    border-left-width: 4px;
  }
  .\32xl\:border-l-8 {
    border-left-width: 8px;
  }
  .\32xl\:border-l {
    border-left-width: 1px;
  }
  .\32xl\:border-solid {
    border-style: solid;
  }
  .\32xl\:border-dashed {
    border-style: dashed;
  }
  .\32xl\:border-dotted {
    border-style: dotted;
  }
  .\32xl\:border-double {
    border-style: double;
  }
  .\32xl\:border-none {
    border-style: none;
  }
  .\32xl\:border-transparent {
    border-color: transparent;
  }
  .\32xl\:border-current {
    border-color: currentColor;
  }
  .\32xl\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .\32xl\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .\32xl\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .\32xl\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .\32xl\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .\32xl\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .\32xl\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .\32xl\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .\32xl\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .\32xl\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .\32xl\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .\32xl\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .\32xl\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .\32xl\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .\32xl\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .\32xl\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .\32xl\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .\32xl\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .\32xl\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .\32xl\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .\32xl\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .\32xl\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .\32xl\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .\32xl\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .\32xl\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .\32xl\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .\32xl\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .\32xl\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-transparent {
    border-color: transparent;
  }
  .group:hover .\32xl\:group-hover\:border-current {
    border-color: currentColor;
  }
  .group:hover .\32xl\:group-hover\:border-black {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-white {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-50 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-100 {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-200 {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-300 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-400 {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-500 {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-600 {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-700 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-800 {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-gray-900 {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-50 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-200 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-400 {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-500 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-600 {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-700 {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-800 {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-red-900 {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-50 {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-100 {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-200 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-300 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-400 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-500 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-600 {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-700 {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-800 {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-yellow-900 {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-50 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-100 {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-200 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-300 {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-400 {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-500 {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-600 {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-700 {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-800 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-green-900 {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-50 {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-100 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-200 {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-300 {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-400 {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-500 {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-600 {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-700 {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-800 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-blue-900 {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-50 {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-100 {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-200 {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-300 {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-400 {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-500 {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-600 {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-700 {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-800 {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-indigo-900 {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-50 {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-100 {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-200 {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-300 {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-400 {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-500 {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-600 {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-700 {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-800 {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-purple-900 {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-50 {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-100 {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-200 {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-300 {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-400 {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-500 {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-600 {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-700 {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-800 {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .group:hover .\32xl\:group-hover\:border-pink-900 {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-transparent:focus-within {
    border-color: transparent;
  }
  .\32xl\:focus-within\:border-current:focus-within {
    border-color: currentColor;
  }
  .\32xl\:focus-within\:border-black:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-white:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-gray-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-red-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-yellow-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-green-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-blue-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-indigo-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-purple-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-50:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-100:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-200:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-300:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-400:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-500:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-600:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-700:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-800:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .\32xl\:focus-within\:border-pink-900:focus-within {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-transparent:hover {
    border-color: transparent;
  }
  .\32xl\:hover\:border-current:hover {
    border-color: currentColor;
  }
  .\32xl\:hover\:border-black:hover {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-white:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-gray-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-red-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-yellow-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-green-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-blue-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-indigo-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-purple-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-50:hover {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-100:hover {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-200:hover {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-300:hover {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-400:hover {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-500:hover {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-600:hover {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-700:hover {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-800:hover {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .\32xl\:hover\:border-pink-900:hover {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-transparent:focus {
    border-color: transparent;
  }
  .\32xl\:focus\:border-current:focus {
    border-color: currentColor;
  }
  .\32xl\:focus\:border-black:focus {
    --tw-border-opacity: 1;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-white:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 255, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 250, 251, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(243, 244, 246, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(229, 231, 235, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(156, 163, 175, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(107, 114, 128, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(75, 85, 99, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 65, 81, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(31, 41, 55, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-gray-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(17, 24, 39, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 242, 242, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 226, 226, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 202, 202, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 165, 165, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(248, 113, 113, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 68, 68, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(220, 38, 38, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(185, 28, 28, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(153, 27, 27, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-red-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(127, 29, 29, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(255, 251, 235, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(254, 243, 199, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 230, 138, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 211, 77, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 191, 36, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 158, 11, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(217, 119, 6, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(180, 83, 9, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(146, 64, 14, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-yellow-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(120, 53, 15, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 253, 245, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(209, 250, 229, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 243, 208, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(110, 231, 183, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(52, 211, 153, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(16, 185, 129, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(4, 120, 87, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 95, 70, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-green-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(6, 78, 59, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(239, 246, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 234, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(191, 219, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(147, 197, 253, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(96, 165, 250, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(59, 130, 246, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(37, 99, 235, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(29, 78, 216, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 64, 175, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-blue-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(30, 58, 138, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(238, 242, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(224, 231, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(199, 210, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(165, 180, 252, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(129, 140, 248, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(99, 102, 241, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(79, 70, 229, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(67, 56, 202, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(55, 48, 163, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-indigo-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(49, 46, 129, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(245, 243, 255, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(237, 233, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(221, 214, 254, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(196, 181, 253, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(167, 139, 250, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(139, 92, 246, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(124, 58, 237, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(109, 40, 217, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(91, 33, 182, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-purple-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(76, 29, 149, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-50:focus {
    --tw-border-opacity: 1;
    border-color: rgba(253, 242, 248, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-100:focus {
    --tw-border-opacity: 1;
    border-color: rgba(252, 231, 243, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-200:focus {
    --tw-border-opacity: 1;
    border-color: rgba(251, 207, 232, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-300:focus {
    --tw-border-opacity: 1;
    border-color: rgba(249, 168, 212, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-400:focus {
    --tw-border-opacity: 1;
    border-color: rgba(244, 114, 182, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-500:focus {
    --tw-border-opacity: 1;
    border-color: rgba(236, 72, 153, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-600:focus {
    --tw-border-opacity: 1;
    border-color: rgba(219, 39, 119, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-700:focus {
    --tw-border-opacity: 1;
    border-color: rgba(190, 24, 93, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-800:focus {
    --tw-border-opacity: 1;
    border-color: rgba(157, 23, 77, var(--tw-border-opacity));
  }
  .\32xl\:focus\:border-pink-900:focus {
    --tw-border-opacity: 1;
    border-color: rgba(131, 24, 67, var(--tw-border-opacity));
  }
  .\32xl\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .\32xl\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .\32xl\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .\32xl\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .\32xl\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .\32xl\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .\32xl\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .\32xl\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .\32xl\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .\32xl\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .\32xl\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .\32xl\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .\32xl\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .\32xl\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .\32xl\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-0 {
    --tw-border-opacity: 0;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-5 {
    --tw-border-opacity: 0.05;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-10 {
    --tw-border-opacity: 0.1;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-20 {
    --tw-border-opacity: 0.2;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-25 {
    --tw-border-opacity: 0.25;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-30 {
    --tw-border-opacity: 0.3;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-40 {
    --tw-border-opacity: 0.4;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-50 {
    --tw-border-opacity: 0.5;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-60 {
    --tw-border-opacity: 0.6;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-70 {
    --tw-border-opacity: 0.7;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-75 {
    --tw-border-opacity: 0.75;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-80 {
    --tw-border-opacity: 0.8;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-90 {
    --tw-border-opacity: 0.9;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-95 {
    --tw-border-opacity: 0.95;
  }
  .group:hover .\32xl\:group-hover\:border-opacity-100 {
    --tw-border-opacity: 1;
  }
  .\32xl\:focus-within\:border-opacity-0:focus-within {
    --tw-border-opacity: 0;
  }
  .\32xl\:focus-within\:border-opacity-5:focus-within {
    --tw-border-opacity: 0.05;
  }
  .\32xl\:focus-within\:border-opacity-10:focus-within {
    --tw-border-opacity: 0.1;
  }
  .\32xl\:focus-within\:border-opacity-20:focus-within {
    --tw-border-opacity: 0.2;
  }
  .\32xl\:focus-within\:border-opacity-25:focus-within {
    --tw-border-opacity: 0.25;
  }
  .\32xl\:focus-within\:border-opacity-30:focus-within {
    --tw-border-opacity: 0.3;
  }
  .\32xl\:focus-within\:border-opacity-40:focus-within {
    --tw-border-opacity: 0.4;
  }
  .\32xl\:focus-within\:border-opacity-50:focus-within {
    --tw-border-opacity: 0.5;
  }
  .\32xl\:focus-within\:border-opacity-60:focus-within {
    --tw-border-opacity: 0.6;
  }
  .\32xl\:focus-within\:border-opacity-70:focus-within {
    --tw-border-opacity: 0.7;
  }
  .\32xl\:focus-within\:border-opacity-75:focus-within {
    --tw-border-opacity: 0.75;
  }
  .\32xl\:focus-within\:border-opacity-80:focus-within {
    --tw-border-opacity: 0.8;
  }
  .\32xl\:focus-within\:border-opacity-90:focus-within {
    --tw-border-opacity: 0.9;
  }
  .\32xl\:focus-within\:border-opacity-95:focus-within {
    --tw-border-opacity: 0.95;
  }
  .\32xl\:focus-within\:border-opacity-100:focus-within {
    --tw-border-opacity: 1;
  }
  .\32xl\:hover\:border-opacity-0:hover {
    --tw-border-opacity: 0;
  }
  .\32xl\:hover\:border-opacity-5:hover {
    --tw-border-opacity: 0.05;
  }
  .\32xl\:hover\:border-opacity-10:hover {
    --tw-border-opacity: 0.1;
  }
  .\32xl\:hover\:border-opacity-20:hover {
    --tw-border-opacity: 0.2;
  }
  .\32xl\:hover\:border-opacity-25:hover {
    --tw-border-opacity: 0.25;
  }
  .\32xl\:hover\:border-opacity-30:hover {
    --tw-border-opacity: 0.3;
  }
  .\32xl\:hover\:border-opacity-40:hover {
    --tw-border-opacity: 0.4;
  }
  .\32xl\:hover\:border-opacity-50:hover {
    --tw-border-opacity: 0.5;
  }
  .\32xl\:hover\:border-opacity-60:hover {
    --tw-border-opacity: 0.6;
  }
  .\32xl\:hover\:border-opacity-70:hover {
    --tw-border-opacity: 0.7;
  }
  .\32xl\:hover\:border-opacity-75:hover {
    --tw-border-opacity: 0.75;
  }
  .\32xl\:hover\:border-opacity-80:hover {
    --tw-border-opacity: 0.8;
  }
  .\32xl\:hover\:border-opacity-90:hover {
    --tw-border-opacity: 0.9;
  }
  .\32xl\:hover\:border-opacity-95:hover {
    --tw-border-opacity: 0.95;
  }
  .\32xl\:hover\:border-opacity-100:hover {
    --tw-border-opacity: 1;
  }
  .\32xl\:focus\:border-opacity-0:focus {
    --tw-border-opacity: 0;
  }
  .\32xl\:focus\:border-opacity-5:focus {
    --tw-border-opacity: 0.05;
  }
  .\32xl\:focus\:border-opacity-10:focus {
    --tw-border-opacity: 0.1;
  }
  .\32xl\:focus\:border-opacity-20:focus {
    --tw-border-opacity: 0.2;
  }
  .\32xl\:focus\:border-opacity-25:focus {
    --tw-border-opacity: 0.25;
  }
  .\32xl\:focus\:border-opacity-30:focus {
    --tw-border-opacity: 0.3;
  }
  .\32xl\:focus\:border-opacity-40:focus {
    --tw-border-opacity: 0.4;
  }
  .\32xl\:focus\:border-opacity-50:focus {
    --tw-border-opacity: 0.5;
  }
  .\32xl\:focus\:border-opacity-60:focus {
    --tw-border-opacity: 0.6;
  }
  .\32xl\:focus\:border-opacity-70:focus {
    --tw-border-opacity: 0.7;
  }
  .\32xl\:focus\:border-opacity-75:focus {
    --tw-border-opacity: 0.75;
  }
  .\32xl\:focus\:border-opacity-80:focus {
    --tw-border-opacity: 0.8;
  }
  .\32xl\:focus\:border-opacity-90:focus {
    --tw-border-opacity: 0.9;
  }
  .\32xl\:focus\:border-opacity-95:focus {
    --tw-border-opacity: 0.95;
  }
  .\32xl\:focus\:border-opacity-100:focus {
    --tw-border-opacity: 1;
  }
  .\32xl\:bg-transparent {
    background-color: transparent;
  }
  .\32xl\:bg-current {
    background-color: currentColor;
  }
  .\32xl\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .\32xl\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .\32xl\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .\32xl\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .\32xl\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .\32xl\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .\32xl\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .\32xl\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .\32xl\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .\32xl\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-transparent {
    background-color: transparent;
  }
  .group:hover .\32xl\:group-hover\:bg-current {
    background-color: currentColor;
  }
  .group:hover .\32xl\:group-hover\:bg-black {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-gray-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-red-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-yellow-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-green-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-blue-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-indigo-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-purple-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-50 {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-100 {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-200 {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-300 {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-400 {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-500 {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-600 {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-700 {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-800 {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .group:hover .\32xl\:group-hover\:bg-pink-900 {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-transparent:focus-within {
    background-color: transparent;
  }
  .\32xl\:focus-within\:bg-current:focus-within {
    background-color: currentColor;
  }
  .\32xl\:focus-within\:bg-black:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-white:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-gray-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-red-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-yellow-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-green-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-blue-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-indigo-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-purple-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-50:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-100:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-200:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-300:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-400:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-500:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-600:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-700:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-800:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .\32xl\:focus-within\:bg-pink-900:focus-within {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-transparent:hover {
    background-color: transparent;
  }
  .\32xl\:hover\:bg-current:hover {
    background-color: currentColor;
  }
  .\32xl\:hover\:bg-black:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-gray-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-red-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-yellow-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-green-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-blue-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-indigo-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-purple-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-50:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-100:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-200:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-300:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-400:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-500:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-600:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-700:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-800:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .\32xl\:hover\:bg-pink-900:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-transparent:focus {
    background-color: transparent;
  }
  .\32xl\:focus\:bg-current:focus {
    background-color: currentColor;
  }
  .\32xl\:focus\:bg-black:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-white:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(243, 244, 246, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 213, 219, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 163, 175, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(107, 114, 128, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(75, 85, 99, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 65, 81, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-gray-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(17, 24, 39, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 165, 165, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(153, 27, 27, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-red-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(127, 29, 29, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 251, 235, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(254, 243, 199, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 230, 138, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 211, 77, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 191, 36, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 158, 11, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(217, 119, 6, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(146, 64, 14, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-yellow-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(120, 53, 15, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 253, 245, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(209, 250, 229, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(110, 231, 183, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(4, 120, 87, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 95, 70, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-green-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(6, 78, 59, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(239, 246, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(191, 219, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(147, 197, 253, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(96, 165, 250, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(59, 130, 246, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(37, 99, 235, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(29, 78, 216, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 64, 175, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-blue-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(30, 58, 138, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(238, 242, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(224, 231, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(199, 210, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(165, 180, 252, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(129, 140, 248, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(67, 56, 202, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(55, 48, 163, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-indigo-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(49, 46, 129, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(245, 243, 255, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(237, 233, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 214, 254, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 181, 253, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(167, 139, 250, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(139, 92, 246, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(124, 58, 237, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(109, 40, 217, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(91, 33, 182, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-purple-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(76, 29, 149, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-50:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(253, 242, 248, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-100:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(252, 231, 243, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-200:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(251, 207, 232, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-300:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(249, 168, 212, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-400:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(244, 114, 182, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-500:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-600:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(219, 39, 119, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-700:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(190, 24, 93, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-800:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(157, 23, 77, var(--tw-bg-opacity));
  }
  .\32xl\:focus\:bg-pink-900:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(131, 24, 67, var(--tw-bg-opacity));
  }
  .\32xl\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .\32xl\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .\32xl\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .\32xl\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .\32xl\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .\32xl\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .\32xl\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .\32xl\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .\32xl\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .\32xl\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .\32xl\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .\32xl\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .\32xl\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .\32xl\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .\32xl\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-5 {
    --tw-bg-opacity: 0.05;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-10 {
    --tw-bg-opacity: 0.1;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-20 {
    --tw-bg-opacity: 0.2;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-25 {
    --tw-bg-opacity: 0.25;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-30 {
    --tw-bg-opacity: 0.3;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-40 {
    --tw-bg-opacity: 0.4;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-50 {
    --tw-bg-opacity: 0.5;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-60 {
    --tw-bg-opacity: 0.6;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-70 {
    --tw-bg-opacity: 0.7;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-75 {
    --tw-bg-opacity: 0.75;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-80 {
    --tw-bg-opacity: 0.8;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-90 {
    --tw-bg-opacity: 0.9;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-95 {
    --tw-bg-opacity: 0.95;
  }
  .group:hover .\32xl\:group-hover\:bg-opacity-100 {
    --tw-bg-opacity: 1;
  }
  .\32xl\:focus-within\:bg-opacity-0:focus-within {
    --tw-bg-opacity: 0;
  }
  .\32xl\:focus-within\:bg-opacity-5:focus-within {
    --tw-bg-opacity: 0.05;
  }
  .\32xl\:focus-within\:bg-opacity-10:focus-within {
    --tw-bg-opacity: 0.1;
  }
  .\32xl\:focus-within\:bg-opacity-20:focus-within {
    --tw-bg-opacity: 0.2;
  }
  .\32xl\:focus-within\:bg-opacity-25:focus-within {
    --tw-bg-opacity: 0.25;
  }
  .\32xl\:focus-within\:bg-opacity-30:focus-within {
    --tw-bg-opacity: 0.3;
  }
  .\32xl\:focus-within\:bg-opacity-40:focus-within {
    --tw-bg-opacity: 0.4;
  }
  .\32xl\:focus-within\:bg-opacity-50:focus-within {
    --tw-bg-opacity: 0.5;
  }
  .\32xl\:focus-within\:bg-opacity-60:focus-within {
    --tw-bg-opacity: 0.6;
  }
  .\32xl\:focus-within\:bg-opacity-70:focus-within {
    --tw-bg-opacity: 0.7;
  }
  .\32xl\:focus-within\:bg-opacity-75:focus-within {
    --tw-bg-opacity: 0.75;
  }
  .\32xl\:focus-within\:bg-opacity-80:focus-within {
    --tw-bg-opacity: 0.8;
  }
  .\32xl\:focus-within\:bg-opacity-90:focus-within {
    --tw-bg-opacity: 0.9;
  }
  .\32xl\:focus-within\:bg-opacity-95:focus-within {
    --tw-bg-opacity: 0.95;
  }
  .\32xl\:focus-within\:bg-opacity-100:focus-within {
    --tw-bg-opacity: 1;
  }
  .\32xl\:hover\:bg-opacity-0:hover {
    --tw-bg-opacity: 0;
  }
  .\32xl\:hover\:bg-opacity-5:hover {
    --tw-bg-opacity: 0.05;
  }
  .\32xl\:hover\:bg-opacity-10:hover {
    --tw-bg-opacity: 0.1;
  }
  .\32xl\:hover\:bg-opacity-20:hover {
    --tw-bg-opacity: 0.2;
  }
  .\32xl\:hover\:bg-opacity-25:hover {
    --tw-bg-opacity: 0.25;
  }
  .\32xl\:hover\:bg-opacity-30:hover {
    --tw-bg-opacity: 0.3;
  }
  .\32xl\:hover\:bg-opacity-40:hover {
    --tw-bg-opacity: 0.4;
  }
  .\32xl\:hover\:bg-opacity-50:hover {
    --tw-bg-opacity: 0.5;
  }
  .\32xl\:hover\:bg-opacity-60:hover {
    --tw-bg-opacity: 0.6;
  }
  .\32xl\:hover\:bg-opacity-70:hover {
    --tw-bg-opacity: 0.7;
  }
  .\32xl\:hover\:bg-opacity-75:hover {
    --tw-bg-opacity: 0.75;
  }
  .\32xl\:hover\:bg-opacity-80:hover {
    --tw-bg-opacity: 0.8;
  }
  .\32xl\:hover\:bg-opacity-90:hover {
    --tw-bg-opacity: 0.9;
  }
  .\32xl\:hover\:bg-opacity-95:hover {
    --tw-bg-opacity: 0.95;
  }
  .\32xl\:hover\:bg-opacity-100:hover {
    --tw-bg-opacity: 1;
  }
  .\32xl\:focus\:bg-opacity-0:focus {
    --tw-bg-opacity: 0;
  }
  .\32xl\:focus\:bg-opacity-5:focus {
    --tw-bg-opacity: 0.05;
  }
  .\32xl\:focus\:bg-opacity-10:focus {
    --tw-bg-opacity: 0.1;
  }
  .\32xl\:focus\:bg-opacity-20:focus {
    --tw-bg-opacity: 0.2;
  }
  .\32xl\:focus\:bg-opacity-25:focus {
    --tw-bg-opacity: 0.25;
  }
  .\32xl\:focus\:bg-opacity-30:focus {
    --tw-bg-opacity: 0.3;
  }
  .\32xl\:focus\:bg-opacity-40:focus {
    --tw-bg-opacity: 0.4;
  }
  .\32xl\:focus\:bg-opacity-50:focus {
    --tw-bg-opacity: 0.5;
  }
  .\32xl\:focus\:bg-opacity-60:focus {
    --tw-bg-opacity: 0.6;
  }
  .\32xl\:focus\:bg-opacity-70:focus {
    --tw-bg-opacity: 0.7;
  }
  .\32xl\:focus\:bg-opacity-75:focus {
    --tw-bg-opacity: 0.75;
  }
  .\32xl\:focus\:bg-opacity-80:focus {
    --tw-bg-opacity: 0.8;
  }
  .\32xl\:focus\:bg-opacity-90:focus {
    --tw-bg-opacity: 0.9;
  }
  .\32xl\:focus\:bg-opacity-95:focus {
    --tw-bg-opacity: 0.95;
  }
  .\32xl\:focus\:bg-opacity-100:focus {
    --tw-bg-opacity: 1;
  }
  .\32xl\:bg-none {
    background-image: none;
  }
  .\32xl\:bg-gradient-to-t {
    background-image: linear-gradient(to top, var(--tw-gradient-stops));
  }
  .\32xl\:bg-gradient-to-tr {
    background-image: linear-gradient(to top right, var(--tw-gradient-stops));
  }
  .\32xl\:bg-gradient-to-r {
    background-image: linear-gradient(to right, var(--tw-gradient-stops));
  }
  .\32xl\:bg-gradient-to-br {
    background-image: linear-gradient(
      to bottom right,
      var(--tw-gradient-stops)
    );
  }
  .\32xl\:bg-gradient-to-b {
    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  }
  .\32xl\:bg-gradient-to-bl {
    background-image: linear-gradient(to bottom left, var(--tw-gradient-stops));
  }
  .\32xl\:bg-gradient-to-l {
    background-image: linear-gradient(to left, var(--tw-gradient-stops));
  }
  .\32xl\:bg-gradient-to-tl {
    background-image: linear-gradient(to top left, var(--tw-gradient-stops));
  }
  .\32xl\:from-transparent {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .\32xl\:from-current {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .\32xl\:from-black {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .\32xl\:from-white {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .\32xl\:from-gray-50 {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .\32xl\:from-gray-100 {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .\32xl\:from-gray-200 {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:from-gray-300 {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .\32xl\:from-gray-400 {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .\32xl\:from-gray-500 {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .\32xl\:from-gray-600 {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .\32xl\:from-gray-700 {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .\32xl\:from-gray-800 {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .\32xl\:from-gray-900 {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .\32xl\:from-red-50 {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .\32xl\:from-red-100 {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .\32xl\:from-red-200 {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .\32xl\:from-red-300 {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .\32xl\:from-red-400 {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .\32xl\:from-red-500 {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .\32xl\:from-red-600 {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .\32xl\:from-red-700 {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .\32xl\:from-red-800 {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .\32xl\:from-red-900 {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .\32xl\:from-yellow-50 {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .\32xl\:from-yellow-100 {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .\32xl\:from-yellow-200 {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .\32xl\:from-yellow-300 {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .\32xl\:from-yellow-400 {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .\32xl\:from-yellow-500 {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .\32xl\:from-yellow-600 {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .\32xl\:from-yellow-700 {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .\32xl\:from-yellow-800 {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .\32xl\:from-yellow-900 {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .\32xl\:from-green-50 {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .\32xl\:from-green-100 {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .\32xl\:from-green-200 {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .\32xl\:from-green-300 {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .\32xl\:from-green-400 {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .\32xl\:from-green-500 {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .\32xl\:from-green-600 {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .\32xl\:from-green-700 {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .\32xl\:from-green-800 {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .\32xl\:from-green-900 {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .\32xl\:from-blue-50 {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .\32xl\:from-blue-100 {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .\32xl\:from-blue-200 {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .\32xl\:from-blue-300 {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .\32xl\:from-blue-400 {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .\32xl\:from-blue-500 {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .\32xl\:from-blue-600 {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .\32xl\:from-blue-700 {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .\32xl\:from-blue-800 {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .\32xl\:from-blue-900 {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .\32xl\:from-indigo-50 {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .\32xl\:from-indigo-100 {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .\32xl\:from-indigo-200 {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .\32xl\:from-indigo-300 {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .\32xl\:from-indigo-400 {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .\32xl\:from-indigo-500 {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .\32xl\:from-indigo-600 {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .\32xl\:from-indigo-700 {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .\32xl\:from-indigo-800 {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .\32xl\:from-indigo-900 {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .\32xl\:from-purple-50 {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .\32xl\:from-purple-100 {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .\32xl\:from-purple-200 {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .\32xl\:from-purple-300 {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .\32xl\:from-purple-400 {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .\32xl\:from-purple-500 {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .\32xl\:from-purple-600 {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .\32xl\:from-purple-700 {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .\32xl\:from-purple-800 {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .\32xl\:from-purple-900 {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .\32xl\:from-pink-50 {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .\32xl\:from-pink-100 {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .\32xl\:from-pink-200 {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .\32xl\:from-pink-300 {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .\32xl\:from-pink-400 {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .\32xl\:from-pink-500 {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .\32xl\:from-pink-600 {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .\32xl\:from-pink-700 {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .\32xl\:from-pink-800 {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .\32xl\:from-pink-900 {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .\32xl\:hover\:from-transparent:hover {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .\32xl\:hover\:from-current:hover {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .\32xl\:hover\:from-black:hover {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .\32xl\:hover\:from-white:hover {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .\32xl\:hover\:from-gray-50:hover {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .\32xl\:hover\:from-gray-100:hover {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .\32xl\:hover\:from-gray-200:hover {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:hover\:from-gray-300:hover {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .\32xl\:hover\:from-gray-400:hover {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .\32xl\:hover\:from-gray-500:hover {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .\32xl\:hover\:from-gray-600:hover {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .\32xl\:hover\:from-gray-700:hover {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .\32xl\:hover\:from-gray-800:hover {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .\32xl\:hover\:from-gray-900:hover {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .\32xl\:hover\:from-red-50:hover {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .\32xl\:hover\:from-red-100:hover {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .\32xl\:hover\:from-red-200:hover {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .\32xl\:hover\:from-red-300:hover {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .\32xl\:hover\:from-red-400:hover {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .\32xl\:hover\:from-red-500:hover {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .\32xl\:hover\:from-red-600:hover {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .\32xl\:hover\:from-red-700:hover {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .\32xl\:hover\:from-red-800:hover {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .\32xl\:hover\:from-red-900:hover {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .\32xl\:hover\:from-yellow-50:hover {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .\32xl\:hover\:from-yellow-100:hover {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .\32xl\:hover\:from-yellow-200:hover {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .\32xl\:hover\:from-yellow-300:hover {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .\32xl\:hover\:from-yellow-400:hover {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .\32xl\:hover\:from-yellow-500:hover {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .\32xl\:hover\:from-yellow-600:hover {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .\32xl\:hover\:from-yellow-700:hover {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .\32xl\:hover\:from-yellow-800:hover {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .\32xl\:hover\:from-yellow-900:hover {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .\32xl\:hover\:from-green-50:hover {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .\32xl\:hover\:from-green-100:hover {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .\32xl\:hover\:from-green-200:hover {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .\32xl\:hover\:from-green-300:hover {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .\32xl\:hover\:from-green-400:hover {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .\32xl\:hover\:from-green-500:hover {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .\32xl\:hover\:from-green-600:hover {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .\32xl\:hover\:from-green-700:hover {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .\32xl\:hover\:from-green-800:hover {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .\32xl\:hover\:from-green-900:hover {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .\32xl\:hover\:from-blue-50:hover {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .\32xl\:hover\:from-blue-100:hover {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .\32xl\:hover\:from-blue-200:hover {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .\32xl\:hover\:from-blue-300:hover {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .\32xl\:hover\:from-blue-400:hover {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .\32xl\:hover\:from-blue-500:hover {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .\32xl\:hover\:from-blue-600:hover {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .\32xl\:hover\:from-blue-700:hover {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .\32xl\:hover\:from-blue-800:hover {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .\32xl\:hover\:from-blue-900:hover {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .\32xl\:hover\:from-indigo-50:hover {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .\32xl\:hover\:from-indigo-100:hover {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .\32xl\:hover\:from-indigo-200:hover {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .\32xl\:hover\:from-indigo-300:hover {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .\32xl\:hover\:from-indigo-400:hover {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .\32xl\:hover\:from-indigo-500:hover {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .\32xl\:hover\:from-indigo-600:hover {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .\32xl\:hover\:from-indigo-700:hover {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .\32xl\:hover\:from-indigo-800:hover {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .\32xl\:hover\:from-indigo-900:hover {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .\32xl\:hover\:from-purple-50:hover {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .\32xl\:hover\:from-purple-100:hover {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .\32xl\:hover\:from-purple-200:hover {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .\32xl\:hover\:from-purple-300:hover {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .\32xl\:hover\:from-purple-400:hover {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .\32xl\:hover\:from-purple-500:hover {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .\32xl\:hover\:from-purple-600:hover {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .\32xl\:hover\:from-purple-700:hover {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .\32xl\:hover\:from-purple-800:hover {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .\32xl\:hover\:from-purple-900:hover {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .\32xl\:hover\:from-pink-50:hover {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .\32xl\:hover\:from-pink-100:hover {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .\32xl\:hover\:from-pink-200:hover {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .\32xl\:hover\:from-pink-300:hover {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .\32xl\:hover\:from-pink-400:hover {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .\32xl\:hover\:from-pink-500:hover {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .\32xl\:hover\:from-pink-600:hover {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .\32xl\:hover\:from-pink-700:hover {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .\32xl\:hover\:from-pink-800:hover {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .\32xl\:hover\:from-pink-900:hover {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .\32xl\:focus\:from-transparent:focus {
    --tw-gradient-from: transparent;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .\32xl\:focus\:from-current:focus {
    --tw-gradient-from: currentColor;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .\32xl\:focus\:from-black:focus {
    --tw-gradient-from: #000;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .\32xl\:focus\:from-white:focus {
    --tw-gradient-from: #fff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .\32xl\:focus\:from-gray-50:focus {
    --tw-gradient-from: #f9fafb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .\32xl\:focus\:from-gray-100:focus {
    --tw-gradient-from: #f3f4f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .\32xl\:focus\:from-gray-200:focus {
    --tw-gradient-from: #e5e7eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:focus\:from-gray-300:focus {
    --tw-gradient-from: #d1d5db;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .\32xl\:focus\:from-gray-400:focus {
    --tw-gradient-from: #9ca3af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .\32xl\:focus\:from-gray-500:focus {
    --tw-gradient-from: #6b7280;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .\32xl\:focus\:from-gray-600:focus {
    --tw-gradient-from: #4b5563;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .\32xl\:focus\:from-gray-700:focus {
    --tw-gradient-from: #374151;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .\32xl\:focus\:from-gray-800:focus {
    --tw-gradient-from: #1f2937;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .\32xl\:focus\:from-gray-900:focus {
    --tw-gradient-from: #111827;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .\32xl\:focus\:from-red-50:focus {
    --tw-gradient-from: #fef2f2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .\32xl\:focus\:from-red-100:focus {
    --tw-gradient-from: #fee2e2;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .\32xl\:focus\:from-red-200:focus {
    --tw-gradient-from: #fecaca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .\32xl\:focus\:from-red-300:focus {
    --tw-gradient-from: #fca5a5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .\32xl\:focus\:from-red-400:focus {
    --tw-gradient-from: #f87171;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .\32xl\:focus\:from-red-500:focus {
    --tw-gradient-from: #ef4444;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .\32xl\:focus\:from-red-600:focus {
    --tw-gradient-from: #dc2626;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .\32xl\:focus\:from-red-700:focus {
    --tw-gradient-from: #b91c1c;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .\32xl\:focus\:from-red-800:focus {
    --tw-gradient-from: #991b1b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .\32xl\:focus\:from-red-900:focus {
    --tw-gradient-from: #7f1d1d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .\32xl\:focus\:from-yellow-50:focus {
    --tw-gradient-from: #fffbeb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .\32xl\:focus\:from-yellow-100:focus {
    --tw-gradient-from: #fef3c7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .\32xl\:focus\:from-yellow-200:focus {
    --tw-gradient-from: #fde68a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .\32xl\:focus\:from-yellow-300:focus {
    --tw-gradient-from: #fcd34d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .\32xl\:focus\:from-yellow-400:focus {
    --tw-gradient-from: #fbbf24;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .\32xl\:focus\:from-yellow-500:focus {
    --tw-gradient-from: #f59e0b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .\32xl\:focus\:from-yellow-600:focus {
    --tw-gradient-from: #d97706;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .\32xl\:focus\:from-yellow-700:focus {
    --tw-gradient-from: #b45309;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .\32xl\:focus\:from-yellow-800:focus {
    --tw-gradient-from: #92400e;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .\32xl\:focus\:from-yellow-900:focus {
    --tw-gradient-from: #78350f;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .\32xl\:focus\:from-green-50:focus {
    --tw-gradient-from: #ecfdf5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .\32xl\:focus\:from-green-100:focus {
    --tw-gradient-from: #d1fae5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .\32xl\:focus\:from-green-200:focus {
    --tw-gradient-from: #a7f3d0;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .\32xl\:focus\:from-green-300:focus {
    --tw-gradient-from: #6ee7b7;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .\32xl\:focus\:from-green-400:focus {
    --tw-gradient-from: #34d399;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .\32xl\:focus\:from-green-500:focus {
    --tw-gradient-from: #10b981;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .\32xl\:focus\:from-green-600:focus {
    --tw-gradient-from: #059669;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .\32xl\:focus\:from-green-700:focus {
    --tw-gradient-from: #047857;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .\32xl\:focus\:from-green-800:focus {
    --tw-gradient-from: #065f46;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .\32xl\:focus\:from-green-900:focus {
    --tw-gradient-from: #064e3b;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .\32xl\:focus\:from-blue-50:focus {
    --tw-gradient-from: #eff6ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .\32xl\:focus\:from-blue-100:focus {
    --tw-gradient-from: #dbeafe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .\32xl\:focus\:from-blue-200:focus {
    --tw-gradient-from: #bfdbfe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .\32xl\:focus\:from-blue-300:focus {
    --tw-gradient-from: #93c5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .\32xl\:focus\:from-blue-400:focus {
    --tw-gradient-from: #60a5fa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .\32xl\:focus\:from-blue-500:focus {
    --tw-gradient-from: #3b82f6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .\32xl\:focus\:from-blue-600:focus {
    --tw-gradient-from: #2563eb;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .\32xl\:focus\:from-blue-700:focus {
    --tw-gradient-from: #1d4ed8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .\32xl\:focus\:from-blue-800:focus {
    --tw-gradient-from: #1e40af;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .\32xl\:focus\:from-blue-900:focus {
    --tw-gradient-from: #1e3a8a;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .\32xl\:focus\:from-indigo-50:focus {
    --tw-gradient-from: #eef2ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .\32xl\:focus\:from-indigo-100:focus {
    --tw-gradient-from: #e0e7ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .\32xl\:focus\:from-indigo-200:focus {
    --tw-gradient-from: #c7d2fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .\32xl\:focus\:from-indigo-300:focus {
    --tw-gradient-from: #a5b4fc;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .\32xl\:focus\:from-indigo-400:focus {
    --tw-gradient-from: #818cf8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .\32xl\:focus\:from-indigo-500:focus {
    --tw-gradient-from: #6366f1;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .\32xl\:focus\:from-indigo-600:focus {
    --tw-gradient-from: #4f46e5;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .\32xl\:focus\:from-indigo-700:focus {
    --tw-gradient-from: #4338ca;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .\32xl\:focus\:from-indigo-800:focus {
    --tw-gradient-from: #3730a3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .\32xl\:focus\:from-indigo-900:focus {
    --tw-gradient-from: #312e81;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .\32xl\:focus\:from-purple-50:focus {
    --tw-gradient-from: #f5f3ff;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .\32xl\:focus\:from-purple-100:focus {
    --tw-gradient-from: #ede9fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .\32xl\:focus\:from-purple-200:focus {
    --tw-gradient-from: #ddd6fe;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .\32xl\:focus\:from-purple-300:focus {
    --tw-gradient-from: #c4b5fd;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .\32xl\:focus\:from-purple-400:focus {
    --tw-gradient-from: #a78bfa;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .\32xl\:focus\:from-purple-500:focus {
    --tw-gradient-from: #8b5cf6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .\32xl\:focus\:from-purple-600:focus {
    --tw-gradient-from: #7c3aed;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .\32xl\:focus\:from-purple-700:focus {
    --tw-gradient-from: #6d28d9;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .\32xl\:focus\:from-purple-800:focus {
    --tw-gradient-from: #5b21b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .\32xl\:focus\:from-purple-900:focus {
    --tw-gradient-from: #4c1d95;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .\32xl\:focus\:from-pink-50:focus {
    --tw-gradient-from: #fdf2f8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .\32xl\:focus\:from-pink-100:focus {
    --tw-gradient-from: #fce7f3;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .\32xl\:focus\:from-pink-200:focus {
    --tw-gradient-from: #fbcfe8;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .\32xl\:focus\:from-pink-300:focus {
    --tw-gradient-from: #f9a8d4;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .\32xl\:focus\:from-pink-400:focus {
    --tw-gradient-from: #f472b6;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .\32xl\:focus\:from-pink-500:focus {
    --tw-gradient-from: #ec4899;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .\32xl\:focus\:from-pink-600:focus {
    --tw-gradient-from: #db2777;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .\32xl\:focus\:from-pink-700:focus {
    --tw-gradient-from: #be185d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .\32xl\:focus\:from-pink-800:focus {
    --tw-gradient-from: #9d174d;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .\32xl\:focus\:from-pink-900:focus {
    --tw-gradient-from: #831843;
    --tw-gradient-stops: var(--tw-gradient-from),
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .\32xl\:via-transparent {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .\32xl\:via-current {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .\32xl\:via-black {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .\32xl\:via-white {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .\32xl\:via-gray-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .\32xl\:via-gray-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .\32xl\:via-gray-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:via-gray-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .\32xl\:via-gray-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .\32xl\:via-gray-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .\32xl\:via-gray-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .\32xl\:via-gray-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .\32xl\:via-gray-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .\32xl\:via-gray-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #111827,
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .\32xl\:via-red-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .\32xl\:via-red-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .\32xl\:via-red-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .\32xl\:via-red-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .\32xl\:via-red-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .\32xl\:via-red-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .\32xl\:via-red-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .\32xl\:via-red-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .\32xl\:via-red-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .\32xl\:via-red-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .\32xl\:via-yellow-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .\32xl\:via-yellow-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .\32xl\:via-yellow-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .\32xl\:via-yellow-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .\32xl\:via-yellow-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .\32xl\:via-yellow-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .\32xl\:via-yellow-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .\32xl\:via-yellow-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .\32xl\:via-yellow-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .\32xl\:via-yellow-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .\32xl\:via-green-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .\32xl\:via-green-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .\32xl\:via-green-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .\32xl\:via-green-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .\32xl\:via-green-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .\32xl\:via-green-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .\32xl\:via-green-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .\32xl\:via-green-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .\32xl\:via-green-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .\32xl\:via-green-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .\32xl\:via-blue-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .\32xl\:via-blue-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .\32xl\:via-blue-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .\32xl\:via-blue-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .\32xl\:via-blue-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .\32xl\:via-blue-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .\32xl\:via-blue-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .\32xl\:via-blue-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .\32xl\:via-blue-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .\32xl\:via-blue-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .\32xl\:via-indigo-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .\32xl\:via-indigo-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .\32xl\:via-indigo-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .\32xl\:via-indigo-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .\32xl\:via-indigo-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .\32xl\:via-indigo-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .\32xl\:via-indigo-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .\32xl\:via-indigo-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .\32xl\:via-indigo-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .\32xl\:via-indigo-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .\32xl\:via-purple-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .\32xl\:via-purple-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .\32xl\:via-purple-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .\32xl\:via-purple-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .\32xl\:via-purple-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .\32xl\:via-purple-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .\32xl\:via-purple-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .\32xl\:via-purple-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .\32xl\:via-purple-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .\32xl\:via-purple-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .\32xl\:via-pink-50 {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .\32xl\:via-pink-100 {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .\32xl\:via-pink-200 {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .\32xl\:via-pink-300 {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .\32xl\:via-pink-400 {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .\32xl\:via-pink-500 {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .\32xl\:via-pink-600 {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .\32xl\:via-pink-700 {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .\32xl\:via-pink-800 {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .\32xl\:via-pink-900 {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .\32xl\:hover\:via-transparent:hover {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .\32xl\:hover\:via-current:hover {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .\32xl\:hover\:via-black:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .\32xl\:hover\:via-white:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .\32xl\:hover\:via-gray-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .\32xl\:hover\:via-gray-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .\32xl\:hover\:via-gray-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:hover\:via-gray-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .\32xl\:hover\:via-gray-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .\32xl\:hover\:via-gray-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .\32xl\:hover\:via-gray-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .\32xl\:hover\:via-gray-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .\32xl\:hover\:via-gray-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .\32xl\:hover\:via-gray-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #111827,
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .\32xl\:hover\:via-red-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .\32xl\:hover\:via-red-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .\32xl\:hover\:via-red-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .\32xl\:hover\:via-red-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .\32xl\:hover\:via-red-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .\32xl\:hover\:via-red-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .\32xl\:hover\:via-red-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .\32xl\:hover\:via-red-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .\32xl\:hover\:via-red-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .\32xl\:hover\:via-red-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .\32xl\:hover\:via-yellow-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .\32xl\:hover\:via-yellow-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .\32xl\:hover\:via-yellow-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .\32xl\:hover\:via-yellow-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .\32xl\:hover\:via-yellow-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .\32xl\:hover\:via-yellow-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .\32xl\:hover\:via-yellow-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .\32xl\:hover\:via-yellow-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .\32xl\:hover\:via-yellow-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .\32xl\:hover\:via-yellow-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .\32xl\:hover\:via-green-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .\32xl\:hover\:via-green-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .\32xl\:hover\:via-green-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .\32xl\:hover\:via-green-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .\32xl\:hover\:via-green-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .\32xl\:hover\:via-green-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .\32xl\:hover\:via-green-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .\32xl\:hover\:via-green-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .\32xl\:hover\:via-green-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .\32xl\:hover\:via-green-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .\32xl\:hover\:via-blue-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .\32xl\:hover\:via-blue-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .\32xl\:hover\:via-blue-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .\32xl\:hover\:via-blue-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .\32xl\:hover\:via-blue-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .\32xl\:hover\:via-blue-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .\32xl\:hover\:via-blue-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .\32xl\:hover\:via-blue-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .\32xl\:hover\:via-blue-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .\32xl\:hover\:via-blue-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .\32xl\:hover\:via-indigo-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .\32xl\:hover\:via-indigo-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .\32xl\:hover\:via-indigo-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .\32xl\:hover\:via-indigo-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .\32xl\:hover\:via-indigo-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .\32xl\:hover\:via-indigo-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .\32xl\:hover\:via-indigo-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .\32xl\:hover\:via-indigo-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .\32xl\:hover\:via-indigo-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .\32xl\:hover\:via-indigo-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .\32xl\:hover\:via-purple-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .\32xl\:hover\:via-purple-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .\32xl\:hover\:via-purple-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .\32xl\:hover\:via-purple-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .\32xl\:hover\:via-purple-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .\32xl\:hover\:via-purple-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .\32xl\:hover\:via-purple-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .\32xl\:hover\:via-purple-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .\32xl\:hover\:via-purple-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .\32xl\:hover\:via-purple-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .\32xl\:hover\:via-pink-50:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .\32xl\:hover\:via-pink-100:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .\32xl\:hover\:via-pink-200:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .\32xl\:hover\:via-pink-300:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .\32xl\:hover\:via-pink-400:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .\32xl\:hover\:via-pink-500:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .\32xl\:hover\:via-pink-600:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .\32xl\:hover\:via-pink-700:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .\32xl\:hover\:via-pink-800:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .\32xl\:hover\:via-pink-900:hover {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .\32xl\:focus\:via-transparent:focus {
    --tw-gradient-stops: var(--tw-gradient-from), transparent,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .\32xl\:focus\:via-current:focus {
    --tw-gradient-stops: var(--tw-gradient-from), currentColor,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .\32xl\:focus\:via-black:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #000,
      var(--tw-gradient-to, rgba(0, 0, 0, 0));
  }
  .\32xl\:focus\:via-white:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fff,
      var(--tw-gradient-to, rgba(255, 255, 255, 0));
  }
  .\32xl\:focus\:via-gray-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9fafb,
      var(--tw-gradient-to, rgba(249, 250, 251, 0));
  }
  .\32xl\:focus\:via-gray-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6,
      var(--tw-gradient-to, rgba(243, 244, 246, 0));
  }
  .\32xl\:focus\:via-gray-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb,
      var(--tw-gradient-to, rgba(229, 231, 235, 0));
  }
  .\32xl\:focus\:via-gray-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1d5db,
      var(--tw-gradient-to, rgba(209, 213, 219, 0));
  }
  .\32xl\:focus\:via-gray-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9ca3af,
      var(--tw-gradient-to, rgba(156, 163, 175, 0));
  }
  .\32xl\:focus\:via-gray-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6b7280,
      var(--tw-gradient-to, rgba(107, 114, 128, 0));
  }
  .\32xl\:focus\:via-gray-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4b5563,
      var(--tw-gradient-to, rgba(75, 85, 99, 0));
  }
  .\32xl\:focus\:via-gray-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #374151,
      var(--tw-gradient-to, rgba(55, 65, 81, 0));
  }
  .\32xl\:focus\:via-gray-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1f2937,
      var(--tw-gradient-to, rgba(31, 41, 55, 0));
  }
  .\32xl\:focus\:via-gray-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #111827,
      var(--tw-gradient-to, rgba(17, 24, 39, 0));
  }
  .\32xl\:focus\:via-red-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef2f2,
      var(--tw-gradient-to, rgba(254, 242, 242, 0));
  }
  .\32xl\:focus\:via-red-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fee2e2,
      var(--tw-gradient-to, rgba(254, 226, 226, 0));
  }
  .\32xl\:focus\:via-red-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fecaca,
      var(--tw-gradient-to, rgba(254, 202, 202, 0));
  }
  .\32xl\:focus\:via-red-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fca5a5,
      var(--tw-gradient-to, rgba(252, 165, 165, 0));
  }
  .\32xl\:focus\:via-red-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f87171,
      var(--tw-gradient-to, rgba(248, 113, 113, 0));
  }
  .\32xl\:focus\:via-red-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ef4444,
      var(--tw-gradient-to, rgba(239, 68, 68, 0));
  }
  .\32xl\:focus\:via-red-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dc2626,
      var(--tw-gradient-to, rgba(220, 38, 38, 0));
  }
  .\32xl\:focus\:via-red-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b91c1c,
      var(--tw-gradient-to, rgba(185, 28, 28, 0));
  }
  .\32xl\:focus\:via-red-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #991b1b,
      var(--tw-gradient-to, rgba(153, 27, 27, 0));
  }
  .\32xl\:focus\:via-red-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d,
      var(--tw-gradient-to, rgba(127, 29, 29, 0));
  }
  .\32xl\:focus\:via-yellow-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fffbeb,
      var(--tw-gradient-to, rgba(255, 251, 235, 0));
  }
  .\32xl\:focus\:via-yellow-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fef3c7,
      var(--tw-gradient-to, rgba(254, 243, 199, 0));
  }
  .\32xl\:focus\:via-yellow-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fde68a,
      var(--tw-gradient-to, rgba(253, 230, 138, 0));
  }
  .\32xl\:focus\:via-yellow-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fcd34d,
      var(--tw-gradient-to, rgba(252, 211, 77, 0));
  }
  .\32xl\:focus\:via-yellow-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbbf24,
      var(--tw-gradient-to, rgba(251, 191, 36, 0));
  }
  .\32xl\:focus\:via-yellow-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f59e0b,
      var(--tw-gradient-to, rgba(245, 158, 11, 0));
  }
  .\32xl\:focus\:via-yellow-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d97706,
      var(--tw-gradient-to, rgba(217, 119, 6, 0));
  }
  .\32xl\:focus\:via-yellow-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #b45309,
      var(--tw-gradient-to, rgba(180, 83, 9, 0));
  }
  .\32xl\:focus\:via-yellow-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #92400e,
      var(--tw-gradient-to, rgba(146, 64, 14, 0));
  }
  .\32xl\:focus\:via-yellow-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #78350f,
      var(--tw-gradient-to, rgba(120, 53, 15, 0));
  }
  .\32xl\:focus\:via-green-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5,
      var(--tw-gradient-to, rgba(236, 253, 245, 0));
  }
  .\32xl\:focus\:via-green-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #d1fae5,
      var(--tw-gradient-to, rgba(209, 250, 229, 0));
  }
  .\32xl\:focus\:via-green-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0,
      var(--tw-gradient-to, rgba(167, 243, 208, 0));
  }
  .\32xl\:focus\:via-green-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7,
      var(--tw-gradient-to, rgba(110, 231, 183, 0));
  }
  .\32xl\:focus\:via-green-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #34d399,
      var(--tw-gradient-to, rgba(52, 211, 153, 0));
  }
  .\32xl\:focus\:via-green-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #10b981,
      var(--tw-gradient-to, rgba(16, 185, 129, 0));
  }
  .\32xl\:focus\:via-green-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #059669,
      var(--tw-gradient-to, rgba(5, 150, 105, 0));
  }
  .\32xl\:focus\:via-green-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #047857,
      var(--tw-gradient-to, rgba(4, 120, 87, 0));
  }
  .\32xl\:focus\:via-green-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #065f46,
      var(--tw-gradient-to, rgba(6, 95, 70, 0));
  }
  .\32xl\:focus\:via-green-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #064e3b,
      var(--tw-gradient-to, rgba(6, 78, 59, 0));
  }
  .\32xl\:focus\:via-blue-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eff6ff,
      var(--tw-gradient-to, rgba(239, 246, 255, 0));
  }
  .\32xl\:focus\:via-blue-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #dbeafe,
      var(--tw-gradient-to, rgba(219, 234, 254, 0));
  }
  .\32xl\:focus\:via-blue-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe,
      var(--tw-gradient-to, rgba(191, 219, 254, 0));
  }
  .\32xl\:focus\:via-blue-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #93c5fd,
      var(--tw-gradient-to, rgba(147, 197, 253, 0));
  }
  .\32xl\:focus\:via-blue-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #60a5fa,
      var(--tw-gradient-to, rgba(96, 165, 250, 0));
  }
  .\32xl\:focus\:via-blue-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3b82f6,
      var(--tw-gradient-to, rgba(59, 130, 246, 0));
  }
  .\32xl\:focus\:via-blue-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #2563eb,
      var(--tw-gradient-to, rgba(37, 99, 235, 0));
  }
  .\32xl\:focus\:via-blue-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8,
      var(--tw-gradient-to, rgba(29, 78, 216, 0));
  }
  .\32xl\:focus\:via-blue-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e40af,
      var(--tw-gradient-to, rgba(30, 64, 175, 0));
  }
  .\32xl\:focus\:via-blue-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a,
      var(--tw-gradient-to, rgba(30, 58, 138, 0));
  }
  .\32xl\:focus\:via-indigo-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #eef2ff,
      var(--tw-gradient-to, rgba(238, 242, 255, 0));
  }
  .\32xl\:focus\:via-indigo-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff,
      var(--tw-gradient-to, rgba(224, 231, 255, 0));
  }
  .\32xl\:focus\:via-indigo-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe,
      var(--tw-gradient-to, rgba(199, 210, 254, 0));
  }
  .\32xl\:focus\:via-indigo-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc,
      var(--tw-gradient-to, rgba(165, 180, 252, 0));
  }
  .\32xl\:focus\:via-indigo-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #818cf8,
      var(--tw-gradient-to, rgba(129, 140, 248, 0));
  }
  .\32xl\:focus\:via-indigo-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6366f1,
      var(--tw-gradient-to, rgba(99, 102, 241, 0));
  }
  .\32xl\:focus\:via-indigo-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4f46e5,
      var(--tw-gradient-to, rgba(79, 70, 229, 0));
  }
  .\32xl\:focus\:via-indigo-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4338ca,
      var(--tw-gradient-to, rgba(67, 56, 202, 0));
  }
  .\32xl\:focus\:via-indigo-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #3730a3,
      var(--tw-gradient-to, rgba(55, 48, 163, 0));
  }
  .\32xl\:focus\:via-indigo-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #312e81,
      var(--tw-gradient-to, rgba(49, 46, 129, 0));
  }
  .\32xl\:focus\:via-purple-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff,
      var(--tw-gradient-to, rgba(245, 243, 255, 0));
  }
  .\32xl\:focus\:via-purple-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ede9fe,
      var(--tw-gradient-to, rgba(237, 233, 254, 0));
  }
  .\32xl\:focus\:via-purple-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe,
      var(--tw-gradient-to, rgba(221, 214, 254, 0));
  }
  .\32xl\:focus\:via-purple-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd,
      var(--tw-gradient-to, rgba(196, 181, 253, 0));
  }
  .\32xl\:focus\:via-purple-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #a78bfa,
      var(--tw-gradient-to, rgba(167, 139, 250, 0));
  }
  .\32xl\:focus\:via-purple-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6,
      var(--tw-gradient-to, rgba(139, 92, 246, 0));
  }
  .\32xl\:focus\:via-purple-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #7c3aed,
      var(--tw-gradient-to, rgba(124, 58, 237, 0));
  }
  .\32xl\:focus\:via-purple-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #6d28d9,
      var(--tw-gradient-to, rgba(109, 40, 217, 0));
  }
  .\32xl\:focus\:via-purple-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #5b21b6,
      var(--tw-gradient-to, rgba(91, 33, 182, 0));
  }
  .\32xl\:focus\:via-purple-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #4c1d95,
      var(--tw-gradient-to, rgba(76, 29, 149, 0));
  }
  .\32xl\:focus\:via-pink-50:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8,
      var(--tw-gradient-to, rgba(253, 242, 248, 0));
  }
  .\32xl\:focus\:via-pink-100:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fce7f3,
      var(--tw-gradient-to, rgba(252, 231, 243, 0));
  }
  .\32xl\:focus\:via-pink-200:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8,
      var(--tw-gradient-to, rgba(251, 207, 232, 0));
  }
  .\32xl\:focus\:via-pink-300:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4,
      var(--tw-gradient-to, rgba(249, 168, 212, 0));
  }
  .\32xl\:focus\:via-pink-400:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #f472b6,
      var(--tw-gradient-to, rgba(244, 114, 182, 0));
  }
  .\32xl\:focus\:via-pink-500:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #ec4899,
      var(--tw-gradient-to, rgba(236, 72, 153, 0));
  }
  .\32xl\:focus\:via-pink-600:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #db2777,
      var(--tw-gradient-to, rgba(219, 39, 119, 0));
  }
  .\32xl\:focus\:via-pink-700:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #be185d,
      var(--tw-gradient-to, rgba(190, 24, 93, 0));
  }
  .\32xl\:focus\:via-pink-800:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #9d174d,
      var(--tw-gradient-to, rgba(157, 23, 77, 0));
  }
  .\32xl\:focus\:via-pink-900:focus {
    --tw-gradient-stops: var(--tw-gradient-from), #831843,
      var(--tw-gradient-to, rgba(131, 24, 67, 0));
  }
  .\32xl\:to-transparent {
    --tw-gradient-to: transparent;
  }
  .\32xl\:to-current {
    --tw-gradient-to: currentColor;
  }
  .\32xl\:to-black {
    --tw-gradient-to: #000;
  }
  .\32xl\:to-white {
    --tw-gradient-to: #fff;
  }
  .\32xl\:to-gray-50 {
    --tw-gradient-to: #f9fafb;
  }
  .\32xl\:to-gray-100 {
    --tw-gradient-to: #f3f4f6;
  }
  .\32xl\:to-gray-200 {
    --tw-gradient-to: #e5e7eb;
  }
  .\32xl\:to-gray-300 {
    --tw-gradient-to: #d1d5db;
  }
  .\32xl\:to-gray-400 {
    --tw-gradient-to: #9ca3af;
  }
  .\32xl\:to-gray-500 {
    --tw-gradient-to: #6b7280;
  }
  .\32xl\:to-gray-600 {
    --tw-gradient-to: #4b5563;
  }
  .\32xl\:to-gray-700 {
    --tw-gradient-to: #374151;
  }
  .\32xl\:to-gray-800 {
    --tw-gradient-to: #1f2937;
  }
  .\32xl\:to-gray-900 {
    --tw-gradient-to: #111827;
  }
  .\32xl\:to-red-50 {
    --tw-gradient-to: #fef2f2;
  }
  .\32xl\:to-red-100 {
    --tw-gradient-to: #fee2e2;
  }
  .\32xl\:to-red-200 {
    --tw-gradient-to: #fecaca;
  }
  .\32xl\:to-red-300 {
    --tw-gradient-to: #fca5a5;
  }
  .\32xl\:to-red-400 {
    --tw-gradient-to: #f87171;
  }
  .\32xl\:to-red-500 {
    --tw-gradient-to: #ef4444;
  }
  .\32xl\:to-red-600 {
    --tw-gradient-to: #dc2626;
  }
  .\32xl\:to-red-700 {
    --tw-gradient-to: #b91c1c;
  }
  .\32xl\:to-red-800 {
    --tw-gradient-to: #991b1b;
  }
  .\32xl\:to-red-900 {
    --tw-gradient-to: #7f1d1d;
  }
  .\32xl\:to-yellow-50 {
    --tw-gradient-to: #fffbeb;
  }
  .\32xl\:to-yellow-100 {
    --tw-gradient-to: #fef3c7;
  }
  .\32xl\:to-yellow-200 {
    --tw-gradient-to: #fde68a;
  }
  .\32xl\:to-yellow-300 {
    --tw-gradient-to: #fcd34d;
  }
  .\32xl\:to-yellow-400 {
    --tw-gradient-to: #fbbf24;
  }
  .\32xl\:to-yellow-500 {
    --tw-gradient-to: #f59e0b;
  }
  .\32xl\:to-yellow-600 {
    --tw-gradient-to: #d97706;
  }
  .\32xl\:to-yellow-700 {
    --tw-gradient-to: #b45309;
  }
  .\32xl\:to-yellow-800 {
    --tw-gradient-to: #92400e;
  }
  .\32xl\:to-yellow-900 {
    --tw-gradient-to: #78350f;
  }
  .\32xl\:to-green-50 {
    --tw-gradient-to: #ecfdf5;
  }
  .\32xl\:to-green-100 {
    --tw-gradient-to: #d1fae5;
  }
  .\32xl\:to-green-200 {
    --tw-gradient-to: #a7f3d0;
  }
  .\32xl\:to-green-300 {
    --tw-gradient-to: #6ee7b7;
  }
  .\32xl\:to-green-400 {
    --tw-gradient-to: #34d399;
  }
  .\32xl\:to-green-500 {
    --tw-gradient-to: #10b981;
  }
  .\32xl\:to-green-600 {
    --tw-gradient-to: #059669;
  }
  .\32xl\:to-green-700 {
    --tw-gradient-to: #047857;
  }
  .\32xl\:to-green-800 {
    --tw-gradient-to: #065f46;
  }
  .\32xl\:to-green-900 {
    --tw-gradient-to: #064e3b;
  }
  .\32xl\:to-blue-50 {
    --tw-gradient-to: #eff6ff;
  }
  .\32xl\:to-blue-100 {
    --tw-gradient-to: #dbeafe;
  }
  .\32xl\:to-blue-200 {
    --tw-gradient-to: #bfdbfe;
  }
  .\32xl\:to-blue-300 {
    --tw-gradient-to: #93c5fd;
  }
  .\32xl\:to-blue-400 {
    --tw-gradient-to: #60a5fa;
  }
  .\32xl\:to-blue-500 {
    --tw-gradient-to: #3b82f6;
  }
  .\32xl\:to-blue-600 {
    --tw-gradient-to: #2563eb;
  }
  .\32xl\:to-blue-700 {
    --tw-gradient-to: #1d4ed8;
  }
  .\32xl\:to-blue-800 {
    --tw-gradient-to: #1e40af;
  }
  .\32xl\:to-blue-900 {
    --tw-gradient-to: #1e3a8a;
  }
  .\32xl\:to-indigo-50 {
    --tw-gradient-to: #eef2ff;
  }
  .\32xl\:to-indigo-100 {
    --tw-gradient-to: #e0e7ff;
  }
  .\32xl\:to-indigo-200 {
    --tw-gradient-to: #c7d2fe;
  }
  .\32xl\:to-indigo-300 {
    --tw-gradient-to: #a5b4fc;
  }
  .\32xl\:to-indigo-400 {
    --tw-gradient-to: #818cf8;
  }
  .\32xl\:to-indigo-500 {
    --tw-gradient-to: #6366f1;
  }
  .\32xl\:to-indigo-600 {
    --tw-gradient-to: #4f46e5;
  }
  .\32xl\:to-indigo-700 {
    --tw-gradient-to: #4338ca;
  }
  .\32xl\:to-indigo-800 {
    --tw-gradient-to: #3730a3;
  }
  .\32xl\:to-indigo-900 {
    --tw-gradient-to: #312e81;
  }
  .\32xl\:to-purple-50 {
    --tw-gradient-to: #f5f3ff;
  }
  .\32xl\:to-purple-100 {
    --tw-gradient-to: #ede9fe;
  }
  .\32xl\:to-purple-200 {
    --tw-gradient-to: #ddd6fe;
  }
  .\32xl\:to-purple-300 {
    --tw-gradient-to: #c4b5fd;
  }
  .\32xl\:to-purple-400 {
    --tw-gradient-to: #a78bfa;
  }
  .\32xl\:to-purple-500 {
    --tw-gradient-to: #8b5cf6;
  }
  .\32xl\:to-purple-600 {
    --tw-gradient-to: #7c3aed;
  }
  .\32xl\:to-purple-700 {
    --tw-gradient-to: #6d28d9;
  }
  .\32xl\:to-purple-800 {
    --tw-gradient-to: #5b21b6;
  }
  .\32xl\:to-purple-900 {
    --tw-gradient-to: #4c1d95;
  }
  .\32xl\:to-pink-50 {
    --tw-gradient-to: #fdf2f8;
  }
  .\32xl\:to-pink-100 {
    --tw-gradient-to: #fce7f3;
  }
  .\32xl\:to-pink-200 {
    --tw-gradient-to: #fbcfe8;
  }
  .\32xl\:to-pink-300 {
    --tw-gradient-to: #f9a8d4;
  }
  .\32xl\:to-pink-400 {
    --tw-gradient-to: #f472b6;
  }
  .\32xl\:to-pink-500 {
    --tw-gradient-to: #ec4899;
  }
  .\32xl\:to-pink-600 {
    --tw-gradient-to: #db2777;
  }
  .\32xl\:to-pink-700 {
    --tw-gradient-to: #be185d;
  }
  .\32xl\:to-pink-800 {
    --tw-gradient-to: #9d174d;
  }
  .\32xl\:to-pink-900 {
    --tw-gradient-to: #831843;
  }
  .\32xl\:hover\:to-transparent:hover {
    --tw-gradient-to: transparent;
  }
  .\32xl\:hover\:to-current:hover {
    --tw-gradient-to: currentColor;
  }
  .\32xl\:hover\:to-black:hover {
    --tw-gradient-to: #000;
  }
  .\32xl\:hover\:to-white:hover {
    --tw-gradient-to: #fff;
  }
  .\32xl\:hover\:to-gray-50:hover {
    --tw-gradient-to: #f9fafb;
  }
  .\32xl\:hover\:to-gray-100:hover {
    --tw-gradient-to: #f3f4f6;
  }
  .\32xl\:hover\:to-gray-200:hover {
    --tw-gradient-to: #e5e7eb;
  }
  .\32xl\:hover\:to-gray-300:hover {
    --tw-gradient-to: #d1d5db;
  }
  .\32xl\:hover\:to-gray-400:hover {
    --tw-gradient-to: #9ca3af;
  }
  .\32xl\:hover\:to-gray-500:hover {
    --tw-gradient-to: #6b7280;
  }
  .\32xl\:hover\:to-gray-600:hover {
    --tw-gradient-to: #4b5563;
  }
  .\32xl\:hover\:to-gray-700:hover {
    --tw-gradient-to: #374151;
  }
  .\32xl\:hover\:to-gray-800:hover {
    --tw-gradient-to: #1f2937;
  }
  .\32xl\:hover\:to-gray-900:hover {
    --tw-gradient-to: #111827;
  }
  .\32xl\:hover\:to-red-50:hover {
    --tw-gradient-to: #fef2f2;
  }
  .\32xl\:hover\:to-red-100:hover {
    --tw-gradient-to: #fee2e2;
  }
  .\32xl\:hover\:to-red-200:hover {
    --tw-gradient-to: #fecaca;
  }
  .\32xl\:hover\:to-red-300:hover {
    --tw-gradient-to: #fca5a5;
  }
  .\32xl\:hover\:to-red-400:hover {
    --tw-gradient-to: #f87171;
  }
  .\32xl\:hover\:to-red-500:hover {
    --tw-gradient-to: #ef4444;
  }
  .\32xl\:hover\:to-red-600:hover {
    --tw-gradient-to: #dc2626;
  }
  .\32xl\:hover\:to-red-700:hover {
    --tw-gradient-to: #b91c1c;
  }
  .\32xl\:hover\:to-red-800:hover {
    --tw-gradient-to: #991b1b;
  }
  .\32xl\:hover\:to-red-900:hover {
    --tw-gradient-to: #7f1d1d;
  }
  .\32xl\:hover\:to-yellow-50:hover {
    --tw-gradient-to: #fffbeb;
  }
  .\32xl\:hover\:to-yellow-100:hover {
    --tw-gradient-to: #fef3c7;
  }
  .\32xl\:hover\:to-yellow-200:hover {
    --tw-gradient-to: #fde68a;
  }
  .\32xl\:hover\:to-yellow-300:hover {
    --tw-gradient-to: #fcd34d;
  }
  .\32xl\:hover\:to-yellow-400:hover {
    --tw-gradient-to: #fbbf24;
  }
  .\32xl\:hover\:to-yellow-500:hover {
    --tw-gradient-to: #f59e0b;
  }
  .\32xl\:hover\:to-yellow-600:hover {
    --tw-gradient-to: #d97706;
  }
  .\32xl\:hover\:to-yellow-700:hover {
    --tw-gradient-to: #b45309;
  }
  .\32xl\:hover\:to-yellow-800:hover {
    --tw-gradient-to: #92400e;
  }
  .\32xl\:hover\:to-yellow-900:hover {
    --tw-gradient-to: #78350f;
  }
  .\32xl\:hover\:to-green-50:hover {
    --tw-gradient-to: #ecfdf5;
  }
  .\32xl\:hover\:to-green-100:hover {
    --tw-gradient-to: #d1fae5;
  }
  .\32xl\:hover\:to-green-200:hover {
    --tw-gradient-to: #a7f3d0;
  }
  .\32xl\:hover\:to-green-300:hover {
    --tw-gradient-to: #6ee7b7;
  }
  .\32xl\:hover\:to-green-400:hover {
    --tw-gradient-to: #34d399;
  }
  .\32xl\:hover\:to-green-500:hover {
    --tw-gradient-to: #10b981;
  }
  .\32xl\:hover\:to-green-600:hover {
    --tw-gradient-to: #059669;
  }
  .\32xl\:hover\:to-green-700:hover {
    --tw-gradient-to: #047857;
  }
  .\32xl\:hover\:to-green-800:hover {
    --tw-gradient-to: #065f46;
  }
  .\32xl\:hover\:to-green-900:hover {
    --tw-gradient-to: #064e3b;
  }
  .\32xl\:hover\:to-blue-50:hover {
    --tw-gradient-to: #eff6ff;
  }
  .\32xl\:hover\:to-blue-100:hover {
    --tw-gradient-to: #dbeafe;
  }
  .\32xl\:hover\:to-blue-200:hover {
    --tw-gradient-to: #bfdbfe;
  }
  .\32xl\:hover\:to-blue-300:hover {
    --tw-gradient-to: #93c5fd;
  }
  .\32xl\:hover\:to-blue-400:hover {
    --tw-gradient-to: #60a5fa;
  }
  .\32xl\:hover\:to-blue-500:hover {
    --tw-gradient-to: #3b82f6;
  }
  .\32xl\:hover\:to-blue-600:hover {
    --tw-gradient-to: #2563eb;
  }
  .\32xl\:hover\:to-blue-700:hover {
    --tw-gradient-to: #1d4ed8;
  }
  .\32xl\:hover\:to-blue-800:hover {
    --tw-gradient-to: #1e40af;
  }
  .\32xl\:hover\:to-blue-900:hover {
    --tw-gradient-to: #1e3a8a;
  }
  .\32xl\:hover\:to-indigo-50:hover {
    --tw-gradient-to: #eef2ff;
  }
  .\32xl\:hover\:to-indigo-100:hover {
    --tw-gradient-to: #e0e7ff;
  }
  .\32xl\:hover\:to-indigo-200:hover {
    --tw-gradient-to: #c7d2fe;
  }
  .\32xl\:hover\:to-indigo-300:hover {
    --tw-gradient-to: #a5b4fc;
  }
  .\32xl\:hover\:to-indigo-400:hover {
    --tw-gradient-to: #818cf8;
  }
  .\32xl\:hover\:to-indigo-500:hover {
    --tw-gradient-to: #6366f1;
  }
  .\32xl\:hover\:to-indigo-600:hover {
    --tw-gradient-to: #4f46e5;
  }
  .\32xl\:hover\:to-indigo-700:hover {
    --tw-gradient-to: #4338ca;
  }
  .\32xl\:hover\:to-indigo-800:hover {
    --tw-gradient-to: #3730a3;
  }
  .\32xl\:hover\:to-indigo-900:hover {
    --tw-gradient-to: #312e81;
  }
  .\32xl\:hover\:to-purple-50:hover {
    --tw-gradient-to: #f5f3ff;
  }
  .\32xl\:hover\:to-purple-100:hover {
    --tw-gradient-to: #ede9fe;
  }
  .\32xl\:hover\:to-purple-200:hover {
    --tw-gradient-to: #ddd6fe;
  }
  .\32xl\:hover\:to-purple-300:hover {
    --tw-gradient-to: #c4b5fd;
  }
  .\32xl\:hover\:to-purple-400:hover {
    --tw-gradient-to: #a78bfa;
  }
  .\32xl\:hover\:to-purple-500:hover {
    --tw-gradient-to: #8b5cf6;
  }
  .\32xl\:hover\:to-purple-600:hover {
    --tw-gradient-to: #7c3aed;
  }
  .\32xl\:hover\:to-purple-700:hover {
    --tw-gradient-to: #6d28d9;
  }
  .\32xl\:hover\:to-purple-800:hover {
    --tw-gradient-to: #5b21b6;
  }
  .\32xl\:hover\:to-purple-900:hover {
    --tw-gradient-to: #4c1d95;
  }
  .\32xl\:hover\:to-pink-50:hover {
    --tw-gradient-to: #fdf2f8;
  }
  .\32xl\:hover\:to-pink-100:hover {
    --tw-gradient-to: #fce7f3;
  }
  .\32xl\:hover\:to-pink-200:hover {
    --tw-gradient-to: #fbcfe8;
  }
  .\32xl\:hover\:to-pink-300:hover {
    --tw-gradient-to: #f9a8d4;
  }
  .\32xl\:hover\:to-pink-400:hover {
    --tw-gradient-to: #f472b6;
  }
  .\32xl\:hover\:to-pink-500:hover {
    --tw-gradient-to: #ec4899;
  }
  .\32xl\:hover\:to-pink-600:hover {
    --tw-gradient-to: #db2777;
  }
  .\32xl\:hover\:to-pink-700:hover {
    --tw-gradient-to: #be185d;
  }
  .\32xl\:hover\:to-pink-800:hover {
    --tw-gradient-to: #9d174d;
  }
  .\32xl\:hover\:to-pink-900:hover {
    --tw-gradient-to: #831843;
  }
  .\32xl\:focus\:to-transparent:focus {
    --tw-gradient-to: transparent;
  }
  .\32xl\:focus\:to-current:focus {
    --tw-gradient-to: currentColor;
  }
  .\32xl\:focus\:to-black:focus {
    --tw-gradient-to: #000;
  }
  .\32xl\:focus\:to-white:focus {
    --tw-gradient-to: #fff;
  }
  .\32xl\:focus\:to-gray-50:focus {
    --tw-gradient-to: #f9fafb;
  }
  .\32xl\:focus\:to-gray-100:focus {
    --tw-gradient-to: #f3f4f6;
  }
  .\32xl\:focus\:to-gray-200:focus {
    --tw-gradient-to: #e5e7eb;
  }
  .\32xl\:focus\:to-gray-300:focus {
    --tw-gradient-to: #d1d5db;
  }
  .\32xl\:focus\:to-gray-400:focus {
    --tw-gradient-to: #9ca3af;
  }
  .\32xl\:focus\:to-gray-500:focus {
    --tw-gradient-to: #6b7280;
  }
  .\32xl\:focus\:to-gray-600:focus {
    --tw-gradient-to: #4b5563;
  }
  .\32xl\:focus\:to-gray-700:focus {
    --tw-gradient-to: #374151;
  }
  .\32xl\:focus\:to-gray-800:focus {
    --tw-gradient-to: #1f2937;
  }
  .\32xl\:focus\:to-gray-900:focus {
    --tw-gradient-to: #111827;
  }
  .\32xl\:focus\:to-red-50:focus {
    --tw-gradient-to: #fef2f2;
  }
  .\32xl\:focus\:to-red-100:focus {
    --tw-gradient-to: #fee2e2;
  }
  .\32xl\:focus\:to-red-200:focus {
    --tw-gradient-to: #fecaca;
  }
  .\32xl\:focus\:to-red-300:focus {
    --tw-gradient-to: #fca5a5;
  }
  .\32xl\:focus\:to-red-400:focus {
    --tw-gradient-to: #f87171;
  }
  .\32xl\:focus\:to-red-500:focus {
    --tw-gradient-to: #ef4444;
  }
  .\32xl\:focus\:to-red-600:focus {
    --tw-gradient-to: #dc2626;
  }
  .\32xl\:focus\:to-red-700:focus {
    --tw-gradient-to: #b91c1c;
  }
  .\32xl\:focus\:to-red-800:focus {
    --tw-gradient-to: #991b1b;
  }
  .\32xl\:focus\:to-red-900:focus {
    --tw-gradient-to: #7f1d1d;
  }
  .\32xl\:focus\:to-yellow-50:focus {
    --tw-gradient-to: #fffbeb;
  }
  .\32xl\:focus\:to-yellow-100:focus {
    --tw-gradient-to: #fef3c7;
  }
  .\32xl\:focus\:to-yellow-200:focus {
    --tw-gradient-to: #fde68a;
  }
  .\32xl\:focus\:to-yellow-300:focus {
    --tw-gradient-to: #fcd34d;
  }
  .\32xl\:focus\:to-yellow-400:focus {
    --tw-gradient-to: #fbbf24;
  }
  .\32xl\:focus\:to-yellow-500:focus {
    --tw-gradient-to: #f59e0b;
  }
  .\32xl\:focus\:to-yellow-600:focus {
    --tw-gradient-to: #d97706;
  }
  .\32xl\:focus\:to-yellow-700:focus {
    --tw-gradient-to: #b45309;
  }
  .\32xl\:focus\:to-yellow-800:focus {
    --tw-gradient-to: #92400e;
  }
  .\32xl\:focus\:to-yellow-900:focus {
    --tw-gradient-to: #78350f;
  }
  .\32xl\:focus\:to-green-50:focus {
    --tw-gradient-to: #ecfdf5;
  }
  .\32xl\:focus\:to-green-100:focus {
    --tw-gradient-to: #d1fae5;
  }
  .\32xl\:focus\:to-green-200:focus {
    --tw-gradient-to: #a7f3d0;
  }
  .\32xl\:focus\:to-green-300:focus {
    --tw-gradient-to: #6ee7b7;
  }
  .\32xl\:focus\:to-green-400:focus {
    --tw-gradient-to: #34d399;
  }
  .\32xl\:focus\:to-green-500:focus {
    --tw-gradient-to: #10b981;
  }
  .\32xl\:focus\:to-green-600:focus {
    --tw-gradient-to: #059669;
  }
  .\32xl\:focus\:to-green-700:focus {
    --tw-gradient-to: #047857;
  }
  .\32xl\:focus\:to-green-800:focus {
    --tw-gradient-to: #065f46;
  }
  .\32xl\:focus\:to-green-900:focus {
    --tw-gradient-to: #064e3b;
  }
  .\32xl\:focus\:to-blue-50:focus {
    --tw-gradient-to: #eff6ff;
  }
  .\32xl\:focus\:to-blue-100:focus {
    --tw-gradient-to: #dbeafe;
  }
  .\32xl\:focus\:to-blue-200:focus {
    --tw-gradient-to: #bfdbfe;
  }
  .\32xl\:focus\:to-blue-300:focus {
    --tw-gradient-to: #93c5fd;
  }
  .\32xl\:focus\:to-blue-400:focus {
    --tw-gradient-to: #60a5fa;
  }
  .\32xl\:focus\:to-blue-500:focus {
    --tw-gradient-to: #3b82f6;
  }
  .\32xl\:focus\:to-blue-600:focus {
    --tw-gradient-to: #2563eb;
  }
  .\32xl\:focus\:to-blue-700:focus {
    --tw-gradient-to: #1d4ed8;
  }
  .\32xl\:focus\:to-blue-800:focus {
    --tw-gradient-to: #1e40af;
  }
  .\32xl\:focus\:to-blue-900:focus {
    --tw-gradient-to: #1e3a8a;
  }
  .\32xl\:focus\:to-indigo-50:focus {
    --tw-gradient-to: #eef2ff;
  }
  .\32xl\:focus\:to-indigo-100:focus {
    --tw-gradient-to: #e0e7ff;
  }
  .\32xl\:focus\:to-indigo-200:focus {
    --tw-gradient-to: #c7d2fe;
  }
  .\32xl\:focus\:to-indigo-300:focus {
    --tw-gradient-to: #a5b4fc;
  }
  .\32xl\:focus\:to-indigo-400:focus {
    --tw-gradient-to: #818cf8;
  }
  .\32xl\:focus\:to-indigo-500:focus {
    --tw-gradient-to: #6366f1;
  }
  .\32xl\:focus\:to-indigo-600:focus {
    --tw-gradient-to: #4f46e5;
  }
  .\32xl\:focus\:to-indigo-700:focus {
    --tw-gradient-to: #4338ca;
  }
  .\32xl\:focus\:to-indigo-800:focus {
    --tw-gradient-to: #3730a3;
  }
  .\32xl\:focus\:to-indigo-900:focus {
    --tw-gradient-to: #312e81;
  }
  .\32xl\:focus\:to-purple-50:focus {
    --tw-gradient-to: #f5f3ff;
  }
  .\32xl\:focus\:to-purple-100:focus {
    --tw-gradient-to: #ede9fe;
  }
  .\32xl\:focus\:to-purple-200:focus {
    --tw-gradient-to: #ddd6fe;
  }
  .\32xl\:focus\:to-purple-300:focus {
    --tw-gradient-to: #c4b5fd;
  }
  .\32xl\:focus\:to-purple-400:focus {
    --tw-gradient-to: #a78bfa;
  }
  .\32xl\:focus\:to-purple-500:focus {
    --tw-gradient-to: #8b5cf6;
  }
  .\32xl\:focus\:to-purple-600:focus {
    --tw-gradient-to: #7c3aed;
  }
  .\32xl\:focus\:to-purple-700:focus {
    --tw-gradient-to: #6d28d9;
  }
  .\32xl\:focus\:to-purple-800:focus {
    --tw-gradient-to: #5b21b6;
  }
  .\32xl\:focus\:to-purple-900:focus {
    --tw-gradient-to: #4c1d95;
  }
  .\32xl\:focus\:to-pink-50:focus {
    --tw-gradient-to: #fdf2f8;
  }
  .\32xl\:focus\:to-pink-100:focus {
    --tw-gradient-to: #fce7f3;
  }
  .\32xl\:focus\:to-pink-200:focus {
    --tw-gradient-to: #fbcfe8;
  }
  .\32xl\:focus\:to-pink-300:focus {
    --tw-gradient-to: #f9a8d4;
  }
  .\32xl\:focus\:to-pink-400:focus {
    --tw-gradient-to: #f472b6;
  }
  .\32xl\:focus\:to-pink-500:focus {
    --tw-gradient-to: #ec4899;
  }
  .\32xl\:focus\:to-pink-600:focus {
    --tw-gradient-to: #db2777;
  }
  .\32xl\:focus\:to-pink-700:focus {
    --tw-gradient-to: #be185d;
  }
  .\32xl\:focus\:to-pink-800:focus {
    --tw-gradient-to: #9d174d;
  }
  .\32xl\:focus\:to-pink-900:focus {
    --tw-gradient-to: #831843;
  }
  .\32xl\:decoration-slice {
    -webkit-box-decoration-break: slice;
    box-decoration-break: slice;
  }
  .\32xl\:decoration-clone {
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
  }
  .\32xl\:bg-auto {
    background-size: auto;
  }
  .\32xl\:bg-cover {
    background-size: cover;
  }
  .\32xl\:bg-contain {
    background-size: contain;
  }
  .\32xl\:bg-fixed {
    background-attachment: fixed;
  }
  .\32xl\:bg-local {
    background-attachment: local;
  }
  .\32xl\:bg-scroll {
    background-attachment: scroll;
  }
  .\32xl\:bg-clip-border {
    background-clip: border-box;
  }
  .\32xl\:bg-clip-padding {
    background-clip: padding-box;
  }
  .\32xl\:bg-clip-content {
    background-clip: content-box;
  }
  .\32xl\:bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
  }
  .\32xl\:bg-bottom {
    background-position: bottom;
  }
  .\32xl\:bg-center {
    background-position: center;
  }
  .\32xl\:bg-left {
    background-position: left;
  }
  .\32xl\:bg-left-bottom {
    background-position: left bottom;
  }
  .\32xl\:bg-left-top {
    background-position: left top;
  }
  .\32xl\:bg-right {
    background-position: right;
  }
  .\32xl\:bg-right-bottom {
    background-position: right bottom;
  }
  .\32xl\:bg-right-top {
    background-position: right top;
  }
  .\32xl\:bg-top {
    background-position: top;
  }
  .\32xl\:bg-repeat {
    background-repeat: repeat;
  }
  .\32xl\:bg-no-repeat {
    background-repeat: no-repeat;
  }
  .\32xl\:bg-repeat-x {
    background-repeat: repeat-x;
  }
  .\32xl\:bg-repeat-y {
    background-repeat: repeat-y;
  }
  .\32xl\:bg-repeat-round {
    background-repeat: round;
  }
  .\32xl\:bg-repeat-space {
    background-repeat: space;
  }
  .\32xl\:bg-origin-border {
    background-origin: border-box;
  }
  .\32xl\:bg-origin-padding {
    background-origin: padding-box;
  }
  .\32xl\:bg-origin-content {
    background-origin: content-box;
  }
  .\32xl\:fill-current {
    fill: currentColor;
  }
  .\32xl\:stroke-current {
    stroke: currentColor;
  }
  .\32xl\:stroke-0 {
    stroke-width: 0;
  }
  .\32xl\:stroke-1 {
    stroke-width: 1;
  }
  .\32xl\:stroke-2 {
    stroke-width: 2;
  }
  .\32xl\:object-contain {
    object-fit: contain;
  }
  .\32xl\:object-cover {
    object-fit: cover;
  }
  .\32xl\:object-fill {
    object-fit: fill;
  }
  .\32xl\:object-none {
    object-fit: none;
  }
  .\32xl\:object-scale-down {
    object-fit: scale-down;
  }
  .\32xl\:object-bottom {
    object-position: bottom;
  }
  .\32xl\:object-center {
    object-position: center;
  }
  .\32xl\:object-left {
    object-position: left;
  }
  .\32xl\:object-left-bottom {
    object-position: left bottom;
  }
  .\32xl\:object-left-top {
    object-position: left top;
  }
  .\32xl\:object-right {
    object-position: right;
  }
  .\32xl\:object-right-bottom {
    object-position: right bottom;
  }
  .\32xl\:object-right-top {
    object-position: right top;
  }
  .\32xl\:object-top {
    object-position: top;
  }
  .\32xl\:p-0 {
    padding: 0;
  }
  .\32xl\:p-1 {
    padding: 0.25rem;
  }
  .\32xl\:p-2 {
    padding: 0.5rem;
  }
  .\32xl\:p-3 {
    padding: 0.75rem;
  }
  .\32xl\:p-4 {
    padding: 1rem;
  }
  .\32xl\:p-5 {
    padding: 1.25rem;
  }
  .\32xl\:p-6 {
    padding: 1.5rem;
  }
  .\32xl\:p-7 {
    padding: 1.75rem;
  }
  .\32xl\:p-8 {
    padding: 2rem;
  }
  .\32xl\:p-9 {
    padding: 2.25rem;
  }
  .\32xl\:p-10 {
    padding: 2.5rem;
  }
  .\32xl\:p-11 {
    padding: 2.75rem;
  }
  .\32xl\:p-12 {
    padding: 3rem;
  }
  .\32xl\:p-14 {
    padding: 3.5rem;
  }
  .\32xl\:p-16 {
    padding: 4rem;
  }
  .\32xl\:p-20 {
    padding: 5rem;
  }
  .\32xl\:p-24 {
    padding: 6rem;
  }
  .\32xl\:p-28 {
    padding: 7rem;
  }
  .\32xl\:p-32 {
    padding: 8rem;
  }
  .\32xl\:p-36 {
    padding: 9rem;
  }
  .\32xl\:p-40 {
    padding: 10rem;
  }
  .\32xl\:p-44 {
    padding: 11rem;
  }
  .\32xl\:p-48 {
    padding: 12rem;
  }
  .\32xl\:p-52 {
    padding: 13rem;
  }
  .\32xl\:p-56 {
    padding: 14rem;
  }
  .\32xl\:p-60 {
    padding: 15rem;
  }
  .\32xl\:p-64 {
    padding: 16rem;
  }
  .\32xl\:p-72 {
    padding: 18rem;
  }
  .\32xl\:p-80 {
    padding: 20rem;
  }
  .\32xl\:p-96 {
    padding: 24rem;
  }
  .\32xl\:p-px {
    padding: 1px;
  }
  .\32xl\:p-0\.5 {
    padding: 0.125rem;
  }
  .\32xl\:p-1\.5 {
    padding: 0.375rem;
  }
  .\32xl\:p-2\.5 {
    padding: 0.625rem;
  }
  .\32xl\:p-3\.5 {
    padding: 0.875rem;
  }
  .\32xl\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }
  .\32xl\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .\32xl\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .\32xl\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .\32xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .\32xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .\32xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .\32xl\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .\32xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .\32xl\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .\32xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .\32xl\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .\32xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .\32xl\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .\32xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .\32xl\:px-20 {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .\32xl\:px-24 {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .\32xl\:px-28 {
    padding-left: 7rem;
    padding-right: 7rem;
  }
  .\32xl\:px-32 {
    padding-left: 8rem;
    padding-right: 8rem;
  }
  .\32xl\:px-36 {
    padding-left: 9rem;
    padding-right: 9rem;
  }
  .\32xl\:px-40 {
    padding-left: 10rem;
    padding-right: 10rem;
  }
  .\32xl\:px-44 {
    padding-left: 11rem;
    padding-right: 11rem;
  }
  .\32xl\:px-48 {
    padding-left: 12rem;
    padding-right: 12rem;
  }
  .\32xl\:px-52 {
    padding-left: 13rem;
    padding-right: 13rem;
  }
  .\32xl\:px-56 {
    padding-left: 14rem;
    padding-right: 14rem;
  }
  .\32xl\:px-60 {
    padding-left: 15rem;
    padding-right: 15rem;
  }
  .\32xl\:px-64 {
    padding-left: 16rem;
    padding-right: 16rem;
  }
  .\32xl\:px-72 {
    padding-left: 18rem;
    padding-right: 18rem;
  }
  .\32xl\:px-80 {
    padding-left: 20rem;
    padding-right: 20rem;
  }
  .\32xl\:px-96 {
    padding-left: 24rem;
    padding-right: 24rem;
  }
  .\32xl\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .\32xl\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .\32xl\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .\32xl\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .\32xl\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .\32xl\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }
  .\32xl\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .\32xl\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .\32xl\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .\32xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .\32xl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .\32xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .\32xl\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .\32xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .\32xl\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .\32xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .\32xl\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .\32xl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .\32xl\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .\32xl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .\32xl\:py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .\32xl\:py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  .\32xl\:py-28 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .\32xl\:py-32 {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }
  .\32xl\:py-36 {
    padding-top: 9rem;
    padding-bottom: 9rem;
  }
  .\32xl\:py-40 {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
  .\32xl\:py-44 {
    padding-top: 11rem;
    padding-bottom: 11rem;
  }
  .\32xl\:py-48 {
    padding-top: 12rem;
    padding-bottom: 12rem;
  }
  .\32xl\:py-52 {
    padding-top: 13rem;
    padding-bottom: 13rem;
  }
  .\32xl\:py-56 {
    padding-top: 14rem;
    padding-bottom: 14rem;
  }
  .\32xl\:py-60 {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
  .\32xl\:py-64 {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
  .\32xl\:py-72 {
    padding-top: 18rem;
    padding-bottom: 18rem;
  }
  .\32xl\:py-80 {
    padding-top: 20rem;
    padding-bottom: 20rem;
  }
  .\32xl\:py-96 {
    padding-top: 24rem;
    padding-bottom: 24rem;
  }
  .\32xl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .\32xl\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .\32xl\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .\32xl\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .\32xl\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .\32xl\:pt-0 {
    padding-top: 0;
  }
  .\32xl\:pt-1 {
    padding-top: 0.25rem;
  }
  .\32xl\:pt-2 {
    padding-top: 0.5rem;
  }
  .\32xl\:pt-3 {
    padding-top: 0.75rem;
  }
  .\32xl\:pt-4 {
    padding-top: 1rem;
  }
  .\32xl\:pt-5 {
    padding-top: 1.25rem;
  }
  .\32xl\:pt-6 {
    padding-top: 1.5rem;
  }
  .\32xl\:pt-7 {
    padding-top: 1.75rem;
  }
  .\32xl\:pt-8 {
    padding-top: 2rem;
  }
  .\32xl\:pt-9 {
    padding-top: 2.25rem;
  }
  .\32xl\:pt-10 {
    padding-top: 2.5rem;
  }
  .\32xl\:pt-11 {
    padding-top: 2.75rem;
  }
  .\32xl\:pt-12 {
    padding-top: 3rem;
  }
  .\32xl\:pt-14 {
    padding-top: 3.5rem;
  }
  .\32xl\:pt-16 {
    padding-top: 4rem;
  }
  .\32xl\:pt-20 {
    padding-top: 5rem;
  }
  .\32xl\:pt-24 {
    padding-top: 6rem;
  }
  .\32xl\:pt-28 {
    padding-top: 7rem;
  }
  .\32xl\:pt-32 {
    padding-top: 8rem;
  }
  .\32xl\:pt-36 {
    padding-top: 9rem;
  }
  .\32xl\:pt-40 {
    padding-top: 10rem;
  }
  .\32xl\:pt-44 {
    padding-top: 11rem;
  }
  .\32xl\:pt-48 {
    padding-top: 12rem;
  }
  .\32xl\:pt-52 {
    padding-top: 13rem;
  }
  .\32xl\:pt-56 {
    padding-top: 14rem;
  }
  .\32xl\:pt-60 {
    padding-top: 15rem;
  }
  .\32xl\:pt-64 {
    padding-top: 16rem;
  }
  .\32xl\:pt-72 {
    padding-top: 18rem;
  }
  .\32xl\:pt-80 {
    padding-top: 20rem;
  }
  .\32xl\:pt-96 {
    padding-top: 24rem;
  }
  .\32xl\:pt-px {
    padding-top: 1px;
  }
  .\32xl\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .\32xl\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .\32xl\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .\32xl\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .\32xl\:pr-0 {
    padding-right: 0;
  }
  .\32xl\:pr-1 {
    padding-right: 0.25rem;
  }
  .\32xl\:pr-2 {
    padding-right: 0.5rem;
  }
  .\32xl\:pr-3 {
    padding-right: 0.75rem;
  }
  .\32xl\:pr-4 {
    padding-right: 1rem;
  }
  .\32xl\:pr-5 {
    padding-right: 1.25rem;
  }
  .\32xl\:pr-6 {
    padding-right: 1.5rem;
  }
  .\32xl\:pr-7 {
    padding-right: 1.75rem;
  }
  .\32xl\:pr-8 {
    padding-right: 2rem;
  }
  .\32xl\:pr-9 {
    padding-right: 2.25rem;
  }
  .\32xl\:pr-10 {
    padding-right: 2.5rem;
  }
  .\32xl\:pr-11 {
    padding-right: 2.75rem;
  }
  .\32xl\:pr-12 {
    padding-right: 3rem;
  }
  .\32xl\:pr-14 {
    padding-right: 3.5rem;
  }
  .\32xl\:pr-16 {
    padding-right: 4rem;
  }
  .\32xl\:pr-20 {
    padding-right: 5rem;
  }
  .\32xl\:pr-24 {
    padding-right: 6rem;
  }
  .\32xl\:pr-28 {
    padding-right: 7rem;
  }
  .\32xl\:pr-32 {
    padding-right: 8rem;
  }
  .\32xl\:pr-36 {
    padding-right: 9rem;
  }
  .\32xl\:pr-40 {
    padding-right: 10rem;
  }
  .\32xl\:pr-44 {
    padding-right: 11rem;
  }
  .\32xl\:pr-48 {
    padding-right: 12rem;
  }
  .\32xl\:pr-52 {
    padding-right: 13rem;
  }
  .\32xl\:pr-56 {
    padding-right: 14rem;
  }
  .\32xl\:pr-60 {
    padding-right: 15rem;
  }
  .\32xl\:pr-64 {
    padding-right: 16rem;
  }
  .\32xl\:pr-72 {
    padding-right: 18rem;
  }
  .\32xl\:pr-80 {
    padding-right: 20rem;
  }
  .\32xl\:pr-96 {
    padding-right: 24rem;
  }
  .\32xl\:pr-px {
    padding-right: 1px;
  }
  .\32xl\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .\32xl\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .\32xl\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .\32xl\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .\32xl\:pb-0 {
    padding-bottom: 0;
  }
  .\32xl\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .\32xl\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .\32xl\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .\32xl\:pb-4 {
    padding-bottom: 1rem;
  }
  .\32xl\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .\32xl\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .\32xl\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .\32xl\:pb-8 {
    padding-bottom: 2rem;
  }
  .\32xl\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .\32xl\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .\32xl\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .\32xl\:pb-12 {
    padding-bottom: 3rem;
  }
  .\32xl\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .\32xl\:pb-16 {
    padding-bottom: 4rem;
  }
  .\32xl\:pb-20 {
    padding-bottom: 5rem;
  }
  .\32xl\:pb-24 {
    padding-bottom: 6rem;
  }
  .\32xl\:pb-28 {
    padding-bottom: 7rem;
  }
  .\32xl\:pb-32 {
    padding-bottom: 8rem;
  }
  .\32xl\:pb-36 {
    padding-bottom: 9rem;
  }
  .\32xl\:pb-40 {
    padding-bottom: 10rem;
  }
  .\32xl\:pb-44 {
    padding-bottom: 11rem;
  }
  .\32xl\:pb-48 {
    padding-bottom: 12rem;
  }
  .\32xl\:pb-52 {
    padding-bottom: 13rem;
  }
  .\32xl\:pb-56 {
    padding-bottom: 14rem;
  }
  .\32xl\:pb-60 {
    padding-bottom: 15rem;
  }
  .\32xl\:pb-64 {
    padding-bottom: 16rem;
  }
  .\32xl\:pb-72 {
    padding-bottom: 18rem;
  }
  .\32xl\:pb-80 {
    padding-bottom: 20rem;
  }
  .\32xl\:pb-96 {
    padding-bottom: 24rem;
  }
  .\32xl\:pb-px {
    padding-bottom: 1px;
  }
  .\32xl\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .\32xl\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .\32xl\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .\32xl\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .\32xl\:pl-0 {
    padding-left: 0;
  }
  .\32xl\:pl-1 {
    padding-left: 0.25rem;
  }
  .\32xl\:pl-2 {
    padding-left: 0.5rem;
  }
  .\32xl\:pl-3 {
    padding-left: 0.75rem;
  }
  .\32xl\:pl-4 {
    padding-left: 1rem;
  }
  .\32xl\:pl-5 {
    padding-left: 1.25rem;
  }
  .\32xl\:pl-6 {
    padding-left: 1.5rem;
  }
  .\32xl\:pl-7 {
    padding-left: 1.75rem;
  }
  .\32xl\:pl-8 {
    padding-left: 2rem;
  }
  .\32xl\:pl-9 {
    padding-left: 2.25rem;
  }
  .\32xl\:pl-10 {
    padding-left: 2.5rem;
  }
  .\32xl\:pl-11 {
    padding-left: 2.75rem;
  }
  .\32xl\:pl-12 {
    padding-left: 3rem;
  }
  .\32xl\:pl-14 {
    padding-left: 3.5rem;
  }
  .\32xl\:pl-16 {
    padding-left: 4rem;
  }
  .\32xl\:pl-20 {
    padding-left: 5rem;
  }
  .\32xl\:pl-24 {
    padding-left: 6rem;
  }
  .\32xl\:pl-28 {
    padding-left: 7rem;
  }
  .\32xl\:pl-32 {
    padding-left: 8rem;
  }
  .\32xl\:pl-36 {
    padding-left: 9rem;
  }
  .\32xl\:pl-40 {
    padding-left: 10rem;
  }
  .\32xl\:pl-44 {
    padding-left: 11rem;
  }
  .\32xl\:pl-48 {
    padding-left: 12rem;
  }
  .\32xl\:pl-52 {
    padding-left: 13rem;
  }
  .\32xl\:pl-56 {
    padding-left: 14rem;
  }
  .\32xl\:pl-60 {
    padding-left: 15rem;
  }
  .\32xl\:pl-64 {
    padding-left: 16rem;
  }
  .\32xl\:pl-72 {
    padding-left: 18rem;
  }
  .\32xl\:pl-80 {
    padding-left: 20rem;
  }
  .\32xl\:pl-96 {
    padding-left: 24rem;
  }
  .\32xl\:pl-px {
    padding-left: 1px;
  }
  .\32xl\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .\32xl\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .\32xl\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .\32xl\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .\32xl\:text-left {
    text-align: left;
  }
  .\32xl\:text-center {
    text-align: center;
  }
  .\32xl\:text-right {
    text-align: right;
  }
  .\32xl\:text-justify {
    text-align: justify;
  }
  .\32xl\:align-baseline {
    vertical-align: baseline;
  }
  .\32xl\:align-top {
    vertical-align: top;
  }
  .\32xl\:align-middle {
    vertical-align: middle;
  }
  .\32xl\:align-bottom {
    vertical-align: bottom;
  }
  .\32xl\:align-text-top {
    vertical-align: text-top;
  }
  .\32xl\:align-text-bottom {
    vertical-align: text-bottom;
  }
  .\32xl\:font-sans {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont,
      "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
      "Noto Color Emoji";
  }
  .\32xl\:font-serif {
    font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif;
  }
  .\32xl\:font-mono {
    font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas,
      "Liberation Mono", "Courier New", monospace;
  }
  .\32xl\:text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }
  .\32xl\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .\32xl\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .\32xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .\32xl\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
  .\32xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .\32xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
  .\32xl\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
  .\32xl\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
  .\32xl\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }
  .\32xl\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
  .\32xl\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
  .\32xl\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }
  .\32xl\:font-thin {
    font-weight: 100;
  }
  .\32xl\:font-extralight {
    font-weight: 200;
  }
  .\32xl\:font-light {
    font-weight: 300;
  }
  .\32xl\:font-normal {
    font-weight: 400;
  }
  .\32xl\:font-medium {
    font-weight: 500;
  }
  .\32xl\:font-semibold {
    font-weight: 600;
  }
  .\32xl\:font-bold {
    font-weight: 700;
  }
  .\32xl\:font-extrabold {
    font-weight: 800;
  }
  .\32xl\:font-black {
    font-weight: 900;
  }
  .\32xl\:uppercase {
    text-transform: uppercase;
  }
  .\32xl\:lowercase {
    text-transform: lowercase;
  }
  .\32xl\:capitalize {
    text-transform: capitalize;
  }
  .\32xl\:normal-case {
    text-transform: none;
  }
  .\32xl\:italic {
    font-style: italic;
  }
  .\32xl\:not-italic {
    font-style: normal;
  }
  .\32xl\:diagonal-fractions,
  .\32xl\:lining-nums,
  .\32xl\:oldstyle-nums,
  .\32xl\:ordinal,
  .\32xl\:proportional-nums,
  .\32xl\:slashed-zero,
  .\32xl\:stacked-fractions,
  .\32xl\:tabular-nums {
    --tw-ordinal: var(--tw-empty); /*!*/ /*!*/
    --tw-slashed-zero: var(--tw-empty); /*!*/ /*!*/
    --tw-numeric-figure: var(--tw-empty); /*!*/ /*!*/
    --tw-numeric-spacing: var(--tw-empty); /*!*/ /*!*/
    --tw-numeric-fraction: var(--tw-empty); /*!*/ /*!*/
    font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero)
      var(--tw-numeric-figure) var(--tw-numeric-spacing)
      var(--tw-numeric-fraction);
  }
  .\32xl\:normal-nums {
    font-variant-numeric: normal;
  }
  .\32xl\:ordinal {
    --tw-ordinal: ordinal;
  }
  .\32xl\:slashed-zero {
    --tw-slashed-zero: slashed-zero;
  }
  .\32xl\:lining-nums {
    --tw-numeric-figure: lining-nums;
  }
  .\32xl\:oldstyle-nums {
    --tw-numeric-figure: oldstyle-nums;
  }
  .\32xl\:proportional-nums {
    --tw-numeric-spacing: proportional-nums;
  }
  .\32xl\:tabular-nums {
    --tw-numeric-spacing: tabular-nums;
  }
  .\32xl\:diagonal-fractions {
    --tw-numeric-fraction: diagonal-fractions;
  }
  .\32xl\:stacked-fractions {
    --tw-numeric-fraction: stacked-fractions;
  }
  .\32xl\:leading-3 {
    line-height: 0.75rem;
  }
  .\32xl\:leading-4 {
    line-height: 1rem;
  }
  .\32xl\:leading-5 {
    line-height: 1.25rem;
  }
  .\32xl\:leading-6 {
    line-height: 1.5rem;
  }
  .\32xl\:leading-7 {
    line-height: 1.75rem;
  }
  .\32xl\:leading-8 {
    line-height: 2rem;
  }
  .\32xl\:leading-9 {
    line-height: 2.25rem;
  }
  .\32xl\:leading-10 {
    line-height: 2.5rem;
  }
  .\32xl\:leading-none {
    line-height: 1;
  }
  .\32xl\:leading-tight {
    line-height: 1.25;
  }
  .\32xl\:leading-snug {
    line-height: 1.375;
  }
  .\32xl\:leading-normal {
    line-height: 1.5;
  }
  .\32xl\:leading-relaxed {
    line-height: 1.625;
  }
  .\32xl\:leading-loose {
    line-height: 2;
  }
  .\32xl\:tracking-tighter {
    letter-spacing: -0.05em;
  }
  .\32xl\:tracking-tight {
    letter-spacing: -0.025em;
  }
  .\32xl\:tracking-normal {
    letter-spacing: 0;
  }
  .\32xl\:tracking-wide {
    letter-spacing: 0.025em;
  }
  .\32xl\:tracking-wider {
    letter-spacing: 0.05em;
  }
  .\32xl\:tracking-widest {
    letter-spacing: 0.1em;
  }
  .\32xl\:text-transparent {
    color: transparent;
  }
  .\32xl\:text-current {
    color: currentColor;
  }
  .\32xl\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .\32xl\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .\32xl\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .\32xl\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .\32xl\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .\32xl\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .\32xl\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .\32xl\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .\32xl\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .\32xl\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .\32xl\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .\32xl\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .\32xl\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .\32xl\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .\32xl\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .\32xl\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .\32xl\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .\32xl\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .\32xl\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .\32xl\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .\32xl\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .\32xl\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .\32xl\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .\32xl\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .\32xl\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .\32xl\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .\32xl\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .\32xl\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-transparent {
    color: transparent;
  }
  .group:hover .\32xl\:group-hover\:text-current {
    color: currentColor;
  }
  .group:hover .\32xl\:group-hover\:text-black {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-50 {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-100 {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-200 {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-300 {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-400 {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-500 {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-600 {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-700 {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-800 {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-gray-900 {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-50 {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-200 {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-400 {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-500 {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-600 {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-700 {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-800 {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-red-900 {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-50 {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-100 {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-200 {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-300 {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-400 {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-500 {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-600 {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-700 {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-800 {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-yellow-900 {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-50 {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-100 {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-200 {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-300 {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-400 {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-500 {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-600 {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-700 {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-800 {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-green-900 {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-50 {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-100 {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-200 {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-300 {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-400 {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-500 {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-600 {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-700 {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-800 {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-blue-900 {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-50 {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-100 {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-200 {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-300 {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-400 {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-500 {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-600 {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-700 {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-800 {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-indigo-900 {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-50 {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-200 {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-300 {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-400 {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-500 {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-600 {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-700 {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-800 {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-purple-900 {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-50 {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-100 {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-200 {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-300 {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-400 {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-500 {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-600 {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-700 {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-800 {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .group:hover .\32xl\:group-hover\:text-pink-900 {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-transparent:focus-within {
    color: transparent;
  }
  .\32xl\:focus-within\:text-current:focus-within {
    color: currentColor;
  }
  .\32xl\:focus-within\:text-black:focus-within {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-white:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-gray-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-red-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-yellow-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-green-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-blue-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-indigo-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-purple-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-50:focus-within {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-100:focus-within {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-200:focus-within {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-300:focus-within {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-400:focus-within {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-500:focus-within {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-600:focus-within {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-700:focus-within {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-800:focus-within {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .\32xl\:focus-within\:text-pink-900:focus-within {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-transparent:hover {
    color: transparent;
  }
  .\32xl\:hover\:text-current:hover {
    color: currentColor;
  }
  .\32xl\:hover\:text-black:hover {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-50:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-100:hover {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-300:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-400:hover {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-500:hover {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-600:hover {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-700:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-800:hover {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-gray-900:hover {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-50:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-200:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-400:hover {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-500:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-600:hover {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-700:hover {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-800:hover {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-red-900:hover {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-50:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-100:hover {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-200:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-300:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-400:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-500:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-600:hover {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-700:hover {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-800:hover {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-yellow-900:hover {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-50:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-100:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-200:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-300:hover {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-400:hover {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-500:hover {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-600:hover {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-700:hover {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-800:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-green-900:hover {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-50:hover {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-100:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-200:hover {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-300:hover {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-400:hover {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-500:hover {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-600:hover {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-700:hover {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-800:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-blue-900:hover {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-50:hover {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-100:hover {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-200:hover {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-300:hover {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-400:hover {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-500:hover {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-600:hover {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-700:hover {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-800:hover {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-indigo-900:hover {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-50:hover {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-100:hover {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-200:hover {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-300:hover {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-400:hover {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-500:hover {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-600:hover {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-700:hover {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-800:hover {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-purple-900:hover {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-50:hover {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-100:hover {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-200:hover {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-300:hover {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-400:hover {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-500:hover {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-600:hover {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-700:hover {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-800:hover {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .\32xl\:hover\:text-pink-900:hover {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-transparent:focus {
    color: transparent;
  }
  .\32xl\:focus\:text-current:focus {
    color: currentColor;
  }
  .\32xl\:focus\:text-black:focus {
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-white:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-50:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-100:focus {
    --tw-text-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-200:focus {
    --tw-text-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-300:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-400:focus {
    --tw-text-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-500:focus {
    --tw-text-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-600:focus {
    --tw-text-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-700:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-800:focus {
    --tw-text-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-gray-900:focus {
    --tw-text-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-50:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-200:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-400:focus {
    --tw-text-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-500:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-600:focus {
    --tw-text-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-700:focus {
    --tw-text-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-800:focus {
    --tw-text-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-red-900:focus {
    --tw-text-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-50:focus {
    --tw-text-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-100:focus {
    --tw-text-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-200:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-300:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-400:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-500:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-600:focus {
    --tw-text-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-700:focus {
    --tw-text-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-800:focus {
    --tw-text-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-yellow-900:focus {
    --tw-text-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-50:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-100:focus {
    --tw-text-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-200:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-300:focus {
    --tw-text-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-400:focus {
    --tw-text-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-500:focus {
    --tw-text-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-600:focus {
    --tw-text-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-700:focus {
    --tw-text-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-800:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-green-900:focus {
    --tw-text-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-50:focus {
    --tw-text-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-100:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-200:focus {
    --tw-text-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-300:focus {
    --tw-text-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-400:focus {
    --tw-text-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-500:focus {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-600:focus {
    --tw-text-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-700:focus {
    --tw-text-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-800:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-blue-900:focus {
    --tw-text-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-50:focus {
    --tw-text-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-100:focus {
    --tw-text-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-200:focus {
    --tw-text-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-300:focus {
    --tw-text-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-400:focus {
    --tw-text-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-500:focus {
    --tw-text-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-600:focus {
    --tw-text-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-700:focus {
    --tw-text-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-800:focus {
    --tw-text-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-indigo-900:focus {
    --tw-text-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-50:focus {
    --tw-text-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-100:focus {
    --tw-text-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-200:focus {
    --tw-text-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-300:focus {
    --tw-text-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-400:focus {
    --tw-text-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-500:focus {
    --tw-text-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-600:focus {
    --tw-text-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-700:focus {
    --tw-text-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-800:focus {
    --tw-text-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-purple-900:focus {
    --tw-text-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-50:focus {
    --tw-text-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-100:focus {
    --tw-text-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-200:focus {
    --tw-text-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-300:focus {
    --tw-text-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-400:focus {
    --tw-text-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-500:focus {
    --tw-text-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-600:focus {
    --tw-text-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-700:focus {
    --tw-text-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-800:focus {
    --tw-text-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-text-opacity));
  }
  .\32xl\:focus\:text-pink-900:focus {
    --tw-text-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-text-opacity));
  }
  .\32xl\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .\32xl\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .\32xl\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .\32xl\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .\32xl\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .\32xl\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .\32xl\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .\32xl\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .\32xl\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .\32xl\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .\32xl\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .\32xl\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .\32xl\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .\32xl\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .\32xl\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-0 {
    --tw-text-opacity: 0;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-5 {
    --tw-text-opacity: 0.05;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-10 {
    --tw-text-opacity: 0.1;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-20 {
    --tw-text-opacity: 0.2;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-25 {
    --tw-text-opacity: 0.25;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-30 {
    --tw-text-opacity: 0.3;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-40 {
    --tw-text-opacity: 0.4;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-50 {
    --tw-text-opacity: 0.5;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-60 {
    --tw-text-opacity: 0.6;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-70 {
    --tw-text-opacity: 0.7;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-75 {
    --tw-text-opacity: 0.75;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-80 {
    --tw-text-opacity: 0.8;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-90 {
    --tw-text-opacity: 0.9;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-95 {
    --tw-text-opacity: 0.95;
  }
  .group:hover .\32xl\:group-hover\:text-opacity-100 {
    --tw-text-opacity: 1;
  }
  .\32xl\:focus-within\:text-opacity-0:focus-within {
    --tw-text-opacity: 0;
  }
  .\32xl\:focus-within\:text-opacity-5:focus-within {
    --tw-text-opacity: 0.05;
  }
  .\32xl\:focus-within\:text-opacity-10:focus-within {
    --tw-text-opacity: 0.1;
  }
  .\32xl\:focus-within\:text-opacity-20:focus-within {
    --tw-text-opacity: 0.2;
  }
  .\32xl\:focus-within\:text-opacity-25:focus-within {
    --tw-text-opacity: 0.25;
  }
  .\32xl\:focus-within\:text-opacity-30:focus-within {
    --tw-text-opacity: 0.3;
  }
  .\32xl\:focus-within\:text-opacity-40:focus-within {
    --tw-text-opacity: 0.4;
  }
  .\32xl\:focus-within\:text-opacity-50:focus-within {
    --tw-text-opacity: 0.5;
  }
  .\32xl\:focus-within\:text-opacity-60:focus-within {
    --tw-text-opacity: 0.6;
  }
  .\32xl\:focus-within\:text-opacity-70:focus-within {
    --tw-text-opacity: 0.7;
  }
  .\32xl\:focus-within\:text-opacity-75:focus-within {
    --tw-text-opacity: 0.75;
  }
  .\32xl\:focus-within\:text-opacity-80:focus-within {
    --tw-text-opacity: 0.8;
  }
  .\32xl\:focus-within\:text-opacity-90:focus-within {
    --tw-text-opacity: 0.9;
  }
  .\32xl\:focus-within\:text-opacity-95:focus-within {
    --tw-text-opacity: 0.95;
  }
  .\32xl\:focus-within\:text-opacity-100:focus-within {
    --tw-text-opacity: 1;
  }
  .\32xl\:hover\:text-opacity-0:hover {
    --tw-text-opacity: 0;
  }
  .\32xl\:hover\:text-opacity-5:hover {
    --tw-text-opacity: 0.05;
  }
  .\32xl\:hover\:text-opacity-10:hover {
    --tw-text-opacity: 0.1;
  }
  .\32xl\:hover\:text-opacity-20:hover {
    --tw-text-opacity: 0.2;
  }
  .\32xl\:hover\:text-opacity-25:hover {
    --tw-text-opacity: 0.25;
  }
  .\32xl\:hover\:text-opacity-30:hover {
    --tw-text-opacity: 0.3;
  }
  .\32xl\:hover\:text-opacity-40:hover {
    --tw-text-opacity: 0.4;
  }
  .\32xl\:hover\:text-opacity-50:hover {
    --tw-text-opacity: 0.5;
  }
  .\32xl\:hover\:text-opacity-60:hover {
    --tw-text-opacity: 0.6;
  }
  .\32xl\:hover\:text-opacity-70:hover {
    --tw-text-opacity: 0.7;
  }
  .\32xl\:hover\:text-opacity-75:hover {
    --tw-text-opacity: 0.75;
  }
  .\32xl\:hover\:text-opacity-80:hover {
    --tw-text-opacity: 0.8;
  }
  .\32xl\:hover\:text-opacity-90:hover {
    --tw-text-opacity: 0.9;
  }
  .\32xl\:hover\:text-opacity-95:hover {
    --tw-text-opacity: 0.95;
  }
  .\32xl\:hover\:text-opacity-100:hover {
    --tw-text-opacity: 1;
  }
  .\32xl\:focus\:text-opacity-0:focus {
    --tw-text-opacity: 0;
  }
  .\32xl\:focus\:text-opacity-5:focus {
    --tw-text-opacity: 0.05;
  }
  .\32xl\:focus\:text-opacity-10:focus {
    --tw-text-opacity: 0.1;
  }
  .\32xl\:focus\:text-opacity-20:focus {
    --tw-text-opacity: 0.2;
  }
  .\32xl\:focus\:text-opacity-25:focus {
    --tw-text-opacity: 0.25;
  }
  .\32xl\:focus\:text-opacity-30:focus {
    --tw-text-opacity: 0.3;
  }
  .\32xl\:focus\:text-opacity-40:focus {
    --tw-text-opacity: 0.4;
  }
  .\32xl\:focus\:text-opacity-50:focus {
    --tw-text-opacity: 0.5;
  }
  .\32xl\:focus\:text-opacity-60:focus {
    --tw-text-opacity: 0.6;
  }
  .\32xl\:focus\:text-opacity-70:focus {
    --tw-text-opacity: 0.7;
  }
  .\32xl\:focus\:text-opacity-75:focus {
    --tw-text-opacity: 0.75;
  }
  .\32xl\:focus\:text-opacity-80:focus {
    --tw-text-opacity: 0.8;
  }
  .\32xl\:focus\:text-opacity-90:focus {
    --tw-text-opacity: 0.9;
  }
  .\32xl\:focus\:text-opacity-95:focus {
    --tw-text-opacity: 0.95;
  }
  .\32xl\:focus\:text-opacity-100:focus {
    --tw-text-opacity: 1;
  }
  .\32xl\:underline {
    text-decoration: underline;
  }
  .\32xl\:line-through {
    text-decoration: line-through;
  }
  .\32xl\:no-underline {
    text-decoration: none;
  }
  .group:hover .\32xl\:group-hover\:underline {
    text-decoration: underline;
  }
  .group:hover .\32xl\:group-hover\:line-through {
    text-decoration: line-through;
  }
  .group:hover .\32xl\:group-hover\:no-underline {
    text-decoration: none;
  }
  .\32xl\:focus-within\:underline:focus-within {
    text-decoration: underline;
  }
  .\32xl\:focus-within\:line-through:focus-within {
    text-decoration: line-through;
  }
  .\32xl\:focus-within\:no-underline:focus-within {
    text-decoration: none;
  }
  .\32xl\:hover\:underline:hover {
    text-decoration: underline;
  }
  .\32xl\:hover\:line-through:hover {
    text-decoration: line-through;
  }
  .\32xl\:hover\:no-underline:hover {
    text-decoration: none;
  }
  .\32xl\:focus\:underline:focus {
    text-decoration: underline;
  }
  .\32xl\:focus\:line-through:focus {
    text-decoration: line-through;
  }
  .\32xl\:focus\:no-underline:focus {
    text-decoration: none;
  }
  .\32xl\:antialiased {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .\32xl\:subpixel-antialiased {
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  }
  .\32xl\:placeholder-transparent::placeholder {
    color: transparent;
  }
  .\32xl\:placeholder-current::placeholder {
    color: currentColor;
  }
  .\32xl\:placeholder-black::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-white::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-gray-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-red-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-yellow-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-green-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-blue-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-indigo-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-purple-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-50::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-100::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-200::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-300::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-400::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-500::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-600::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-700::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-800::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-pink-900::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-transparent:focus::placeholder {
    color: transparent;
  }
  .\32xl\:focus\:placeholder-current:focus::placeholder {
    color: currentColor;
  }
  .\32xl\:focus\:placeholder-black:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-white:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 250, 251, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(243, 244, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(229, 231, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(156, 163, 175, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(107, 114, 128, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(75, 85, 99, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 65, 81, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(31, 41, 55, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-gray-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(17, 24, 39, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 242, 242, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 226, 226, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 202, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 165, 165, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(248, 113, 113, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 68, 68, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(220, 38, 38, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(185, 28, 28, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(153, 27, 27, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-red-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(127, 29, 29, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(255, 251, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(254, 243, 199, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 230, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 211, 77, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 191, 36, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 158, 11, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(217, 119, 6, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(180, 83, 9, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(146, 64, 14, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-yellow-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(120, 53, 15, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 253, 245, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(209, 250, 229, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 243, 208, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(110, 231, 183, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(52, 211, 153, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(16, 185, 129, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(5, 150, 105, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(4, 120, 87, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 95, 70, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-green-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(6, 78, 59, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(239, 246, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 234, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(191, 219, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(147, 197, 253, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(96, 165, 250, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(37, 99, 235, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(29, 78, 216, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 64, 175, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-blue-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(30, 58, 138, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(238, 242, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(224, 231, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(199, 210, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(165, 180, 252, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(129, 140, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(99, 102, 241, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(79, 70, 229, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(67, 56, 202, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(55, 48, 163, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-indigo-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(49, 46, 129, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(245, 243, 255, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(237, 233, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(221, 214, 254, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(196, 181, 253, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(167, 139, 250, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(139, 92, 246, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(124, 58, 237, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(109, 40, 217, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(91, 33, 182, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-purple-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(76, 29, 149, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-50:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(253, 242, 248, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(252, 231, 243, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-200:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(251, 207, 232, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-300:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(249, 168, 212, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-400:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(244, 114, 182, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-500:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(236, 72, 153, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-600:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(219, 39, 119, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-700:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(190, 24, 93, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-800:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(157, 23, 77, var(--tw-placeholder-opacity));
  }
  .\32xl\:focus\:placeholder-pink-900:focus::placeholder {
    --tw-placeholder-opacity: 1;
    color: rgba(131, 24, 67, var(--tw-placeholder-opacity));
  }
  .\32xl\:placeholder-opacity-0::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .\32xl\:placeholder-opacity-5::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .\32xl\:placeholder-opacity-10::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .\32xl\:placeholder-opacity-20::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .\32xl\:placeholder-opacity-25::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .\32xl\:placeholder-opacity-30::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .\32xl\:placeholder-opacity-40::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .\32xl\:placeholder-opacity-50::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .\32xl\:placeholder-opacity-60::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .\32xl\:placeholder-opacity-70::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .\32xl\:placeholder-opacity-75::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .\32xl\:placeholder-opacity-80::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .\32xl\:placeholder-opacity-90::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .\32xl\:placeholder-opacity-95::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .\32xl\:placeholder-opacity-100::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .\32xl\:focus\:placeholder-opacity-0:focus::placeholder {
    --tw-placeholder-opacity: 0;
  }
  .\32xl\:focus\:placeholder-opacity-5:focus::placeholder {
    --tw-placeholder-opacity: 0.05;
  }
  .\32xl\:focus\:placeholder-opacity-10:focus::placeholder {
    --tw-placeholder-opacity: 0.1;
  }
  .\32xl\:focus\:placeholder-opacity-20:focus::placeholder {
    --tw-placeholder-opacity: 0.2;
  }
  .\32xl\:focus\:placeholder-opacity-25:focus::placeholder {
    --tw-placeholder-opacity: 0.25;
  }
  .\32xl\:focus\:placeholder-opacity-30:focus::placeholder {
    --tw-placeholder-opacity: 0.3;
  }
  .\32xl\:focus\:placeholder-opacity-40:focus::placeholder {
    --tw-placeholder-opacity: 0.4;
  }
  .\32xl\:focus\:placeholder-opacity-50:focus::placeholder {
    --tw-placeholder-opacity: 0.5;
  }
  .\32xl\:focus\:placeholder-opacity-60:focus::placeholder {
    --tw-placeholder-opacity: 0.6;
  }
  .\32xl\:focus\:placeholder-opacity-70:focus::placeholder {
    --tw-placeholder-opacity: 0.7;
  }
  .\32xl\:focus\:placeholder-opacity-75:focus::placeholder {
    --tw-placeholder-opacity: 0.75;
  }
  .\32xl\:focus\:placeholder-opacity-80:focus::placeholder {
    --tw-placeholder-opacity: 0.8;
  }
  .\32xl\:focus\:placeholder-opacity-90:focus::placeholder {
    --tw-placeholder-opacity: 0.9;
  }
  .\32xl\:focus\:placeholder-opacity-95:focus::placeholder {
    --tw-placeholder-opacity: 0.95;
  }
  .\32xl\:focus\:placeholder-opacity-100:focus::placeholder {
    --tw-placeholder-opacity: 1;
  }
  .\32xl\:opacity-0 {
    opacity: 0;
  }
  .\32xl\:opacity-5 {
    opacity: 0.05;
  }
  .\32xl\:opacity-10 {
    opacity: 0.1;
  }
  .\32xl\:opacity-20 {
    opacity: 0.2;
  }
  .\32xl\:opacity-25 {
    opacity: 0.25;
  }
  .\32xl\:opacity-30 {
    opacity: 0.3;
  }
  .\32xl\:opacity-40 {
    opacity: 0.4;
  }
  .\32xl\:opacity-50 {
    opacity: 0.5;
  }
  .\32xl\:opacity-60 {
    opacity: 0.6;
  }
  .\32xl\:opacity-70 {
    opacity: 0.7;
  }
  .\32xl\:opacity-75 {
    opacity: 0.75;
  }
  .\32xl\:opacity-80 {
    opacity: 0.8;
  }
  .\32xl\:opacity-90 {
    opacity: 0.9;
  }
  .\32xl\:opacity-95 {
    opacity: 0.95;
  }
  .\32xl\:opacity-100 {
    opacity: 1;
  }
  .group:hover .\32xl\:group-hover\:opacity-0 {
    opacity: 0;
  }
  .group:hover .\32xl\:group-hover\:opacity-5 {
    opacity: 0.05;
  }
  .group:hover .\32xl\:group-hover\:opacity-10 {
    opacity: 0.1;
  }
  .group:hover .\32xl\:group-hover\:opacity-20 {
    opacity: 0.2;
  }
  .group:hover .\32xl\:group-hover\:opacity-25 {
    opacity: 0.25;
  }
  .group:hover .\32xl\:group-hover\:opacity-30 {
    opacity: 0.3;
  }
  .group:hover .\32xl\:group-hover\:opacity-40 {
    opacity: 0.4;
  }
  .group:hover .\32xl\:group-hover\:opacity-50 {
    opacity: 0.5;
  }
  .group:hover .\32xl\:group-hover\:opacity-60 {
    opacity: 0.6;
  }
  .group:hover .\32xl\:group-hover\:opacity-70 {
    opacity: 0.7;
  }
  .group:hover .\32xl\:group-hover\:opacity-75 {
    opacity: 0.75;
  }
  .group:hover .\32xl\:group-hover\:opacity-80 {
    opacity: 0.8;
  }
  .group:hover .\32xl\:group-hover\:opacity-90 {
    opacity: 0.9;
  }
  .group:hover .\32xl\:group-hover\:opacity-95 {
    opacity: 0.95;
  }
  .group:hover .\32xl\:group-hover\:opacity-100 {
    opacity: 1;
  }
  .\32xl\:focus-within\:opacity-0:focus-within {
    opacity: 0;
  }
  .\32xl\:focus-within\:opacity-5:focus-within {
    opacity: 0.05;
  }
  .\32xl\:focus-within\:opacity-10:focus-within {
    opacity: 0.1;
  }
  .\32xl\:focus-within\:opacity-20:focus-within {
    opacity: 0.2;
  }
  .\32xl\:focus-within\:opacity-25:focus-within {
    opacity: 0.25;
  }
  .\32xl\:focus-within\:opacity-30:focus-within {
    opacity: 0.3;
  }
  .\32xl\:focus-within\:opacity-40:focus-within {
    opacity: 0.4;
  }
  .\32xl\:focus-within\:opacity-50:focus-within {
    opacity: 0.5;
  }
  .\32xl\:focus-within\:opacity-60:focus-within {
    opacity: 0.6;
  }
  .\32xl\:focus-within\:opacity-70:focus-within {
    opacity: 0.7;
  }
  .\32xl\:focus-within\:opacity-75:focus-within {
    opacity: 0.75;
  }
  .\32xl\:focus-within\:opacity-80:focus-within {
    opacity: 0.8;
  }
  .\32xl\:focus-within\:opacity-90:focus-within {
    opacity: 0.9;
  }
  .\32xl\:focus-within\:opacity-95:focus-within {
    opacity: 0.95;
  }
  .\32xl\:focus-within\:opacity-100:focus-within {
    opacity: 1;
  }
  .\32xl\:hover\:opacity-0:hover {
    opacity: 0;
  }
  .\32xl\:hover\:opacity-5:hover {
    opacity: 0.05;
  }
  .\32xl\:hover\:opacity-10:hover {
    opacity: 0.1;
  }
  .\32xl\:hover\:opacity-20:hover {
    opacity: 0.2;
  }
  .\32xl\:hover\:opacity-25:hover {
    opacity: 0.25;
  }
  .\32xl\:hover\:opacity-30:hover {
    opacity: 0.3;
  }
  .\32xl\:hover\:opacity-40:hover {
    opacity: 0.4;
  }
  .\32xl\:hover\:opacity-50:hover {
    opacity: 0.5;
  }
  .\32xl\:hover\:opacity-60:hover {
    opacity: 0.6;
  }
  .\32xl\:hover\:opacity-70:hover {
    opacity: 0.7;
  }
  .\32xl\:hover\:opacity-75:hover {
    opacity: 0.75;
  }
  .\32xl\:hover\:opacity-80:hover {
    opacity: 0.8;
  }
  .\32xl\:hover\:opacity-90:hover {
    opacity: 0.9;
  }
  .\32xl\:hover\:opacity-95:hover {
    opacity: 0.95;
  }
  .\32xl\:hover\:opacity-100:hover {
    opacity: 1;
  }
  .\32xl\:focus\:opacity-0:focus {
    opacity: 0;
  }
  .\32xl\:focus\:opacity-5:focus {
    opacity: 0.05;
  }
  .\32xl\:focus\:opacity-10:focus {
    opacity: 0.1;
  }
  .\32xl\:focus\:opacity-20:focus {
    opacity: 0.2;
  }
  .\32xl\:focus\:opacity-25:focus {
    opacity: 0.25;
  }
  .\32xl\:focus\:opacity-30:focus {
    opacity: 0.3;
  }
  .\32xl\:focus\:opacity-40:focus {
    opacity: 0.4;
  }
  .\32xl\:focus\:opacity-50:focus {
    opacity: 0.5;
  }
  .\32xl\:focus\:opacity-60:focus {
    opacity: 0.6;
  }
  .\32xl\:focus\:opacity-70:focus {
    opacity: 0.7;
  }
  .\32xl\:focus\:opacity-75:focus {
    opacity: 0.75;
  }
  .\32xl\:focus\:opacity-80:focus {
    opacity: 0.8;
  }
  .\32xl\:focus\:opacity-90:focus {
    opacity: 0.9;
  }
  .\32xl\:focus\:opacity-95:focus {
    opacity: 0.95;
  }
  .\32xl\:focus\:opacity-100:focus {
    opacity: 1;
  }
  .\32xl\:bg-blend-normal {
    background-blend-mode: normal;
  }
  .\32xl\:bg-blend-multiply {
    background-blend-mode: multiply;
  }
  .\32xl\:bg-blend-screen {
    background-blend-mode: screen;
  }
  .\32xl\:bg-blend-overlay {
    background-blend-mode: overlay;
  }
  .\32xl\:bg-blend-darken {
    background-blend-mode: darken;
  }
  .\32xl\:bg-blend-lighten {
    background-blend-mode: lighten;
  }
  .\32xl\:bg-blend-color-dodge {
    background-blend-mode: color-dodge;
  }
  .\32xl\:bg-blend-color-burn {
    background-blend-mode: color-burn;
  }
  .\32xl\:bg-blend-hard-light {
    background-blend-mode: hard-light;
  }
  .\32xl\:bg-blend-soft-light {
    background-blend-mode: soft-light;
  }
  .\32xl\:bg-blend-difference {
    background-blend-mode: difference;
  }
  .\32xl\:bg-blend-exclusion {
    background-blend-mode: exclusion;
  }
  .\32xl\:bg-blend-hue {
    background-blend-mode: hue;
  }
  .\32xl\:bg-blend-saturation {
    background-blend-mode: saturation;
  }
  .\32xl\:bg-blend-color {
    background-blend-mode: color;
  }
  .\32xl\:bg-blend-luminosity {
    background-blend-mode: luminosity;
  }
  .\32xl\:mix-blend-normal {
    mix-blend-mode: normal;
  }
  .\32xl\:mix-blend-multiply {
    mix-blend-mode: multiply;
  }
  .\32xl\:mix-blend-screen {
    mix-blend-mode: screen;
  }
  .\32xl\:mix-blend-overlay {
    mix-blend-mode: overlay;
  }
  .\32xl\:mix-blend-darken {
    mix-blend-mode: darken;
  }
  .\32xl\:mix-blend-lighten {
    mix-blend-mode: lighten;
  }
  .\32xl\:mix-blend-color-dodge {
    mix-blend-mode: color-dodge;
  }
  .\32xl\:mix-blend-color-burn {
    mix-blend-mode: color-burn;
  }
  .\32xl\:mix-blend-hard-light {
    mix-blend-mode: hard-light;
  }
  .\32xl\:mix-blend-soft-light {
    mix-blend-mode: soft-light;
  }
  .\32xl\:mix-blend-difference {
    mix-blend-mode: difference;
  }
  .\32xl\:mix-blend-exclusion {
    mix-blend-mode: exclusion;
  }
  .\32xl\:mix-blend-hue {
    mix-blend-mode: hue;
  }
  .\32xl\:mix-blend-saturation {
    mix-blend-mode: saturation;
  }
  .\32xl\:mix-blend-color {
    mix-blend-mode: color;
  }
  .\32xl\:mix-blend-luminosity {
    mix-blend-mode: luminosity;
  }
  .\32xl\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .\32xl\:group-hover\:shadow-sm {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .\32xl\:group-hover\:shadow {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .\32xl\:group-hover\:shadow-md {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .\32xl\:group-hover\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .\32xl\:group-hover\:shadow-xl {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .\32xl\:group-hover\:shadow-2xl {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .\32xl\:group-hover\:shadow-inner {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .group:hover .\32xl\:group-hover\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow-sm:focus-within {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow:focus-within {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow-md:focus-within {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow-lg:focus-within {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow-xl:focus-within {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow-2xl:focus-within {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow-inner:focus-within {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:focus-within\:shadow-none:focus-within {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:hover\:shadow-sm:hover {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:hover\:shadow:hover {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:hover\:shadow-md:hover {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:hover\:shadow-lg:hover {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:hover\:shadow-xl:hover {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:hover\:shadow-2xl:hover {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:hover\:shadow-inner:hover {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:hover\:shadow-none:hover {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:focus\:shadow-sm:focus {
    --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:focus\:shadow:focus {
    --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:focus\:shadow-md:focus {
    --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:focus\:shadow-lg:focus {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
      0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:focus\:shadow-xl:focus {
    --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:focus\:shadow-2xl:focus {
    --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:focus\:shadow-inner:focus {
    --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:focus\:shadow-none:focus {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  .\32xl\:outline-none {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .\32xl\:outline-white {
    outline: 2px dotted white;
    outline-offset: 2px;
  }
  .\32xl\:outline-black {
    outline: 2px dotted black;
    outline-offset: 2px;
  }
  .\32xl\:focus-within\:outline-none:focus-within {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .\32xl\:focus-within\:outline-white:focus-within {
    outline: 2px dotted white;
    outline-offset: 2px;
  }
  .\32xl\:focus-within\:outline-black:focus-within {
    outline: 2px dotted black;
    outline-offset: 2px;
  }
  .\32xl\:focus\:outline-none:focus {
    outline: 2px solid transparent;
    outline-offset: 2px;
  }
  .\32xl\:focus\:outline-white:focus {
    outline: 2px dotted white;
    outline-offset: 2px;
  }
  .\32xl\:focus\:outline-black:focus {
    outline: 2px dotted black;
    outline-offset: 2px;
  }
  .\32xl\:ring-0 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:ring-1 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:ring-2 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:ring-4 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:ring-8 {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:ring {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:focus-within\:ring-0:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:focus-within\:ring-1:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:focus-within\:ring-2:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:focus-within\:ring-4:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:focus-within\:ring-8:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:focus-within\:ring:focus-within {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:focus\:ring-0:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:focus\:ring-1:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:focus\:ring-2:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:focus\:ring-4:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:focus\:ring-8:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:focus\:ring:focus {
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
      var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
      calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
      var(--tw-shadow, 0 0 #0000);
  }
  .\32xl\:ring-inset {
    --tw-ring-inset: inset;
  }
  .\32xl\:focus-within\:ring-inset:focus-within {
    --tw-ring-inset: inset;
  }
  .\32xl\:focus\:ring-inset:focus {
    --tw-ring-inset: inset;
  }
  .\32xl\:ring-transparent {
    --tw-ring-color: transparent;
  }
  .\32xl\:ring-current {
    --tw-ring-color: currentColor;
  }
  .\32xl\:ring-black {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .\32xl\:ring-white {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .\32xl\:ring-gray-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .\32xl\:ring-red-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .\32xl\:ring-yellow-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .\32xl\:ring-green-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .\32xl\:ring-blue-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .\32xl\:ring-indigo-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .\32xl\:ring-purple-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-50 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-100 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-200 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-300 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-400 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-500 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-600 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-700 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-800 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .\32xl\:ring-pink-900 {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-transparent:focus-within {
    --tw-ring-color: transparent;
  }
  .\32xl\:focus-within\:ring-current:focus-within {
    --tw-ring-color: currentColor;
  }
  .\32xl\:focus-within\:ring-black:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-white:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-gray-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-red-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-yellow-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-green-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-blue-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-indigo-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-purple-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-50:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-100:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-200:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-300:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-400:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-500:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-600:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-700:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-800:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .\32xl\:focus-within\:ring-pink-900:focus-within {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-transparent:focus {
    --tw-ring-color: transparent;
  }
  .\32xl\:focus\:ring-current:focus {
    --tw-ring-color: currentColor;
  }
  .\32xl\:focus\:ring-black:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(0, 0, 0, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-white:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 255, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 250, 251, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(243, 244, 246, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(229, 231, 235, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 213, 219, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(156, 163, 175, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(107, 114, 128, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(75, 85, 99, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 65, 81, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-gray-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(17, 24, 39, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 242, 242, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 226, 226, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 202, 202, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 165, 165, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(248, 113, 113, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(220, 38, 38, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(185, 28, 28, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(153, 27, 27, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-red-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(127, 29, 29, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(255, 251, 235, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(254, 243, 199, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 230, 138, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 211, 77, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 191, 36, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 158, 11, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(217, 119, 6, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(180, 83, 9, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(146, 64, 14, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-yellow-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(120, 53, 15, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 253, 245, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(209, 250, 229, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 243, 208, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(110, 231, 183, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(52, 211, 153, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(16, 185, 129, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(5, 150, 105, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(4, 120, 87, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 95, 70, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-green-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(6, 78, 59, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(239, 246, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 234, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(191, 219, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(147, 197, 253, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(96, 165, 250, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(37, 99, 235, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(29, 78, 216, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 64, 175, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-blue-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(30, 58, 138, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(238, 242, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(224, 231, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(199, 210, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(165, 180, 252, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(129, 140, 248, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(99, 102, 241, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(79, 70, 229, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(67, 56, 202, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(55, 48, 163, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-indigo-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(49, 46, 129, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(245, 243, 255, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(237, 233, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(221, 214, 254, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(196, 181, 253, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(167, 139, 250, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(139, 92, 246, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(124, 58, 237, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(109, 40, 217, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(91, 33, 182, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-purple-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(76, 29, 149, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-50:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(253, 242, 248, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-100:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(252, 231, 243, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-200:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(251, 207, 232, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-300:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(249, 168, 212, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-400:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(244, 114, 182, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-500:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(236, 72, 153, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-600:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(219, 39, 119, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-700:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(190, 24, 93, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-800:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(157, 23, 77, var(--tw-ring-opacity));
  }
  .\32xl\:focus\:ring-pink-900:focus {
    --tw-ring-opacity: 1;
    --tw-ring-color: rgba(131, 24, 67, var(--tw-ring-opacity));
  }
  .\32xl\:ring-opacity-0 {
    --tw-ring-opacity: 0;
  }
  .\32xl\:ring-opacity-5 {
    --tw-ring-opacity: 0.05;
  }
  .\32xl\:ring-opacity-10 {
    --tw-ring-opacity: 0.1;
  }
  .\32xl\:ring-opacity-20 {
    --tw-ring-opacity: 0.2;
  }
  .\32xl\:ring-opacity-25 {
    --tw-ring-opacity: 0.25;
  }
  .\32xl\:ring-opacity-30 {
    --tw-ring-opacity: 0.3;
  }
  .\32xl\:ring-opacity-40 {
    --tw-ring-opacity: 0.4;
  }
  .\32xl\:ring-opacity-50 {
    --tw-ring-opacity: 0.5;
  }
  .\32xl\:ring-opacity-60 {
    --tw-ring-opacity: 0.6;
  }
  .\32xl\:ring-opacity-70 {
    --tw-ring-opacity: 0.7;
  }
  .\32xl\:ring-opacity-75 {
    --tw-ring-opacity: 0.75;
  }
  .\32xl\:ring-opacity-80 {
    --tw-ring-opacity: 0.8;
  }
  .\32xl\:ring-opacity-90 {
    --tw-ring-opacity: 0.9;
  }
  .\32xl\:ring-opacity-95 {
    --tw-ring-opacity: 0.95;
  }
  .\32xl\:ring-opacity-100 {
    --tw-ring-opacity: 1;
  }
  .\32xl\:focus-within\:ring-opacity-0:focus-within {
    --tw-ring-opacity: 0;
  }
  .\32xl\:focus-within\:ring-opacity-5:focus-within {
    --tw-ring-opacity: 0.05;
  }
  .\32xl\:focus-within\:ring-opacity-10:focus-within {
    --tw-ring-opacity: 0.1;
  }
  .\32xl\:focus-within\:ring-opacity-20:focus-within {
    --tw-ring-opacity: 0.2;
  }
  .\32xl\:focus-within\:ring-opacity-25:focus-within {
    --tw-ring-opacity: 0.25;
  }
  .\32xl\:focus-within\:ring-opacity-30:focus-within {
    --tw-ring-opacity: 0.3;
  }
  .\32xl\:focus-within\:ring-opacity-40:focus-within {
    --tw-ring-opacity: 0.4;
  }
  .\32xl\:focus-within\:ring-opacity-50:focus-within {
    --tw-ring-opacity: 0.5;
  }
  .\32xl\:focus-within\:ring-opacity-60:focus-within {
    --tw-ring-opacity: 0.6;
  }
  .\32xl\:focus-within\:ring-opacity-70:focus-within {
    --tw-ring-opacity: 0.7;
  }
  .\32xl\:focus-within\:ring-opacity-75:focus-within {
    --tw-ring-opacity: 0.75;
  }
  .\32xl\:focus-within\:ring-opacity-80:focus-within {
    --tw-ring-opacity: 0.8;
  }
  .\32xl\:focus-within\:ring-opacity-90:focus-within {
    --tw-ring-opacity: 0.9;
  }
  .\32xl\:focus-within\:ring-opacity-95:focus-within {
    --tw-ring-opacity: 0.95;
  }
  .\32xl\:focus-within\:ring-opacity-100:focus-within {
    --tw-ring-opacity: 1;
  }
  .\32xl\:focus\:ring-opacity-0:focus {
    --tw-ring-opacity: 0;
  }
  .\32xl\:focus\:ring-opacity-5:focus {
    --tw-ring-opacity: 0.05;
  }
  .\32xl\:focus\:ring-opacity-10:focus {
    --tw-ring-opacity: 0.1;
  }
  .\32xl\:focus\:ring-opacity-20:focus {
    --tw-ring-opacity: 0.2;
  }
  .\32xl\:focus\:ring-opacity-25:focus {
    --tw-ring-opacity: 0.25;
  }
  .\32xl\:focus\:ring-opacity-30:focus {
    --tw-ring-opacity: 0.3;
  }
  .\32xl\:focus\:ring-opacity-40:focus {
    --tw-ring-opacity: 0.4;
  }
  .\32xl\:focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }
  .\32xl\:focus\:ring-opacity-60:focus {
    --tw-ring-opacity: 0.6;
  }
  .\32xl\:focus\:ring-opacity-70:focus {
    --tw-ring-opacity: 0.7;
  }
  .\32xl\:focus\:ring-opacity-75:focus {
    --tw-ring-opacity: 0.75;
  }
  .\32xl\:focus\:ring-opacity-80:focus {
    --tw-ring-opacity: 0.8;
  }
  .\32xl\:focus\:ring-opacity-90:focus {
    --tw-ring-opacity: 0.9;
  }
  .\32xl\:focus\:ring-opacity-95:focus {
    --tw-ring-opacity: 0.95;
  }
  .\32xl\:focus\:ring-opacity-100:focus {
    --tw-ring-opacity: 1;
  }
  .\32xl\:ring-offset-0 {
    --tw-ring-offset-width: 0px;
  }
  .\32xl\:ring-offset-1 {
    --tw-ring-offset-width: 1px;
  }
  .\32xl\:ring-offset-2 {
    --tw-ring-offset-width: 2px;
  }
  .\32xl\:ring-offset-4 {
    --tw-ring-offset-width: 4px;
  }
  .\32xl\:ring-offset-8 {
    --tw-ring-offset-width: 8px;
  }
  .\32xl\:focus-within\:ring-offset-0:focus-within {
    --tw-ring-offset-width: 0px;
  }
  .\32xl\:focus-within\:ring-offset-1:focus-within {
    --tw-ring-offset-width: 1px;
  }
  .\32xl\:focus-within\:ring-offset-2:focus-within {
    --tw-ring-offset-width: 2px;
  }
  .\32xl\:focus-within\:ring-offset-4:focus-within {
    --tw-ring-offset-width: 4px;
  }
  .\32xl\:focus-within\:ring-offset-8:focus-within {
    --tw-ring-offset-width: 8px;
  }
  .\32xl\:focus\:ring-offset-0:focus {
    --tw-ring-offset-width: 0px;
  }
  .\32xl\:focus\:ring-offset-1:focus {
    --tw-ring-offset-width: 1px;
  }
  .\32xl\:focus\:ring-offset-2:focus {
    --tw-ring-offset-width: 2px;
  }
  .\32xl\:focus\:ring-offset-4:focus {
    --tw-ring-offset-width: 4px;
  }
  .\32xl\:focus\:ring-offset-8:focus {
    --tw-ring-offset-width: 8px;
  }
  .\32xl\:ring-offset-transparent {
    --tw-ring-offset-color: transparent;
  }
  .\32xl\:ring-offset-current {
    --tw-ring-offset-color: currentColor;
  }
  .\32xl\:ring-offset-black {
    --tw-ring-offset-color: #000;
  }
  .\32xl\:ring-offset-white {
    --tw-ring-offset-color: #fff;
  }
  .\32xl\:ring-offset-gray-50 {
    --tw-ring-offset-color: #f9fafb;
  }
  .\32xl\:ring-offset-gray-100 {
    --tw-ring-offset-color: #f3f4f6;
  }
  .\32xl\:ring-offset-gray-200 {
    --tw-ring-offset-color: #e5e7eb;
  }
  .\32xl\:ring-offset-gray-300 {
    --tw-ring-offset-color: #d1d5db;
  }
  .\32xl\:ring-offset-gray-400 {
    --tw-ring-offset-color: #9ca3af;
  }
  .\32xl\:ring-offset-gray-500 {
    --tw-ring-offset-color: #6b7280;
  }
  .\32xl\:ring-offset-gray-600 {
    --tw-ring-offset-color: #4b5563;
  }
  .\32xl\:ring-offset-gray-700 {
    --tw-ring-offset-color: #374151;
  }
  .\32xl\:ring-offset-gray-800 {
    --tw-ring-offset-color: #1f2937;
  }
  .\32xl\:ring-offset-gray-900 {
    --tw-ring-offset-color: #111827;
  }
  .\32xl\:ring-offset-red-50 {
    --tw-ring-offset-color: #fef2f2;
  }
  .\32xl\:ring-offset-red-100 {
    --tw-ring-offset-color: #fee2e2;
  }
  .\32xl\:ring-offset-red-200 {
    --tw-ring-offset-color: #fecaca;
  }
  .\32xl\:ring-offset-red-300 {
    --tw-ring-offset-color: #fca5a5;
  }
  .\32xl\:ring-offset-red-400 {
    --tw-ring-offset-color: #f87171;
  }
  .\32xl\:ring-offset-red-500 {
    --tw-ring-offset-color: #ef4444;
  }
  .\32xl\:ring-offset-red-600 {
    --tw-ring-offset-color: #dc2626;
  }
  .\32xl\:ring-offset-red-700 {
    --tw-ring-offset-color: #b91c1c;
  }
  .\32xl\:ring-offset-red-800 {
    --tw-ring-offset-color: #991b1b;
  }
  .\32xl\:ring-offset-red-900 {
    --tw-ring-offset-color: #7f1d1d;
  }
  .\32xl\:ring-offset-yellow-50 {
    --tw-ring-offset-color: #fffbeb;
  }
  .\32xl\:ring-offset-yellow-100 {
    --tw-ring-offset-color: #fef3c7;
  }
  .\32xl\:ring-offset-yellow-200 {
    --tw-ring-offset-color: #fde68a;
  }
  .\32xl\:ring-offset-yellow-300 {
    --tw-ring-offset-color: #fcd34d;
  }
  .\32xl\:ring-offset-yellow-400 {
    --tw-ring-offset-color: #fbbf24;
  }
  .\32xl\:ring-offset-yellow-500 {
    --tw-ring-offset-color: #f59e0b;
  }
  .\32xl\:ring-offset-yellow-600 {
    --tw-ring-offset-color: #d97706;
  }
  .\32xl\:ring-offset-yellow-700 {
    --tw-ring-offset-color: #b45309;
  }
  .\32xl\:ring-offset-yellow-800 {
    --tw-ring-offset-color: #92400e;
  }
  .\32xl\:ring-offset-yellow-900 {
    --tw-ring-offset-color: #78350f;
  }
  .\32xl\:ring-offset-green-50 {
    --tw-ring-offset-color: #ecfdf5;
  }
  .\32xl\:ring-offset-green-100 {
    --tw-ring-offset-color: #d1fae5;
  }
  .\32xl\:ring-offset-green-200 {
    --tw-ring-offset-color: #a7f3d0;
  }
  .\32xl\:ring-offset-green-300 {
    --tw-ring-offset-color: #6ee7b7;
  }
  .\32xl\:ring-offset-green-400 {
    --tw-ring-offset-color: #34d399;
  }
  .\32xl\:ring-offset-green-500 {
    --tw-ring-offset-color: #10b981;
  }
  .\32xl\:ring-offset-green-600 {
    --tw-ring-offset-color: #059669;
  }
  .\32xl\:ring-offset-green-700 {
    --tw-ring-offset-color: #047857;
  }
  .\32xl\:ring-offset-green-800 {
    --tw-ring-offset-color: #065f46;
  }
  .\32xl\:ring-offset-green-900 {
    --tw-ring-offset-color: #064e3b;
  }
  .\32xl\:ring-offset-blue-50 {
    --tw-ring-offset-color: #eff6ff;
  }
  .\32xl\:ring-offset-blue-100 {
    --tw-ring-offset-color: #dbeafe;
  }
  .\32xl\:ring-offset-blue-200 {
    --tw-ring-offset-color: #bfdbfe;
  }
  .\32xl\:ring-offset-blue-300 {
    --tw-ring-offset-color: #93c5fd;
  }
  .\32xl\:ring-offset-blue-400 {
    --tw-ring-offset-color: #60a5fa;
  }
  .\32xl\:ring-offset-blue-500 {
    --tw-ring-offset-color: #3b82f6;
  }
  .\32xl\:ring-offset-blue-600 {
    --tw-ring-offset-color: #2563eb;
  }
  .\32xl\:ring-offset-blue-700 {
    --tw-ring-offset-color: #1d4ed8;
  }
  .\32xl\:ring-offset-blue-800 {
    --tw-ring-offset-color: #1e40af;
  }
  .\32xl\:ring-offset-blue-900 {
    --tw-ring-offset-color: #1e3a8a;
  }
  .\32xl\:ring-offset-indigo-50 {
    --tw-ring-offset-color: #eef2ff;
  }
  .\32xl\:ring-offset-indigo-100 {
    --tw-ring-offset-color: #e0e7ff;
  }
  .\32xl\:ring-offset-indigo-200 {
    --tw-ring-offset-color: #c7d2fe;
  }
  .\32xl\:ring-offset-indigo-300 {
    --tw-ring-offset-color: #a5b4fc;
  }
  .\32xl\:ring-offset-indigo-400 {
    --tw-ring-offset-color: #818cf8;
  }
  .\32xl\:ring-offset-indigo-500 {
    --tw-ring-offset-color: #6366f1;
  }
  .\32xl\:ring-offset-indigo-600 {
    --tw-ring-offset-color: #4f46e5;
  }
  .\32xl\:ring-offset-indigo-700 {
    --tw-ring-offset-color: #4338ca;
  }
  .\32xl\:ring-offset-indigo-800 {
    --tw-ring-offset-color: #3730a3;
  }
  .\32xl\:ring-offset-indigo-900 {
    --tw-ring-offset-color: #312e81;
  }
  .\32xl\:ring-offset-purple-50 {
    --tw-ring-offset-color: #f5f3ff;
  }
  .\32xl\:ring-offset-purple-100 {
    --tw-ring-offset-color: #ede9fe;
  }
  .\32xl\:ring-offset-purple-200 {
    --tw-ring-offset-color: #ddd6fe;
  }
  .\32xl\:ring-offset-purple-300 {
    --tw-ring-offset-color: #c4b5fd;
  }
  .\32xl\:ring-offset-purple-400 {
    --tw-ring-offset-color: #a78bfa;
  }
  .\32xl\:ring-offset-purple-500 {
    --tw-ring-offset-color: #8b5cf6;
  }
  .\32xl\:ring-offset-purple-600 {
    --tw-ring-offset-color: #7c3aed;
  }
  .\32xl\:ring-offset-purple-700 {
    --tw-ring-offset-color: #6d28d9;
  }
  .\32xl\:ring-offset-purple-800 {
    --tw-ring-offset-color: #5b21b6;
  }
  .\32xl\:ring-offset-purple-900 {
    --tw-ring-offset-color: #4c1d95;
  }
  .\32xl\:ring-offset-pink-50 {
    --tw-ring-offset-color: #fdf2f8;
  }
  .\32xl\:ring-offset-pink-100 {
    --tw-ring-offset-color: #fce7f3;
  }
  .\32xl\:ring-offset-pink-200 {
    --tw-ring-offset-color: #fbcfe8;
  }
  .\32xl\:ring-offset-pink-300 {
    --tw-ring-offset-color: #f9a8d4;
  }
  .\32xl\:ring-offset-pink-400 {
    --tw-ring-offset-color: #f472b6;
  }
  .\32xl\:ring-offset-pink-500 {
    --tw-ring-offset-color: #ec4899;
  }
  .\32xl\:ring-offset-pink-600 {
    --tw-ring-offset-color: #db2777;
  }
  .\32xl\:ring-offset-pink-700 {
    --tw-ring-offset-color: #be185d;
  }
  .\32xl\:ring-offset-pink-800 {
    --tw-ring-offset-color: #9d174d;
  }
  .\32xl\:ring-offset-pink-900 {
    --tw-ring-offset-color: #831843;
  }
  .\32xl\:focus-within\:ring-offset-transparent:focus-within {
    --tw-ring-offset-color: transparent;
  }
  .\32xl\:focus-within\:ring-offset-current:focus-within {
    --tw-ring-offset-color: currentColor;
  }
  .\32xl\:focus-within\:ring-offset-black:focus-within {
    --tw-ring-offset-color: #000;
  }
  .\32xl\:focus-within\:ring-offset-white:focus-within {
    --tw-ring-offset-color: #fff;
  }
  .\32xl\:focus-within\:ring-offset-gray-50:focus-within {
    --tw-ring-offset-color: #f9fafb;
  }
  .\32xl\:focus-within\:ring-offset-gray-100:focus-within {
    --tw-ring-offset-color: #f3f4f6;
  }
  .\32xl\:focus-within\:ring-offset-gray-200:focus-within {
    --tw-ring-offset-color: #e5e7eb;
  }
  .\32xl\:focus-within\:ring-offset-gray-300:focus-within {
    --tw-ring-offset-color: #d1d5db;
  }
  .\32xl\:focus-within\:ring-offset-gray-400:focus-within {
    --tw-ring-offset-color: #9ca3af;
  }
  .\32xl\:focus-within\:ring-offset-gray-500:focus-within {
    --tw-ring-offset-color: #6b7280;
  }
  .\32xl\:focus-within\:ring-offset-gray-600:focus-within {
    --tw-ring-offset-color: #4b5563;
  }
  .\32xl\:focus-within\:ring-offset-gray-700:focus-within {
    --tw-ring-offset-color: #374151;
  }
  .\32xl\:focus-within\:ring-offset-gray-800:focus-within {
    --tw-ring-offset-color: #1f2937;
  }
  .\32xl\:focus-within\:ring-offset-gray-900:focus-within {
    --tw-ring-offset-color: #111827;
  }
  .\32xl\:focus-within\:ring-offset-red-50:focus-within {
    --tw-ring-offset-color: #fef2f2;
  }
  .\32xl\:focus-within\:ring-offset-red-100:focus-within {
    --tw-ring-offset-color: #fee2e2;
  }
  .\32xl\:focus-within\:ring-offset-red-200:focus-within {
    --tw-ring-offset-color: #fecaca;
  }
  .\32xl\:focus-within\:ring-offset-red-300:focus-within {
    --tw-ring-offset-color: #fca5a5;
  }
  .\32xl\:focus-within\:ring-offset-red-400:focus-within {
    --tw-ring-offset-color: #f87171;
  }
  .\32xl\:focus-within\:ring-offset-red-500:focus-within {
    --tw-ring-offset-color: #ef4444;
  }
  .\32xl\:focus-within\:ring-offset-red-600:focus-within {
    --tw-ring-offset-color: #dc2626;
  }
  .\32xl\:focus-within\:ring-offset-red-700:focus-within {
    --tw-ring-offset-color: #b91c1c;
  }
  .\32xl\:focus-within\:ring-offset-red-800:focus-within {
    --tw-ring-offset-color: #991b1b;
  }
  .\32xl\:focus-within\:ring-offset-red-900:focus-within {
    --tw-ring-offset-color: #7f1d1d;
  }
  .\32xl\:focus-within\:ring-offset-yellow-50:focus-within {
    --tw-ring-offset-color: #fffbeb;
  }
  .\32xl\:focus-within\:ring-offset-yellow-100:focus-within {
    --tw-ring-offset-color: #fef3c7;
  }
  .\32xl\:focus-within\:ring-offset-yellow-200:focus-within {
    --tw-ring-offset-color: #fde68a;
  }
  .\32xl\:focus-within\:ring-offset-yellow-300:focus-within {
    --tw-ring-offset-color: #fcd34d;
  }
  .\32xl\:focus-within\:ring-offset-yellow-400:focus-within {
    --tw-ring-offset-color: #fbbf24;
  }
  .\32xl\:focus-within\:ring-offset-yellow-500:focus-within {
    --tw-ring-offset-color: #f59e0b;
  }
  .\32xl\:focus-within\:ring-offset-yellow-600:focus-within {
    --tw-ring-offset-color: #d97706;
  }
  .\32xl\:focus-within\:ring-offset-yellow-700:focus-within {
    --tw-ring-offset-color: #b45309;
  }
  .\32xl\:focus-within\:ring-offset-yellow-800:focus-within {
    --tw-ring-offset-color: #92400e;
  }
  .\32xl\:focus-within\:ring-offset-yellow-900:focus-within {
    --tw-ring-offset-color: #78350f;
  }
  .\32xl\:focus-within\:ring-offset-green-50:focus-within {
    --tw-ring-offset-color: #ecfdf5;
  }
  .\32xl\:focus-within\:ring-offset-green-100:focus-within {
    --tw-ring-offset-color: #d1fae5;
  }
  .\32xl\:focus-within\:ring-offset-green-200:focus-within {
    --tw-ring-offset-color: #a7f3d0;
  }
  .\32xl\:focus-within\:ring-offset-green-300:focus-within {
    --tw-ring-offset-color: #6ee7b7;
  }
  .\32xl\:focus-within\:ring-offset-green-400:focus-within {
    --tw-ring-offset-color: #34d399;
  }
  .\32xl\:focus-within\:ring-offset-green-500:focus-within {
    --tw-ring-offset-color: #10b981;
  }
  .\32xl\:focus-within\:ring-offset-green-600:focus-within {
    --tw-ring-offset-color: #059669;
  }
  .\32xl\:focus-within\:ring-offset-green-700:focus-within {
    --tw-ring-offset-color: #047857;
  }
  .\32xl\:focus-within\:ring-offset-green-800:focus-within {
    --tw-ring-offset-color: #065f46;
  }
  .\32xl\:focus-within\:ring-offset-green-900:focus-within {
    --tw-ring-offset-color: #064e3b;
  }
  .\32xl\:focus-within\:ring-offset-blue-50:focus-within {
    --tw-ring-offset-color: #eff6ff;
  }
  .\32xl\:focus-within\:ring-offset-blue-100:focus-within {
    --tw-ring-offset-color: #dbeafe;
  }
  .\32xl\:focus-within\:ring-offset-blue-200:focus-within {
    --tw-ring-offset-color: #bfdbfe;
  }
  .\32xl\:focus-within\:ring-offset-blue-300:focus-within {
    --tw-ring-offset-color: #93c5fd;
  }
  .\32xl\:focus-within\:ring-offset-blue-400:focus-within {
    --tw-ring-offset-color: #60a5fa;
  }
  .\32xl\:focus-within\:ring-offset-blue-500:focus-within {
    --tw-ring-offset-color: #3b82f6;
  }
  .\32xl\:focus-within\:ring-offset-blue-600:focus-within {
    --tw-ring-offset-color: #2563eb;
  }
  .\32xl\:focus-within\:ring-offset-blue-700:focus-within {
    --tw-ring-offset-color: #1d4ed8;
  }
  .\32xl\:focus-within\:ring-offset-blue-800:focus-within {
    --tw-ring-offset-color: #1e40af;
  }
  .\32xl\:focus-within\:ring-offset-blue-900:focus-within {
    --tw-ring-offset-color: #1e3a8a;
  }
  .\32xl\:focus-within\:ring-offset-indigo-50:focus-within {
    --tw-ring-offset-color: #eef2ff;
  }
  .\32xl\:focus-within\:ring-offset-indigo-100:focus-within {
    --tw-ring-offset-color: #e0e7ff;
  }
  .\32xl\:focus-within\:ring-offset-indigo-200:focus-within {
    --tw-ring-offset-color: #c7d2fe;
  }
  .\32xl\:focus-within\:ring-offset-indigo-300:focus-within {
    --tw-ring-offset-color: #a5b4fc;
  }
  .\32xl\:focus-within\:ring-offset-indigo-400:focus-within {
    --tw-ring-offset-color: #818cf8;
  }
  .\32xl\:focus-within\:ring-offset-indigo-500:focus-within {
    --tw-ring-offset-color: #6366f1;
  }
  .\32xl\:focus-within\:ring-offset-indigo-600:focus-within {
    --tw-ring-offset-color: #4f46e5;
  }
  .\32xl\:focus-within\:ring-offset-indigo-700:focus-within {
    --tw-ring-offset-color: #4338ca;
  }
  .\32xl\:focus-within\:ring-offset-indigo-800:focus-within {
    --tw-ring-offset-color: #3730a3;
  }
  .\32xl\:focus-within\:ring-offset-indigo-900:focus-within {
    --tw-ring-offset-color: #312e81;
  }
  .\32xl\:focus-within\:ring-offset-purple-50:focus-within {
    --tw-ring-offset-color: #f5f3ff;
  }
  .\32xl\:focus-within\:ring-offset-purple-100:focus-within {
    --tw-ring-offset-color: #ede9fe;
  }
  .\32xl\:focus-within\:ring-offset-purple-200:focus-within {
    --tw-ring-offset-color: #ddd6fe;
  }
  .\32xl\:focus-within\:ring-offset-purple-300:focus-within {
    --tw-ring-offset-color: #c4b5fd;
  }
  .\32xl\:focus-within\:ring-offset-purple-400:focus-within {
    --tw-ring-offset-color: #a78bfa;
  }
  .\32xl\:focus-within\:ring-offset-purple-500:focus-within {
    --tw-ring-offset-color: #8b5cf6;
  }
  .\32xl\:focus-within\:ring-offset-purple-600:focus-within {
    --tw-ring-offset-color: #7c3aed;
  }
  .\32xl\:focus-within\:ring-offset-purple-700:focus-within {
    --tw-ring-offset-color: #6d28d9;
  }
  .\32xl\:focus-within\:ring-offset-purple-800:focus-within {
    --tw-ring-offset-color: #5b21b6;
  }
  .\32xl\:focus-within\:ring-offset-purple-900:focus-within {
    --tw-ring-offset-color: #4c1d95;
  }
  .\32xl\:focus-within\:ring-offset-pink-50:focus-within {
    --tw-ring-offset-color: #fdf2f8;
  }
  .\32xl\:focus-within\:ring-offset-pink-100:focus-within {
    --tw-ring-offset-color: #fce7f3;
  }
  .\32xl\:focus-within\:ring-offset-pink-200:focus-within {
    --tw-ring-offset-color: #fbcfe8;
  }
  .\32xl\:focus-within\:ring-offset-pink-300:focus-within {
    --tw-ring-offset-color: #f9a8d4;
  }
  .\32xl\:focus-within\:ring-offset-pink-400:focus-within {
    --tw-ring-offset-color: #f472b6;
  }
  .\32xl\:focus-within\:ring-offset-pink-500:focus-within {
    --tw-ring-offset-color: #ec4899;
  }
  .\32xl\:focus-within\:ring-offset-pink-600:focus-within {
    --tw-ring-offset-color: #db2777;
  }
  .\32xl\:focus-within\:ring-offset-pink-700:focus-within {
    --tw-ring-offset-color: #be185d;
  }
  .\32xl\:focus-within\:ring-offset-pink-800:focus-within {
    --tw-ring-offset-color: #9d174d;
  }
  .\32xl\:focus-within\:ring-offset-pink-900:focus-within {
    --tw-ring-offset-color: #831843;
  }
  .\32xl\:focus\:ring-offset-transparent:focus {
    --tw-ring-offset-color: transparent;
  }
  .\32xl\:focus\:ring-offset-current:focus {
    --tw-ring-offset-color: currentColor;
  }
  .\32xl\:focus\:ring-offset-black:focus {
    --tw-ring-offset-color: #000;
  }
  .\32xl\:focus\:ring-offset-white:focus {
    --tw-ring-offset-color: #fff;
  }
  .\32xl\:focus\:ring-offset-gray-50:focus {
    --tw-ring-offset-color: #f9fafb;
  }
  .\32xl\:focus\:ring-offset-gray-100:focus {
    --tw-ring-offset-color: #f3f4f6;
  }
  .\32xl\:focus\:ring-offset-gray-200:focus {
    --tw-ring-offset-color: #e5e7eb;
  }
  .\32xl\:focus\:ring-offset-gray-300:focus {
    --tw-ring-offset-color: #d1d5db;
  }
  .\32xl\:focus\:ring-offset-gray-400:focus {
    --tw-ring-offset-color: #9ca3af;
  }
  .\32xl\:focus\:ring-offset-gray-500:focus {
    --tw-ring-offset-color: #6b7280;
  }
  .\32xl\:focus\:ring-offset-gray-600:focus {
    --tw-ring-offset-color: #4b5563;
  }
  .\32xl\:focus\:ring-offset-gray-700:focus {
    --tw-ring-offset-color: #374151;
  }
  .\32xl\:focus\:ring-offset-gray-800:focus {
    --tw-ring-offset-color: #1f2937;
  }
  .\32xl\:focus\:ring-offset-gray-900:focus {
    --tw-ring-offset-color: #111827;
  }
  .\32xl\:focus\:ring-offset-red-50:focus {
    --tw-ring-offset-color: #fef2f2;
  }
  .\32xl\:focus\:ring-offset-red-100:focus {
    --tw-ring-offset-color: #fee2e2;
  }
  .\32xl\:focus\:ring-offset-red-200:focus {
    --tw-ring-offset-color: #fecaca;
  }
  .\32xl\:focus\:ring-offset-red-300:focus {
    --tw-ring-offset-color: #fca5a5;
  }
  .\32xl\:focus\:ring-offset-red-400:focus {
    --tw-ring-offset-color: #f87171;
  }
  .\32xl\:focus\:ring-offset-red-500:focus {
    --tw-ring-offset-color: #ef4444;
  }
  .\32xl\:focus\:ring-offset-red-600:focus {
    --tw-ring-offset-color: #dc2626;
  }
  .\32xl\:focus\:ring-offset-red-700:focus {
    --tw-ring-offset-color: #b91c1c;
  }
  .\32xl\:focus\:ring-offset-red-800:focus {
    --tw-ring-offset-color: #991b1b;
  }
  .\32xl\:focus\:ring-offset-red-900:focus {
    --tw-ring-offset-color: #7f1d1d;
  }
  .\32xl\:focus\:ring-offset-yellow-50:focus {
    --tw-ring-offset-color: #fffbeb;
  }
  .\32xl\:focus\:ring-offset-yellow-100:focus {
    --tw-ring-offset-color: #fef3c7;
  }
  .\32xl\:focus\:ring-offset-yellow-200:focus {
    --tw-ring-offset-color: #fde68a;
  }
  .\32xl\:focus\:ring-offset-yellow-300:focus {
    --tw-ring-offset-color: #fcd34d;
  }
  .\32xl\:focus\:ring-offset-yellow-400:focus {
    --tw-ring-offset-color: #fbbf24;
  }
  .\32xl\:focus\:ring-offset-yellow-500:focus {
    --tw-ring-offset-color: #f59e0b;
  }
  .\32xl\:focus\:ring-offset-yellow-600:focus {
    --tw-ring-offset-color: #d97706;
  }
  .\32xl\:focus\:ring-offset-yellow-700:focus {
    --tw-ring-offset-color: #b45309;
  }
  .\32xl\:focus\:ring-offset-yellow-800:focus {
    --tw-ring-offset-color: #92400e;
  }
  .\32xl\:focus\:ring-offset-yellow-900:focus {
    --tw-ring-offset-color: #78350f;
  }
  .\32xl\:focus\:ring-offset-green-50:focus {
    --tw-ring-offset-color: #ecfdf5;
  }
  .\32xl\:focus\:ring-offset-green-100:focus {
    --tw-ring-offset-color: #d1fae5;
  }
  .\32xl\:focus\:ring-offset-green-200:focus {
    --tw-ring-offset-color: #a7f3d0;
  }
  .\32xl\:focus\:ring-offset-green-300:focus {
    --tw-ring-offset-color: #6ee7b7;
  }
  .\32xl\:focus\:ring-offset-green-400:focus {
    --tw-ring-offset-color: #34d399;
  }
  .\32xl\:focus\:ring-offset-green-500:focus {
    --tw-ring-offset-color: #10b981;
  }
  .\32xl\:focus\:ring-offset-green-600:focus {
    --tw-ring-offset-color: #059669;
  }
  .\32xl\:focus\:ring-offset-green-700:focus {
    --tw-ring-offset-color: #047857;
  }
  .\32xl\:focus\:ring-offset-green-800:focus {
    --tw-ring-offset-color: #065f46;
  }
  .\32xl\:focus\:ring-offset-green-900:focus {
    --tw-ring-offset-color: #064e3b;
  }
  .\32xl\:focus\:ring-offset-blue-50:focus {
    --tw-ring-offset-color: #eff6ff;
  }
  .\32xl\:focus\:ring-offset-blue-100:focus {
    --tw-ring-offset-color: #dbeafe;
  }
  .\32xl\:focus\:ring-offset-blue-200:focus {
    --tw-ring-offset-color: #bfdbfe;
  }
  .\32xl\:focus\:ring-offset-blue-300:focus {
    --tw-ring-offset-color: #93c5fd;
  }
  .\32xl\:focus\:ring-offset-blue-400:focus {
    --tw-ring-offset-color: #60a5fa;
  }
  .\32xl\:focus\:ring-offset-blue-500:focus {
    --tw-ring-offset-color: #3b82f6;
  }
  .\32xl\:focus\:ring-offset-blue-600:focus {
    --tw-ring-offset-color: #2563eb;
  }
  .\32xl\:focus\:ring-offset-blue-700:focus {
    --tw-ring-offset-color: #1d4ed8;
  }
  .\32xl\:focus\:ring-offset-blue-800:focus {
    --tw-ring-offset-color: #1e40af;
  }
  .\32xl\:focus\:ring-offset-blue-900:focus {
    --tw-ring-offset-color: #1e3a8a;
  }
  .\32xl\:focus\:ring-offset-indigo-50:focus {
    --tw-ring-offset-color: #eef2ff;
  }
  .\32xl\:focus\:ring-offset-indigo-100:focus {
    --tw-ring-offset-color: #e0e7ff;
  }
  .\32xl\:focus\:ring-offset-indigo-200:focus {
    --tw-ring-offset-color: #c7d2fe;
  }
  .\32xl\:focus\:ring-offset-indigo-300:focus {
    --tw-ring-offset-color: #a5b4fc;
  }
  .\32xl\:focus\:ring-offset-indigo-400:focus {
    --tw-ring-offset-color: #818cf8;
  }
  .\32xl\:focus\:ring-offset-indigo-500:focus {
    --tw-ring-offset-color: #6366f1;
  }
  .\32xl\:focus\:ring-offset-indigo-600:focus {
    --tw-ring-offset-color: #4f46e5;
  }
  .\32xl\:focus\:ring-offset-indigo-700:focus {
    --tw-ring-offset-color: #4338ca;
  }
  .\32xl\:focus\:ring-offset-indigo-800:focus {
    --tw-ring-offset-color: #3730a3;
  }
  .\32xl\:focus\:ring-offset-indigo-900:focus {
    --tw-ring-offset-color: #312e81;
  }
  .\32xl\:focus\:ring-offset-purple-50:focus {
    --tw-ring-offset-color: #f5f3ff;
  }
  .\32xl\:focus\:ring-offset-purple-100:focus {
    --tw-ring-offset-color: #ede9fe;
  }
  .\32xl\:focus\:ring-offset-purple-200:focus {
    --tw-ring-offset-color: #ddd6fe;
  }
  .\32xl\:focus\:ring-offset-purple-300:focus {
    --tw-ring-offset-color: #c4b5fd;
  }
  .\32xl\:focus\:ring-offset-purple-400:focus {
    --tw-ring-offset-color: #a78bfa;
  }
  .\32xl\:focus\:ring-offset-purple-500:focus {
    --tw-ring-offset-color: #8b5cf6;
  }
  .\32xl\:focus\:ring-offset-purple-600:focus {
    --tw-ring-offset-color: #7c3aed;
  }
  .\32xl\:focus\:ring-offset-purple-700:focus {
    --tw-ring-offset-color: #6d28d9;
  }
  .\32xl\:focus\:ring-offset-purple-800:focus {
    --tw-ring-offset-color: #5b21b6;
  }
  .\32xl\:focus\:ring-offset-purple-900:focus {
    --tw-ring-offset-color: #4c1d95;
  }
  .\32xl\:focus\:ring-offset-pink-50:focus {
    --tw-ring-offset-color: #fdf2f8;
  }
  .\32xl\:focus\:ring-offset-pink-100:focus {
    --tw-ring-offset-color: #fce7f3;
  }
  .\32xl\:focus\:ring-offset-pink-200:focus {
    --tw-ring-offset-color: #fbcfe8;
  }
  .\32xl\:focus\:ring-offset-pink-300:focus {
    --tw-ring-offset-color: #f9a8d4;
  }
  .\32xl\:focus\:ring-offset-pink-400:focus {
    --tw-ring-offset-color: #f472b6;
  }
  .\32xl\:focus\:ring-offset-pink-500:focus {
    --tw-ring-offset-color: #ec4899;
  }
  .\32xl\:focus\:ring-offset-pink-600:focus {
    --tw-ring-offset-color: #db2777;
  }
  .\32xl\:focus\:ring-offset-pink-700:focus {
    --tw-ring-offset-color: #be185d;
  }
  .\32xl\:focus\:ring-offset-pink-800:focus {
    --tw-ring-offset-color: #9d174d;
  }
  .\32xl\:focus\:ring-offset-pink-900:focus {
    --tw-ring-offset-color: #831843;
  }
  .\32xl\:filter {
    --tw-blur: var(--tw-empty); /*!*/ /*!*/
    --tw-brightness: var(--tw-empty); /*!*/ /*!*/
    --tw-contrast: var(--tw-empty); /*!*/ /*!*/
    --tw-grayscale: var(--tw-empty); /*!*/ /*!*/
    --tw-hue-rotate: var(--tw-empty); /*!*/ /*!*/
    --tw-invert: var(--tw-empty); /*!*/ /*!*/
    --tw-saturate: var(--tw-empty); /*!*/ /*!*/
    --tw-sepia: var(--tw-empty); /*!*/ /*!*/
    --tw-drop-shadow: var(--tw-empty); /*!*/ /*!*/
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast)
      var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert)
      var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }
  .\32xl\:filter-none {
    filter: none;
  }
  .\32xl\:blur-0 {
    --tw-blur: blur(0);
  }
  .\32xl\:blur-none {
    --tw-blur: blur(0);
  }
  .\32xl\:blur-sm {
    --tw-blur: blur(4px);
  }
  .\32xl\:blur {
    --tw-blur: blur(8px);
  }
  .\32xl\:blur-md {
    --tw-blur: blur(12px);
  }
  .\32xl\:blur-lg {
    --tw-blur: blur(16px);
  }
  .\32xl\:blur-xl {
    --tw-blur: blur(24px);
  }
  .\32xl\:blur-2xl {
    --tw-blur: blur(40px);
  }
  .\32xl\:blur-3xl {
    --tw-blur: blur(64px);
  }
  .\32xl\:brightness-0 {
    --tw-brightness: brightness(0);
  }
  .\32xl\:brightness-50 {
    --tw-brightness: brightness(0.5);
  }
  .\32xl\:brightness-75 {
    --tw-brightness: brightness(0.75);
  }
  .\32xl\:brightness-90 {
    --tw-brightness: brightness(0.9);
  }
  .\32xl\:brightness-95 {
    --tw-brightness: brightness(0.95);
  }
  .\32xl\:brightness-100 {
    --tw-brightness: brightness(1);
  }
  .\32xl\:brightness-105 {
    --tw-brightness: brightness(1.05);
  }
  .\32xl\:brightness-110 {
    --tw-brightness: brightness(1.1);
  }
  .\32xl\:brightness-125 {
    --tw-brightness: brightness(1.25);
  }
  .\32xl\:brightness-150 {
    --tw-brightness: brightness(1.5);
  }
  .\32xl\:brightness-200 {
    --tw-brightness: brightness(2);
  }
  .\32xl\:contrast-0 {
    --tw-contrast: contrast(0);
  }
  .\32xl\:contrast-50 {
    --tw-contrast: contrast(0.5);
  }
  .\32xl\:contrast-75 {
    --tw-contrast: contrast(0.75);
  }
  .\32xl\:contrast-100 {
    --tw-contrast: contrast(1);
  }
  .\32xl\:contrast-125 {
    --tw-contrast: contrast(1.25);
  }
  .\32xl\:contrast-150 {
    --tw-contrast: contrast(1.5);
  }
  .\32xl\:contrast-200 {
    --tw-contrast: contrast(2);
  }
  .\32xl\:drop-shadow-sm {
    --tw-drop-shadow: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.05));
  }
  .\32xl\:drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1))
      drop-shadow(0 1px 1px rgba(0, 0, 0, 0.06));
  }
  .\32xl\:drop-shadow-md {
    --tw-drop-shadow: drop-shadow(0 4px 3px rgba(0, 0, 0, 0.07))
      drop-shadow(0 2px 2px rgba(0, 0, 0, 0.06));
  }
  .\32xl\:drop-shadow-lg {
    --tw-drop-shadow: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04))
      drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));
  }
  .\32xl\:drop-shadow-xl {
    --tw-drop-shadow: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03))
      drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  }
  .\32xl\:drop-shadow-2xl {
    --tw-drop-shadow: drop-shadow(0 25px 25px rgba(0, 0, 0, 0.15));
  }
  .\32xl\:drop-shadow-none {
    --tw-drop-shadow: drop-shadow(0 0 #0000);
  }
  .\32xl\:grayscale-0 {
    --tw-grayscale: grayscale(0);
  }
  .\32xl\:grayscale {
    --tw-grayscale: grayscale(100%);
  }
  .\32xl\:hue-rotate-0 {
    --tw-hue-rotate: hue-rotate(0deg);
  }
  .\32xl\:hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(15deg);
  }
  .\32xl\:hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(30deg);
  }
  .\32xl\:hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(60deg);
  }
  .\32xl\:hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(90deg);
  }
  .\32xl\:hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(180deg);
  }
  .\32xl\:-hue-rotate-180 {
    --tw-hue-rotate: hue-rotate(-180deg);
  }
  .\32xl\:-hue-rotate-90 {
    --tw-hue-rotate: hue-rotate(-90deg);
  }
  .\32xl\:-hue-rotate-60 {
    --tw-hue-rotate: hue-rotate(-60deg);
  }
  .\32xl\:-hue-rotate-30 {
    --tw-hue-rotate: hue-rotate(-30deg);
  }
  .\32xl\:-hue-rotate-15 {
    --tw-hue-rotate: hue-rotate(-15deg);
  }
  .\32xl\:invert-0 {
    --tw-invert: invert(0);
  }
  .\32xl\:invert {
    --tw-invert: invert(100%);
  }
  .\32xl\:saturate-0 {
    --tw-saturate: saturate(0);
  }
  .\32xl\:saturate-50 {
    --tw-saturate: saturate(0.5);
  }
  .\32xl\:saturate-100 {
    --tw-saturate: saturate(1);
  }
  .\32xl\:saturate-150 {
    --tw-saturate: saturate(1.5);
  }
  .\32xl\:saturate-200 {
    --tw-saturate: saturate(2);
  }
  .\32xl\:sepia-0 {
    --tw-sepia: sepia(0);
  }
  .\32xl\:sepia {
    --tw-sepia: sepia(100%);
  }
  .\32xl\:backdrop-filter {
    --tw-backdrop-blur: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-brightness: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-contrast: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-grayscale: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-hue-rotate: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-invert: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-opacity: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-saturate: var(--tw-empty); /*!*/ /*!*/
    --tw-backdrop-sepia: var(--tw-empty); /*!*/ /*!*/
    -webkit-backdrop-filter: var(--tw-backdrop-blur)
      var(--tw-backdrop-brightness) var(--tw-backdrop-contrast)
      var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate)
      var(--tw-backdrop-invert) var(--tw-backdrop-opacity)
      var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
    backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
      var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
      var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
      var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
      var(--tw-backdrop-sepia);
  }
  .\32xl\:backdrop-filter-none {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
  }
  .\32xl\:backdrop-blur-0 {
    --tw-backdrop-blur: blur(0);
  }
  .\32xl\:backdrop-blur-none {
    --tw-backdrop-blur: blur(0);
  }
  .\32xl\:backdrop-blur-sm {
    --tw-backdrop-blur: blur(4px);
  }
  .\32xl\:backdrop-blur {
    --tw-backdrop-blur: blur(8px);
  }
  .\32xl\:backdrop-blur-md {
    --tw-backdrop-blur: blur(12px);
  }
  .\32xl\:backdrop-blur-lg {
    --tw-backdrop-blur: blur(16px);
  }
  .\32xl\:backdrop-blur-xl {
    --tw-backdrop-blur: blur(24px);
  }
  .\32xl\:backdrop-blur-2xl {
    --tw-backdrop-blur: blur(40px);
  }
  .\32xl\:backdrop-blur-3xl {
    --tw-backdrop-blur: blur(64px);
  }
  .\32xl\:backdrop-brightness-0 {
    --tw-backdrop-brightness: brightness(0);
  }
  .\32xl\:backdrop-brightness-50 {
    --tw-backdrop-brightness: brightness(0.5);
  }
  .\32xl\:backdrop-brightness-75 {
    --tw-backdrop-brightness: brightness(0.75);
  }
  .\32xl\:backdrop-brightness-90 {
    --tw-backdrop-brightness: brightness(0.9);
  }
  .\32xl\:backdrop-brightness-95 {
    --tw-backdrop-brightness: brightness(0.95);
  }
  .\32xl\:backdrop-brightness-100 {
    --tw-backdrop-brightness: brightness(1);
  }
  .\32xl\:backdrop-brightness-105 {
    --tw-backdrop-brightness: brightness(1.05);
  }
  .\32xl\:backdrop-brightness-110 {
    --tw-backdrop-brightness: brightness(1.1);
  }
  .\32xl\:backdrop-brightness-125 {
    --tw-backdrop-brightness: brightness(1.25);
  }
  .\32xl\:backdrop-brightness-150 {
    --tw-backdrop-brightness: brightness(1.5);
  }
  .\32xl\:backdrop-brightness-200 {
    --tw-backdrop-brightness: brightness(2);
  }
  .\32xl\:backdrop-contrast-0 {
    --tw-backdrop-contrast: contrast(0);
  }
  .\32xl\:backdrop-contrast-50 {
    --tw-backdrop-contrast: contrast(0.5);
  }
  .\32xl\:backdrop-contrast-75 {
    --tw-backdrop-contrast: contrast(0.75);
  }
  .\32xl\:backdrop-contrast-100 {
    --tw-backdrop-contrast: contrast(1);
  }
  .\32xl\:backdrop-contrast-125 {
    --tw-backdrop-contrast: contrast(1.25);
  }
  .\32xl\:backdrop-contrast-150 {
    --tw-backdrop-contrast: contrast(1.5);
  }
  .\32xl\:backdrop-contrast-200 {
    --tw-backdrop-contrast: contrast(2);
  }
  .\32xl\:backdrop-grayscale-0 {
    --tw-backdrop-grayscale: grayscale(0);
  }
  .\32xl\:backdrop-grayscale {
    --tw-backdrop-grayscale: grayscale(100%);
  }
  .\32xl\:backdrop-hue-rotate-0 {
    --tw-backdrop-hue-rotate: hue-rotate(0deg);
  }
  .\32xl\:backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(15deg);
  }
  .\32xl\:backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(30deg);
  }
  .\32xl\:backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(60deg);
  }
  .\32xl\:backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(90deg);
  }
  .\32xl\:backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(180deg);
  }
  .\32xl\:-backdrop-hue-rotate-180 {
    --tw-backdrop-hue-rotate: hue-rotate(-180deg);
  }
  .\32xl\:-backdrop-hue-rotate-90 {
    --tw-backdrop-hue-rotate: hue-rotate(-90deg);
  }
  .\32xl\:-backdrop-hue-rotate-60 {
    --tw-backdrop-hue-rotate: hue-rotate(-60deg);
  }
  .\32xl\:-backdrop-hue-rotate-30 {
    --tw-backdrop-hue-rotate: hue-rotate(-30deg);
  }
  .\32xl\:-backdrop-hue-rotate-15 {
    --tw-backdrop-hue-rotate: hue-rotate(-15deg);
  }
  .\32xl\:backdrop-invert-0 {
    --tw-backdrop-invert: invert(0);
  }
  .\32xl\:backdrop-invert {
    --tw-backdrop-invert: invert(100%);
  }
  .\32xl\:backdrop-opacity-0 {
    --tw-backdrop-opacity: opacity(0);
  }
  .\32xl\:backdrop-opacity-5 {
    --tw-backdrop-opacity: opacity(0.05);
  }
  .\32xl\:backdrop-opacity-10 {
    --tw-backdrop-opacity: opacity(0.1);
  }
  .\32xl\:backdrop-opacity-20 {
    --tw-backdrop-opacity: opacity(0.2);
  }
  .\32xl\:backdrop-opacity-25 {
    --tw-backdrop-opacity: opacity(0.25);
  }
  .\32xl\:backdrop-opacity-30 {
    --tw-backdrop-opacity: opacity(0.3);
  }
  .\32xl\:backdrop-opacity-40 {
    --tw-backdrop-opacity: opacity(0.4);
  }
  .\32xl\:backdrop-opacity-50 {
    --tw-backdrop-opacity: opacity(0.5);
  }
  .\32xl\:backdrop-opacity-60 {
    --tw-backdrop-opacity: opacity(0.6);
  }
  .\32xl\:backdrop-opacity-70 {
    --tw-backdrop-opacity: opacity(0.7);
  }
  .\32xl\:backdrop-opacity-75 {
    --tw-backdrop-opacity: opacity(0.75);
  }
  .\32xl\:backdrop-opacity-80 {
    --tw-backdrop-opacity: opacity(0.8);
  }
  .\32xl\:backdrop-opacity-90 {
    --tw-backdrop-opacity: opacity(0.9);
  }
  .\32xl\:backdrop-opacity-95 {
    --tw-backdrop-opacity: opacity(0.95);
  }
  .\32xl\:backdrop-opacity-100 {
    --tw-backdrop-opacity: opacity(1);
  }
  .\32xl\:backdrop-saturate-0 {
    --tw-backdrop-saturate: saturate(0);
  }
  .\32xl\:backdrop-saturate-50 {
    --tw-backdrop-saturate: saturate(0.5);
  }
  .\32xl\:backdrop-saturate-100 {
    --tw-backdrop-saturate: saturate(1);
  }
  .\32xl\:backdrop-saturate-150 {
    --tw-backdrop-saturate: saturate(1.5);
  }
  .\32xl\:backdrop-saturate-200 {
    --tw-backdrop-saturate: saturate(2);
  }
  .\32xl\:backdrop-sepia-0 {
    --tw-backdrop-sepia: sepia(0);
  }
  .\32xl\:backdrop-sepia {
    --tw-backdrop-sepia: sepia(100%);
  }
  .\32xl\:transition-none {
    transition-property: none;
  }
  .\32xl\:transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .\32xl\:transition {
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, backdrop-filter;
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform, filter, backdrop-filter,
      -webkit-backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .\32xl\:transition-colors {
    transition-property: background-color, border-color, color, fill, stroke;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .\32xl\:transition-opacity {
    transition-property: opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .\32xl\:transition-shadow {
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .\32xl\:transition-transform {
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  .\32xl\:delay-75 {
    transition-delay: 75ms;
  }
  .\32xl\:delay-100 {
    transition-delay: 0.1s;
  }
  .\32xl\:delay-150 {
    transition-delay: 150ms;
  }
  .\32xl\:delay-200 {
    transition-delay: 0.2s;
  }
  .\32xl\:delay-300 {
    transition-delay: 0.3s;
  }
  .\32xl\:delay-500 {
    transition-delay: 0.5s;
  }
  .\32xl\:delay-700 {
    transition-delay: 0.7s;
  }
  .\32xl\:delay-1000 {
    transition-delay: 1s;
  }
  .\32xl\:duration-75 {
    transition-duration: 75ms;
  }
  .\32xl\:duration-100 {
    transition-duration: 0.1s;
  }
  .\32xl\:duration-150 {
    transition-duration: 150ms;
  }
  .\32xl\:duration-200 {
    transition-duration: 0.2s;
  }
  .\32xl\:duration-300 {
    transition-duration: 0.3s;
  }
  .\32xl\:duration-500 {
    transition-duration: 0.5s;
  }
  .\32xl\:duration-700 {
    transition-duration: 0.7s;
  }
  .\32xl\:duration-1000 {
    transition-duration: 1s;
  }
  .\32xl\:ease-linear {
    transition-timing-function: linear;
  }
  .\32xl\:ease-in {
    transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  }
  .\32xl\:ease-out {
    transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
  .\32xl\:ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
}
