// * {
//     margin: 0;
//     padding: 0;
//   }

//   .App {
//     display: flex;
//     height: 100vh;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//   }

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 300px;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate3d(-50%, -50%, 0);
}
