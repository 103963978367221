.all-docs-copy {
    display: grid;
    grid-template-columns: [full-start]0rem 1fr repeat(8,[col-start] minmax(min-content, 14rem))[col-end] 1fr  0rem[full-end];
    // background-color: red
    .sub-header {}
    .search-folder {
        margin-left: auto;
        grid-column: col-start 1 / col-end;
        position: relative;
        .search-input {
            border-radius: .2rem;
            outline: none;
            border: .1rem solid #555;
            padding: .3rem 2rem .3rem;
        }

        .search-icon {
            width: 1rem;
            height: 1rem;
            fill: #777;

            position: absolute;
            right: .5rem;
            top: .5rem;
        }

    }

    .breadcrumbs {
        display: flex;
        align-items: center;
    
        padding: 0 1rem;
        grid-column: col-start 1 / col-end;
        &__link {
            transform: skew(-20deg);
            color: #fff;
            font-weight: 500;
            max-width: fit-content;
            margin-left: 4rem;
            background-color: green;
            padding:  .3rem .7rem;
            cursor: pointer;

            &:not(:first-child) {
                margin-left: .5rem;
             
            }
        }

        .back-root {
            transform: skew(-20deg);
            color: #fff;
            text-transform: capitalize;
            background-color: green;
            padding:  .3rem .7rem;
            cursor: pointer;
            width: 5rem;
        }

    }

    
    

    
    &__list {
        margin-top: 1rem;

        grid-column: col-start 1 / col-end;
        
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(6rem, 6rem));
        gap: 2rem;

        .void-documents {
            margin: 1rem auto;

            .notfound {
                width: 5rem;
                height: 5rem;
            }
        }
        .not-found-text {
            font-size: 1.6rem;
            width: 22rem;
            margin-left: 1rem;
            text-transform: capitalize;
        }
        
    }



}