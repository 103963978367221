
  .cursive {
    /* font-family: "Pinyon Script", cursive; */
  }
  .sans {
    font-family: "Open Sans", sans-serif;
  }
  .bold {
    font-weight: bold;
  }
  .block {
    display: block;
  }
  .underline {
    border-bottom: 1px solid #777;
    padding: 5px;
    margin-bottom: 15px;
    width: 50% !important;
    display: flex;
    justify-self: center;
    align-self: center;
    margin: 0 auto;
   
  }
 
  .margin-0 {
    margin: 0;
  }
  .padding-0 {
    padding: 0;
  }
  .pm-empty-space {
    height: 40px;
    width: 100%;
  }
 
  .pm-certificate-container {
    position: relative;
    width: 800px;
    height: 600px;
    background-color: #618597;
    padding: 30px;
    color: #333;
    font-family: "Open Sans", sans-serif;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    
  }
  .pm-certificate-container .outer-border {
    width: 794px;
    height: 594px;
    position: absolute;
    left: 50%;
    margin-left: -397px;
    top: 50%;
    margin-top: -297px;
    border: 2px solid #fff;
  }
  .pm-certificate-container .inner-border {
    width: 730px;
    height: 530px;
    position: absolute;
    left: 50%;
    margin-left: -365px;
    top: 50%;
    margin-top: -265px;
    border: 2px solid #fff;
  }
  .pm-certificate-container .pm-certificate-border {
    position: relative;
    width: 720px;
    height: 520px;
    padding: 0;
    border: 1px solid #e1e5f0;
    background-color: #F7F7F7;
    background-image: none;
    left: 50%;
    margin-left: -360px;
    top: 50%;
    margin-top: -260px;
  }
  .pm-certificate-container .pm-certificate-border .pm-certificate-block {
    width: 650px;
    height: 200px;
    position: relative;
    left: 50%;
    margin-left: -325px;
    top: 70px;
    margin-top: 0;
  }
  .pm-certificate-container .pm-certificate-border .pm-certificate-header {
    margin-bottom: 10px;
  }
  .pm-certificate-container .pm-certificate-border .pm-certificate-title {
    position: relative;
    top: 40px;
  }
  .pm-certificate-container .pm-certificate-border .pm-certificate-title h2 {
    font-size: 34px !important;
  }
  .pm-certificate-container .pm-certificate-border .pm-certificate-body {
    /* padding: 20px; */
  }
  .pm-certificate-container .pm-certificate-border .pm-certificate-body .pm-name-text {
    font-size: 20px;
  }
  .pm-certificate-container .pm-certificate-border .pm-earned {
    /* margin: 15px 0 20px; */
  }
  .pm-certificate-container .pm-certificate-border .pm-earned .pm-earned-text {
    font-size: 30px;
  }
  .pm-certificate-container .pm-certificate-border .pm-earned .pm-credits-text {
    font-size: 5px;
  }
  .pm-certificate-container .pm-certificate-border .pm-course-title .pm-earned-text {
    font-size: 12px;
  }
  .pm-certificate-container .pm-certificate-border .pm-course-title .pm-credits-text {
    font-size: 15px;
  }
  .pm-certificate-container .pm-certificate-border .pm-certified {
    font-size: 12px;
  }
  .pm-certificate-container .pm-certificate-border .pm-certified .underline {
    margin-bottom: 5px;
  }
  .pm-certificate-container .pm-certificate-border .pm-certificate-footer {
    width: 650px;
    height: 100px;
    position: relative;
    left: 50%;
    margin-left: -325px;
    bottom: -105px;
  }
  
  .certificate-label-id {
      font-weight: 550;
      color: #000;
  }

  .certificate-footer {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  .barcode {
    width: 60px;
    height: 60px;
    margin-bottom: 10px;
  }

  .barcode-img {
    width: 100%;
    height: 100%;
  }

  .certificate-id {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }