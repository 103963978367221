.report-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 20px;
    background: white !important;
    border-bottom: 1px solid #CCC7C7;
    opacity: 0.8;
  .col {
    &:last-child {
        text-align: right;
    }
  }

    h3, h4, h6 {
        margin-bottom: 0;
        font-weight: 400;
    }
}

.report-card {

    .card-header-inner {
        text-align: center;
        margin: auto;

        .icon-container {
            border: 1px solid #CCC7C7;
            border-radius: 100%;
            padding: 10px;
            .icon {
                color: #6e6e6e;
                width: 20px;
                height: 20px;
                display: block;
            }
        }

        
    }

    .card-content {
        margin-bottom: 20px;
    
        &:last-child {
            margin-bottom: 0;
        }
    
        .card-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-top: 2px solid #CCC7C7;
    
            h6 {
                margin-bottom: 0;
                padding: 5px 0;
                font-size: 14px !important;
                font-weight: 500;
                text-transform: capitalize;
                color: #6e6e6e;
            }
    
            &.alt {
                background: #E6E8ED;
            }
    
            .profit-title {
                text-transform: uppercase;
                color: #6e6e6e;
                font-weight: 700;
                font-size: 14px;
                margin-bottom: 0;
            }
        }
    }
    
}