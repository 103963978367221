.sidebar {
  color: #4d06a5 !important;

  .icon {
    color: #4d06a5 !important;
  }

  li {
    color: #4d06a5 !important;
  }

  .mm-active {
    .icon {
      color: #4d06a5 !important;
      &:active {
        color: #4d06a5 !important;
      }
    }

    a {
      .icon {
        color: #4d06a5 !important;
      }

      &::after {
        border-top: 0.3125rem solid #4d06a5 !important;
        border-left: 0.3125rem solid #4d06a5 !important;
      }

      &:hover {
        span {
          color: #4d06a5 !important;
        }
      }
    }

    .nav-text {
      color: #4d06a5 !important;

      li a {
        .mm-active {
          color: #4d06a5 !important;

          i {
            color: #4d06a5 !important;
          }
        }
      }
    }
  }

  .mm-show {
    a {
      color: #4d06a5 !important;
      &::before {
        background-color: #4d06a5 !important;
      }
    }
  }
}
.active-menu {
  background-color: #ccc !important;
  border-radius: 30px;
}

[data-sidebar-style="full"][data-layout="vertical"]
  .dlabnav
  .metismenu
  > li.mm-active
  > a
  i {
  color: #4d06a5 !important;
}

.dlabnav .metismenu ul a.mm-active {
  color: #4d06a5 !important;
}
