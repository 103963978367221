.breadcrumb-list {
    padding: .7rem;
    &__item {
        display: flex;
        align-items: center;
        gap: .5rem;

        &--text {
            text-transform: capitalize;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 550;
            cursor: pointer;
            .divider {
                margin-left: .5rem;
            }
        }
    }
}