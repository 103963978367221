.pagination {
    width: 100% !important;
    display: flex !important;
    justify-content: center !important;
    margin: 20px auto !important;
    padding: 0.3125rem 0.3125rem;
    color: #6E6E6E;
    border-radius: 1rem;
    padding: 0.125rem 0;
    margin-bottom: 1.25rem;

    .previous, 
    .next {
        color: var(--secondary) !important;
        background: inherit !important;
        font-size: 1rem;
        height: 3.125rem;
        width: -moz-fit-content;
        width: fit-content;
        border: 0.0625rem solid var(--secondary);
        border-radius: 0.75rem;
        padding: 0 1.25rem;
        line-height: 3.125rem;
        margin: 0 0.625rem;
        display: inline-block;
        color: var(--secondary) !important;
        font-size: 20px !important;

        &:hover {
            background: var(--secondary) !important;
        }

        a {
            &:hover {
                
                color: #fff !important;
            }
        }
    }

    li {
        // display: inline-block;
        height: 3.125rem;
        width: 3.125rem;
        padding: 0;
        margin-left: 0rem;
        margin: 0 0.125rem;
        line-height: 3.125rem;
        text-align: center;
        font-size: 1.125rem;
        border-radius: 0.75rem;
        color: #fff !important;
        background: var(--secondary) !important;

        a{
            display: inline-block;
            width: 100%;
            height: 100%;
        }
    }
}