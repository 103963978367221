.calendarWrap {
    position: relative;

    input {
        width: 100%;
        display: flex !important;
        justify-content: center !important;
        text-align: center !important;
        padding: 10px 15px;
        font-size: 12px;
        font-weight: 400;
        background-color: hsl(0, 0%, 100%);
        border-color: hsl(0, 0%, 80%);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        color: hsl(0, 0%, 20%);
        cursor: pointer;
    }

    > div {
        position: absolute;
        top: 50px;
        left: -200px;
        @media screen and (max-width: 768px) {
            left: 0;
        }
        z-index: 999;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.75rem;

    }
}