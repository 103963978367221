.main__chatbody {
  flex-grow: 1;
  background-color: #f4f3f8;
  border-radius: 10px;
  padding: 15px 20px;
  display: flex;

  background-color: #fff;
  border: 0rem solid transparent;
  border-radius: 0.75rem;
  box-shadow: 0rem 0.3125rem 0.3125rem 0rem rgb(82 63 105 / 5%);
  height: calc(100vh - 30vh);
}
