.card-header {

    .search-input {
        position: relative;
        border-radius: 0.3rem;
        padding: 0.4rem 2rem 0.4rem 0.4rem;
        outline: none;
        border: .1rem solid #555;
    }
    .search-icon {
        width: 1.3rem; 
        height: 1.3rem;
        position: absolute;
        right: 2.5rem;
        top: 1.9rem;
    }
}