.institution-folders {
    display: grid;
    grid-template-columns: [full-start]0rem 1fr repeat(8,[col-start] minmax(min-content, 14rem))[col-end] 1fr  0rem[full-end];
    

    &__list {
        grid-template-columns: repeat(auto-fit, minmax(6rem, 6rem));
        background-color: blue;
        .folder {
            width: 6rem;
        }
    }

    
}

.insitution-folder-sub-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

.breadcrumb-nav {
        display: flex;
        align-items: center ;
        // gap: 1rem;

        .back-btn {
            text-transform: capitalize;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 550;
            cursor: pointer;
        }
    }

    .search-folder {
        margin-left: auto;
        grid-column: col-start 1 / col-end;
        position: relative;
        .search-input {
            border-radius: .2rem;
            outline: none;
            border: .1rem solid #555;
            padding: .3rem 2rem .3rem;
        }

        .search-icon {
            width: 1rem;
            height: 1rem;
            fill: #777;

            position: absolute;
            right: .5rem;
            top: .5rem;
        }

    }
}