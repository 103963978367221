.file {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;
    cursor: pointer;
    
    .file-icon {
    width: 6rem;
    height: 6rem;
    fill:  #555;  
    }
    .file-name {
        font-size: 16px;
        text-transform: capitalize;
        width: 6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
    }
}