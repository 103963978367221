.dashboard-card {

    .card-body {
        color: #4D06A5 !important;
        &:hover {
            color: #4D06A5 !important;

            .booking-status {
                background: white !important;

                span {
                    color: #4D06A5 !important;

                    .icon {
                        width: 40px ;
                        height: 40px;
                    }
                   
                }
            }
        }
    }
    .booking-status {
    
        span {
            background: inherit !important;
           
            .icon {
                width: 30px ;
                height: 30px;
            }
        }
    }
}

.grid-5 {
    @media screen and (min-width: 1024px) {
        width: calc(100% / 5) !important;
    }
}

.booking {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80px !important;
}