.folder {
    
    overflow: hidden;
    text-overflow: ellipsis;
    height: 10rem;
    cursor: pointer;

    &__icon {
        width: 6rem;
        height: 6rem;
        display: flex;
        
        // background-color: red;
    }

    .folder__icon-name {
        font-size: 16px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        width: 6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        margin-top: 1rem;

        &:hover {
            overflow: none;
        }
    }

    .tooltip {
        position: absolute;
        top: -25px; /* Adjust this value as needed to position the tooltip */
        left: 50%;
        transform: translateX(-50%);
        background-color: #555;
        color: #fff;
        padding: 5px 10px;
        font-size: 12px;
        border-radius: 4px;
        white-space: nowrap;
      }
}